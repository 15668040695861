export {
  TransformControls as TransformControls,
  TransformGizmoScale as TransformGizmoScale,
  TransformGizmoRotate as TransformGizmoRotate,
  TransformGizmoTranslate as TransformGizmoTranslate,
  TransformGizmo as TransformGizmo
} from './scripts/controls/TransformControls.js';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light.css';

import "./styles/main.css";
import "./styles/auto-segments.css";
import "./styles/beta-tag.css";
import "./styles/buttons.css";
import "./styles/dev.css";
import "./styles/dialogs.css";
import "./styles/drone-images.css";
import "./styles/exports.css";
import "./styles/header.css";
import "./styles/help-tool.css";
import "./styles/layers.css";
import "./styles/loading-screen.css";
import "./styles/mask-tool.css";
import "./styles/material.css";
import "./styles/model-import-tool.css";
import "./styles/module-library.css";
import "./styles/module-specs-tool.css";
import "./styles/occluder.css";
import "./styles/project-info.css";
import "./styles/responsive.css";
import "./styles/segment-tree.css";
import "./styles/settings.css";
import "./styles/share-tool.css";
import "./styles/sidebar-keepouts.css";
import "./styles/sidebar.css";
import "./styles/split.css";
import "./styles/status-indicator.css";
import "./styles/system-tree.css";
import "./styles/viewshed-tool.css";
import "./styles/widgets.css";

import "fine-uploader";
import "pako";
import _ from 'lodash';

require("jquery-ui/ui/widgets/accordion");
require("jquery-ui/ui/widgets/datepicker");
require("jquery-ui/ui/widgets/dialog");
require("jquery-ui/ui/widgets/slider");
require("jquery-ui/ui/widgets/spinner");
require("jquery-ui/ui/widgets/tabs");
require("jquery.panzoom");

import './scripts/libs/jquery.ddslick.min.js';


import "./bootstrap/Config.js";

import { viewer } from './scripts/Viewer.js';

if(!globalThis.viewer) {
  viewer.runViewerInit();
}
