

import { Vector2 } from './three.module';

class Polygon {
    constructor(options) {
        options = Object.assign({
            fixWindingOrder: true
        }, options || {});

        var vv = [];

        if (options.vertices) {
            if (options.fixWindingOrder && this.getArea(options.vertices) < 0) {
                this.windingOrderFixed = true;
                options.vertices.reverse();
            }

            for (let v of options.vertices)
                vv.push(v.clone());
        } else if (options.x !== undefined && options.y !== undefined) {
            var { x, y, w, h } = options;

            vv.push(
                new Vector2(x,   y),
                new Vector2(x+w, y),
                new Vector2(x+w, y+h),
                new Vector2(x,   y+h)
            );
        }

        var edges = [];

        var minX = (vv.length > 0) ? vv[0].x : undefined;
        var minY = (vv.length > 0) ? vv[0].y : undefined;
        var maxX = minX;
        var maxY = minY;

        for (var i = 0; i < vv.length; i++) {
            let edge = {
                v1: vv[i],
                v2: vv[(i + 1) % vv.length]
            };

            edge.ni = this.normalInward(edge);
            edge.no = edge.ni.clone().negate();

            edges.push(edge);

            minX = Math.min(vv[i].x, minX);
            minY = Math.min(vv[i].y, minY);
            maxX = Math.max(vv[i].x, maxX);
            maxY = Math.max(vv[i].y, maxY);
        }

        this.vertices = vv;
        this.edges    = edges;

        this.minX = minX;
        this.minY = minY;
        this.maxX = maxX;
        this.maxY = maxY;

        this.options = options;
    }

    normalInward(edge) {
        var v1 = edge.v1;
        var v2 = edge.v2;

        var vd = v2.clone().sub(v1);

        return new Vector2(-vd.y, vd.x).normalize();
    }


    // based on http://local.wasp.uwa.edu.au/~pbourke/geometry/lineline2d/
    edgeIntersection(edgeA, edgeB, options) {
        options = options || {};

        var av1 = edgeA.v1;
        var av2 = edgeA.v2;
        var bv1 = edgeB.v1;
        var bv2 = edgeB.v2;

        var den = (bv2.y - bv1.y) * (av2.x - av1.x) - (bv2.x - bv1.x) * (av2.y - av1.y);

        if (den == 0)
            return null; // parallel or coincident

        var ua = ((bv2.x - bv1.x) * (av1.y - bv1.y) - (bv2.y - bv1.y) * (av1.x - bv1.x)) / den;
        var ub = ((av2.x - av1.x) * (av1.y - bv1.y) - (av2.y - av1.y) * (av1.x - bv1.x)) / den;

        if (!options.asLines)
            if (ua < 0 || ub < 0 || ua > 1 || ub > 1)
                return null; // edges/segments too short to cross

        return new Vector2(
            av1.x + ua * (av2.x - av1.x),
            av1.y + ua * (av2.y - av1.y)
        );
    }


    arc(center, radius, vStart, vEnd, isPaddingBoundary) {
        const twoPI = Math.PI * 2;

        var start = Math.atan2(vStart.y - center.y, vStart.x - center.x);
        var end   = Math.atan2(vEnd.y   - center.y, vEnd.x   - center.x);

        if (start < 0)
            start += twoPI;

        if (end < 0)
            end += twoPI;

        var nSegments = 5; // odd number so that one arc vertex is exactly radius from center
        var angle = (start > end) ? start - end : start + twoPI - end;
        var step = (isPaddingBoundary ? -angle : twoPI - angle) / nSegments;

        var vv = [ vStart ];

        for (let i = 1; i < nSegments; ++i) {
            let angle = start + step * i;

            let v = new Vector2(
                center.x + Math.cos(angle) * radius,
                center.y + Math.sin(angle) * radius
            );

            vv.push(v);
        }

        vv.push(vEnd);

        return vv;
    }


    // adapted from:
    //
    // http://hansmuller-webkit.blogspot.com/2013/04/growing-and-shrinking-polygons-round-one.html
    //
    shrink(amount) {
        var ee = []; // offset edges

        var f = this.getArea(this.vertices) < 0 ? -1 : 1;

        for (let i = 0; i < this.edges.length; i++) {
            let e = this.edges[i];
            let d = e.ni.clone().multiplyScalar(f * amount(i));

            ee.push({
                v1: e.v1.clone().add(d),
                v2: e.v2.clone().add(d)
            });
        }

        var vv = [];

        for (var i = 0; i < ee.length; i++) {
            var cur  = ee[i];
            var prev = ee[(i + ee.length - 1) % ee.length];

            var vi = this.edgeIntersection(prev, cur, { asLines: true });

            if (vi) {
                vv.push(vi);
            } else {
                var c = this.edges[i].v1;

                vv.push(...this.arc(c, f * amount(i), prev.v2, cur.v1, true));
            }
        }

        var offset = new Polygon(Object.assign(this.options, { vertices: vv }));

        return offset;
    }


    getMidpointOffsets(amount) {
        var ee = []; // offset edges

        for (let i = 0; i < this.edges.length; i++) {
            let e = this.edges[i];
            let d = e.no.clone().multiplyScalar(amount(i));

            ee.push({
                v1: e.v1.clone().add(d),
                v2: e.v2.clone().add(d)
            });
        }

        var vvm = ee.map(e => e.v1.add(e.v2).divideScalar(2));

        return vvm;
    }


    /**
     * winding number algorithm from: http://geomalgorithms.com/a03-_inclusion.html
     * @param {{x: number, y: number}} v
     */
    contains(v) {
        let wn = 0;

        for (let idx = 0; idx < this.vertices.length; ++idx) {
            const curVtx = this.vertices[idx];
            const nextVtx = this.vertices[(idx + 1) % this.vertices.length];
            const isLeftVal = this._isLeft(curVtx, nextVtx, v);

            // the weird "+- 0.0" terms were like that when I got here
            // I'm assuming it's some sort of edge-case floating-point hack
            if (curVtx.y <= v.y) {
                if (nextVtx.y > v.y && (isLeftVal - 0.0) > 0.0) {
                    ++wn;
                }
                continue;
            }

            if (nextVtx.y <= v.y && (isLeftVal + 0.0) < 0.0) {
                --wn;
            }
        }

        return wn !== 0;
    }

    _isLeft(l1, l2, v) {
        return ((l2.x - l1.x) * (v.y - l1.y) - (v.x - l1.x) * (l2.y - l1.y));
    }


    getArea(vv) {
        var area = 0;

        for (var i = 0; i < vv.length; i++) {
            var j = (i + 1) % vv.length;

            area += vv[i].x * vv[j].y;
            area -= vv[j].x * vv[i].y;
        }

        return area / 2;
    }


    getMaxDimensions() {
        var xx = this.vertices.map(v => v.x);
        var yy = this.vertices.map(v => v.y);

        var maxX = Math.max.apply(Math, xx);
        var maxY = Math.max.apply(Math, yy);
        var minX = Math.min.apply(Math, xx);
        var minY = Math.min.apply(Math, yy);

        return {
            width:  maxX - minX,
            height: maxY - minY
        };
    }
}



export { Polygon };
