import tippy from 'tippy.js';
import { moduleSpecsTool, prompts, pvWattsClient } from '../Viewer';
import { CSVTool } from '../tools/CSVTool';

import { disableUntil } from './Gui.js';

import * as html from '../../templates/GuiExports/ProductionData.hbs';

class ExportProductionDataGUI {
    /**
     * @param {object} param0
     * @param {import('./GuiStatusIndicator').StatusIndicatorGui} param0.statusIndicator
     */
    constructor({ statusIndicator }) {
        this.name = 'Production Data';
        this.selector = '.prod-exports';
        this.html = html;
        this.statusIndicator = statusIndicator;
    }

    async onDownloadNrelPvReport() {
        this.statusIndicator.pushStatus('Downloading shade report')
            .setIndeterminate();
        const csvTool = new CSVTool(pvWattsClient);

        try {
            await csvTool.getPVReportNREL();
            this.statusIndicator.pushStatus('Shade report downloaded!')
                .deferClearProgress(1000);
        } catch (err) {
            console.error(err);
            const errText = 'An error occurred while downloading shade report';
            this.statusIndicator.pushError(errText);
            prompts.info(
                `<p>${errText}.</p>
                 <p>Please contact support for assistance.</p>`
            );
        } finally {
            this.statusIndicator.deferClearProgress();
        }
    }

    init() {
        const self = this;
        $('body').on('click', 'button.nrel-pv-report', function () {
            disableUntil(() => self.onDownloadNrelPvReport())($(this), 'Downloading');
        });

        $('body button.nrel-pv-report').on("hover", function(e) {
            const segments = moduleSpecsTool.getSolarParams();

            const modsMissing = segments.some(function(segment){
                return segment.kW <= 0
            })

            if(modsMissing){
                $(this).prop("disabled", true)
                $(this).attr("aria-disabled", "true")
                $(this).addClass("disabled-exports")
            }
            $(this).prop("disabled", false)
            $(this).attr("aria-disabled", "false")
            $(this).removeClass("disabled-exports")
        })
    }

    renderState() {
        const moduleSpecsToolState = moduleSpecsTool.getState();
        const disabled = moduleSpecsToolState.totalQty === 0;
        $('button.nrel-pv-report').prop("disabled", disabled).attr("aria-disabled", disabled).toggleClass("disabled-exports", disabled);
        if(disabled) {
            const tip = "Please add viewsheds and one or more modules to each segment first";
            tippy('.hourly-prod-tooltip', { content: tip });
        }
    }
}

export {ExportProductionDataGUI}
