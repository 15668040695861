import { KeyStore } from '../alibs/KeyStore';

var _MaterialsCache = new KeyStore();


/**
 * Collection/cache of materials used in the viewer.
 *
 * Be careful when modifying the materials below. These can be shared between different objects in the scene, and
 * modifying them can lead to unintended consequences.
 *
 * Don't pass colors, line thicknesses and such as options as that defeats the purpose of having all 3D appearance
 * related stuff in the same place.
 *
 * When in doubt, add a new material.
 */
import { DoubleSide, LineBasicMaterial, LineDashedMaterial, MeshBasicMaterial } from './three.module';
class Materials {

    static get guideClosed() {
        let key = [ 'guideClosed' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new LineDashedMaterial({
                color:       0xeaeaea,
                linewidth:   1.5,
                scale:       1,
                dashSize:    0.25,
                gapSize:     0.15,
                depthTest:   false,
                transparent: true,
                opacity:     1.0
            }));
        }

        return _MaterialsCache.get(key);
    }

    static get dashed() {
        let key = [ 'dashed' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new LineDashedMaterial({
                color:       0xffffff,
                linewidth:   3,
                scale:       1,
                dashSize:    0.3,
                gapSize:     0.15,
                depthTest:   false,
                transparent: true
            }));
        }

        return _MaterialsCache.get(key);
    }

    static get planeIntersection() {
        let key = [ 'planeIntersection' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new LineDashedMaterial({
                color:       0x57d4fd,
                linewidth:   3,
                scale:       1,
                dashSize:    0.3,
                gapSize:     0.15,
                depthTest:   false,
                transparent: true,
                opacity:     0.75
            }));
        }

        return _MaterialsCache.get(key);
    }

    static get invisibleMesh() {
        let key = [ 'invisibleMesh' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new MeshBasicMaterial({
                side:        DoubleSide,
                transparent: true,
                opacity:     0.0,
                depthTest:   false
            }));
        }

        return _MaterialsCache.get(key);
    }

    static get invisibleLine() {
        let key = [ 'invisibleLine' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new LineBasicMaterial({
                color:       0xffff00,
                linewidth:   1,
                transparent: true,
                depthTest:   false,
                opacity:     0
            }));
        }

        return _MaterialsCache.get(key);
    }

    static get normalLine() {
        let key = [ 'normalLine' ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new LineBasicMaterial({
                color:       0xff9200,
                linewidth:   1.5,
                transparent: true,
                depthTest:   true
            }));
        }

        return _MaterialsCache.get(key);
    }

    static markerSegment({ xray }) {
        let depthTest = ! Boolean(xray);
        let key = [ 'markerSegment', depthTest ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new MeshBasicMaterial({
                color:       0xffff00,
                opacity:     0.7,
                transparent: true,
                depthTest
            }));
        }

        return _MaterialsCache.get(key);
    }

    static markerMidpoint({ xray }) {
        let depthTest = ! Boolean(xray);
        let key = [ 'markerMidpoint', depthTest ];

        if (!_MaterialsCache.get(key)) {
            _MaterialsCache.set(key, new MeshBasicMaterial({
                color:       0xf47b1a,
                opacity:     0.7,
                transparent: true,
                depthTest
            }));
        }

        return _MaterialsCache.get(key);
    }
}


export { Materials, _MaterialsCache };
