import { SidebarGUI } from './gui/GuiSidebar.js';
import { Stats } from './libs/stats.min.js';
import { MidpointInputsGUI } from './gui/GuiMidpointInputs.js';
import { CompassTool } from './tools/CompassTool.js';
import { MeasurementUnits, MeasurementsTool } from './tools/MeasurementsTool.js';
import { ImageTool } from './tools/ImageTool.js';
import { HeaderGUI } from './gui/GuiHeader.js';
import { ModuleLibraryGUI } from './gui/GuiModuleLibrary.js';
import { Versions } from './core/Versions.js';
import { loadChanges, getSaveData, viewshedsSaved, embedLoadChanges } from './core/Saves.js';
import { AutoSegments } from './libs/AutoSegments.js';
import { ShareTool } from './tools/ShareTool.js';
import { Permissions } from './libs/Permissions.js';
import { Net } from './libs/Net.js';
import { GUI, guiReady } from './gui/Gui.js';
import { AnnotationsToolGUI } from './gui/GuiAnnotationsTool.js';
import { MeasurementsToolGUI } from './gui/GuiMeasurementsTool.js';
import { CSVExportTool } from './tools/CSVExportTool.js';
import { ModelImportTool } from './tools/ModelImportTool.js';
import { ModuleSpecsToolKeyboardControls } from './controls/ModuleSpecsKeyboardControls.js';
import { OccluderTool } from './tools/OccluderTool.js';
import { ModuleSpecsTool } from './tools/ModuleSpecsTool.js';
import { PVWattsClient } from './libs/PVWattsClient.js';
import { AutoKeepoutFinder } from './libs/AutoKeepoutFinder.js';
import { DxfExportTool } from './tools/DxfExportTool.js';
import { GuiHelpTool } from './gui/GuiHelpTool.js';
import { SlowAnnotationsTool } from './tools/SlowAnnotationsTool.js';
import { AnnotationsTool } from './tools/AnnotationsTool.js';
import { ViewshedTool } from './tools/ViewshedTool.js';
import { UnawareDate } from './libs/UnawareDate.js';
import { ShadeTool } from './tools/ShadeTool.js';
import { DeTiltTool } from './tools/DeTiltTool.js';
import { DeTiltToolGUI, DeTiltToolGUICallbacks } from './gui/GuiDeTiltTool.js';
import { ElevationHeatMap } from './tools/ElevationHeatMap.js';
import { AreaAndExcavate } from './tools/AreaAndExcavate.js';
import { VolumeTool } from './tools/VolumeTool.js';
import { Protractor } from './tools/Protractor.js';
import { ToolSelector } from './tools/ToolSelector.js';
import { OrbitControls } from './controls/OrbitControls.js';
import { ProgressBarGUI } from './gui/GuiProgressBar.js';
import { MultiProjectionCamera } from './libs/MultiProjectionCamera.js';
import { ModuleLibrary } from './tools/ModuleLibrary.js';
import { Mouse } from './gui/Mouse.js';
import { KeyboardState } from './controls/KeyboardState.js';
import { Prompts } from './gui/Prompts.js';
import { Config, getSearchParameters } from '../bootstrap/Config';
import { AmbientLight, Box3, DoubleSide, Mesh, MeshBasicMaterial, OrthographicCamera, PCFSoftShadowMap, PlaneGeometry, Raycaster, Scene, Texture, Vector2, WebGLRenderer } from './libs/three.module';
import { browserDownloadUrl } from './libs/Utilities.js';
import { SelectedSegmentSync } from './libs/SelectedSegmentSync.js';
import * as Q from 'q';
import { Userpilot } from 'userpilot';

/** @typedef {{
 *    modelURL: string,
 *    simpleModelURL?: string | undefined,
 *    materialURL?: string | undefined,
 *    textureURL: string,
 *    kmlURL?: string | undefined }} ModelComponents */

var gui;

var DEFAULT_LAT      =  25.77;
var DEFAULT_LNG      = -80.18;
var DEFAULT_MAP_ZOOM =  8;

var viewerInitialized = false;

var container, stats;

/** @type {Camera} */
let camera;

/** @type {Scene} */
let scene;

/** @type {Raycaster} */
let raycaster;

/** @type {WebGLRenderer} */
let renderer;

/** @type {OrbitControls} */
let controls;

var cubeScene;
var mesh, vMouse;
var cameraOrtho, sceneOrtho;
var bgCamera, bgScene, bg, bgTexture;

/** @type {ToolSelector}  */
let toolSelector;

/** @type {AnnotationsTool} */
let annotationsTool;

/** @type {ElevationHeatMap} */
let elevationHeatMap;

/** @type {DxfExportTool} */
let dxfExportTool;

var measurementsTool, protractor,
    shadeTool,
    treeVegetationAnnotation,
    areaAndExcavate,
    modelImportTool, compassTool,
    csvExportTool, shareTool;

/** @type {ModuleSpecsTool} */
let moduleSpecsTool;
/** @type {ViewshedTool} */
let viewshedTool;
/** @type {OccluderTool} */
let occluderTool;

/** @type {VolumeTool} */
let volumeTool;

/** @type {DeTiltTool} */
let deTiltTool;

/** @type {DeTiltToolGUI} */
let deTiltToolGui;

/** @type {PVWattsClient} */
let pvWattsClient;

/** @type {SelectedSegmentSync} */
let segmentSync;

var msKeyboardControls;

var guiProjInfo, guiHelpTool, annotationsToolGui;

/** @type {ModuleLibraryGUI} */
let guiModuleLibrary;

/** @type {Prompts} */
let prompts = new Prompts();

var model, texture, simpleObject;
var panning;
var loader;

var keyboard = new KeyboardState();
var mouseHandler = new Mouse();

var urlParams = getSearchParameters();
var user, project, currentDesign, designs, autoSegmentsData;
var measurementUnits = MeasurementUnits.FEET_INCHES;

/** @type {ModuleLibrary} */
let moduleLibrary;

var nIcon, northArrow, dir, sph, bottom, topSprite, compass, homeSprite, outlinePass, composer
var cubeOffset      = true
var viewUpdate      = false




var screenshot;

/** @type {GUIModelList} **/
var modelList;
var saveData;

export class VIEWER {
  constructor() {
    this.inspectorToolEnabled = false;
    this.viewerChangesLoaded = false;
    this.camera = camera;
    this.scene = null;
    this.thumbnail = null;
  }

  runStandaloneInit() {
    // mock some stuff up to run standalone for dev purposes
    console.warn('[ ::::::::::: RUNNING IN STANDALONE MODE, LOADING SOME FUNNY STUFF!!! ::::::::::: ]');

    user = {
      id: 0,
      role: ['scaniflyAdmin']
    };

    project = {
      id: 0,
      monthlyUsage: [],
      details: {
        additionalModels: [],
        droneImages: {
            eyeView: []
        },
        viewer3d: {
            shadeTool: {
                "lat": 40.0767,
                "lng": -74.0418,
                "lockMovement": true,
                "mapUrl": "https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=40.0767,-74.0418&zoom=19&size=512x512&scale=2&key=AIzaSyCIxJV6mYZCYE8U4IYI5_JNoaQtRdp3NCE",
                "mapZoom": 19,
                "position": {
                    "x": 2.4833466729921696,
                    "y": -0.00005978017708890886,
                    "z": 0.5978017710315289
                },
                "rotationDegrees": 180
            }
          }
      },
      createdBy: user
    };

    designs         = [];
    moduleLibrary = new ModuleLibrary();

    this.init({
        modelURL:   './test-models/3dmodel.obj',
        textureURL: './test-models/3dmodel.jpg',
    });
  }

  runViewerInit() {
    globalThis.viewer = this;
    if (urlParams.token) {
      localStorage.setItem('token', urlParams.token);
    }

    if (urlParams.ID) {
      if (!urlParams.embed) {
        Q.all([
            Net.getLoggedInUser(),
            Net.getProject(urlParams.ID, urlParams.did),
            Net.getProjectDesigns(urlParams.ID),
        ]).spread(function (userData, projectData, designData) {
            user    = userData;
            project = projectData;
            designs = designData;

            for (const d of designs) {
                if (d.id === urlParams.did) {
                    currentDesign = d;
                    break;
                }
            }
            if(currentDesign)
                globalThis.viewer.thumbnail = currentDesign.thumbnailUrl;

            return Net.getModuleLibrary(project.createdBy.id);
        }).then(libraryData => {
            moduleLibrary = new ModuleLibrary(user.id, libraryData);

            /** @type {ModelComponents} */
            const modelComponents = {};

            modelComponents.modelURL       = project.details.modelUrl;
            modelComponents.simpleModelURL = project.details.simpleModelUrl;
            modelComponents.materialURL    = project.details.materialUrl;
            modelComponents.textureURL     = project.details.textureUrl;
            modelComponents.kmlURL         = project.details.kmlUrl;

            this.init(modelComponents, moduleLibrary);
        }).fail((err) => {
            prompts.info('Error: Failed to load model');
            throw err;
        }).done();
      } else {
          // EMBED MODE (public, read-only)
        Net.getEmbedData(urlParams.ID, urlParams.did).then(projectData => {
            project = projectData;

            const modelComponents = {
                modelURL       : project.details.modelUrl,
                simpleModelURL : project.details.simpleModelUrl,
                materialURL    : project.details.materialUrl,
                textureURL     : project.details.textureUrl,
                kmlURL         : project.details.kmlUrl
            };

            moduleLibrary = new ModuleLibrary('');

            GUI.embedMode({
                init:      () => this.init(modelComponents, moduleLibrary),
                title:     project.name,
                thumbnail: project.thumbnailUrl
            });
        }).fail((err) => {
            prompts.info('Error: Failed to load model');
            throw err;
        }).done();
      }
    } else {
      import('../bootstrap/Config').then(
        ({Config}) => {
          if (!Config.envType === 'development') {
            prompts.info('Error: Model not found');
            throw new Error('Model id is missing');
          }

          this.runStandaloneInit();
        }
      );
    }
  }

  /**
   * @param {ModelComponents} modelComponents
   * @param {ModuleLibrary} moduleLibrary
   */
  init(modelComponents, moduleLibrary) {
      container = document.createElement( 'div' );
      document.getElementById('container3d').appendChild(container);

      var width    = window.innerWidth;
      var height   = window.innerHeight;

      // scene
      scene = this.scene = new Scene();

      sceneOrtho = new Scene();

      // camera
      camera = new MultiProjectionCamera(45, width / height, 1, 2000);
      camera.position.set(0, 60, -110);
      camera.lookAt(scene.position);
      this.camera = camera;

      camera.visible = false

      cameraOrtho = new OrthographicCamera(-width/2, width/2, height/2, -height/2, 1, 10);

      cameraOrtho.position.z = 10;

      // background
      bgScene = new Scene();

      bgCamera = new OrthographicCamera(-width/2, width/2, height/2, -height/2, 1, 100);
      bgCamera.position.set(0, 0, 10);

      Net.loadTexture('/styles/images/s3d-background.jpg?x=202104140000').then(function (image) {
          bgTexture = new Texture();
          bgTexture.image = image;
          bgTexture.needsUpdate = true;

          updateBG(width, height);
      });

      // lights
      // NOTE: set ambient light to 0x909090 or another shade of gray to render non-textured models properly
      var ambient = new AmbientLight(0xffffff);
      scene.add(ambient);

      //var directionalLight = new DirectionalLight( 0xffffff, 0.5 );
      //directionalLight.position.set( 5, 5, 0 );
      //scene.add( directionalLight );

      // raycaster
      raycaster = new Raycaster();
      vMouse = new Vector2();

      // renderer
      const canvasSize = SidebarGUI.canvasSize;

      renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasSize.w, canvasSize.h, false);
      //renderer.setClearColor(new Color(0.749, 0.82, 0.898), 1.0);

      renderer.autoClear = false;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = PCFSoftShadowMap;

      container.appendChild( renderer.domElement );


      loader = new ProgressBarGUI();
      loader.show = true;
      loader.determinate = false;


      // controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.noKeys = true; // don't respond to keyboard events
      controls.target.set(0, 5, 0);

      // tools
      toolSelector = new ToolSelector();

      measurementsTool = new MeasurementsTool();
      measurementsTool.setCamera(camera);
      measurementsTool.setRaycaster(raycaster);
      measurementsTool.addToScene(scene);
      measurementsTool.setUnits(measurementUnits);
      toolSelector.register(measurementsTool);

      protractor = new Protractor();
      protractor.setCamera(camera);
      protractor.setRaycaster(raycaster);
      protractor.addToScene(scene, sceneOrtho);
      toolSelector.register(protractor);

      volumeTool = new VolumeTool();
      volumeTool.setCamera(camera);
      volumeTool.setRaycaster(raycaster);
      volumeTool.addToScene(scene);
      toolSelector.register(volumeTool);

      areaAndExcavate = new AreaAndExcavate();
      areaAndExcavate.setCamera(camera);
      areaAndExcavate.setRaycaster(raycaster);
      areaAndExcavate.addToScene(scene);
      toolSelector.register(areaAndExcavate);

      elevationHeatMap = new ElevationHeatMap();
      elevationHeatMap.setCamera(camera);
      elevationHeatMap.setControls(controls);
      toolSelector.register(elevationHeatMap);

      shadeTool = new ShadeTool(ambient);
      shadeTool.setCamera(camera);
      shadeTool.setRaycaster(raycaster);
      shadeTool.addToScene(scene);
      shadeTool.setLatitude(DEFAULT_LAT);
      shadeTool.setLongitude(DEFAULT_LNG);
      shadeTool.setTime(720); // noon
      // shadeTool.setDate(new UnawareDate(new Date(), true)); // today
      shadeTool.setDate(new UnawareDate(new Date("December 21, " + (new Date()).getFullYear()), true)); // today
      shadeTool.setTimezone();
      shadeTool.moveLight();
      toolSelector.register(shadeTool);

      viewshedTool = new ViewshedTool();
      viewshedTool.setCamera(camera);
      viewshedTool.setRaycaster(raycaster);
      viewshedTool.addToScene(scene);
      viewshedTool.setCoordinates(shadeTool.getLatitude(), shadeTool.getLongitude());
      viewshedTool.setYear(shadeTool.getDate().getFullYear());
      viewshedTool.setUTCOffset(shadeTool.getUTCOffset());
      viewshedTool.setProjectId(project.id);
      toolSelector.register(viewshedTool);

      annotationsTool = new AnnotationsTool();
      annotationsTool.setCamera(camera);
      annotationsTool.setRaycaster(raycaster);
      toolSelector.register(annotationsTool);

      treeVegetationAnnotation = new SlowAnnotationsTool();
      treeVegetationAnnotation.setCamera(camera);
      treeVegetationAnnotation.setRaycaster(raycaster);
      toolSelector.register(treeVegetationAnnotation);

      guiHelpTool = new GuiHelpTool()
      guiHelpTool.init()

      dxfExportTool = new DxfExportTool(camera, controls);

      const autoKeepoutFinder = new AutoKeepoutFinder({scene, debug: Config.envType === 'development'});

      pvWattsClient = new PVWattsClient('tew0lvIOije7WHPQElAxYcboj9XI4v2wIJ8AhM2M');

      moduleSpecsTool = new ModuleSpecsTool(pvWattsClient, autoKeepoutFinder, moduleLibrary);
      this.moduleSpecsTool = moduleSpecsTool;

      moduleSpecsTool.setCamera(camera, renderer.domElement);
      moduleSpecsTool.setRaycaster(raycaster);
      moduleSpecsTool.addToScene(scene);

      moduleSpecsTool.setProjectId(project.id);
      moduleSpecsTool.getMonthlySolarAccess = function () { return viewshedTool.getMonthlySolarAccess(); };
      moduleSpecsTool.getMonthlyUsage       = function () { return project.kwh.some(usage => usage > 0) ? project.kwh : project.monthlyUsage; };
      moduleSpecsTool.getPlaneInfo          = function () {
          return {
              planes:      viewshedTool.getPlanes(),
              lat:         shadeTool.getLatitude(),
              lng:         shadeTool.getLongitude(),
              utilityRate: project.utilityRate
          };
      };
      moduleSpecsTool.getPricePerKWH = function () {
          var utility = (project.utilityRate !== undefined) ? project.utilityRate : 0.13;
          var solar   = (project.solarRate   !== undefined) ? project.solarRate   : 0.09;

          return { utility: utility, solar: solar };
      };

      toolSelector.register(moduleSpecsTool);


      occluderTool = new OccluderTool();
      occluderTool.setCamera(camera, renderer.domElement);
      occluderTool.setRaycaster(raycaster);
      occluderTool.addToScene(scene);

      toolSelector.register(occluderTool);


      msKeyboardControls = new ModuleSpecsToolKeyboardControls(moduleSpecsTool);

      modelImportTool = new ModelImportTool();
      modelImportTool.setCamera(camera, renderer.domElement);
      modelImportTool.setRaycaster(raycaster);
      modelImportTool.addToScene(scene);
      toolSelector.register(modelImportTool);

      csvExportTool = new CSVExportTool();

      deTiltTool = new DeTiltTool();
      deTiltTool.setCamera(camera, renderer.domElement);
      deTiltTool.setRaycaster(raycaster);
      deTiltTool.addToScene(scene);
      toolSelector.register(deTiltTool);

      // model
      var promise = Q();

      if (modelComponents.simpleModelURL) {
          promise = Net.loadOBJ(modelComponents.simpleModelURL, {
              materials:          null,
              onProgress:         function () {},
              onDownloadFinished: function () {},
              onParsing:          function () {}
          }).then(function (object) {
              simpleObject = object;

              simpleObject.rotateX(-Math.PI/2);
              simpleObject.traverse(function (child) {
                  if (child.type === "Mesh") {
                      child.material.side = DoubleSide;

                      annotationsTool.addObject(child);
                      treeVegetationAnnotation.addObject(child);
                  }
              });

              // center the object in the scene
              var bbox = new Box3().setFromObject(simpleObject);
              simpleObject.position.x = -(bbox.min.x + (bbox.max.x - bbox.min.x) * 0.5);
              simpleObject.position.y = - bbox.min.y;
              simpleObject.position.z = -(bbox.min.z + (bbox.max.z - bbox.min.z) * 0.5);

              simpleObject.visible = false;

              // the simplified mesh is added to the scene when the larger mesh loads
              return Q();
          });
      }







      var tPromise = Q();

      if (modelComponents.materialURL) {
          promise = promise.then(function () {
              return Net.loadMTL(modelComponents.materialURL, {
                  texturePath: modelComponents.textureURL
              });
          }).then(function (materials) {
              materials.preload();

              return Net.loadOBJ(modelComponents.modelURL, {
                  materials:          materials,
                  onProgress:         onProgress,
                  onDownloadFinished: onDownloadFinished,
                  onParsing:          onParsing
              });
          });
      } else {
          if (modelComponents.textureURL) {
              tPromise = Net.loadTexture(modelComponents.textureURL);
          }

          promise = promise.then(function () {
              return Net.loadOBJ(modelComponents.modelURL, {
                  materials:          null,
                  onProgress:         onProgress,
                  onDownloadFinished: onDownloadFinished,
                  onParsing:          onParsing
              });
          });
      }







      promise.then(function (obj) {
          model = obj;

          autoKeepoutFinder.setModel(model.children[0]);

          return Q();
      }).catch(onError);



      let textureImg;

      Q.all([ tPromise, promise ]).spread(function (image) {
          textureImg = image;

          return project.details.segmentsUrl ?
              Net.loadAutoSegments(project.details.segmentsUrl) :
              Q();
      }).then(function (segmentData) {
          autoSegmentsData = segmentData;
          loader.show = false;

          onWindowResize();
          onLoad(modelComponents, model);

          if (urlParams.embed) {
              $('.start-screen')         .css('display', 'none');
              $('.project-header .close').css('display', 'block');

              embedLoadChanges();
              moduleSpecsTool.setFeatureVisibility(false);
              annotationsTool.annotations.forEach(a => a.sprite.visible = false);
          } else {
              guiReady(globalThis.viewer.showGUI);
          }

          if (textureImg) {
              texture             = new Texture();
              texture.image       = textureImg;
              texture.needsUpdate = true;

              model.traverse(function (child) {
                  if (child.type === "Mesh") {
                      child.material.map = texture;
                  }
              });
          }

          model.traverse(function(node) {
              if (node.material && node.material.map) {
                  occluderTool.setMaterials(node);
              }
          });

          // elevation heatmap does its own material manipulation and should get the model after it's set to use
          // occludable materials
          elevationHeatMap.addObject(model);
      }).done();

      window.addEventListener( 'resize', onWindowResize, false );

      document.body.addEventListener('mousemove',      onMouseMove,  false);
      container    .addEventListener('mouseup',        onMouseUp,    false);
      container    .addEventListener('mousedown',      onMouseDown,  false);
      container    .addEventListener('mousewheel',     onMouseWheel, false);
      container    .addEventListener('DOMMouseScroll', onMouseWheel, false); // firefox

      scene.add(camera);

      window.heap = window.heap || [], heap.load = function (e, t) {
        window.heap.appid = e, window.heap.config = t = t || {};
        var r = document.createElement("script");
        r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r, a);
        for (var n = function (e) {
            return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
            }
        },

        p = [
         "addEventProperties",
         "addUserProperties",
         "clearEventProperties",
         "identify",
         "resetIdentity",
         "removeEventProperty",
         "setEventProperties",
         "track",
         "unsetEventProperty"
        ],

         o = 0; o < p.length; o++)heap[p[o]] = n(p[o])
    };

    heap.load(Config.heapAnalyticsId);

    if (user) {
        heap.identify(user.id);
    }

    heap.addEventProperties({
        projectId: project.id,
        projectName: project.name
    });

    this.initUserpilot();

    animate();
    viewerInitialized = true;
  }

    async initUserpilot() {
        Userpilot.initialize(Config.userpilotToken);

        if (user) {
            const company = await Net.getLoggedInUserCompany();

            Userpilot.identify(
                user.id,
                {
                    name: [user.firstName, user.lastName].join(" "),
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    role: user.roles.join(", "),
                    created_at: user.createdAt,
                    company: {
                        id: company.id,
                        name: company.name,
                        pricingTier: company.pricingTier,
                        created_at: company.createdAt
                    }
                }
            );
        } else {
            Userpilot.anonymous();
        }
    }

  showGUI() {
    gui = new GUI();

    saveData = getSaveData() || {};

    // MEASUREMENTS
    if (Permissions.hasPermission(user, project, Permissions.DISTANCE)) {
        var measurementsToolGui = new MeasurementsToolGUI();

        (function (t, gui) {
            gui.registerGetState(() => t.getState());
            gui.registerUpdateState((s) => {
                t.setXray(s.xray);
                t.setShowOffsets(s.offsets);

                if (moduleSpecsTool)
                    moduleSpecsTool.showMidpointItems();
            });
            t.registerRenderState(() => gui.renderState());

            gui.init(() => toolSelector.enable(t));
        }) (measurementsTool, measurementsToolGui);
    }

    // PROTRACTOR
    if (Permissions.hasPermission(user, project, Permissions.ANGLE)) {
        gui.protractor(function () { toolSelector.enable(protractor); });
    }

    // VOLUME TOOL
    if (Permissions.hasPermission(user, project, Permissions.VOLUME)) {
        gui.volumeTool(function () { toolSelector.enable(volumeTool); });
        gui.volumeToolUnits('meters', function (value) {
            if (value === 'meters') {
                volumeTool.setUnits(volumeTool.unitTypes.METERS);
            } else {
                volumeTool.setUnits(volumeTool.unitTypes.YARDS);
            }
        });
        gui.volumeToolClear(function () { volumeTool.clear(); });
        volumeTool.setUpdateGui(function (values) {
            gui.volumeToolVolume(values.volume.toFixed(2));
            gui.volumeToolUnits(values.units === volumeTool.unitTypes.METERS ? 'meters' : 'yards');
        });
    }

    // AREA AND EXCAVATE
    if (Permissions.hasPermission(user, project, Permissions.AREA)) {
        gui.areaAndExcavate(function () { toolSelector.enable(areaAndExcavate); });
        gui.areaAndExcavateAreaUnits('meters', function (value) {
            if (value === 'meters') {
                areaAndExcavate.setAreaUnits(areaAndExcavate.unitTypes.METERS);
            } else {
                areaAndExcavate.setAreaUnits(areaAndExcavate.unitTypes.FEET);
            }

            gui.areaAndExcavateArea(areaAndExcavate.getArea().toFixed(2));
        });
        gui.areaAndExcavateVolumeUnits('meters', function (value) {
            if (value === 'meters') {
                areaAndExcavate.setVolumeUnits(areaAndExcavate.unitTypes.METERS);
            } else {
                areaAndExcavate.setVolumeUnits(areaAndExcavate.unitTypes.YARDS);
            }

            gui.areaAndExcavateVolume(areaAndExcavate.getVolume().toFixed(2));
        });
        gui.areaAndExcavateDepth(0, function (value) {
            var depth = parseFloat(value);

            if (!isNaN(depth)) {
                areaAndExcavate.setDepth(depth);
            } else {
                depth = areaAndExcavate.getDepth();
            }

            return depth;
        });
        gui.areaAndExcavateClear(function () { areaAndExcavate.clear(); });
        areaAndExcavate.setUpdateGui(function () {
            gui.areaAndExcavateArea(areaAndExcavate.getArea().toFixed(2));
            gui.areaAndExcavateVolume(areaAndExcavate.getVolume().toFixed(2));
        });
    }

    // ELEVATION HEAT MAP
    if (Permissions.hasPermission(user, project, Permissions.ELEVATION)) {
        gui.elevationHeatMap(function (min, max) {
            toolSelector.enable(elevationHeatMap);
            return elevationHeatMap.setThreshold(min, max);
        });
        gui.elevationHeatMapThreshold(function (min, max) { return elevationHeatMap.setThreshold(min, max); });
        gui.elevationHeatMap3D(function (show3d) { elevationHeatMap.setView3D(show3d); });
    }

    // SHADE TOOL
    if (Permissions.hasPermission(user, project, Permissions.SHADE)) {
        gui.shadeTool(function () { toolSelector.enable(shadeTool); });

        gui.shadeToolOpacity(1.0, function (value) {
            var opacity = parseFloat(value);

            if (!isNaN(opacity)) {
                shadeTool.setOpacity(opacity);
            } else {
                opacity = shadeTool.getOpacity();
            }

            return opacity;
        });

        gui.shadeToolLocation({
            mapParams:   function () { return shadeTool.getMapParams(); },
            setLocation: function (lat, lng, zoom) {
                shadeTool.setLatitude(lat);
                shadeTool.setLongitude(lng);
                shadeTool.setMapZoom(zoom);
                shadeTool.placeOnMap();
                shadeTool.setTimezone();
                shadeTool.calculateAzimuthElevation();
                shadeTool.moveLight();

                viewshedTool.setCoordinates(shadeTool.getLatitude(),
                                            shadeTool.getLongitude());

                viewshedTool.loadStationData().then(function (data) {
                    var UTCOffset = data.UTCOffset;

                    viewshedTool.setUTCOffset(UTCOffset);

                    gui.shadeToolWeatherStationCallback(data);
                }).done();

                gui.shadeToolTimezoneName(shadeTool.getTimezoneName());
            }
        });

        gui.shadeToolRotate((0).toFixed(1), function (value) {
            var degrees = parseFloat(value);

            if (!isNaN(degrees)) {
                shadeTool.setRotationDegrees(degrees);
            } else {
                degrees = shadeTool.getRotationDegrees();
            }

            return (Math.round(degrees * 10) / 10).toFixed(1);
        });

        gui.shadeToolLockMovement(false, function (lock) { shadeTool.setLockMovement(lock); });

        // update shade tool time
        gui.shadeToolTime(shadeTool.getTime(), function (value) {
            shadeTool.setTime(value);
            shadeTool.calculateAzimuthElevation();
            shadeTool.moveLight();

            return {
                azimuth:   shadeTool.getAzimuth(),
                elevation: shadeTool.getElevation()
            };
        });

        // update shade tool date
        gui.shadeToolDate(shadeTool.getDate(), function (date) {
            shadeTool.setDate(date);
            shadeTool.setTimezone();
            shadeTool.calculateAzimuthElevation();
            shadeTool.moveLight();

            viewshedTool.setYear(date.getFullYear());

            return {
                azimuth:   shadeTool.getAzimuth(),
                elevation: shadeTool.getElevation()
            };
        });

        shadeTool.setUpdateGui(function () {
            gui.shadeToolTimezoneName(shadeTool.getTimezoneName());
            gui.shadeToolRotate(      shadeTool.getRotationDegrees());
            gui.shadeToolLockMovement(shadeTool.getLockMovement());
        });
    }

    // ANNOTATIONS TOOL
    if (Permissions.hasPermission(user, project, Permissions.ANNOTATIONS)) {
        annotationsToolGui = new AnnotationsToolGUI({
            remove: (aid) => { annotationsTool.removeAnnotation(aid); }
        });

        (function (t, anGui) {
            anGui.registerGetState(   ()      => { return t.getState(); });
            anGui.registerUpdateState((state) => { t.updateState(state); });

            anGui.init(() => toolSelector.enable(t));

            t.registerPropagateState(() => { anGui.renderState(); });
        }) (annotationsTool, annotationsToolGui);

        // Vegetation
        gui.vegetationAnnotationsTool(function () { toolSelector.enable(treeVegetationAnnotation); });

        treeVegetationAnnotation.setUpdateGui(function (tool, annotations) {
            gui.vegetationAnnotationsToolUpdateAnnotations(tool, annotations);
        });

        treeVegetationAnnotation.setUpdateMarkerPositions(function (tool, annotations) {
            gui.vegetationAnnotationsToolUpdateMarkerPositions(tool, annotations);
        });
    }

    // MODULE SPECS TOOL
    moduleSpecsTool.onContextSelect = (options) => {
        const opt = {
            ...options,
            details:        project.details,
            projectName:    project.name,
            importedModels: modelImportTool.getModelsInfo(),
            project
        };

        dxfExportTool.exportParallelPlane(opt)
    };

    // MODEL IMPORT
    if (Permissions.hasPermission(user, project, Permissions.IMPORT_MODEL)) {
        var importUnits = 'meters';

        const updateGui = function (models, id, scale, focusRow, clearAllRows, pos) {
            var units = (measurementUnits == 1) ? "feet" : "meters"
            gui.updateRows(models, id, scale, focusRow, clearAllRows, pos, units);
            if(pos) {
                gui.vegetationAnnotationsToolFollowModelPosition(treeVegetationAnnotation, treeVegetationAnnotation.getAnnotations(), id, pos)
            }
        };

        modelImportTool.setUpdateGui(updateGui);

        gui.modelImport({
            init:       function (importOnly) {
                if(importOnly) {
                    toolSelector.enable(modelImportTool);
                } else {
                    toolSelector.enable(treeVegetationAnnotation);
                }
            },

            project_id: project.id,
            models:     project.details.additionalModels,
            setUnits:   function (units) { importUnits = units; },
            getUnits:   function ()      {
                var units = (measurementUnits == 1) ? "feet" : "meters"
                return units
            },
            updatePos: function(pos) { return treeVegetationAnnotation.container.localToWorld(pos.clone()) },
            import:    function (modelId) {
                Net.getProject(urlParams.ID).then(function (response) {
                    project.details.additionalModels = response.details.additionalModels;

                    gui.modelImportUpdate({ models: project.details.additionalModels });

                    var model = getAdditionalModel(project, modelId);

                    if (model) {
                        loadAdditionalModel(model).then(function (obj) {
                            modelImportTool.scaleToUnits(obj, model.units);

                            var modelObj = modelImportTool.addModel(modelId, obj);

                            modelImportTool.setSelection(modelObj, true);

                            initToolsWithAdditionalModel(modelObj);
                        }).done();
                    } else {
                        modelList.error(modelId, 'Failed to process model');
                    }
                }).done();
            },
            importVegetation:     function (key, model, libModels, sceneObjs, imperial) {
                loadAdditionalModel(model).then(function (obj) {
                    for (const k in sceneObjs) {
                        if (sceneObjs.hasOwnProperty(k)) {
                            for (const annotationRef in sceneObjs[k]["annotationRefs"]) {
                                if (sceneObjs[k]["annotationRefs"].hasOwnProperty(annotationRef)) {
                                    const pos           = sceneObjs[k]["annotationRefs"][annotationRef]["pos"]
                                    const scale         = imperial ? ({x: 0.3048, y: 0.3048, z: 0.3048}) : sceneObjs[k]["annotationRefs"][annotationRef]["scale"]
                                    const alreadyPlaced = sceneObjs[k]["annotationRefs"][annotationRef]["placed"]
                                    const objCopy = obj.clone()

                                    if(!alreadyPlaced) {
                                        sceneObjs[k]["annotationRefs"][annotationRef]["placed"] = true
                                        // Obj scaled appropriately in addModel
                                        const modelObj = modelImportTool.addModel(
                                            annotationRef, objCopy, pos, null, scale, null, "library"
                                        );

                                        sceneObjs[k]["annotationRefs"][annotationRef]["imperial"] = imperial

                                        // Calculating twice
                                        // var size = modelImportTool.calculateSize(modelObj)
                                        // sceneObjs[k]["annotationRefs"][annotationRef]["size"] = size

                                        modelObj.userData = key
                                        modelImportTool.setSelection(modelObj, false);

                                        initToolsWithAdditionalModel(modelObj)
                                    }
                                }
                            }
                        }
                    }

                    modelImportTool.resetSelections()
                }).catch(function (error) {
                    console.log("Error");
                    console.log(error);
                })
            },
            setSelection: function(modelObj, val, id, allModels) {
                modelImportTool.setSelection(modelObj, val, id, allModels);
            },
            vegetationSeeds: treeVegetationAnnotation.annotations,
            vegetationSeeds: function() { return treeVegetationAnnotation.getAnnotations() },
            removeSeeds: function() { treeVegetationAnnotation.removeAnnotations() },
            removeVegAnnotation: function(id) { treeVegetationAnnotation.removeVegAnnotation(id) },
            saveVegAnnotation: function(id, data) { treeVegetationAnnotation.saveVegAnnotation(id, data) },
            resizeHeightWidth: function(id,h,w,d) { modelImportTool.resizeHeightWidth(id,h,w,d); },
            toggleModel: function(id) { modelImportTool.toggleModel(id) },

            move:   function ()   { modelImportTool.move(); },
            rotate: function ()   { modelImportTool.rotate(); },
            scale:  function ()   { modelImportTool.scale(); },

            delete: function (id) {
                project.details.additionalModels = project.details.additionalModels.filter(function (m) {
                    return m.id !== id;
                });

                modelImportTool.delete(id);
            },
            deleteSelected: function (idsToDelete) {
                project.details.additionalModels = project.details.additionalModels.filter(function (m) {
                    return !idsToDelete.has(m["id"])
                });

                modelImportTool.deleteSelected();
            }
        });
    }

    // DE-TILT TOOL
    if ( Permissions.isScaniflyAdmin(user) ) {
        const callbacks = new DeTiltToolGUICallbacks();
        callbacks.enableCallback = () => { toolSelector.enable(deTiltTool) };
        callbacks.executeDeTiltCallback = () => { deTiltTool.deTilt(); };
        callbacks.executeResetCallback = () => { deTiltTool.reset(); };
        deTiltToolGui = new DeTiltToolGUI(callbacks);
        deTiltTool.registerPropagateState( () => { deTiltToolGui.renderState(deTiltTool.getState()); } );
    }

    // ADMIN USER
    if (Permissions.hasPermission(user)) {
        gui.enableAdminZ( (enable) => {
            shadeTool.enableAdminZ(enable, renderer.domElement);
        })
    }



/*
    if (Permissions.hasPermission(user, project, Permissions.PROJECT_INFO)) {
        if (project.ownerDetails === undefined)
            project.ownerDetails = {};

        if (project.address === undefined)
            project.address = {};

        guiProjInfo = new ProjInfoGUI({
            project:                project,
            getProductionValues:    ()              => moduleSpecsTool.getProductionValues(),
            getSystemParams:        (planeId)       => moduleSpecsTool.getSystemParams(planeId),
            getDefaultSystemParams: ()              => moduleSpecsTool.getDefaultSystemParams(),
            updateSystemParams:     (planeId, k, v) => moduleSpecsTool.updateSystemParams(planeId, k, v),
            calculateLosses:        (planeId)       => moduleSpecsTool.calculateLosses(planeId),
            getWeatherStationInfo:  (callback)      => viewshedTool.loadStationData().then(callback).done(),
            getRoofPlaneInfo:       ()              => {
                return {
                    planes:  viewshedTool.getRoofPlaneInfo(),
                    modules: moduleSpecsTool.getModuleInfos()
                };
            },
            refresh: () => sidebarGui.renderState()
        });
    }*/


    gui.show();


    toolSelector.enable(moduleSpecsTool);

    // load location from shade tool regardless of permissions
    if (shadeTool.setLocation(saveData.shadeTool, project.details.boundingBox)) {
        let p = Permissions;

        if (    p.hasPermission(user, project, p.SHADE) &&
                p.hasPermission(user, project, p.VIEWSHEDS)) {

            viewshedTool.setCoordinates(shadeTool.getLatitude(),
                                        shadeTool.getLongitude());

            viewshedTool.loadStationData().then(function (data) {
                viewshedTool.setUTCOffset(data.UTCOffset);

                gui.shadeToolWeatherStationCallback(data);
            }).done();
        }
    }

    shareTool = new ShareTool({ project, currentDesign, user });

    // quick DEV setup
    if (location.host === 'localhost:8008') {
        //toggleStats();

        //controls.dollyIn(3);
        //controls.rotateLeft(Math.PI);
        //controls.rotateUp(Math.PI/7);
    } // DEV

    let startOver = (urlParams.l === '0');

    if (!this.viewerChangesLoaded) {
        let saveData = getSaveData() || { };

        if (!startOver) {
            loadChanges(saveData, gui);
        }
    }

    const selectedPID = moduleSpecsTool.planes.length - 1;

    if (selectedPID >= 0) {
        moduleSpecsTool.switchPlane(selectedPID);
        viewshedTool.setCurrentRoofPlane(selectedPID);
    }

    var versions = new Versions();

    guiModuleLibrary = new ModuleLibraryGUI({
        refresh: () => sidebarGui.renderState()
    });
    guiModuleLibrary.init();

    var sidebarGui = new SidebarGUI({
        moduleSpecsTool: moduleSpecsTool,
        viewshedTool:    viewshedTool,
        shareTool:       shareTool,
        versions:        versions,
        occluderTool:    occluderTool,
        selectOutline:   selectedPID,
        getState: () => {
            return {
                array:         moduleSpecsTool.getState(),
                viewsheds:     viewshedTool.getState(),
                images:        (project.medias && project.medias.length)
                               ? project.medias
                               : project.details.droneImages.eyeView,
                moduleLibrary: moduleLibrary.modules,
                share:         shareTool.getState(),
                projectName:   project.name,
                occluders:     occluderTool.getState(),
                currentDesign,
                designs
            }
        },
        back: () => {
            window.top.location.href = Config.portalURL + '/projects-list';
        },
        help:              () => guiHelpTool.show(),
        openModuleLibrary: () => guiModuleLibrary.openLibrary(),
    });

    sidebarGui.init();
    sidebarGui.selectSegmentOutlines(selectedPID);
    sidebarGui.renderState();

    var headerGUI = new HeaderGUI({
        tool: new ImageTool(),
        getState: () => {
            return {
                project:    project,
                user:       user,
                projection: camera.projectionMode
            };
        },
        switchUnits: (val) => {
            var units = val ? MeasurementUnits.METERS : MeasurementUnits.FEET_INCHES;

            measurementUnits = units;

            measurementsTool.setUnits(units);
            volumeTool      .setUnits(units);
            elevationHeatMap.setUnits(units);

            dxfExportTool.updateState({ units: units });

            gui.elevationHeatMapThresholdBounds(elevationHeatMap.getThresholdBounds());
            gui.updateRowModelUnits(units);

            moduleSpecsTool.updateDimensions();
            moduleSpecsTool.showMidpointItems();

            sidebarGui.renderState();
        },
        showDimensions: (val) => {
            moduleSpecsTool.showDimensions = val;
            moduleSpecsTool.updateDimensions();
        }, selectMode: (val) => {
            moduleSpecsTool.selectMode = val;
            if(moduleSpecsTool.selectMode){

                const onPlaneHoverCb = (pid) => {
                    sidebarGui.hoverSegmentOutlines(pid);
                };

                const onPlaneHoverLeaveCb = () => {
                    sidebarGui.removeHoverSegmentOutlines();
                };

                moduleSpecsTool.startGuiHover(onPlaneHoverCb);
                moduleSpecsTool.endGuiHover(onPlaneHoverLeaveCb);



               const onPlaneSelectCb = (pid, ctx) => {
                    moduleSpecsTool.switchPlane(pid);
                    moduleSpecsTool.propagateState();
               };

               moduleSpecsTool.startPlaneHighlight(onPlaneSelectCb);

             } else {
                moduleSpecsTool.resetMode();
             }

        },
        switchProjection: (val) => {
            if (val)
                camera.toOrthographic();
            else
                camera.toPerspective();
        },
        getMainSplit:      () => sidebarGui.mainSplit,
        openModuleLibrary: () => guiModuleLibrary.openLibrary()
    });

    headerGUI.init();

    moduleSpecsTool.registerPropagateState(() => {
        sidebarGui.selectSegmentOutlines(moduleSpecsTool.planeId);
        sidebarGui.renderState();
    });

    occluderTool.registerPropagateState(() => {
        sidebarGui.renderState();
    });

    versions.registerPropagateState(() => {
        sidebarGui.renderState();
    });

    // navigation
    compassTool = new CompassTool({ camera, controls });

    compassTool.registerPropagateState(() => {
        headerGUI.renderState();
    });

    document.querySelector('.nav-container').appendChild(compassTool.domElement);

    var midpointInputsGUI = new MidpointInputsGUI({
        tool: moduleSpecsTool,
        getState: () => {
            let s = moduleSpecsTool.getState();

            return {
                pid:          s.pid,
                fireSetbacks: s.fireSetbacks,
                parapets:     s.parapets
            }
        }
    });

    midpointInputsGUI.init();

    moduleSpecsTool.registerRenderMidpointItems(dim => midpointInputsGUI.render(dim));

    viewshedTool.registerPropagateState(() => {
        let s = viewshedTool.getState();
        let viewsheds = s.viewsheds[s.pid];

        gui.generateShadeReport(viewsheds ? viewsheds.length : 0);
        toolSelector.enable(moduleSpecsTool);

        const pid = moduleSpecsTool.planeId;

        if (pid !== undefined) {
            moduleSpecsTool.switchPlane(pid);
        }

        sidebarGui.renderState();
    });

    // give viewshed tool access to module specs meshes for viewsheds
    viewshedTool.moduleSpecsMeshes = function() {
        return moduleSpecsTool.getModuleMeshes();
    }

    segmentSync = new SelectedSegmentSync({
        modSpec:moduleSpecsTool,
        viewTool:viewshedTool
    });

    onWindowResize();
  }
}

function getAdditionalModel(project, id, type) {
  for (var i = 0; i < project.details.additionalModels.length; i++) {
      if (project.details.additionalModels[i].id === id){
          return project.details.additionalModels[i];
      }
  }

  return null;
}

function loadAdditionalModel(model) {
  var deferred = Q.defer();

  var textureAdditional;
  var promise;

  if (model.materialUrl) {
      promise = Net.loadMTL(model.materialUrl, {
          texturePath: model.textureUrl
      }).then(function (materials) {
          materials.preload();

          return Net.loadOBJ(model.url, {
              materials:          materials,
              onProgress:         function () {},
              onDownloadFinished: function () {},
              onParsing:          function () {}
          });
      });
  } else {
      promise = Net.loadOBJ(model.url, {
          materials:          null,
          onProgress:         function () {},
          onDownloadFinished: function () {},
          onParsing:          function () {}
      });

      // no mtl file, see if there's a single texture
      if (model.textureUrl) {
          Net.loadTexture(model.textureUrl).then(function (image) {
              textureAdditional             = new Texture();
              textureAdditional.image       = image;
              textureAdditional.needsUpdate = true;
          });
      }
  }

  promise.then(function (obj) {
      if (textureAdditional) {
          obj.traverse(function (child) {
              if (child.type === "Mesh") {
                  child.material.map = textureAdditional;
              }
          });
      }

      deferred.resolve(obj);
  }).catch(function (error) {
      deferred.reject(new Error(error));
  });

  return deferred.promise;

}

function initToolsWithAdditionalModel(model) {
  model.traverse(function (child) {
      if (child.type === "Mesh") {
          measurementsTool        .addMesh(child);
          protractor              .addMesh(child);
          viewshedTool            .addMesh(child);
          moduleSpecsTool         .addMesh(child);
          occluderTool            .addMesh(child);
          annotationsTool         .addObject(child);
          treeVegetationAnnotation.addObject(child);
      }
  });

  elevationHeatMap.addObject(model);
}

function onLoad(modelComponents, object) {
    if (!modelComponents.kmlURL) { // hacky way to check for kmz upload
        object.rotateX(-Math.PI/2);
    }

    // center the object in the scene
    var bbox = new Box3().setFromObject(object);
    object.position.x = -(bbox.min.x + (bbox.max.x - bbox.min.x) * 0.5);
    object.position.y = -bbox.min.y;
    object.position.z = -(bbox.min.z + (bbox.max.z - bbox.min.z) * 0.5);

    object.traverse(function (child) {
        if (child.type === "Mesh") {
            mesh = child;

            // TODO: face count is wrong if vertices are indexed
            let posAttr = mesh.geometry.getAttribute('position');
            console.log(`Vertices: ${posAttr.count}, Faces: ${posAttr.count/3}`);

            // TODO: vertex/face count should come from the processing pipeline, not a viewer calc
            // and especially not from a viewer calc done here
            if(project) {
                project.meshData = { vertices: posAttr.count, faces: posAttr.count/3 }
            }

            // our models are not watertight and will not display properly with front or back only
            mesh.material.side = DoubleSide;

            mesh.receiveShadow = true;
            mesh.castShadow = true;

            measurementsTool.addMesh(mesh);
            protractor      .addMesh(mesh);
            viewshedTool    .addMesh(mesh);
            moduleSpecsTool .addMesh(mesh);
            occluderTool    .addMesh(mesh);
            deTiltTool      .addMesh(mesh);
            volumeTool      .setMesh(mesh);
            areaAndExcavate .setMesh(mesh);

            if (!modelComponents.simpleModelURL) {
                // if there's no simple model, use full model for annotations
                // direct 3d model uploads don't have simple models
                annotationsTool.addObject(mesh);
                treeVegetationAnnotation.addObject(mesh);
            }
        }
    });

    var wrapped = shadeTool.setObject(object);

    // add the simplified mesh
    if (simpleObject) {
        wrapped.children[0].add(simpleObject);
    } else {
        console.warn('No simplified mesh found');
    }

    annotationsTool         .setContainerObject(wrapped.children[0]);
    treeVegetationAnnotation.setContainerObject(wrapped.children[0]);
    modelImportTool         .setContainerObject(wrapped.children[0]);
    moduleSpecsTool         .setContainerObject(wrapped);
    volumeTool              .setObject(object);
    areaAndExcavate         .setObject(object);

    scene.add(wrapped);

    if (modelComponents.kmlURL) {
        Net.getKML(modelComponents.kmlURL).then(function (kml) {
            var $xml = $(kml);

            var lat     = $xml.find('Model latitude') .text();
            var lng     = $xml.find('Model longitude').text();
            var heading = $xml.find('Model heading')  .text();

            var dim = Math.max(bbox.max.x - bbox.min.x, bbox.max.y - bbox.min.y);

            shadeTool.setKML(parseFloat(lat), parseFloat(lng), parseFloat(heading) + 180, dim);
        });
    }

    return bbox;
}


function onProgress(xhr) {
    if (xhr.lengthComputable) {
        loader.determinate = false;
        loader.percent = xhr.loaded / xhr.total;
    }
}

function onDownloadFinished() {
    loader.determinate = true;
    loader.description = 'Parsing Model...';
}

function onParsing(progress) {
    // last progress value always seems to be around 0.92
    loader.percent = 8 + progress * 100;
}

function onError(e) {
    console.log(e);
    prompts.info('Model file format is not recognized.');
}


function toggleStats() {
    if (stats) {
        container.removeChild(stats.domElement);
        stats = null;
    } else {
        stats = new Stats();
        container.appendChild(stats.domElement);
    }
}

function updateStats() {
    if (stats) {
        stats.update();
    }
}


function onWindowResize() {
    let { w, h } = SidebarGUI.canvasSize;

    renderer.setSize(w, h, false);

    // background
    bgCamera.left   = -w/2;
    bgCamera.right  =  w/2;
    bgCamera.top    =  h/2;
    bgCamera.bottom = -h/2;

    bgCamera.updateProjectionMatrix();

    updateBG(w, h);

    // main camera
    camera.aspect = w / h;
    camera.updateProjectionMatrix();

    cameraOrtho.left   = -w/2;
    cameraOrtho.right  =  w/2;
    cameraOrtho.top    =  h/2;
    cameraOrtho.bottom = -h/2;

    cameraOrtho.updateProjectionMatrix();

    if (moduleSpecsTool)
        moduleSpecsTool.showMidpointItems();
}

function updateBG(width, height) {
    if (bgTexture === undefined)
        return;

    if (bg) {
        scene.remove(bg);
    }

    var material = new MeshBasicMaterial({
        map:   bgTexture,
        color: 0xffffff,
        side:  DoubleSide
    });

    var ar = bgTexture.image.height / bgTexture.image.width;
    var bgW, bgH;

    if (height / width >= ar) { // compare aspect ratios of the bg texture and screen
        bgH = height;
        bgW = bgH / ar;
    } else {
        bgW = width;
        bgH = bgW * ar;
    }

    var geometry = new PlaneGeometry(bgW, bgH);
    bg = new Mesh(geometry, material);

    bgScene.add(bg);
}


function render() {
    renderer.clear();

    if (toolSelector.getCurrentlyEnabled().length > 0) {
        if (toolSelector.isEnabled(annotationsTool) || toolSelector.isEnabled(elevationHeatMap)) {
            /** @type {AnnotationsTool | ElevationHeatMap} */
            let t = toolSelector.getCurrentlyEnabled()[0];

            let left = t.getRotateLeftAngle();
            let up   = t.getRotateUpAngle();

            if (left != 0 || up != 0) {
                controls.rotateLeft(left);
                controls.rotateUp(up);

                if (toolSelector.isEnabled(annotationsTool)) {
                    annotationsTool.popupOnly = true;
                    annotationsTool.propagateState();
                }
            }
        }

        modelImportTool.render();
        shadeTool.render();
    }

    renderer.render(bgScene, bgCamera);

    renderer.clearDepth();
    renderer.render(scene, camera);

    renderer.clearDepth();
    renderer.render(sceneOrtho, cameraOrtho);
}


function animate() {
    requestAnimationFrame(animate);

    if(viewUpdate) {
        if(moduleSpecsTool) {
            moduleSpecsTool.showMidpointItems();
            viewUpdate = false
        }
    }

    render();

    controls.update();
    keyboard.update();

    updateStats();

    if (treeVegetationAnnotation.updateMarkerPositions) {
        treeVegetationAnnotation.updateMarkerPositions(treeVegetationAnnotation, treeVegetationAnnotation.annotations);
    }

    measurementsTool        .handleKeyboard(keyboard);
    protractor              .handleKeyboard(keyboard);
    volumeTool              .handleKeyboard(keyboard);
    areaAndExcavate         .handleKeyboard(keyboard);
    shadeTool               .handleKeyboard(keyboard);
    annotationsTool         .handleKeyboard(keyboard);
    treeVegetationAnnotation.handleKeyboard(keyboard);
    viewshedTool            .handleKeyboard(keyboard);
    modelImportTool         .handleKeyboard(keyboard);
    deTiltTool              .handleKeyboard(keyboard);

    moduleSpecsTool.onEachFrame();
    occluderTool   .onEachFrame();

    if (compassTool)
        compassTool.onEachFrame();

    msKeyboardControls.onKeyboardUpdate(keyboard);
}

function onMouseDown(event) {
    event.preventDefault();

    // compass interaction takes priority over other tools and can stop propagation
    if (compassTool && compassTool.mouseDown(event, vMouse, panning))
        return;

    var panning = controls.isRotating() || controls.isPanning();

    if (model)
        protractor.mouseDown(event, vMouse, model.matrixWorld, panning);

    shadeTool               .mouseDown(event, vMouse, panning);
    annotationsTool         .mouseDown(event, vMouse, panning);
    treeVegetationAnnotation.mouseDown(event, vMouse, panning);
    moduleSpecsTool         .mouseDown(event, vMouse, panning);
    modelImportTool         .mouseDown(event, vMouse, panning);
    occluderTool            .mouseDown(event, vMouse, panning);
    deTiltTool              .mouseDown(event, vMouse, panning);
}

function onMouseUp(event) {
    event.preventDefault();

    // compass interaction takes priority over other tools and can stop propagation
    if (compassTool && compassTool.mouseUp(event, vMouse, panning))
        return;

    var panning = controls.isRotating() || controls.isPanning();

    measurementsTool.mouseUp(event, vMouse, panning);
    volumeTool      .mouseUp(event, vMouse, panning);
    areaAndExcavate .mouseUp(event, vMouse, panning);
    shadeTool       .mouseUp(event, vMouse, panning);
    moduleSpecsTool .mouseUp(event, vMouse, panning);
    viewshedTool    .mouseUp(event, vMouse, panning);
    modelImportTool .mouseUp(event, vMouse, panning);
    deTiltTool      .mouseUp(event, vMouse, panning);
}

function onMouseMove(event) {
    event.preventDefault();

    var panning = controls.isRotating() || controls.isPanning();

    // translate screen coordinates to world coordinates
    var container3d = document.getElementById('container3d');
    var rect = container3d.getBoundingClientRect();

    var x = event.clientX - rect.x;
    var y = event.clientY - rect.y;
    var w = container3d.clientWidth;
    var h = container3d.clientHeight;

    vMouse.x =   (x / w) * 2 - 1;
    vMouse.y = - (y / h) * 2 + 1;

    measurementsTool            .mouseMove(vMouse, panning);
    protractor                  .mouseMove(vMouse, panning);
    volumeTool                  .mouseMove(vMouse, panning);
    areaAndExcavate             .mouseMove(vMouse, panning);
    shadeTool                   .mouseMove(vMouse, panning);
    annotationsTool             .mouseMove(vMouse, panning);
    treeVegetationAnnotation    .mouseMove(vMouse, panning);
    moduleSpecsTool             .mouseMove(vMouse, panning, event);
    viewshedTool                .mouseMove(vMouse, panning);
    modelImportTool             .mouseMove(vMouse, panning);
    deTiltTool                  .mouseMove(vMouse, panning);

    if (compassTool)
        compassTool.mouseMove(vMouse, panning, event);
}

function onMouseWheel(event) {
    measurementsTool.mouseWheel(vMouse);
    annotationsTool .mouseWheel(vMouse);
    moduleSpecsTool .mouseWheel(vMouse);
    viewshedTool    .mouseWheel(vMouse);
}

/**
 * @template {'status' | 'progress'} EventType
 * @param {EventType} name
 * @param {EventType extends 'status' ? {message: string} : {percent: number}} data
 */
function emitEvent(name, data) {
    document.body.dispatchEvent(new CustomEvent(name, { detail: data }));
}
const viewer = new VIEWER();
export {
  viewer,
  emitEvent,
  onMouseWheel,
  onMouseMove,
  onMouseUp,
  onMouseDown,
  animate,
  render,
  updateBG,
  onWindowResize,
  updateStats,
  toggleStats,
  onError,
  onParsing,
  onDownloadFinished,
  onProgress,
  onLoad,
  initToolsWithAdditionalModel,
  loadAdditionalModel,
  getAdditionalModel,
  saveData,
  modelList,
  screenshot,
  viewUpdate,
  cubeOffset,
  nIcon,
  northArrow,
  dir,
  sph,
  bottom,
  topSprite,
  compass,
  homeSprite,
  outlinePass,
  composer,
  measurementUnits,
  user,
  project,
//  saves,
  moduleLibrary,
  autoSegmentsData,
  urlParams,
  mouseHandler,
  keyboard,
  loader,
  panning,
  model,
  texture,
  simpleObject,
  prompts,
  guiProjInfo,
  guiHelpTool,
  guiModuleLibrary,
  annotationsToolGui,
  msKeyboardControls,
  pvWattsClient,
  volumeTool,
  occluderTool,
  viewshedTool,
  moduleSpecsTool,
  measurementsTool,
  protractor,
  shadeTool,
  treeVegetationAnnotation,
  dxfExportTool,
  areaAndExcavate,
  modelImportTool,
  compassTool,
  csvExportTool,
  shareTool,
  elevationHeatMap,
  annotationsTool,
  toolSelector,
  bgCamera,
  bgScene,
  bg,
  bgTexture,
  cameraOrtho,
  sceneOrtho,
  mesh,
  vMouse,
  cubeScene,
  controls,
  renderer,
  raycaster,
  scene,
  camera,
  container,
  stats,
  viewerInitialized,
  DEFAULT_MAP_ZOOM,
  DEFAULT_LNG,
  DEFAULT_LAT,
  currentDesign,
  designs,
  deTiltTool,
};
