/// @ts-check

import { Matrix4, Vector3 } from '../libs/three.module';

class BasisAlignedBoundingBox {
    /**
     * @param {Matrix4} basis Basis matrix for coordinate frame the bounding box
     *   should be computed in. This is the local to world matrix.
     */
    constructor(basis) {
        this.basisToWorld = basis;
        this.worldToBasis = (new Matrix4()).getInverse(this.basisToWorld);

        /** @type {Vector3?} */
        this.lowerLeftBottom = null;

        /** @type {Vector3?} */
        this.extent = null;
    }

    /** @param {Vector3[]} points */
    grow(...points) {
        for (const point of points) {
            const pointInBasis = point.clone().applyMatrix4(this.worldToBasis);

            if (!this.lowerLeftBottom || !this.extent) {
                this.lowerLeftBottom = pointInBasis;
                this.extent = new Vector3();
                continue;
            }

            const newLowerLeftBottom = pointInBasis.clone().min(this.lowerLeftBottom);
            const newUpperRightTop = this.upperRightTop.max(pointInBasis);

            this.lowerLeftBottom = newLowerLeftBottom;
            this.extent = newUpperRightTop.sub(newLowerLeftBottom);
        }

        return this;
    }

    get upperRightTop() {
        return this.lowerLeftBottom && this.extent
            ? this.lowerLeftBottom.clone().add(this.extent)
            : null;
    }

    get worldLowerLeftBottom() {
        return this.lowerLeftBottom
            ? this.lowerLeftBottom.clone().applyMatrix4(this.basisToWorld)
            : null;
    }

    get worldExtent() {
        const wurt = this.worldUpperRightTop;

        return wurt ? wurt.sub(this.worldLowerLeftBottom) : null;
    }

    get worldUpperRightTop() {
        const urt = this.upperRightTop;
        return urt ? urt.applyMatrix4(this.basisToWorld) : null;
    }
}


export { BasisAlignedBoundingBox };
