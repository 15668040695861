
import { LinearFilter, Object3D, PerspectiveCamera, RGBAFormat, Vector3, WebGLRenderTarget } from './three.module';
class FisheyeLens extends Object3D {
    constructor(near, far) {
        super();

        this.type = 'FisheyeLens';

        var fov = 90;
        var aspect = 1;
        var res = 256;

        // CAMERAS
        this.cameras = [];

        var camera = new PerspectiveCamera(fov, aspect, near, far);
        camera.lookAt(new Vector3(0, -1, 0));
        this.add(camera);
        this.cameras.push(camera);

        var camera = new PerspectiveCamera(fov, aspect, near, far);
        camera.lookAt(new Vector3(0, 0, 1));
        camera.rotation.z = Math.PI / 2;
        this.add(camera);
        this.cameras.push(camera);

        var camera = new PerspectiveCamera(fov, aspect, near, far);
        camera.lookAt(new Vector3(0, 0, -1));
        camera.rotation.z = Math.PI / 2;
        this.add(camera);
        this.cameras.push(camera);

        var camera = new PerspectiveCamera(fov, aspect, near, far);
        camera.lookAt(new Vector3(1, 0, 0));
        camera.rotation.z = Math.PI / 2;
        this.add(camera);
        this.cameras.push(camera);

        var camera = new PerspectiveCamera(fov, aspect, near, far);
        camera.lookAt(new Vector3(-1, 0, 0));
        camera.rotation.z = Math.PI / 2;
        this.add(camera);
        this.cameras.push(camera);

        // RENDER TARGETS
        var renderTarget = new WebGLRenderTarget(res, res, {
            format: RGBAFormat,
            magFilter: LinearFilter,
            minFilter: LinearFilter
        });

        //renderTarget.texture.wrapS = RepeatWrapping;
        //renderTarget.texture.repeat.x = -1;
        this.renderTargets = [];
        this.renderTargets.push(renderTarget);
        this.renderTargets.push(renderTarget.clone());
        this.renderTargets.push(renderTarget.clone());
        this.renderTargets.push(renderTarget.clone());
        this.renderTargets.push(renderTarget.clone());

        this.render = function (renderer, scene) {
            for (var i = 0; i < 5; i++) {
                renderer.render(scene, this.cameras[i], this.renderTargets[i], true);
            }
        };
    }
}


export { FisheyeLens };
