import { emitEvent, prompts, init } from '../Viewer.js';
import { SidebarGUI } from './GuiSidebar.js';
import { Format } from './Format';
import { TreeGUI } from './GuiTree';
import { VersionsGUI } from './GuiVersions';


import tippy from 'tippy.js';
import Split from 'split.js';


class SystemTreeGUI {
    /**
     * @param {object} options
     * @param {() => any} options.getState
     * @param {Versions} options.versions
     * @param {ModuleSpecsTool} options.moduleSpecsTool
     * @param {ViewshedTool} options.viewshedTool
     * @param {(panel: unknown) => void} options.swapPanel
     * @param {string} options.selector
     */
    constructor(options) {
        this.options = options;

        this.getState = options.getState;

        this.collapsedPlanes = [];
        this.collapsedGlobals = {
            'all':       true,
            'occluders': true
        };

        this.versions = new VersionsGUI({
            selector: '#versions-container',
            tool:     this.options.versions,
            getState: this.getState
        });

        this.tree = new TreeGUI({
            selector: '#my-tree',
            collapse: (data) => {
                if (data.pid !== undefined || data.parent) {
                    const pid = (data.pid !== undefined) ? data.pid : data.parent.pid;
                    this.collapsedPlanes[pid] = data.collapsed;
                } else {
                    this.collapsedGlobals[data.show] = data.collapsed;
                }
            },
            select:  (data) => {
                this.selected.onDeselect && this.selected.onDeselect();

                if (data.oid !== undefined) {
                    // occluder node
                    this.select(data.oid, data.nodeType, data.onDeselect);
                    this.options.occluderTool.cmd('select', data.oid);
                    this.options.moduleSpecsTool.cmd('selectNoPlanes');
                    this.renderState();
                } else if (data.pid !== undefined || (data.parent && data.parent.pid !== undefined)) {
                    // segment and children node
                    const pid = (data.pid !== undefined) ? data.pid : data.parent.pid;

                    this.select(pid, data.nodeType, data.onDeselect);

                    // last tool getting the command remains enabled
                    this.options.occluderTool.cmd('select', -1);

                    const s = this.getState();

                    this.options.viewshedTool.cmd('switchPlane', pid);
                    this.options.viewshedTool.cmd('setAzimuthAndTilt', {
                        tilt:    s.array.planes[pid].tilt,
                        azimuth: s.array.planes[pid].azimuth
                    });

                    this.options.moduleSpecsTool.cmd('switchPlane', pid);

                    emitEvent('status', {message: 'Select array type and fill modules.'});
                } else {
                    // global node
                    this.options.occluderTool.cmd('select', -1);
                    this.options.moduleSpecsTool.cmd('selectAllPlanes');
                    emitEvent('status', {message: 'View all segments'});
                }

                if (data.widget) {// Can add message for all segments node here
                    this.panel = data.widget;
                    this.options.swapPanel(this.panel);
                }

                data.onSelect && data.onSelect();
            },
            checked: (checked, data) => {
                if (data.oid !== undefined) {
                    this.options.occluderTool.cmd('show', {
                        oid:  data.oid,
                        show: checked
                    });
                } else {
                    let pid;

                    if (data.pid !== undefined || data.parent) {
                        pid = (data.pid !== undefined) ? data.pid : data.parent.pid;
                    }

                    this.options.moduleSpecsTool.cmd('show', {
                        item: data.show,
                        pid:  pid,
                        show: checked
                    });

                    this.options.viewshedTool.cmd('show', {
                        item: data.show,
                        pid:  pid,
                        show: checked
                    });
                }

                this.tree.renderState();
            },
            rename: (newName, data) => {
                if (newName) {
                    if (data.oid !== undefined) {
                        this.options.occluderTool.cmd('rename', {
                            oid: data.oid,
                            newName
                        });
                    } else {
                        const renameArgs = { pid: data.pid, newName };
                        this.options.moduleSpecsTool.cmd('renamePlane', renameArgs);
                        this.options.viewshedTool.cmd('renamePlane', renameArgs);
                    }
                }

                this.tree.renderState();
            },
            delete: (data) => {
                if (data.oid !== undefined) {
                    this.options.occluderTool.cmd('remove', data.oid);

                    this.select(-1, 'occluder');
                    this.panel = 'occludersWidget';

                    this.options.swapPanel(this.panel);
                } else {
                    this.options.moduleSpecsTool.cmd('removePlane', data.pid);
                    this.options.viewshedTool   .cmd('removePlane', data.pid);

                    this.collapsedPlanes.splice(data.pid, 1);

                    const s = this.getState();

                    if (s.array.planes.length > 0) {
                        this.select(s.array.pid, 'outlines');
                        this.panel = 'outlinesWidget';
                    } else {
                        // last segment deleted
                        this.select(-1, 'outlines');
                        this.panel = undefined;
                    }

                    this.options.swapPanel(this.panel);

                    // re-render versions widget to update system size and qty
                    this.versions.renderState();
                }

                this.tree.renderState();
            },
            hover: (toggle,data) =>{
                if(!this.options.moduleSpecsTool.selectMode) {
                    return;
                }

                this.options.moduleSpecsTool.isInGuiHover = toggle;
                if (data.pid !== undefined) {
                    const pid = data.pid;
                    this.options.moduleSpecsTool.cmd('manualSegmentHighlight', { toggle, pid });
                } else if (data.parent !== undefined) {
                    const pid = data.parent.pid;
                    pid != undefined ? this.options.moduleSpecsTool.cmd('manualSegmentHighlight', { toggle, pid }): null;
                }
            },
            getState: () => {
                const s = this.getState();
                return this.getTreeState(s);
            }
        });

        this.select(-1, 'outline');
        this.panel = 'outlinesWidget';
    }


    init() {
        // collapse all but last plane initially
        const s = this.getState();

        for (let pid = 0; pid < s.array.planes.length; pid++) {
            if (pid !== s.array.pid)
                this.collapsedPlanes[pid] = true;
        }

        this.versions.init();
        this.tree.init();

        const self = this;

        $('body').on({
            mouseenter: function() {
                $(this).find('.segment-dropdown-content').fadeIn(100);
            },
            mouseleave: function() {
                $(this).find('.segment-dropdown-content').fadeOut(100);
            },
            click: function() {
                $(this).find('.segment-dropdown-content').fadeOut(100);
            }
        }, '.segment-dropdown');

        $('body').on('click', '#auto-segment', function (e) {
            // prevent clicking on the disabled option from closing the dropdown
            // TODO: delete when the auto segments item is removed
            return false;
        });

        $('body').on('click', '#manual-segment', function () {
            var icon = $('#add-segment i');
            if (icon.hasClass('fa-bolt'))
                icon.removeClass('fa-bolt').addClass('fa-pencil');

            const pid = self.options.moduleSpecsTool.cmd('addSegment');
            self.options.viewshedTool.cmd('addSegment');

            self.options.occluderTool   .cmd('select',       -1);
            self.options.viewshedTool   .cmd('switchPlane', pid);
            self.options.moduleSpecsTool.cmd('switchPlane', pid);

            self.select(pid, 'outlines');
            self.panel = 'outlinesWidget';
            self.collapsedGlobals['all'] = true;
            self.options.swapPanel(self.panel);

            self.tree.renderState();

            $('#sidebar-deck-top').scrollTop($('#system-tree-container').height());
            emitEvent('status', {message: 'Create outline to get started!'});
        });

        $('body').on('click', '#add-segment', function () {
            var icon = $('#add-segment i');
            if (icon.hasClass('fa-bolt')) {
                //
                // auto segments
                //
            } else { // manual segments
                const pid = self.options.moduleSpecsTool.cmd('addSegment');
                self.options.viewshedTool.cmd('addSegment');

                self.options.occluderTool   .cmd('select',       -1);
                self.options.viewshedTool   .cmd('switchPlane', pid);
                self.options.moduleSpecsTool.cmd('switchPlane', pid);
                self.select(pid, 'outlines');
                self.panel = 'outlinesWidget';
                self.options.swapPanel(self.panel);

                self.tree.renderState();

                $('#sidebar-deck-top').scrollTop($('#system-tree-container').height());
            }
        });

        $('body').on('click', ('button.apply-auto-segments'), function () {
            self.tree.renderState();
        });

    }


    select(id, nodeType, onDeselect) {
        this.selected = { id, nodeType, onDeselect };

        if (nodeType === 'autoOutlines')
            this.collapsedGlobals.all = false;
    }


    renderState() {
        if ($('#sidebar-deck-top').length === 0) {
            $(this.options.selector).html(`
                <div id="versions-container"></div>

                <div class="actions">
                    <h2 style="padding-top: 10px">Layers</h2>
                    <div class="segment-dropdown">
                        <button id="add-segment" class="sy btn-tree add-segment">
                            <i class="fa fa-pencil" aria-hidden="true"></i> New Segment &nbsp<i class="fa fa-angle-down" aria-label="dropdown"></i>
                        </button>
                        <div class="segment-dropdown-content">
                            <a id="manual-segment"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp Manual</a>
                            <a id="auto-segment" class="disabled" aria-disabled="true" role="link"><i class="fa fa-bolt" aria-hidden="true"></i> &nbsp Automatic</a>
                        </div>
                    </div>
                </div>

                <div class="expand vbox">
                    <div id="sidebar-deck-top" class="content">
                        <div id="system-tree-container">
                            <div id="my-tree" class="tree left system-tree"></div>
                        </div>
                    </div>
                    <!--<div style="display: flex; justify-content: space-between; padding: 0px 20px; font-size: 15px;">
                        <i id="expand-tree" class="fa fa-angle-down" aria-label="expand system tree" style="cursor: pointer"></i>
                        <i id="collapse-tree" class="fa fa-angle-up" aria-label="collapse system tree" style="cursor: pointer"></i>
                    </div>-->
                    <div id="sidebar-deck-bottom" class="content"></div>
                </div>`
            );

            Split(['#sidebar-deck-top', '#sidebar-deck-bottom'], {
                direction:  'vertical',
                gutterSize: 12,
                cursor:     'row-resize',
                sizes:      [ 24, 76 ]
            });

            tippy('.segment-dropdown-content [data-tippy-content]', {
                animation: 'scale',
                inertia:   true,
                placement: 'top',
            });
        }

        this.versions.renderState();
        this.tree.renderState();
    }

    manualHover(id, nodeType, onDeselect) {
        if(nodeType === 'segment') {
            this.tree.hoverSegment(id);
        }
    }

    removeManualHover() {
        this.tree.clearHoverSegments();
    }

    getTreeState(s) {
        let ts = [];
        let vsShow = s.viewsheds.show;

        // VISIBILITY
        let showItems = [];

        for (let pid = 0; pid < s.array.planes.length; pid++) {
            showItems[pid] = {
                'viewsheds': vsShow.get([ pid ], true)
            };

            for (let item of [ 'annotations','outlines', 'keepouts', 'modules' ]) {
                showItems[pid][item] = s.array.show.get([ pid, item ], true);
            }
        }

        let showOccluders = [];

        for (let oid = 0; oid < s.occluders.occluders.length; oid++) {
            showOccluders.push(s.occluders.show.get([ oid ], true));
        }

        // ALL SEGMENTS
        let categories = {
            'annotations': 'Annotations',
            'outlines':  'Outlines',
            'keepouts':  'Keepouts',
            'viewsheds': 'Shade',
            'modules':   'Arrays'
        };

        let allSegments = {
            text:      'All Segments',
            suffix:    '',
            checked:   showItems.map(o => Object.values(o)).flat().some(a => a),
            collapsed: this.collapsedGlobals.all,
            show:      'all',
            nodeType:  'global',
            widget:    'allSegmentsWidget',
            disabled:  (s.array.planes.length === 0),
            children:  [],
        };

        for (let c in categories) {
            if (c === 'outlines') {
                allSegments.children.push({
                    text:     categories[c],
                    checked:  showItems.map(o => o[c]).some(a => a),
                    parent:   allSegments,
                    show:     c,
                    nodeType: 'global',
                    widget:   'autoOutlinesWidget',
                    selected: this.selected.nodeType === 'autoOutlines'
                });
            } else {
                allSegments.children.push({
                    text:     categories[c],
                    checked:  showItems.map(o => o[c]).some(a => a),
                    parent:   allSegments,
                    disabled: (s.array.planes.length === 0),
                    show:     c,
                    nodeType: 'global',
                });
            }
        }

        ts.push(allSegments);

        if (this.selected.nodeType === 'occluder' && this.selected.id !== s.occluders.selected) {
            // select parent occluder when switching to another tool or
            // select newly added occluder
            this.select(s.occluders.selected, 'occluder');

            // expand occluders after adding one
            if (this.selected.id !== -1)
                this.collapsedGlobals['occluders'] = false;
        }

        // OCCLUDERS
        let occluders = {
            text:      'Occluders',
            suffix:    '',
            checked:   showOccluders.some(a => a),
            collapsed: this.collapsedGlobals['occluders'],
            show:      'occluders',
            nodeType:  'occluder',
            widget:    'occludersWidget',
            selected:  this.selected.nodeType === 'occluder' && this.selected.id === -1,
            oid:       -1,
            children:  []
        };


        for (let oid = 0; oid < s.occluders.occluders.length; oid++) {
            let occluder = s.occluders.occluders[oid];

            occluders.children.push({
                text:        occluder.occluderName,
                checked:     showOccluders[oid],
                parent:      occluders,
                show:        'occluders',
                nodeType:    'occluder',
                oid:         oid,
                widget:      'occludersWidget',
                selected:    this.selected.nodeType === 'occluder' && this.selected.id === oid,
                editable:    true,
                editText:    'Edit Occluder Name',
                deleteText:  'Delete Occluder',
                confirmText: 'Are you sure you would like to delete this occluder?',
            });
        }

        if (occluders.selected) {
            emitEvent('status', {message: 'Select type and add occluder to scene'});
        }

        ts.push(occluders);

        // ROOF SEGMENTS
        for (let pid = 0; pid < s.array.planes.length; pid++) {
            let suffix = '';

            if (s.array.planes[pid].auto)
                continue; // don't include auto generated planes in the tree

            if (s.array.arrayInfo[pid][0]) {
                let w = Format.wattsAsKilowatts(s.array.arrayInfo[pid][0].wattage);
                let qty = s.array.arrayInfo[pid][0].qty;

                suffix = ` | ${w} DC STC | ${qty} Modules`;
            }

            let segment = {
                text:        s.array.planes[pid].name,
                suffix:      suffix,
                checked:     Object.values(showItems[pid]).some(x => x),
                collapsed:   (this.collapsedPlanes[pid] === true),
                show:        'all',
                pid:         pid,
                editable:    true,
                editText:    'Edit Segment Name',
                deleteText:  'Delete Segment',
                confirmText: 'Are you sure you would like to delete this roof segment?',
                widget:      'segmentInfoWidget',
                selected:    this.selected.nodeType === 'segment' && this.selected.id === pid,
                nodeType:    'segment',
                children:    []
            };

            let disabled = (s.array.arrayInfo[pid].length === 0 || s.array.planes[pid].azimuth === undefined);

            // SUBCATEGORIES UNDER SEGMENTS NODES
            for (const c in categories) {
                let cNode = {
                    text:     categories[c],
                    checked:  showItems[pid][c],
                    parent:   segment,
                    widget:   c + 'Widget',
                    selected: this.selected.nodeType === c && this.selected.id === pid,
                    show:     c,
                    nodeType: c,
                    disabled: (c === 'outlines') ? false : disabled
                };

                if (c === 'keepouts') {
                    cNode.onSelect = () => {
                        const s = this.getState();

                        let autoKeepoutQty = s.array.keepouts[s.array.pid].filter(k => k.automatic).length;


                        if (SidebarGUI.keepoutModeToggle === 'auto') {
                            if (autoKeepoutQty === 0) {
                                this.options.moduleSpecsTool.cmd('toggleOffsetSegment', true);
                                emitEvent('status', { message: "Adjust plane offset and generate automatic keepouts"});
                            }
                            else {
                                emitEvent('status', { message: "You've accepted your keepouts!"});
                            }
                        }
                        else {
                            emitEvent('status', { message: "Draw keepouts manually"});
                        }
                    };

                    cNode.onDeselect = () => {
                        this.options.moduleSpecsTool.cmd('toggleOffsetSegment', false);
                        this.options.moduleSpecsTool.cmd('clearProposedKeepouts');
                    };
                }

                if (c === 'viewsheds') {
                    cNode.onSelect = () => {
                        const s = this.getState();

                        let autoViewshedQty = s.viewsheds.viewsheds[s.array.pid].filter(v => v.automatic).length;

                        if (SidebarGUI.viewshedModeToggle === 'auto') {
                            if (autoViewshedQty === 0) {
                                this.options.viewshedTool.cmd('proposeAutoViewsheds');
                                emitEvent('status', { message: "Adjust density and generate automatic viewsheds"});
                            }
                            else {
                                emitEvent('status', { message: `${autoViewshedQty} viewsheds created`});
                            }
                        }
                        else {
                            emitEvent('status', { message: "Add viewsheds manually"});
                        }
                    };

                    cNode.onDeselect = () => this.options.viewshedTool.cmd('clearAutoViewsheds');
                }

                segment.children.push(cNode);
            }

            ts.push(segment);
        }

        return ts;
    }
}

export { SystemTreeGUI };
