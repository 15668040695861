/** @typedef {import("../../tools/DxfExportTool").OutlineContext} OutlineContext */

export default class ModuleCenterPlacementStrategy {
    /** @param {OutlineContext} outlineContext */
    constructor(
        outlineContext
    ) {
        /** @type {OutlineContext} */
        this.outlineContext = outlineContext;
    }

    getLocations() {
        const mods = this.outlineContext.moduleArray?.group?.children;

        if (!mods) {
            return [];
        }

        return mods.map(mod => mod.mesh.getWorldPosition());
    }
}
