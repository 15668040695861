


class GuiHelpTool {

    constructor() {
        this.basics       = ".basics"
        this.controls     = ".controls"

        this.nav          = ".modal .modal-content .nav"
        this.desc         = ".modal .modal-content .descriptions"
    }

    init() {
        const self  = this

        this.hide()

        // Header Help button – I don't think this is doing anything..
        $("body .actions-header .help").on("click", function() {
            self.show()
        })

        // Exit out
        $("body .exit").on("click", function() {
            self.hide()
        })

        // Toggle controls / basics
        $(`${self.nav} .0`).click( function(e) {
            $(`${self.desc} .controls`).hide()
            $(`${self.desc} .basics`).show()
        });

        $(`${self.nav} .1`).click( function(e) {
            $(`${self.desc} .controls`).show()
            $(`${self.desc} .basics`).hide()
            // Set default targets
            $(`${self.nav} .0 a`).css({
                "text-decoration": "none",
                "opacity": ".6"
            })
        });
    }

    hide() {
        $(".modal").css({
            "opacity": "0",
            "pointer-events": "none"
        })
        $(".modal-content").hide()
    }

    show() {
        const self = this

        $(".modal-content").show()


        $(".modal").css({
            "z-index": "1100",
            "opacity": "1",
            "pointer-events": "auto",
        })
        $(`${self.desc} .controls`).hide()
        $(`${self.desc} .basics`).show()

        $(`${self.nav} .0 a`).css({
            "color": "white",
            "opacity": "1",
        })

        // Add Escape listener
        $(document).keyup(function(e) {
            if(e.key === "Escape") {
                self.hide()
            }
        })
    }
}


export { GuiHelpTool };
