

/**
 * Replacement for alert() and confirm().
 *
 * This is not your regular GUI widget class (:
 */
class Prompts {

    constructor() {
        this.resolve;
        this.init();
    }

    init() {
        let self = this;

        $('body').on('click', '#info-dialog .ok', function () {
            $('#info-dialog').dialog('close');
        });

        $('body').on('click', '#confirm-dialog .cancel', function () {
            self.respond(false);

            $('#confirm-dialog').dialog('close');
        });

        $('body').on('click', '#confirm-dialog .ok', function () {
            self.respond(true);

            $('#confirm-dialog').dialog('close');
        });

        // close on click out
        $('body').on('click', '.ui-widget-overlay', function () {
            $('.dialog').each(function () {
                try {
                    $(this).dialog('close');
                } catch (e) {
                    // ignore errors about the uninitialized dialogs
                }
            });
        });
    }


    respond(answer) {
        if (this.resolve) {
            this.resolve(answer);
            this.resolve = null;
        }
    }


    info(msg) {
        $('#info-dialog .desc').html(msg);

        $("#info-dialog").dialog({
            modal: true,
            classes: {
                'ui-dialog': 'save-as-dialog'
            },
            height: 'auto',
            minHeight: 50,
            closeOnEscape: true,
            open: function () {
                $(this).find('.ok').trigger('focus');
            },
            close: function () {
                $(this).find('.ok').trigger('blur');
            }
        });
    }


    async confirm(msg) {
        let self = this;

        $('#confirm-dialog .desc').html(msg);

        $("#confirm-dialog").dialog({
            modal: true,
            classes: {
                'ui-dialog': 'save-as-dialog'
            },
            height: 'auto',
            minHeight: 50,
            closeOnEscape: true,
            open: function () {
                $(this).find('.ok').trigger('focus');
            },
            close: function () {
                self.respond(false);
                $(this).find('.ok').trigger('blur');
            }
        });

        return new Promise(resolve => self.resolve = resolve);
    }
}

export { Prompts };
