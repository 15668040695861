import { mouseHandler, toolSelector } from '../Viewer.js';
import { KeyStore } from '../alibs/KeyStore';
import { OcclusionManager } from '../libs/OcclusionManager';
import { TransformControls } from '../controls/TransformControls';
import * as THREE from '../libs/three.module';

class OccluderTool {
    constructor() {
        this.name = 'occluderTool';
        this.mode = 'translate';

        this.occlusionManager = new OcclusionManager();

        this.commandHistory = [];

        this.meshes = [];

        this.enabled = true;
        this.states = {
            NONE:         0,
            ADD_CUBE:     1,
            ADD_CYLINDER: 2,
            MOVE:         3,
            ROTATE:       4,
            SIZE:         5
        };

        this.state = this.states.NONE;
        this.selected = -1;
        this.occluders = [];

        this.show = new KeyStore();
    }

    setRaycaster(rc)  { this.raycaster = rc; }

    setCamera(camera, domElement) {
        this.camera = camera;

        this.controls = new TransformControls(camera, domElement);
        this.controls.setMode('translate');
        this.controls.setSize(0.5);
    }

    addToScene(scene) {
        this.scene = scene;
        this.scene.add(this.controls);
    }

    addMesh(mesh) {
        this.meshes.push(mesh);
    }

    registerPropagateState(callback) {
        this.propagateState = callback;
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.selectOccluder(-1);

        if (this.propagateState)
            this.propagateState();

        this.enabled = false;
    }

    getState() {
        let occluderType = (this.selected === -1) ? 'cube' : this.occluders[this.selected].type;

        let s = {
            mode:      this.mode,
            type:      occluderType,
            adding:    (this.state === this.states.ADD_CUBE || this.state === this.states.ADD_CYLINDER),
            occluders: this.occluders,
            selected:  this.selected,
            show:      this.show,
            opacity:   this.occlusionManager.opacity,
            maxCount:  this.occlusionManager.maxOccluders
        };

        return s;
    }


    cmd(c, val) {
        this.commandHistory.push({ c, val });

        toolSelector.enable(this);

        if (c === 'setMode') {
            this.mode = val;
            this.controls.setMode(val);
        } else if (c === 'add') {
            this.state = (val === 'cube') ? this.states.ADD_CUBE : this.states.ADD_CYLINDER;
            mouseHandler.setCursor('crosshair', 'occ', true);
        } else if (c === 'select') {
            this.selectOccluder(val);
        } else if (c === 'show') {
            this.showOccluder(val.oid, val.show);
            this.toggleVisibility();
        } else if (c === 'remove') {
            this.remove(val);
        } else if (c === 'rename') {
            this.rename(val.oid, val.newName);
        } else if (c === 'changeShape') {
            if (this.selected !== -1)
                this.changeShape(this.selected, val);
        } else if (c === 'setOpacity') {
            this.occlusionManager.opacity = val;
        } else {
            console.log('Unknown command:', c, val);
        }
    }


    selectOccluder(oid) {
        this.controls.detach();
        this.occluders.map(o => o.visible = false);

        this.selected = oid;

        if (this.selected !== -1) {
            this.occluders[this.selected].visible = true;
            this.controls.attach(this.occluders[this.selected]);
        }

        this.state = this.states.NONE;
        mouseHandler.clearCursor('occ');
    }


    showOccluder(oid, show) {
        if (oid !== -1) {
            this.show.set([ oid ], show);

            if (show) {
                this.controls.attach(this.occluders[this.selected]);
            } else {
                this.controls.detach();
            }
        } else {
            for (let i = 0; i < this.occluders.length; i++) {
                this.show.set([ i ], show);
            }

            this.controls.detach();
        }
    }


    setMaterials(node) {
        if (!this.occMat) {
            let occMat = this.occlusionManager.getOccludableMaterial(node.material.map);
            let occShadowMat = this.occlusionManager.getOccludableShadowMaterial();

            node.material = occMat;
            node.renderOrder = -10;
            node.customDepthMaterial = occShadowMat;

            this.occMat = occMat;
        }
    }


    onEachFrame() {
        this.occlusionManager.update();
        this.controls.update();
    }


    addOccluderAt(point, shape, name) {
        let guide;

        if (shape === 'cylinder') {
            guide = this.occlusionManager.placeCylinderAt(point.x, point.y, point.z);
        } else {
            guide = this.occlusionManager.placeBoxAt(point.x, point.y, point.z);
        }

        if (guide) {
            this.occluders.map(o => o.visible = false);

            guide.occluderName = name || 'Occluder ' + (this.occluders.length + 1);
            guide.castShadow = false;
            guide.receiveShadow = false;

            this.occluders.push(guide);
            this.controls.attach(guide);

            this.scene.add(guide);
        }

        return guide;
    }


    remove(oid) {
        let guide = this.occluders[oid];

        this.occlusionManager.remove(guide);
        this.scene.remove(guide);

        this.occluders.splice(oid, 1);
        this.show.splice([ oid ], this.occluders.length);

        this.selected = -1;

        this.controls.detach();
    }


    rename(oid, name) {
        this.occluders[oid].occluderName = name;
    }


    changeShape(oid, shape) {
        const o = this.occluders[oid];

        const name  = o.occluderName;
        const pos   = o.position;
        const rot   = o.rotation;
        const scale = o.scale;
        const show  = this.show.get([ oid ], true);

        this.remove(oid);

        let guide;

        if (shape === 'cylinder') {
            guide = this.occlusionManager.placeCylinderAt(pos.x, pos.y, pos.z);
        } else {
            guide = this.occlusionManager.placeBoxAt(pos.x, pos.y, pos.z);
        }

        guide.occluderName = name;
        guide.scale.set(scale.x, scale.y, scale.z);
        guide.rotation.set(rot.x, rot.y, rot.z, rot.order);

        this.scene.add(guide);

        this.occluders.splice(oid, 0, guide);
        this.show.set([ oid ], show);
        this.selected = oid;
        this.controls.attach(guide);
    }


    hideFeaturesFromViewsheds() {
        this.occluders.map(o => o.visible = false);
    }


    toggleVisibility() {
        for (let oid = 0; oid < this.occluders.length; oid++) {
            let show = this.show.get([ oid ], true);

            if (this.occluders[oid].type === 'cube') {
                this.occlusionManager.boxOccludersActive[oid] = show ? 1 : 0;
            } else {
                this.occlusionManager.cylinderOccludersActive[oid] = show ? 1 : 0;
            }
        }
    }


    mouseDown(event, vMouse, panning) {
        if (!this.enabled)
            return;

        if (event.button === THREE.MOUSE.LEFT) {
            if (this.state === this.states.ADD_CUBE || this.state === this.states.ADD_CYLINDER) {
                this.raycaster.setFromCamera(vMouse, this.camera);
                const xs = this.raycaster.intersectObjects(this.meshes, true);

                if (xs.length > 0) {
                    let occluderType = (this.state === this.states.ADD_CUBE) ? 'cube' : 'cylinder';
                    this.addOccluderAt(xs[0].point, occluderType);

                    this.state = this.states.NONE;
                    mouseHandler.clearCursor('occ');

                    this.selected = this.occluders.length - 1;
                    this.propagateState();
                }
            }
        }
    }


    getSaveData() {
        let data = {
            opacity:   this.occlusionManager.opacity,
            occluders: this.occluders.map((o) => {
                return {
                    name:  o.occluderName,
                    shape: o.type,
                    pos: {
                        x: o.position.x,
                        y: o.position.y,
                        z: o.position.z
                    },
                    rot: {
                        x:     o.rotation.x,
                        y:     o.rotation.y,
                        z:     o.rotation.z,
                        order: o.rotation.order
                    },
                    scale: {
                        x: o.scale.x,
                        y: o.scale.y,
                        z: o.scale.z
                    }
                };
            })
        };

        return data;
    }


    restoreSaveData(data) {
        if (data.opacity !== undefined)
            this.occlusionManager.opacity = data.opacity;

        for (let oData of data.occluders) {
            let o = this.addOccluderAt(oData.pos, oData.shape, oData.name);

            this.controls.detach(o); // don't show controls on load

            o.visible = false;
            o.scale.set(oData.scale.x, oData.scale.y, oData.scale.z);
            o.rotation.set(oData.rot.x, oData.rot.y, oData.rot.z, oData.rot.order);
        }
    }
}

export { OccluderTool };
