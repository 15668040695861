import { render, prompts, init } from '../Viewer.js';
import { Validator } from './Validator';
import { GUI } from './Gui';
import { DroneImagesGui } from './GuiDroneImages';


import tippy from 'tippy.js';


class ShareProjectGUI {
    constructor(options) {
        this.options = options;
        this.getState = options.getState;
    }


    cmd(c, val) {
        return this.options.tool.cmd(c, val);
    }


    init() {
        const self = this;

        $('body').on('click', '#share-photo-0 .take-share-img', function() {
            self.uploadPhoto(0, false);
        });

        $('body').on('click', '#share-photo-1 .take-share-img', function() {
            self.uploadPhoto(1, false);
        });

        $('body').on('click', '#share-photo-2 .take-share-img', function() {
            self.uploadPhoto(2, false);
        });

        $('body').on('click', '#share-photo-3 .take-share-img', function() {
            self.uploadPhoto(3, false);
        });

        $('body').on('click', '.banner-option img', function() {
            const src = $(this).attr('data-image-path');

            self.uploadPhoto(4, 'roll', self.isJpeg(src), src);

            // close thumbnail dialog
            $('#banner-thumbnail-dialog ul').empty();
            $('#banner-thumbnail-dialog')
                .css({ width: '0px' })
                .addClass('hidden');
        });

        $('body').on('click', '#share-upload-banner', function() {
            $('#share-upload-banner-file').trigger('click');
        });

        $('body').on('change', '#share-upload-banner-file', function () {
            if (this.files && this.files[0]) {
                self.uploadPhoto(4, this.files[0], self.isJpeg(this.files[0].name));
            }
        });

        GUI.inputText('#share-project-name', {
            validate: Validator.create({
                updateState: (val) => this.cmd('editName', val),
                resetState:  () => this.getState().share.shareName
            })
        });

        GUI.inputText('#share-sales-name', {
            validate: Validator.create({
                updateState: (val) => this.cmd('editSalesInfo', { idx: 0, val }),
                resetState:  () => this.getState().share.salesInfo[0]
            })
        });

        GUI.inputText('#share-sales-email', {
            validate: Validator.create({
                updateState: (val) => this.cmd('editSalesInfo', { idx: 1, val }),
                resetState:  () => this.getState().share.salesInfo[1]
            })
        });

        GUI.inputText('#share-sales-phone', {
            validate: Validator.create({
                updateState: (val) => this.cmd('editSalesInfo', { idx: 2, val }),
                resetState:  () => this.getState().share.salesInfo[2]
            })
        });

        GUI.inputText('#share-sales-company', {
            validate: Validator.create({
                updateState: (val) => this.cmd('editSalesInfo', { idx: 3, val }),
                resetState:  () => this.getState().share.salesInfo[3]
            })
        });

        $('body').on('click', '.thumbnail-select', function () {
            let diag = $('#banner-thumbnail-dialog');
            let $thumbSelect = $(this);

            if (diag.hasClass('hidden')) { // open
                diag.removeClass('hidden');

                const s = self.getState();

                for (let img of s.images) {
                    const src = img.thumbnailUrl || img.thumbnail;
                    const path = DroneImagesGui.getFullImgUrl(img);

                    $('#banner-thumbnail-dialog ul').append(`
                        <li class="banner-option">
                            <img class="thumb" src="${src}" data-image-path="${path}" alt="thumbnail image"/>
                        </li>
                    `);
                }

                $thumbSelect.prop('disabled', true);
                $thumbSelect.attr('aria-disabled', "true");

                diag.animate({ width: '75%' }, 750, function () {
                    $thumbSelect.prop('disabled', false);
                    $thumbSelect.attr('aria-disabled', "false");
                });
            } else { // close
                $thumbSelect.prop('disabled', true);
                $thumbSelect.attr('aria-disabled', "true");

                diag.animate({ width: '0px' }, 750, function() {
                    $thumbSelect.prop('disabled', false);
                    $thumbSelect.attr('aria-disabled', "false");
                    diag.addClass('hidden');

                    $('#banner-thumbnail-dialog ul').empty();
                });
            }
        });

        $('body').on('click', '.share-project-button', function () {
            const s = self.getState();

            if (s.share.images.slice(0, 5).filter(x => x).length < 5) {
                prompts.info('Please add all 5 images first');
            } else {
                self.options.tool.createShareLink().then(function (url) {
                    self.linkPopup(url);
                }).catch((err) => {
                    prompts.info('There was an error sharing your project. Please contact support.')
                    throw err;
                }).done();
            }
        })
    }


    isJpeg(name) {
        const lower = name.toLowerCase();
        return lower.endsWith('jpg') || lower.endsWith('jpeg');
    }


    uploadPhoto(imgIdx, imgFile, jpeg, src) {
        this.setSpinner(imgIdx, true);

        this.options.tool.uploadPhoto(imgIdx, imgFile, jpeg, src).then((shareImg) => {
            this.setProjectShareImage(shareImg, imgIdx);
            this.setSpinner(imgIdx, false);
        }).catch((err) => {
            prompts.info('There was an issue with your image. Please contact support.')
            this.setSpinner(imgIdx, false);
            throw err;
        });
    }


    renderState() {
        this.render();

        const s = this.options.getState();

        for (let i = 0; i < s.share.images.length; i++) {
            if (s.share.images[i]) {
                this.setProjectShareImage(s.share.images[i], i);
            }
        }

        $('#share-checkboxes').show();

        const viewshedsPerPlane = s.viewsheds.viewsheds;

        if (viewshedsPerPlane.length > 0) {
            let viewshedCount = viewshedsPerPlane.map((p) => p.length).reduce((a, b) => a + b, 0);

            if (viewshedCount > 0) {
                $('#shadeAndViewshed .fa-check-circle').css('color', '#27E2A4')
            }
        }

        if (s.array.totalQty > 0)
            $('#modulesPopulated .fa-check-circle').css('color', '#27E2A4')

        if (this.hasCustomerData(s.share.project))
            $('#customerInfo .fa-check-circle').css('color', '#27E2A4')

        if (this.hasMonthlyUsageData(s.share.project.kwh) || s.share.project.monthlyUsage.length)
            $('#monthlyUsage .fa-check-circle').css('color', '#27E2A4')

        $('#share-project-name' ).val(s.share.shareName    || '');
        $('#share-sales-name'   ).val(s.share.salesInfo[0] || '');
        $('#share-sales-email'  ).val(s.share.salesInfo[1] || '');
        $('#share-sales-phone'  ).val(s.share.salesInfo[2] || '');
        $('#share-sales-company').val(s.share.salesInfo[3] || '');
    }


    hasCustomerData(p) {
        return (
            p.name &&
            p.ownerDetails &&
            p.ownerDetails.firstName &&
            p.ownerDetails.lastName &&
            p.address
        );
    }


    // Check that at least some of the months are defined with usage
    hasMonthlyUsageData(usage) {
        return usage.some((kWh) => kWh > 0);
    }

    render() {
        $('#sidebar-deck').html(`
            <div id="share-tool-dialog" class="share-tool">
                <div class="share-prompt">
                    Upload a banner image, then position the 3D model and take four screenshots using the screenshot icon.
                </div>

                <div class="share-contain">
                    <div class="share-row">
                        <button id="share-upload-banner" class="sy" style="position: absolute; z-index: 4; padding: 8px 10px; margin-left: 3%; margin-top: 3%">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                        <div id="share-photo-4" class="share-column">
                            <div class="share-img-box thumbnail-select" alt="select a thumbnail to use as banner image">

                                <img src="/styles/images/icons/project-thumbnail-icon.svg" class="placeholder-img" alt="">
                                <div class="add-img-text">upload banner image</div>
                            </div>
                        </div>
                    </div>

                    <div class="share-row">
                        <div id="share-photo-0" class="share-column">
                            <a class="sy take-share-img" alt="take an image to share">
                                <div class="share-img-box">
                                    <img src="/styles/images/icons/screenshot-icon.svg" alt="" class="placeholder-img">
                                    <div class="add-img-text">add image <span>(social media)</span></div>
                                </div>
                            </a>
                        </div>

                        <div id="share-photo-1" class="share-column">
                            <a class="sy take-share-img" alt="take an image to share">
                                <div class="share-img-box">
                                    <img src="/styles/images/icons/screenshot-icon.svg" alt="" class="placeholder-img">
                                    <div class="add-img-text">add image</div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="share-row">
                        <div id="share-photo-2" class="share-column">
                            <a class="sy take-share-img" alt="take an image to share">
                                <div class="share-img-box">
                                    <img src="/styles/images/icons/screenshot-icon.svg" alt="" class="placeholder-img">
                                    <div class="add-img-text">add image</div>
                                </div>
                            </a>
                        </div>

                        <div id="share-photo-3" class="share-column">
                            <a class="sy take-share-img" alt="Take an image to share">
                                <div class="share-img-box">
                                    <img src="/styles/images/icons/screenshot-icon.svg" alt="" class="placeholder-img">
                                    <div class="add-img-text">add image</div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div id="banner-thumbnail-dialog" class="hidden">
                        <ul class="banner-thumbnail-list"></ul>
                    </div>

                    <div id="share-checkboxes" style="display:none;">
                        <div id="shadeAndViewshed" class="check-row">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>

                            <span>Viewsheds populated</span>

                            <div class="qmark-info" aria-hidden="true">
                                <div class="tooltip-content">
                                    Generate solar access viewsheds from anywhere in the 3D mapped environment. <a style="font-family: inherit; color: white" href="https://help.scanifly.com/generating-solar-access-viewsheds" target="_blank">Learn More</a>
                                </div>
                            </div>
                        </div>

                        <div id="modulesPopulated" class="check-row">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>

                            <span>Modules added</span>

                            <div class="qmark-info" aria-hidden="true">
                                <div class="tooltip-content">
                                    <a style="font-family: inherit; color: white" href="https://help.scanifly.com/modifying-arrays" target="_blank">Learn More</a> about adding modules.
                                </div>
                            </div>
                        </div>

                        <div id="customerInfo" class="check-row">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>

                            <span>Customer contact information</span>

                            <div class="qmark-info" aria-hidden="true">
                                <div class="tooltip-content">
                                    Customer and project info can be added in the project portal. <a href="https://help.scanifly.com/adding-customer-information" style="font-family: inherit; color: white" target="_blank" aria-label="support documentation">Learn more</a>
                                </div>
                            </div>
                        </div>

                        <div id="monthlyUsage" class="check-row">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>

                            <span>Monthly consumption data</span>

                            <div class="qmark-info" aria-hidden="true">
                                <div class="tooltip-content">
                                    Current monthly usage (kWh) will be used for consumption vs. production analysis.
                                </div>
                            </div>

                        </div>

                        <form class="edit-project">
                            <div class="edit-project-header">
                                <h4>Project Name</h4>
                                <input id="share-project-name" type="text" aria-label="project name"></input>

                                <div class="qmark-info" aria-hidden="true">
                                    <div class="tooltip-content">
                                        This textbox only updates the project name used on the share page and PDF.
                                    </div>
                                </div>

                            </div>

                            <div class="edit-title">
                                <h4>Point of Contact</h4>

                                <div class="qmark-info" aria-hidden="true">
                                    <div class="tooltip-content">
                                        This is the contact information for the customer's main point of contact.
                                    </div>
                                </div>
                            </div>

                            <div class="edit-project-group">
                                <input id="share-sales-name" type="text" placeholder="Name" aria-label="name"></input>
                            </div>

                            <div class="edit-project-group">
                                <input id="share-sales-email" type="email" placeholder="Email" aria-label="email"></input>
                            </div>

                            <div class="edit-project-group">
                                <input id="share-sales-phone" type="text" placeholder="Phone" aria-label="phone"></input>
                                <input id="share-sales-company" type="text" placeholder="Company" aria-label="company"></input>
                            </div>
                        </form>

                        <div class="share-btn-container">
                            <button class="sy share-project-button">Share</button>
                        </div>
                    </div><!-- #share-checkboxes -->
                </div>

                <input type="file" id="share-upload-banner-file" class="hidden"></input>
            </div>
        `);


        tippy(`.share-tool .qmark-info`, {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            interactive: true
        });
    }


    setProjectShareImage(imgURL, idx) {
        if(idx < 0 || idx > 4) { return }

        var img = new Image;
        img.src = imgURL;

        var imgEl = $('<img>')
            .attr('src', img.src)
            .css('height', '100%')
            .css('width', '100%')
            .css('border-radius', '10px')
            .css('object-fit', 'cover')

        $(`#share-photo-${idx} .share-img-box`).empty().append(imgEl);
    }


    linkPopup(shareURL) {
        $("#share-dialog-title").text("Success! Your shareable link is: ");
        $("#share-dialog-text").text(`${shareURL}`);

        // Copy to clipboard
        $("#share-link-dialog button").on("click", function() {
            var toCopy = document.getElementById("share-dialog-text");
            toCopy.select();
            document.execCommand("Copy");
        })

        $("#share-link-dialog").css("display", "block")

        var shareDialog = $( '#share-link-dialog' ).dialog({
            'autoOpen': false,
            width:       $(document).height() * 0.38 * 1.25,
            height:      $(document).height() * 0.36 * .75,
            position: {
                my: 'center',
                at: 'center',
                of: window
            },
            modal: true,
            open: function() {
                $('.ui-widget-overlay').on('click',function() {
                    $('#share-link-dialog').dialog('close');
                });
            }
        });

        shareDialog.dialog('open');
    }

    setSpinner(imgIdx, add) {
        if(add) {
            $(`#share-photo-${imgIdx} .placeholder-img`).addClass('hidden');
            if(imgIdx === 4) {
                $(`#share-photo-${imgIdx}`).append("<i class='fa fa-4x fa-spinner fa-spin wait-photo-banner' aria-hidden='true'></i>")
            } else {
                $(`#share-photo-${imgIdx}`).append("<i class='fa fa-4x fa-spinner fa-spin wait-photo' aria-hidden='true'></i>")
            }
            $(`#share-photo-${imgIdx} .add-img-text`).text("")
        } else {
            $(`#share-photo-${imgIdx} .fa-spinner`).remove()
            $(`#share-photo-${imgIdx} .add-img-text`).text("add image")
        }
    }
}


export { ShareProjectGUI };
