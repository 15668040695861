import { perMSqToPerFtSq, perFtSqToPerMSq } from '../alibs/Units';
import { MeasurementUnits } from '../tools/MeasurementsTool';
import { measurementUnits, viewshedTool, prompts, emitEvent, init } from '../Viewer.js';
import { GUI } from './Gui.js';
import { Colors } from '../core/Colors';
import { InfernoGradient } from '../draw/InfernoGradient';
import { SidebarGUI } from './GuiSidebar.js';
import { ShadingValuesGui } from './GuiShadingValues';

import * as guiSidebarViewshedTemplate from '../../templates/GuiSidebarViewsheds/GuiSidebarViewsheds.hbs';

import tippy from 'tippy.js';

const AUTO_GEN_BUTTON_SELECTOR = '.viewshed-buttons > button.generate-auto-viewsheds';
const DELETE_ALL_AUTO_SELECTOR = '.viewshed-buttons > button.delete-all-auto-viewsheds';
const GRID_DENSITY_CONTROLS_SELECTOR = '.auto-viewsheds-density';

const AUTO_GEN_MOD_CENTER_MODE_SELECTOR = '.auto-viewsheds-strategy-selector #sw-option-auto-viewsheds-per-mod';
const AUTO_GEN_DENSITY_GRID_MODE_SELECTOR = '.auto-viewsheds-strategy-selector #sw-option-auto-viewsheds-grid';

const AUTO_GEN_STRATEGIES = /** @type {const} */ ({
    'module-center': 'module-center',
    'density-grid': 'density-grid'
});


class SidebarViewshedGui {
    /**
     * @param {object} options
     * @param {import('../tools/ViewshedTool').ViewshedTool} options.tool
     */
    constructor(options) {
        this.options = options;
        this.getState = options.getState;

        this.spinnerSelector = '#density-spinner';
        this.deleteAllSelector = 'button.delete-all-auto-viewsheds';
        this.viewshedDialogSelector = '#dialog-viewshed';
        this.viewshedCheckboxSelector = '.shading-container .selected-viewshed';
        this.removeViewshedsSelector = '#remove-viewsheds';
        this.refreshViewshedsSelector = '#refresh-viewsheds';

        this.selectedViewsheds = [];

        this.viewMode = 'list';
    }

    cmd(c, val) {
        return this.options.tool.cmd(c, val);
    }

    getValidatedViewshedPlaneState() {
        const state = this.getState();

        const pid = state.array.pid;
        const tilt = state.array.planes[pid].tilt;
        const azimuth = state.array.planes[pid].azimuth;

        if (pid === undefined) {
            prompts.info('Please select a roof plane before adding viewsheds.');
            return {pid: undefined, tilt: undefined, azimuth: undefined};
        }

        if (azimuth === undefined || tilt === undefined) {
            prompts.info('Please set azimuth and tilt before adding viewsheds.');
            return {pid: undefined, tilt: undefined, azimuth: undefined};
        }

        return {pid, tilt, azimuth};
    }

    updateSelectAllViewshedsCheckboxState() {
        if(this.selectedViewsheds.length === 0 ) {
            $('input.select-all-viewsheds').prop('checked', false).prop('indeterminate', false);
        } else {
            const s = this.getState();
            const pid = s.array.pid;
            if(this.selectedViewsheds.length === s.viewsheds.viewsheds[pid].length ) {
                $('input.select-all-viewsheds').prop('checked',true).prop('indeterminate', false);
            } else {
                $('input.select-all-viewsheds').prop('checked', false).prop('indeterminate', true);
            }
        }
    }

    updateSelectedViewshedsActions() {
        const s = this.getState();
        const pid = s.array.pid;
        if(s.viewsheds.viewsheds[pid].length == 0) {
            $('input.select-all-viewsheds').attr('disabled', true);
            $('#show-viewshed-actions-dropdown').attr('disabled', true);
        }
        GUI.toggleEnable('.viewshed-action')($('input.selected-viewshed:checked').length > 0);
    }

    updateDisplayedValueAsaOrTsrf() {
        const s = this.getState();
        const pid = s.array.pid;
        if(s.viewsheds.viewsheds[pid].length == 0) {
            $('#displayed-value-options .sw-multiway').addClass('disabled');
            $('#displayed-value-tsrf').removeClass('active');
            $('#displayed-value-asa').removeClass('active');
        } else {
            $('#displayed-value-options .sw-multiway').removeClass('disabled');
            if(s.viewsheds.displayValue === viewshedTool.displayValues.ASA ) {
                $('#displayed-value-asa').addClass('active');
                $('#displayed-value-tsrf').removeClass('active');
            } else {
                $('#displayed-value-asa').removeClass('active');
                $('#displayed-value-tsrf').addClass('active');
            }
        }
    }


    init() {
        const self = this;

        // manual mode
        $('body').on('click', '#sw-option-manual-viewsheds', function () {
            SidebarGUI.viewshedModeToggle = 'manual';
            self.cmd('clearAutoViewsheds');
            emitEvent('status', { message: 'Add viewsheds manually'});

            self.renderState();
        });

        // auto mode
        $('body').on('click', '#sw-option-auto-viewsheds', function () {
            SidebarGUI.viewshedModeToggle = 'auto';

            const {pid, tilt, azimuth} = self.getValidatedViewshedPlaneState();

            if (pid === undefined || tilt === undefined || azimuth === undefined) {
                return;
            }

            if (!self.cmd('isDataReady')) {
                prompts.info('Weather station data is not loaded. Please set the site location in Shade Tool.');
                return;
            }

            /// @todo this is static but set per segment, why?
            if (SidebarViewshedGui.autoViewshedCount === 0) {
                self.cmd('switchPlane', pid);
                self.cmd('setAzimuthAndTilt', {azimuth, tilt});
                self.cmd('proposeAutoViewsheds');
                emitEvent('status', {message: 'Adjust density and generate automatic viewsheds'});
            } else {
                emitEvent('status', {message: `${SidebarViewshedGui.autoViewshedCount} viewsheds created`});
            }

            self.renderState();
        });

        $('body').on(
            'wheel',
            this.spinnerSelector,
            /** @this {HTMLInputElement} */
            function () {
                self.setDensity(this.value);
            }
        );

        $('body').on('click', AUTO_GEN_DENSITY_GRID_MODE_SELECTOR, () => {
            this.setAutoStrategy('density-grid');
        });

        $('body').on('click', AUTO_GEN_MOD_CENTER_MODE_SELECTOR, () => {
            this.setAutoStrategy('module-center');
        })

        // generate auto viewsheds
        $('body').on('click', AUTO_GEN_BUTTON_SELECTOR, async function () {
            GUI.toggleEnable(this)(false).addClass('disabled').html(
                '<i class="fa fa-spinner fa-spin"></i> Generating'
            );
            GUI.toggleEnableRecursive('.auto-viewsheds-controls')(false);
            $(DELETE_ALL_AUTO_SELECTOR).addClass('hidden');

            await self.options.tool.cmd('commitAutoViewsheds');

            self.renderState();
        });

        // delete all auto viewsheds
        $('body').on('click', DELETE_ALL_AUTO_SELECTOR, async function () {
            const ok = await prompts.confirm(
                'Are you sure you want to delete all automatic viewsheds?'
            );

            if (!ok) {
                return;
            }

            self.options.tool.cmd('removeAutoViewsheds');
            emitEvent('status', { message: 'Adjust density and generate automatic viewsheds' });
            self.selectedViewsheds.length = 0;
            self.renderState();
        });

        // add a manual viewshed
        $('body').on('click', 'button.add-viewshed', function () {
            const {pid, tilt, azimuth} = self.getValidatedViewshedPlaneState();

            if (pid === undefined || azimuth === undefined || tilt === undefined) {
                return;
            }

            if (self.cmd('isDataReady')) {
                self.cmd('switchPlane', pid);
                self.cmd('setAzimuthAndTilt', {azimuth, tilt});
                self.cmd('add');
            } else {
                prompts.info('Weather station data is not loaded. Please set the site location in Shade Tool.');
            }
        });

        // select/deselect all
        $('body').on('change', 'input.select-all-viewsheds', function() {
            self.selectedViewsheds.length = 0;
            if( $(this).is(':checked')) {
                $('input.selected-viewshed').each(function(idx, e) {
                    let data_vid = parseInt($(this).attr('data-vid'));
                    self.selectedViewsheds.push(data_vid);
                })
            }
            $('input.selected-viewshed').prop('checked', $(this).is(':checked'));
            self.updateSelectedViewshedsActions();
        });

        // select viewshed
        $('body').on('change', 'input.selected-viewshed', function () {
            if ($('input.selected-viewshed[data-vid=' + $(this).attr('data-vid') + ']').is(':checked')) {
                self.selectedViewsheds.push(parseInt($(this).attr('data-vid')));
            } else {
                self.selectedViewsheds.pop(parseInt($(this).attr('data-vid')));
            }

            self.updateSelectAllViewshedsCheckboxState();
            self.updateSelectedViewshedsActions();
        });

        // show/hide selected viewshed actions menu
        $('body').on('click', 'button#show-viewshed-actions-dropdown', function() {
            $('#viewshed-actions-dropdown').toggle('show');
        });

        // hide the actions menu if the user clicks elsewhere
        $(document).on('click', function(event) {
            if(event.target.id != 'show-viewshed-actions-dropdown') {
                $('#viewshed-actions-dropdown').hide();
            }
        });

        // remove selected viewsheds
        $('body').on('click', this.removeViewshedsSelector, async function () {
            if(!self.selectedViewsheds.length) {
                return;
            }

            const ok = await prompts.confirm(
                'Are you sure you want to delete all selected viewsheds?'
            );

            if (!ok) {
                return;
            }

            const s = self.getState();

            self.cmd('switchPlane', s.array.pid);
            self.cmd('removeViewsheds', self.selectedViewsheds.slice());
            self.selectedViewsheds.length = 0; // remove deleted viewsheds from selectedViewsheds array
        });

        // refresh selected viewsheds
        $('body').on('click', this.refreshViewshedsSelector, async function () {
            if(!self.selectedViewsheds.length) {
                return;
            }

            const ok = await prompts.confirm(
                'Are you sure you want to refresh all selected viewsheds?'
            );

            if (!ok) {
                return;
            }

            const s = self.getState();
            self.cmd('switchPlane', s.array.pid);
            self.cmd('refreshViewsheds', self.selectedViewsheds);
        });

        // change the displayed value to ASA
        $('body').on('click', '#displayed-value-asa', function () {
            self.cmd('setDisplayedValue', viewshedTool.displayValues.ASA);
            self.renderState();
        });
        // OR
        // change the displayed value to ASA
        $('body').on('click', '#displayed-value-tsrf', function () {
            self.cmd('setDisplayedValue', viewshedTool.displayValues.TSRF);
            self.renderState();
        });

        // viewshed modal
        $('body').on('click', '.viewshed-modal', function () {
            const s = self.getState();

            const pid = s.array.pid;
            const vid = $(this).attr('data-vid');

            const viewshed = s.viewsheds.viewsheds[pid][vid];

            if (!viewshed) {
                return;
            }

            const solarAccessFraction = viewshed.solarAccess / 100;
            const markerColor = `#${InfernoGradient.value(solarAccessFraction).getHexString()}`;

            const viewshedColor = viewshed.automatic
                ? Colors.Scanifly.teal()
                : Colors.Scanifly.red();

            // toggle label text color on viewsheds to white for darker gradient colors
            const fontColor = solarAccessFraction >= 0.84 ? Colors.darkGray() : Colors.white();

            let svg;



            $(self.viewshedDialogSelector).dialog({
                dialogClass:   'dialog-viewshed',
                width:         '50%',
                height:        $(document).height() * 0.8,
                autoOpen:      true,
                modal:         true,
                title:         '',
                closeOnEscape: true,
                open: function(){
                    $('.ui-dialog-titlebar-close').on('click',function() {
                        $('#dialog-viewshed').dialog('close');
                        $('.ui-dialog-content').dialog('destroy');
                    });

                    $('.ui-widget-overlay').on('click',function() {
                        $('#dialog-viewshed').dialog('close');
                        $('.ui-dialog-content').dialog('destroy');
                    });

                    $(document).on('keyup', function(e) {
                        if (e.key == "Escape") {
                            $('#dialog-viewshed').dialog('close');
                            $('.ui-dialog-content').dialog('destroy');
                        }
                    });
                },
                close: function() {

                },
                position: {
                    my: 'center',
                    at: 'center',
                    of: window
                }
            });

            $('.dialog-viewshed .ui-dialog-titlebar').children(".ui-dialog-title").html(`
            <div class="viewshed-modal-heading">
                <div class="tags">
                    <div class="tag viewshed-tag" style="background-color: ${markerColor}; color: ${fontColor}">${parseInt(vid) + 1}</div>
                    <div class="tag" style="background-color: ${viewshedColor}">
                        ${viewshed.automatic ? 'Automatic' : 'Manual'}
                    </div>
                </div>
            </div>`
            );

            if ($(this).hasClass('btn-horizon')) {
                $(".dialog-viewshed .ui-dialog-content").html(`
                    <img class="horizon-overlay">
                    <img class="horizon-graph profile-${'' + pid}">
                `);
                svg = s.viewsheds.horizonSVG;

                self.cmd('switchPlane', s.array.pid);
                self.cmd('getHorizonProfile', { vid: parseInt(vid) }).then((url) => {
                    var a = document.createElement('a');

                    a.setAttribute('class', 'attach-overlay');
                    a.setAttribute('href',  url);
                    $('.horizon-graph').attr('src', svg);
                    $('.horizon-overlay').attr('src', url);

                    document.body.appendChild(a);
                });
            } else {
                $(".dialog-viewshed .ui-dialog-content").html(`
                    <div class="viewshed-enlarged">
                        <img class="base" src="${viewshed.viewshed}" />
                        <img class="svg" src="${viewshed.overlay.svg}" />
                    </div>
                `);
            }
        });

        // switch to grid
        $('body').on('click', '.grid-view', function () {
            $(this).addClass('active');
            self.viewMode = 'grid';

            self.renderState();
        });

        // switch to list
        $('body').on('click', '.list-view', function () {
            $(this).addClass('active');
            self.viewMode = 'list';

            self.renderState();
        });

        // switch to list
        $('body').on('change', '#toggle-measurement-units', function () {

            self.renderState();
        });
    }


    setDensity(value) {
        $(this.spinnerSelector).spinner('value', value);

        this.options.tool.cmd('setAutoGridDensity', this.convToPerSqM(value), 3);
        this.options.tool.cmd('proposeAutoViewsheds');
    }

    /**
     *
     * @param {AUTO_GEN_STRATEGIES[keyof AUTO_GEN_STRATEGIES]} strategy
     */
    setAutoStrategy(strategy) {
        this.cmd('setAutoStrategy', strategy);
        switch (strategy) {
            case 'density-grid':
                $(GRID_DENSITY_CONTROLS_SELECTOR).removeClass('visibility-hidden');
                $(AUTO_GEN_DENSITY_GRID_MODE_SELECTOR).addClass('active');
                $(AUTO_GEN_MOD_CENTER_MODE_SELECTOR).removeClass('active');
                break;
            case 'module-center':
                $(GRID_DENSITY_CONTROLS_SELECTOR).addClass('visibility-hidden');
                $(AUTO_GEN_DENSITY_GRID_MODE_SELECTOR).removeClass('active');
                $(AUTO_GEN_MOD_CENTER_MODE_SELECTOR).addClass('active');
                break;
        }
        this.cmd('proposeAutoViewsheds');
    }

    /**
     * Indicate that auto-generation is available (unavailable) by showing (hiding)
     * the Generate button and hiding (showing) the Delete All Auto button.
     * @param {boolean} visible
     */
    setGenerateVisible(available = true) {
        if (available) {
            $(AUTO_GEN_BUTTON_SELECTOR).removeClass('hidden');
            $(DELETE_ALL_AUTO_SELECTOR).addClass('hidden');
            return;
        }

        $(AUTO_GEN_BUTTON_SELECTOR).addClass('hidden');
        $(GRID_DENSITY_CONTROLS_SELECTOR).addClass('visibility-hidden');
        $(DELETE_ALL_AUTO_SELECTOR).removeClass('hidden');
    }


    renderState() {
        const s = Object.freeze(this.getState());
        const pid = s.array.pid;
        const units = (measurementUnits === MeasurementUnits.METERS) ? "m" : "ft";

        const viewsheds = s.viewsheds.viewsheds[pid];
        const autoCount = viewsheds.filter(v => v.automatic).length;

        const t = guiSidebarViewshedTemplate({
            pid,
            units,
            hasArray: s.array.qty > 0,
            hasAutoViewsheds: autoCount > 0,
            spinnerMin: this.convFromPerSqM(0.1, 3),
            spinnerMax: this.convFromPerSqM(5, 3),
            spinnerStep: units === 'ft' ? .01 : 0.1,
            spinnerValue: this.convFromPerSqM(s.viewsheds.autoGridDensity, 3)
        });

        $(this.options.selector).html(t);

        $('button.add-viewshed').focus();

        tippy('.shading-container [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'top',
        });

        tippy('.shading-container .qmark-info', {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            interactive: true
        });

        this.options.tiltAzimuthWidget.renderState();

        const shadingValues = new ShadingValuesGui({
            selector: '.shading-values',
            getState: this.getState,
            mini: true
        });

        shadingValues.renderState();

        // active tab
        $('#sw-option-manual-viewsheds').toggleClass('active', SidebarGUI.viewshedModeToggle === 'manual');
        $('#sw-option-auto-viewsheds')  .toggleClass('active', SidebarGUI.viewshedModeToggle === 'auto');

        $('.viewsheds-manual').toggleClass('hidden', SidebarGUI.viewshedModeToggle === 'auto');
        $('.viewsheds-auto').toggleClass('hidden', SidebarGUI.viewshedModeToggle === 'manual');

        // spin box
        $(this.spinnerSelector).spinner({
            spin: (_, ui) => this.setDensity(ui.value)
        });


        SidebarViewshedGui.autoViewshedCount = autoCount;

        // auto controls
        if (autoCount > 0) {
            this.setGenerateVisible(false);
        } else if (s.viewsheds.toolState === viewshedTool.states.AUTO) {
            GUI.toggleEnable(AUTO_GEN_BUTTON_SELECTOR)(false);

            $(this.spinnerSelector).spinner('disable');

            $('.auto-viewshed-inputs').css('cursor', 'not-allowed');
            $(this.deleteAllSelector).addClass('hidden');
        } else {
            GUI.toggleEnable(AUTO_GEN_BUTTON_SELECTOR)(true);

            $(this.spinnerSelector).spinner('enable');

            $('.auto-viewshed-inputs').css('cursor', 'auto');
            $(this.deleteAllSelector).addClass('hidden');
        }

        // viewshed listing
        let tViewsheds = '';

        if (viewsheds.length === 0) {
            tViewsheds = `
                <div class="empty">
                    <img src="/styles/images/scanifly-illustration-viewsheds.svg" class="viewshed-illustration">
                </div>
            `;
        } else {
            const s = this.getState();
            for (var i = viewsheds.length - 1; i >= 0; i--) {
                const v = viewsheds[i];

                const display_value = s.viewsheds.displayValue == viewshedTool.displayValues.ASA ? v.solarAccess : v.TSRF;
                const markerColor = `#${InfernoGradient.value(display_value / 100).getHexString()}`;
                const viewshedColor = v.automatic
                    ? Colors.Scanifly.teal()
                    : Colors.Scanifly.red(); // either red (#FF4D4D) or teal (#0DCAD3)
                const fontColor = display_value >= 84 ? Colors.darkGray() : Colors.white();

                tViewsheds += `
                    <div class="values-actions">
                        <div class="viewshed">
                            <input type="checkbox" class="selected-viewshed" data-vid="${i}"/>

                            <div class="tags">
                                <div class="tag" style="background-color: ${markerColor}; color: ${fontColor}">${i + 1}</div>
                                <span style="color: ${viewshedColor}"><i class="fa fa-square viewshed-type" aria-hidden="true"></i></span>
                            </div>

                            <button class="sy icon btn-horizon viewshed-modal" data-pid="${pid}" data-vid="${i}" data-tippy-content="Horizon Profile">
                                <img src="/styles/images/icons/obstruction-elevation-icon.svg">
                            </button>
                            <button class="sy icon btn-viewshed viewshed-modal" data-pid="${pid}" data-vid="${i}" data-tippy-content="Viewshed">
                                <img src="/styles/images/icons/viewshed-icon.svg">
                            </button>

                            <div class="values-actions">
                                <div class="viewshed-thumbnail viewshed-modal thumbnail-${'' + pid + i + 1}" data-vid="${i}">
                                    <img class="base" src="${v.viewshed}" /><img class="svg" src="${v.overlay.svg}" />
                                </div>

                                <div class="viewshed-content">
                                    <div class="shading-values-viewshed">
                                        <p> ASA: <span class="shade-value">${v.solarAccess}% </span> </p>
                                        <p> TOF: <span class="shade-value">${v.TOF}% </span> </p>
                                        <p> TSRF: <span class="shade-value">${v.TSRF}% </span> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>`;
            }
        }
        $(`#viewsheds-${pid}`).html(tViewsheds);

        if(this.selectedViewsheds.length) {
            $('input.selected-viewshed[data-vid=' + this.selectedViewsheds.join('],[data-vid=') + ']').prop('checked', true);
        }
        this.updateSelectAllViewshedsCheckboxState();
        this.updateSelectedViewshedsActions();
        this.updateDisplayedValueAsaOrTsrf();

        var i;
        const listView = ['.btn-horizon', '.viewshed-content', '.shading-values-viewshed'];
        if( viewsheds.length == 0 ) {
            $('.list-view').attr('disabled', true);
            $('.grid-view').attr('disabled', true);
        } else if (this.viewMode === 'list') {
            $('.list-view').addClass('active');
            $('.grid-view').removeClass('active');
            $('.viewshed-thumbnail').addClass('hidden');
            $('.btn-viewshed').removeClass('hidden');
            for (i = 0; i < listView.length; i++) {
                $(listView[i]).addClass('list-view');
            }
            $('.viewshed').addClass('viewshed-list-view');
            $('.viewshed-list-view').removeClass('viewshed');
        } else {
            $('.list-view').removeClass('active');
            $('.grid-view').addClass('active');
            $('.viewshed-thumbnail').removeClass('hidden');
            $('.btn-viewshed').addClass('hidden');
            for (i = 0; i < listView.length; i++) {
                $(listView[i]).removeClass('list-view');
            }
            $('.viewshed-list-view').addClass('viewshed');
            $('.viewshed').removeClass('viewshed-list-view');
        }
    }

    /** @type {(v: number, k: number) => number} */
    convToPerSqM(v, k) {
        return measurementUnits === MeasurementUnits.METERS ? v : perFtSqToPerMSq(v, k);
    }

    /** @type {(v: number, k: number) => number} */
    convFromPerSqM(v, k) {
        return measurementUnits === MeasurementUnits.METERS ? v : perMSqToPerFtSq(v, k);
    }

    /* viewer backend works in meters */
}


export { SidebarViewshedGui };
