

class LearnMoreDialogGUI {
    init() {
        let self = this;

        $("#learnMore").dialog({
            dialogClass: "no-close",
            autoOpen: false,
            resizable: false,
            position: {
                my: "center",
                at: "center",
                of: window
            },
            textAlign: "center",
            margin: "6px 0px -4px",
            height: 720,
            width: 900,
            modal: true,
            buttons: [ {
                text: "Next",
                "class": "learn-more-btn",
                click: function() {
                    self.slideOneToTwo();
                }
            }]
        })

        $("#learnMore").siblings(".ui-dialog-buttonpane").children(".ui-dialog-buttonset").addClass("button-set-custom")
        $("#learnMore").siblings(".ui-dialog-buttonpane").addClass("button-pane-custom")

        $('body').on('click', 'a.learn-more',  function () {
            $("#learnMore").dialog("open");

            // Refactor this for use elsewhere
            function initializedAndOpen($sel) {
                if($($sel).hasClass("ui-dialog-content") && $($sel).dialog("isOpen")) {
                    $($sel).dialog( "close" );
                }
            }

            // Should we close dialog here?
            let confirmOne = "#dialog-confirm-mode-1"
            let confirmTwo = "#dialog-confirm-mode-2"

            if(initializedAndOpen(confirmOne)) {
                // $(confirmOne).dialog("close")
            }
            if(initializedAndOpen(confirmTwo)) {
                // $(confirmTwo).dialog("close")
            }
        });

        $("body").on("click", ".learn-more-exit", function () {
            if($("#learnMore").hasClass("ui-dialog-content") && $("#learnMore").dialog("isOpen")) {
                $("#learnMore").dialog( "close" );
            }

            if($("#learnMore-2").hasClass("ui-dialog-content") && $("#learnMore-2").dialog("isOpen")) {
                $("#learnMore-2").dialog( "close" );
            }

            if($("#learnMore-3").hasClass("ui-dialog-content") && $("#learnMore-3").dialog("isOpen")) {
                $("#learnMore-3").dialog( "close" );
            }
        });
    }


    slideOneToTwo() {
        var self = this;

        $("#learnMore").dialog("close")

        // MODAL #2
        $("#learnMore-2").dialog({
            dialogClass: "no-close",
            autoOpen: false,
            resizable: false,
            position: {
                my: "center",
                at: "center",
                of: window
            },
            textAlign: "center",
            margin: "6px 0px -4px",
            height: 720,
            width: 900,
            modal: true,
            buttons: [
                {
                    text: "Next",
                    "class": "learn-more-btn",
                    click: function() {
                        self.slideTwoToThree();
                    }
                },
                {
                    text: "Back",
                    "class": "learn-more-btn",
                    click: function() {
                        $("#learnMore-2").dialog("close")
                        $("#learnMore").dialog("open")
                    }
                }
            ]
        });

        $("#learnMore-2").siblings(".ui-dialog-buttonpane").children(".ui-dialog-buttonset").addClass("button-set-custom")
        $("#learnMore-2").siblings(".ui-dialog-buttonpane").addClass("button-pane-custom")
        $("#learnMore-2").dialog("open")
    }


    slideTwoToThree() {
        $("#learnMore-2").dialog("close")

        // MODAL #3
        $("#learnMore-3").dialog({
            dialogClass: "no-close",
            autoOpen: false,
            resizable: false,
            position: {
                my: "center",
                at: "center",
                of: window
            },
            textAlign: "center",
            margin: "6px 0px -4px",
            height: 720,
            width: 900,
            modal: true,
            buttons: [
                {
                    text: "Done",
                    "class": "learn-more-btn",
                    click: function() {
                        $("#learnMore-3").dialog("close")
                    }
                },
                {
                    text: "Back",
                    "class": "learn-more-btn",
                    click: function() {
                        $("#learnMore-3").dialog("close")
                        $("#learnMore-2").dialog("open")
                    }
                }
            ]
        })

        $("#learnMore-3").siblings(".ui-dialog-buttonpane").children(".ui-dialog-buttonset").addClass("button-set-custom")
        $("#learnMore-3").siblings(".ui-dialog-buttonpane").addClass("button-pane-custom")
        $("#learnMore-3").dialog("open")
    }
}

export { LearnMoreDialogGUI };
