export  var areaAndExcavate = function (callback) {
    const template = [
        '<h3 class="header area-and-excavate">Area and Excavate</h3>',
        '<div class="tool-options">',
            '<div class="option">',
                '<div class="label">',
                    'Area:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<span class="excavate-area">0.00</span>',
                '</div>',
                '<div class="input-wrapper second">',
                    '<select class="input-area-units" name="area units">',
                        '<option value="meters">meters &sup2;</option>',
                        '<option value="feet">feet &sup2;</option>',
                    '</select>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',

            '<div class="option">',
                '<div class="label">',
                    'Depth:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<input type="text" class="input-excavate-depth" />',
                '</div>',
                '<div class="input-wrapper second"> inches </div>',
                '<div class="clearfix"></div>',
            '</div>',

            '<div class="option">',
                '<div class="label">',
                    'Excavate:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<span class="excavate-volume">0.00</span>',
                '</div>',
                '<div class="input-wrapper second">',
                    '<select class="input-excavate-units" name="excavate units">',
                        '<option value="meters">meters &sup3;</option>',
                        '<option value="feet">yards &sup3;</option>',
                    '</select>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',

            '<div class="option">',
                '<div class="label">',
                    '&nbsp;',
                '</div>',
                '<div class="input-wrapper">',
                    '<button class="sy input-excavate-clear"><i class="fa fa-times" aria-hidden="true"></i> Clear</button>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',
        '</div>'
    ].join("\n");

    $('.placeholder.area-and-excavate').replaceWith(template);
    $('.header.area-and-excavate').click(callback);
};

export function areaAndExcavateAreaUnits(value, callback) {
    $('.input-area-units').val(value);

    if (callback) {
        $('.input-area-units').change(function () {
            callback($(this).val());
        });
    }
};

export function areaAndExcavateDepth(value, callback) {
    var input = $('.input-excavate-depth');
    input.val(value);

    if (callback) {
        input.change(function () {
            $(this).val(callback($(this).val()));
        })
    }
};

export function areaAndExcavateVolumeUnits(value, callback) {
    $('.input-excavate-units').val(value);

    if (callback) {
        $('.input-excavate-units').change(function () {
            callback($(this).val());
        });
    }
};

export function areaAndExcavateArea(value) {
    $('.excavate-area').html(value);
};

export function areaAndExcavateVolume(value) {
    $('.excavate-volume').html(value);
};

export function areaAndExcavateClear (callback) {
    $('.input-excavate-clear').click(callback);
};
