import * as THREE from './three.module';

class Viewshed extends THREE.Object3D {
    constructor(size) {
        super();

        this.type = 'Viewshed';

        this.scale.z = -1;

        this.materials = [];

        var shaderMaterial = new THREE.ShaderMaterial({
            uniforms: {
                "texid": { type: "t" },
                "side": { type: "i", value: 1 },
                "output_w": { type: "f" }
            },
            vertexShader: [
                "varying vec2 vUv;",

                "void main() {",
                "  vUv = uv;",
                "  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
                "}"
            ].join("\n"),

            fragmentShader: [
                "varying vec2 vUv;",

                "uniform sampler2D texid;",
                "uniform int side;",
                "uniform float output_w;",

                "void main() {",
                "  vec2 uv = (vUv*2.0)-1.0;",

                "  if ((side == 2 && uv.x > 0.0) || (side == 3 && uv.x <= 0.0)) {",
                "    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);",
                "    return;",
                "  }",

                // spherical projection
                "  float z = sqrt(1.0 - uv.x*uv.x - uv.y*uv.y);",

                "  if (side == 1) {",
                "    float a = 1.0 / z;",
                "    uv.x = uv.x * a;",
                "    uv.y = uv.y * a;",
                "  } else if (side >= 2) {",
                "    float a = 1.0 / uv.x;",
                "    uv.x = z * -a;",
                "    uv.y = uv.y * abs(a);",
                "  }",

                "  uv = (uv + 1.0) / 2.0;",

                "  if (uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0 || uv.y > 1.0) {",
                "    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);",
                "    return;",
                "  }",

                // make the image circular
                "  float x = gl_FragCoord.x - output_w / 2.0;",
                "  float y = gl_FragCoord.y - output_w / 2.0;",

                "  if (sqrt(x*x + y*y) >= output_w / 2.0) {",
                "    gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);",
                "    return;",
                "  }",

                "  gl_FragColor = texture2D(texid, uv);",

                "  vec4 clear      = vec4(0.49, 0.0, 1.0, 1.0);",
                "  vec4 masked     = vec4(1.0, 0.0, 1.0, 1.0);",
                "  vec4 threshold  = vec4(0.01, 0.01, 0.01, 1.0);",
                "  vec4 threshold2 = vec4(0.01, 1.0, 0.01, 1.0);",

                // this looks weird, but for some reason Chrome on Linux crashes
                // when it's written as a single if clause; not sure why...
                "  if (all(lessThan(abs(gl_FragColor - clear), threshold))) {",
                // replace the clear color with white
                "      gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);",
                "  } else if (all(lessThan(abs(gl_FragColor - masked), threshold2))) {",
                // keep the color
                "  } else {",
                // replace with grey
                "      gl_FragColor = vec4(0.267, 0.267, 0.267, 1.0);",
                "  }",
                "}",

                ""
            ].join("\n"),
            transparent: true,
            depthTest: false
        });
        shaderMaterial.uniforms['side'].value = 1;
        this.materials.push(shaderMaterial);

        var mesh = new THREE.Mesh(new THREE.PlaneGeometry(size, size, 1, 1), shaderMaterial);
        mesh.rotation.x = -Math.PI / 2;
        mesh.rotation.z = -Math.PI / 2;
        this.add(mesh);

        var material = shaderMaterial.clone();
        material.uniforms['side'].value = 2;
        this.materials.push(material);

        var mesh = new THREE.Mesh(new THREE.PlaneGeometry(size, size, 1, 1), material);
        mesh.rotation.x = -Math.PI / 2;
        this.add(mesh);

        var material = shaderMaterial.clone();
        material.uniforms['side'].value = 3;
        this.materials.push(material);

        var mesh = new THREE.Mesh(new THREE.PlaneGeometry(size, size, 1, 1), material);
        mesh.rotation.x = -Math.PI / 2;
        this.add(mesh);

        var material = shaderMaterial.clone();
        material.uniforms['side'].value = 3;
        this.materials.push(material);

        var mesh = new THREE.Mesh(new THREE.PlaneGeometry(size, size, 1, 1), material);
        mesh.rotation.x = -Math.PI / 2;
        mesh.rotation.z = -Math.PI / 2;
        this.add(mesh);

        var material = shaderMaterial.clone();
        material.uniforms['side'].value = 3;
        this.materials.push(material);

        var mesh = new THREE.Mesh(new THREE.PlaneGeometry(size, size, 1, 1), material);
        mesh.rotation.x = -Math.PI / 2;
        mesh.rotation.z = Math.PI / 2;
        this.add(mesh);

        this.setRenderTargets = function (targets) {
            for (var i = 0; i < this.materials.length; i++) {
                this.materials[i].uniforms['texid'].value = targets[i].texture;
            }
        };

        this.setOutputWidth = function (w) {
            this.outputWidth = w;

            for (var i = 0; i < this.materials.length; i++) {
                this.materials[i].uniforms['output_w'].value = w;
            }
        };
    }
}


export { Viewshed };
