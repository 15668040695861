



class Mouse {
    constructor() {
        this.cursorMap = {};
    }

    // TODO: move other scattered mouse code that uses jQuery here

    /**
     * Change the cursor if at least one caller requests it; if not, switch to default.
     */
    setCursor(cursor, caller, val) {
        if (this.cursorMap[cursor] === undefined)
            this.cursorMap[cursor] = {};

        this.cursorMap[cursor][caller] = val;

        this._setCanvasStyle();
    }


    /**
     * Reset all cursor shapes for caller to default.
     */
    clearCursor(caller) {
        for (let cursor in this.cursorMap) {
            if (this.cursorMap[cursor][caller]) {
                this.cursorMap[cursor][caller] = false;
            }
        }

        this._setCanvasStyle();
    }


    _setCanvasStyle() {
        let setDefault = true;

        for (let cName in this.cursorMap) {
            let c = Object.values(this.cursorMap[cName]).some(x => x);

            if (c) {
                $('body canvas').css('cursor', cName);
                setDefault = false;
                break;
            }
        }

        if (setDefault)
            $('#container3d canvas').css('cursor', 'default');
    }
}

export { Mouse };
