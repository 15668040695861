/// @ts-check

import { Vector2 } from './three.module';


class DensityGrid {
    /**
     * @param {number} rho
     */
    constructor(rho) {
        if (rho <= 0) {
            throw new Error("Grid density must be positive");
        }
        this.rho = rho;
    }

    /**
     * Extract a collection of points in a regular grid bounded by {ll} and {ur}.
     *
     * The points are selected such that:
     *   - the corners of the bounding box are included
     *   - the density of points in the grid is at least {this.rho}
     *   - within the first two constraints, the number of points along each axis
     *     is roughly the same proportion as the length of the axes
     *
     * @param {Vector2} ll
     * @param {Vector2} ur
     * @returns {Generator<Vector2>}
     */
    *gridFromBoundsCorners(ll, ur) {
        const {x, y} = ur.clone().sub(ll);

        // wtf is this monster?
        // dividing grid into nx columns and ny rows corner aligned
        // is (nx + 1) * (ny + 1) grid points which must be at least rho * x * y
        // substituting for ny using the aspect ratio ansatz (nx / ny ~= x / y)
        // yields (nx + 1) * (y * nx / x + 1) >= p * x * y
        // to which (per wolfram alpha because ain't no one got time for the quadratic
        // formula in symbolic coefficients) the smallest integral solution is:
        const nx = Math.ceil(
            (y * (Math.sqrt(4 * this.rho * x * x + (x - y) ** 2 / y ** 2) - 1) - x) / (2 * y)
        );
        const ny = Math.ceil(y * nx / x);

        const deltaX = x / nx;
        const deltaY = y / ny;

        for (let idxX = 0; idxX <= nx; ++idxX) {
            for (let idxY = 0; idxY <= ny; ++idxY) {
                yield new Vector2(idxX * deltaX, idxY * deltaY).add(ll);
            }
        }
    }

    /**
     * @see {gridFromBoundsCorners}
     * except that the points at the centers, rather than corners,
     * of each grid cell are output
     *
     * @param {Vector2} ll
     * @param {Vector2} ur
     * @returns {Generator<Vector2>}
     */
    *gridFromBoundsCenters(ll, ur) {
        const {x, y} = ur.clone().sub(ll);

        // this one is comparatively easy: nx * ny >= rho * x * y
        // nx * (y * nx / x) >= rho * x * y => nx^2 >= rho * x^2
        const nx = Math.ceil(x * Math.sqrt(this.rho));
        const ny = Math.ceil(y * Math.sqrt(this.rho));

        const deltaX = x / nx;
        const deltaY = y / ny;

        for (let idxX = 0; idxX < nx; ++idxX) {
            for (let idxY = 0; idxY < ny; ++idxY) {
                yield new Vector2((idxX + 0.5) * deltaX, (idxY + 0.5) * deltaY).add(ll);
            }
        }
    }

    /**
     * @param {Vector2} ll
     * @param {Vector2} ur
     * @returns {Generator<Vector2>}
     */
    *gridFromBoundsCornersCheckerboard(ll, ur) {
        const {x, y} = ur.clone().sub(ll);

        // - number of points is (nx + 1) * ceil(ny / 2) + nx * floor(ny / 2) in cornered box
        //   starting with corner offset in x and alternating corner/center with y
        // - Density requires nx * ny + ceil(ny / 2) >= p * x * y (simplifying the above)
        // - It suffices to take ny so that nx * ny + ny / 2 >= p * x * y
        // - Substitute ny = y * nx / x (aspect ratio ansatz) and solve resultant inequality to get:
        const nx = Math.ceil((Math.sqrt(16 * this.rho * x * x + y * y) - y) / 4);
        const ny = Math.ceil(y * nx / x);

        const deltaX = x / nx;
        const deltaY = y / ny;

        for (let idxY = 0; idxY <= ny; ++idxY) {
            const offset = idxY % 2 === 1 ? 0.5 : 0;
            const nMax = idxY % 2 === 1 ? nx - 1 : nx;

            for (let idxX = 0; idxX <= nMax; ++idxX) {
                yield new Vector2(
                    (idxX + offset) * deltaX,
                    idxY * deltaY
                ).add(ll);
            }
        }

    }

    /**
     * @param {Vector2} ll
     * @param {Vector2} ur
     */
    *gridFromBoundsCentersCheckerboard(ll, ur) {
        const {x, y} = ur.clone().sub(ll);

        const nx = Math.ceil((Math.sqrt(16 * this.rho * (x * x) + 1) - 1) / 4);
        const ny = Math.ceil(y * nx / x);

        const deltaX = x / nx;
        const deltaY = y / ny;
        const offset = new Vector2(deltaX / 2, deltaY / 2);

        yield* this.gridFromBoundsCornersCheckerboard(
            ll.clone().add(offset),
            ur.clone().sub(offset)
        );
    }
}


export { DensityGrid };
