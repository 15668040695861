import { project, currentDesign, moduleSpecsTool, viewshedTool } from "../Viewer"

export const getShadeDataCsv = async () => {

    const solarAccess = await moduleSpecsTool.getMonthlySolarAccess();
    const lat = viewshedTool.lat;
    const lng = viewshedTool.lng;
    const url = `https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=${lat}&lon1=${lng}&key=zNEw7&resultFormat=json`;
    let magDec = undefined;
    try {
        const response = await fetch(url);
        const declinationData = await response.json();
        magDec = declinationData.result[0]['declination'];
    } catch (e) {
        console.error(e);
    }
    if(undefined === magDec) {
        magDec = 0;
    }

    const numViewsheds = viewshedTool.getViewshedLength();

    const averages = solarAccess.averages;
    const annualAverage = averages.reduce((prev, cur) => prev += cur, 0)/averages.length;
    const avgForMonths = months => {
        const avgs = months.map(month => averages[month-1]);
        return avgs.reduce((p, c) => p += c)/avgs.length;
    }
    const mayOct = [3, 4, 5, 6, 7, 8, 9, 10];
    const mayOctAvg = avgForMonths(mayOct);

    const novApr = [10, 11, 12, 1, 2, 3, 4];
    const navAprAvg = avgForMonths(novApr);

    const csvLines = [];
    csvLines.push('Average Solar Access 1.0');
    csvLines.push('Session Name:,' + project.name);
    const now = new Date();
    const dateString =  Intl.DateTimeFormat().format(now) + ' ' + Intl.DateTimeFormat(undefined, {hour:'numeric', hour12:false, minute:'numeric'}).format(now);
    csvLines.push('Creation Date:,' + dateString);
    csvLines.push('Latitude:,' + Math.round(viewshedTool.lat * 10)/10);
    csvLines.push('Longitude:,' + Math.round(viewshedTool.lng * 10)/10);
    csvLines.push('Mag Dec:,' + Math.round(magDec*10)/10);
    const timezone = 'GMT' + (viewshedTool.UTCOffset >= 0 ? '+' : '-') + String(Math.abs(viewshedTool.UTCOffset)).padStart(2, 0) + ':00';
    csvLines.push('Time Zone:,' + timezone);
    csvLines.push('Number of viewsheds:,' + numViewsheds);
    csvLines.push('');
    csvLines.push(`The values reported are average percentages of all the viewsheds (${numViewsheds}) contained in this session`)
    csvLines.push('');
    csvLines.push('begin data');
    csvLines.push(`Annual:,${Math.round(annualAverage)}`);
    csvLines.push(`May-Oct:,${Math.round(mayOctAvg)}`);
    csvLines.push(`Nov-Apr:,${Math.round(navAprAvg)}`);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    months.forEach((m, idx) => csvLines.push(m + ':,' + Math.round(averages[idx])));

    return csvLines.join('\n');
}