



function vegetationAnnotationsTool(callback) {
    var template = [
        '<h3 class="header vegetation-annotations-tool">Seed Vegetation</h3>',
        '<div class="tool-options">',
            '<div class="vegetation-annotations">',
            '</div>',

            '<div class="vegetation-annotations-empty">',
                'Click anywhere on the model to begin seeding.',
            '</div>',
        '</div>'
    ].join("\n");

    $('.seed-empty').replaceWith(template);
    callback();
}

function vegetationAnnotationsToolUpdateAnnotations(tool, seeds) {

    $(".veg-annotation-markers").empty()

    if (seeds.length == 0) { return }

    var list = $(".model-list li")
    var guiNames = []
    var count = list.length

    list.each(function(idx, item) {
        if(item.className.includes("vegetation")) {
            // Only remove imported models
            guiNames.push(item)
            item.remove();
        }
    })

    var markerTemplate = [
        '<div class="veg-annotation-marker" data-id="{ID}">',
            '{NUMBER}',
        '</div>'
    ].join('\n');


    var updatedTemplate = [
        '<li class="model-item model-item-vegetation-{ID}" data-id={ID}>',
            '<div class="row-title">',
                '<input type="checkbox" value="{ID}"/>',
                '<div class="info">{VEGETATION}</div>',
            '</div>',
            '<div class="actions">',
                    'H: <input class="input-height" step="0.01"  type="number" value="{HEIGHT}" />',
                    'W: <input class="input-width" step="0.01" type="number" value="{WIDTH}" />',
                    'D: <input style="background-color: #ccc;" disabled class="input-depth" aria-disabled="true" type="number" value="{DIAMETER}" />',
                    '<button class="delete">',
                        '<img src="styles/images/icons/trash-icon.svg" alt="">',
                        '',
                    '</button>',
                    '<button class="hide">',
                        'H',
                    '</button>',
            '</div>',
        '</li>',
        ].join('\n');

    for (var i = 0; i < seeds.length; i++) {
        var template = updatedTemplate;

        if (seeds[i].dirty) {
            template = template.replace(/\{ID\}/g, i);
            template = template.replace(/\{VEGETATION\}/g, "New model");
        } else {
            template = template.replace(/\{ID\}/g, i);
            // template = template.replace(/\{VEGETATION\}/g, seeds[i].name)
            template = template.replace(/\{VEGETATION\}/g, `${seeds[i].name} #${i+1}`)
            template = template.replace(/\{HEIGHT\}/g, seeds[i].height)
            template = template.replace(/\{WIDTH\}/g, seeds[i].width)
        }

        var m = markerTemplate.replace(/\{NUMBER\}/g, i + 1);
        m = m.replace(/\{ID\}/g, i);

        $($(m)).appendTo('.veg-annotation-markers');

        var coords = tool.getScreenCoordinates(seeds[i]);

        // Marking the annotation
        var marker = $('.veg-annotation-marker:last-child');
        var width = marker.width();
        var height = marker.height();
        marker
            .css('top',     (coords.pos.y - height) + 'px')
            .css('left',    (coords.pos.x - width) + 'px')
            .css('opacity', (coords.occluded && i !== seeds.length - 1) ? 0.1 : 1.0);

    }
}

function vegetationAnnotationsToolUpdateMarkerPositions(tool, annotations) {
    for (var i = 0; i < annotations.length; i++) {
        var coords = tool.getScreenCoordinates(annotations[i]);

        var marker = $('.veg-annotation-marker:eq(' + i + ')');

        var width = marker.width();
        var height = marker.height();
        marker
            .css('top',     (coords.pos.y - height) + 'px')
            .css('left',    (coords.pos.x - width) + 'px')
            .css('opacity', coords.occluded ? 0.1 : 1.0)
            ;

        if (tool.popup == i) {
            $('#veg-annotation-marker-popup')
                .css('top',  (coords.pos.y + 10) + 'px')
                .css('left', (coords.pos.x + 10) + 'px')
            ;
        }
    }
}

function vegetationAnnotationsToolFollowModelPosition(tool, annotations, idToUpdate, pos) {
    var modelId = idToUpdate.split("-")[1]

    if(annotations[parseInt(modelId)]) {
        annotations[parseInt(modelId)].position.x = pos.x
        annotations[parseInt(modelId)].position.y = pos.y
        annotations[parseInt(modelId)].position.z = pos.z
    }
}

export { vegetationAnnotationsTool, vegetationAnnotationsToolUpdateAnnotations, vegetationAnnotationsToolUpdateMarkerPositions, vegetationAnnotationsToolFollowModelPosition }
