import { Colors } from '../core/Colors.js';
import { Object3D, RepeatWrapping, Sprite, SpriteMaterial, Texture } from '../libs/three.module';
import { cameraOrtho } from '../Viewer.js';

class SegmentAnnotation {
    constructor(options) {
        this.options = Object.assign({
            borderColor: Colors.gray(),
            bgColor:     Colors.Scanifly.blue(),
            color:       Colors.white(),
            fontSize:    14,
            radius:      3,
            offset:      6,
        }, options || { });

        this.rectW = 256;
        this.rectH = 64;

        this.sprite = new Sprite();

    }

    show(val = true) {
        this.sprite.visible = val;

        if (this.doubleSprite){
            this.doubleSprite.visible = val;
        }
    }

    hide() { this.show(false); }

    getSprite() {

        if (this.doubleSprite) {
            return this.doubleSprite;
        }

        let obj = new Object3D();

        if (this.sprite.material.depthTest && this.texture) {
            const spriteMaterial = new SpriteMaterial({
                map:         this.texture,
                color:       0xffffff,
                transparent: true,
                opacity:     1,
                depthTest:   false
            });

            var s = new Sprite(spriteMaterial);

            s.scale.copy(this.sprite.scale);

            obj.add(s);
        }

        this.doubleSprite = obj;

        return this.doubleSprite;
    }

    setupSprite(w, h) {
        // tweak the canvas pixel ratio to prevent blurry text
        var ratio = 4;

        this.canvas = document.createElement('canvas');
        this.canvas.width = w * ratio;
        this.canvas.height = h * ratio;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        this.ctx.font = this.options.fontSize + "px 'Poppins',Helvetica,Arial,sans-serif";
        // canvas contents will be used for a texture
        this.texture = new Texture(this.canvas);
        this.texture.wrapT= RepeatWrapping;

        var spriteMaterial = new SpriteMaterial({
            map:   this.texture,
            color: 0xffffff,
        });

        this.sprite.material = spriteMaterial;
    }

    scale(factor) {
        this.doubleSprite.children.forEach(c => c.scale.set(factor, factor, 1));
    }

    setTextRectangle(text) {

        text = ' ' + text.length<11 ? text: (text.substring(0,12)+'...') + ' ';
        var w = 2*this.options.radius;
        var h = 2*this.options.radius;

        this.setupSprite(w, h);
        this.sprite.scale.set(2, 2, 1);

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();

        // DEV uncomment to draw whole sprite
        //this.ctx.fillStyle = '#ffff0055';
        //this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        var textW = this.ctx.measureText(text).width;

        // starting point
        this.ctx.translate(w/2, h/2);

        // fill
        this.ctx.fillStyle = this.options.bgColor;


        this.roundRect(this.ctx, (-textW/2)-10, -h/2+45, textW+20, 38, 20 * 0.2);


        this.ctx.fill();

        // text
        this.ctx.fillStyle = this.options.color;
        this.ctx.textBaseline = 'middle';

        this.ctx.fillText(text, -textW/2,0);

        this.ctx.restore();
        this.texture.needsUpdate = true;

    }

    setPosition(vScreen) {
        var v = vScreen.clone().unproject(cameraOrtho);

        this.sprite.position.set(v.x + this.rectW/2,
                                 v.y - this.rectH/2,
                                 1);
    }

    roundRect(ctx, x, y, w, h, radius) {
        if (typeof radius === 'number')
            radius = {
                tl: radius,
                tr: radius,
                br: radius,
                bl: radius
            };

        ctx.beginPath();

        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + w - radius.tr, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + radius.tr);

        ctx.lineTo(x + w, y + h - radius.br);
        ctx.quadraticCurveTo(x + w, y + h, x + w - radius.br, y + h);

        ctx.lineTo(x + radius.bl, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - radius.bl);

        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);

        ctx.closePath();
    }
}

export { SegmentAnnotation };
