import { ShaderMaterial, UniformsUtils, UniformsLib } from './three.module';

var occ_shadow_shader = {
    uniforms:  UniformsUtils.merge([
        UniformsLib.common,
        UniformsLib.displacementmap,
        {
            boxOccludersActive:      { value: new Array(8).fill(1) },
            cylinderOccludersActive: { value: new Array(8).fill(1) },

            occluder_count:    { value: 1 },
            occluder_cy_count: { value: 1 },

            bounds_array:    { value: new Array(48).fill(0) },
            bounds_cy_array: { value: new Array(48).fill(0) },

            occluder_transforms:    { value: new Array(128).fill(0) },
            occluder_cy_transforms: { value: new Array(128).fill(0) },
        }
    ]),

    vertexShader: `
#include <common>
#include <uv_pars_vertex>
#include <displacementmap_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

// This is used for computing an equivalent of gl_FragCoord.z that is as high precision as possible.
// Some platforms compute gl_FragCoord at a lower precision which makes the manually computed value better for
// depth-based postprocessing effects. Reproduced on iPad with A10 processor / iPadOS 13.3.1.
varying vec2 vHighPrecisionZW;

/* SCANIFLY-SPECIFIC */
uniform bool boxOccludersActive[8];
uniform bool cylinderOccludersActive[8];

uniform int occluder_count;
uniform int occluder_cy_count;

uniform float bounds_array[48];
uniform float bounds_cy_array[48];

uniform mat4 occluder_transforms[8];
uniform mat4 occluder_cy_transforms[8];

varying float occ_amt;


void main() {

    #include <uv_vertex>

    #include <skinbase_vertex>

    #ifdef USE_DISPLACEMENTMAP

        #include <beginnormal_vertex>
        #include <morphnormal_vertex>
        #include <skinnormal_vertex>

    #endif

    #include <begin_vertex>
    #include <morphtarget_vertex>
    #include <skinning_vertex>
    #include <displacementmap_vertex>
    #include <project_vertex>
    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>

    /* SCANIFLY-SPECIFIC */
    #include <worldpos_vertex>

    occ_amt = 1.0;

    // 8 is max number of occluders supported
    for (int i = 0; i < 8; i++) {
        if (i < occluder_count) {
            if (boxOccludersActive[i]) {
                int ind = i * 6; //6 boundries per set
                vec4 testPos = occluder_transforms[i] * vec4(worldPosition.xyz, 1.0);

                if (testPos.x > bounds_array[ind+0] && testPos.x < bounds_array[ind+1]) {
                    if (testPos.y > bounds_array[ind+2] && testPos.y < bounds_array[ind+3]) {
                        if (testPos.z > bounds_array[ind+4] && testPos.z < bounds_array[ind+5]) {
                            occ_amt = 10.0;
                            gl_Position.z = 10.0;
                        }
                    }
                }
            }
        } else {
            break;
        }
    }

    // cylinders
    for (int i = 0; i < 8; i++) {
        if (i < occluder_cy_count) {
            if (cylinderOccludersActive[i]) {
                vec4 testPos = occluder_cy_transforms[i] * vec4(worldPosition.xyz, 1.0);

                if (testPos.y > bounds_cy_array[i*6] && testPos.y < bounds_cy_array[(i*6)+1]) {
                    // within radius
                    float testPos_dist = distance(testPos.xyz, vec3(0.0, testPos.y, 0.0));

                    if (testPos_dist < bounds_cy_array[(i*6)+2]) {
                        occ_amt = 10.0;
                        gl_Position.z = 10.0;
                    }
                }
            }
        } else {
            break;
        }
    }

    vHighPrecisionZW = gl_Position.zw;
}
`,

    fragmentShader: `
#include <common>
#include <packing>
#include <uv_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

varying vec2 vHighPrecisionZW;
varying float occ_amt;


void main() {

    #include <clipping_planes_fragment>

    vec4 diffuseColor = vec4( 1.0 );

    //jcg hard code 3200 out
    //#if DEPTH_PACKING == 3200

        //diffuseColor.a = opacity;

    //#endif

    #include <map_fragment>
    #include <alphamap_fragment>
    #include <alphatest_fragment>
    #include <logdepthbuf_fragment>

    // Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
    float hdz = vHighPrecisionZW[0];
    float hdw = vHighPrecisionZW[1];
    float fragCoordZ = 0.5 * hdz / hdw + 0.5;

    //jcg hard code 3201
    //#if DEPTH_PACKING == 3200

        //gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity ) * occ_amt;

    //#elif DEPTH_PACKING == 3201

        gl_FragColor = packDepthToRGBA( fragCoordZ )  * occ_amt;

    //#endif
}
`
};


class OccludableShadowMaterial extends ShaderMaterial {
    constructor(config) {
        super({
            uniforms:       occ_shadow_shader.uniforms,
            vertexShader:   occ_shadow_shader.vertexShader,
            fragmentShader: occ_shadow_shader.fragmentShader
        });
    }
}


export { OccludableShadowMaterial };
