import { isLeapYear } from '../workers/Irradiation';
import { UnawareDate } from '../libs/UnawareDate';
import { GUI } from './Gui.js';
import { GMaps } from '../libs/GMaps';





function shadeTool(callback) {
    var template = `
        <h3 class="header shade-tool">Shade Tool</h3>
        <div class="tool-options">
            <div class="option">
                <div class="label">
                    Model opacity:
                </div>
                <div class="input-wrapper first">
                    <div class="slider input-shade-tool-opacity" aria-valuemin="0" aria-valuemax="1" aria-valuenow="1"></div>
                </div>
                <div class="input-wrapper second">
                    <input class="input-shade-tool-opacity-value" type="text" / aria-label="shade tool opacity">
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    &nbsp;
                </div>
                <div class="input-wrapper first">
                    <button class="sy input-shade-location">
                        <i class="fa fa-globe" aria-hidden="true"></i>
                        Adjust Location
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Closest weather station:
                </div>
                <div class="input-wrapper first">
                    <span class="weather-station">Not set</span>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Timezone:
                </div>
                <div class="input-wrapper first">
                    <span class="shade-tool-timezone"></span>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Optimum tilt/azimuth:
                </div>
                <div class="input-wrapper first">
                    <span class="optimum-tilt-azimuth"></span>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Rotate (degrees):
                </div>
                <div class="input-wrapper first">
                    <div class="slider input-rotate-degrees" aria-valuemin="0" aria-valuemax="359.8" aria-valuenow="0"></div>
                </div>
                <div class="input-wrapper second">
                    <input class="input-rotate-degrees-value" type="text" /><br>
                    <input class="input-rotate-degrees-lock" type="checkbox" aria-checked="false" /> &nbsp <i class="fa fa-lock" aria-hidden="true"></i>&nbsp Lock
                </div>
                <div class="label z-label" style="display: none;">
                    Enable Z Adjustment:
                </div>
                <div class="input-wrapper third">
                    <input class="input-admin-z" style="display: none;" type="checkbox" aria-checked="false"/>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Time:
                </div>
                <div class="input-wrapper first">
                    <div class="slider input-shade-time" aria-valuemin="00:00" aria-valuemax="23:59" aria-valuenow="12:00"></div>
                </div>
                <div class="input-wrapper second">
                    <input class="input-shade-time-value" type="text" readonly />
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="option">
                <div class="label">
                    Date:
                </div>
                <div class="input-wrapper first">
                    <div class="slider input-shade-date" aria-valuemin="01/01" aria-valuemax="12/31" aria-valuenow="12/21"></div>
                </div>
                <div class="input-wrapper second">
                    <input class="input-shade-date-value" type="text" />
                </div>
                <div class="clearfix"></div>
            </div>

            <!--
            <div class="option">
                <div class="label">
                    Show Light Cage:
                </div>
                <div class="input-wrapper first">
                    <input class="input-shade-light-cage" type="checkbox" aria-checked="false"/>
                </div>
                <div class="clearfix"></div>
            </div>
            -->
        </div>
    `;

    $('.placeholder.shade-tool').replaceWith(template);

    var self = this;

    $('.header.shade-tool').click(function () {
        callback();
    });
};

function shadeToolOpacity(value, callback) {
    this.rangeInputWidget('.input-shade-tool-opacity', {
            min:   0,
            max:   1.0,
            value: 1.0,
            step:  0.01,
        }, value, callback);
};

function shadeToolLocation(options) {
    var self = this;

    var metersPerPixel = function(lat, zoom) {
        return 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
    };

    var initMap = function() {
        var mapParams = options.mapParams();

        self.map = new google.maps.Map(document.getElementById('map-location'), {
            center:            { lat: mapParams.lat, lng: mapParams.lng },
            streetViewControl: false,
            rotateControl:     false, // do not show 45 degree tilt control
            zoom:              mapParams.zoom,
            mapTypeId:         google.maps.MapTypeId.HYBRID
        });

        self.map.setTilt(0); // do not use 45 degree imagery

        var rectangle = new google.maps.Rectangle();
        var input = document.getElementById('pac-input');
        var searchBox = new google.maps.places.SearchBox(input);
        self.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        self.map.addListener('bounds_changed', function() {
            searchBox.setBounds(self.map.getBounds());

            // draw frame around area for grabbing
            var c = self.map.getCenter();

            var sSize = 512 * metersPerPixel(c.lat(), self.map.getZoom());

            var dLat = google.maps.geometry.spherical.computeOffset(c, sSize/2,  0).lat() - c.lat();
            var dLng = google.maps.geometry.spherical.computeOffset(c, sSize/2, 90).lng() - c.lng();

            var bounds = new google.maps.LatLngBounds(
                {
                    lat: c.lat() - dLat,
                    lng: c.lng() - dLng
                },
                {
                    lat: c.lat() + dLat,
                    lng: c.lng() + dLng
                }
            );

            rectangle.setOptions({
                strokeColor:   '#f3514b',
                strokeOpacity: 0.75,
                strokeWeight:  3,
                map:           self.map,
                bounds:        bounds
            });
        });

        searchBox.addListener('places_changed', function() {
            // show the places from the search bar in the viewport
            var places = searchBox.getPlaces();

            if (places.length == 0)
                return;

            var bounds = new google.maps.LatLngBounds();
            places.forEach(function(place) {
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });

            self.map.fitBounds(bounds);
        });

        var controlDiv = document.createElement('div');
        controlDiv.className = 'grab';
        var grabControl = new GMaps.GrabControl(controlDiv, self.map, function () {
            $('#dialog').dialog('close');

            var center = self.map.getCenter();
            var lat = Math.round(center.lat() * 10000) / 10000;
            var lng = Math.round(center.lng() * 10000) / 10000;

            // update the lat-lng inputs
            self.shadeToolLatitude(lat);
            self.shadeToolLongitude(lng);

            options.setLocation(lat, lng, self.map.getZoom());
        });

        controlDiv.index = 1;
        self.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(controlDiv);
    };

    $('.input-shade-location').click(function (event) {
        event.preventDefault();

        $("#dialog").dialog({
            width:  800,
            height: 640,
            create: function (event, ui) {
                if (!self.map) {
                    initMap();
                    google.maps.event.trigger(self.map, "resize");
                }
            },
            closeOnEscape: true,
            modal: true,
            classes: {
                'ui-dialog': 'input-shade-location'
            },
            open: function (event, ui) {
                google.maps.event.trigger(self.map, "resize");
                $('.ui-widget-overlay').on('click',function(){
                    $('#dialog').dialog('close');
                })
            },
            resize: function (event, ui) {
                google.maps.event.trigger(self.map, "resize");
            }
        });
    });
};

function shadeToolWeatherStationCallback(station) {
    $('.weather-station').html(station.info + ' (' + station.distance + ' miles)');
    $('.optimum-tilt-azimuth').html(station.optTilt + ' tilt, ' + station.optAzimuth + ' azimuth');

    if (station.UTCOffset !== undefined) {
        $('.input-utc-offset').val(station.UTCOffset);
    }
};

function shadeToolRotate(degrees, callback) {
    this.rangeInputWidget('.input-rotate-degrees', {
            min:   0,
            max:   359.9,
            value: degrees,
            step:  0.1,
        }, degrees, callback);
};

function shadeToolRotateEnable(value) {
    if (value) {
        $('.input-rotate-degrees').slider('disable');
        $('.input-rotate-degrees-value').prop('disabled', true);
        $('.input-rotate-degrees-value').attr('aria-disabled', "true");
    } else {
        $('.input-rotate-degrees').slider('enable');
        $('.input-rotate-degrees-value').prop('disabled', false);
        $('.input-rotate-degrees-value').attr('aria-disabled', "false");
    }
};

function shadeToolLockMovement(value, callback) {
    var input = $('.input-rotate-degrees-lock');

    input.prop('checked', value);
    this.shadeToolRotateEnable(value);

    if (callback) {
        var self = this;

        input.change(function () {
            var checked = $(this).is(':checked');

            self.shadeToolRotateEnable(checked);
            callback(checked);
        });
    }
};

function enableAdminZ(callback) {
    var label = $('.z-label')
    var input = $('.input-admin-z');
    label.show();
    input.show();


    if (callback) {
        var self = this;

        input.change(function () {
            var checked = $(this).is(':checked');
            callback(checked);
        });
    }
};

function shadeToolLatitude(value, callback) {
    var input = $('.input-shade-latitude');
    input.val(value);

    if (callback !== undefined) {
        input.change(function () {
            $(this).val(callback($(this).val()));
        })
    }
};

function shadeToolLongitude(value, callback) {
    var input = $('.input-shade-longitude');
    input.val(value);

    if (callback !== undefined) {
        input.change(function () {
            $(this).val(callback($(this).val()));
        })
    }
};

function shadeToolTimezoneName(value) { $('.shade-tool-timezone').html(value); };

function shadeToolAzimuth(min, max, value, callback) {
    $('.input-shade-azimuth').slider({
        min:   min,
        max:   max,
        value: value,
        step:  1,
        range: "min",
        slide: function (e, ui) {
            $('.input-shade-azimuth-value').val(ui.value);
            callback(ui.value);
        }
    });

    $('.input-shade-azimuth-value')
        .val(value)
        .change(function () {
            $(this).val(callback($(this).val()));
            $('.input-shade-azimuth').slider({ value: $(this).val()});
        })
        ;
};

function shadeToolElevation(min, max, value, callback) {
    $('.input-shade-elevation').slider({
        min:   min,
        max:   max,
        value: value,
        step:  1,
        range: "min",
        slide: function (e, ui) {
            $('.input-shade-elevation-value').val(ui.value);
            callback(ui.value);
        }
    });

    $('.input-shade-elevation-value')
        .val(value)
        .change(function () {
            $(this).val(callback($(this).val()));
            $('.input-shade-elevation').slider({ value: $(this).val()});
        })
        ;
};

function _updateAzimuthElevation(result) {
    $('.input-shade-azimuth').slider({ value: Math.floor(result.azimuth) });
    $('.input-shade-azimuth-value').val(result.azimuth);

    $('.input-shade-elevation').slider({ value: Math.floor(result.elevation) });
    $('.input-shade-elevation-value').val(result.elevation);
};

function _formatTime(minutes) {
    var hours = Math.floor(minutes / 60);
    var minutes = minutes % 60;

    return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2);
};

function shadeToolTime(defaultTime, callback) {
    var self = this;

    $('.input-shade-time').slider({
        min:   0,
        max:   1439,
        value: defaultTime,
        step:  1,
        range: "min",
        slide: function (e, ui) {
            var minutes = parseInt(ui.value);

            var result = callback(minutes);
            self._updateAzimuthElevation(result);

            $('.input-shade-time-value').val(self._formatTime(minutes));
        }
    });

    $('.input-shade-time-value').val(self._formatTime(defaultTime));
};

function shadeToolDate(defaultDate, callback) {
    var year = defaultDate.getFullYear();

    var self = this;

    $('.input-shade-date').slider({
        min:   0,
        max:   isLeapYear(year) ? 365 : 364,
        value: defaultDate.dayOfTheYear(),
        range: "min",
        step:  1,
        range: "min",
        slide: function (e, ui) {
            var date = UnawareDate.fromDayOfTheYear(year, ui.value);

            var result = callback(date);
            self._updateAzimuthElevation(result);

            // update the datepicker
            $('.input-shade-date-value').datepicker('setDate',
                date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
        }
    });

    $('.input-shade-date-value').datepicker({
        dateFormat: 'yy-mm-dd',
        altFormat:  'mm/dd',
        altField:   '.input-shade-date-value',
        onSelect:   function (selected) {
            var date = new UnawareDate(new Date(selected), true);

            var result = callback(date);
            self._updateAzimuthElevation(result);

            // update the slider based on the date selected
            year = date.getFullYear();

            $('.input-shade-date').slider({
                max:   isLeapYear(year) ? 365 : 364,
                value: date.dayOfTheYear()
            });
        }
    });

    $('.input-shade-date-value').datepicker('setDate', defaultDate.getFullYear() + '-' + (defaultDate.getMonth() + 1) + '-' + defaultDate.getDate());
};

export { shadeTool, shadeToolOpacity, shadeToolLocation, shadeToolWeatherStationCallback, shadeToolRotate, shadeToolRotateEnable, shadeToolLockMovement, enableAdminZ, shadeToolDate, shadeToolLatitude, shadeToolLongitude, shadeToolTimezoneName, shadeToolAzimuth, shadeToolElevation, shadeToolTime, _formatTime, _updateAzimuthElevation };
