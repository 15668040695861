import { Draw } from './Draw.js';
import { Polygon } from '../libs/Polygon.js';
import { degToRad } from '../libs/Geometry';
import { Geometry, Line, LineBasicMaterial, LineSegments, MOUSE, Object3D, Plane, Vector3 } from '../libs/three.module';



class LineContext {
    constructor(options) {
        options = options || {};

        /** @type {'line'} */
        this.shape = 'line';

        this.container = new Object3D();

        this.camera    = options.camera;
        this.raycaster = options.raycaster;

        this.meshes = [];

        this.material = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth || 1,
            transparent: true,
            depthTest:   false
        });

        this.material2 = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });

        this.materialHighlight = new LineBasicMaterial({
            color:       options.colorHighlight || 0xffff00,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });

        this.states = { NONE: 0, STARTED: 1, CLOSED: 2 };
        this.state = this.states.NONE;

        this.onComplete = options.onComplete;

        this.height      = 0;
        this.setbackSize = 0;

        this.extrude = options.extrude || 'vertical';
        this.normal  = options.normal;

        if (options.vertices)
            this.initFromVertices(options.vertices);

        this.updateParams(options);
    }


    addToScene(scene) {
        this.scene = scene;
        this.scene.add(this.container);
    }


    show(show) {
        if (show !== false) {
            this.scene.add(this.container);
        } else {
            this.scene.remove(this.container);
        }
    }


    addMesh(mesh) { this.meshes.push(mesh); }

    undo() {
        if (this.state === this.states.STARTED) {
            this.container.remove(this.line);

            this.state = this.states.NONE;
        }
    }

    mouseDown(event, mouse, panning) { }

    mouseMove(mouse, panning) {
        if (this.state === this.states.STARTED) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            var point = intersects[0].point;

            if (this.line) {
                let g = this.line.geometry;

                g.vertices[1].copy(point);
                g.verticesNeedUpdate = true;
            }
        }
    }


    mouseUp(event, mouse, panning) {
        if (event.button !== MOUSE.LEFT)
            return;

        if (this.state === this.states.NONE) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            let l = this.createLine(intersects[0].point);

            this.container.add(l);
            this.line = l;

            this.state = this.states.STARTED;
        } else if (this.state === this.states.STARTED) {
            if (this.onComplete)
                this.onComplete();

            this.state = this.states.CLOSED;
        }
    }


    createLine(v) {
        var g = new Geometry();
        g.vertices.push(v.clone(), v.clone());

        var line = new Line(g, this.material);

        // prevent line from disappearing due to frustum culling
        // alternatively, call line.geometry.computeBoundingSphere() every time we
        // update the geometry
        line.frustumCulled = false;

        return line;
    }


    updateParams({ extrude, height, setback, highlight }) {
        if (height !== undefined || (this.height && extrude)) {
            if (this.extrusion) {
                this.container.remove(this.extrusion);
                delete this.extrusion;
            }

            if (height === undefined)
                height = this.height;

            if (height > 0) {
                let g = new Geometry();
                let p = new Plane();

                if (extrude === undefined)
                    extrude = this.extrude;

                let offset = (extrude === 'vertical') ?
                    new Vector3(0, height, 0) :
                    this.normal.clone().multiplyScalar(height);

                p.setFromNormalAndCoplanarPoint(offset.clone().normalize(), this.line.geometry.vertices[0]);

                for (let v of this.line.geometry.vertices) {
                    v = p.projectPoint(this.line.localToWorld(v.clone()));
                    g.vertices.push(v);
                }

                let line = new Line(g, this.material2);

                line.position.copy(offset);
                line.updateMatrixWorld();

                let g2 = new Geometry();

                for (let i = 0; i < this.line.geometry.vertices.length; i++) {
                    let v1 = this.line.geometry.vertices[i].clone();
                    let v2 =      line.geometry.vertices[i].clone();

                    g2.vertices.push(this.line.localToWorld(v1));
                    g2.vertices.push(     line.localToWorld(v2));
                }

                let s = new LineSegments(g2, this.material2);

                let e = new Object3D();
                e.add(line);
                e.add(s);

                this.container.add(e);
                this.extrusion = e;
            }

            this.height = height;
            this.extrude = extrude;
        }

        if (setback !== undefined) {
            if (this.setback) {
                this.container.remove(this.setback);
                delete this.setback;
            }

            if (setback > 0) {
                let vv = this.line.geometry.vertices;
                let axis = new Vector3(0, 1, 0);
                let o = new Object3D();

                o.position.copy(vv[0]);
                o.quaternion.setFromUnitVectors(axis, this.normal);
                o.rotateX(degToRad(90));
                o.updateMatrixWorld();

                // create rectangle with 0 width
                let vl = [];

                for (let v of vv) {
                    let p = this.line.localToWorld(v.clone());
                    p = o.worldToLocal(p);

                    vl.push(p);
                }

                // vector perpendicular to line
                let vp = vl[1].clone().sub(vl[0]);

                vp = new Vector3(-vp.y, vp.x, 0).normalize();
                vp.multiplyScalar(0.001);

                let poly2 = new Polygon({
                    vertices: [
                        vl[0].clone().sub(vp),
                        vl[0].clone().add(vp),
                        vl[1].clone().add(vp),
                        vl[1].clone().sub(vp)
                    ]
                });

                let offset = poly2.shrink(() => { return -setback; });
                let g = new Geometry();

                for (let v of offset.vertices) {
                    let vw = o.localToWorld(new Vector3(v.x, v.y, 0));
                    g.vertices.push(vw);
                }

                g.vertices.push(g.vertices[0].clone());

                let s = new Line(g, this.material2);

                this.container.add(s);
                this.setback = s;
            }

            this.setbackSize = setback;
        }

        if (this.line)
            this.line.material = highlight ? this.materialHighlight : this.material;

        if (this.extrusion) {
            this.extrusion.children.forEach((c) => {
                c.material = highlight ? this.materialHighlight : this.material2;
            });
        }
    }


    getVertices() {
        var vv = [];

        for (let v of this.line.geometry.vertices)
            vv.push(this.line.localToWorld(v.clone()));

        return vv;
    }


    getSetbackVertices() {
        var vv = [];

        if (this.setback !== undefined) {
            let sgv = this.setback.geometry.vertices;

            for (let i = 0; i < sgv.length - 1; i++)
                vv.push(this.setback.localToWorld(sgv[i].clone()));
        }

        return vv;
    }


    initFromVertices(vv) {
        var l = this.createLine(vv[0].clone());

        l.geometry.vertices[1].copy(vv[1]);

        this.container.add(l);
        this.line = l;
    }


    getLines() {
        var lines = [];

        if (this.extrusion) {
            let l = this.extrusion.children[0];
            let vv1 = l.geometry.vertices.map(v => l.localToWorld(v.clone()));

            lines.push(...Draw.exportLineGeometry(vv1));

            let vv2 = this.extrusion.children[1].geometry.vertices;

            lines.push(...Draw.exportSegmentGeometry(vv2));
        }

        if (this.setback) {
            let vv = this.setback.geometry.vertices;

            lines.push(...Draw.exportLineGeometry(vv));
        }

        if (this.line) {
            let vv = this.getVertices();

            lines.push([ vv[0].clone(), vv[1].clone() ]);
        }

        return lines;
    }

    /**
     *
     * @returns {import('../../../types').Tuple2<Vector3> | []}
     */
    getPolyline() {
        if (!this.line) {
            return [];
        }

        const vs = this.getVertices();
        return [vs[0].clone(), vs[1].clone()];
    }

    getSetbackPolyline() {
        if (!this.setback) {
            return [];
        }

        return this.setback.geometry.vertices.map(v => this.setback.localToWorld(v.clone()));
    }

    getExtrusions() {
        const kids = this.extrusion.children;
        return {
            top: kids[0].geometry.vertices.map(v => kids[0].localToWorld(v.clone())),
            edges: Draw.exportSegmentGeometry(
                kids[1].geometry.vertices.map(v => kids[1].localToWorld(v.clone()))
            )
        };
    }
}



export { LineContext };
