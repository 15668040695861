
import { Geometry, LineBasicMaterial, Line as ThreeLine, Vector3 } from '../libs/three.module';

var Line = function (options) {
    var geometry = new Geometry();
    geometry.vertices.push(new Vector3(0, 0, 0));
    geometry.vertices.push(new Vector3(0, 0, 0));

    var material = new LineBasicMaterial(options);

    this.line = new ThreeLine(geometry, material);
};

Line.prototype = {
    constructor: Line,
    addToScene: function (scene) {
        scene.add(this.line);
    },
    setPoints: function (a, b) {
        this.line.geometry.vertices[0].copy(a);
        this.line.geometry.vertices[1].copy(b);
        this.line.geometry.verticesNeedUpdate = true;
    },
    copyPosition: function (v) {
        this.line.position.copy(v);
    },
    setVisible: function (value) {
        this.line.visible = value;
    },
    lookAt: function (v) {
        this.line.lookAt(v);
    }
};


export { Line };
