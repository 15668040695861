import { mouseHandler } from '../Viewer.js';
import * as THREE from '../libs/three.module';

class ModuleSpecsToolDynamicMode {
    constructor(name, parent) {
        this.name = name;
        this.parent = parent;

        this.moveOrigin;
        this.moveDiff;

        this.states = {
            NONE:   0,
            MOVE:   1,
            MOVING: 2
        };

        this.stop();
    }


    stop() {
        //console.log('stopping in mode:', this.name)
        this.state = this.states.NONE;
    }


    exit() {
        if (this.parent)
            this.parent.stop();
    }


    move() {
        mouseHandler.setCursor('move', 'ms', true);

        this.state = this.states.MOVE;
    }


    fill() {
        this.setMoveDiff(false);
    }


    selectModule(module) {
        if (module.toggleSelect()) {
            this.parent.selectAll();
        } else {
            this.parent.selectNone();
        }
    }


    positionWorldToLocal(pos) {
        let ma = this.parent.ctxModuleArray();

        if (ma) {
            return ma.frame.worldToLocal(pos.clone()).setY(0);
        } else {
            return null;
        }
    }


    setMoveDiff(pos) {
        if (pos) {
            if (this.moveOrigin) {
                this.moveDiff = new THREE.Vector3(
                    pos.x - this.moveOrigin.x,
                    pos.y - this.moveOrigin.y,
                    pos.z - this.moveOrigin.z
                );
            }
        } else {
            this.moveOrigin = null;
            this.moveDiff = null;
        }
    }


    mouseDown() {
        if (this.state === this.states.MOVE) {
            let xm = this.parent.intersectModules();

            if (xm) {
                let xp = this.parent.intersectMovingPlane();

                if (xp) {
                    this.moveOrigin = this.positionWorldToLocal(xp.point.clone());

                    mouseHandler.setCursor('move', 'ms', true);

                    this.state = this.states.MOVING;
                }
            }
        } else if (this.state === this.states.MOVING) {
            this.moveOrigin = null;
            this.state = this.states.NONE;

            mouseHandler.clearCursor('ms');

            this.parent.selectNone();
            this.parent.propagateState();

            this.exit();
        }
    }


    onShapeChanged() {
        if (this.parent.ctxModuleArray())
            this.parent.placeModules(this.parent.outlineCtx);

        // TODO: update parapet and fire setback shapes if any exist
    }


    mouseMove() {
        if (this.state === this.states.MOVING) {
            let x = this.parent.intersectMovingPlane();

            if (x) {
                this.setMoveDiff(this.positionWorldToLocal(x.point.clone()));
            }

            let ma = this.parent.ctxModuleArray();
            let offsets = ma.getOptimalModuleOffsets();

            this.parent.dynamicDiff = this.moveDiff ? {
                x: offsets.w + this.moveDiff.x,
                z: offsets.h + this.moveDiff.z
            } : null;

            this.parent.placeModules(this.parent.outlineCtx);
        }
    }
}

export { ModuleSpecsToolDynamicMode };
