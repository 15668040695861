

import { formatK, avg } from '../libs/Utilities';
import { InfernoGradient } from '../draw/InfernoGradient';
import { Colors } from '../core/Colors';
import { GRAPH } from '../libs/Graph';
import { GUI } from './Gui.js';
import { Validator } from './Validator';
import { project, user } from '../Viewer.js';
import { Permissions } from '../libs/Permissions';


import * as d3 from 'd3';
import tippy from 'tippy.js';


class ProjInfoGUI {
    constructor(options) {
        this.options = options;
        this.getState = options.getState;

        if (Permissions.hasPermission(user, project, Permissions.PROJECT_INFO)) { // moved from Viewer.js
            if (this.options.project.ownerDetails === undefined)
                this.options.project.ownerDetails = {};

            if (this.options.project.address === undefined)
                this.options.project.address = {};
        }
    }

    cmd(c, val) {
        return this.options.tool.cmd(c, val);
    }

    init() { }

    renderState() {
        const self = this;

        $('#sidebar-deck').html(`
        <div id="dialog-project-info">
            <div id="pp-info-tabs">
                <ul>
                    <li><a href="#pp-info-tabs-chart" aria-pressed="true">Overview</a></li>
                </ul>

                <div id="pp-info-tabs-chart">
                    <div class="loading">
                        Loading, please wait...
                    </div>

                    <div class="no-content">
                        Please add a module array and one or more viewsheds to generate production graphs.<br /><br />
                    </div>

                    <div class="content">
                        <table>
                            <tr class="charts">
                                <td class="msa-chart-col">
                                    <h4>Average Monthly Solar Access</h4>

                                    <div id="msa-chart"></div>
                                </td>
                                <td class="mp-chart-col">
                                    <h4>Monthly Consumption and Production</h4>

                                    <div id="mp-chart"></div>
                                </td>
                            </tr>
                        </table>

                        <div class="tags">
                            <div id="asa-percentage" class="tag"> </div>
                            <div id="total-kw" class="tag"> </div>
                            <div id="annual-production" class="tag"> </div>
                            <div id="annual-consumption" class="tag"> </div>
                            <div id="pv-system-offset" class="tag"> </div>
                        </div>

                        <div class="weather-station-text">
                            <strong>Nearest weather station:</strong>
                            <span class="weather-station-info"></span>
                        </div>

                        <div class="please-wait">
                            Loading, please wait<span class="dots"></span>
                        </div>

                        <table class="module-info hidden">
                            <thead>
                                <tr>
                                    <td>Segment(s)</td>
                                    <td>Azimuth</td>
                                    <td>Tilt</td>
                                    <td>Solar Access</td>
                                    <td>Module Wattage</td>
                                    <td>Modules</td>
                                    <td>kW </td>
                                    <td>Annual kWh</td>
                                </tr>
                            </thead>
                            <tbody> </tbody>
                        </table>
                    </div>
                </div><!-- #pp-info-tabs-chart -->
            </div>
        </div>
        `);

        var infoTabs = $('#pp-info-tabs');

        infoTabs.tabs({
            activate: function () {
                var index = infoTabs.tabs('option', 'active');
                self.renderTabs();
            }
        });

        var info = self.options.getRoofPlaneInfo();

        $('#pp-info-tabs li.segment').remove();
        $('.pp-info-tabs-segment').remove();


        for (var planeId = 0; planeId < info.planes.length; planeId++) {
            if (info.modules[planeId] === undefined)
                continue;

            let name = info.planes[planeId].name;

            if (!name.startsWith('Segment '))
                name = 'Segment ' + name;

            $('#pp-info-tabs ul').append(`
                <li class="segment">
                    <a href="#pp-info-tabs-segment-${planeId}" aria-pressed="false">${name}</a>
                </li>
            `);

            if (info.planes[planeId].viewsheds.length === 0) {
                $('#pp-info-tabs').append(`
                    <div id="pp-info-tabs-segment-${planeId}" class="pp-info-tabs-segment">
                        <div class="empty-tab">
                            <p>Please add at least one viewshed to show production data.</p>
                            <img src="/styles/images/scanifly-illustration-viewsheds.svg" class="viewshed-illustration">
                        </div>
                    </div>
                `);
            } else {
                let ts = `
                    <div id="pp-info-tabs-segment-${planeId}" class="pp-info-tabs-segment">
                        <div class="stats">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Segment</td>
                                        <td>Azimuth</td>
                                        <td>Tilt</td>
                                        <td>Solar Access</td>
                                        <td>Module Wattage</td>
                                        <td>Modules</td>
                                        <td>kW DC STC</td>
                                        <td>Annual kWh</td>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                        <div class="system-parameters">
                            <h2>System Parameters</h2>

                            <table>
                                <tr>
                                    <td>Array Type:</td>
                                    <td>
                                        <select id="input-${planeId}-array-type" name="array type">
                                            <option value="0">Fixed - Open Rack</option>
                                            <option value="1">Fixed - Roof Mounted</option>
                                            <option value="2">1-Axis</option>
                                            <option value="3">1-Axis Backtracking</option>
                                            <option value="4">2-Axis</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Module Type:</td>
                                    <td>
                                        <select id="input-${planeId}-array-module-type" name="module type">
                                            <option value="0">Standard</option>
                                            <option value="1">Premium</option>
                                            <option value="2">Thin film</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DC to AC Size Ratio:</td><td><input id="input-${planeId}-array-dc-ac-ratio" type="text" aria-label="dc to ac size ratio"/></td>
                                </tr>
                                <tr>
                                    <td>Inverter Efficiency (%):</td><td><input id="input-${planeId}-array-inv-eff" type="text" aria-label="inverter efficiency"/></td>
                                </tr>
                            </table>

                            <hr />

                            <table>
                                <tr>
                                    <td>Shading (%):</td><td><input id="input-${planeId}-array-shading" type="text" disabled="disabled" aria-disabled="true" aria-label="shading"/></td>
                                </tr>
                                <tr>
                                    <td>Snow (%):</td><td><input id="input-${planeId}-array-snow" type="text" aria-label="snow"/></td>
                                </tr>
                                <tr>
                                    <td>Mismatch (%):</td><td><input id="input-${planeId}-array-mismatch" type="text" aria-label="mismatch"/></td>
                                </tr>
                                <tr>
                                    <td>Wiring (%):</td><td><input id="input-${planeId}-array-wiring" type="text" aria-label="wiring"/></td>
                                </tr>
                                <tr>
                                    <td>Connections (%):</td><td><input id="input-${planeId}-array-connections" type="text" aria-label="connections"/></td>
                                </tr>
                                <tr>
                                    <td>Light Induced Degradation (%):</td><td><input id="input-${planeId}-array-lid" type="text" aria-label="light induced degradation"/></td>
                                </tr>
                                <tr>
                                    <td>Nameplate Rating (%):</td><td><input id="input-${planeId}-array-rating" type="text" aria-label="nameplate rating"/></td>
                                </tr>
                                <tr>
                                    <td>Age (%):</td><td><input id="input-${planeId}-array-age" type="text" aria-label="age"/></td>
                                </tr>
                                <tr>
                                    <td>Availability (%):</td><td><input id="input-${planeId}-array-availability" type="text" aria-label="availability"/></td>
                                </tr>
                                <tr>
                                    <td>Soiling (%):</td><td><input id="input-${planeId}-array-soiling" type="text" aria-label="soiling"/></td>
                                </tr>
                            </table>

                            <div class="actions">
                                <button id="reset-losses-${planeId}" class="sy" aria-label="reset">Reset</button> Estimated System losses <span id="system-losses-${planeId}"></span>%
                            </div>
                        </div>

                        <div class="solar-access">
                            <h2>Solar Access</h2>

                            <div class="inner">
                                <table>
                                    <tr style="display:  flex; flex-direction: column">
                                        <td class="chart">
                                            <div id="sa-chart-${planeId}" class="sa-chart"></div>
                                        </td>
                                        <td>
                                            <div class="stats viewshed-stats"></div>

                                            <div id="pp-info-viewsheds-${planeId}" class="pp-info-viewsheds">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>Viewshed</td>
                                                            <td>Solar Access</td>
                                                            <td>TOF</td>
                                                            <td>TSRF</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody></tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                `;

                $('#pp-info-tabs').append(ts);

                // SYSTEM PARAMETERS INPUTS
                var sysParams = self.options.getSystemParams(planeId);
                var textInputs = {
                    'dcToAcRatio':  `#input-${planeId}-array-dc-ac-ratio`,
                    'invEff':       `#input-${planeId}-array-inv-eff`,
                    'shading':      `#input-${planeId}-array-shading`,
                    'snow':         `#input-${planeId}-array-snow`,
                    'mismatch':     `#input-${planeId}-array-mismatch`,
                    'wiring':       `#input-${planeId}-array-wiring`,
                    'connections':  `#input-${planeId}-array-connections`,
                    'lid':          `#input-${planeId}-array-lid`,
                    'rating':       `#input-${planeId}-array-rating`,
                    'age':          `#input-${planeId}-array-age`,
                    'availability': `#input-${planeId}-array-availability`,
                    'soiling':      `#input-${planeId}-array-soiling`
                };

                for (var key in textInputs) {
                    GUI.textInputWidget($(textInputs[key]), Math.round(sysParams[key] * 100) / 100,
                                        self.getSysParamTextCallback(planeId, key));
                }

                // SELECTS
                var inputArrayType = $('#input-' + planeId + '-array-type');
                inputArrayType.change(function (planeId) {
                    return function () {
                        self.options.updateSystemParams(planeId, 'arrayType', parseInt($(this).val()));
                        self.renderTabs();
                    };
                } (planeId));

                inputArrayType.prop('selectedIndex', sysParams.arrayType);

                var inputModuleType = $('#input-' + planeId + '-array-module-type');
                inputModuleType.change(function (planeId) {
                    return function () {
                        self.options.updateSystemParams(planeId, 'moduleType', parseInt($(this).val()));
                        self.renderTabs();
                    };
                } (planeId));

                inputModuleType.prop('selectedIndex', sysParams.moduleType);

                // RESET
                var defaults = self.options.getDefaultSystemParams();
                $('#reset-losses-' + planeId).click(function (planeId, textInputs) {
                    return function () {
                        for (var key in textInputs) {
                            GUI.textInputWidget($(textInputs[key]), defaults[key]);
                            self.options.updateSystemParams(planeId, key, defaults[key]);
                        }

                        self.renderTabs();
                    };
                } (planeId, textInputs));
            }
        }

        $('#pp-info-tabs').tabs('refresh');

        tippy(`#pp-info-tabs .qmark-info`, {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            interactive: true
        });

        self.options.getWeatherStationInfo(function (info) { self.renderTabs(info); });
    }


    renderTabs(weatherStationInfo) {
        var self = this;

        this.options.getProductionValues().then(function (values) {
            $('#dialog-project-info .loading').css('display', 'none');

            if (!values) {
                $('#dialog-project-info .no-content').css('display', 'block');
                return;
            }

            $('#dialog-project-info .no-content').css('display', 'none');
            $('#dialog-project-info .content')   .css('display', 'block');

            // AVERAGE MONTHLY SOLAR ACCESS
            var graph = GRAPH.monthly(values.solarAccess.averages, {
                color:    '#008AFC',
                type:     'line',
                compress: true,
                format:   function (x) { return d3.format('s')(x) + '%'; },
                fontSize: 2.8
            }).attr('stroke-linecap', 'round');
            $('#msa-chart').empty().append(graph.node());

            // MONTHLY CONSUMPTION AND PRODUCTION
            var graph = GRAPH.monthly([ values.consumption, values.production ], {
                color:       [ '#1C48F2', '#0DCAD3' ],
                format:      function (x) { return d3.format(',')(Math.round(x / 1000)) + 'kWh' },
                fontSize:    2.8,
                paddingLeft: 16,
                labelFormat: function (x) { return d3.format(',')(Math.round(x / 1000)) }
            });
            $('#mp-chart').empty().append(graph.node());

            var info = self.options.getRoofPlaneInfo();

            $('#pp-info-tabs-chart .module-info tbody').empty();

            let populatedPIDs = [];

            for (var pid = 0; pid < info.planes.length; pid++) {
                if (info.modules[pid] !== undefined && info.planes[pid].viewsheds.length > 0)
                    populatedPIDs.push(pid);
            }

            if (populatedPIDs.length > 0) {
                $('#pp-info-tabs-chart .module-info').removeClass('hidden');

                for (let pid of populatedPIDs) { // in order to display unpopulated segments in the segment table, I think we'll need to make some adjustments here and in renderRoofPlaneStats to allow for unpopulated viewsheds
                    $('#pp-info-tabs-chart .module-info tbody').append(
                        self.renderRoofPlaneStats(pid, info, values.annualProduction)
                    );
                }
            }

            var asa         = values.totalAvgASA.toFixed(0);
            var consumption = formatK(values.annualUsage.toFixed(0));
            var totalKW     = values.systemSize.toFixed(3);
            var production  = formatK(values.annualProductionTotal.toFixed(0));
            var offset      = (consumption === "0" || values.annualUsage === 0) ? "0" : values.systemOffset.toFixed(0);

            $('#asa-percentage')    .html('Annual Solar Access: '        + asa + '%');
            $('#annual-consumption').html('Annual Consumption: ' + consumption + ' kWh');
            $('#total-kw')          .html('Total kW DC STC: '        + totalKW + ' kW');
            $('#annual-production') .html('Annual Production: '   + production + ' kWh');
            $('#pv-system-offset')  .html('PV System Offset: '        + offset + '%');

            $('#input-annual-usage').val(consumption || 'N/A');

            if (weatherStationInfo) {
                $('#pp-info-tabs .weather-station-info').html(weatherStationInfo.info +
                    ' (' + weatherStationInfo.distance + ' miles)');

                $('#performance-simulation .weather-station-info').html(weatherStationInfo.info +
                    ' (' + weatherStationInfo.distance + ' miles)');
            }

            self.renderSegmentTabs(values.annualProduction, values.solarAccess);

        }).catch(function (error) {
            console.log('error', error);
        });
    }


    renderSegmentTabs(annualProduction, solarAccess) {
        var info = this.options.getRoofPlaneInfo();

        for (var pid = 0; pid < info.planes.length; pid++) {
            if (info.modules[pid] === undefined || info.planes[pid].viewsheds.length === 0)
                continue;

            if (solarAccess.planes[pid] === undefined || solarAccess.planes[pid].length < 12)
                return; // TODO: why is this a return and not a continue?

            // ROOF PLANE STATS
            $(`#pp-info-tabs-segment-${pid} > .stats tbody`).html(
                this.renderRoofPlaneStats(pid, info, annualProduction)
            );

            // LOSSES
            $('#system-losses-' + pid).html(
                this.options.calculateLosses(pid).toFixed(2)
            );

            // SOLAR ACCESS STATS
            const p = info.planes[pid];

            const summer = avg(solarAccess.planes[pid].slice(4, 9));
            const winter = avg(solarAccess.planes[pid].slice(0, 4)
                                 .concat(solarAccess.planes[pid].slice(9, 12)));

            $(`#pp-info-tabs-segment-${pid} .viewshed-stats`).html(`
                Annual: ${p.solarAccess.toFixed(0)}% &nbsp;&nbsp;
                TOF: ${p.TOF.toFixed(0)}% &nbsp;&nbsp;
                TSRF: ${p.TSRF.toFixed(0)}%
                <br />
                Summer (May - Oct): ${summer.toFixed(0)}% <br />
                Winter (Nov - Apr): ${winter.toFixed(0)}%`
            );

            // SOLAR ACCESS CHART
            var saChart = GRAPH.monthly(solarAccess.planes[pid], {
                color:       '#1C48F2',
                type:        'line',
                compress:    true,
                format:      function (x) { return d3.format('s')(x) + '%'; },
                paddingLeft: 13,
                fontSize:    4
            }).attr('stroke-linecap', 'round');

            $('#sa-chart-' + pid).empty().append(saChart.node());

            $(`#pp-info-viewsheds-${pid} tbody`).empty();

            for (var i = 0; i < info.planes[pid].viewsheds.length; i++) {
                var vs = info.planes[pid].viewsheds[i];
                var fontColor = vs.solarAccess >= 84 ? Colors.darkGray() : Colors.white(); // we should store these in viewshed so we can use them globally without doing this extra work :)
                var markerColor = `#${InfernoGradient.value(vs.solarAccess / 100).getHexString()}`;

                $(`#pp-info-viewsheds-${pid} tbody`).append(`
                    <tr>
                        <td><div class="tag" style="background-color: ${markerColor}; color: ${fontColor}">${i + 1}</div></td>
                        <td>${vs.solarAccess.toFixed(0)}%</td>
                        <td>${vs.TOF.toFixed(0)}%</td>
                        <td>${vs.TSRF.toFixed(0)}%</td>
                    </tr>`
                );
            }
        }
    }


    renderRoofPlaneStats(pid, info, annualProduction) {
        const p = info.planes[pid];

        let name = p.name;
        if (!name.startsWith('Segment '))
            name = 'Segment ' + name;

        const SA         = p.solarAccess.toFixed(0);
        const wattage    = this.formatWattage(info.modules[pid].wattage);
        const output     = (info.modules[pid].output / 1000).toFixed(3);
        const production = formatK(annualProduction[pid].toFixed(0));

        const checkmarkColor = (p.viewsheds.length > 0) ? '#27E2A4' : '#CFCFCF';

        let t = `
            <tr>
                <td><i class="fa fa-check-circle" aria-hidden="true" style="color: ${checkmarkColor}"></i> ${name}</td>
                <td>${p.azimuth.toFixed(0)}&deg;</td>
                <td>${p.tilt.toFixed(0)}&deg;</td>
                <td>${SA}%</td>
                <td>${wattage}W</td>
                <td>${info.modules[pid].qty}</td>
                <td>${output} kW</td>
                <td>${production} kWh</td>
            </tr>
        `;

        return t;
    }


    get optionsUSStates() {
        return `
            <option value="">State / Province</option>
            <option value="Alabama-AL">Alabama</option>
            <option value="Alaska-AK">Alaska</option>
            <option value="Arizona-AZ">Arizona</option>
            <option value="Arkansas-AR">Arkansas</option>
            <option value="California-CA">California</option>
            <option value="Colorado-CO">Colorado</option>
            <option value="Connecticut-CT">Connecticut</option>
            <option value="Delaware-DE">Delaware</option>
            <option value="Florida-FL">Florida</option>
            <option value="Georgia-GA">Georgia</option>
            <option value="Hawaii-HI">Hawaii</option>
            <option value="Idaho-ID">Idaho</option>
            <option value="Illinois-IL">Illinois</option>
            <option value="Indiana-IN">Indiana</option>
            <option value="Iowa-IA">Iowa</option>
            <option value="Kansas-KS">Kansas</option>
            <option value="Kentucky-KY">Kentucky</option>
            <option value="Louisiana-LA">Louisiana</option>
            <option value="Maine-ME">Maine</option>
            <option value="Maryland-MD">Maryland</option>
            <option value="Massachusetts-MA">Massachusetts</option>
            <option value="Michigan-MI">Michigan</option>
            <option value="Minnesota-MN">Minnesota</option>
            <option value="Mississippi-MS">Mississippi</option>
            <option value="Missouri-MO">Missouri</option>
            <option value="Montana-MT">Montana</option>
            <option value="Nebraska-NE">Nebraska</option>
            <option value="Nevada-NV">Nevada</option>
            <option value="New Hampshire-NH">New Hampshire</option>
            <option value="New Jersey-NJ">New Jersey</option>
            <option value="New Mexico-NM">New Mexico</option>
            <option value="New York-NY">New York</option>
            <option value="North Carolina-NC">North Carolina</option>
            <option value="North Dakota-ND">North Dakota</option>
            <option value="Ohio-OH">Ohio</option>
            <option value="Oklahoma-OK">Oklahoma</option>
            <option value="Oregon-OR">Oregon</option>
            <option value="Pennsylvania-PA">Pennsylvania</option>
            <option value="Rhode Island-RI">Rhode Island</option>
            <option value="South Carolina-SC">South Carolina</option>
            <option value="South Dakota-SD">South Dakota</option>
            <option value="Tennessee-TN">Tennessee</option>
            <option value="Texas-TX">Texas</option>
            <option value="Utah-UT">Utah</option>
            <option value="Vermont-VT">Vermont</option>
            <option value="Virgin Islands-VI">Virgin Islands</option>
            <option value="Virginia-VA">Virginia</option>
            <option value="Washington-WA">Washington</option>
            <option value="West Virginia-WV">West Virginia</option>
            <option value="Wisconsin-WI">Wisconsin</option>
            <option value="Wyoming-WY">Wyoming</option>
            <option value="Alberta-AB">Alberta</option>
            <option value="British Columbia-BC">British Columbia</option>
            <option value="Manitoba-MB">Manitoba</option>
            <option value="New Brunswick-NB">New Brunswick</option>
            <option value="Newfoundland and Labrador-NL">Newfoundland and Labrador</option>
            <option value="Northwest Territories-NT">Northwest Territories</option>
            <option value="Nova Scotia-NS">Nova Scotia</option>
            <option value="Nunavut-NU">Nunavut</option>
            <option value="Ontario-ON">Ontario</option>
            <option value="Prince Edward Island-PE">Prince Edward Island</option>
            <option value="Quebec-QC">Quebec</option>
            <option value="Saskatchewan-SK">Saskatchewan</option>
            <option value="Yukon-YT">Yukon</option>
        `;
    }


    formatWattage(w) {
        var s;

        if (Array.isArray(w)) {
            var uniq = [];

            // filter out duplicates
            for (var i = 0; i < w.length; i++) {
                if (uniq.indexOf(w[i]) === -1) {
                    uniq.push(w[i]);
                }
            }

            s = uniq.sort().join(', ');
        } else {
            s = w;
        }

        return s;
    }


    getSysParamTextCallback(planeId, k) {
        var self = this;

        return function (value) {
            var number = parseFloat(value);

            if (isNaN(number))
                return;

            if (k === 'invEff') {
                number = Math.max(90, Math.min(number, 99.5));
            } else {
                number = Math.max(0, number);
            }

            self.options.updateSystemParams(planeId, k, number);
            self.renderTabs();

            return number;
        };
    }


    getUtilityRate() {
        return (this.options.project.utilityRate !== undefined) ? this.options.project.utilityRate : 0.13;
    }


    getSolarRate() {
        return (this.options.project.solarRate !== undefined) ? this.options.project.solarRate : 0.09;
    }
}


export { ProjInfoGUI };
