const INDICATOR_SELECTOR = '.status-indicator';
const BAR_SELECTOR = `${INDICATOR_SELECTOR} .bar`;
const PERCENT_SELECTOR = `${INDICATOR_SELECTOR} .percentage`;


class StatusIndicatorGui {
    constructor(barSelector = BAR_SELECTOR, percentSelector = PERCENT_SELECTOR) {
        this.open = false;
        this.barSelector = barSelector;
        this.percentSelector = percentSelector;

        this.lines = [];
    }


    init() {
        const self = this;

        $('body').on('click', '.open-status-indicator', function () {
            let konsole = $('#console');

            if (konsole.hasClass('open')) {
                konsole.removeClass('open');
            } else {
                konsole.addClass('open');
            }

            let icon = $('.fa', $(this));

            if (icon.hasClass('fa-angle-up')) {
                icon.removeClass('fa-angle-up');
                icon.addClass('fa-angle-down');
            } else {
                icon.removeClass('fa-angle-down');
                icon.addClass('fa-angle-up');
            }

            self.open = !self.open;
            self.renderState();
        });

        document.body.addEventListener(
            'status',
            ({ detail: { message } }) => { this.pushStatus(message); },
            false
        );

        document.body.addEventListener(
            'progress',
            ({ detail: { percent } }) => { this.setProgress(percent); },
            false
        );
    }

    /**
     * @param {string | undefined} message
     */
    pushStatus(message) {
        this.lines.push(
            message
                ? { message, timestamp: new Date().toLocaleString() }
                : { message: '' }
        );

        this.renderState();

        return this;
    }

    /**
     * @param {Error} error
     */
    pushError(error) {
        $(this.barSelector).addClass('error');
        return this.pushStatus(error.message)
            .deferClearProgress();
    }

    /**
     * @param {number} percent
     */
    setProgress(percent) {
        $(this.barSelector).removeClass('hidden').css('width', `${percent}%`);
        $(this.percentSelector).removeClass('hidden').html(`${percent.toFixed(1)}%`);

        if (percent === 100) {
            this.deferClearProgress();
        }

        return this;
    }

    clearProgress() {
        $(this.barSelector).css('width', 0)
            .addClass('hidden')
            .removeClass('error indeterminate');
        $(this.percentSelector).addClass('hidden');

        return this;
    }

    deferClearProgress(timer = 2000) {
        setTimeout(() => this.clearProgress(), timer);

        return this;
    }

    setIndeterminate() {
        $(this.barSelector).addClass('indeterminate')
            .removeClass('hidden')
            .css('width', '50%');

        return this;
    }

    renderState() {
        $('.status-indicator').removeClass('hidden');
        $('.console-wrapper').removeClass('hidden');

        $('#console').empty();

        let lines = this.lines;

        if (!this.open && lines.length > 0)
            lines = [ lines[lines.length - 1] ];

        for (let l of lines) {
            $('#console').append(`
                <div class="item">
                    <span>${l.message}</span>
                    <span class="timestamp">${l.timestamp || ''}</span>
                </div>
            `);
        }

        $('#console').scrollTop($('#console').height());
    }
}

export { StatusIndicatorGui };
