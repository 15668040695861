import { ImageLoader, LoadingManager } from './three.module';
import * as Q from 'q';

import { MTLLoader } from '../loaders/MTLLoader';
import { OBJLoader } from '../loaders/OBJLoader';
import { Config } from '../../bootstrap/Config';
import { createPromiseFromDomEvent } from '../libs/Utilities';


var Net = {};

Net.postJSON = function (url, data) {
    var deferred = Q.defer();

    $.ajax({
        url:         url,
        method:      'POST',
        data:        JSON.stringify(data),
        contentType: 'application/json;charset=UTF-8',
        success:     function (response) { deferred.resolve(response); },
        error:       function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};

/**
 * @param {number} [ttl = 300] Cache the result for {ttl} seconds.
 */
export const getDecimatorUrl = (
    () => {
        /** @type {PromiseLike<string> | null} */
        let decimatorUrlPromise;

        return async (ttl = 300) => {
            if (decimatorUrlPromise) {
                return decimatorUrlPromise;
            }

            const def = Q.defer();
            decimatorUrlPromise = def.promise;

            try {
                // local dev environment
                if (location.host === "localhost:8008") {
                    def.resolve(Config.decimatorURL);
                }
                const url = await Net._req('GET', `${Config.baseURL}/config/get/decimatorUrl`);
                def.resolve(url);
            } catch {
                // configured backend doesn't support d7r url queries? fall back to legacy default
                def.resolve(Config.decimatorURL);
            } finally {
                setTimeout(() => decimatorUrlPromise = null, ttl * 1000);
                return decimatorUrlPromise;
            }
        }
    }
)();

Net.getDecimatorUrl = getDecimatorUrl;

Net.getLoggedInUser = function () {
    return Net._req('GET', Config.baseURL + '/users/current');
};

Net.getLoggedInUserCompany = function() {
    return Net._req('GET', Config.baseURL + '/companies/current');
}

Net.getProject = function (projectId, designId) {
    let url = `${Config.baseURL}/projects/viewer/${projectId}`;

    if (designId) {
        url += `/${designId}`;
    }

    return Net._req('GET', url);
};

Net.saveProject = function (projectId, data) {
    return Net._req('PUT', Config.baseURL + '/projects/viewer/' + projectId, data);
};

// PROJECT DESIGNS / SAVES / VERSIONS

Net.createProjectDesign = function (projectId, name) {
    return Net._req('POST', `${Config.baseURL}/projects/designs/`, {
        projectId,
        name
    });
};

Net.getProjectDesigns = function (projectId) {
    return Net._req('GET', `${Config.baseURL}/projects/designs/project/${projectId}`);
};

Net.updateProjectDesign = function (designId, data) {
    return Net._req('PATCH', `${Config.baseURL}/projects/designs/${designId}`, data);
};

Net.deleteProjectDesign = function (designId) {
    return Net._req('DELETE', `${Config.baseURL}/projects/designs/${designId}`);
};

Net.getEmbedData = function (projectId, designId) {
    return Net._req('GET', `${Config.baseURL}/projects/viewer/embed/${projectId}/${designId}`);
};

// MODULE LIBRARY

Net.getModuleLibrary = function (userId) {
    return Net._req('GET', `${Config.baseURL}/module-library/combined/${userId}`);
};

Net.updateModuleLibrary = function (userId, data) {
    return Net._req('PUT', `${Config.baseURL}/module-library/${userId}`, data);
};

// PDF REPORT

Net.requestReport = function (projectId, designId, proposal) {
    return Net._req('POST', `${Config.baseURL}/projects/viewer/report/${projectId}/${designId}`, {
        p: proposal
    });
};

Net.getReportPollingUrlFromTaskId = async (taskId) => {
    return `${await Net.getDecimatorUrl()}/reports/${taskId}`;
}

Net.pollReport = function (url) {
    return Net._req('GET', url);
};


//Csv Shade Report
Net.requestReportSummary = function (projectId, designId) {
    return Net._req('GET', `${Config.baseURL}/projects/viewer/report-basic/${projectId}/${designId}`)
};

// MODEL

Net.loadOBJ = function (url, options) {
    var deferred = Q.defer();

    if (options === undefined) {
        options = { materials: null };
    }

    var loader = new OBJLoader();
    loader.setMaterials(options.materials);

    loader.load(url, {
        onLoad: function (obj) {
            deferred.resolve(obj);
        },
        onError: function (error) {
            deferred.reject(new Error(error));
        },
        onProgress:         options.onProgress,
        onDownloadFinished: options.onDownloadFinished,
        onParsing:          options.onParsing
    });

    return deferred.promise;
};

Net.loadMTL = function (url, options) {
    var deferred = Q.defer();

    if (options === undefined)
        options = { texturePath: '/' };

    var mtlLoader = new MTLLoader();
    mtlLoader.setTexturePath(options.texturePath);
    mtlLoader.setCrossOrigin(true);
    mtlLoader.load(url, function (materials) {
        deferred.resolve(materials);
    }, function (percent) {
    }, function (error) {
        deferred.reject(new Error(error));
    });

    return deferred.promise;
};

Net.loadTexture = function (url, options) {
    var deferred = Q.defer();

    var textureLoader = new ImageLoader(new LoadingManager());
    textureLoader.setCrossOrigin(true);
    textureLoader.load(url, function (image) {
        deferred.resolve(image);
    });

    return deferred.promise;
};

Net.getKML = function (url) {
    var deferred = Q.defer();

    $.ajax({
        url:         url,
        crossDomain: true,
        success:     function (response)  { deferred.resolve(response); },
        error:       function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};

Net.getClosestClimateData = function (lat, lng) {
    var deferred = Q.defer();

    $.ajax({
        url: 'https://developer.nrel.gov/api/solar/data_query/v1.json',
        type: 'GET',
        data: {
            api_key: 'tew0lvIOije7WHPQElAxYcboj9XI4v2wIJ8AhM2M',
            lat:     lat,
            lon:     lng
        },
        crossDomain: true,
        success: function (data) {
            var result = null;

            for (var key in data.outputs) {
                if (data.outputs[key] !== null) {
                    result = key;
                    break;
                }
            }

            deferred.resolve(result);
        },
        error: function (xhr) {
            console.log(xhr.status + ': ' + xhr.statusText);
            deferred.reject(new Error('data_query request failed'));
        }
    });

    return deferred.promise;
};

Net._pvWattsCache = {};

Net.pvWatts = function (solar, system, losses) {
    var deferred = Q.defer();

    if (solar.kW > 0) {
        var key = '' + solar.kW + system.moduleType + losses + system.dcToAcRatio + system.invEff +
            system.arrayType + solar.tilt + solar.azimuth + solar.lat + solar.lng;

        if (Net._pvWattsCache[key] === undefined) {
            // https://developer.nrel.gov/docs/solar/pvwatts/v6/
            var inputs = {
                api_key:         'tew0lvIOije7WHPQElAxYcboj9XI4v2wIJ8AhM2M',
                system_capacity: solar.kW,
                module_type:     system.moduleType,
                losses:          losses,
                dc_ac_ratio:     system.dcToAcRatio,
                inv_eff:         system.invEff,
                array_type:      system.arrayType,
                tilt:            solar.tilt,
                azimuth:         solar.azimuth,
                lat:             solar.lat,
                lon:             solar.lng,
                radius:          0
            };

            $.ajax({
                url: 'https://developer.nrel.gov/api/pvwatts/v6.json',
                type: 'GET',
                data: inputs,
                crossDomain: true,
                success: function (data) {
                    var output = {
                        ac_annual:  data.outputs.ac_annual,
                        ac_monthly: data.outputs.ac_monthly
                    };

                    Net._pvWattsCache[key] = output;
                    deferred.resolve(output);
                },
                error: function (xhr) {
                    var msg;

                    try {
                        var response = JSON.parse(xhr.responseText);

                        msg = response.errors.join(' ');
                        msg += ' ' + response.warnings.join(' ');
                    } catch (e) {
                        if (e instanceof SyntaxError) {
                            msg = 'PVWatts request failed.';
                        } else {
                            msg = e.message;
                        }
                    }

                    deferred.reject(new Error(msg));
                }
            });
        } else {
            deferred.resolve(Net._pvWattsCache[key]);
        }
    } else {
        deferred.resolve({
            ac_annual:  0,
            ac_monthly: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
        });
    }

    return deferred.promise;
};


Net.loadStationList = function () {
    var deferred = Q.defer();

    $.ajax({
        url: Config.assetsURL + '/StationList.json',
        dataType: 'json',
        success: function (response) {
            deferred.resolve(response);
        },
        error: function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};


Net.loadStation = function (station) {
    var deferred = Q.defer();

    $.ajax({
        url: Config.assetsURL + '/stations/' + station + '.js',
        dataType: 'script',
        success: function () {
            // the loaded script will have the local variables in this scope
            deferred.resolve({
                data:       stationData,
                optTilt:    optTilt,
                optAzimuth: optAzimuth,
                info:       stationInfo,
                UTCOffset:  utcOffset
            });
        },
        error: function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};

/**
 *
 * @param {*} options
 * @returns {Promise} resolves to ArrayBuffer containing zipped DXF, rejects with XMLHttpRequest status text
 */
Net.generateDxf = async (options) => {

    var xhr = new XMLHttpRequest();
    const promise = createPromiseFromDomEvent(xhr, 'load').then( () => {
        if(xhr.status === 200) {
            return xhr.response;
        } else {
            throw xhr.statusText;
        }
    });

    const decimatorUrl = await Net.getDecimatorUrl();
    xhr.open('POST', decimatorUrl + '/dxf');
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.responseType = options.responseType;

    xhr.send(JSON.stringify(options));
    return promise;
};


Net.createImageDownload = async (projectId, userId) => {
    const deferred = Q.defer();

    const decimatorUrl = await Net.getDecimatorUrl();
    const poll = function (task_id) {
        $.ajax({
            url:     decimatorUrl + '/images/' + task_id,
            success: function (response) {
                if (response.status == 'pending') {
                    setTimeout(poll, 5000, task_id);
                } else {
                    deferred.resolve(response.url);
                }
            }
        });
    };

    $.ajax({
        url:     decimatorUrl + '/images/' + projectId + '/' + userId,
        method:  'POST',
        success: function (response) {
            if (response.task_id) {
                poll(response.task_id)
            }
        }
    });

    return deferred.promise;
};


Net.pollAdditionalModels = async (taskId) => {
    const deferred = Q.defer();
    const decimatorUrl = await Net.getDecimatorUrl();

    $.ajax({
        url:         decimatorUrl + '/upload/' + taskId,
        crossDomain: true,
        success: function (response) { deferred.resolve(response); },
        error: function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};


Net._req = function (method, url, payload) {
    // TODO: switch to native promises already, this is getting ridiculous
    const deferred = Q.defer();

    let options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    };

    if (payload !== undefined) {
        options.body = JSON.stringify(payload);
    }

    // won’t send cross-origin cookies unless you set the credentials init option
    fetch(url, options)
        .then(response => {
            // Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500
            if (!response.ok) throw response;

            const contentType = response.headers.get('content-type');

            return (contentType && contentType.indexOf('application/json') !== -1)
                ? response.json()
                : response.text();
        })
        .then(data => deferred.resolve(data))
        .catch(error => deferred.reject(error));

    return deferred.promise;
};

Net.getCompanyLogoBase64 = function () {
    const token = localStorage.getItem('token');
    return Net._req('GET', `${Config.baseURL}/companies/current/image?token=${token}&base64=true`);
};

Net.shareProject = function (designId, data) {
    var deferred = Q.defer();

    // for hyst[eo]rical reasons, this is not a JSON request
    $.ajax({
        url:     `${Config.shareURL}/${designId}`,
        method:  'POST',
        context: this,
        data:    data,
        success: function (response)  { deferred.resolve(response); },
        error:   function (xhr) {
            var errMsg = xhr.responseJSON ? xhr.responseJSON.message : '';
            deferred.reject(new Error(`${xhr.statusText} (${xhr.status}): ${errMsg}`));
        }
    });

    return deferred.promise;
};


Net.loadAutoSegments = function (url) {
    var deferred = Q.defer();

    $.ajax({
        url: url,
        dataType: 'json',
        success: function (response) {
            deferred.resolve(response);
        },
        error: function (xhr) {
            deferred.reject(new Error(xhr.status + ': ' + xhr.statusText));
        }
    });

    return deferred.promise;
};


export { Net };
