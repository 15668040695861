var MODULE_TYPE_VALUES = {
    Standard: 0,
    Premium: 1,
    "Thin Film": 2
};

var MODULES_VALUES_TO_TYPE = {
    "0": "Standard",
    "1": "Premium",
    "2": "Thin Film"
};

var ARRAY_TYPE_VALUES = {
    "Fixed (Open Rack)": 0,
    "Fixed (Roof Mount)": 1,
    "1-Axis Tracking": 2,
    "1-Axis Backtracking": 3,
    "2-Axis Tracking": 4
};

var MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Annually"
];

var ARRAY_VALUES_TO_TYPE = {
    "0": "Fixed (Open Rack)",
    "1": "Fixed (Roof Mount)",
    "2": "1-Axis Tracking",
    "3": "1-Axis Backtracking",
    "4": "2-Axis Tracking"
};

var HEADERS_DESCRIPTIVE = [
    "Month",
    "Day",
    "Hour",
    "Beam Irradiance (W/m^2)",
    "Diffuse Irradiance (W/m^2)",
    "Ambient Temperature (C)",
    "Wind Speed (m/s)",
    "Plane of Array Irradiance (W/m^2)",
    "Cell Temperature (C)",
    "DC Array Output (W)",
    "AC System Output (W)"
];

var HEADERS_DESCRIPTIVE_TOTAL = [
    "Month",
    "Day",
    "Hour",
    "Beam Irradiance (W/m^2) -- Average",
    "Diffuse Irradiance (W/m^2) -- Average",
    "Ambient Temperature (C)",
    "Wind Speed (m/s)",
    "Plane of Array Irradiance (W/m^2) -- Average",
    "Cell Temperature (C) -- Average",
    "DC Array Output (W) -- Total",
    "AC System Output (W) -- Total"
];

var HEADERS_CODE = ["dn", "df", "tamb", "wspd", "poa", "tcell", "dc", "ac"];
var HEADERS_CODE_SET = new Set([
    "dn",
    "df",
    "tamb",
    "wspd",
    "poa",
    "tcell",
    "dc",
    "ac"
]);

var DAYS_IN_MONTHS = {
    "1": 31,
    "2": 28,
    "3": 31,
    "4": 30,
    "5": 31,
    "6": 30,
    "7": 31,
    "8": 31,
    "9": 30,
    "10": 31,
    "11": 30,
    "12": 31
};

var SCFLY_ASSETS_S3 = "https://scfly-assets.s3.amazonaws.com/"
var SCANIFLY_MODELS_LIBRARY = "https://scfly-assets.s3.amazonaws.com/scanifly-models-library/"

var SCANIFLY_GLOBAL_MODELS = {
    "lowPolyTrees": [
        {
            "name": "Conifer",
            "value": 1,
            "selected": false,
            "description": "Conifer",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Conifer-evergreen-75x75.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/conifer/LarchTree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/conifer/LarchTree.mtl",
            // "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/conifer/LarchTree_BaseColor.png",
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/conifer/",
        },
        {
            "name": "Deciduous Long Trunk",
            "value": 2,
            "selected": false,
            "description": "Deciduous Long Trunk",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Deciduous-long-trunk-75x75.png ",
            "url": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-long-trunk/BrazilNutTree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-long-trunk/BrazilNutTree.mtl",
            // "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-long-trunk/BrazilNutTree_BaseColor.png"
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-long-trunk/"
        },
        {
            "name": "Deciduous Short Trunk",
            "value": 3,
            "selected": false,
            "description": "Deciduous Short Trunk",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Deciduous-short-trunk-75x75.png ",
            "url": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-short-trunk/OakTree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-short-trunk/OakTree.mtl",
            // "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-short-trunk/OakTree_BaseColor.png"
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-short-trunk/"
        },
        {
            "name": "Palm Tree",
            "value": 4,
            "selected": false,
            "description": "Palm Tree",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Queen-palm-75x75.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/palm-tree/QueenPalmTree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/palm-tree/QueenPalmTree.mtl",
            // "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/palm-tree/QueenPalmTree_BaseColor.png"
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/palm-tree/"
        },
        {
            "name": "Deciduous Tree Dormant",
            "value": 8,
            "selected": false,
            "description": "Deciduous Tree Dormant",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "deciduous-tree-dormant-75x75.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-tree-dormant/dormantTree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-tree-dormant/dormantTree.mtl",
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/low-poly/trees/deciduous-tree-dormant/"
        }
    ],

    "highPolyTrees": [
        {
            "name": "River Birch",
            "value": 5,
            "selected": false,
            "description": "River Birch",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Betula-nigra-River-Birch-75x75.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/river-birch/Birch_Tree.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/river-birch/Birch_Tree.mtl",
            "textureUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/river-birch/"
        },
        {
            "name": "Birch Tree 2",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 2",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-2.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-2/river-birch-2.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-2/river-birch-2.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 3",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 3",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-3.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-3/river-birch-3.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-3/river-birch-3.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 4",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 4",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-4.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-4/river-birch-4.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-4/river-birch-4.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 5",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 5",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-5.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-5/river-birch-5.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-5/river-birch-5.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 6",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 6",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-6.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-6/river-birch-6.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-6/river-birch-6.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 7",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 7",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-7.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-7/river-birch-7.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-7/river-birch-7.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 8",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 8",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-8.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-8/river-birch-8.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-8/river-birch-8.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 9",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 9",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-9.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-9/river-birch-9.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-9/river-birch-9.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 10",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 10",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-10.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-10/river-birch-10.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-10/river-birch-10.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 11",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 11",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-11.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-11/river-birch-11.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-11/river-birch-11.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 12",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 12",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-12.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-12/river-birch-12.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-12/river-birch-12.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 13",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 13",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-13.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-13/river-birch-13.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-13/river-birch-13.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 14",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 14",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-14.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-14/river-birch-14.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-14/river-birch-14.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 15",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 15",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-15.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-15/river-birch-15.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-15/river-birch-15.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 16",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 16",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-16.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-16/river-birch-16.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-16/river-birch-16.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 17",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 17",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-17.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-17/river-birch-17.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-17/river-birch-17.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 18",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 18",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-18.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-18/river-birch-18.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-18/river-birch-18.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Birch Tree 19",
            "value": 5,
            "selected": false,
            "description": "Birch Tree 19",
            // "imageSrc": "https://i.img + "thumbnails/"ur.com/z9DY7ev.jpg",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "birch-tree-19.png",
            "url": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-19/river-birch-19.obj",
            "materialUrl": SCFLY_ASSETS_S3 + "vegetation/high-poly/trees/birch/birch-tree-19/river-birch-19.mtl",
            "textureUrl": "",
            "imperial": true
        }
    ],

    "inverters": [
        {
            "name": "SolarEdge HD Wave - Large",
            "value": 6,
            "selected": false,
            "description": "SolarEdge HD Wave - Large",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "inverter-hdwave-solaredge-large.png",
            "url": SCANIFLY_MODELS_LIBRARY + "inverters/inverter-hdwave-solaredge-large/inverter-hdwave-solaredge-large.obj",
            "materialUrl": SCANIFLY_MODELS_LIBRARY + "inverters/inverter-hdwave-solaredge-large/inverter-hdwave-solaredge-large.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "SolarEdge HD Wav - Small",
            "value": 8,
            "selected": false,
            "description": "SolarEdge HD Wav - Small",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "inverter-hdwave-solaredge-small.png",
            "url": SCANIFLY_MODELS_LIBRARY + "inverters/inverter-hdwave-solaredge-small/inverter-hdwave-solaredge-small.obj",
            "materialUrl": SCANIFLY_MODELS_LIBRARY + "inverters/inverter-hdwave-solaredge-small/inverter-hdwave-solaredge-small.mtl",
            "textureUrl": "",
            "imperial": true
        },
        {
            "name": "Fronius",
            "value": 6,
            "selected": false,
            "description": "Fronius",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "Fronius-Symo-10-27-3d-model-75x75.png",
            "url": SCANIFLY_MODELS_LIBRARY + "inverters/fronius/Fronius-v2-m.obj",
            "materialUrl": SCANIFLY_MODELS_LIBRARY + "inverters/fronius/Fronius-v2-m.mtl",
            "textureUrl": ""
        },
        {
            "name": "SMA Tripower",
            "value": 8,
            "selected": false,
            "description": "SMA Tripower",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "sma-tripower-75x75.png",
            "url": SCANIFLY_MODELS_LIBRARY + "inverters/sma/SMA_17LT_2015.obj",
            "materialUrl": SCANIFLY_MODELS_LIBRARY + "inverters/sma/SMA_17LT_2015.mtl",
            "textureUrl": "",
            "imperial": true
        },
    ],

    "carports": [
        {
            "name": "TYP Solar Carport",
            "value": 7,
            "selected": false,
            "description": "TYP Solar Carport",
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + "solar-carport-75x75.png",
            "url": SCANIFLY_MODELS_LIBRARY + "solar-carports/TYP/TYP_Solar_Carport_v2.obj",
            "materialUrl": SCANIFLY_MODELS_LIBRARY + "solar-carports/TYP/TYP_Solar_Carport_v2.mtl",
            "textureUrl": SCANIFLY_MODELS_LIBRARY + "solar-carports/TYP/"
        }
    ]
}

var SCANIFLY_EXCLUDE_ROWS = ["Ladder", "ladder", "ACDisconnect", "TeslaSupercharger",
        "SMATripower", "Antenna"] // Don't include h/w/d on these imports

var SCANIFLY_MISC_MODELS = [ "ladders", "shapes", "electrical-components",
        "ev-infrastructure", "telecom-infrastructure"]

var SCANIFLY_MISC_MODEL_OBJS = {
    "ladders": [
        {
            n: "24ft Ladder",
            thumb: "aluminum-extension-ladder-24ft.png",
            objUrl: "aluminum-extension-ladder-24ft/aluminum-extension-ladder-24ft" + ".obj",
            mtl: "aluminum-extension-ladder-24ft/aluminum-extension-ladder-24ft" + ".mtl",
        },
        {
            n: "10ft Ladder",
            thumb: "10'-Fiberglass-ladder-75x75.png",
            objUrl: "10_ladder" + ".obj",
            mtl: "10_ladder" + ".mtl",
        },
        {
            n: "Extension Ladder not extended",
            thumb: "16'-Fiberglass-Extension-ladder---0-extented--75x75.png",
            objUrl: "0_EXTENTION_UNITS_FEET" + ".obj",
            mtl: "0_EXTENTION_UNITS_FEET" + ".mtl",
        },
        {
            n: "Extension Ladder 2ft extended",
            thumb: "16'-Fiberglass-Extension-ladder---2-extented--75x75.png",
            objUrl: "2_EXTENTION_UNITS_FEET" + ".obj",
            mtl: "2_EXTENTION_UNITS_FEET" + ".mtl",
        },
        {
            n: "Extension Ladder 4ft extended",
            thumb: "16'-Fiberglass-Extension-ladder---4-extented--75x75.png",
            objUrl: "4_EXTENTION_UNITS_FEET" + ".obj",
            mtl: "4_EXTENTION_UNITS_FEET" + ".mtl",
        },
        {
            n: "Extension Ladder 6ft extended",
            thumb: "16'-Fiberglass-Extension-ladder---6-extented--75x75.png",
            objUrl: "6_EXTENTION_UNITS_FEET" + ".obj",
            mtl: "6_EXTENTION_UNITS_FEET" + ".mtl",
        }
    ],

    "shapes": [
        { n:"Cube 1x1x1 ft", objUrl: "1x1x1" + ".obj", mtl: "1x1x1" + ".mtl", thumb: "cube-75x75.png" },
        { n:"Cube 2x2x2 ft", objUrl: "2x2x2" + ".obj", mtl: "2x2x2" + ".mtl", thumb: "cube-75x75.png" },
        { n:"Cube 3x3x3 ft", objUrl: "3x3x3" + ".obj", mtl: "3x3x3" + ".mtl", thumb: "cube-75x75.png" },
        { n:"Cube 4x4x4 ft", objUrl: "4x4x4" + ".obj", mtl: "4x4x4" + ".mtl", thumb: "cube-75x75.png" },
        { n:"Cube 5x5x5 ft", objUrl: "5x5x5" + ".obj", mtl: "5x5x5" + ".mtl", thumb: "cube-75x75.png" },
        { n:"Cylinder 1D x 1H ft", objUrl: "1Dx1H" + ".obj", mtl: "1Dx1H" + ".mtl", thumb: "cylinder-75x75.png" },
        { n:"Cylinder 2D x 2H ft", objUrl: "2Dx2H" + ".obj", mtl: "2Dx2H" + ".mtl", thumb: "cylinder-75x75.png" },
        { n:"Cylinder 3D x 3H ft", objUrl: "3Dx3H" + ".obj", mtl: "3Dx3H" + ".mtl", thumb: "cylinder-75x75.png" },
        { n:"Cylinder 4D x 4H ft", objUrl: "4Dx4H" + ".obj", mtl: "4Dx4H" + ".mtl", thumb: "cylinder-75x75.png" },
        { n:"Cylinder 5D x 5H ft", objUrl: "5Dx5H" + ".obj", mtl: "5Dx5H" + ".mtl", thumb: "cylinder-75x75.png" }
    ],

    "electrical-components": [
        { n:`AC Disconnect - 60a`, objUrl: "AC-disco-0-export-two-sided-faces-off" + ".obj",
        mtl: "AC-disco-0-export-two-sided-faces-off" + ".mtl", thumb: "AC-disco-75x75.png" },
        { n:`200a Meter`, objUrl: "200a-meter/200a-meter" + ".obj",
        mtl: "200a-meter/200a-meter" + ".mtl", thumb: "200a-meter.png" },
        { n:`400a Meter`, objUrl: "400a-meter/400a-meter" + ".obj",
        mtl: "400a-meter/400a-meter" + ".mtl", thumb: "400a-meter.png" },
        { n:`20kW Generator`, objUrl: "generac-20kw-generator/generac-20kw-generator" + ".obj",
        mtl: "generac-20kw-generator/generac-20kw-generator" + ".mtl", thumb: "generac-20kw-generator.png", imperial: true },
        { n:`Smart Flower`, objUrl: "smart-flower/smart-flower" + ".obj",
        mtl: "smart-flower/smart-flower" + ".mtl", thumb: "smart-flower.png" },
        { n:`Conduit - 1 in - 6 ft - Horizontal`, objUrl: "Conduit-1in-6ft-Horizontal/1in-6ft-horizontal" + ".obj",
        mtl: "Conduit-1in-6ft-Horizontal/1in-6ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 1 in - 10 ft - Horizontal`, objUrl: "Conduit-1in-10ft-Horizontal/1in-10ft-horizontal" + ".obj",
        mtl: "Conduit-1in-10ft-Horizontal/1in-10ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 1 in - 20 ft - Horizontal`, objUrl: "Conduit-1in20ft-Horizontal/1in-20ft-horizontal" + ".obj",
        mtl: "Conduit-1in20ft-Horizontal/1in-20ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 2 in - 6 ft - Horizontal`, objUrl: "Conduit-2in-6ft-Horizontal/2in-6ft-horizontal" + ".obj",
        mtl: "Conduit-2in-6ft-Horizontal/2in-6ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 2 in - 10 ft - Horizontal`, objUrl: "Conduit-2in-10ft-Horizontal/2in-10ft-horizontal" + ".obj",
        mtl: "Conduit-2in-10ft-Horizontal/2in-10ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 2 in - 20 ft - Horizontal`, objUrl: "Conduit-2in-20ft-Horizontal/2in-20ft-horizontal" + ".obj",
        mtl: "Conduit-2in-20ft-Horizontal/2in-20ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 3 in - 6 ft - Horizontal`, objUrl: "Conduit-3in-6ft-Horizontal/3in-6ft-horizontal" + ".obj",
        mtl: "Conduit-3in-6ft-Horizontal/3in-6ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 3 in - 10 ft - Horizontal`, objUrl: "Conduit-3in-10ft-Horizontal/3in-10ft-horizontal" + ".obj",
        mtl: "Conduit-3in-10ft-Horizontal/3in-10ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 3 in - 20 ft - Horizontal`, objUrl: "Conduit-3in-20ft-Horizontal/3in-20ft-horizontal" + ".obj",
        mtl: "Conduit-3in-20ft-Horizontal/3in-20ft-horizontal" + ".mtl", thumb: "conduit-horizontal.png" },
        { n:`Conduit - 1 in - 6 ft - Vertical`, objUrl: "Conduit-1in-6ft-vertical/1in-6ft-vertical" + ".obj",
        mtl: "Conduit-1in-6ft-vertical/1in-6ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 1 in - 10 ft - Vertical`, objUrl: "Conduit-1in-10ft-vertical/1in-10ft-vertical" + ".obj",
        mtl: "Conduit-1in-10ft-vertical/1in-10ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 1 in - 20 ft - Vertical`, objUrl: "Conduit-1in20ft-vertical/1in-20ft-vertical" + ".obj",
        mtl: "Conduit-1in20ft-vertical/1in-20ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 2 in - 6 ft - Vertical`, objUrl: "Conduit-2in-6ft-vertical/2in-6ft-vertical." + ".obj",
        mtl: "Conduit-2in-6ft-vertical/2in-6ft-vertical." + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 2 in - 10 ft - Vertical`, objUrl: "Conduit-2in-10ft-vertical/2in-10ft-vertical" + ".obj",
        mtl: "Conduit-2in-10ft-vertical/2in-10ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 2 in - 20 ft - Vertical`, objUrl: "Conduit-2in-20ft-vertical/2in-20ft-vertical" + ".obj",
        mtl: "Conduit-2in-20ft-vertical/2in-20ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 3 in - 6 ft - Vertical`, objUrl: "Conduit-3in-6ft-vertical/3in-6ft-vertical" + ".obj",
        mtl: "Conduit-3in-6ft-vertical/3in-6ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 3 in - 10 ft - Vertical`, objUrl: "Conduit-3in-10ft-vertical/3in-10ft-vertical" + ".obj",
        mtl: "Conduit-3in-10ft-vertical/3in-10ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
        { n:`Conduit - 3 in - 20 ft - Vertical`, objUrl: "Conduit-3in-20ft-vertical/3in-20ft-vertical" + ".obj",
        mtl: "Conduit-3in-20ft-vertical/3in-20ft-vertical" + ".mtl", thumb: "conduit-vertical.png" },
    ],

    "ev-infrastructure": [
        { n:`Tesla Supercharger`, objUrl: "Tesla_Supercharger" + ".obj",
        mtl: "Tesla_Supercharger" + ".mtl", thumb: "tesla-supercharger75x75.png" },
        { n:`Tesla Powerwall 2`, objUrl: "tesla-powerwall-2/tesla-powerwall-2" + ".obj",
        mtl: "tesla-powerwall-2/tesla-powerwall-2" + ".mtl", thumb: "tesla-powerwall-2.png" },
        { n:`Tesla Powerpack - Single Unit`, objUrl: "tesla-powerpack-single/tesla-powerpack-single" + ".obj",
        mtl: "tesla-powerpack-single/tesla-powerpack-single" + ".mtl", thumb: "tesla-powerpack-single.png" },
        { n:`Tesla Powerpack - Full`, objUrl: "tesla-powerpack-full/tesla-powerpack-full" + ".obj",
        mtl: "tesla-powerpack-full/tesla-powerpack-full" + ".mtl", thumb: "tesla-powerpack-full.png" },
    ],

    "telecom-infrastructure": [
        { n:`Satellite Dish`, objUrl: "Satellite_Dish_2_NEW" + ".obj",
        mtl: "Satellite_Dish_2_NEW" + ".mtl", thumb: "Satellite-Dish-75x75.png" },

        { n:`Antenna`, objUrl: "Antenna" + ".obj",
        mtl: "Antenna" + ".mtl", thumb: "tv-roof-antenna-75x75.png" },
    ],
}

SCANIFLY_MISC_MODELS.forEach( (item) => {
    let modelsList = SCANIFLY_MISC_MODEL_OBJS[item] // []
    modelsList.forEach( (model, idx) => {

        if(!SCANIFLY_GLOBAL_MODELS[item]) {
            SCANIFLY_GLOBAL_MODELS[item] = []
        }

        SCANIFLY_GLOBAL_MODELS[item].push({
            "name": model.n,
            "value": 9 + idx, // Start at +1 previous list
            "selected": false,
            "description": model.n,
            "imageSrc": SCANIFLY_MODELS_LIBRARY + "thumbnails/" + model.thumb,
            "url": SCANIFLY_MODELS_LIBRARY + item + "/" + model.objUrl,
            "materialUrl": SCANIFLY_MODELS_LIBRARY + item + "/" + model.mtl,
            "imperial": true // These were all exported in imperial units from SketchUp
        })
    })
})

var TEXTURE_MAPS_ASSETS = [
    {
        "name": "Monocrystalline Blue",
        "value": "mono",
        "selected": false,
        "description": "Monocrystalline Blue",
        "imageSrc": 'styles/images/modules/module-monocrystalline.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Blue II",
        "value": "sunpower-x",
        "selected": false,
        "description": "Monocrystalline Blue II",
        "imageSrc": 'styles/images/modules/module-sunpower-x.jpg?x=v3',
    },
    {
        "name": "Polycrystalline Blue",
        "value": "poly",
        "selected": false,
        "description": "Polycrystalline Blue",
        "imageSrc": 'styles/images/modules/module-polycrystalline.jpg?x=v3'
    },
    {
        "name": "Monocrystalline Black",
        "value": "mono-black",
        "selected": false,
        "description": "Monocrystalline Black",
        "imageSrc": 'styles/images/modules/module-monocrystalline-black.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Black II",
        "value": "sunpower-x-black",
        "selected": false,
        "description": "Monocrystalline Black II",
        "imageSrc": 'styles/images/modules/module-sunpower-x-black.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Black III",
        "value": "lg-neon",
        "selected": false,
        "description": "Monocrystalline Black III",
        "imageSrc": 'styles/images/modules/module-lg-neon.jpg?x=v3',
    },
    {
        "name": "Polycrystalline Blue II",
        "value": "poly-blue-2",
        "selected": false,
        "description": "Polycrystalline Blue II",
        "imageSrc": 'styles/images/modules/module-polycrystalline-2.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Blue III",
        "value": "mono-blue-3",
        "selected": false,
        "description": "Monocrystalline Blue III",
        "imageSrc": 'styles/images/modules/module-monocrystalline-3.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Black IV",
        "value": "mono-black-4",
        "selected": false,
        "description": "Monocrystalline Black IV",
        "imageSrc": 'styles/images/modules/module-monocrystalline-black-4.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Blue",
        "value": "mono",
        "selected": false,
        "description": "Monocrystalline Blue",
        "imageSrc": 'styles/images/modules/module-monocrystalline.jpg?x=v3',
    },
    {
        "name": "Monocrystalline Black V",
        "value": "mono-black-5",
        "selected": false,
        "description": "Monocrystalline Black V",
        "imageSrc": 'styles/images/modules/module-monocrystalline-black-5.png?x=v3',
    }
]


export {
  TEXTURE_MAPS_ASSETS,
  SCANIFLY_MISC_MODEL_OBJS,
  SCANIFLY_MISC_MODELS,
  SCANIFLY_EXCLUDE_ROWS,
  SCANIFLY_GLOBAL_MODELS,
  SCANIFLY_MODELS_LIBRARY,
  SCFLY_ASSETS_S3,
  DAYS_IN_MONTHS,
  HEADERS_CODE_SET,
  HEADERS_CODE,
  HEADERS_DESCRIPTIVE_TOTAL,
  HEADERS_DESCRIPTIVE,
  ARRAY_VALUES_TO_TYPE,
  MONTHS,
  ARRAY_TYPE_VALUES,
  MODULES_VALUES_TO_TYPE,
  MODULE_TYPE_VALUES
};
