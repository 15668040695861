import { PolygonDrawer } from '../libs/PolygonDrawer';
import { BufferAttribute, BufferGeometry, DoubleSide, LineBasicMaterial, Matrix3, Mesh, MeshBasicMaterial, MeshLambertMaterial, Plane, Vector3 } from '../libs/three.module';




class VolumeTool extends PolygonDrawer {
    constructor() {
        super();

        this.material = new LineBasicMaterial({
            color:       0x31ef78,
            linewidth:   3,
            transparent: true
        });

        this.unitTypes = { METERS: 0, YARDS: 1 };
        this.units = this.unitTypes.METERS;

        this.volume = 0;
    }

    setUpdateGui(callback) {
        var self = this;

        this.updateGui = function () {
            var volume = self.volume;

            if (self.units === self.unitTypes.YARDS) {
                volume *= 1.30795; // cubic yards
            }

            callback({ volume: volume, units: self.units });
        };
    }

    setUnits(units) {
        this.units = units;
        this.updateGui();
    }

    closedLoop() {
        var fitPlane = this.planeFromSegments();

        var polygonGeometry = this.getPolygonGeometry(fitPlane);
        var material = new MeshBasicMaterial({
            color:       0xcafff9,
            side:        DoubleSide,
            //transparent: true,
            opacity:     0.7
        });

        this.fillMesh = new Mesh(polygonGeometry, material);
        this.scene.add(this.fillMesh);

        var cutGeometry = this.getCutGeometry(fitPlane.normal, polygonGeometry.vertices[0]);
        var material = new MeshLambertMaterial({ color: 0xeeeeee });
        this.cutMesh = new Mesh(cutGeometry, material);
        this.cutMesh.position.z = 7;
        this.object.add(this.cutMesh);

        this.calculateVolume(cutGeometry, fitPlane);
        this.updateGui();
    }

    getCutGeometry(normal, point) {
        var pos     = this.meshes[0].geometry.attributes.position.array;
        var normals = this.meshes[0].geometry.attributes.normal.array;

        var newPos     = [];
        var newNormals = [];

        normal = this.normalWorldToLocal(this.object, normal.clone());
        point = this.object.worldToLocal(point.clone());

        var polygon = this.getLocalPolygonBoundaries();

        for (var i = 0; i < pos.length; i += 9) {
            var a = new Vector3(pos[i],   pos[i+1], pos[i+2]);
            var b = new Vector3(pos[i+3], pos[i+4], pos[i+5]);
            var c = new Vector3(pos[i+6], pos[i+7], pos[i+8]);

            var faceAbovePlane = normal.clone().dot(a.clone().sub(point)) >= 0 &&
                                 normal.clone().dot(b.clone().sub(point)) >= 0 &&
                                 normal.clone().dot(c.clone().sub(point)) >= 0;
            var faceInPolygon = polygon.contains(a) || polygon.contains(b) || polygon.contains(c);

            if (faceAbovePlane && faceInPolygon) {
                for (var j = 0; j < 9; j++) {
                    newPos.push(pos[i+j]);
                    newNormals.push(normals[i+j]);
                }
            }
        }

        var geometry = new BufferGeometry();

        geometry.addAttribute('position', new BufferAttribute( new Float32Array(newPos),     3 ));
        geometry.addAttribute('normal',   new BufferAttribute( new Float32Array(newNormals), 3 ));

        return geometry;
    }

    /**
     * Calculate the volume from geometry to the plane.
     *
     * The volume is calculated from a sum of volumes of truncated triangular
     * prisms formed between each face and the base plane.
     *
     * ref: http://mathcentral.uregina.ca/QQ/database/QQ.09.07/h/jhey1.html
     */
    calculateVolume(geometry, plane) {
        var pos = geometry.attributes.position.array;

        var normal = this.normalWorldToLocal(this.object, plane.normal.clone());
        var centroid = this.object.worldToLocal(plane.centroid.clone());

        var p = new Plane();
        p.setFromNormalAndCoplanarPoint(normal, centroid);

        this.volume = 0;

        for (var i = 0; i < pos.length; i += 9) {
            var a = new Vector3(pos[i],   pos[i+1], pos[i+2]);
            var b = new Vector3(pos[i+3], pos[i+4], pos[i+5]);
            var c = new Vector3(pos[i+6], pos[i+7], pos[i+8]);

            var A = this.calculateTriangleArea(a, b, c);
            var da = Math.max(0, p.distanceToPoint(a));
            var db = Math.max(0, p.distanceToPoint(b));
            var dc = Math.max(0, p.distanceToPoint(c));

            this.volume += A * (da + db + dc) / 3;
        }

        return this.volume;
    }

    /**
     * Update the normal from world space to object's local space.
     *
     * Object3D.worldToLocal() does not work for normals.
     */
    normalWorldToLocal(object, normal) {
        var normalMatrix  = new Matrix3().getNormalMatrix(object.matrixWorld);
        var inverseMatrix = new Matrix3().getInverse(normalMatrix);
        normal.applyMatrix3(inverseMatrix).normalize();

        return normal;
    }

    calculateTriangleArea(a, b, c) {
        return (a.x*b.y + c.x*a.y + b.x*c.y - c.x*b.y - b.x*a.y - a.x*c.y) / 2;
    }

    clear() {
        PolygonDrawer.prototype.clear.call(this);

        this.object.remove(this.cutMesh);
        this.scene.remove(this.fillMesh);
        this.scene.remove(this.frame);

        this.volume = 0;
        this.updateGui();
    }
}



export { VolumeTool };
