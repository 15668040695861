/// @ts-check


/**
 * @typedef {{name?: string, enable: () => void, disable: () => void}} ITool
 */
class ToolSelector {
    constructor() {
        /** @type {WeakSet<ITool>} */
        this.tools = new WeakSet();

        /** @type {Set<ITool>} */
        this.enabled = new Set();
    }

    /** @param {ITool} tool */
    register(tool) {
        this.tools.add(tool);
    }

    /**
     * @param {ITool} tool
     */
    enable(tool) {
        if (this.enabled.has(tool)) {
            return;
        }

        for (let t of this.enabled) {
            // occluders should be enabled when shade tool is enabled
            let keepEnabled = (t.name === 'shadeTool'    && tool.name === 'occluderTool') ||
                              (t.name === 'occluderTool' && tool.name === 'shadeTool');

            if (t !== tool && !keepEnabled) {
                t.disable();
                this.enabled.delete(t);
            }
        }

        tool.enable();
        this.enabled.add(tool);
    }

    /** @param {ITool} tool */
    isEnabled(tool) {
        return this.enabled.has(tool);
    }

    getCurrentlyEnabled() {
        return Array.from(this.enabled);
    }
}


export { ToolSelector };