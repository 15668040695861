import * as d3 from 'd3';
import { Net } from './Net.js';
import { Config } from '../../bootstrap/Config.js'
import { project, currentDesign } from '../Viewer.js'

function deallocateObject(obj) {
    obj.geometry.dispose();
    obj.geometry = null;
    obj.material.dispose();
    obj.material = null;
}

/**
 * @template T
 * @param {T[]} arr
 * @param {(_: T) => number} [key]
 */
const sum = function (arr, key) {
    var sum = 0;

    if (key === undefined) {
        key = x => x;
    }

    for (var i = 0; i < arr.length; i++) {
        sum += key(arr[i]);
    }

    return sum;
};

const avg = function (arr) {
    return sum(arr, function (x) { return parseFloat(x); }) / arr.length;
};

export const capitalize = function(str) {
    if(typeof str !== "string") { return "" }
    return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Separate thousands with commas.
 */
const formatK = function (x) {
    return d3.format(',')(Math.round(x));
};

const invert = function (obj) {
    const ret = {};

    Object.keys(obj).forEach((key) => {
        ret[obj[key]] = key;
    });

    return ret;
};

const getPlaneName = function (n) {
    var name = [];

    while (n >= 0) {
        name.push(String.fromCharCode(65 + n % 26));
        n = n / 26 - 1;
    }

    name.reverse();

    return name.join('');
};

/**
 * @template T
 * @param {T} o
 * @returns {T}
 */
const deepCopy = function (o) {
    // we can use a different lib or own solution in the future to avoid depending on jQuery
    return $.extend(true, {}, o);
}

/**
 * Quick and dirty port of numpy.arange
 *
 * @param {number} start
 * @param {number} stop
 * @param {number} [step = 1]
 */
const range = function* (start, stop, step = 1) {
    for (let i = start; i < stop; i += step) {
        yield i;
    }
}

/**
 * Quick and dirty port of numpy.arange
 * @param {number} start
 * @param {number} stop
 * @param {number} [step = 1]
 */
const rangeArray = function (start, stop, step = 1) {
    if (Math.sign(stop - start) != Math.sign(step)) {
        throw "Can't expand an infinite iterator as an array";
    }

    return Array.from(range(start, stop, step));
}

/**
 * Quick and dirty port of PHP's array_chunk.
 * @param {number} chunkSize
 */
const chunkBy = function (chunkSize) {
    if (chunkSize <= 0) {
        throw "You cannot chunk by zero.";
    }

    return /** @template T @param {ArrayLike<T>} arr */ arr => {
        const chunks = [];
        let chunk = [];
        for (let i = 0; i < arr.length; ++i) {
            chunk.push(arr[i]);
            if (chunk.length >= chunkSize) {
                chunks.push(chunk);
                chunk = [];
            }
        }

        if (chunk.length > 0) {
            chunks.push(chunk);
        }

        return chunks;
    }
};

/**
 * Given an array {arr} of totally-orderable objects under the ordering function {cmp},
 * get an array containing the same objects without duplicates under {cmp}.
 *
 * The resultant array will respect the original ordering of {arr} except for the omission
 * of duplicate elements;
 *
 * @template T
 * @param {T[]} arr
 * @param {(v1: T, v2: T) => number} cmp
 * @returns {T[]}
 */
const dedupe = function (arr, cmp) {
    if (arr.length === 0) {
        return [];
    }

    /** @param {[T, keyof arr]} v1 @param {[T, keyof arr]} v2 */
    const tagCmp = (v1, v2) => cmp(v1[0], v2[0]);

    const taggedCopy = arr.map(
        /** @returns {[T, keyof arr]} */ (v, idx) => [v, idx]
    ).sort(
        tagCmp
    );

    /** @type {[T, keyof arr][]} */
    const dedupedTaggedCopy = [taggedCopy[0]];

    for (let i = 1; i < taggedCopy.length; ++i) {
        if (tagCmp(taggedCopy[i], dedupedTaggedCopy[dedupedTaggedCopy.length - 1]) !== 0) {
            dedupedTaggedCopy.push(taggedCopy[i]);
        }
    }

    // Restore the original ordering of the array modulo duplicates
    return dedupedTaggedCopy.sort(
        (v1, v2) => v1[1] < v2[1] ? -1 : (v1[1] > v2[1] ? 1 : 0)
    ).map(
        v => v[0]
    );
};

/**
 * Quick and dirty port of python's sorted().
 *
 * @template T
 * @param {T[]} arr A collection of objects totally-orderable under {cmp}.
 * @param {(t1: T, t2: T) => number} cmp Total-ordering for T such that t1 < t2
 *   iff cmp(t1, t2) < 0, t1 > t2 iff cmp(t1, t2) > 0.
 * @returns {T[]} A copy of {arr} with objects in ascending order by {cmp}.
 */
const sorted = function (arr, cmp) {
    const output = deepCopy(arr);
    return output.sort(cmp);
};

 /**
 * @template {any[][]} U
 * @param {U} arrs
 * @returns {import("../../../types/index").Zip<U>[]}
 */
const zip = function (...arrs) {
    const minLength = Math.min(...arrs.map(r => r.length));

    /** @type {import("../../../types/index").Zip<U>[]} */
    const result = [];
    for (let i = 0; i < minLength; ++i) {
        /// @ts-ignore because I can't jsdoc do an "as cast"
        result.push(arrs.map(r => r[i]));
    };

    return result;
};

/**
 * Extract the maximum valued object from an array of {objects}, where "maximum"
 * is over a metric on {objects} given by the function {metric}.
 *
 * In the event of ties, the first entry in {objects} having the maximum value
 * is returned.
 *
 * @template T
 * @param {T[]} objects
 * @param {(_: T) => number} metric
 */
const maxByMetric = function (objects, metric) {
    /** @type {T?} */
    let maxValued = null;
    let maxValue = -Infinity;

    for (let i = 0; i < objects.length; ++i) {
        const val = metric(objects[i]);

        if (val > maxValue) {
            maxValue = val;
            maxValued = objects[i];
        }
    }

    return maxValued;
};

/**
 * Sometimes, for some reason, you have a field that is usually a number, to
 * which you need to apply toFixed, but can sometimes also be a non-numeric string.
 *
 * This yields a callable that will act as toFixed if the arg {p} is a number, or
 * will simply pass through {p} if it's a string. So that you don't have to think
 * about it.
 *
 * @param {number | string} p
 * @returns {typeof Number.prototype.toFixed}
 */
const toFixedIfNumber = function (p) {
    return typeof p === 'number' ? p.toFixed.bind(p) : () => p
};

/**
 * @param {number} [min = -Infinity]
 * @param {number} [max = Infinity]
 */
const clampBetween = (min = -Infinity, max = Infinity) => {
    return /** @param {number} num */ (num) => num < min ? min : (num > max ? max : num);
};

/**
 * Class encapsulating the data needed to download and potentially store
 * a file.
 * @interface
 * @todo uncomment when our build process allows us to have nice things
 */
//class DownloadFileMetaData {
//    /**
//     * @var {Blob} blob blob of data to be downloaded/stored
//     */
//    blob;
//
//    /**
//     * @var {string} filename name of the file to be downloaded/stored
//     */
//    filename;
//
//    /**
//     * @var {string|undefined} url existing public url to reuse
//     */
//    url;
//}

/**
 * Causes the browser to download the provided fileMetaData.blob
 * and save it as fileMetaData.filename. Here we are reusing
 * DownloadFileMetaData, and the url field is unused.
 * @param {DownloadFileMetaData} fileMetaData
 */
const browserDownload = function( fileMetaData ) {
    var url = window.URL.createObjectURL(fileMetaData.blob);
    browserDownloadUrl(url, fileMetaData.filename);
    window.URL.revokeObjectURL(url);
}

/**
 * Triggers the browser to download the provided url as a file.
 * @param {string} url           The url to download
 * @param {string} [filename=''] If specified, alternative filename to download the url as.
 */
const browserDownloadUrl = function (url, filename = '') {
    const a = document.createElement('a');
    a.hidden = true;
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

/**
 *
 * @param {EventTarget} eventTarget
 * @param {string} eventName
 * @returns {Promise} resolves when the event is received
 */
const createPromiseFromDomEvent = function(eventTarget, eventName) {
    return new Promise((resolve) => {
        const handleEvent = () => {
            eventTarget.removeEventListener(eventName, handleEvent);
            resolve();
        };
        eventTarget.addEventListener(eventName, handleEvent);
    });
}

/**
 * @returns {Promise<boolean>}
 */
const shouldStoreExportsForPublicAPI = async function() {
    const company_info = await Net.getLoggedInUserCompany();
    return company_info.accessTokensIssued
}

/**
 *
 * @param {DownloadFileMetaData} fileMetaData
 * @returns {Promise} URL that can be used to retrieve the file in the future
 */
const downloadAndArchiveForPublicAPI = async function( fileMetaData ) {
    var exportUrl = undefined;

    if (await shouldStoreExportsForPublicAPI()) {
        const key = fileMetaData.url ?
                        new URL(fileMetaData.url).pathname.substring(1) :
                        `${project.id}/${currentDesign.id}/exports/${fileMetaData.filename}`;
        const reader = new FileReader();
        const readerPromise = createPromiseFromDomEvent(  reader, 'load' );
        reader.readAsDataURL(fileMetaData.blob);
        await readerPromise;
        const base64 = reader.result;

        const decimatorUrl = await Net.getDecimatorUrl();
        const postJsonResponse = await Net.postJSON(decimatorUrl + '/s3', {
                key:          key,
                base64:       base64,
                content_type: fileMetaData.blob.type
        });
        exportUrl = postJsonResponse.url;
    }
    browserDownload(fileMetaData);
    return exportUrl;
}

/**
 * Creates and returns a Blob from the provided base64 encoded string.
 * https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @param {string} b64Data base64 encoded string
 * @param {string} contentType mime type for blob
 * @param {number} sliceSize size of chunks for processing to blob.
 * @returns Blob with provided mimetype and decoded base64 string
 */
const b64toBlob = function(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export { avg, browserDownload, browserDownloadUrl, chunkBy, clampBetween, createPromiseFromDomEvent,
    deallocateObject, deepCopy, downloadAndArchiveForPublicAPI, formatK, getPlaneName, rangeArray, shouldStoreExportsForPublicAPI,
    sum, toFixedIfNumber, zip };
