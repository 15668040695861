import { ErrorPointsNotOnPlane } from '../errors/Errors';
import * as THREE from './three.module';
import { Vector3 } from './three.module';
import { Matrix4 } from './three.module';



const iUnitVector = Object.freeze(new THREE.Vector3(1, 0, 0));
const jUnitVector = Object.freeze(new THREE.Vector3(0, 1, 0));
const kUnitVector = Object.freeze(new THREE.Vector3(0, 0, 1));

function radToDeg(rad) {
    return (180.0 * rad / Math.PI);
}

function degToRad(deg) {
    return (Math.PI * deg / 180.0);
}

/**
 * @param {number} heading Clockwise from north
 * @returns {THREE.Vector3} Unit vector at given heading, in x-z plane with +x west and +z north
 */
function headingToDirectionVector(heading) {
    heading = degToRad(heading);
    return new THREE.Vector3(
        -1 * Math.sin(heading),
        0,
        Math.cos(heading)
    );
}

var azElToCoord = function (origin, r, azEl) {
    var x = origin.x + r * Math.cos(degToRad(azEl.elevation)) * Math.cos(degToRad(azEl.azimuth + 90));
    var y = origin.y + r * Math.cos(degToRad(azEl.elevation)) * Math.sin(degToRad(azEl.azimuth + 90));

    return new THREE.Vector2(x, y);
};

var interpolateArc = function (origin, r, start, end, vectors) {
    var start = (start + 360) % 360;
    var end = (end + 360) % 360;

    if (start > end) {
        var tmp = start;
        start = end;
        end = tmp;
    }

    var a = start;
    while (a < end) {
        var x = origin.x + r * Math.cos(degToRad(a + 90));
        var y = origin.y + r * Math.sin(degToRad(a + 90));

        vectors.push(new THREE.Vector2(x, y));

        a = (a + 10) % 360;
    }
};

/**
 * See http://geomalgorithms.com/a05-_intersect-1.html, section 'Intersection
 * of 2 Planes', subsection (A) Direct Linear Equation
 */
var intersectPlanes = function (p1, p2) {
    // find direction of line at intersection of 2 planes
    var dir = p1.normal.clone().cross(p2.normal);

    if (dir.distanceTo(new THREE.Vector3(0, 0, 0)) === 0)
        return null; // 2 planes are parallel

    // use direct linear equation to find point on intersection; choose which
    // coordinate to set as zero by selecting the largest absolute value in dir
    // vector
    var X = Math.abs(dir.x);
    var Y = Math.abs(dir.y);
    var Z = Math.abs(dir.z);

    var point;

    if (Z >= X && Z >= Y) {
        point = _solveIntersectingPoint('z', 'x', 'y', p1, p2);
    } else if (Y >= Z && Y >= X){
        point = _solveIntersectingPoint('y', 'z', 'x', p1, p2);
    } else {
        point = _solveIntersectingPoint('x', 'y', 'z', p1, p2);
    }

    return [ point, dir ];
};

/**
 * Find point on intersection of 2 planes.
 *
 * Depending on orientation of planes, solution for zero point can be on x, y
 * or z axis.
 */
var _solveIntersectingPoint = function (zeroCoord, A, B, p1, p2) {
    var a1 = p1.normal[A];
    var b1 = p1.normal[B];
    var d1 =  p1.constant;

    var a2 = p2.normal[A];
    var b2 = p2.normal[B];
    var d2 =  p2.constant;

    var A0 = ((b2 * d1) - (b1 * d2)) / ((a1 * b2 - a2 * b1));
    var B0 = ((a1 * d2) - (a2 * d1)) / ((a1 * b2 - a2 * b1));

    var point = new THREE.Vector3();
    point[zeroCoord] = 0;
    point[A] = A0;
    point[B] = B0;

    return point;
};

/**
 * Math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
 */
var intersectLineSegments = function (ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
    // one or both lines have length 0
    if ((ax1 === ax2 && ay1 === ay2) || (bx1 === bx2 && by1 === by2))
        return false;

    var denominator = ((by2 - by1) * (ax2 - ax1) - (bx2 - bx1) * (ay2 - ay1));

    // lines are parallel
    if (denominator === 0)
        return false;

    var ua = ((bx2 - bx1) * (ay1 - by1) - (by2 - by1) * (ax1 - bx1)) / denominator;
    var ub = ((ax2 - ax1) * (ay1 - by1) - (ay2 - ay1) * (ax1 - bx1)) / denominator;

    // intersection is outside the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1)
        return false;

    var x = ax1 + ua * (ax2 - ax1);
    var y = ay1 + ua * (ay2 - ay1);

    return { x, y };
};

/**
 * Find nearest neighbor point (x, y) in quadtree node
 * Based on code from http://bl.ocks.org/patricksurry/6478178
 * Requires D3 v3
 */
var kNearest = function (x, y, best, node) {
    var x1 = node.x1, y1 = node.y1, x2 = node.x2, y2 = node.y2;

    node.visited = true;

    // exclude node if point is farther away than best distance in either axis
    if (x < x1 - best.d || x > x2 + best.d || y < y1 - best.d || y > y2 + best.d)
        return best;

    // test point if there is one, potentially updating best
    var p = node.point;

    if (p) {
        p.scanned = true;

        var dx = p[0] - x, dy = p[1] - y, d = Math.sqrt(dx*dx + dy*dy);

        if (d < best.d) {
            best.d = d;
            best.p = p;
        }
    }

    // check if kid is on the right or left, and top or bottom
    // and then recurse on most likely kids first, so we quickly find a
    // nearby point and then exclude many larger rectangles later
    var kids = node.nodes;

    var rl = (2*x > x1 + x2);
    var bt = (2*y > y1 + y2);

    if (kids[bt*2+rl])         best = kNearest(x, y, best, kids[bt*2+rl]);
    if (kids[bt*2+(1-rl)])     best = kNearest(x, y, best, kids[bt*2+(1-rl)]);
    if (kids[(1-bt)*2+rl])     best = kNearest(x, y, best, kids[(1-bt)*2+rl]);
    if (kids[(1-bt)*2+(1-rl)]) best = kNearest(x, y, best, kids[(1-bt)*2+(1-rl)]);

    return best;
};


/**
 * Constructs a plane from a collection of points so that the summed squared
 * distance to all points is minimized.
 *
 * ref (in Rust): http://www.ilikebigbits.com/blog/2015/3/2/plane-from-points
 *
 * @returns {PlaneDescriptor} Object containing centroid and normal vector to fit plane
 */
var planeFromPoints = function (vv) {
    if (vv.length < 3)
        return null;

    var sum = new THREE.Vector3(0, 0, 0);

    for (let v of vv)
        sum.add(v);

    var centroid = sum.clone().multiplyScalar(1.0 / vv.length);

    // calculate full 3x3 covariance matrix, excluding symmetries
    var xx = 0; var xy = 0; var xz = 0;
    var yy = 0; var yz = 0; var zz = 0;

    for (let v of vv) {
        let r = v.clone().sub(centroid);

        xx += r.x * r.x;
        xy += r.x * r.y;
        xz += r.x * r.z;
        yy += r.y * r.y;
        yz += r.y * r.z;
        zz += r.z * r.z;
    }

    var detX = yy*zz - yz*yz;
    var detY = xx*zz - xz*xz;
    var detZ = xx*yy - xy*xy;

    var detMax = Math.max(detX, detY, detZ);

    if (detMax <= 0)
        throw new ErrorPointsNotOnPlane('The points do not span a plane');

    // pick a path with best conditioning

    /** @type {import('three').Vector3} */
    let normal;

    if (detMax === detX) {
        var a = (xz*yz - xy*zz) / detX;
        var b = (xy*yz - xz*yy) / detX;

        normal = new THREE.Vector3(1, a, b);

    } else if (detMax === detY) {
        var a = (yz*xz - xy*zz) / detY;
        var b = (xy*xz - yz*xx) / detY;

        normal = new THREE.Vector3(a, 1, b);

    } else if (detMax === detZ) {
        var a = (yz*xy - xz*yy) / detZ;
        var b = (xz*xy - yz*xx) / detZ;

        normal = new THREE.Vector3(a, b, 1);
    }

    normal.normalize();

    // flip normal to always point out with respect to horizon
    if (normal.angleTo(jUnitVector) > Math.PI/2)
        normal.negate();

    return {
        normal,
        centroid,
        threePlane: new THREE.Plane(normal, -1 * normal.dot(centroid))
    };
}


/**
 * Returns a new matrix that transforms from plane space to world space
 * @param {THREE.Plane} plane
 * @returns {THREE.Matrix4}
 */
 export const planeToMatrix4 = (plane) => {
    const X = new THREE.Vector3(1, 0, 0);
    const Y = new THREE.Vector3(0, 1, 0);
    const Z = new THREE.Vector3(0, 0, 1);

    const w = plane.normal;
    const o = w.clone().multiplyScalar(-plane.constant);

    const dotX = w.dot(X);
    const dotY = w.dot(Y);
    const dotZ = w.dot(Z);

    let u = undefined;
    if(dotX <= dotY && dotX <= dotZ) {
        // least projection is X
        u = X.cross(w);
    } else if(dotY <= dotX && dotY <= dotZ) {
        // least projection is Y
        u = Y.cross(w);
    } else {
        // least projection is Z
        u = Z.cross(w);
    }
    const v = u.clone().cross(w);

    return new THREE.Matrix4().makeBasis(u, v, w).setPosition(o);
}

/**
 * Obtains two matrices for transforming points
 * to/from world/plane-space coordinates
 * @param {THREE.Plane} plane
 * @returns {{toWorld: THREE.Matrix4, toPlane: THREE.Matrix4}}
 */
export const planeToMatrix4s = (plane) => {
    const toWorld = planeToMatrix4(plane);
    const toPlane = new Matrix4().getInverse(toWorld);
    return {
        toWorld,
        toPlane
    }
}

/**
 *
 * @param {THREE.Vector3} v 3D position in world space
 * @param {THREE.Matrix4} toPlane transformation matrix from reference to plane space
 * @returns {THREE.Vector2} 2D position on plane
 */
export const toVector2WithMatrix = (v, toPlane) => {
    const t = v.clone().applyMatrix4(toPlane);
    return new THREE.Vector2(t.x, t.y);
}

/**
 *
 * @param {THREE.Vector3[]} vs 3D positions in world space
 * @param {THREE.Matrix4} toPlane transformation from reference to plane space
 * @returns {THREE.Vector2[]} 3D positions on plane
 */
export const toVector2sWithMatrix = (vs, toPlane) => vs.map(v => toVector2WithMatrix(v, toPlane));

/**
 *
 * @param {THREE.Vector2} v planar point
 * @param {THREE.Matrix4} toWorld transformatin from plane to world
 * @returns
 */
export const toVector3WithMatrix = (v, toWorld) => new THREE.Vector3(v.x, v.y, 0).applyMatrix4(toWorld);
/**
 *
 * @param {THREE.Vector2[]} vs planar points
 * @param {THREE.Matrix4} toWorld transformation from plane to world
 * @returns
 */
export const toVector3sWithMatrix = (vs, toWorld) => vs.map(v => toVector3WithMatrix(v, toWorld));

/**
 *
 * @param {THREE.Vector2[] || THREE.Vector3[]} vv Points to compute the centroid for. Must not be 0 length
 * @returns {THREE.Vector2 || THREE.Vector3} Centroid of provided points
 */
export const getCentroidForPoints = vv => vv.reduce((p, c) => p.add(c), new Vector3()).divideScalar(vv.length);
export {
  planeFromPoints,
  kNearest,
  intersectLineSegments,
  intersectPlanes,
  interpolateArc,
  azElToCoord,
  headingToDirectionVector,
  degToRad,
  radToDeg,
  kUnitVector,
  jUnitVector,
  iUnitVector
};
