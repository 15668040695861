import { downloadAndArchiveForPublicAPI, browserDownload } from '../libs/Utilities';
import { viewshedTool } from '../Viewer';
import * as Q from 'q';



class CSVExportTool {
    constructor() {
        /**
         * @var {string};
         */
        this.horizonProfileUrl;

        /**
         * @var {string};
         */
        this.hourlyProductionUrl;
    }

    exportHorizonProfile(viewsheds) {
        var promises   = [];
        var elevations = [];
        var elHeader   = [];

        for (let pid = 0; pid < viewsheds.length; pid++) {
            for (let vid = 0; vid < viewsheds[pid].length; vid++) {
                let p = viewshedTool.getElevationBreakdown(viewsheds[pid][vid].viewshed).then((data) => {
                    if (elevations[pid] === undefined)
                        elevations[pid] = [];

                    elevations[pid][vid] = data;
                });

                if (elHeader[pid] === undefined)
                    elHeader[pid] = [];

                elHeader[pid].push(`"Elevation (0-90) Segment ${pid} Viewshed ${vid}"`);

                promises.push(p);
            }
        }

        return Q.all(promises).then(async () => {
            // combined csv
            let lines = [];
            let pids = [...Array(elevations.length).keys()];

            for (let row of this.getRows(elevations, pids))
                lines.push(row.join(','));

            let header = [];

            for (let plane of elHeader) {
                if (plane !== undefined)
                    header.push(...plane);
            }

            this.horizonProfileUrl = await downloadAndArchiveForPublicAPI({
                blob: new Blob([[ ...this.getHeader(header), ...lines ].join('\n')], {type: 'text/csv;charset=utf-8;'}),
                filename: 'horizonProfileCombined.csv',
                url: this.horizonProfileUrl
            });

            // csv per roof segment
            for (let pid = 0; pid < viewsheds.length; pid++) {
                if (viewsheds[pid].length === 0)
                    continue;

                let lines = [];

                for (let row of this.getRows(elevations, [pid]))
                    lines.push(row.join(','));

                let planeName = viewshedTool.getPlaneName(pid);

                browserDownload({
                    blob: new Blob([[ ...this.getHeader(elHeader[pid]), ...lines ].join('\n')], {type: 'text/csv;charset=utf-8;'} ),
                    filename: `horizonProfileSegment${planeName}.csv`
                });
            }
        });
    }
    getHeader(extra) {
        let header = [
            //Session Obstruction Elevations 1.0
            //Session Name:,"Blacquier roof 1"
            //Latitude:,42.3
            //Longitude:,-71.4
            //Mag Dec:,-14.4
            //Time Zone:,GMT-05:00
            //
            //"The average elevation for the session is the average elevation of all of the skylines. The maximum elevation for the session is the maximum elevation of all of the skylines.  The elevation given for each skyline represents the highest point a shade causing obstruction occurs at the given azimuth."
            //
            'begin data',
            [
                '"Compass Heading (0-360; North=0; East=90)"',
                '"Southerly Oriented Azimuth (-180 to +180; south=0; East=-90)"',
                '"Average Elevation (0-90)"',
                '"Maximum Elevation (0-90)"',
                ...extra
            ].join(','),
        ];

        return header;
    }

    getRows(elevations, pids) {
        let values = [];

        for (let az = 0; az < 360; az++) {
            let els = [];

            for (let pid of pids) {
                if (elevations[pid] === undefined)
                    continue;

                for (let vid = 0; vid < elevations[pid].length; vid++)
                    els.push(elevations[pid][vid][az]);
            }

            let avgEl = (els.length > 0) ?
                Math.round(els.reduce((a, b) => a + b) / els.length) :
                0;

            let maxEl = (els.length > 0) ?
                Math.max.apply(null, els) :
                0;

            values.push([ az, az - 180, avgEl, maxEl, ...els ]);
        }

        // 360 heading is the same as 0 heading
        values.push([ 360, 180, ...values[0].slice(2) ]);

        return values;
    }
}


export { CSVExportTool };
