import { camera, raycaster, mouseHandler, vMouse, scene, renderer } from '../Viewer.js';
import { SolarPanelModuleStatic } from '../elements/SolarPanelModule';
import { kNearest } from '../libs/Geometry';
import { SelectionHelper } from '../libs/SelectionHelper';
import { SelectionBox } from '../libs/SelectionBox';
import * as THREE from '../libs/three.module';
import * as d3 from 'd3';


class ModuleSpecsToolManualMode {
    /**
     * @param {string} name
     * @param {ModuleSpecsTool} parent
     */
    constructor(name, parent) {
        this.name = name;
        this.parent = parent;

        this.selectionBox = new SelectionBox(camera, scene);
        this.selectionHelper = new SelectionHelper(renderer, 'selectBox');

        this.snapPoints;
        this.refPoints;
        this.originalPosition;
        this.dragOffset;
        this.anchors;

        this.states = {
            NONE:        0,
            ADD:         1,
            COPY:        2,
            MOVE:        3,
            MOVING:      4,
            RECT_SELECT: 5
        };

        this.state = this.states.NONE;
    }


    stop() {
        if (this.anchors)
            this.anchors.forEach(a => scene.remove(a));

        this.state = this.states.NONE;
    }


    exit() {
        if (this.parent)
            this.parent.stop();
    }


    add() {
        let ma = this.parent.ctxModuleArray();

        if (ma) {
            let specs = this.parent.moduleSpecs[this.parent.planeId][0];

            if (!ma.currentModule) {
                let dim = ma.getEffectiveModuleDimensions({ specs });
                let m = ma.initModule(dim, specs);

                m.visible = false;

                ma.addSpecs(m, ma.specsDiff(specs));
                ma.group.add(m);
                ma.currentModule = m;
            }

            this.createSnapPoints();

            this.state = this.states.ADD;

            mouseHandler.clearCursor('ms');
        }
    }


    move() {
        if (this.parent.selectedModules.length === 0)
            return;

        this.refPoints = [];

        let ma = this.parent.ctxModuleArray();

        this.parent.selectedModules.forEach((m) => {
            m.refPointsMode(true);

            if (m.refCubes && m.refCubes.length > 0)
                this.refPoints.push(...m.refCubes);

            ma.groupMove.add(m);
        });

        this.state = this.states.MOVE;
    }


    selectModule(module) {
        if (module.toggleSelect()) {
            this.parent.selectedModules.push(module);
        } else {
            let idx = this.parent.selectedModules.indexOf(module);

            if (idx > -1)
                this.parent.selectedModules.splice(idx, 1);
        }
    }


    copy() {
        if (this.parent.selectedModules.length === 0)
            return;

        let ma = this.parent.ctxModuleArray();
        this.moduleCopies = [];

        this.parent.selectedModules.forEach((m) => {
            let specs = ma.getIndividualSpecs(m);
            let dim = ma.getEffectiveModuleDimensions({ specs });
            let copy = ma.initModule(dim, specs);

            copy.visible = true;
            copy.position.copy(m.position);

            ma.addSpecs(copy, ma.specsDiff(specs));
            ma.group.add(copy);

            this.moduleCopies.push(copy);
        });

        this.parent.selectedModules = this.moduleCopies;

        ma.updateModuleCount();
    }


    rectangleSelect() {
        this.state = this.states.RECT_SELECT;

        mouseHandler.setCursor('crosshair', 'ms', true);
    }


    createSnapPoints() {
        let ma = this.parent.ctxModuleArray();
        var vv = [];

        for (let m of ma.group.children) {
            let specs = ma.getIndividualSpecs(m);
            let portrait = (specs.orientation === 'portrait');

            let hOffset = portrait ? specs.hOffset : specs.vOffset;
            let vOffset = portrait ? specs.vOffset : specs.hOffset;

            vv.push(... m.getAnchorVertices(hOffset, vOffset));
        }

        // visualize snap points
        if (this.anchors)
            this.anchors.forEach(a => scene.remove(a));

        this.anchors = [];

        let g = new THREE.SphereBufferGeometry(0.01, 16, 16);
        let m = new THREE.MeshBasicMaterial({ color: 0x7fffa6 });

        vv.forEach((a) => {
            let mesh = new THREE.Mesh(g, m);
            mesh.position.copy(a);
            scene.add(mesh);
            this.anchors.push(mesh);
        });

        // build a quadtree to speed up lookup
        this.snapPoints = d3.geom.quadtree()(vv.map((v) => {
            let vL = ma.frame.worldToLocal(v.clone());
            let p = [ vL.x, vL.z ];

            // we'll display snap type in a tooltip
            p.anchorType = v.anchorType;

            return p;
        }));
    }


    nearestSnapPoint(ma, p, snapPoints) {
        // snap to closest anchor point
        let lPos = ma.frame.worldToLocal(p.clone()).setY(0);

        // scale snap distance with zoom by placing 2 vertices in front
        // of the camera and comparing their distance in world coords
        // with their distance in normalized device coordinates (-1, 1)
        let dir = camera.getWorldDirection();
        let up  = camera.up;

        let ho = new THREE.Object3D();

        ho.position.copy(
            camera.position.clone()
            .add(
                dir.clone().multiplyScalar(2)));

        let lv1 = ho.localToWorld(new THREE.Vector3(0, 0, 0));
        let lv2 = ho.localToWorld(up.clone().multiplyScalar(0.5));

        let lv1s = lv1.clone().project(camera);
        let lv2s = lv2.clone().project(camera);

        let best = {
            d: lv2.sub(lv1).length() / lv2s.sub(lv1s).length() * 0.02,
            p: null
        };

        return kNearest(lPos.x, lPos.z, best, snapPoints);
    }


    cancelMoving() {
        if (this.state === this.states.MOVING) {
            let ma = this.parent.ctxModuleArray();

            if (this.originalPosition) {
                ma.groupMove.position.copy(this.originalPosition);

                this.tooltip.hide();

                this.state = this.states.MOVE;
            }

            if (this.moduleCopies) {
                this.moduleCopies.forEach(m => ma.removeModule(m));
                this.moduleCopies = [];

                ma.updateModuleCount();
            }
        }
    }


    finalizeMove() {
        let ma = this.parent.ctxModuleArray();

        if (ma && ma.groupMove) {
            ma.groupMove.children.forEach((m) => m.refPointsMode(false));
            ma.group    .children.forEach((m) => m.refPointsMode(false));

            if (this.state === this.states.MOVE) {
                ma.updateModulePositions();
            }

            this.moduleCopies = [];
        }
    }


    rectSelectModules() {
        var ma = this.parent.ctxModuleArray();

        if (ma) {
            var uuids = ma.group.children.map(c => c.mesh.uuid);
            var selected = this.selectionBox.select(uuids);

            this.parent.selectedModules.forEach( m => { m.deselect(); })
            this.parent.selectedModules = [];

            for ( var i = 0; i < selected.length; i ++ ) {
                let module = selected[i].parent.parent.parent
                module.select();
                this.parent.selectedModules.push(module);
            }
        } else {
            // [tag:autoSegments]
            // auto segment selection


            // select yellow orbs at the vertex of each segment
            let targets = [];

            for (let pid = 0; pid < this.parent.outlineCtxs.length; pid++) {
                let c = this.parent.outlineCtxs[pid][0];

                for (let m of c.markersSegment)
                    targets.push(m);
            }

            var uuids = targets.map(t => t.uuid);
            var selected = this.selectionBox.select(uuids);



            // TODO: get this out the parent and into the auto segments mode
            this.parent.autoSegmentsSelected = [];

            for (let t of targets) {
                //this.parent.planes[t.parentCtx.pid].auto = true;

                t.parentCtx.container.visible = false;
            }

            for (let s of selected) {
                let pid = s.parentCtx.pid;

                //this.parent.planes[pid].auto = false;
                this.parent.autoSegmentsSelected.push(pid);

                s.parentCtx.container.visible = true;
            }




        }
    }


    mouseDown(event) {
        if (event.button === THREE.MOUSE.LEFT) {
            let ma = this.parent.ctxModuleArray();

            if (this.state === this.states.NONE && ma) {
                raycaster.setFromCamera(vMouse, camera);
                var xs = raycaster.intersectObjects(ma.group.children, true);

                if (xs.length > 0) {
                    var module = xs[0].object.parent.parent.parent;

                    if (module.toggleSelect()) {
                        this.parent.selectedModules.push(module);
                    } else {
                        let idx = this.parent.selectedModules.indexOf(module);

                        if (idx > -1)
                            this.parent.selectedModules.splice(idx, 1);
                    }

                    this.parent.propagateState();
                }
            } else if (this.state === this.states.RECT_SELECT) {
                /** @global vMouse */
                this.selectionBox.startPoint.set(vMouse.x, vMouse.y, 0.5);

                this.selectionHelper.isDown = true;
                this.selectionHelper.onSelectStart(event);
            }
        }
    }


    mouseUp(event) {
        if (this.state === this.states.ADD) {
            let ma = this.parent.ctxModuleArray();

            ma.finalizeModule();

            this.parent.tooltip.hide();
            this.parent.propagateState();
            this.parent.checkForModuleConflicts();

            this.state = this.states.NONE;

            this.stop();

        } else if (this.state === this.states.MOVE) {
            if (this.refPoints && this.refPoints.length > 0) {
                /** @global vMouse */
                raycaster.setFromCamera(vMouse, camera);
                var xs = raycaster.intersectObjects(this.refPoints);

                if (xs.length > 0) {
                    // project anchor point onto the moving plane
                    let o = xs[0].object;
                    let ma = this.parent.ctxModuleArray();
                    let n = this.parent.outlineCtx.fitPlane.normal.clone().normalize();
                    let h = ma.moduleSpecs.heightOffset;

                    let refWorld = o.parent.localToWorld(o.position.clone());

                    this.dragOffset = refWorld.clone()
                        .sub(ma.groupMove.position)
                        .sub(n.multiplyScalar(h));

                    this.originalPosition = ma.groupMove.position.clone();
                    this.createSnapPoints();

                    this.state = this.states.MOVING;

                    mouseHandler.setCursor('crosshair', 'ms', true);
                }
            }
        } else if (this.state === this.states.MOVING) {
            this.parent.tooltip.hide();

            let ma = this.parent.ctxModuleArray();

            ma.updateModulePositions();

            this.parent.selectedModules.forEach((m) => ma.groupMove.add(m));

            this.state = this.states.MOVE;

            this.finalizeMove();
            this.stop();

            this.parent.checkForModuleConflicts();
            this.parent.selectNone();
            this.parent.propagateState();

            mouseHandler.clearCursor('ms');
        } else if (this.state === this.states.RECT_SELECT) {
            /** @global vMouse */
            this.selectionBox.endPoint.set(vMouse.x, vMouse.y, 0.5);

            this.selectionHelper.isDown = false;
            this.selectionHelper.onSelectOver(event);

            this.rectSelectModules();

            mouseHandler.clearCursor('ms');

            this.stop();










            // [tag:autoSegments]
            // TODO: don't manipulate parent's statuses directly?
            this.parent.statusAutoSegments = this.parent.autoSegmentsStatuses.SELECTED;
            this.parent.propagateState();
        }
    }


    mouseMove(mouse, panning, event) {
        if (this.state === this.states.ADD) {
            let ma = this.parent.ctxModuleArray();

            raycaster.setFromCamera(mouse, camera);
            var xs = raycaster.intersectObject(ma.movingPlane, true);

            if (xs.length > 0) {
                this.parent.outlineCtx.placement = 'manual';

                let p = xs[0].point.clone();
                let m = ma.currentModule;

                let ms = this.parent.moduleSpecs[this.parent.planeId][0];
                let tooltip = this.parent.tooltip;

                let near = this.parent.outlineCtx.snap.modules ?
                    this.nearestSnapPoint(ma, p, this.snapPoints) :
                    {};

                if (near.p) {
                    let s = new THREE.Vector3(near.p[0], ms.heightOffset, near.p[1]);
                    let sw = ma.frame.localToWorld(s.clone());

                    m.position.copy(s);

                    tooltip.setText(near.p.anchorType);
                    tooltip.setPosition(sw.clone().project(camera));
                    tooltip.show();
                } else {
                    let pL = ma.frame.worldToLocal(p.clone());
                    let s = new THREE.Vector3(pL.x, ms.heightOffset, pL.z);

                    m.position.copy(s);

                    tooltip.hide();
                }

                m.visible = true;
                m.updateMatrixWorld();
            }
        } else if (this.state === this.states.MOVE) {
            if (this.refPoints && this.refPoints.length > 0) {
                // reset hover color
                this.refPoints.forEach((a) => {
                    a.material = SolarPanelModuleStatic.materialSnapCubeNormal;
                });

                raycaster.setFromCamera(mouse, camera);
                var xs = raycaster.intersectObjects(this.refPoints);

                let moveCursor = false;

                if (xs.length > 0) {
                    let c = xs[0].object;

                    c.material = SolarPanelModuleStatic.materialSnapCubeHover;

                    moveCursor = true;
                }

                mouseHandler.setCursor('move', 'ms', moveCursor);
            }
        } else if (this.state === this.states.MOVING) {
            let ma = this.parent.ctxModuleArray();

            raycaster.setFromCamera(mouse, camera);
            var xs = raycaster.intersectObject(ma.movingPlane, true);

            if (xs.length > 0) {
                this.parent.outlineCtx.placement = 'manual'; // TODO: do we need this?

                let p = xs[0].point.clone();
                let pos = p.clone().sub(this.dragOffset);

                let near = this.parent.outlineCtx.snap.modules ?
                    this.nearestSnapPoint(ma, p, this.snapPoints) :
                    {};

                let tooltip = this.parent.tooltip;

                if (near.p) {
                    let s = new THREE.Vector3(near.p[0], 0, near.p[1]);
                    let sw = ma.frame.localToWorld(s.clone());

                    // add the difference between reference and snap points to selected group position
                    pos.sub(p.clone().sub(sw));

                    tooltip.setText(near.p.anchorType);
                    tooltip.setPosition(sw.clone().project(camera));
                    tooltip.show();
                } else {
                    tooltip.hide();
                }

                ma.groupMove.position.copy(pos);
                ma.groupMove.updateMatrixWorld();
            }
        } else if (this.state === this.states.RECT_SELECT) {
            if (this.selectionHelper.isDown) {
                this.selectionHelper.onSelectMove(event);

                this.selectionBox.endPoint.set(mouse.x, mouse.y, 0.5);

                this.rectSelectModules();
            }
        }
    }
}

export { ModuleSpecsToolManualMode };
