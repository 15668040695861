import { DEFAULT_MAP_ZOOM, DEFAULT_LNG, DEFAULT_LAT, render } from '../Viewer.js';
import { calculateSunPositionSunCalc } from '../workers/Irradiation.js';
import { SunCalc } from '../workers/suncalc.js';
import { degToRad } from '../libs/Geometry';
import { TransformControls } from '../controls/TransformControls.js';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';
import { Box3, CameraHelper, Color, DirectionalLight, Group, ImageLoader, LoadingManager, Mesh, MOUSE, Object3D, MeshLambertMaterial, Texture, PlaneGeometry, Vector3 } from '../libs/three.module';




class ShadeTool {

    constructor(ambientLight) {
        this.latitude        = 0;
        this.longitude       = 0;
        this.mapZoom         = DEFAULT_MAP_ZOOM;
        this.azimuth         = 0;
        this.elevation       = 0;
        this.distance        = 100;
        this.UTCOffset       = -5;
        this.rotationDegrees = 0;

        this.ambientLight = ambientLight;
        this.ambientLightColor = this.ambientLight.color;

        this.sunLightIntensity = 0.8;
        this.sunLight = new DirectionalLight(0xffffff, this.sunLightIntensity);

        this.sunLight.castShadow = true;

        // minimize self-shadowing artifacts (moire or stripes)
        this.sunLight.shadow.bias = -0.002;

        this.sunLight.shadow.mapSize.width  = 4096;
        this.sunLight.shadow.mapSize.height = 4096;

        this.sunLight.intensity = 0;

        this.states = { NONE: 0, MOVE: 1 };
        this.state = this.states.NONE;
        this.point = new Vector3();

        this.mapTilePixels = 512;
        this.minMapZoom    = 7;
        this.maxMapZoom    = 22;

        this.lockMovement = false;

        this.name = 'shadeTool';
    }


    getUTCOffset      ()      { return this.UTCOffset;       }
    getTimezoneName   ()      { return this.timezoneName;    }
    getAzimuth        ()      { return this.azimuth;         }
    setAzimuth        (deg)   { this.azimuth = deg;          }
    getElevation      ()      { return this.elevation;       }
    setElevation      (deg)   { this.elevation = deg;        }
    getLatitude       ()      { return this.latitude;        }
    setLatitude       (deg)   { this.latitude = deg;         }
    getLongitude      ()      { return this.longitude;       }
    setLongitude      (deg)   { this.longitude = deg;        }
    setMapZoom        (zoom)  { this.mapZoom = zoom;         }
    getRotationDegrees()      { return this.rotationDegrees; }


    setRotationDegrees(degrees) {
        this.rotationDegrees = degrees;
        this.rotateObject.rotation.y = degToRad(-degrees);
    }


    setOpacity(value) {
        this.opacity = value;

        for (var i = 0; i < this.rotateObject.children[0].children.length; i++) {
            var mesh = this.rotateObject.children[0].children[i];

            this._setMaterialOpacity(mesh.material, value);
        }
    }


    _setMaterialOpacity(material, value) {
        if (Array.isArray(material)) {
            for (var i = 0; i < material.length; i++) {
                this._setMaterialOpacity(material[i], value);
            }
        } else if (material) {
            material.opacity = value;
            material.transparent = (value < 0.999);
        }
    }

    getOpacity() { return this.opacity; }

    enable() {
        if(this.enabled){
            return
        }

        this.enabled = true;

        this.ambientLight.color = new Color(0x909090);
        this.sunLight.intensity = this.sunLightIntensity;

        if (this.plane)
            this.plane.visible = true;

        this.calculateAzimuthElevation();
        this.moveLight();
    }


    disable() {
        this.enabled = false;

        this.ambientLight.color = this.ambientLightColor;
        this.sunLight.intensity = 0;
        this.lightHelper.visible = false;
        //this.setOpacity(1.0);
    }


    addToScene(scene) {
        scene.add(this.sunLight);

        this.scene = scene;
    }

    setCamera(camera) { this.camera = camera; }

    render() {
        if(this.zEnabled) { this.controls.update(); }
    }


    enableAdminZ(enable, domElement) {
        if(!this.controls) {
            this.controls = new TransformControls(this.camera, domElement);
            this.controls.addEventListener('change', render);
            this.controls.setMode('translate');
            this.controls.setSize(1);
            // hide x
            this.controls.children[0].children[0].children[0].visible = false;
            this.controls.children[0].children[0].children[1].visible = false;
            // hide z
            this.controls.children[0].children[0].children[4].visible = false;
            this.controls.children[0].children[0].children[5].visible = false;
            // hide planes/anchor
            this.controls.children[0].children[0].children[6].visible = false;
            this.controls.children[0].children[0].children[7].visible = false;
            this.controls.children[0].children[0].children[8].visible = false;
            this.controls.children[0].children[0].children[9].visible = false;
            this.scene.add(this.controls);
        }

        if(enable) {
            this.zEnabled = true
            this.controls.attach(this.moveObject);
            this.controls.setMode('translate');
            this.state = this.states.MOVE;
        } else {
            this.zEnabled = false
            this.controls.detach(this.moveObject);
            this.state = this.states.NONE;
        }
    }


    setRaycaster   (rc)       { this.raycaster = rc; }
    setUpdateGui   (callback) { this.updateGui = callback; }
    setLockMovement(value)    { this.lockMovement = value; }
    getLockMovement()         { return this.lockMovement; }


    calculatePosition(azimuth, elevation) {
        var d = this.distance * Math.cos(degToRad(elevation));

        var x =             d * Math.cos(degToRad(90 + azimuth));
        var y = this.distance * Math.sin(degToRad(elevation));
        var z =             d * Math.sin(degToRad(90 + azimuth));

        return new Vector3(x, y, z);
    }


    moveLight() {
        this.sunLight.position.copy(this.calculatePosition(this.azimuth, this.elevation));

        if (this.enabled) {
            // turn off light source at sunrise and turn off at sunset
            var utc = Date.UTC(this.year, this.month-1, this.day+1);
            var times = SunCalc.getTimes(new Date(utc), this.latitude, this.longitude);

            var riseH = times.sunrise.getUTCHours();
            var riseM = times.sunrise.getUTCMinutes();

            var setH = times.sunset.getUTCHours();
            var setM = times.sunset.getUTCMinutes();

            var sunrise = (24 + riseH + this.UTCOffset) % 24 * 60 + riseM;
            var  sunset = (24 +  setH + this.UTCOffset) % 24 * 60 +  setM;

            var current = this.hours * 60 + this.minutes;

            if (sunrise < current && current < sunset) {
                this.sunLight.intensity = this.sunLightIntensity;
            } else {
                this.sunLight.intensity = 0;
            }
        }
    }


    showLightHelper(show) {
        this.lightHelper.visible = show;
    }

    getTime() { return this.time; }

    setTime(minutes) {
        this.time = minutes;

        this.hours   = Math.floor(minutes / 60);
        this.minutes = minutes % 60;
        this.seconds = 0;

        this.setTimezone();
    }

    getDate() { return this.date; }

    setDate(date) {
        this.date = date;

        this.year  = date.getFullYear();
        this.month = date.getMonth() + 1;
        this.day   = date.getDate();

        this.setTimezone();
    }

    clampAzimuth(azimuth) {
        return Math.max(0, Math.min(azimuth, 359));
    }

    clampElevation(elevation) {
        return Math.max(0, Math.min(elevation, 90));
    }


    calculateAzimuthElevation() {
        //console.log('Calculating Sun\'s position', this.latitude, this.longitude, this.year,
        //        this.month, this.day, this.hours, this.minutes, this.seconds, this.UTCOffset);

        var result = calculateSunPositionSunCalc(this.latitude, this.longitude, this.year,
                this.month - 1, this.day, this.hours, this.minutes, this.seconds, this.UTCOffset);

        //console.log('Sun azimuth and elevation', result);

        this.azimuth   = this.clampAzimuth(result.azimuth);
        this.elevation = this.clampElevation(result.elevation);
    }


    placeOnMap() {
        if (this.plane)
            this.scene.remove(this.plane);

        this.groundSize = this.mapTilePixels * this.metersPerPixel(this.latitude, this.mapZoom);

        var geometry = new PlaneGeometry(this.groundSize, this.groundSize, 32);
        var material = new MeshLambertMaterial({ color: 0xffffff });

        this.plane = new Mesh(geometry, material);
        this.plane.lookAt(new Vector3(0, 1, 0));
        this.plane.rotateOnAxis(new Vector3(0, 0, 1), Math.PI);

        this.scene.add(this.plane);

        this.mapUrl = 'https://maps.googleapis.com/maps/api/staticmap?maptype=satellite' +
            '&center=' + this.latitude + ',' + this.longitude +
            '&zoom=' + this.mapZoom +
            '&size=' + this.mapTilePixels + 'x' + this.mapTilePixels +
            '&scale=2' +
            '&key=AIzaSyCIxJV6mYZCYE8U4IYI5_JNoaQtRdp3NCE';

        var self = this;

        var loader = new ImageLoader(new LoadingManager());
        loader.setCrossOrigin('anonymous'); // required for loading from an external domain
        loader.load(this.mapUrl, function (image) {
            self.texture             = new Texture();
            self.texture.image       = image;
            self.texture.needsUpdate = true;

            self.plane.material.map         = self.texture;
            self.plane.material.needsUpdate = true;

            var canvas = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');

            canvas.width  = image.width;
            canvas.height = image.height;

            var ctx = canvas.getContext('2d');

            ctx.drawImage(image, 0, 0, image.width, image.height);

            self.imgDataUrl = canvas.toDataURL('image/jpeg', 0.9);
        });
    }


    getImgDataUrl() {
        return this.imgDataUrl
    }


    _metersPerPixel(lat, zoom) {
        return 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
    }


    getMapParams() {
        return {
            lat:  this.getLatitude(),
            lng:  this.getLongitude(),
            zoom: this.mapZoom
        };
    }


    setObject(o) {
        // calculate the object's bounding volume to figure out the shadow camera dimensions
        var bbox = new Box3();
        if( o.type === Group) {
          for(let child of o) {
            this.setObject(o);
          }
        } else {
          bbox.expandByObject(o);
        }
        var l = bbox.max.clone().sub(bbox.min).length();

        this.distance = l;

        this.sunLight.shadow.camera.near   =  10;
        this.sunLight.shadow.camera.far    =  l * 1.5;
        this.sunLight.shadow.camera.right  =  l/2;
        this.sunLight.shadow.camera.left   = -l/2;
        this.sunLight.shadow.camera.top    =  l/2;
        this.sunLight.shadow.camera.bottom = -l/2;

        this.sunLight.shadow.camera.updateProjectionMatrix();

        this.lightHelper = new CameraHelper(this.sunLight.shadow.camera);
        this.lightHelper.visible = false;
        this.scene.add(this.lightHelper);

        // wrap the original object
        this.rotateObject = new Object3D();
        this.rotateObject.add(o);

        this.moveObject = new Object3D();
        this.moveObject.add(this.rotateObject);
        this.moveObject.add(this.sunLight);

        this.sunLight.target = this.rotateObject; // point light at the object

        return this.moveObject; // return wrapped object back to the caller
    }


    mouseDown(event, mouse, panning) {
        if (!this.enabled || event.button !== MOUSE.LEFT || panning || this.lockMovement)
            return;

        if (this.plane) {
            this.camera.setRaycasterRay(mouse, this.raycaster);

            if (this.raycaster.intersectObject(this.rotateObject.children[0].children[0]).length === 0)
                return; // move only if the user clicked on the model

            var intersects = this.raycaster.intersectObject(this.plane);
            if (intersects.length === 0)
                return;

            this.point.copy(intersects[0].point);
            this.state = this.states.MOVE;
        }
    }


    mouseUp(event, mouse, panning) {
        if (!this.enabled || event.button !== MOUSE.LEFT || panning || this.lockMovement)
            return;

        this.state = this.states.NONE;
    }


    mouseMove(mouse, panning) {
        if (!this.enabled || panning || this.lockMovement)
            return;

        if (this.state === this.states.MOVE) {
            this.camera.setRaycasterRay(mouse, this.raycaster);

            var intersects = this.raycaster.intersectObject(this.plane);
            if (intersects.length === 0)
                return;

            var dx = intersects[0].point.x - this.point.x;
            var dz = intersects[0].point.z - this.point.z;
            var dy = intersects[0].point.y - this.point.y;

            this.moveObject.position.x += dx;
            this.moveObject.position.z += dz;
            this.moveObject.position.y += dy;

            this.point.copy(intersects[0].point);
        }
    }

    handleKeyboard(keyboard) { }

    getSaveData() {
        return {
            lat:              this.getLatitude(),
            lng:              this.getLongitude(),
            mapZoom:          this.mapZoom,
            mapUrl:           this.mapUrl,
            UTCOffset:        this.UTCOffset,
            rotationDegrees:  this.getRotationDegrees(),
            position:         { x: this.moveObject.position.x,
                                y: this.moveObject.position.y,
                                z: this.moveObject.position.z},
            lockMovement:     this.lockMovement
        };
    }


    isLocationSet(data) {
        return (data.lat !== DEFAULT_LAT ||
                data.lng !== DEFAULT_LNG ||
                data.mapZoom !== DEFAULT_MAP_ZOOM);
    }


    restoreSaveData(data) {
        this.calculateAzimuthElevation();
        this.moveLight();
        this.setLocation(data);

        if (this.updateGui)
            this.updateGui();
    }


    setKML(lat, lng, heading, dim) {
        this.setLatitude(lat);
        this.setLongitude(lng);
        this.setRotationDegrees(heading);
        this.setMapZoom(this.calculateMapZoom(lat, dim));

        this.placeOnMap();
        this.updateGui();
    }


    setTimezone() {
        if (this.year === undefined)
            return;

        let zoneName = tzlookup(this.latitude, this.longitude);

        let month   = ('' + this.month    ).padStart(2, '0');
        let day     = ('' + (this.day + 1)).padStart(2, '0');
        let hours   = ('' + this.hours    ).padStart(2, '0');
        let minutes = ('' + this.minutes  ).padStart(2, '0');

        let sDate = `${this.year}-${month}-${day}T${hours}:${minutes}:00`;
        let zone = moment.tz(sDate, zoneName);

        this.timezoneName = zoneName;
        this.UTCOffset = zone.utcOffset()/60;
    }


    setLocation(data, bbox) {
        if (data && this.isLocationSet(data)) {
            this.setLatitude(data.lat);
            this.setLongitude(data.lng);
            this.setMapZoom(data.mapZoom);
            this.setRotationDegrees(data.rotationDegrees);
            this.setLockMovement(data.lockMovement === true);

            this.moveObject.position.x = data.position.x;
            this.moveObject.position.z = data.position.z;

            if(data.position.y) {
                this.moveObject.position.y = data.position.y;
            }
        } else if (bbox) {
            this.setLatitude( bbox.center.lat);
            this.setLongitude(bbox.center.lng);

            var dim = Math.max(bbox.width, bbox.height);
            this.setMapZoom(this.calculateMapZoom(bbox.center.lat, dim));

            // 180 degrees seems to work for most agisoft models
            this.setRotationDegrees(180);
            this.setLockMovement(true);
        } else { // location not saved, no bounding box
            return false;
        }

        // without calls to updateMatrixWorld() here, restoring positions of ground
        // mounted solar arrays using raytracing is not working properly
        this.rotateObject.updateMatrixWorld();
        this.moveObject  .updateMatrixWorld();

        this.placeOnMap();

        this.setTimezone();
        this.calculateAzimuthElevation();
        this.moveLight();

        if (this.updateGui)
            this.updateGui();

        return true;
    }


    calculateMapZoom(lat, dim) {
        for (var zoom = this.maxMapZoom; zoom >= this.minMapZoom; zoom--) {
            if (this.mapTilePixels * this.metersPerPixel(lat, zoom) >= dim * 2)
                return zoom;
        }

        return this.minMapZoom;
    }

    metersPerPixel(lat, zoom) {
        return 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom);
    }
}


export { ShadeTool };
