import { disableUntil, getMouse, prepareDownload, toggleEnable } from './Gui.js';
import { emitEvent, viewshedTool, moduleSpecsTool, csvExportTool, dxfExportTool, project, prompts, currentDesign } from '../Viewer.js';
import { formatK, browserDownloadUrl, browserDownload, capitalize } from '../libs/Utilities.js';
import { Format } from './Format.js';
import { Net } from '../libs/Net.js';
import tippy from 'tippy.js';
import { takeScreenshot } from '../tools/ScreenshotTool.js';
import { getShadeDataCsv } from '../tools/CSVShadeDataTool.js';

import * as html from '../../templates/GuiExports/ShadeFiles.hbs';

class ExportShadeFilesGUI {
    /**
     * @param {object} options
     * @param {import('./GuiStatusIndicator').StatusIndicatorGui} options.statusIndicator
     */
    constructor(options) {
        this.options = options;
        this.name = 'Shade Files';
        this.selector = '.shade-exports';
        this.statusIndicator = options.statusIndicator;
        this.html = html;
    }

    /* @todo: this is copy-pasta from GuiExportCAD, DRY this */
    getPrepareDownloadCbs(exportName) {
        return {
            onStart: () => {
                this.statusIndicator.pushStatus(`Downloading ${exportName}...`);
                this.statusIndicator.setIndeterminate();
            },
            onError: (err) => {
                this.statusIndicator.pushError(
                    new Error(`An error occurred when downloading ${exportName}`)
                );
                prompts.info(
                    `<p>An error occurred when downloading the ${exportName}.</p>
                     <p>Please contact support for assistance.</p>`
                );
                console.error(err);
            },
            onSuccess: () => {
                this.statusIndicator.pushStatus(`${capitalize(exportName)} created successfully`);
                this.statusIndicator.deferClearProgress(1000);
            },
            onSettled: () => this.statusIndicator.deferClearProgress()
        };
    }

    prepareDownloadFactory(selector, type, download) {
        return () => {
            const { onStart, onSuccess, onError, onSettled } = this.getPrepareDownloadCbs(type);
            prepareDownload(selector, onStart, download, onSuccess, onError, onSettled);
        }
    }

    onDownloadHorizonProfileCsv = this.prepareDownloadFactory(
        'button.horizon-profile-csv',
        'horizon profile CSV',
        () => csvExportTool.exportHorizonProfile(viewshedTool.viewsheds)
    );

    onDownloadShadeDataCsv = this.prepareDownloadFactory(
        'button.shade-data-csv',
        'shade data CSV',
        async () => {
            const content = await getShadeDataCsv();
            browserDownload({
                blob: new Blob([content], {type: 'text/csv'}),
                filename: 'AverageSolarAccess.csv'
            });
        }
    );

    async pollShadeReport(pollingUrl, resolve, reject) {
        const response = await Net.pollReport(pollingUrl);
        if(response.status === 'pending') {
            setTimeout( () => this.pollShadeReport(pollingUrl, resolve, reject), 2000 );
            return;
        }

        if(!response.url) {
            reject('Error: something went wrong while generating the report');
        }

        resolve(response.url);
    }

    getShadeReportUrlFromPollingUrl(pollingUrl) {
        return new Promise(
            (resolve, reject) => { this.pollShadeReport(pollingUrl, resolve, reject); }
        );
    }

    onDownloadShadeReportPdf = this.prepareDownloadFactory(
        'button.shade-report',
        'shade report',
        async () => {
            const response = await Net.requestReport(project.id, currentDesign.id);
            let pollingUrl = undefined;

            if(response['polling_url'] && location.host !== 'localhost:8008') {
                pollingUrl = response.polling_url;
            } else {
                pollingUrl = await Net.getReportPollingUrlFromTaskId(response.task_id);
            }
            dxfExportTool.shadeReportUrl = await this.getShadeReportUrlFromPollingUrl(pollingUrl);
            browserDownloadUrl(dxfExportTool.shadeReportUrl, 'shade_report.pdf');
        }
    );

    async onTakeScreenshot() {
        try {
            await disableUntil(
                async () => {
                    this.statusIndicator.setIndeterminate()
                        .pushStatus('Taking screenshot...');
                    const screenshot = await takeScreenshot();

                    if (undefined === moduleSpecsTool.planeId) {
                        viewshedTool.setCoverImage(screenshot);
                    } else {
                        viewshedTool.setImage(moduleSpecsTool.planeId, screenshot);
                    }

                    this.statusIndicator.deferClearProgress(1000)
                        .pushStatus('Screenshot taken');
                    this.renderState();
                }
            )($('#banner-img-screenshot'), 'Please wait...');
        } catch (err) {
            this.statusIndicator.pushError(
                new Error('An error occurred while taking screenshot')
            ).deferClearProgress();
            console.error(err);
        }
    }

    onAnnotate() {
        var adjustCanvasSize = function () {
            var canvas = document.getElementById('plane-photo');

            canvas.width  = dialog.width();
            canvas.height = dialog.height() - $('.actions', dialog).outerHeight(true);
        };

        var dialogHeight = $(document).height() * .825;

        var dialog = $('#dialog-annotation').dialog({
            dialogClass: 'dialog-annotation',
            width:       dialogHeight * 1.33, // 4:3
            height:      dialogHeight,
            autoOpen:    false,
            closeOnEscape: true,
            modal:      true,
            position: {
                my: 'center',
                at: 'center',
                of: window
            },
            resize: (event, ui) => {
                adjustCanvasSize();
                viewshedTool.cmd('annotations.redraw');
            },
            resizeStop: (event, ui) => {
                adjustCanvasSize();
                viewshedTool.cmd('annotations.redraw');
            },
            open: (event, ui) => {
                adjustCanvasSize();
                $('.ui-widget-overlay').on('click',function() {
                    $('#dialog-annotation').dialog('close');
                });
            },
            close: (event, ui) => {
                viewshedTool.cmd('annotations.close');
            }
        });

        dialog.dialog('open');

        var img = new Image;
        img.onload = () => {
            var canvas = document.getElementById('plane-photo');
            let a = viewshedTool.annotations[moduleSpecsTool.planeId];

            a.setCanvas(canvas);
            a.setImage(img);
            a.redraw();
        };

        img.src = viewshedTool.planes[moduleSpecsTool.planeId].image;
    }

    /**
     * @param {number} pid
     */
     setSelectedSegment(pid) {
        if(this.selectedPlane === pid) {
            return;
        }

        this.selectedPlane = pid;
        viewshedTool.cmd('switchPlane', pid);
        moduleSpecsTool.cmd('switchPlane', pid);

        this.options.selectSegmentOutlines(pid);
        this.renderState();
    }

    init() {
        let self = this;
        $('body').on('click', '.check-row', function () {
            const pid = parseInt($(this).attr('data-pid'));
            if( !Number.isNaN(pid) ) {
                self.setSelectedSegment(pid);
            }
        });

        $('body').on('click', 'button.shade-report', () => { this.onDownloadShadeReportPdf(); } );
        $('body').on('click', 'button.horizon-profile-csv', () => { this.onDownloadHorizonProfileCsv(); });
        $('body').on('click', 'button.shade-data-csv', () => { this.onDownloadShadeDataCsv(); } );
        $('body').on('click', '#banner-img-screenshot', () => { this.onTakeScreenshot(); });
        $('body').on('click', 'button.annotate', () => { this.onAnnotate() } );

        $('#plane-photo')
            .bind('contextmenu', function (e) { e.preventDefault(); }) // disable the context menu
            .bind('mouseup',     function (e) { viewshedTool.cmd('annotations.mouseUp',    { mouse: getMouse(e, $(this)), button: e.button }); })
            .bind('mousedown',   function (e) { viewshedTool.cmd('annotations.mouseDown',  { mouse: getMouse(e, $(this)), button: e.button }); })
            .bind('mouseenter',  function (e) { viewshedTool.cmd('annotations.mouseEnter', { mouse: getMouse(e, $(this)) }); })
            .bind('mousemove',   function (e) {
                var mouse = getMouse(e, $(this));

                viewshedTool.cmd('annotations.mouseMove', {
                    mouse,
                    callback: (handle) => {
                        if (handle && (handle.bottom || handle.top)) {
                            $('#plane-photo').css('cursor', 'grab'); // move to render state or use self.cmd to do this as you shouldn't modify DOM from init
                        } else {
                            $('#plane-photo').css('cursor', 'default'); // move to render state or use self.cmd to do this as you shouldn't modify DOM from init
                        }
                }
            });
        });

        $('body').on('click', '.viewshed-info .add', function () {
            const id = parseInt($(this).attr('data-id'));
            viewshedTool.cmd('annotations.add', id);
        });

        $('body').on('click', '.viewshed-info .a-remove', function () {
            const id = parseInt($(this).attr('data-id'));
            viewshedTool.cmd('annotations.remove', id);
        });

        $('body').on('click', '#dialog-annotation .actions .clear', function () {
            viewshedTool.cmd('annotations.clear');
        });
    }

    renderState() {
        const self = this;
        const pid = moduleSpecsTool.planeId;
        const planes = viewshedTool.planes;
        const plane = planes[pid];
        let viewsheds = viewshedTool.viewsheds;
        const numViewsheds = viewshedTool.getViewshedLength();
        const hasViewsheds = numViewsheds > 0;
        let arrays = moduleSpecsTool.getState();

        if (plane) { // this should be when an outline has been drawn – figure out way to distinguish this
            const crop  = viewshedTool.getState().crops[pid];
            const imgPos = crop ? Math.round(crop.y / crop.d * 1000) / 10 : 50;

            let planeImg = plane.thumb ?
                `<div class="banner-img" style="background-image: url(${plane.image}); background-position: 50% ${imgPos}%;"></div>`
                :
                `<div class="placeholder banner-img" disabled>
                    <img src="styles/images/icons/screenshot-icon.svg" alt="">
                    <span>Please click Take Screenshot to use as banner image</span>
                </div>`
                ;
            let annotationsSVG = plane.svg ?
                `<div class="banner-img banner-annotations" style="background-image: url(${plane.svg}); background-position: 50% ${imgPos}%;"></div>`
                :
                '';

            emitEvent('status', { message: 'Please click Take Screenshot to use as banner image' });

            $('.pane-container .img-container').html(planeImg + annotationsSVG);

            toggleEnable($('#banner-img-screenshot'))(plane.thumb);

            toggleEnable($('button.horizon-profile-csv'))(hasViewsheds);
            toggleEnable($('button.shade-data-csv'))(hasViewsheds);
            toggleEnable($('button.shade-report'))(hasViewsheds);
            toggleEnable('.sy.annotate', plane.thumb != undefined);

            if (!hasViewsheds) {
                const tip = "Please add viewsheds to download shade files";
                tippy('.shade-report-tooltip, .horizon-profile-tooltip, .shade-data-csv-tooltip', { content: tip });
            }
        } else {
            $('.alert-box').removeClass('hidden');
            $('.pane-container .img-container').html(`
                <div class="placeholder banner-img">
                    <img src="styles/images/icons/screenshot-icon.svg" alt="">
                    <span>Please add outline before uploading banner image</span>
                </div>`
            )
            toggleEnable($('#banner-img-screenshot'))(false);
            toggleEnable($('button.horizon-profile-csv'))(false);
            toggleEnable($('button.shade-data-csv'))(false);
            toggleEnable($('button.shade-report'))(false);
            toggleEnable($('.sy.annotate'))(false);
            const tip = "Please add roof segments and viewsheds to download shade files";
            tippy('.shade-report-tooltip, .horizon-profile-tooltip, .shade-data-csv-tooltip', { content: tip });
            emitEvent('status', { message: 'Add a new segment and then create an outline to get started' });
        }

        if (viewshedTool.planes.length > 0) { // segment list
            let segments = ``;
            let array = moduleSpecsTool.getState();
            for (let i = 0; i < arrays.planes.length; i++) {
                let qty = 0;
                let systemSize = '0 kW';

                if (arrays.arrayInfo[i] !== undefined && arrays.arrayInfo[i][0]) {
                    qty = formatK(arrays.arrayInfo[i][0].qty);
                    systemSize = Format.wattsAsKilowatts(arrays.arrayInfo[i][0].wattage);
                }

                segments += `<div class="check-row segment-${i}" data-pid=${i}>
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                <span>${array.planes[i].name}&nbsp|&nbsp${systemSize}&nbsp|&nbsp${qty} Modules</span>
                                <span class="image-taken"></span>
                            </div>`;
            }

            $('.segment-list').html(segments);
            $('.segment-list-key').removeClass('hidden');

            for (let vpid = 0; vpid < viewsheds.length; vpid++) {
                if (viewsheds[vpid].length > 0) {  // if at least one viewshed has been added to the plane, checkmark should be green
                    $(`.segment-${vpid} .fa-check-circle`).css('color', '#27E2A4');
                }

                if (viewshedTool.planes[vpid].thumb) { // show camera icon if thumbnail exists
                    $(`.segment-${vpid} .image-taken`).html('&nbsp <i class="fa fa-camera" aria-hidden="true"></i>');
                }
            }

            $(`.segment-${pid}`).addClass('selected-segment'); // setting selected segment to last segment selected

            const vPlane = viewshedTool.planes[pid];

            if (vPlane) {
                toggleEnable($('.sy.annotate'))(vPlane.thumb !== undefined);

                if (vPlane.thumb) {
                    const crop = viewshedTool.getState().crops[pid];

                    $('.pane-container .img-container').html(`<div class="banner-img" style="background-image: url(${vPlane.image}); background-position: 50% ${crop ? Math.round(crop.y / crop.d * 1000) / 10 : 50}%;"></div>`);
                } else {
                    $('.pane-container .img-container').html(`
                        <div class="placeholder banner-img">
                            <img src="styles/images/icons/screenshot-icon.svg" alt="">
                            <span>Please click Take Screenshot to use as banner image</span>
                        </div>`
                    )
                }
            }

            tippy('.shade-report-banner-image .qmark-info', {
                animation:   'scale',
                inertia:     true,
                content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
                allowHTML:   true,
                interactive: true
            });
        }
    }

}

export {ExportShadeFilesGUI}
