function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split('&');

    for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split('=');
        params[tmparr[0]] = tmparr[1];
    }

    return params;
}

export function getSearchParameters() {
    var prmstr = window.location.search.substr(1);
    return (prmstr != null && prmstr != '') ? transformToAssocArray(prmstr) : {};
}

export const Config = (
    () => {
        if (process.env.USE_DOTENV) {
            return Object.freeze({
                envType: process.env.ENV_TYPE,
                baseURL: process.env.PORTAL_BE_URL,
                shareURL: process.env.SHARE_URL,
                assetsURL: process.env.WEATHER_DATA_URL,
                portalURL: process.env.PORTAL_FE_URL,
                decimatorURL: process.env.DECIMATOR_URL,
                heapAnalyticsId: process.env.HEAP_ANALYTICS_ID,
            });
        }

        const cfg = {};

        if (location.host === 'localhost:8008') {
            cfg.envType = 'development';

            cfg.baseURL = 'http://localhost:3000';
            cfg.portalURL = 'http://localhost:3001';
            cfg.assetsURL = 'https://scfly-dev-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'http://localhost:3003/submit-share-design';

            cfg.heapAnalyticsId = '1088303723';
            var urlParams = getSearchParameters();

            if (urlParams.env) {
                cfg.decimatorURL = 'https://scanifly3d.scanifly.com:8444';
            } else {
                cfg.decimatorURL = '//localhost:5000';
            }
        } else if (location.host === 'scanifly3d-demo-beta.scanifly.com'
            || location.href.indexOf('beta.cloud-scanifly.com') !== -1) {
            cfg.envType = 'beta';

            cfg.baseURL = 'https://api.portal-beta.scanifly.com';
            cfg.portalURL = 'https://portal-beta.scanifly.com';
            cfg.decimatorURL = 'https://decimator.staging.cloud-scanifly.com';
            cfg.assetsURL = 'https://scfly-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'https://share.portal-beta.scanifly.com/submit-share-design';
            cfg.heapAnalyticsId = '2124395367';
        } else if (location.href.indexOf('scanifly3d.cloud-scanifly.com') !== -1) {
            cfg.envType = 'review-app';

            cfg.baseURL = 'https://api.portal-beta.scanifly.com';
            cfg.portalURL = 'https://portal-beta.scanifly.com';
            cfg.decimatorURL = 'https://decimator.staging.cloud-scanifly.com';
            cfg.assetsURL = 'https://scfly-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'https://share.scanifly.com/submit-share-design';
            cfg.heapAnalyticsId = '2124395367';
        } else if (location.host === 'scanifly3d.testing.cloud-scanifly.com') {
            cfg.envType = 'testing';

            cfg.baseURL = 'https://api.portal-testing.cloud-scanifly.com';
            cfg.portalURL = 'https://portal-testing.cloud-scanifly.com';
            cfg.decimatorURL = 'https://decimator.dev.cloud-scanifly.com';
            cfg.assetsURL = 'https://scfly-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'https://share.scanifly.com/submit-share-design';
            cfg.heapAnalyticsId = '2124395367';
        } else if (location.host === 'scanifly3d-v2.scanifly.com') {
            cfg.envType = 'portal';

            cfg.baseURL = 'https://api.portal.scanifly.com';
            cfg.portalURL = 'https://portal.scanifly.com';
            cfg.decimatorURL = 'https://scanifly3d-portal.scanifly.com:8443';
            cfg.assetsURL = 'https://scfly-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'https://share.scanifly.com/submit-share-design';
            cfg.heapAnalyticsId = '3954962027';
        } else {
            cfg.envType = 'portal';

            cfg.baseURL = 'https://api.portal.scanifly.com';
            cfg.portalURL = 'https://portal.scanifly.com';
            cfg.decimatorURL = 'https://scanifly3d-portal.scanifly.com:8443';
            cfg.assetsURL = 'https://scfly-assets.s3.amazonaws.com/weather_data';
            cfg.shareURL = 'https://share.scanifly.com/submit-share-design';
            cfg.heapAnalyticsId = '3954962027';
        }

        // userpilot filters by domain and doesn't need separate ids for different envs
        cfg.userpilotToken = 'NX-2b5a125b';

        return Object.freeze(cfg);
    }
)();
