

/**
 * A wrapper around JavaScript's Date to provide a more abstract date.
 *
 * UnawareDate is useful when we need to operate on dates without having to
 * specify a timezone, but would like to use other functions provided by Date.
 */
var UnawareDate = function (date, localtime) {
    this.date = date;
    this.localtime = localtime;
};

UnawareDate.fromDayOfTheYear = function (year, day) {
    var start = new Date(year + '-01-01T00:00:00Z');
    var tstamp = start.getTime() + day * 86400000;
    return new UnawareDate(new Date(tstamp));
};

UnawareDate.prototype = {
    constructor: UnawareDate,
    getFullYear: function () {
        return this.localtime ? this.date.getFullYear() : this.date.getUTCFullYear();
    },
    getMonth: function () {
        return this.localtime ? this.date.getMonth() : this.date.getUTCMonth();
    },
    getDate: function () {
        var date = this.localtime ? this.date.getDate() : this.date.getUTCDate();
        return date;
    },
    /**
     * Calculate now many days elapsed since the beginning of the year.
     */
    dayOfTheYear: function () {
        var offset = this.localtime ? this.date.getTimezoneOffset() : 0;
        var start = new Date(this.getFullYear() + '-01-01T00:00:00Z').getTime() + offset * 1000;
        var days = Math.floor((this.date.getTime() - start) / 86400000);
        return days;
    }
};
/*
// this file can also be used as a node module
if (typeof module !== 'undefined') {
    module.exports = {
        UnawareDate: UnawareDate
    };
}

*/
export { UnawareDate };
