/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 */
import { Vector2 } from './three.module';

// import {
// 	Vector2
// } from "../../../build/three.module.js";

var SelectionHelper = ( function () {

	function SelectionHelper( renderer, cssClassName ) {
		this.element = document.createElement( 'div' );
		this.element.classList.add( cssClassName );
		this.element.style.pointerEvents = 'none';

		this.renderer = renderer;

		this.startPoint = new Vector2();
		this.pointTopLeft = new Vector2();
        this.pointBottomRight = new Vector2();

		this.isDown = false;
	}

	SelectionHelper.prototype.onSelectStart = function ( event ) {
		this.renderer.domElement.parentElement.appendChild( this.element );

		// TODO: we shouldn't have to do this every time we need correct mouse coordinates
		// [tag:autoSegments]
		var container3d = document.getElementById('container3d');
		var rect = container3d.getBoundingClientRect();

		let clientX = event.clientX - rect.x;
		let clientY = event.clientY;

		this.element.style.left = clientX + 'px';
		this.element.style.top = clientY + 'px';
		this.element.style.width = '0px';
		this.element.style.height = '0px';

		this.startPoint.x = clientX;
		this.startPoint.y = clientY;
	};

	SelectionHelper.prototype.onSelectMove = function ( event ) {

		// TODO: we shouldn't have to do this every time we need correct mouse coordinates
		// [tag:autoSegments]
		var container3d = document.getElementById('container3d');
		var rect = container3d.getBoundingClientRect();

		let clientX = event.clientX - rect.x;
		let clientY = event.clientY;

		this.pointBottomRight.x = Math.max( this.startPoint.x, clientX );
		this.pointBottomRight.y = Math.max( this.startPoint.y, clientY );
		this.pointTopLeft.x = Math.min( this.startPoint.x, clientX );
		this.pointTopLeft.y = Math.min( this.startPoint.y, clientY );

		this.element.style.left = this.pointTopLeft.x + 'px';
		this.element.style.top = this.pointTopLeft.y + 'px';
		this.element.style.width = ( this.pointBottomRight.x - this.pointTopLeft.x ) + 'px';
		this.element.style.height = ( this.pointBottomRight.y - this.pointTopLeft.y ) + 'px';
	};

	SelectionHelper.prototype.onSelectOver = function () {
		if(this.element.parentElement) {
			this.element.parentElement.removeChild( this.element );
		}
	};

	return SelectionHelper;
} )();

export { SelectionHelper };
