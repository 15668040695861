import { GUI } from './Gui.js';
import { emitEvent, init } from '../Viewer.js';


import tippy from 'tippy.js';

class SidebarAutoOutlineGui {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;

        this.autoDrawSelector = 'button.draw-auto-segments';
        this.autoApplySelector = 'button.apply-auto-segments';
        this.autoCancelSelector = 'button.cancel-auto-segments';
    }


    init() {
        const self = this;

        $('body').on('click', this.autoDrawSelector, function onDraw() {
            self.options.tool.cmd('autoSegments.select');
            emitEvent('status', { message: 'Draw automatic outlines' });
            self.renderState();
        });

        $('body').on('click', this.autoApplySelector, function onAutoAccept() {
            self.options.tool.cmd('autoSegments.apply');
            emitEvent('status', { message: "You've applied your outlines!" });
            // not calling propagateState here because we need the whole sidebar to update, not just this widget
            // the tool will take care of that for us
        });

        $('body').on('click', this.autoCancelSelector, function onAutoClear() {
            self.options.tool.cmd('autoSegments.clear');
            emitEvent('status', { message: 'Draw automatic outlines' });
            // not calling propagateState same as apply
        });

        $('.setback-spinner').on(
            'wheel',
            /** @this {HTMLInputElement} */
            function () {
                // ..
        });
    }


    renderState() {
        let s = this.getState();
        const self = this;
        const pid = s.pid;

        var t = `
            <div class="pane-container plane-${pid} auto-segments">
                <div class="auto-segment-header">
                    <h3>Outlines</h3>
                    <div class="info">
                        <div class="qmark-info">
                            <div class="tooltip-content">
                                Draw perimeter around desired roof planes
                            </div>
                        </div>
                    </div>
                </div>

                <div class="auto-segment-actions">
                    <button class="sy draw-auto-segments">
                        Draw
                    </button>
                    <button class="sy apply-auto-segments" disabled aria-disabled="true">
                        Apply
                    </button>
                    <button class="sy cancel-auto-segments" disabled aria-disabled="true">
                        Cancel
                    </button>
                </div>

                <div class="auto-segment-actions-msg hidden">
                    <div class="alert-box"><p><span>&#128204 &nbspHelpful Tip<br></span>Check yellow vertices between roof planes to ensure they align and reflect the desired coverage. Select the polyline to regenerate tilt and azimuth if needed.</p></div>
                    <p>&nbsp<i class="fa fa-refresh" aria-hidden="true"></i>&nbsp Redraw outlines by starting over. <a href="https://help.scanifly.com/clear-your-design-work" target="_blank" aria-label="learn more about starting over" style="color: var(--light-blue)">Learn More</a></p>
                </div>

                <div class="manual-array">
                    <div class="subsection snap auto-segment-inputs">
                        <!--
                        <h4>Global Setback</h4>
                        <div class="content">
                            <div class="row">
                                <input type="checkbox"
                                    id=""
                                    class=""
                                    checked="checked"
                                    aria-label="global setback" />

                                <div class="auto-segment-inputs">
                                    <input type="hidden" id="auto-segment-setback">
                                    <input id="spinner" class="setback-spinner"
                                        name="value"
                                        type="number"
                                        min="-2"
                                        max="3"
                                        step="0.25"
                                        value="1"
                                        size="23"
                                        aria-label="density"><span class="units">inches</span><br><br>
                                </div>
                            </div>

                            <div class="row">
                                <input type="checkbox"
                                    id=""
                                    class=""
                                    checked="checked" />

                                <label for="">
                                    Include firesetbacks
                                </label>
                            </div>
                        </div>
                        -->
                        <h4>Segment Outline Visibility</h4>
                        <div class="auto-seg-content">
                            <div class="row">
                                <input type="radio"
                                    id="visibility-realistic"
                                    name="mode"
                                    checked="checked" />

                                <label for="visibility-realistic">
                                    Realistic
                                </label>

                                <div class="qmark-info">
                                    <div class="tooltip-content">
                                        Outlines and yellow vertices will not be visible behind 3D model
                                    </div>
                                </div>
                            </div>

                            <div class="row x-ray-visibility">
                                <input type="radio"
                                    id="visibility-xray"
                                    name="mode"/>

                                <label for="visibility-xray">
                                    X-ray Mode
                                </label>

                                <div class="qmark-info">
                                    <div class="tooltip-content">
                                        Outlines and yellow vertices will be visible behind 3D model
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;

        $(this.options.selector).html(t);

        $('.setback-spinner').spinner({
            spin: (_, ui) => {
            }
        });

        if (s.array.statusAutoSegments === 'not-selected') {
            GUI.toggleEnable(self.autoDrawSelector)  (true);
            GUI.toggleEnable(self.autoApplySelector) (false);
            GUI.toggleEnable(self.autoCancelSelector)(false);
        } else if (s.array.statusAutoSegments === 'selecting') {
            GUI.toggleEnable(self.autoDrawSelector)  (false);
            GUI.toggleEnable(self.autoApplySelector) (false);
            GUI.toggleEnable(self.autoCancelSelector)(false);
        } else if (s.array.statusAutoSegments === 'selected') {
            GUI.toggleEnable(self.autoDrawSelector)  (false);
            GUI.toggleEnable(self.autoApplySelector) (true);
            GUI.toggleEnable(self.autoCancelSelector)(true);
        } else if (s.array.statusAutoSegments === 'applied') {

            $('.auto-segment-actions').addClass('hidden');
            $('.auto-segment-actions-msg').removeClass('hidden');


            $('.setback-spinner').spinner('enable');
            $('.auto-segment-inputs').css('opacity', '100%');
            $('.auto-segment-inputs').css('cursor', 'auto');
        }

        tippy('.auto-segments .qmark-info', {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            placement:   'top'
        });
    }
}

export { SidebarAutoOutlineGui };
