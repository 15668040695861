import { Color, MeshPhongMaterial, UniformsLib, UniformsUtils } from './three.module';


var occ_shader = {
    uniforms: [
        UniformsLib.common,
        UniformsLib.specularmap,
        UniformsLib.envmap,
        UniformsLib.aomap,
        UniformsLib.lightmap,
        UniformsLib.emissivemap,
        UniformsLib.bumpmap,
        UniformsLib.normalmap,
        UniformsLib.displacementmap,
        UniformsLib.gradientmap,
        UniformsLib.fog,
        UniformsLib.lights,
        {
            emissive: { value: new Color( 0x000000 ) },
            specular: { value: new Color( 0x111111 ) },
            shininess: { value: 30 }
        },
        {
            boxOccludersActive:      { value: new Array(8).fill(1) },
            cylinderOccludersActive: { value: new Array(8).fill(1) },

            occluder_count:    { value: 1 },
            occluder_cy_count: { value: 1 },

            bounds_array:    { value: new Array(48).fill(0) },
            bounds_cy_array: { value: new Array(48).fill(0) },

            occluder_transforms:    { value: new Array(128).fill(0) },
            occluder_cy_transforms: { value: new Array(128).fill(0) },
        }
    ],

    vertexShader: `
#define PHONG

varying vec3 vViewPosition;

#ifndef FLAT_SHADED

    varying vec3 vNormal;

#endif

#include <common>
#include <uv_pars_vertex>
#include <uv2_pars_vertex>
#include <displacementmap_pars_vertex>
#include <envmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

/* SCANIFLY-SPECIFIC */
varying vec3 inter_pos;

uniform bool boxOccludersActive[8];
uniform bool cylinderOccludersActive[8];

uniform int occluder_count;
uniform int occluder_cy_count;

uniform float bounds_array[48];
uniform float bounds_cy_array[48];

uniform mat4 occluder_transforms[8];
uniform mat4 occluder_cy_transforms[8];


void main() {

    #include <uv_vertex>
    #include <uv2_vertex>
    #include <color_vertex>

    #include <beginnormal_vertex>
    #include <morphnormal_vertex>
    #include <skinbase_vertex>
    #include <skinnormal_vertex>
    #include <defaultnormal_vertex>

#ifndef FLAT_SHADED // Normal computed with derivatives when FLAT_SHADED

    vNormal = normalize( transformedNormal );

#endif

    #include <begin_vertex>
    #include <morphtarget_vertex>
    #include <skinning_vertex>
    #include <displacementmap_vertex>
    #include <project_vertex>
    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>

    vViewPosition = - mvPosition.xyz;

    #include <worldpos_vertex>
    #include <envmap_vertex>
    #include <shadowmap_vertex>
    #include <fog_vertex>

    // store position for OCCLUDERS
    inter_pos = worldPosition.xyz;
}
  `,

  fragmentShader: `
#define PHONG

uniform vec3 diffuse;
uniform vec3 emissive;
uniform vec3 specular;
uniform float shininess;
uniform float opacity;

#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <envmap_pars_fragment>
#include <gradientmap_pars_fragment>
#include <fog_pars_fragment>
#include <bsdfs>
#include <lights_pars>
#include <lights_phong_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <specularmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

/* SCANIFLY-SPECIFIC */
varying vec3 inter_pos;

uniform bool boxOccludersActive[8];
uniform bool cylinderOccludersActive[8];

uniform int occluder_count;
uniform int occluder_cy_count;

uniform float bounds_array[48];
uniform float bounds_cy_array[48];

uniform mat4 occluder_transforms[8];
uniform mat4 occluder_cy_transforms[8];


void main() {

    #include <clipping_planes_fragment>

    vec4 diffuseColor = vec4( diffuse, opacity );
    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = emissive;

    #include <logdepthbuf_fragment>
    #include <map_fragment>
    #include <color_fragment>
    #include <alphamap_fragment>
    #include <alphatest_fragment>
    #include <specularmap_fragment>
    #include <normal_fragment>
    #include <emissivemap_fragment>

    // accumulation
    #include <lights_phong_fragment>
    #include <lights_template>

    // modulation
    #include <aomap_fragment>

    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

    #include <envmap_fragment>

    vec4 outColor = vec4( outgoingLight, diffuseColor.a );


    // OCCLUDER TEST
    float occ_alpha = 1.0;

    // boxes
    for (int i = 0; i < 8; i++) {
        if (i < occluder_count) {
            if (boxOccludersActive[i]) {
                vec4 testPos = occluder_transforms[i] * vec4(inter_pos, 1.0);

                // 6 boundaries per occluder
                if (testPos.x > bounds_array[i*6] && testPos.x < bounds_array[(i*6)+1]) {
                    if (testPos.y > bounds_array[(i*6)+2] && testPos.y < bounds_array[(i*6)+3]) {
                        if (testPos.z > bounds_array[(i*6)+4] && testPos.z < bounds_array[(i*6)+5]) {
                            occ_alpha = 0.0;
                        }
                    }
                }
            }
        } else {
            break;
        }
    }

    // cylinders
    for (int i = 0; i < 8; i++) {
        if (i < occluder_cy_count) {
            if (cylinderOccludersActive[i]) {
                vec4 testPos = occluder_cy_transforms[i] * vec4(inter_pos, 1.0);

                if (testPos.y > bounds_cy_array[i*6] && testPos.y < bounds_cy_array[(i*6)+1]) {
                    // within radius
                    float testPos_dist = distance(testPos.xyz, vec3(0.0, testPos.y, 0.0));

                    if (testPos_dist < bounds_cy_array[(i*6)+2]) {
                        occ_alpha = 0.0;
                    }
                }
            }
        } else {
            break;
        }
    }

    outColor.a = min(outColor.a, occ_alpha);
    gl_FragColor = outColor;

    #include <tonemapping_fragment>
    #include <encodings_fragment>
    #include <fog_fragment>
    #include <premultiplied_alpha_fragment>
    #include <dithering_fragment>
}
  `
};


//test values - real values should pull from source outside of threejs
class OccludableMaterial extends MeshPhongMaterial{
    constructor(config){
        config.transparent = true;
        config.shininess = 1;
        config.reflectivity = 1;
        config.specular = 0x000000;

        super(config);

        this.type = "none";
        this.uniforms = UniformsUtils.merge(occ_shader.uniforms);
        this.vertexShader = occ_shader.vertexShader;
        this.fragmentShader = occ_shader.fragmentShader;
    }
}


export { OccludableMaterial, occ_shader };
