



class MeasurementsToolGUI {
    constructor() {
        this.sXray    = '.input-ruler-xray';
        this.sOffsets = '.input-ruler-azimuths';

        var t = `
            <h3 class="header ruler">Ruler</h3>
            <div class="tool-options">
                <div class="option">
                    <div class="label">
                        X-ray Mode:
                    </div>
                    <div class="input-wrapper">
                        <input class="input-ruler-xray" type="checkbox" />
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option">
                    <div class="label">
                        Show extra azimuths:
                    </div>
                    <div class="input-wrapper">
                        <input class="input-ruler-azimuths" type="checkbox" />
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        `;

        $('.placeholder.ruler').replaceWith(t);
    }


    registerGetState   (callback) { this.getState    = callback; }
    registerUpdateState(callback) { this.updateState = callback; }


    init(callback) {
        var s = this.getState();

        $(this.sXray)  .val(s.xray);
        $(this.sOffets).val(s.offsets);

        var self = this;

        $('body').on('click', '.header.ruler', callback);

        $('body').on('change', this.sXray, function () {
            var s = self.getState();

            s.xray = $(this).is(':checked');

            self.updateState(s);

            $('.input-ruler-xray').attr('checked', $(this).is(':checked'));
            $(this).attr('checked', $(this).is(':checked'));
            // this is working but again only updating on the first time the state is changed.
            // evidently doing something wrong - maggie to come back to!
        });


        $('body').on('change', this.sOffsets, function () {
            var s = self.getState();

            s.offsets = $(this).is(':checked');

            self.updateState(s);
        });
    }


    renderState() {
        var s = this.getState();

        $(this.sXray)   .prop('checked', s.xray);
        $(this.sOffsets).prop('checked', s.offsets);
    }
}


export { MeasurementsToolGUI };
