/// @ts-check

import { jUnitVector, kUnitVector, iUnitVector } from './Geometry';
import { zip } from './Utilities';
import { scene } from '../Viewer.js';
import { BufferAttribute, BufferGeometry, Float32BufferAttribute, Geometry, Line, Line3, LineBasicMaterial, Matrix4, Mesh, Object3D, Plane, PlaneHelper, Points, PointsMaterial, Quaternion, Ray, Scene, Vector3 } from './three.module';


/**
 * @typedef {{x: number, y: number, z: number}} Vector3Literal
 */

/**
 * @typedef {{
 *   points: import("../../../types/index").Tuple3<{
 *     position: Vector3,
 *     branched: boolean,
 *     island_id: number,
 *     above: boolean
 *   }>,
 *   rotated_points?: import("../../../types/index").Tuple3<(Vector3 & {above?: boolean})>,
 *   in_bounds?: boolean,
 *   cross_status: -1 | 0 | 1,
 *   checked: boolean,
 *   branch_queued: boolean,
 *   island_id: number
 * }} Face
 */

/**
 * @template {Object3D} O
 * @typedef {O & {dispose?: () => void}} MaybeDisposable
 */
class AutoKeepoutFinder {

    /**
     * @param {object} options
     * @param {Mesh} [options.model]      Model on which to compute keepouts.
     * @param {Scene} [options.scene]     Required when debug = true to draw debug objects.
     * @param {boolean} [options.debug = false] When set, output debug info to the console and,
     *                                          if [scene] is set, draw intermediate debug objects.
     * @param {number} [options.offset = 0]     Offset in direction of plane normal, in world units,
     *                                          from which to consider "obstructions." This controls
     *                                          the sensitivity of the auto-keepout detection.
     */
    constructor(options){
        this.model = options.model;
        this.debug = options.debug || false;

        this._offset = options.offset || 0;

        /** @type {MaybeDisposable<Object3D>[]} */
        this.debugObjects = [];

        this.scene = options.scene;
    }

    /** @param {Mesh} model */
    setModel(model) {
        this.model = model;
    }

    /** @param {Plane} plane */
    getOffsetPlane(plane) {
        const offsetPlane = plane.clone();
        offsetPlane.constant += this._offset;

        return offsetPlane;
    }

    /**
     * Determine rotation necessary to bring the offset plane parallel to the
     * x-z axis, with the azimuth vector in the direction of the +ve z-axis.
     *
     * This is applied to the mesh so that the roof segment of interest is flat
     * and axis-aligned to the segment azimuth/ridge, allowing the numerous point-in-polygon
     * calculations needed to be done with simple plane geometry.
     *
     * @param {Vector3} planeNormal
     * @param {Vector3} azimuthDir
     */
    getNormalizingRotation(planeNormal, azimuthDir) {
        const xzParallelRotation = (new Quaternion()).setFromUnitVectors(planeNormal, jUnitVector);

        const azimuthUnderXZRotation = azimuthDir.clone()
            .applyQuaternion(xzParallelRotation)
            .projectOnPlane(jUnitVector)
            .normalize();

        const azimuthToZAxisRotation = (new Quaternion()).setFromUnitVectors(
            azimuthUnderXZRotation,
            kUnitVector
        );

        return azimuthToZAxisRotation.multiply(xzParallelRotation).normalize();
    }

    /**
     * @param {PlaneDescriptor} plane    Fit-plane to the roof segment.
     * @param {Vector3} azimuthDir Unit vector in the direction of the segment azimuth.
     * @param {Vector3[]} corners  Bounding vertices of the plane of interest.
     */
    getKeepOuts(plane, azimuthDir, corners) {
        const offsetPlane = this.getOffsetPlane(plane.threePlane);

        // Make sure "up" component of the plane is world-up.
        // (If your plane is exactly orthogonal to the X-Z world plane
        // then that is not a roof, that is a wall, and you are a bad person.)
        if (offsetPlane.normal.dot(jUnitVector) < 0) {
            offsetPlane.negate();
        }

        //draw normals
        if (this.debug) {
            const planeO_h= new PlaneHelper( offsetPlane, 10, 0xffff00 );
            this.debugObjects.push(planeO_h);
            this.scene.add(planeO_h);
        }

        const normalizingRotation = this.getNormalizingRotation(offsetPlane.normal, azimuthDir);
        const inverseNormalizingRotation = normalizingRotation.clone().inverse();

        // Transform coordinate system so that the offset plane is parallel to
        // the x-z plane and the segment azimuth is parallel to the +ve z-axis.
        // This allows us to use plane geometry to detect obstructions, and align
        // their footprints with the segment azimuth.
        const rotatedOffsetPlane = offsetPlane.clone().applyMatrix4(
            (new Matrix4()).makeRotationFromQuaternion(
                normalizingRotation
            )
        );

        const upUnderNormalization = Object.freeze(jUnitVector.clone().applyQuaternion(normalizingRotation));

        if (this.debug) {
            var planeR_h= new PlaneHelper(rotatedOffsetPlane, 10, 0xff0000);
            this.debugObjects.push(planeR_h);
            this.scene.add(planeR_h);
        }

        // Bounds of the segment naturally also have to be in the transformed CS.
        const rotatedBounds = corners.map(
            corner => corner.clone().applyQuaternion(normalizingRotation)
        );

        //set y to 0;
        const bounds_2d = rotatedBounds.map( point => ({x:point.x, y:0-point.z}) );
        if (this.debug) {
            this.drawPoints(scene, corners, 1, '0xff0000');
            this.drawPoints(scene, rotatedBounds, 1, '0x800080');
            this.drawPoints(scene, bounds_2d, 1, '0xFF6347');
        }

        // Extract mesh vertices into Face structures for bookkeeping, and
        // determine which are within the bounds of the segment of interest.
        const facesInBounds = Array.from(
            this.checkFacesInBounds(
                this.defineFaces(this.model),
                normalizingRotation,
                rotatedOffsetPlane,
                bounds_2d
            )
        );

        //group islands into seperate obstructions
        const islandsFaces = this.buildIslands(facesInBounds);

        if (islandsFaces.length === 0) {
            return [];
        }

        //get individual points from faces in groups
        const obstructionVertices = islandsFaces.map(island => this.getObstructionPointsFromFaces(island));

        const footprintVertices = islandsFaces.map(
            faces => this.getObstructionFootprintFromFaces(faces, rotatedOffsetPlane)
        );

        /** @type {[Vector3, ...Vector3[]][]} */
        /// @ts-ignore There has to be a better way to express "this is not empty"
        const obstructionAndFootprintVertexGroups = zip(
            obstructionVertices,
            footprintVertices
        ).map(
            ([ov, fv]) => fv.concat(ov)
        );

        /** @param {ReturnType<this['getAABVForPoints']>} aabv */
        const aabvVolume = aabv => aabv.extent.x * aabv.extent.y * aabv.extent.z;

        /**
         * Bound each island by an orthogonal and vertical bounding box. Use the
         * one that yields the minimal volume to define the keepout.
         */
        return obstructionAndFootprintVertexGroups.map(
            vtxs => {
                const orthoExtrusion = Object.freeze(this.getAABVForPoints(vtxs));
                /// @ts-ignore Really? Intellisense can't infer that the return value from getAABVForPoints is
                /// assignable to ReturnType<getAABVForPoints>???
                const orthoVol = aabvVolume(orthoExtrusion);

                const {
                    volume: vertVol,
                    extrusionHeight: vertHeight,
                    overheadAABV: vertExtrusion
                } = this.getOverheadProjectionExtrusion(
                    vtxs,
                    rotatedOffsetPlane,
                    upUnderNormalization
                );

                const {extrusionToUse, extrusionHeight, offsetFactor} = vertVol < orthoVol
                    ? {
                        extrusionToUse: vertExtrusion,
                        extrusionHeight: vertHeight,
                        offsetFactor: jUnitVector.dot(upUnderNormalization)
                        }
                    : {extrusionToUse: orthoExtrusion, extrusionHeight: orthoExtrusion.extent.y, offsetFactor: 1};


                return {
                    height: extrusionHeight + this.offset * offsetFactor,
                    extrude: vertVol < orthoVol ? 'vertical' : 'normal',
                    shape: 'poly',
                    vertices: [
                        extrusionToUse.llb,
                        extrusionToUse.llb.clone().add(
                            extrusionToUse.extent.clone().projectOnVector(iUnitVector)
                        ),
                        extrusionToUse.llb.clone().add(extrusionToUse.extent),
                        extrusionToUse.llb.clone().add(
                            extrusionToUse.extent.clone().projectOnVector(kUnitVector)
                        )
                    ].map(
                        v => v.projectOnPlane(jUnitVector)
                            .add(new Vector3(0, -1 * rotatedOffsetPlane.constant, 0))
                            .applyQuaternion(inverseNormalizingRotation)
                            .sub(plane.normal.clone().multiplyScalar(this.offset))
                    )
                };
            }
        )
    }

    /**
     * @param {import("../../../types/index").NonEmptyArray<Readonly<Vector3>>} vtxs
     * @param {Readonly<Plane>} rotatedOffsetPlane
     * @param {Readonly<Vector3>} upUnderNormalization
     * @returns
     */
    getOverheadProjectionExtrusion(vtxs, rotatedOffsetPlane, upUnderNormalization) {
        const downUnderNormalization = upUnderNormalization.clone().negate();

        /** @type {import("../../../types/index").NonEmptyArray<Vector3>} */
        /// @ts-ignore [T, ...T[]].map<T -> U> => [U, ...U[]]
        const vertexOverheadFootprints = vtxs.map(
            v => {
                let footprintVtx = (new Ray(v, downUnderNormalization)).intersectPlane(
                    rotatedOffsetPlane,
                    new Vector3()
                );

                if (!footprintVtx) {
                    // edge case: this is a "footprint" vertex -- calculated intersection of
                    // a mesh face with the offset plane -- which due to floating-point
                    // imprecision appears as a tiny bit -- atomic scale "tiny bit" -- under
                    // the offset plane, so raycasting down to it fails. If it's within a
                    // millimeter of it that's good enough for me.
                    if (Math.abs(rotatedOffsetPlane.distanceToPoint(v)) < 1e3) {
                        footprintVtx = v;
                    } else {
                        throw new Error("Auto-keepout: an obstruction vertex is below the offset plane");
                    }
                }

                return footprintVtx;
            }
        );

        const overheadAABV = this.getAABVForPoints(vertexOverheadFootprints);
        const overheadFootprintCentroid = overheadAABV.llb.clone().add(
            overheadAABV.extent.clone().divideScalar(2)
        );

        // Vertical height of a keepout is measured relative to the x-z plane through
        // the centroid of the keepout footprint on the roof.
        const centralPlane = new Plane().setFromNormalAndCoplanarPoint(
            upUnderNormalization,
            overheadFootprintCentroid
        );

        const extrusionHeight = vtxs.reduce(
            (h, v) => Math.max(centralPlane.distanceToPoint(v), h),
            -Infinity
        );

        // This is technically not a rectangular prism since the "height" isn't orthogonal
        // to the length and width, but symmetry allows us to pretend it is for volume
        // calculation: the central plane overcounts the wedge volume between centroid and
        // top of extrusion, but undercounts the congruent wedge volume between bottom and
        // centroid, so it balances out.
        const volume = extrusionHeight * overheadAABV.extent.x * overheadAABV.extent.z;
        return { volume, extrusionHeight, overheadAABV };
    }

    /** @param {Object3D} obj */
    defineFaces(obj) {

        /** @type {Face[]} */
        let faces = [];

        obj.traverse(
            /** @param {Object3D & {isMesh?: boolean, geometry?: BufferGeometry | Geometry}} child */
            child => {
                if (!child.isMesh ||
                    !child.geometry ||
                    !(child.geometry instanceof BufferGeometry)
                ) {
                    return;
                }

                const positions = child.geometry.getAttribute('position');
                if (!(positions instanceof BufferAttribute)) {
                    console.warn(`mesh ${child.uuid} uses an interleaved position attribute, skipping`);
                    return;
                }

                /** @param {number} idx @returns {Face["points"]}*/
                const makeFacePoints = idx => {
                    let points = [];
                    for (let j = 0; j < 3; ++j) {
                        points.push({
                            position: new Vector3().fromBufferAttribute(
                                positions,
                                idx + j
                            ).applyMatrix4(
                                child.matrixWorld
                            ),
                            branched: false,
                            island_id: -1,
                            above: false
                        });
                    }

                    /// @ts-ignore I push exactly three items into the array. It's type-safe.
                    return points;
                };

                //MANAGE AND GROUP IN FACES
                for (let i = 0; i < positions.count; i += 3) {
                    /** @type {Face} */
                    const face = {
                        points: makeFacePoints(i),
                        cross_status: -1,  //-1 all below, 0 crosses, 1 all above
                        checked: false,
                        branch_queued: false,
                        island_id: -1
                    };

                    faces.push(face);
                }
            }
        );

        return faces;
    }

    /**
     * @param {Face[]} faces
     * @param {Quaternion} rotation_quat
     * @param {Plane} rotated_plane
     * @param {{x: number, y: number}[]} bounds_2d
     */
    *checkFacesInBounds(faces, rotation_quat, rotated_plane, bounds_2d) {
        /** @param {Face} face */
        const setFaceBounds = face => {
            /// @ts-ignore I'm applying a 1-1 map to a 3-tuple and thus this is a 3-tuple.
            face.rotated_points = face.points.map(
                p => p.position.clone().applyQuaternion(rotation_quat)
            );

            face.cross_status = -1;
            face.in_bounds = true;

            let hasVertexAbove = false;
            let hasVertexBelow = false;

            for (const rotatedPoint of face.rotated_points) {
                rotatedPoint.above = this.isAbovePlane(rotatedPoint, rotated_plane);
                hasVertexAbove = hasVertexAbove || rotatedPoint.above;
                hasVertexBelow = hasVertexBelow || !rotatedPoint.above;

                face.in_bounds = face.in_bounds && this.pointInConcavePoly(
                    {
                        x: rotatedPoint.x,
                        y: -1 * rotatedPoint.z
                    },
                    bounds_2d
                );
            }

            face.cross_status = hasVertexAbove
                ? (hasVertexBelow ? 0 : 1)
                : -1;
        };

        for (const face of faces) {
            setFaceBounds(face);

            if (face.in_bounds) {
                yield face;
            }
        }
    }

    /** @param {Face[]} faces */
    buildIslands(faces) {

        /** @type {Face[][]} */
        let islands_faces = [];
        if (this.debug) {
            console.log(`group faces into islands; facecount: ${faces.length}`);
        }

        //loop all faces
        for(let i = 0 ; i < faces.length ; i++){

            //if assigned - skip
            if(faces[i]['queued']) continue;
            //if entierly below skip
            if(faces[i]['cross_status'] < 0) continue;
            //only use crossing faces as seeds
            if(faces[i]['cross_status'] > 0) continue;

            //set up a new island
            /** @type {Face[]} */
            let current_island = [];

            /** @type {Face[]} */
            let branching_queue = [];

            /** @type {Face[]} */
            let next_cue = [];

            //add seed to cue
            branching_queue.push(faces[i]);
            faces[i]['queued'] = true;

            //while branching
            let branching = true;
            while(branching){
                //loop branch_cue
                for( let b = 0 ; b < branching_queue.length ; b++ ){
                    //loop all faces and find shred
                    for( let f = 0 ; f < faces.length ; f++ ){
                        //if shared but not in cue or on island
                        if( !Boolean(faces[f]['queued']) && faces[f]['cross_status'] > -1 ){
                            //add the ones we find to cue
                            if(this.checkForSharedPoints(branching_queue[b], faces[f])){
                                next_cue.push(faces[f])
                                faces[f]['queued'] = true;
                            }
                        }
                    }
                }
                if(next_cue.length == 0){
                    branching = false;
                }

                //add all next to branch

                current_island.push(...branching_queue);

                branching_queue = [];

                //cue has content set branching to true;
                if (next_cue.length > 0) {
                    branching = true; // LOOP CONTROLLER
                    branching_queue.push(...next_cue);
                    next_cue = [];
                }
            }

            islands_faces.push(current_island);

        }

        if (this.debug) {
            console.log(`grouped faces into ${islands_faces.length} islands`);
        }

        return islands_faces;
    }

    /**
     * @param {Face[]} faceSet
     * @returns {(Vector3 & {above: true})[]}
     */
    getObstructionPointsFromFaces(faceSet) {
        return faceSet.reduce(
            (obstrVtxs, face) => obstrVtxs.concat(...face.rotated_points.filter(v => v.above)),
            []
        );
    }

    /**
     * @param {Face[]} faceSet
     * @param {Plane} rotatedPlane
     */
    getObstructionFootprintFromFaces(faceSet, rotatedPlane) {
        /** @type {Vector3[]} */
        let islandFootprint = [];

        faceSet.filter(face => face.cross_status === 0).forEach(
            face => {
                const intersects = this.getFaceIntersects(face.rotated_points, rotatedPlane);
                if (intersects) {
                    islandFootprint.push(...intersects.map(v => v.clone()));
                }
            }
        );

        return islandFootprint;
    }

    /**
     * @param {Readonly<[Vector3, ...Vector3[]]>} points
     */
    getAABVForPoints(points) {
        const [pt0, ...restPoints] = points;

        return restPoints.reduce(
            (aabv, vtx) => {
                const oldUrt = aabv.llb.clone().add(aabv.extent);
                const llb = new Vector3(
                    Math.min(aabv.llb.x, vtx.x),
                    Math.min(aabv.llb.y, vtx.y),
                    Math.min(aabv.llb.z, vtx.z)
                );

                const newUrt = new Vector3(
                    Math.max(oldUrt.x, vtx.x),
                    Math.max(oldUrt.y, vtx.y),
                    Math.max(oldUrt.z, vtx.z)
                );

                return {
                    llb,
                    extent: newUrt.sub(llb)
                }
            },
            {llb: pt0, extent: new Vector3()}
        );
    }

    /**
     * Helper function to copy the set of {points} with the rotation {quat} applied.
     * @param {Vector3[]} points
     * @param {Quaternion} quat
     */
    cloneAndRotate(points, quat) {
        return points.map(p => p.clone().applyQuaternion(quat));
    }

    /**
     * Extract the intersection points between the mesh
     * face defined by {points} and the given {plane}.
     *
     * @param {import("../../../types/index").Tuple3<Vector3>} points
     * @param {Plane} plane
     */
    getFaceIntersects(points, plane) {
        let return_points = [];

        for (let i = 0; i < 3; ++i) {
            const p = plane.intersectLine(
                new Line3(points[i], points[(i + 1) % 3]),
                new Vector3()
            );

            if (!p || p.x === 0 || p.y === 0 || p.z === 0) {
                continue;
            }

            return_points.push(p);
        }

        if (return_points.length === 3){
            //THROW ERROR or just alert parent to model error
            console.log("3 intersectip  points ERROR");
        }

        return return_points;
    }

    pointInPoly(point, poly) {

        var x = point.x, y = point.y;

        var inside = false;
        for (var i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            var xi = poly[i].x, yi = poly[i].y;
            var xj = poly[j].x, yj = poly[j].y;

            var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;

    }

    // poly = polygon points
    pointInConcavePoly(point, poly) {

        let j = poly.length - 1;
        let oddNodes = false;

        for(let i = 0 ; i < poly.length ; i++)
        {
            if(
                poly[i].y < point.y && poly[j].y >= point.y
                ||
                poly[j].y < point.y && poly[i].y >= point.y
            ){
                if(
                    poly[i].x+(point.y-poly[i].y)/(poly[j].y-poly[i].y)*(poly[j].x-poly[i].x)<point.x
                ){
                    oddNodes = !oddNodes;
                }
            }
            j = i;
        }

        //true if point inside
        return oddNodes;
    }

    drawPoints(scene, points, size, color) {

        color = (color === undefined) ?  '0x'+Math.floor(Math.random()*16777215).toString(16) : color;
        let count = points.length;

        let positions = [];
        points.map((point, index)=>{
            if(typeof point.toArray !== 'function'){
                point = new Vector3(point.x, point.y, point.z);
            }
            point.toArray(positions, index*3);
        });

        const geometry = new BufferGeometry();
        geometry.addAttribute( 'position', new Float32BufferAttribute( positions, 3 ) );

        const material = new PointsMaterial( { color: Number(color) , size:size} );

        /** @type {MaybeDisposable<Points>} */
        const pointsGeo = new Points(geometry, material);

        this.scene.add(pointsGeo);

        pointsGeo.dispose = () => {
            geometry.dispose();
            material.dispose();
        };

        this.debugObjects.push(pointsGeo);

        return pointsGeo;

    }

    /** @param {Mesh} mesh */
    addMesh(mesh) {
        this.scene.add(mesh);
    }

    checkForSharedPoints(face_a, face_b) {

        let shared_points = false;

        let sample, compare;

        loop1:
        for(let i = 0 ; i < 3 ; i++){
            sample = face_a['points'][i];

            loop2:
            for(let j = 0 ; j < 3 ; j++){
                compare = face_b['points'][j];

                if(  this.checkSamePoint( sample, compare )){
                    shared_points = true;
                    break loop1;
                }
            }

        }

        return shared_points;

    }

    checkSamePoint(point_a, point_b) {

        let same = false;
        if(point_a['position'].x == point_b['position'].x && point_a['position'].y == point_b['position'].y && point_a['position'].z == point_b['position'].z){
            same = true;
        }
        return same;

    }

    /**
     * @param {Vector3} point
     * @param {Plane} plane
     */
    isAbovePlane(point, plane) {
        return (new Ray(point, plane.normal.clone().negate())).intersectsPlane(plane);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    //	TESTS ANF DEBUG FUNCTIONS
    //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    reset() {
        const endIdx = this.debugObjects.length - 1;
        for (let i = endIdx; i >= 0; --i){
            const obj = this.debugObjects[i];
            this.scene.remove(obj);
            (obj.dispose || (() => {}))();
        }

        this.debugObjects = [];
    }

    //var material = new LineBasicMaterial( { color: 0x0000ff } );
    draw_lines(scene, points, size, color){
        color = (color === undefined) ?  '0x'+Math.floor(Math.random()*16777215).toString(16) : color;

        let loop = [...points];
        loop.push(points[0]);

        const geometry = new BufferGeometry().setFromPoints( loop );
        const material = new LineBasicMaterial( { color: color , linewidth:size , opacity:0.4, transparent:true} );

        /** @type {MaybeDisposable<Line>} */
        const line = new Line( geometry, material );
        this.scene.add(line);

        line.dispose = () => {
            geometry.dispose();
            material.dispose();
        };

        this.debugObjects.push(line);
    }



    get offset(){
        return 0 - this._offset;
    }

    set offset(val){
        this._offset = 0 - val;
    }
}


export { AutoKeepoutFinder };
