/// @ts-check

import { uuidv4 } from '../alibs/Random';
import * as Q from 'q';


class WorkerPool {
    /**
     * @param {number} [concurrency = 4]
     */
    constructor(concurrency = 4) {
        /** @type {Map<Worker, number>} A collection of workers => current job counts */
        this.queues = new Map();

        for (let idx = 0; idx < concurrency; ++idx) {
            // TODO: refactor WorkerPool to accept workers instead of instantiating them?
            const myWorker = new Worker(
                // my understanding is that the path to the worker has to be hardcoded here,
                // otherwise webpack magic won't work - e.g. if we pass the path as a variable
                new URL('../workers/ViewshedOverlayWorker.js', import.meta.url)
            );

            this.queues.set(this.initWorker(myWorker), 0);
        }

        /** @type {Map<string, Q.Deferred>} Map of job IDs to deferred instances. */
        this.tasks = new Map();
    }

    dispatch(jobData) {
        const jobId = uuidv4();
        const deferred = Q.defer();

        this.tasks.set(jobId, deferred);

        const {queue, currentJobCount} = this.findLeastBusyQueue();

        this.queues.set(queue, currentJobCount + 1);

        queue.postMessage({
            jobId,
            jobData
        });

        return deferred.promise;
    }

    findLeastBusyQueue() {
        let minLength = Infinity;
        let minQueue = null;

        for (const [queue, length] of this.queues) {
            if (length < minLength) {
                minQueue = queue;
                minLength = length;
            }
        }

        return {queue: minQueue, currentJobCount: minLength};
    }

    /**
     * @param {Worker} worker
     */
    initWorker(worker) {
        worker.onmessage = reply => {
            const deferred = this.tasks.get(reply.data.jobId);

            if (!deferred) {
                throw new Error(`Got a result from an unknown job id ${reply.data.jobId}`);
            }

            deferred.resolve(reply.data.result);
            this.tasks.delete(reply.data.jobId);

            const workerJobCount = this.queues.get(worker);
            this.queues.set(worker, workerJobCount - 1);
        }

        return worker;
    }
}

export { WorkerPool };
