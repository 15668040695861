/// @ts-check


import { Color, Vector2 } from '../libs/three.module';

const InfernoGradient = function () {
    /// @source: https://www.kennethmoreland.com/color-advice/#inferno 64 stop color table
    const colorTable = Object.freeze(
        [
            [0.001461995581172, 0.000465991391911, 0.013866005775116],
            [0.006085619997348, 0.004760815063129, 0.03897257707401],
            [0.014239096822768, 0.01140671455955, 0.072696769367604],
            [0.026359749473248, 0.019637151126263, 0.10716483484186],
            [0.043229851029403, 0.028549223372005, 0.142852712978962],
            [0.062610574525987, 0.037042920398208, 0.17985444206125],
            [0.083491320587915, 0.043731455491058, 0.218001796452774],
            [0.106536467594939, 0.047164227362149, 0.256584286054193],
            [0.131756633283636, 0.047141018809471, 0.294206729149934],
            [0.158780545053641, 0.044145884791768, 0.328737055029881],
            [0.186733329929695, 0.039855863772595, 0.358052359225789],
            [0.214686823777425, 0.036817917088719, 0.381158987649421],
            [0.24210131963255, 0.036867328389754, 0.398440459948391],
            [0.268860236564388, 0.040435453023432, 0.410973503776611],
            [0.295043778216277, 0.046857487501737, 0.419873183422571],
            [0.320805201292263, 0.05501784421326, 0.426016855869708],
            [0.346281074456275, 0.064067381152232, 0.430028703278884],
            [0.371582339259072, 0.073467111591578, 0.432322959012017],
            [0.396786518835543, 0.082921034082273, 0.433172687379822],
            [0.421957821951614, 0.092279384352912, 0.432736050145098],
            [0.447132547088418, 0.101487073842185, 0.431104636870458],
            [0.472328562220233, 0.110545092538776, 0.428334014815688],
            [0.497553992191462, 0.119480869034309, 0.42443439148319],
            [0.522801097717206, 0.128355769541252, 0.419415328375083],
            [0.54804935640957, 0.137240876708372, 0.413271108162237],
            [0.573268679899184, 0.146220809714808, 0.405996456852091],
            [0.598417715745656, 0.155392602826041, 0.397593944336347],
            [0.623447507630125, 0.164863285576461, 0.388066346832288],
            [0.648298745176934, 0.174745908480477, 0.377435816595761],
            [0.672901427451771, 0.185160072075058, 0.36572645737999],
            [0.697178837390563, 0.196230202492462, 0.352986326605839],
            [0.721046373907239, 0.208077178009854, 0.33926352722012],
            [0.744412926342759, 0.220819672521875, 0.32463028809972],
            [0.767180818935575, 0.234564263830226, 0.309166113425662],
            [0.789251451523649, 0.24940618962454, 0.292951056907997],
            [0.810521167028571, 0.265419184128435, 0.276078592700971],
            [0.830892563919666, 0.282655485985509, 0.258636436168729],
            [0.850271993858104, 0.301139500632269, 0.240708260994333],
            [0.86857066608623, 0.320871960487056, 0.222360279627972],
            [0.885714759337038, 0.341827592284566, 0.203640498111205],
            [0.901640550261075, 0.363959095979889, 0.18457349147998],
            [0.916296162750187, 0.387201375136605, 0.165157315890811],
            [0.929643901494175, 0.411476127788153, 0.145367501589709],
            [0.941652912473698, 0.436706371340739, 0.125173915945657],
            [0.952307195320108, 0.462802558058243, 0.104552195353787],
            [0.961593200741639, 0.489679930045928, 0.083564484007114],
            [0.969498576265873, 0.51726131833482, 0.062543001348175],
            [0.976015438594503, 0.5454771936452, 0.042566775071953],
            [0.981132084729808, 0.574262121119513, 0.027866493814822],
            [0.984834876858908, 0.60355552196792, 0.02380133141431],
            [0.987109315870737, 0.633306719393646, 0.03242071865993],
            [0.987938055030967, 0.663463874150851, 0.054613310502616],
            [0.987306753639649, 0.693975342354764, 0.083722994484984],
            [0.985204306485513, 0.724786625222741, 0.116752338558081],
            [0.981631559724328, 0.755837259949963, 0.152913003311621],
            [0.976644332735287, 0.787033951555767, 0.192201991207091],
            [0.97035267847147, 0.818256124008254, 0.235034475886167],
            [0.963061766225448, 0.849295459716074, 0.282094171894424],
            [0.955443281570408, 0.879795044372219, 0.334276019263328],
            [0.948945503801506, 0.909124926212728, 0.392440927815436],
            [0.946407796142502, 0.936242328500773, 0.45628273513208],
            [0.951534773801058, 0.96005871736487, 0.522652097343438],
            [0.96603874051072, 0.980448827956062, 0.586478502427134],
            [0.988362079921221, 0.998361647062055, 0.644924098280386]
        ].map(v => Object.freeze(new Color(...v)))
    );

    /**
     * Circular interpolation between two angular quantities.
     * @see https://en.wikipedia.org/wiki/Circular_mean
     *
     * @param {number} thetaStart Radial measurement
     * @param {number} thetaEnd   Radial measurement
     * @param {number} alpha      mixing coefficient in [0, 1]
     */
    function cerp(thetaStart, thetaEnd, alpha) {
        const vStart = new Vector2(Math.cos(thetaStart), Math.sin(thetaStart));
        const vEnd = new Vector2(Math.cos(thetaEnd), Math.sin(thetaEnd));

        const vMean = new Vector2().lerpVectors(vStart, vEnd, alpha);
        return Math.atan2(vMean.y, vMean.x);
    }

    return {
        /** @param {number} v in [0, 1] */
        value: v => {

            const bucket = v * (colorTable.length - 1);
            const leftIdx = Math.trunc(bucket);

            if (bucket === leftIdx) {
                return colorTable[leftIdx];
            }

            const rightIdx = leftIdx + 1;

            const leftHSL = colorTable[leftIdx].getHSL();
            const rightHSL = colorTable[rightIdx].getHSL();

            const alpha = leftIdx - bucket;

            /** @param {keyof leftHSL} key */
            const lerp = key => leftHSL[key] * (1 - alpha) + rightHSL[key] * alpha;

            // Hue in the HSL system is an angular quantity, not linear!
            return new Color().setHSL(
                cerp(leftHSL['h'] * 2 * Math.PI, rightHSL['h'] * 2 * Math.PI, alpha) / (2 * Math.PI),
                lerp('s'),
                lerp('l')
            );
        }
    }
}();


export { InfernoGradient };
