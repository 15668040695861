class SelectedSegmentSync {
    constructor(tools) {
        Object.defineProperties(tools.modSpec, {
            planeId: {
              set: function(pid){
                  this._planeId != pid ? this._planeId = pid : null;
                  tools.viewTool.currentRoofPlane != pid ? tools.viewTool.cmd('switchPlane', pid) : null;
              },

              get: function(){return this._planeId}
            },
          });

        Object.defineProperties(tools.viewTool, {
            currentRoofPlane: {
              set: function(pid){
                  this._currentRoofPlane != pid ? this._currentRoofPlane = pid : null;
                  tools.modSpec.planeId != pid ? tools.modSpec.cmd('switchPlane', pid) : null;
              },

              get: function(){return this._currentRoofPlane}
            }
          });

          //set default value
          tools.modSpec.planeId = 0;
          tools.viewTool.currentRoofPlane = 0;

          this.moduleSpecTool = tools.modSpec;
          this.viewshedTool = tools.viewTool;
    }
}

export { SelectedSegmentSync }
