import { headingToDirectionVector,  degToRad, radToDeg } from './Geometry';
import { Vector3 } from './three.module';


/**
 * Module for connecting real world cardinal directions to their 3D world/scene counterparts.
 */
const UP    = Object.freeze( new Vector3( 0,  1,  0) );
const DOWN  = Object.freeze( new Vector3( 0, -1,  0) );
const NORTH = Object.freeze( new Vector3( 0,  0,  1) );
const EAST  = Object.freeze( new Vector3(-1,  0,  0) );
const SOUTH = Object.freeze( new Vector3( 0,  0, -1) );
const WEST  = Object.freeze( new Vector3( 1,  0,  0) );


function azimuthFromVector(v) {
    let vFlat = v.clone().projectOnPlane(UP);

    let aN = radToDeg(NORTH.angleTo(vFlat));
    let aW = radToDeg(WEST.angleTo(vFlat));

    if (aW < 90)
        aN = 360 - aN;

    if (aN < 0)
        aN = 180;

    return aN;
}


function tiltFromVector(v) {
    let n = new Vector3(v.x, 0, v.z);

    return tiltFromVectorAndNormal(v, n);
}

function tiltFromVectorAndNormal(v, n) {
    let tilt = Math.round((90 - radToDeg(n.angleTo(v))) * 10) / 10;

    if (tilt < 0)
        tilt = -1 * tilt;

    return tilt;
}

export {
    azimuthFromVector,
    tiltFromVector,
    headingToDirectionVector,
    tiltFromVectorAndNormal,
    UP,
    DOWN,
    NORTH,
    WEST,
    SOUTH,
    EAST
};
