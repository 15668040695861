import { inchesToMeters, mmToMeters } from '../alibs/Units';
import { Net } from '../libs/Net';


/**
 * @param {[string, { favorite: boolean | undefined }][]} modEntries
 */
function sortModEntries(modEntries) {
    return modEntries.sort(
        ([name1, { favorite: fav1 }], [name2, { favorite: fav2 }]) => fav1 && !fav2
            ? -1
            : (fav2 && !fav1) ? 1 : name1.localeCompare(name2)
    );
}

const DEFAULT_MOD_SUNPOWER_X21_BLACK = Object.freeze({
    "moduleName":       'SunPower X21 Black',
    "model":            "SPR-X21-335-BLK",
    "manufacturer":     "SunPower",
    "wattage":          335,
    "moduleMechType":   "mono",
    "textureMap":       "mono-black-4",
    "powTol":           "0/5",
    "ratVol":           57.3,
    "ratCurr":          5.85,
    "opnCirc":          67.9,
    "shrtCrc":          6.23,
    "maxSeriesFuse":    15,
    "powTemp":          -0.29,
    "voltTemp":         -167.4,
    "currTemp":         2.9,
    "unitsVoltageTemp": "mvc",
    "unitsCurrTemp":    "mac",
    "cell_quan":        96,
    "weight":           41,
    "max_load_wind":    3600,
    "max_load_snow":    2600,
    "moduleWidth":      41.2,
    "moduleHeight":     61.3,
    "units":            1,
    "shared":           true,
});

const DEFAULT_MOD_SUNPOWER_X21 = Object.freeze({
    "moduleName":       'SunPower X21',
    "model":            "SPR-X21-345",
    "manufacturer":     "SunPower",
    "wattage":          345,
    "moduleMechType":   "mono",
    "textureMap":       "mono-blue-3",
    "powTol":           "0/5",
    "ratVol":           57.3,
    "ratCurr":          6.01,
    "opnCirc":          68.2,
    "shrtCrc":          6.39,
    "maxSeriesFuse":    15,
    "powTemp":          -0.29,
    "voltTemp":         -167.4,
    "currTemp":         2.9,
    "unitsVoltageTemp": "mvc",
    "unitsCurrTemp":    "mac",
    "cell_quan":        96,
    "weight":           41,
    "max_load_wind":    3600,
    "max_load_snow":    2600,
    "moduleWidth":      41.2,
    "moduleHeight":     61.3,
    "units":            1,
    "shared":           true,
});

const DEFAULT_MOD_LG_NEON_R_360 = Object.freeze({
    "moduleName":       'LG NeON R 360',
    "model":            "360Q1C-A5",
    "manufacturer":     "LG",
    "wattage":          360,
    "moduleMechType":   "mono",
    "textureMap":       "sunpower-x-black",
    "powTol":           "0/3",
    "ratVol":           36.5,
    "ratCurr":          9.87,
    "opnCirc":          42.7,
    "shrtCrc":          10.79,
    "maxSeriesFuse":    20,
    "powTemp":          -0.30,
    "voltTemp":         -0.24,
    "currTemp":         0.04,
    "unitsVoltageTemp": "c",
    "unitsCurrTemp":    "c",
    "cell_quan":        60,
    "weight":           40.79,
    "max_load_wind":    3600,
    "max_load_snow":    2600,
    "moduleWidth":      40,
    "moduleHeight":     66.93,
    "units":            1,
    "shared":           true,
});

const DEFAULT_MOD_SCANIFLY_POLY = Object.freeze({
    "moduleName":       'Scanifly Default Poly Module',
    "model":            "SDM-290W",
    "manufacturer":     "Stark Industries",
    "wattage":          290,
    "moduleMechType":   "poly",
    "textureMap":       "poly",
    "powTol":           "0/5",
    "ratVol":           31.8,
    "ratCurr":          9.2,
    "opnCirc":          38.5,
    "shrtCrc":          9.65,
    "maxSeriesFuse":    15,
    "powTemp":          -0.42,
    "voltTemp":         -0.32,
    "currTemp":         0.05,
    "unitsVoltageTemp": "c",
    "unitsCurrTemp":    "c",
    "cell_quan":        60,
    "weight":           41.89,
    "max_load_wind":    3600,
    "max_load_snow":    2600,
    "moduleWidth":      39.06,
    "moduleHeight":     66.14,
    "units":            1,
    "shared":           true,
});

const DEFAULT_MOD_SCANIFLY = Object.freeze({
    "moduleName":       'Scanifly Default Module',
    "model":            "SDM-300W",
    "manufacturer":     "Stark Industries",
    "wattage":          300,
    "moduleMechType":   "mono",
    "textureMap":       "mono",
    "powTol":           "0/5",
    "ratVol":           32.2,
    "ratCurr":          9.32,
    "opnCirc":          38.8,
    "shrtCrc":          9.71,
    "maxSeriesFuse":    15,
    "powTemp":          0.39,
    "voltTemp":         0.30,
    "currTemp":         0.06,
    "unitsVoltageTemp": "c",
    "unitsCurrTemp":    "c",
    "cell_quan":        60,
    "weight":           41.3,
    "max_load_wind":    3600,
    "max_load_snow":    2600,
    "moduleWidth":      39,
    "moduleHeight":     64.6,
    "units":            1,
    "shared":           true,
});

const DEFAULT_MOD_MAP = Object.freeze(new Map([
    /** @type {const} */ (['Scanifly Default Module', DEFAULT_MOD_SCANIFLY]),
    /** @type {const} */ (['SunPower X21 Black', DEFAULT_MOD_SUNPOWER_X21_BLACK]),
    /** @type {const} */ (['SunPower X21', DEFAULT_MOD_SUNPOWER_X21]),
    /** @type {const} */ (['LG NeON R 360', DEFAULT_MOD_LG_NEON_R_360]),
    /** @type {const} */ (['Scanifly Default Poly Module', DEFAULT_MOD_SCANIFLY_POLY]),
]));

export function formatSpecForModuleSpecsTool(mData) {
    const convertToMeters = mData.units === 0
        ? /** @param {number} v */ v => mmToMeters(v, 3)
        : /** @param {number} v */ v => inchesToMeters(v, 4);

    let w = mData.moduleWidth;
    let h = mData.moduleHeight;

    if (mData.units === 0) {
        w = mmToMeters(w, 3);
        h = mmToMeters(h, 3);
    } else {
        w = inchesToMeters(w, 4);
        h = inchesToMeters(h, 4);
    }

    const width = convertToMeters(mData.moduleWidth);
    const height = convertToMeters(mData.moduleHeight);

    return {
        width,
        height,
        model: mData.model,
        wattage: parseInt(mData.wattage, 10),
        moduleName: mData.moduleName,
        moduleType: mData.textureMap,
        manufacturer: mData.manufacturer
    };
}

export class ModuleLibrary {
    static get defaultModuleSpecs() {
        // TODO: consolidate with the list of default modules below
        return {
            manufacturer: 'Stark Industries',
            model:        'SDM-300W',
            moduleName:   'Scanifly Default Module',
            moduleType:   'mono',
            mountType:    'flush-mount',
            orientation:  'portrait',
            dynamicMode:  true,
            wattage:      300,
            width:        0.99,
            height:       1.64,
            cols:         8,
            rows:         4,
            hOffset:      0.0254,
            vOffset:      0.0254,
            hSpacing:     6.096,
            vSpacing:     1.524,
            heightOffset: 0.1016
        };
    }

    /**
     * @param {string} userId
     * @param {{ modules: Record<string, any> | undefined }} data
     */
    constructor(userId, data) {
        this.userId = userId;
        this.data = data || {
            modules: {}
        };

        this._modules = {
            ...Object.fromEntries(DEFAULT_MOD_MAP),
            ...this.data.modules || {}
        };


        this._isDirty = true; // lazily sort the mods by favorite status then name
    }

    get modules() {
        if (this._isDirty) {
            this._modules = Object.fromEntries(
                sortModEntries(
                    Object.entries(this._modules)
                )
            );

            this._isDirty = false;
        }

        return this._modules;
    }

    /**
     * @param {string} moduleName
     */
    getSpecByName(moduleName) {
        // select by name => no need for sorted list
        return this._modules[moduleName];
    }

    getDefaultSpec() {
        return Object.values(this.modules)[0];
    }

    cmd(command, val) {
        if (command === 'save') {
            return this.save();
        } else if (command === 'getModules') {
            return this.modules;
        } else if (command === 'editModule') {
            this._modules[val.moduleName] = val;
            this._isDirty = true;
        } else if (command === 'deleteModule') {
            delete this._modules[val];
        } else if (command === 'toggleFavoriteModule') {
            const mod = this._modules[val];
            mod.favorite = !mod.favorite;
            this._isDirty = true;
        } else {
            console.log('Unknown command:', command);
        }
    }

    // TODO: handle module specs created before module library was introduced

    async save() {
        const ownModules = Object.fromEntries(
            Object.entries(this.modules).filter(
                ([name, modSpec]) => !modSpec.shared && !DEFAULT_MOD_MAP.has(name)
            )
        );

        await Net.updateModuleLibrary(this.userId, { modules: ownModules });
    }
}
