import { controls } from '../Viewer.js';
import { radToDeg, degToRad } from '../libs/Geometry';



var CameraRotator = function () {
    this.rotateLeftAngle = 0;
    this.rotateUpAngle = 0;

    this.leftDelta = 0;
    this.upDelta = 0;

    this.leftAmount = 10;
    this.upAmount = 10;
};

CameraRotator.prototype = {};
CameraRotator.prototype.constructor = CameraRotator;

CameraRotator.prototype.getRotateLeftAngle = function () {
    if (    (this.leftDelta > 0 && this.rotateLeftAngle > 0) ||
            (this.leftDelta < 0 && this.rotateLeftAngle < 0) ) {
        var delta = Math.abs(this.rotateLeftAngle) < Math.abs(this.leftDelta) ?
            this.rotateLeftAngle : this.leftDelta;

        this.rotateLeftAngle -= delta;
        return degToRad(delta);
    }

    return 0;
};

CameraRotator.prototype.getRotateUpAngle = function () {
    if (    (this.upDelta > 0 && this.rotateUpAngle > 0) ||
            (this.upDelta < 0 && this.rotateUpAngle < 0) ) {
        var delta = Math.abs(this.rotateUpAngle) < Math.abs(this.upDelta) ?
            this.rotateUpAngle : this.upDelta;

        this.rotateUpAngle -= delta;
        return degToRad(delta);
    }

    return 0;
};

CameraRotator.prototype.moveCameraAngle = function (azimuthalAngle, polarAngle) {
    this.rotateLeftAngle = -radToDeg(azimuthalAngle - controls.getAzimuthalAngle());

    if (this.rotateLeftAngle > 180) {
        this.rotateLeftAngle = -360 + this.rotateLeftAngle;
    } else if (this.rotateLeftAngle < -180) {
        this.rotateLeftAngle = 360 + this.rotateLeftAngle;
    }

    this.leftDelta = this.rotateLeftAngle > 0 ? this.leftAmount : -this.leftAmount;

    this.rotateUpAngle = -radToDeg(polarAngle - controls.getPolarAngle());
    this.upDelta = this.rotateUpAngle > 0 ? this.upAmount : -this.upAmount;
};



export { CameraRotator };
