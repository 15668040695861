import { Polygon } from './Polygon';
import { planeFromPoints } from './Geometry';
import { Geometry, Line, Mesh, MeshBasicMaterial, MOUSE, Plane, Shape, ShapeGeometry, SphereGeometry, Vector3 } from './three.module';








// TODO: deprecated







var PolygonDrawer = function () {
    this.segments = [];
    this.segmentMarkers = [];

    this.states = { NONE: 0, STARTED: 1, CLOSED: 2 };
    this.state = this.states.NONE;

    this.snapDistance = 0.5;

    this.meshes = [];

    this.segmentMarkerRadius = 0.25;
};

PolygonDrawer.prototype = {};
PolygonDrawer.prototype.constructor = PolygonDrawer;

PolygonDrawer.prototype.setCamera    = function (camera) { this.camera = camera; };
PolygonDrawer.prototype.setRaycaster = function (rc)     { this.raycaster = rc; };
PolygonDrawer.prototype.setMesh      = function (mesh)   { this.meshes.push(mesh); };
PolygonDrawer.prototype.setObject    = function (o)      { this.object = o; };
PolygonDrawer.prototype.addToScene   = function (scene)  { this.scene = scene; };

PolygonDrawer.prototype.enable = function () {
    if (this.enabled)
        return;

    this.enabled = true;
};

PolygonDrawer.prototype.disable = function () {
    if (!this.enabled)
        return;

    this.clear();
    this.enabled = false;
};

PolygonDrawer.prototype.addSegment = function (point) {
    var segment = this.createSegment(point);
    this.segments.push(segment);
    this.scene.add(segment);
};

PolygonDrawer.prototype.insertSegment = function (index, point) {
    var end = this.segments[index].geometry.vertices[1].clone();
    this.segments[index].geometry.vertices[1].copy(point);

    var next = (index + 1) % this.segments.length;

    var segment = this.createSegment(point);
    this.scene.add(segment);
    this.segments.splice(next, 0, segment);

    this.segments[next].geometry.vertices[1].copy(end);

    this.segments[index].geometry.verticesNeedUpdate = true;
    this.segments[ next].geometry.verticesNeedUpdate = true;

    var marker = this.createSegmentMarker(point);
    this.scene.add(marker);
    this.segmentMarkers.splice(next, 0, marker);
};

PolygonDrawer.prototype.createSegment = function (point) {
    var geometry = new Geometry();
    geometry.vertices.push(point.clone());
    geometry.vertices.push(point.clone());

    var line = new Line(geometry, this.material);

    return line;
};

PolygonDrawer.prototype.createSegmentMarker = function (v) {
    var markerGeometry = new SphereGeometry(this.segmentMarkerRadius, 32, 32);
    var markerMaterial = new MeshBasicMaterial({
        color:       0xffff00,
        opacity:     0.7,
        transparent: true,
        depthTest:   false
    });

    var marker = new Mesh(markerGeometry, markerMaterial);
    marker.position.copy(v);

    return marker;
};

PolygonDrawer.prototype.moveVertex = function (index, v) {
    var prev = (this.segments.length + index - 1) % this.segments.length;

    this.segments[index].geometry.vertices[0].copy(v);
    this.segments[ prev].geometry.vertices[1].copy(v);

    this.segments[index].geometry.verticesNeedUpdate = true;
    this.segments[ prev].geometry.verticesNeedUpdate = true;

    this.segmentMarkers[index].position.copy(v);
};

PolygonDrawer.prototype.undo = function () {
    if (this.state === this.states.STARTED) {
        if (this.segments.length) {
            var segment = this.segments.pop();
            this.scene.remove(segment);

            this.clearMarkers();
        } else {
            this.state = this.states.NONE; // no more segments to remove
        }
    }
};

PolygonDrawer.prototype.planeFromSegments = function () {
    return planeFromPoints(this.segments.map((s) => s.geometry.vertices[1]));
};

/**
 * Create a Geometry out of line segments (in world coordinates).
 */
PolygonDrawer.prototype.getPolygonGeometry = function (plane) {
    var v = this.segments[0].geometry.vertices[0];

    // construct a shape geometry to triangulate our polygon
    var shape = new Shape();
    shape.moveTo(v.x, v.z);

    for (var i = 0; i < this.segments.length; i++) {
        var v2 = this.segments[i].geometry.vertices[1];
        shape.lineTo(v2.x, v2.z);
    }

    var shapeGeometry = new ShapeGeometry(shape);

    // convert shape geometry into geometry, projecting the points onto the plane
    var geometry = new Geometry();

    var p = new Plane();
    p.setFromNormalAndCoplanarPoint(plane.normal, plane.centroid);

    for (var i = 0; i < shapeGeometry.vertices.length; i++) {
        v = new Vector3(shapeGeometry.vertices[i].x, 0, shapeGeometry.vertices[i].y);
        v.y = -p.distanceToPoint(v);

        geometry.vertices.push(v);
    }

    for (var i = 0; i < shapeGeometry.faces.length; i++) {
        var face = shapeGeometry.faces[i];

        // orient faces the right way up
        var tmp = face.a;
        face.a = face.c;
        face.c = tmp;

        geometry.faces.push(face);
    }

    return geometry;
};

PolygonDrawer.prototype.getLocalPolygonBoundaries = function () {
    var v = this.segments[0].geometry.vertices[0];
    var vv = [ this.object.worldToLocal(new Vector3(v.x, 0, v.z)) ];

    for (var i = 0; i < this.segments.length; i++) {
        var v2 = this.segments[i].geometry.vertices[1];
        vv.push(this.object.worldToLocal(new Vector3(v2.x, 0, v2.z)));
    }

    var polygon = new Polygon({ vertices: vv });

    return polygon;
};

PolygonDrawer.prototype.clearMarkers = function () {
    for (var i = 0; i < this.segmentMarkers.length; i++) {
        this.scene.remove(this.segmentMarkers[i]);
    }

    this.segmentMarkers = [];
};

PolygonDrawer.prototype.clear = function () {
    this.state = this.states.NONE;

    for (var i = 0; i < this.segments.length; i++) {
        this.scene.remove(this.segments[i]);
    }

    this.segments = [];

    this.clearMarkers();
};

PolygonDrawer.prototype.mouseUp = function (event, mouse, panning) {
    if (!this.enabled || panning)
        return;

    if (event.button === MOUSE.LEFT) {
        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObjects(this.meshes);
        if (intersects.length === 0)
            return;

        if (this.state === this.states.NONE) {
            this.state = this.states.STARTED;

            this.addSegment(intersects[0].point);

        } else if (this.state === this.states.STARTED) {
            if (this._isClosedLoop()) {
                this.state = this.states.CLOSED;
                this.closedLoop();
            } else {
                this.addSegment(intersects[0].point);
            }
        }
    }
};

PolygonDrawer.prototype.closedLoop = function () { };

PolygonDrawer.prototype.mouseMove = function (mouse) {
    if (!this.enabled)
        return;

    if (this.state === this.states.STARTED) {
        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObjects(this.meshes);
        if (intersects.length === 0)
            return;

        var point = intersects[0].point;

        if (this.segments.length > 0) {
            this.clearMarkers();

            if (this.segments.length > 2 && point.distanceTo(this.segments[0].geometry.vertices[0]) < this.snapDistance) {
                // estimate a reasonable marker size
                var distances = [];

                for (var i = 0; i < this.segments.length; i++) {
                    var v1 = this.segments[i].geometry.vertices[0];
                    var v2 = this.segments[i].geometry.vertices[1];

                    distances.push(v1.distanceTo(v2));
                }

                this.segmentMarkerRadius = Math.min.apply(Math, distances) / 20;

                for (var i = 0; i < this.segments.length; i++) {
                    var marker = this.createSegmentMarker(this.segments[i].geometry.vertices[0]);

                    this.segmentMarkers.push(marker);
                    this.scene.add(marker);
                }

                point = this.segments[0].geometry.vertices[0];
            }

            var geometry = this.segments[this.segments.length - 1].geometry;
            geometry.vertices[1].copy(point);
            geometry.verticesNeedUpdate = true;
        }
    }
};

PolygonDrawer.prototype.handleKeyboard = function (keyboard) {
    if (!this.enabled)
        return;

    if (keyboard.down('esc'))
        this.undo();
};

PolygonDrawer.prototype._isClosedLoop = function () {
    if (this.segments.length < 3)
        return false;

    var first = this.segments[0].geometry.vertices[0];
    var last = this.segments[this.segments.length - 1].geometry.vertices[1];

    return first.equals(last);
};



export { PolygonDrawer };
