/// @ts-check

import { kUnitVector } from '../libs/Geometry.js';
import { Colors } from '../core/Colors.js';
import { PolyContext } from '../draw/PolyContext';
import { DoubleSide, Matrix4, Mesh, MeshBasicMaterial, Shape, ShapeBufferGeometry, Vector2 } from '../libs/three.module';


class OffsetSegment {
    /**
     * @param {object} ctorOpts
     * @param {PolyContext} ctorOpts.context
     * @param {number} [ctorOpts.initialOffset = 0]
     * @param {MeshBasicMaterialParameters['color']} [ctorOpts.color]
     * @param {boolean} [ctorOpts.transparent = true]
     * @param {number}  [ctorOpts.opacity = 0.75]
     * @param {number} [ctorOpts.scale = 1.0]
     */
    constructor(ctorOpts) {

        // this seems really code-smelly to me to get a CSS default
        const planeColor = ctorOpts.color || Colors.Scanifly.blue();
        const scale = ctorOpts.scale || 1.0;
        this.parentCtx = ctorOpts.context;
        this.parentMovePlane = this.parentCtx.movementPlane;

        const shape = new Shape();
        shape.autoClose = true;

        const localVs = this.parentCtx.getVerticesInCoordinateFrameOf(this.parentMovePlane);

        shape.setFromPoints(
            localVs.map(
                v => {
                    const vOrtho = v.clone().projectOnPlane(kUnitVector);
                    return new Vector2(vOrtho.x, vOrtho.y)
                }
            )
        );


        this.shapeGeo = new ShapeBufferGeometry(shape);
        this.shapeGeo.normalizeNormals();

        this.meshMtl = new MeshBasicMaterial({
            color: planeColor,
            transparent: ctorOpts.transparent || true,
            opacity: ctorOpts.opacity || 0.75,
            side: DoubleSide
        });

        this.mesh = new Mesh(
            this.shapeGeo,
            this.meshMtl
        );

        // Scale offset plane to overlap the segment and flip normals
        this.mesh.applyMatrix(
            new Matrix4().makeScale(scale, scale, -1 * scale)
        );

        this.toggleVisible(false);

        /**
         * @todo Most of the viewer code uses world coordinates instead of
         *   leveraging the scene graph like this. Do we want to break
         *   this pattern by making the offset plane local to the movement
         *   plane here?
         */
        this.parentMovePlane.add(this.mesh);

        this.offset = 0;
    }

    dispose() {
        this.parentMovePlane.remove(this.mesh);
        this.meshMtl.dispose();
        this.shapeGeo.dispose();
    }

    /** @param {number} offset */
    setOffset(offset) {
        this.offset = offset;

        this.mesh.position.copy(kUnitVector.clone().negate().multiplyScalar(offset));
        this.mesh.updateMatrixWorld(false);
    }

    /** @param {boolean} on */
    toggleVisible(on = true) {
        this.mesh.visible = on;
    }
}


export { OffsetSegment };
