import { deepCopy, rangeArray } from '../libs/Utilities';
import { Draw } from '../draw/Draw.js';
import { Polygon } from '../libs/Polygon.js';
import { radToDeg, degToRad } from '../libs/Geometry';
import { CollisionDetector } from '../libs/CollisionDetector.js';
import { SolarPanelModule } from './SolarPanelModule.js';
import { uuidv4 } from '../alibs/Random.js';
import { DoubleSide, Geometry, Group, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, Raycaster, Vector2, Vector3 } from '../libs/three.module';




/**
 * Module width  - width of solar cells only
 * Module height - height of solar cells only
 * Module border - width of the border/bezel around the module
 * hOffset       - horizontal distance between roof mounted modules or modules on the same ground mount
 * vOffset       - vertical distance between roof mounted modules or modules on the same ground mount
 * heightOffset  - distance from the modules to the roof plane
 * hSpacing      - horizontal distance between ground mounts
 * vSpacing      - vertical distance between ground mounts
 */
class SolarPanelModuleArray extends Object3D {
    /**
     *
     * @param {{
     *   raycaster?: Raycaster,
     *   meshes?: Mesh[],
     *   scene?: Scene,
     *   fireSetbacks?: unknown[],
     *   moduleSpecs?: {
     *     dynamicMode?: boolean,
     *     width?: number,
     *     height?: number,
     *     azimuth?: number,
     *     [k: string]: any
     *   },
     *   wStart?: number,
     *   wStep?: number,
     *   hStart?: number,
     *   hStep?: number,
     *   addedSpecs?: Object
     * }} options
     */
    constructor(options) {
        super();

        options = options || {};

        /** @type {import('three').Raycaster} */
        this.raycaster = options.raycaster;

        /** @type {import('three').Mesh[]} */
        this.meshes    = options.meshes;

        /** @type {import('three').Scene} */
        this.scene     = options.scene;

        this.raycasterY = 200;

        this.fireSetbacks = options.fireSetbacks ? options.fireSetbacks : [];
        this.moduleSpecs  = options.moduleSpecs;

        /** @type {import('three').Group} */
        this.groupMove = new Group();
        this.scene.add(this.groupMove);

        /** @type {import('three').MeshBasicMaterial} */
        this.material = new MeshBasicMaterial({
            color:       0x0000ff,
            side:        DoubleSide,
            transparent: true,
            opacity:     0.0,
            depthTest:   false
        });

        /** @type {import('three').LineBasicMaterial} */
        this.materialSetback = new LineBasicMaterial({
            color:       0xff0000,
            depthTest:   false,
            transparent: true
        });

        this.wStart = options.wStart;
        this.wStep  = options.wStep;
        this.hStart = options.hStart;
        this.hStep  = options.hStep;

        this.hPrevOffset = 0;
        this.wPrevOffset = 0;

        // specs for modules added manually after array was initially filled
        this.addedSpecs = options.addedSpecs || {};
    }


    setCommonSpecs(specs) { this.moduleSpecs = deepCopy(specs);  }


    getOptimalModuleOffsets() {
        return {
            w: this.wPrevOffset || 0,
            h: this.hPrevOffset || 0
        };
    }


    setSelectedSpecs(modules, specs) {
        var added = this.specsDiff(specs);

        delete added.dynamicMode; // no need to store per module

        for (let m of modules) {
            this.addSpecs(m, added);
        }
    }


    addSpecs(module, addedSpecs) {
        if (module.sid || Object.keys(addedSpecs).length > 0) {
            if (!module.sid)
                module.sid = uuidv4();

            // TODO: use specs_id instead of full specs
            this.addedSpecs[module.sid] = addedSpecs;
        }
    }


    get mode() {
        return this.moduleSpecs.dynamicMode ? 'dynamic' : 'manual';
    }


    set mode(m) {
        if (m === 'dynamic') {
            this.moduleSpecs.dynamicMode = true;
            this.addedSpecs = {};
        } else {
            this.moduleSpecs.dynamicMode = false;
        }
    }


    specsDiff(specs) {
        let diff = this._specsDiff(this.moduleSpecs, specs);

        console.log('specsDiff', diff);

        return diff;
    }

    /** @private */
    _specsDiff(sub, sup) {
        var result = {};

        for (let k in sup) {
            if (typeof sup[k] !== 'object' && sub[k] !== sup[k]) {
                result[k] = sup[k];
            } else if (typeof sup[k] === 'object') {
                let diff = this._specsDiff(sub[k], sup[k]);

                if (Object.keys(diff).length > 0)
                    result[k] = diff;
            }
        }

        return result;
    }


    getAddedSpecs()          { return this.addedSpecs;         }

    getFireSetbacks()        { return this.fireSetbacks;       }
    setFireSetback(i, value) { this.fireSetbacks[i] = value;   }


    getFireSetback(i) {
        var fs = this.fireSetbacks;

        return (i < fs.length && fs[i] !== undefined) ? fs[i] : 0;
    }

    /**
     *
     * @param {import('three').Vector3} origin
     * @param {{centroid: import('three').Vector3, normal: import('three').Vector3}} fitPlane
     */
    setUpPlane(origin, fitPlane) {
        if (this.movingPlane)
            this.remove(this.movingPlane);

        if (this.frame)
            this.remove(this.frame);

        if (this.group)
            this.remove(this.group);

        if (this.groupMove)
            this.scene.remove(this.groupMove);

        var { normal, centroid } = fitPlane;

        // MOVING PLANE
        var geometry = new PlaneGeometry(10000, 10000);
        var plane = new Mesh(geometry, this.material);
        var axis = new Vector3(0, 1, 0);

        plane.position.copy(centroid);
        plane.quaternion.setFromUnitVectors(axis, normal.clone().normalize());
        plane.rotateOnAxis(axis, degToRad(360 - this.moduleSpecs.azimuth));
        plane.rotateX(degToRad(90));
        plane.updateMatrixWorld();

        this.add(plane);
        this.movingPlane = plane;

        /** @type {import('three').Object3D} */
        var frame = new Object3D();
        frame.position.copy(origin);

        // orient the grid to match the polygon
        frame.quaternion.setFromUnitVectors(axis, normal.clone().normalize());
        frame.rotateOnAxis(axis, degToRad(360 - this.moduleSpecs.azimuth));
        frame.updateMatrixWorld();

        this.frame = frame;
        this.add(frame);

        /** @type {import('three').Object3D & {children: SolarPanelModule[]}} */
        this.group = new Object3D();
        this.group.applyMatrix(this.frame.matrix);

        this.add(this.group);

        this.groupMove = new Group();
        this.groupMove.applyMatrix(this.frame.matrix);
        this.scene.add(this.groupMove);
    }


    initModule(dim, specs) {
        var specs = specs ? specs : this.moduleSpecs;

        var planeAngle = this.calculatePlaneAngle();

        var moduleParams = {
            width:        dim.wSingle,
            height:       dim.hSingle,
            moduleType:   specs.moduleType,
            orientation:  specs.orientation
        };

        if (specs.mountType === 'ground') {
            moduleParams.cols        = specs.cols;
            moduleParams.rows        = specs.rows;
            moduleParams.hOffset     = specs.hOffset;
            moduleParams.vOffset     = specs.vOffset;
            moduleParams.orientation = specs.orientation;
        }

        var module = new SolarPanelModule(moduleParams);

        module.setTilt(specs.tilt - planeAngle);

        return module;
    }


    /**
     *
     * @param {{
     *   origin: Vector3,
     *   fitPlane: {centroid: Vector3, normal: Vector3},
     *   vertices: Vector3[],
     *   fireSetback: unknown,
     *   keepouts: (CircleContext | PolyContext | RectContext )[],
     *   placement: unknown,
     *   progressCb?: (progress: number, msg?: string) => void
     * }} param0
     */
    placeAsync({ origin, fitPlane, vertices, fireSetback, keepouts, placement, progressCb }) {
        this.setUpPlane(origin, fitPlane);

        progressCb = progressCb || (() => {});
        var bounds2;

        if (placement === 'manual') {
            bounds2 = (fireSetback !== undefined) ?
                this.toPoly2(fireSetback) :
                this.toPoly2(vertices);
        } else {
            // auto array setback
            let poly2 = this.toPoly2(vertices);
            bounds2 = poly2.shrink(i => this.fireSetbacks[i] || 0);

            this.drawSetbacks({ poly2: bounds2 });
        }

        const cd = new CollisionDetector({
            ref:      this.frame,
            bounds2:  bounds2,
            keepouts: keepouts,
            ma: this
        });
        const planeArea = bounds2.getArea(bounds2.vertices);

        return this.calculateModulePositionsAsync(
            cd,
            planeArea,
            progressCb
        ).then(
            ppos => {
                let { wStart, wStop, wStep,
                    hStart, hStop, hStep,
                    maxCount              } = ppos;

                // position the panels
                if (maxCount >= 0) {
                    let dim = this.getEffectiveModuleDimensions();
                    // If stop/start overlap then reverse them
                    if(hStop < hStart) {
                        let temp = hStop
                        hStop    = hStart
                        hStart   = temp
                    }
                    if(wStop < wStart) {
                        let temp = wStop
                        wStop    = wStart
                        wStart   = temp
                    }

                    for (let h = hStart; h < hStop; h += hStep) {
                        for (let w = wStart; w < wStop; w += wStep) {
                            if (!cd.inBounds(w, h, dim.w, dim.h))
                                continue;

                            const module = this.initModule(dim);
                            const pos = new Vector3(w, this.moduleSpecs.heightOffset, h + dim.h);

                            if (this.moduleSpecs.mountType === 'ground') {
                                var posY0 = new Vector3(w, 0, h + dim.h);
                                var surfaceY = this.findSurfaceHeight(posY0, dim.w);

                                pos.setY(this.moduleSpecs.heightOffset + surfaceY);
                            }

                            module.position.copy(pos);

                            this.group.add(module);
                        }
                    }
                }

                this.updateModuleCount();
            }
        );
    }


    placeSaveData({ modules, origin, fitPlane }) {
        this.setUpPlane(origin, fitPlane);

        for (let m of modules) {
            const specs = this.getIndividualSpecs(m);
            const dim = this.getEffectiveModuleDimensions({ specs });
            const pos = this.group.worldToLocal(new Vector3(m.x, m.y, m.z));

            let module = this.initModule(dim, specs);

            module.sid = m.sid;
            module.position.copy(pos);

            this.group.add(module);
        }

        this.group.updateMatrixWorld();

        this.updateModuleCount();
    }


    updateInPlace(modules) {
        for (let m of modules) {
            const specs = this.getIndividualSpecs(m);
            const dims = this.getEffectiveModuleDimensions({ specs });

            let newModule = this.initModule(dims, specs);

            newModule.position.copy(m.position);
            newModule.sid = m.sid;

            this.group.remove(m);
            this.group.add(newModule);

            if (m === this.currentModule) {
                this.currentModule = newModule;
            }
        }

        this.updateModuleCount(); // count before == count after, but wattage can change
    }


    updateModuleCount() {
        this.moduleCount = this.group.children.length;

        if (this.moduleSpecs.mountType === 'ground')
            this.moduleCount *= this.moduleSpecs.rows * this.moduleSpecs.cols;

        this.totalOutput = this.moduleSpecs.wattage * this.moduleCount;
    }

    /**
     *
     * @param {CollisionDetector} cd
     * @param {number} planeArea
     * @param {((progress: number, msg?: string) => void)?} notify Progress callback
     *   called with current completion percentage of task, and a status message.
     */
    calculateModulePositionsAsync(cd, planeArea, notify) {
        notify = notify || (() => {});
        var dim = this.getEffectiveModuleDimensions();

        var wStep   = dim.wPadded;
        var hStep   = dim.hPadded;
        var moduleW = dim.w;
        var moduleH = dim.h;

        var maxDim = cd.bounds2.getMaxDimensions();

        var maxW = maxDim.width  * 2;
        var maxH = maxDim.height * 2;

        var maxCount = 0;
        var dwBest   = 0;
        var dhBest   = 0;

        if (this.moduleSpecs.dynamicDiff) {
            const w = this.moduleSpecs.dynamicDiff.x;
            const h = this.moduleSpecs.dynamicDiff.z;

            console.log('placing with offset', w, h);

            notify(1);
            return Promise.resolve({
                wStart:   -maxW/2 + w % moduleW,
                wStop:     maxW/2 + w % moduleW,
                hStart:   -maxH/2 + h % moduleH,
                hStop:     maxH/2 + h % moduleH,
                wStep:     wStep,
                hStep:     hStep,
                maxCount:  maxCount
            });
        }

        // current algorithm is too slow to handle large planes
        if (planeArea > 5000) {
            notify(1);
            return Promise.resolve({
                wStart:   -maxW/2 + dwBest,
                wStop:     maxW/2 + dwBest,
                wStep:     wStep,
                hStart:   -maxH/2 + dhBest,
                hStop:     maxH/2 + dhBest,
                hStep:     hStep,
                maxCount:  maxCount
            });
        }

        /**
         * Calculate the grid offset to fit the most panels
         * This is done in an asynchronous fashion by defining a sub-optimizer
         * to find an optimal dw, given dh, which runs in a single context.
         *
         * The sub-optimizer is then pushed onto the execution stack for each
         * valid value of dh.
         *
         * @param {number} deltaH
         */
        const findOptimalParamsForDeltaH = deltaH => {
            let maxCount = 0;
            let deltaWBest = -wStep / 2;

            for (let deltaW = -wStep / 2; deltaW <= wStep / 2; deltaW += wStep / 20) {
                let count = 0;

                for (let h = -maxH / 2 + deltaH; h < maxH / 2 + deltaH; h += hStep) {
                    for (let w = -maxW / 2 + deltaW; w < maxW / 2 + deltaW; w += wStep) {
                        if (cd.inBounds(w, h, moduleW, moduleH)) {
                            ++count;
                        }
                    }
                }

                if (count > maxCount) {
                    maxCount = count;
                    deltaWBest = deltaW;
                }
            }

            return {
                wStart:   -maxW / 2 + deltaWBest,
                wStop:     maxW / 2 + deltaWBest,
                wStep:     wStep,
                hStart:   -maxH / 2 + deltaH,
                hStop:     maxH / 2 + deltaH,
                hStep:     hStep,
                maxCount:  maxCount
            };
        }

        let hStepsCompleted = 0;
        const deltaHRange = rangeArray(-hStep / 2, hStep / 2, hStep / 20);

        /**
         * For each deltaH, determine whether it produces a better positioning
         * than the deltaHs considered thus far.
         * @type {Promise<ReturnType<findOptimalParamsForDeltaH>>} */

        const promiseChain = deltaHRange.reduce(
            async (carry, dh) => {
                const bestSoFar = await carry;

                // Push next iteration off to another tick of the event loop
                const current = await new Promise(
                    r => setTimeout(
                        () => r(findOptimalParamsForDeltaH(dh)),
                        1
                    )
                );

                const retVal = current.maxCount > bestSoFar.maxCount
                    ? current
                    : bestSoFar;

                hStepsCompleted++;
                notify(
                    hStepsCompleted / deltaHRange.length,
                    `Calculating module positions ${hStepsCompleted}/${deltaHRange.length}`
                );

                return retVal;
            },
            Promise.resolve(
                {maxCount: -Infinity, wStart: 0, wStop: 0, wStep: 0, hStart: 0, hStop: 0, hStep: 0}
            )
        );

        return promiseChain.then(
            best => {
                /** deltaHBest */
                this.hPrevOffset = (best.hStart + best.hStop) / 2;

                /** deltaWBest */
                this.wPrevOffset = (best.wStart + best.wStop) / 2;

                return best;
            }
        );
    }


    calculatePlaneAngle() {
        var      a = this.frame.localToWorld(new Vector3(0, 0, 10));
        var origin = this.frame.localToWorld(new Vector3(0, 0, 0));

        a.sub(origin);

        var aProj = new Vector3(a.x, 0, a.z);
        var planeAngle = radToDeg(a.angleTo(aProj));

        return planeAngle;
    }

    /**
     * @param {import('three').Vector3} origin
     * @param {number} moduleWidth
     */
    findSurfaceHeight(origin, moduleWidth) {
        var points = [ origin, origin.clone().setX(origin.x + moduleWidth) ];
        var heights = [];

        for (var i = 0; i < points.length; i++) {
            // find the point on the model above which the module should sit
            var rayOrigin = this.frame.localToWorld(points[i]);
            rayOrigin.setY(this.raycasterY);

            //var geometry = new SphereGeometry( 1, 16, 16 );
            //var material = new MeshBasicMaterial( {color: 0xffff00} );
            //var sphere = new Mesh( geometry, material );
            //sphere.position.copy(rayOrigin);
            //this.scene.add( sphere );

            // raycast down to surface
            this.raycaster.set(rayOrigin, new Vector3(0, -1, 0));

            var results = this.raycaster.intersectObjects(this.meshes, true);

            if (results.length > 0) {
                var localPoint = this.frame.worldToLocal(results[0].point);
                heights.push(localPoint.y);
            }
        }

        return Math.max(heights[0], heights[1]);
    }

    getEffectiveModuleDimensions(options) {
        var specs = (options && options.specs) ? options.specs : this.moduleSpecs;

        // in addition to width and height, we also flip the module offsets because
        // the UI associates vertical with the longer side and horizontal with the
        // shorter side
        let orientation = specs.orientation;

        var wSingle = (orientation === 'portrait') ? specs.width   : specs.height;
        var hSingle = (orientation === 'portrait') ? specs.height  : specs.width;
        var hOffset = (orientation === 'portrait') ? specs.hOffset : specs.vOffset;
        var vOffset = (orientation === 'portrait') ? specs.vOffset : specs.hOffset;

        var w = wSingle;
        var h = hSingle;

        var wPadded = w + hOffset;
        var hPadded = h + vOffset;

        if (specs.mountType === 'ground') {
            w = (w + hOffset) * specs.cols;
            h = (h + vOffset) * specs.rows;

            wPadded = wPadded * specs.cols + specs.hSpacing;
            hPadded = hPadded * specs.rows + specs.vSpacing;
        }

        return {
            wSingle: wSingle,
            hSingle: hSingle,
            w:       w,
            h:       h,
            wPadded: wPadded,
            hPadded: hPadded
        };
    }


    toPoly2(vv) {
        var poly2 = new Polygon({
            vertices: vv.map((v) => {
                var vw = this.frame.worldToLocal(v.clone());
                return new Vector2(vw.x, vw.z);
            })
        });

        return poly2;
    }


    movingPlaneMaxDims() {
        var xx = this.movingPlane.geometry.vertices.map(v => v.x);
        var zz = this.movingPlane.geometry.vertices.map(v => v.z);

        var maxX = Math.max.apply(Math, xx);
        var maxZ = Math.max.apply(Math, zz);
        var minX = Math.min.apply(Math, xx);
        var minZ = Math.min.apply(Math, zz);

        return {
            width:  maxX - minX,
            height: maxZ - minZ
        };
    }


    drawSetbacks(options) {
        var bounds2;

        if (options.vertices) {
            let poly2 = this.toPoly2(options.vertices);
            bounds2 = poly2.shrink(i => this.fireSetbacks[i] || 0);
        } else {
            bounds2 = options.poly2;
        }

        if (this.setbackOutline !== undefined)
            this.frame.remove(this.setbackOutline);

        var g = new Geometry();

        for (let v of bounds2.vertices)
            g.vertices.push(new Vector3(v.x, 0, v.y));

        g.vertices.push(g.vertices[0].clone());

        var line = new Line(g, this.materialSetback);

        // prevent line from disappearing due to frustum culling
        line.frustumCulled = false;

        var outline = new Object3D();
        outline.add(line);

        this.frame.add(outline);
        this.setbackOutline = outline;
    }


    insertSetback(index, copyIndex) {
        this.fireSetbacks.splice(index, 0, this.getFireSetback(copyIndex));
    }


    showSetbacks(show) {
        if (show !== false && this.setbackOutline) {
            this.frame.add(this.setbackOutline);
        } else if (this.setbackOutline) {
            this.frame.remove(this.setbackOutline);
        }
    }


    getSetbackLines() {
        var lines = [];

        if (this.setbackOutline) {
            let vv = this.setbackOutline.children[0].geometry.vertices.map((v) => {
                return this.frame.localToWorld(v.clone());
            });

            lines.push(...Draw.exportLineGeometry(vv));
        }

        return lines;
    }


    getModules()    { return this.group.children;                           }
    getVertices()   { return this.group.children.map(m => m.getVertices()); }
    getGeometries() { return this.group.children.map(m => m.getGeometry()); }


    *getPolylines() {
        for (const module of this.getModules()) {
            yield module.getPolyline();
        }
    }


    getVerticesExcept(ignoreM)   {
        let results = []
        this.group.children.forEach( (m) => {
            if(ignoreM !== m) {
                results.push(m.getVertices())
            }
        })
        return results
    }


    getModuleMeshes() {
        let meshes = [];

        if (this.moduleSpecs.mountType === "ground") {
            this.group.children.forEach( gmArray => {
                gmArray.children[0].children[0].children.forEach( mesh => {
                    meshes.push(mesh)
                })
            })
        } else {
            this.group.children.forEach( m => {
                meshes.push(m.mesh)
            })
        }

        return meshes;
    }


    updateModulePositions() {
        var g  = this.group;
        var gm = this.groupMove;

        var vDelta = g.worldToLocal(gm.position.clone());
        var selected = gm.children.slice();

        for (let s of selected) {
            s.position.add(vDelta);

            g.add(s);
        }

        this.scene.remove(gm);

        gm = new Group();
        gm.applyMatrix(g.matrixWorld);

        this.scene.add(gm);
        this.groupMove = gm;
    }


    getIndividualSpecs(module) {
        var specs = deepCopy(this.moduleSpecs);

        if (module.sid && this.addedSpecs[module.sid]) {
            specs = Object.assign(specs, this.addedSpecs[module.sid]);
        }

        return specs;
    }


    finalizeModule() {
        this.currentModule = undefined;

        this.placement = 'manual';

        this.updateModuleCount();
    }


    checkConflicts() {
        var mm = [];

        for (let m of this.group.children) {
            let specs = this.getIndividualSpecs(m);
            let dims = this.getEffectiveModuleDimensions({ specs: specs });

            // Add buffer for module conflicts, to avoid showing conflict when spacing=0
            let buf = 0.0025;

            mm.push({
                m: m,
                x: m.position.x + buf,
                y: m.position.z + buf,
                w: dims.w - buf*2,
                h: dims.h - buf*2
            })
        }

        var conflictingModules = [];
        var conflictSetUUIDs = new Set([]);

        for (let m1 of mm) {
            for (let m2 of mm) {
                if (m1.m.uuid !== m2.m.uuid) {
                    let l1x = m1.x;
                    let l1y = m1.y - m1.h;
                    let r1x = m1.x + m1.w;
                    let r1y = m1.y;

                    let l2x = m2.x;
                    let l2y = m2.y - m2.h;
                    let r2x = m2.x + m2.w;
                    let r2y = m2.y;

                    let overlap = true;

                    if (l1x >= r2x || l2x >= r1x)
                        overlap = false;

                    if (r1y <= l2y || r2y <= l1y)
                        overlap = false;

                    let m = m1.m;

                    if (overlap) {
                        conflictingModules.push(m)
                        conflictSetUUIDs.add(m.uuid)
                        m.conflictSelect();
                    } else {
                        if(!conflictSetUUIDs.has(m.uuid)) {
                            m.conflictDeselect();
                        }
                    }
                }
            }
        }

        return conflictingModules;
    }


    removeModule(m) {
        this.group.remove(m);

        if (m.sid) {
            delete this.addedSpecs[m.sid];
        }
    }
}


export { SolarPanelModuleArray };
