


class Validator {
    static identity       (val) { return val; };
    static valid          (val) { return true; };
    static validNumber    (val) { return !isNaN(val); };
    static positiveNumber (val) { return !isNaN(val) && val >= 0; };


    static create(options) {
        options = options || {};

        var sanitize    = options.sanitize    || this.identity;
        var isValid     = options.isValid     || this.valid;
        var convertFrom = options.convertFrom || this.identity;
        var convertTo   = options.convertTo   || this.identity;

        var updateState, resetState;

        if (options.target && options.name) {
            updateState = this._update(options.target, options.name);
            resetState  =  this._reset(options.target, options.name);
        } else {
            updateState = options.updateState;
            resetState  = options.resetState;
        }

        return function (val, $input) {
            var sanitized = sanitize(val);

            var result = isValid(sanitized) ?
                updateState(convertFrom(sanitized), $input) :
                resetState($input);

            return convertTo(result);
        };
    }

    static _update(o, name) {
        return function (val) {
            let s = o.getState();
            s[name] = val;

            o.updateState(s);

            return val;
        };
    }

    static _reset(o, name) {
        return function () { return o.getState()[name]; };
    }
}



export { Validator };