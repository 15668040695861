import { PolygonDrawer } from '../libs/PolygonDrawer';
import { LineBasicMaterial, Plane, Vector3} from '../libs/three.module';



var AreaAndExcavate = function () {
    PolygonDrawer.call(this);

    this.material = new LineBasicMaterial({
        color:       0x31ef78,
        linewidth:   3,
        transparent: true
    });

    this.unitTypes = { METERS: 0, FEET: 1, YARDS: 2 };

    this.areaUnits   = this.unitTypes.METERS;
    this.volumeUnits = this.unitTypes.METERS;

    this.depth  = 0;
    this.area   = 0;
    this.volume = 0;
};

AreaAndExcavate.prototype = Object.create(PolygonDrawer.prototype);
AreaAndExcavate.prototype.constructor = AreaAndExcavate;

AreaAndExcavate.prototype.setUpdateGui = function (callback) { this.updateGui = callback; };

AreaAndExcavate.prototype.getArea = function () {
    if (this.areaUnits === this.unitTypes.METERS) {
        return this.area;
    } else {
        return this.area * 10.7639; // square feet
    }
};

AreaAndExcavate.prototype.getVolume = function () {
    if (this.volumeUnits === this.unitTypes.METERS) {
        return this.volume;
    } else {
        return this.volume * 1.30795; // cubic yards
    }
};

AreaAndExcavate.prototype.getAreaUnits   = function ()      { return this.areaUnits; };
AreaAndExcavate.prototype.setAreaUnits   = function (units) { this.areaUnits = units; };
AreaAndExcavate.prototype.getVolumeUnits = function ()      { return this.volumeUnits; };
AreaAndExcavate.prototype.setVolumeUnits = function (units) { this.volumeUnits = units; };
AreaAndExcavate.prototype.getDepth       = function ()      { return this.depth; };

AreaAndExcavate.prototype.setDepth = function (value) {
    this.depth = value;

    if (this._isClosedLoop()) {
        this.calculatePolygonArea();
        this.calculateExcavationVolume();
        this.updateGui();
    }
};

AreaAndExcavate.prototype.closedLoop = function () {
    this.calculatePolygonArea();
    this.calculateExcavationVolume();
    this.updateGui();
};

// TODO: refactor into a separate function that can be invoked from elsewhere
AreaAndExcavate.prototype.calculatePolygonArea = function () {
    if (this.segments.length < 3) {
        this.area = 0;
        return this.area;
    }

    var total = new Vector3(0, 0, 0);

    for (var i = 0; i < this.segments.length; i++) {
        var v1 = this.segments[i].geometry.vertices[0];
        var v2 = this.segments[i].geometry.vertices[1];

        var prod = new Vector3(0, 0, 0);
        prod.crossVectors(v1, v2);

        total.add(prod);
    }

    var plane = new Plane();
    plane.setFromCoplanarPoints(this.segments[0].geometry.vertices[0],
                                this.segments[0].geometry.vertices[1],
                                this.segments[1].geometry.vertices[1]);

    this.area = 0.5 * Math.abs(total.dot(plane.normal));

    return this.area;
};

AreaAndExcavate.prototype.calculateExcavationVolume = function () {
    this.volume = this.area * this.depth * 0.0254; // depth is in inches
};

AreaAndExcavate.prototype.clear = function () {
    PolygonDrawer.prototype.clear.call(this);

    this.scene.remove(this.fillMesh);
    this.area = 0;
    this.volume = 0;

    this.updateGui();
};



export { AreaAndExcavate };
