
import { interpolateArc, azElToCoord, degToRad } from '../libs/Geometry';
import { calculateSunPositionSunCalcDate } from './Irradiation';
import { SunCalc } from './suncalc';
import * as THREE from '../libs/three.module';
import * as d3 from 'd3';





class ViewshedOverlay {

    constructor() {
        this.color1 = '#777';
        this.color2 = '#d9d9d9';
        this.color3 = '#c9c9c9';
        this.line1Width = '0.25pt';
    }


    calculate(lat, lng, year, offset) {
        this.lat = lat;
        this.lng = lng;
        this.year = year;
        this.offset = offset;

        this.sunPositionsDate = [];
        this.sunPositionsHour1 = [];
        this.sunPositionsHour2 = [];

        for (var t = 0; t < 24; t++) {
            this.sunPositionsHour1.push([]);
            this.sunPositionsHour2.push([]);
        }

        var period = 900000; // milliseconds (15 minutes)

        for (var m = 1; m <= 12; m++) {
            var month = { month: m, pos: [] };

            var times = SunCalc.getTimes(new Date(Date.UTC(year, m - 1, 1, -offset)), lat, lng);

            var sunrise = times.sunrise.getTime(); // in milliseconds since January 1, 1970
            var sunset  = times.sunset .getTime();

            // sunrise to sunset in equal increments
            var start = Math.round(sunrise / period) * period - period;
            var end   = Math.round( sunset / period) * period + period;

            for (var t = start; t <= end; t += period) {
                var d = new Date(t);
                var position = calculateSunPositionSunCalcDate(lat, lng, d);

                position.hoursUTC   = d.getUTCHours();
                position.minutesUTC = d.getUTCMinutes();

                month.pos.push(position);

                if (d.getMinutes() == 0) {
                    var bin = (m > 6) ? this.sunPositionsHour2 : this.sunPositionsHour1;
                    bin[(24 + d.getUTCHours() + offset) % 24].push(position);

                    var d2 = new Date(t + 1209600000); // add 14 days to get position on the 15th
                    var position = calculateSunPositionSunCalcDate(lat, lng, d2);
                    bin[(24 + d2.getUTCHours() + offset) % 24].push(position);
                }
            }

            this.sunPositionsDate.push(month);
        }

        // make the both halves of the analemmas meet at the bottom
        for (var i = 0; i < this.sunPositionsHour2.length; i++) {
            if (this.sunPositionsHour2[i].length > 0) {
                var endPos = this.sunPositionsHour1[i][0];

                if (endPos !== undefined) {
                    this.sunPositionsHour2[i].push(endPos);
                }
            }
        }
    }


    /**
     * Get the coordinates of the polygon bounding all possible positions of the
     * sun in the sky throughout the year for an image of radius r, with (0, 0) in
     * the top left corner.
     */
    getBounds(r) {
        var polygon = [];

        var origin = new THREE.Vector2(0, 0);

        var jan = this.sunPositionsDate[0].pos;
        var jul = this.sunPositionsDate[6].pos.slice().reverse();

        for (var i = 0; i < jan.length; i++) {
            polygon.push(azElToCoord(origin, r, jan[i]));
        }

        interpolateArc(origin, r, jan[jan.length - 1].azimuth, jul[0].azimuth, polygon);

        for (var i = 0; i < jul.length; i++) {
            polygon.push(azElToCoord(origin, r, jul[i]));
        }

        interpolateArc(origin, r, jan[0].azimuth, jul[jul.length - 1].azimuth, polygon);

        var coords = [];
        for (var i = 0; i < polygon.length; i++) {
            coords.push(polygon[i].x - r, -polygon[i].y - r);
        }

        return coords;
    }


    getSVG(image) {
        var round = function (val) {
            return Math.round(val * 100) / 100;
        };

        var r = 100;
        var vb = r * ViewshedOverlay.SVG_RATIO;
        var fontSize = round(vb / 60);
        var scaleFactor = 1.02;

        var svg = d3.select('body').append('svg')
            .remove() // we'll attach it to the DOM later
            .attr('xmlns',        'http://www.w3.org/2000/svg')
            .attr('xmlns:xlink',  'http://www.w3.org/1999/xlink')
            .attr('viewBox',      '-' + vb/2 + ' -' + vb/2 + ' ' + vb + ' ' + vb)
            .attr('width',        '1.23456789in')
            .attr('height',       '1.23456789in')
            .attr('font-size',    fontSize + 'px')
            .attr('font-family',  'Helvetica, Arial, sans-serif')
            ;

        // inner ring
        svg.append("circle")
            .attr('cx',           0)
            .attr('cy',           0)
            .attr('r',            r)
            .attr('fill',         'none')
            .attr('stroke',       this.color3)
            .attr('stroke-width', '0.3pt')
            ;

        // outer ring
        svg.append("circle")
            .attr('cx',           0)
            .attr('cy',           0)
            .attr('r',            r * scaleFactor)
            .attr('fill',         'none')
            .attr('stroke',       this.color3)
            .attr('stroke-width', '0.3pt')
            ;

        var angleMarkings = svg.append('g')
            .attr('stroke', this.color3)
            ;

        var angles = [];
        for (var i = 0; i < 360; i += 5) {
            angles.push(i);
        }

        angleMarkings.selectAll('line')
            .data(angles)
            .enter()
            .append('line')
            .attr('x1', function (d) { return round(r * Math.cos(degToRad(d))); })
            .attr('y1', function (d) { return round(r * Math.sin(degToRad(d))); })
            .attr('x2', function (d) {
                var l = r * scaleFactor;

                if (d % 15 == 0) {
                    l *= scaleFactor;
                }

                return round(l * Math.cos(degToRad(d)));
            })
            .attr('y2', function (d) {
                var l = r * scaleFactor;

                if (d % 15 == 0) {
                    l *= scaleFactor;
                }

                return round(l * Math.sin(degToRad(d)));
            })
            ;

        // angle guides
        var angleGuides = [];
        var startR = r * Math.cos(degToRad(80));

        for (var i = 0; i < 360; i += 15) {
            angleGuides.push({ angle: i, startR: (i % 90 == 0) ? 0 : startR });
        }

        var angleLines = angleMarkings.append('g')
            .attr('stroke',           this.color2)
            .attr('stroke-width',     this.line1Width)
            .attr('stroke-dasharray', '5,5')
            .attr('fill',             'none')
            ;

        angleLines.selectAll('.ag')
            .data(angleGuides)
            .enter()
            .append('line')
            .attr('class', 'ag')
            .attr('x1',    function (d) { return round(r * Math.cos(degToRad(d.angle))); })
            .attr('y1',    function (d) { return round(r * Math.sin(degToRad(d.angle))); })
            .attr('x2',    function (d) { return round(d.startR * Math.cos(degToRad(d.angle))); })
            .attr('y2',    function (d) { return round(d.startR * Math.sin(degToRad(d.angle))); })
            ;

        var textR = r * 1.09;

        var angleLabels = svg.append('g')
            .attr('fill',         this.color1)
            .style('text-anchor', 'middle')
            ;

        angleLabels.selectAll('text')
            .data(angles)
            .enter()
            .append('text')
            .attr('x',            function (d) { return round(textR * Math.cos(degToRad(d - 90))); })
            .attr('y',            function (d) { return round(textR * Math.sin(degToRad(d - 90))); })
            .attr('dy',           '.35em')
            .text(function(d) { return (d % 15 == 0) ? d + '°': null; })
            ;

        var directions = [ [-3, 'N'], [87, 'E'], [183, 'S'], [273, 'W'] ];

        svg.selectAll('.dir')
            .data(directions)
            .enter()
            .append('text')
            .attr('class',        'dir')
            .attr('x',            function (d) { return round(textR * Math.cos(degToRad(d[0] - 90))); })
            .attr('y',            function (d) { return round(textR * Math.sin(degToRad(d[0] - 90))); })
            .attr('dy',           '.35em')
            .attr('fill',         '#3663c8')
            .attr('font-weight',  'bold')
            .style('text-anchor', 'middle')
            .text(function(d) { return d[1]; })
            ;

        // altitude angle markings
        var altAngles = [];

        for (var i = 20; i <= 80; i += 10) {
            altAngles.push(i);
        }

        // altitude circles
        svg.selectAll('.ac')
            .data(altAngles)
            .enter()
            .append('circle')
            .attr('class',            'ac')
            .attr('cx',               0)
            .attr('cy',               0)
            .attr('r',                function (d) { return round(r * Math.cos(degToRad(d))); })
            .attr('fill',             'none')
            .attr('stroke',           this.color2)
            .attr('stroke-width',     this.line1Width)
            .attr('stroke-dasharray', '5,5')
            ;

        // altitude labels
        svg.selectAll('.al')
            .data(altAngles)
            .enter()
            .append('text')
            .attr('class',        'al')
            .attr('x',            0)
            .attr('y',            function (d) { return round(r * Math.cos(degToRad(d))); })
            .attr('dy',           '-.35em')
            .attr('fill',         this.color1)
            .style('text-anchor', 'middle')
            .text(function (d) { return d + '°'; })
            ;

        // date lines
        var dateLine = d3.svg.line()
            .x(function(d) { return round(r * Math.cos(degToRad(d.elevation)) * Math.cos(degToRad(d.azimuth - 90))); })
            .y(function(d) { return round(r * Math.cos(degToRad(d.elevation)) * Math.sin(degToRad(d.azimuth - 90))); })
            //.interpolate("basis")
            ;

        //svg.selectAll('.date_line')
        //    .data(this.sunPositionsDate)
        //    .enter()
        //    .append("path")
        //    .attr("d", function (d) { return dateLine(d.pos); })
        //    .attr("class", "date_line")
        //    .attr('fill', 'none')
        //    .attr('stroke', 'steelblue')
        //    .attr('stroke-width', '0.15%')
        //    .attr('stroke-dasharray', function (d) { return (d.month > 6) ? '5,5' : 'none'; })
        //    ;

        var polygons = this.getQuarterHourPolygons(r);
        var rect = d3.svg.line().x(function(d) { return round(d.x); }).y(function(d) { return round(d.y); });

        var hourTicks = svg.append('g')
            .attr('fill',         'none')
            .attr('stroke',       '#ffa0a0')
            .attr('stroke-width', '0.5pt')
            ;

        for (var m = 0; m < polygons.length; m++) {
            if (m == 6) {
                hourTicks = svg.append('g')
                    .attr('fill',         'none')
                    .attr('stroke',       '#ffd4d4')
                    .attr('stroke-width', '0.5pt')
                    ;
            }

            hourTicks.selectAll('.ht' + m)
                .data(polygons[m])
                .enter()
                .append("path")
                .attr('d',      function (d) { return rect(d.polygon); })
                .attr("class",  'ht' + m)
                ;
        }

        // show which quarter hour polygons are considered shaded
        var pixels = this.fill(r, polygons, image, 600);

        var shadedPolygons = svg.append('g')
            .attr('fill', 'green')
            ;

        shadedPolygons.selectAll('.sp')
            .data(pixels)
            .enter()
            .append('circle')
            .attr('class', 'sp')
            .attr('r',  0.5)
            .attr('cx', function (d) { return round(d.x); })
            .attr('cy', function (d) { return round(d.y); })
            ;

        var analemmas = svg.append('g')
            .attr('fill',         'none')
            .attr('stroke',       '#8686ff')
            .attr('stroke-width', '0.5pt')
            ;

        analemmas.selectAll('.an1')
            .data(this.sunPositionsHour1)
            .enter()
            .append('path')
            .attr('d',     function (d) { return dateLine(d); })
            .attr('class', 'an1')
            ;

        analemmas.selectAll('.an2')
            .data(this.sunPositionsHour2)
            .enter()
            .append('path')
            .attr('d',                function (d) { return dateLine(d); })
            .attr('class',            'an2')
            .attr('stroke-dasharray', '5,5')
            ;

        return svg;
    }


    getHorizonSVG() {
        var round = (x) => Math.round(x * 1000) / 1000;

        var r = 100;
        var vb = r * ViewshedOverlay.SVG_RATIO;
        var fontSize = round(vb / 60);

        var svg = d3.select('body').append('svg')
            .remove() // we'll attach it to the DOM later
            .attr('xmlns',        'http://www.w3.org/2000/svg')
            .attr('xmlns:xlink',  'http://www.w3.org/1999/xlink')
            .attr('viewBox',      '-' + vb/2 + ' -' + vb/2 + ' ' + vb + ' ' + vb)
            .attr('width',        '1.23456789in')
            .attr('height',       '1.23456789in')
            .attr('font-size',    fontSize + 'px')
            .attr('font-family',  'Helvetica, Arial, sans-serif');

        // outer border
        svg.append('rect')
            .attr('x',             -r)
            .attr('y',             -r)
            .attr('width',        2*r)
            .attr('height',       2*r)
            .attr('fill',         'none')
            .attr('stroke',       this.color3)
            .attr('stroke-width', '0.3pt');

        // azimuth grid lines
        for (let x = 1; x < 8; x++) {
            let lx = -r + (2*r/8 * x);

            svg.append('line')
                .attr('x1',    lx)
                .attr('y1',    -r)
                .attr('x2',    lx)
                .attr('y2',    r)
                .attr('fill',         'none')
                .attr('stroke',       this.color2)
                .attr('stroke-width', '0.3pt')
                .attr('stroke-dasharray', '3,2');
        }

        // elevation grid lines
        for (let y = 1; y <  9; y++) {
            let ly = -r + (2*r/9 * y);

            svg.append('line')
                .attr('x1',    -r)
                .attr('y1',    ly)
                .attr('x2',    r)
                .attr('y2',    ly)
                .attr('fill',         'none')
                .attr('stroke',       this.color2)
                .attr('stroke-width', '0.3pt')
                .attr('stroke-dasharray', '3,2');
        }

        // azimuth markings
        for (let az = 0; az <= 360; az += 45) {
            let tx = -r + (2*r * az/360);

            svg.append('text')
                .attr('x', tx)
                .attr('y', -r - 2)
                .attr('text-anchor', 'middle')
                .attr('fill',       this.color1)
                .text(az);
        }

        // elevation markings
        for (let el = 0; el <= 90; el += 10) {
            let ty = r - (2*r * el/90);

            svg.append('text')
                .attr('x', -r - 5)
                .attr('y', ty)
                .attr('dy', '.35em')
                .attr('text-anchor', 'middle')
                .attr('fill',       this.color1)
                .text(el);
        }

        // cardinal direction markings
        var dir = ['North', 'East', 'South', 'West', 'North'];

        for (let i = 0; i < dir.length; i++) {
            let tx = -r + (2*r * i / (dir.length - 1));

            svg.append('text')
                .attr('x', tx)
                .attr('y', r + 5)
                .attr('text-anchor', 'middle')
                .attr('fill',       this.color1)
                .text(dir[i]);
        }

        // x axis label
        svg.append('text')
            .attr('x', 0)
            .attr('y', -r - 10)
            .attr('text-anchor', 'middle')
            .attr('fill',       this.color1)
            .text('Azimuth (degrees)');

        // y axis label
        svg.append('text')
            .attr('x', 0)
            .attr('y', -r - 10)
            .attr('transform', 'rotate(-90)')
            .attr('text-anchor', 'middle')
            .attr('fill',       this.color1)
            .text('Elevation (degrees)');

        // point where line segment defined by p1 and p2 crosses the 0 elevation line
        // (not Elon Musk's son's name)
        let x0El = (p1, p2) => {
            let k = (p2.azimuth - p1.azimuth) / (p2.elevation - p1.elevation);

            return {
                elevation: 0,
                azimuth:   p1.azimuth - p1.elevation * k
            };
        }

        // neatly cut a chain of line segments at 0 elevation
        let cut = (points) => {
            var result = [];

            for (let i = 0; i < points.length - 1; i++) {
                let p1 = points[i];
                let p2 = points[i+1];

                if (p1.elevation >= 0 && p2.elevation > 0 || p1.elevation > 0 && p2.elevation >= 0) {
                    // above horizon
                    result.push(p1);
                } else if (p1.elevation <= 0 && p2.elevation <= 0) {
                    // below horizon
                    continue;
                } else { // crosses horizon
                    if (p1.elevation > 0)
                        result.push(p1);

                    result.push(x0El(p1, p2));
                }
            }

            return result;
        }

        var lSunPath = d3.svg.line()
            .x((d) => round(-r + 2*r * d.azimuth/360))
            .y((d) => round( r - 2*r * d.elevation/90));

        var analemmas = svg.append('g')
            .attr('fill',         'none')
            .attr('stroke',       '#8686ff')
            .attr('stroke-width', '0.5pt');

        analemmas.selectAll('.an1')
            .data(this.sunPositionsHour1.map((d) => cut(d))).enter()
            .append('path')
            .attr('class', 'an1')
            .attr('d', lSunPath);

        analemmas.selectAll('.an2')
            .data(this.sunPositionsHour2.map((d) => cut(d))).enter()
            .append('path')
            .attr('class', 'an2')
            .attr('d', lSunPath)
            .attr('stroke-dasharray', '2,3');

        // top and bottom lines around analemmas
        let minEls = [], maxEls = [];

        for (let h = 0; h < 24; h++) {
            let maxEl = { elevation:  0 };
            let minEl = { elevation: 90 };

            let mmf = (d) => {
                if (d.elevation > maxEl.elevation) {
                    maxEl.azimuth   = d.azimuth;
                    maxEl.elevation = d.elevation;
                }

                if (d.elevation < minEl.elevation) {
                    minEl.azimuth   = d.azimuth;
                    minEl.elevation = d.elevation;
                }
            };

            this.sunPositionsHour1[h].forEach(mmf);
            this.sunPositionsHour2[h].forEach(mmf);

            if (minEl.azimuth !== undefined)
                minEls.push(minEl);

            if (maxEl.azimuth !== undefined)
                maxEls.push(maxEl);
        }

        let len = maxEls.length;
        let p0 = x0El(maxEls[0], maxEls[1]);
        let pN = x0El(maxEls[len - 2], maxEls[len - 1]);

        maxEls = [p0, ...maxEls, pN];

        var analemmaEdges = svg.append('g')
            .attr('fill',         'none')
            .attr('stroke',       '#ccc')
            .attr('stroke-width', '0.3pt');

        analemmaEdges.selectAll('.maxEl')
            .data([ maxEls ]).enter()
            .append('path')
            .attr('class', 'maxEl')
            .attr('d', lSunPath.interpolate('cardinal'));

        analemmaEdges.selectAll('.minEl')
            .data([ cut(minEls) ]).enter()
            .append('path')
            .attr('class', 'minEl')
            .attr('d', lSunPath.interpolate('cardinal'));

        return svg;
    }


    getQuarterHourPolygons(r) {
        var polygons = [];
        var size = r * 0.01;

        for (var i = 0; i < this.sunPositionsDate.length; i++) {
            var month = [];

            for (var j = 0; j < this.sunPositionsDate[i].pos.length - 1; j++) {
                var az1 = degToRad(this.sunPositionsDate[i].pos[j].azimuth - 90);
                var el1 = degToRad(this.sunPositionsDate[i].pos[j].elevation);
                var az2 = degToRad(this.sunPositionsDate[i].pos[j + 1].azimuth - 90);
                var el2 = degToRad(this.sunPositionsDate[i].pos[j + 1].elevation);

                var x1 = r * Math.cos(el1) * Math.cos(az1);
                var y1 = r * Math.cos(el1) * Math.sin(az1);
                var x2 = r * Math.cos(el2) * Math.cos(az2);
                var y2 = r * Math.cos(el2) * Math.sin(az2);

                month.push({
                    hoursUTC:   this.sunPositionsDate[i].pos[j + 1].hoursUTC,
                    minutesUTC: this.sunPositionsDate[i].pos[j + 1].minutesUTC,
                    polygon:    [
                        { x: x1, y: y1 - size },
                        { x: x1, y: y1 + size },
                        { x: x2, y: y2 + size },
                        { x: x2, y: y2 - size },
                        { x: x1, y: y1 - size }
                    ]
                });
            }

            polygons.push(month);
        }

        return polygons;
    }


    findShadedTimes(r, polygonData, image, imageWidth) {
        var times = [];

        for (var m = 0; m < polygonData.length; m++) {
            var month = [];

            for (var p = 0; p < polygonData[m].length; p++) {
                if (this.polygonIsShaded(r, polygonData[m][p].polygon, image, imageWidth, 3)) {
                    month.push({
                        hoursUTC:   polygonData[m][p].hoursUTC,
                        minutesUTC: polygonData[m][p].minutesUTC
                    });
                }
            }

            times.push(month);
        }

        return times;
    }


    fill(r, polygonData, image, imageWidth) {
        var pixels = [];

        for (var m = 0; m < polygonData.length; m++) {
            for (var p = 0; p < polygonData[m].length; p++) {
                var shaded = [];

                var polygon = polygonData[m][p].polygon;
                var start = {
                    x: (polygon[0].x + polygon[1].x + polygon[2].x) / 3,
                    y: (polygon[0].y + polygon[1].y + polygon[2].y) / 3
                };
                this.findShadedPixels(shaded, [], r, start, polygon, image, imageWidth);

                pixels = pixels.concat(shaded);
            }
        }

        return pixels;
    }


    polygonIsShaded(r, polygon, image, imageWidth, threshold) {
        var start = {
            x: (polygon[0].x + polygon[1].x + polygon[2].x) / 3,
            y: (polygon[0].y + polygon[1].y + polygon[2].y) / 3
        };

        var shaded = [];
        this.findShadedPixels(shaded, [], r, start, polygon, image, imageWidth);

        return shaded.length < threshold ? false : true;
    }


    findShadedPixels(shaded, visited, r, v, polygon, image, imageWidth, threshold) {
        if (this.inPolygon(polygon, v) && !visited.find(this.findPixel(v))) {
            visited.push(v);

            var ix = Math.floor((v.x + r) * imageWidth/2 / r);
            var iy = imageWidth - Math.floor((v.y + r) * imageWidth/2 / r); // flip the y
            var color = this.getColor(image, imageWidth, ix, iy);

            if (    !(color.r == 255 && color.g == 255 && color.b == 255) &&
                    !(color.r == 255 && color.b == 255)) {
                shaded.push(v);

                if (threshold && shaded.length >= threshold)
                    return;
            }

            if (v.y > -r) this.findShadedPixels(shaded, visited, r, { x: v.x,     y: v.y - 1 }, polygon, image, imageWidth, threshold);
            if (v.y <  r) this.findShadedPixels(shaded, visited, r, { x: v.x,     y: v.y + 1 }, polygon, image, imageWidth, threshold);
            if (v.x > -r) this.findShadedPixels(shaded, visited, r, { x: v.x - 1, y: v.y },     polygon, image, imageWidth, threshold);
            if (v.x <  r) this.findShadedPixels(shaded, visited, r, { x: v.x + 1, y: v.y },     polygon, image, imageWidth, threshold);
        }
    }


    inPolygon(polygon, v) {
        var wn = 0; // winding number counter

        for (var i = 0; i < polygon.length - 1; i++) {
            if (polygon[i].y <= v.y) {
                if (polygon[i+1].y > v.y) {
                    if ((this.isLeft(polygon[i], polygon[i+1], v) - 0.0) > 0.0) {
                        wn++;
                    }
                }
            } else {
                if (polygon[i+1].y <= v.y) {
                    if ((this.isLeft(polygon[i], polygon[i+1], v) + 0.0) < 0.0) {
                        wn--;
                    }
                }
            }
        }

        return wn;
    }


    isLeft(line0, line1, v) {
        return ((line1.x - line0.x) * (v.y - line0.y) - (v.x - line0.x) * (line1.y - line0.y));
    }


    findPixel(v) {
        return function (p) {
            return (Math.abs(p.x - v.x) < 0.01) && (Math.abs(p.y - v.y) < 0.01);
        };
    }


    getColor(image, w, x, y) {
        return {
            r: image[(w * y + x) * 4],
            g: image[(w * y + x) * 4 + 1],
            b: image[(w * y + x) * 4 + 2]
        };
    }
}


ViewshedOverlay.SVG_RATIO = 2.5; // the ratio of total svg width to sungraph radius



export { ViewshedOverlay };
