

import tippy from 'tippy.js';

class ShadingValuesGui {
    constructor(options) {
        this.options = options;
        this.mini = options.mini;
        this.getState = options.getState;
    }


    renderState(pid) {
        let s = this.getState();

        if (pid === undefined)
            pid = s.array.pid;

        let averages = s.viewsheds.averages[pid];

        let solarAccess = (averages.solarAccess > 0) ? (Math.round(averages.solarAccess * 100) / 100) + '%' : '--';
        let tof         = (        averages.TOF > 0) ? (Math.round(averages.TOF         * 100) / 100) + '%' : '--';
        let tsrf        = (       averages.TSRF > 0) ? (Math.round(averages.TSRF        * 100) / 100) + '%' : '--';
        let t;

        if(this.mini){
            t =  `
            <div class="shading-values-mini">
                <p data-tippy-content="Annual Solar Access">ASA: <span class="shading-value">${solarAccess}</span></p>
                <p data-tippy-content="Tilt & Orientaion Factor">TOF: <span class="shading-value">${tof}</span></p>
                <p data-tippy-content="Total Solar Resource Fraction">TSRF: <span class="shading-value">${tsrf}</span></p>
            </div>
            `;
        }
        else {
            t = `
                <table class="shading-values">
                    <tr>
                        <td>Annual Solar Access (ASA):</td>
                        <td class="number shading-value">${solarAccess}</td>
                    </tr>
                    <tr>
                        <td>Tilt &amp; Orientation Factor (TOF):</td>
                        <td class="number shading-value">${tof}</td>
                    </tr>
                    <tr>
                        <td>Total Solar Resource Fraction (TSRF):</td>
                        <td class="number shading-value">${tsrf}</td>
                    </tr>
                </table>
            `;
        }

        $(this.options.selector).html(t);

        tippy('.shading-values-mini [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'top',
        });
    }

}

export { ShadingValuesGui };
