import { project, saves, currentDesign, designs } from '../Viewer.js';
import { Net } from '../libs/Net';
import { Saves, saveDesign, updateSaveStatusCb } from './Saves';
import { Config } from '../../bootstrap/Config';

class Versions {
    constructor() { }

    registerPropagateState (callback)  { this.propagateState = callback; }

    cmd(command, val) {
        if (command === 'save') {
            this.createDesign(val);
        } else if (command === 'load') {
            this.loadDesign(val);
        } else if (command === 'delete') {
            this.deleteCurrentDesign();
        } else if (command === 'startOver') {
            var url = window.location.href;

            url += (url.indexOf('?') > -1) ? '&' : '?';
            url += 'l=0';

            window.location.href = url;
        } else if (command === 'rename') {
            this.renameDesign(val);
        }
    }

    createDesign(name) {
        Net.createProjectDesign(project.id, name).then((newDesign) => {
            return saveDesign(newDesign.id, pct => updateSaveStatusCb(pct));
        }).then((design) => {
            this.loadDesign(design.id);
        }).done();
    }

    loadDesign(designId) {
        window.top.location.href = `${Config.portalURL}/projects/${project.id}/${designId}/viewer`;
    }

    renameDesign(newName) {
        Net.updateProjectDesign(currentDesign.id, {
            name: newName
        }).then(() => {
            currentDesign.name = newName;

            this.propagateState();
        }).done();
    }

    deleteCurrentDesign() {
        let otherId;

        for (const d of designs) {
            if (d.id !== currentDesign.id) {
                otherId = d.id;
                break;
            }
        }

        if (otherId) {
            Net.deleteProjectDesign(currentDesign.id).then(() => {
                this.loadDesign(otherId);
            }).done();
        } else {
            throw new Error('Cannot delete last remaining design');
        }
    }
}

export { Versions };
