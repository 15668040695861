


/**
 * Store values in a tree-like structure.
 *
 * It might be a good idea to replace this with something more robust, but it has some functionality that might be
 * specific to us, like splicing numerical children of the same parent.
 *
 * Maybe it just needs some unit tests.
 */
class KeyStore {
    constructor(data) {
        this.data = data || { };
    }

    /**
     * Get value of key
     *      'parent.child.grandchild'
     * or
     *      [ 'parent', 'child', 'grandchild' ]
     * or defaultVal if it's undefined
     */
    get(path, defaultVal) {
        var parts = this.parsePath(path);
        var val;

        if (parts.length > 0) {
            var last = parts.length - 1;
            var d = this.data;

            for (let p of parts.slice(0, last)) {
                if (d[p] === undefined)
                    return defaultVal;

                d = d[p];
            }

            val = d[ parts[last] ];
        } else {
            val = this.data;
        }

        return (val !== undefined) ? val : defaultVal;
    }


    set(path, val) {
        var parts = this.parsePath(path);
        var last = parts.length - 1;
        var d = this.data;

        for (let p of parts.slice(0, last)) {
            if (d[p] === undefined)
                d[p] = {};

            d = d[p];
        }

        d[ parts[last] ] = val;
    }


    remove(path) {
        var parts = this.parsePath(path);
        var last = parts.length - 1;
        var d = this.data;

        for (let p of parts.slice(0, last)) {
            if (d[p] === undefined)
                return; // doesn't exist or already removed

            d = d[p];
        }

        delete d[ parts[last] ];
    }


    parsePath(path) {
        return (typeof path === 'string') ?
               path.split('.') :
               path;
    }


    /**
     * Delete data for a numeric key and deal with decrementing subsequent sibling keys.
     */
    splice(path, maxLength) {
        var parts = this.parsePath(path);

        this.remove(parts);

        let prefix = parts.slice(0, parts.length - 1);
        let key = parts[parts.length - 1];

        for (let i = key + 1; i < maxLength + 1; i++) {
            let val = this.get([ ...prefix, '' + i ]);

            if (val !== undefined) {
                this.set([ ...prefix, '' + (i - 1) ], val);
                this.remove([ ...prefix, '' + i ]);
            }
        }
    }
}


export { KeyStore };
