



/**
 * @typedef {{
 *  lat: number, lng: number, tilt: number, azimuth: number, kW: number, utilityRate: number
 * }} SolarParams
 *
 * @typedef {{
 *   ac_annual: number,
 *   ac_monthly: import('../../../types/index.js').Tuple<number, 12>
 * }} PVWattsInfo
 *
 * Quick and dirty NREL API client.
 */
class PVWattsClient {
    /**
     * @param {string} apiKey
     */
    constructor(apiKey) {
        this.apiKey = apiKey;

        /** @type {Map<string>} */
        this.pvWattsCache = new Map();

        /**
         * @private @readonly @constant
         * This should be static but current uglify doesn't support static initialization??
        */
        this.pvWattsEndpoint = 'https://developer.nrel.gov/api/pvwatts/v6.json';

        /**
         * @private @readonly @constant
         * Represents input keys to the PVWatts API which are semantically numeric,
         * but syntactically strings because they are passed via query string.
         *
         * This should also be static.
         */
        this.numericInputKeys = {
            'system_capacity': parseFloat,
            'module_type': parseInt,
            'losses': parseFloat,
            'array_type': parseInt,
            'tilt': parseFloat,
            'azimuth': parseFloat,
            'lat': parseFloat,
            'lon': parseFloat,
            'radius': parseInt,
            'dc_ac_ratio': parseFloat,
            'gcr': parseFloat,
            'inv_eff': parseFloat
        };
    }

    /**
     * @private
     * @param {Object} data
     * @param {string} url
     */
    makeAjaxRequestTo(data, url) {
        return new Promise(
            (success, error) => $.ajax({
                url,
                error,
                success,
                type: 'GET',
                crossDomain: true,
                data: {...data, api_key: this.apiKey}
            })
        );
    }

    /**
     * Calls the NREL PVWatts API with monthly granularity and yields the annual
     * and monthly production estimates from it. Result is cached.
     *
     * @param {SolarParams} solar
     * @param {*} system
     * @param {*} losses
     */
    async pvWattsInfo(solar, system, losses) {
        if (solar.kW <= 0) {
            return {
                ac_annual: 0,
                ac_monthly: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        }

        const cacheKey = PVWattsClient.pvWattsCacheKey(solar, system, losses);

        /** @type {PVWattsInfo} */
        const cacheResult = this.pvWattsCache.get(cacheKey);

        if (cacheResult) {
            return cacheResult;
        }

        try {
            const apiResponse = await this.callPVWatts(solar, system, losses);

            /** @type {PVWattsInfo} */
            const output = {
                ac_annual: apiResponse.outputs.ac_annual,
                ac_monthly: apiResponse.outputs.ac_monthly
            };

            this.pvWattsCache.set(cacheKey, output);
            return output;
        } catch (xhr) {
            let msg;

            try {
                const response = JSON.parse(xhr.responseText);
                msg = [...response.errors, ...response.warnings].join(' ');
            } catch (err) {
                msg = err instanceof SyntaxError ? "PVWatts request failed." : err.message;
            }

            throw new Error(msg);
        }
    }

    /**
     * Calls the NREL PVWatts API with hourly granularity and yields the entire output.
     * @param {SolarParams} solar
     * @param {*} system
     * @param {*} losses
     */
    async pvWattsReport(solar, system, losses) {
        const cacheKey = PVWattsClient.pvWattsCacheKey(solar, system, losses, 'hourly');
        let cacheVal = this.pvWattsCache.get(cacheKey);

        if (cacheVal) {
            return cacheVal;
        }

        cacheVal = await this.callPVWatts(solar, system, losses, 'hourly');
        this.pvWattsCache.set(cacheKey, cacheVal);

        return cacheVal;
    }

    /**
     * @param {*} solar
     * @param {*} system
     * @param {*} losses
     * @param {'hourly' | 'monthly'} timeframe
     */
    async callPVWatts(solar, system, losses, timeframe='monthly') {
        // Default dataset for PVWatts is the NSRDB. Don't use that if you're not
        // getting a production estimate for a spot covered by the NSRDB.
        const dataset = PVWattsClient.nsrdbCovers(solar.lat, solar.lng) ? 'nsrdb' : 'intl';

        const inputs = {
            losses,
            dataset,
            ...solar,
            timeframe,
            lon: solar.lng, // key name override
            inv_eff: system.invEff,
            system_capacity: solar.kW, // key name override
            array_type: system.arrayType,
            module_type: system.moduleType,
            dc_ac_ratio: system.dcToAcRatio,
            radius: 200
        };

        const output = await this.makeAjaxRequestTo(inputs, this.pvWattsEndpoint);
        this.conditionPVWattsInputEcho(output.inputs);

        return output;
    }

    /**
     * @private
     * @returns {string}
     */
    static pvWattsCacheKey(solar, system, losses, timeframe = 'monthly') {
        return Object.values({...solar, ...system, losses, timeframe}).join('');
    }

    /**
     * The PVWatts API echos back the inputs you sent as part of its output.
     * These are *semantically* numeric but *syntactically* strings because they
     * were input via query string.
     *
     * 8760 CSVs utilize these input values as part of the output response. This
     * exists to make their semantic and syntactic definitions the same and so I
     * can get rid of parseInt/parseFloats floating (no pun intended) around.
     *
     * @private
     * @typedef {{
     *   inputs: {
     *     lat: string,
     *     lon: string,
     *     gcr?: string,
     *     tilt: string,
     *     losses: string,
     *     inv_eff: string,
     *     azimuth: string,
     *     radius?: string,
     *     dc_ac_ratio: string,
     *     module_type: '0' | '1' | '2',
     *     array_type: '0' | '1' | '2' | '3' | '4',
     *   }
     * }} PVWattsInput
     * @param {PVWattsInput} response
     */
    conditionPVWattsInputEcho(response) {
        Object.entries(this.numericInputKeys).forEach(
            /** @param {[string, (_: string) => number]} _ */
            ([key, converter]) => response[key] && (response[key] = converter(response[key]))
        );
    }

    /**
     * @param {number} lat
     * @param {number} lng
     * @returns {boolean}
     */
    static nsrdbCovers(lat, lng) {
        // @see https://nsrdb.nrel.gov/about/u-s-data.html
        const nsrdbUSCovers = lat >= -20 && lat <= 60 && lng >= -170 && lng <= -20;

        // Determined by inspection of https://maps.nrel.gov/nsrdb-viewer/
        // because I can't find the grid bounds written down *anywhere*.
        const nsrdbIndiaCovers = lat >= 5 && lat <= 38 && lng >= 67 && lng <= 98;

        return nsrdbUSCovers || nsrdbIndiaCovers;
    }
}


export { PVWattsClient };
