import { metersToInches, metersToCm, inchesToMeters, cmToMeters } from '../alibs/Units';
import { MeasurementUnits } from '../tools/MeasurementsTool';
import { measurementUnits, emitEvent, prompts, init } from '../Viewer.js';
import { GUI } from './Gui.js';
import { SidebarGUI } from './GuiSidebar.js';
import { Validator } from './Validator';
import { Colors } from '../core/Colors';


import tippy from 'tippy.js';


class SidebarKeepoutsGui {

    /**
     * @param {object} options
     * @param {ModuleSpecsTool} options.tool
     * @param {() => any} options.getState
     * @param {string} options.selector
     * @param {TiltAzimuthGui} options.tiltAzimuthWidget
     */
    constructor(options) {
        this.options = options;

        this.getState = options.getState;

        this.sliderSelector = '#plane-offset-slider';
        this.spinnerSelector = '#spinner';
        this.autoGenSelector = 'button.generate-auto-keepouts';
        this.autoAcceptSelector = 'button.accept-auto-keepouts';
        this.autoClearSelector = 'button.clear-auto-keepouts';
        this.autoKeepoutRunningDialogSelector = '#dialog-gen-keepouts';
        this.autoKeepoutQty = 0;
    }


    cmd(command, val) {
        this.options.tool.cmd(command, val);
        return val;
    }


    init() {
        const self = this;

        // AUTO KEEPOUTS
        // manual mode
        $('body').on('click', '#sw-option-manual', function () {
            SidebarGUI.keepoutModeToggle = 'manual';

            self.cmd('clearProposedKeepouts');
            self.cmd('toggleOffsetSegment', false);
            emitEvent('status', { message: 'Draw keepouts manually'});

            self.renderState();
        });

        // auto mode
        $('body').on('click', '#sw-option-auto', function () {
            SidebarGUI.keepoutModeToggle = 'auto';
            self.cmd('toggleOffsetSegment', (!$('.generate-auto-keepouts').prop('disabled')) && ($('.delete-all-auto-keepouts').hasClass('hidden')));

            (self.autoKeepoutQty > 0) ? emitEvent('status', { message: "You've accepted your keepouts!"}) : emitEvent('status', { message: 'Adjust plane offset and generate automatic keepouts'});

            self.renderState();
        });

        /**
         * From empirical observation, there seems to be an onwheel handler
         * on this input element already that causes it to increment on wheelup
         * and decrement on wheeldown. It does not trigger the spin event but
         * does alter the value of the spinner. So we have to sync the slider
         * onspin and onwheel separately.
         */
        $('body').on(
            'wheel',
            this.spinnerSelector,
            /** @this {HTMLInputElement} */
            function () {
                $(self.sliderSelector).slider('value', this.value);
                self.cmd("setAutoKeepoutOffset", self.convToM($(self.sliderSelector).slider('value'), 10));
            }
        )

        // generate
        $('body').on(
            'click',
            this.autoGenSelector,
            function onAutoGen() {
                GUI.toggleEnable(this)(false);

                const keepoutRunningDialog = $(self.autoKeepoutRunningDialogSelector).dialog({
                    dialogClass: 'dialog-gen-keepouts',
                    width:       '40%',
                    height:      $(document).height() * 0.25,
                    autoOpen:    false,
                    modal:       true,
                    position: {
                        my: 'center',
                        at: 'center',
                        of: window
                    }
                }).dialog('open');

                setTimeout(
                    () => {
                        self.cmd('proposeKeepouts', Colors.Scanifly.teal());
                        self.renderState();

                        // Don't *immediately* flash the working dialog in and out on
                        // small models that complete quicker than the user can read.
                        setTimeout(() => keepoutRunningDialog.dialog('close'), 500);
                        self.cmd('toggleOffsetSegment', $('.clear-auto-keepouts').prop('disabled'));
                    },
                    10
                );
            }
        );

        // clear
        $('body').on(
            'click',
            this.autoClearSelector,
            function onAutoClear() {
                self.cmd('clearProposedKeepouts');
                self.renderState();
                self.cmd('toggleOffsetSegment', true);
                emitEvent('status', { message: "Adjust plane offset and generate automatic keepouts"});
            }
        );

        // accept
        $('body').on(
            'click',
            this.autoAcceptSelector,
            function onAutoAccept() {
                self.cmd('acceptProposedKeepouts');
                self.cmd('toggleOffsetSegment', false);

                emitEvent('status', { message: "You've accepted your keepouts!"});

                self.renderState();
            }
        );

        // delete
        $('body').on(
            'click',
            'button.delete-all-auto-keepouts',
            function () {
                prompts.confirm('Are you sure you want to delete all automatic keepouts?').then(function (ok) {
                    if (ok) {
                        self.cmd('removePlaneAutoKeepouts'); // method to delete all accepted keepouts
                        self.cmd('toggleOffsetSegment', true);

                        self.renderState();

                        emitEvent('status', { message: "Adjust plane offset and generate automatic keepouts"});
                    }
                });
            }
        );

        // MANUAL KEEPOUTS
        $('body').on('click', 'button.keepout-line',    function () { self.cmd('addKeepout', 'line');         });
        $('body').on('click', 'button.keepout-rect',    function () { self.cmd('addKeepout', 'rect');         });
        $('body').on('click', 'button.keepout-poly',    function () { self.cmd('addKeepout', 'poly');         });
        $('body').on('click', 'button.keepout-circle',  function () { self.cmd('addKeepout', 'circle');       });
        $('body').on('click', 'button.keepout-fs',      function () { self.cmd('addKeepout', 'fire-setback'); });

        $('body').on('click', 'button.keepout-edit', function () {
            let [ pid, kid ] = self.getKeepoutIds($(this));

            self.cmd('enableSetbackEditing', { pid, kid });
        });

        $('body').on('click', 'button.keepout-remove', function () {
            let [ pid, kid ] = self.getKeepoutIds($(this));

            prompts.confirm('Are you sure you would like to delete this keepout?').then(function (ok) {
                if (ok) {
                    let auto = $('#sw-option-auto').hasClass('active');

                    self.cmd('removeKeepout', { pid, kid });
                    if (auto && self.autoKeepoutQty === 0) {
                        self.cmd('toggleOffsetSegment', true);
                        // TODO: renderState()?
                    }
                }
            });
        });

        let update = (name) => {
            return (val, $input) => {
                let [ pid, kid ] = self.getKeepoutIds($input);
                let params = {};

                params[name] = val;

                self.cmd('editKeepout', { pid, kid, params });

                return val;
            };
        };

        let reset = (name) => {
            return ($input) => {
                let kid = self.getKeepoutIds($input).kid;

                return self.getState().keepouts[kid][name];
            };
        };

        let cFrom = (val) =>  {
            if (measurementUnits === MeasurementUnits.METERS) {
                return cmToMeters(val, 10)
            } else {
                return inchesToMeters(val, 10)
            }
        };

        let cTo = (val) =>  {
            if (measurementUnits === MeasurementUnits.METERS) {
                return metersToCm(val, 10)
            } else {
                return metersToInches(val, 10)
            }
        };

        $('body').on('change', '.module-specs-keepout-extrude', function () {
            let [ pid, kid ] = self.getKeepoutIds($(this));
            let params = {
                extrude: $(this).val()
            };

            self.cmd('editKeepout', { pid, kid, params });
        });

        $('body').on('change', '#fire-setback-color-mode-toggle', ev => {
            const $elt = $(ev.currentTarget);
            const [pid, kid] = this.getKeepoutIds($elt);

            const colorMode = $elt.is(':checked') ? 'dark' : 'light';

            this.cmd('editKeepout', { pid, kid, params: { colorMode }});
        });

        GUI.inputText('.input-keepout-height', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                updateState: update('height'),
                resetState:  reset( 'height'),
                convertFrom: cFrom,
                convertTo:   cTo
            })
        });

        GUI.inputText('.input-keepout-setback', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                updateState: update('setback'),
                resetState:  reset( 'setback'),
                convertFrom: cFrom,
                convertTo:   cTo
            })
        });

        GUI.inputText('.input-keepout-radius', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                updateState: update('radius'),
                resetState:  reset( 'radius'),
                convertFrom: cFrom,
                convertTo:   cTo
            })
        });
    }


    getKeepoutIds($el) {
        let s = this.getState();
        let kid = $el.first().attr('data-keepout-id');

        if (kid === undefined)
            kid = $el.parents('.keepout-row').first().attr('data-keepout-id');

        if (kid === undefined)
            throw new Error('Keepout ID not found');

        return [ s.array.pid, parseInt(kid) ];
    }


    renderState() {
        const s = this.getState();

        const self = this;
        const units = (measurementUnits === MeasurementUnits.METERS) ? "cm" : "in.";

        var t = `
            <div class="pane-container">
                <h3>Keepouts</h3>

                <div id="dialog-gen-keepouts" style="display: none;">
                    <i class="fa fa-spinner" aria-hidden="true"></i>
                    <h3>Hang tight!</h3>
                    <p>This may take a second.<br></p>
                </div>

                <div class="sw-multiway">
                    <div id="sw-option-manual" class="sw-option active">
                        Manual
                    </div>
                    <div id="sw-option-auto" class="sw-option">
                        Automatic
                    </div>
                </div>
                <div class="keepouts-auto hidden">
                    <div class="auto-keepouts-header">
                        <h4 class="label">Plane Offset</h4>

                        <div class="info">
                            <div class="qmark-info">
                                <div class="tooltip-content">
                                    The offset plane represents the height above the defined roof segment where extrusions are considered keepouts.
                                    In the model space, it is shown in blue, and everything above it will be marked as a keepout.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="auto-keepout-inputs">
                        <div class="input-wrapper first">
                            <div id="plane-offset-slider" aria-label="plane offset"></div>
                        </div>
                        <input type="hidden" id="auto-keepouts-${s.array.pid}-offset">
                        <input id="spinner"
                               name="value"
                               type="number"
                               min="${this.convFromM(-1, 0)}"
                               max="${this.convFromM(2, 0)}"
                               step="${units === 'cm' ? 1 : 0.5}"
                               value="${this.convFromM(s.array.keepoutPlaneOffset, 0)}"
                               size="30"
                               aria-label="plane offset"> <span class="keepout-units">${units}</span><br><br>
                    </div>
                    <div class="keepout-buttons">
                        <button class="sy generate-auto-keepouts">
                            Generate
                        </button>
                        <button class="sy accept-auto-keepouts" disabled aria-disabled="true">
                            Accept All
                        </button>
                        <button class="sy clear-auto-keepouts" disabled aria-disabled="true">
                            Clear All
                        </button>
                    </div>
                    <button class="sy delete-all-auto-keepouts hidden" aria-disabled="true">
                            <i class="fa fa-trash"></i>&nbsp Delete All
                    </button>
                    <hr>
                </div>

                <div class="segment-info"></div>

                <div id="plane-tabs-keepouts-${s.array.pid}" class="sidebar-keepouts">
                    <div class="module-specs-tool-actions">
                        <h4>Keepout Shapes</h4>
                        <div class="sidebar-keepouts-container">
                            <div class="sidebar-keepouts-row">
                                <button class="sy keepout-button keepout-rect" aria-label="add square keepout">
                                    <img src="styles/images/icons/square.svg">
                                </button>
                                <button class="sy keepout-button keepout-circle" aria-label="add circle keepout">
                                    <img src="styles/images/icons/circle.svg">
                                </button>
                                <button class="sy keepout-button keepout-poly" aria-label="add polygon keepout">
                                    <img src="styles/images/icons/polygon.svg">
                                </button>
                                <button class="sy keepout-button keepout-line" aria-label="add line keepout">
                                    <img src="styles/images/icons/line.svg">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="fire-setback">
                        <button class="sy keepout-fs">
                            <i class="fa fa-fire-extinguisher"  aria-hidden="true"></i>
                            Fire Setback
                        </button>
                    </div>



                    <div class="units">&nbsp Units: <i>${units}</i><div class="keepout-color-key"><i class="fa fa-square automatic-label" aria-hidden="true"></i> <i>Automatic</i> &nbsp &nbsp &nbsp <i class="fa fa-square manual-label" aria-hidden="true"></i> <i>Manual</i>&nbsp &nbsp</div></div>

                    <table class="module-specs-tool-keepouts">
                        <tr>
                            <td> Type </td>
                            <td class="direction"> Direction   </td>
                            <td data-tippy-content="Height"> H </td>
                            <td data-tippy-content="Setback Distance"> S </td>
                            <td data-tippy-content="Radius"> R </td>
                            <td> </td>
                        </tr>
                    </table>
                </div>
            </div>
        `;

        $(this.options.selector).html(t);

        this.options.tiltAzimuthWidget.renderState();


        let keepouts = s.array.keepouts[s.array.pid];

        for (var kid = 0; kid < keepouts.length; kid++) {
            let k = keepouts[kid];

            if (k.shape === 'fire-setback') {
                const colorModeToggleState = (k.colorMode ?? 'dark') === 'dark' ? 'checked="checked"' : '';
                // hide fire setback button if there is one already
                $(`#plane-tabs-keepouts-${s.array.pid} .keepout-fs`).hide();

                $(`#plane-tabs-keepouts-${s.array.pid} .fire-setback`).append(`
                    <div class="inner keepout-row fire-keepout-row" data-keepout-id="${kid}">
                        <div class="setback-heading">
                            <span class="icon"><i class="fa fa-2x fa-fire-extinguisher" aria-hidden="true"></i></span>
                            <span class="title">Fire setback</span>
                        </div>
                        <div class="keepout-edit-remove">
                            <div class="toggle-container fsColorToggle">
                                <span>Light</span>
                                <div class="toggle">
                                    <input type="checkbox" id="fire-setback-color-mode-toggle" class="switch-input" ${colorModeToggleState}>
                                    <label for="fire-setback-color-mode-toggle" class="switch-label switch-details"></label>
                                </div>
                                <span>Dark</span>
                            </div>
                            <div class="fire-setback-buttons">
                                <button id="fsKeepout-edit" class="sy keepout-edit">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                                <button class="sy btn-delete keepout-remove">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                `);
            } else {
                let st, et;

                if (k.shape === 'parapet') {

                    st = '<td></td>';
                    et = `
                        <button class="sy keepout-edit" data-keepout-id="${kid}">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                    `;
                } else {
                    const val = this.convFromM(k.setback, 0);

                    st = `
                        <td>
                            <input type="text" title="Setback" class="number input-keepout-setback" value="${val}" />
                        </td>
                    `;
                    et = '';
                }

                const heightVal = this.convFromM(k.height, 0);

                let ht =`
                    <td>
                        <input type="text" title="Height" class="number input-keepout-height" value="${heightVal}" />
                    </td>
                `;

                let rt = '<td></td>';

                if (k.radius !== undefined) {
                    const radiusVal = this.convFromM(k.radius, 0);

                    rt = `
                        <td>
                            <input type="text" title="Radius" class="number input-keepout-radius" value="${radiusVal}" />
                        </td>
                    `;
                }

                // the 'auto' in 'iconic keepout-type auto' indicates the teal tag. manual keepouts should use 'manual' to get red tag
                // i'm not sure how these types are set up, but we'll want to do something like ${k.type} to get the tag color working
                const t = `
                    <tr class="keepout-row ${k.shape}" data-keepout-id="${kid}">
                        <td class="iconic keepout-type ${k.automatic || false ? 'auto' : 'manual'}">
                            ${this.getKeepoutIconElement(k)}
                        </td>
                        <td>
                            <select class="module-specs-keepout-extrude" name="keepout extrude">
                                <option value="vertical" ${k.extrude === 'vertical' ? 'selected="selected"' : ''}> Z Axis</option>
                                <option value="normal"   ${k.extrude === 'normal'   ? 'selected="selected"' : ''}> Perpendicular</option>
                            </select>
                        </td>

                        ${ht}
                        ${st}
                        ${rt}

                        <td>
                            <div class="keepout-remove">
                                ${et}
                                <button class="sy btn-delete keepout-remove">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                `;


                $('.module-specs-tool-keepouts').append(t);
            }
        }


        // active tab
        $('#sw-option-manual').toggleClass('active', SidebarGUI.keepoutModeToggle === 'manual');
        $('#sw-option-auto')  .toggleClass('active', SidebarGUI.keepoutModeToggle === 'auto');



        $('.sidebar-keepouts .module-specs-tool-actions').toggleClass('hidden', SidebarGUI.keepoutModeToggle === 'auto');
        $('.keepouts-auto').toggleClass('hidden', SidebarGUI.keepoutModeToggle === 'manual');

        if(SidebarGUI.keepoutModeToggle === 'auto') {
            $('.keepout-fs').addClass('hidden');
            // if this row exists that means we have a fire keepout row that should show in auto and manual modes.
            if($('.fire-keepout-row').get().length) {
                $('.fire-setback').removeClass('hidden');
            }
            else {
                $('.fire-setback').addClass('hidden');
            }
        } else {
            $('.keepout-fs').removeClass('hidden');
        }

        if($('.fire-keepout-row').get().length) {
            $('.fire-setback').removeClass('hidden');
        }
        else {
            $('.keepout-fs').removeClass('hidden');
        }


        $(this.sliderSelector).slider({
            value:       this.convFromM(s.array.keepoutPlaneOffset, 0),
            min:         this.convFromM(-1, 0),
            max:         this.convFromM( 2, 0),
            orientation: "horizontal",
            range:       "min",
            animate:     true,
            step:        measurementUnits === MeasurementUnits.METERS ? 1 : 0.5,
            slide: (_, ui) => {
                $(self.spinnerSelector).spinner('value', ui.value);
                self.cmd("setAutoKeepoutOffset", self.convToM($(self.sliderSelector).slider('value'), 10));
            }
        });

        $(this.spinnerSelector).spinner({
            spin: (_, ui) => {
                $(self.sliderSelector).slider('value', ui.value);
                self.cmd("setAutoKeepoutOffset", self.convToM($(self.sliderSelector).slider('value'), 10));
            }
        });

        this.autoKeepoutQty = keepouts.filter(k => k.automatic).length;

        if (SidebarGUI.keepoutModeToggle === 'auto') {
            $('#plane-offset-slider').slider('enable');
            $('.delete-all-auto-keepouts').addClass('hidden');
            $('.keepout-buttons').removeClass('hidden');
        }

        if (this.autoKeepoutQty === 0 && s.array.proposedKeepoutQty === 0) { // This might be causing an issue where if the user generates keepouts somewhere
            // no auto keepouts have been found or accepted             // that there's no reason to / none are found, the UI doesn't reflect – come back to.
            $('#plane-offset-slider').slider('enable');
            $(this.spinnerSelector).spinner('enable');
            ($('.keepout-units').css('opacity', '1'));
            ($('.auto-keepout-inputs').css('cursor', 'auto'));

            GUI.toggleEnable(this.autoGenSelector)   (true);
            GUI.toggleEnable(this.autoAcceptSelector)(false);
            GUI.toggleEnable(this.autoClearSelector) (false);
        } else if (s.array.proposedKeepoutQty > 0) {
            // some auto keepouts have been found, user needs to accept
            $('#plane-offset-slider').slider('disable');
            $(this.spinnerSelector).spinner('disable');
            ($('.keepout-units').css('opacity', '.5'));
            ($('.auto-keepout-inputs').css('cursor', 'not-allowed'));

            emitEvent('status', { message: "Accept or clear your automatic keepouts."});

            GUI.toggleEnable(this.spinnerSelector)   (false);
            GUI.toggleEnable(this.autoGenSelector)   (false);
            GUI.toggleEnable(this.autoAcceptSelector)(true);
            GUI.toggleEnable(this.autoClearSelector) (true);
        } else if (this.autoKeepoutQty > 0) {
            // auto keepouts accepted
            $('#plane-offset-slider').slider('disable');
            $(this.spinnerSelector).spinner('disable');
            ($('.keepout-units').css('opacity', '.5'));
            ($('.auto-keepout-inputs').css('cursor', 'not-allowed'));

            $('.keepout-buttons').addClass('hidden');
            $('.delete-all-auto-keepouts').removeClass('hidden');
        }

        // HANDLERS that have to be bound to existing elements in the DOM

        // if mouse is over the row when render happens, mouse enter keeps firing in a loop
        // need to suppress repeated mouse enters on the same target to prevent this behavior
        $(`#plane-tabs-keepouts-${s.array.pid} .keepout-row`).mouseenter(function (e) {
            if (self.prevMouseEnterTarget && self.prevMouseEnterTarget.isEqualNode(e.target))
                return;

            self.prevMouseEnterTarget = e.target;

            let [ pid, kid ] = self.getKeepoutIds($(this));

            self.cmd('editKeepout', { pid, kid, params: { highlight: true } });
        });

        $(`#plane-tabs-keepouts-${s.array.pid} .keepout-row`).mouseleave(function () {
            self.prevMouseEnterTarget = null;

            let [ pid, kid ] = self.getKeepoutIds($(this));

            self.cmd('editKeepout', { pid, kid, params: { highlight: false } });
        });

        tippy('.sidebar-keepouts [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'top',
        });

        tippy('.sw-multiway [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'top',
        });

        tippy(`.keepouts-auto .qmark-info`, {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            interactive: true
        });
    }


    /** @type {(v: number, k: number) => number}*/
    convToM(v, k) {
        return (measurementUnits === MeasurementUnits.METERS) ? cmToMeters(v, k) : inchesToMeters(v, k);
    }

    /** @type {(v: number, k: number) => number} */
    convFromM(v, k) {
        return measurementUnits === MeasurementUnits.METERS ? metersToCm(v, k) : metersToInches(v, k);
    }


    getKeepoutIconElement(k) {
        var iconClass = '';

        switch (k.shape) {
            case 'line':
                iconClass = 'styles/images/icons/line.svg';
                break;
            case 'rect':
                iconClass = 'styles/images/icons/square.svg';
                break;
            case 'circle':
                iconClass = 'styles/images/icons/circle.svg';
                break;
            case 'poly':
                iconClass = 'styles/images/icons/polygon.svg';
                break;
            case 'fire-setback':
                iconClass = 'fa-fire-extinguisher';
                break;
            case 'parapet':
                iconClass = 'styles/images/icons/parapet.png';
                break;
        }
        let element;

        switch (k.shape) {
            case 'line':
            case 'rect':
            case 'poly':
            case 'parapet':
            case 'circle':
                element = `<image class="fa fa-2x icon" src="${iconClass}">`
                break;
            default:
                element = `<i class="fa fa-2x icon ${iconClass}"></i>`;
        }
        return element;
    }
}

export { SidebarKeepoutsGui };
