import { Draw } from './Draw.js';
import { Polygon } from '../libs/Polygon.js';
import { intersectLineSegments, jUnitVector, planeFromPoints } from '../libs/Geometry.js';
import { Materials } from '../libs/Materials.js';
import { keyboard, emitEvent, mouseHandler, camera } from '../Viewer.js';
import { degToRad } from '../libs/Geometry';
import { Arrow } from '../elements/Arrow.js';
import { headingToDirectionVector, UP } from '../libs/Orienteering.js';
import { BasisAlignedBoundingBox } from '../libs/BasisAlignedBoundingBox.js';
import { AreaAndExcavate } from '../tools/AreaAndExcavate.js';
import { OffsetSegment } from './OffsetSegment.js';
import { SegmentAnnotation } from '../libs/SegmentAnnotation.js';
import { Box3, BufferGeometry, Color, DoubleSide, Face3, Geometry, Group, Line, Line3, LineBasicMaterial, LineSegments, Matrix4, Mesh, MeshBasicMaterial, MOUSE, Object3D, Plane, PlaneBufferGeometry, Ray, Shape, ShapeGeometry, SphereGeometry, Vector2, Vector3 } from '../libs/three.module';
import Colors from '../core/Colors.js';

const PURE_YELLOW = Object.freeze(new Color('yellow'));
const SCANIFLY_RED = Object.freeze(new Color(Colors.Scanifly.red()));


/** @typedef {import('../../../types/index').NoBufferGeom<import('three').Line>} OutlineSegment */
/**
 * @typedef {{
 *   centroid: import('three').Vector3,
 *   normal: import('three').Vector3,
 *   threePlane?: Plane
 * }} PlaneDescriptor */

/**
 * @template {'fire-setback' | 'poly' | 'parapet'} Shape
 */
class PolyContext {
    /**
     *
     * @param {object} options
     * @param {Camera} options.camera
     * @param {Raycaster} options.raycaster
     * @param {string} options.label
     * @param {"auto" | "manual"} [options.placement = 'auto']
     * @param {LineBasicMaterialParameters['color']} [options.color]
     * @param {number} [options.lineWidth = 3]
     * @param {Shape extends 'poly' ? 'poly' : Shape extends 'fire-setback' ? 'fire-setback' : Shape extends 'parapet' ? 'parapet' : never} options.shape
     * @param {Vector3[]} [options.vertices]
     * @param {'normal' | 'vertical'} [options.extrude = 'vertical']
     * @param {boolean} [options.automatic = false] Whether this is a keepout created by auto-keepouts
     *   (This really shouldn't belong to PolyContext since it doesn't make sense in all PolyContexts
     *    but that is a can to be kicked perpetually down the road.)
     * @param {boolean} [options.showSegmentMarkers = true]
     * @param {boolean} [options.showMidpointMarkers = true]
     * @param {boolean} [options.hasAnnotation = true] Can this object have an annotation? This is set false for keepouts
     *
     * @param {object} [options.snap = {}]
     * @param {boolean} [options.snap.ninety = true]
     * @param {boolean} [options.snap.vertices = true]
     * @param {boolean} [options.snap.intersects = true]
     * @param {boolean} [options.snap.start = true]
     * @param {'dark' | 'light' | undefined} options.colorMode Hack: this is separate from `color`
     *   for two reasons: (1) to persist coloring to saves while maintaining legacy behavior of
     *   not saving options.color to the context and (2) allowing the exact colors of the dark/light
     *   scheme to be modified and applied uniformly. This setting overrides `color` when both are
     *   set.
     */
    constructor(options) {
        options = options || {};

        if (!options.shape || !options.camera || !options.raycaster) {
            throw new Error("PolyContext not constructible without camera, raycaster and shape type");
        }

        this.shape = options.shape;
        this.automatic = options.automatic || false;
        this.container = new Group();
        this.camera    = options.camera;
        this.raycaster = options.raycaster;
        this.label     = options.label;

        // type of module placement for this context
        this.placement = options.placement || 'auto';

        /** @type {import('three').Mesh[]} */
        this.meshes = [];

        this.materialOutline = new LineBasicMaterial({
            color:       options.color || 0x26ec6a,
            linewidth:   options.lineWidth || 3,
            transparent: true
        });

        this.materialExtrusion = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth * 0.5 || 1.5,
            transparent: true
        });

        this.materialHighlight = new LineBasicMaterial({
            color:       0xffff00,
            linewidth:   options.lineWidth || 5,
            transparent: true
        });

        this.setOutlineColor = this._setColorOf(this.materialOutline);
        this.setExtrusionColor = this._setColorOf(this.materialExtrusion);

        // backward compatibility, remove when no longer needed
        this.material = this.materialOutline;

        /** @type {OutlineSegment[]} */
        this.segments        = [];
        this.markersSegment  = [];
        this.markersMidpoint = [];

        this.states = { NONE: 0, STARTED: 1, CLOSED: 3 };
        this.state = this.states.NONE;

        this.movementMarker = null;
        this.splitMarker    = null;
        this.areaTool       = null;

        this.planeIntersections = [];
        this.moveIntersections  = [];
        this.movePlanes         = [];

        this.vvSnap = [];
        this.parentSnap = false;

        this.snap = Object.assign({
            ninety:     true,
            vertices:   true,
            intersects: true,
            start:      true
        }, options.snap || {});

        var materialSnap90 = new LineBasicMaterial({
            color:       0xffff00,
            linewidth:   2,
            transparent: true,
            depthTest:   false
        });

        var geometry = new Geometry();
        geometry.vertices.push(
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0)
        );

        this.markerSnap90 = new Line(geometry, materialSnap90);

        this.height      = 0;
        this.setbackSize = 0;

        this.onComplete      = options.onComplete;
        this.onShapeChanged  = options.onShapeChanged;
        this.onVertexMove    = options.onVertexMove;
        this.onSegmentInsert = options.onSegmentInsert;
        this.onSelectDir     = options.onSelectDir;

        this.showSegmentMarkers = (options.showSegmentMarkers === undefined) ?
            true : options.showSegmentMarkers;

        this.showMidpointMarkers = (options.showMidpointMarkers === undefined) ?
            true : options.showMidpointMarkers;

        this.hasAnnotation = (options.hasAnnotation === undefined) ?
            true : options.hasAnnotation;

        this.extrude = options.extrude || 'vertical';
        this.normal  = options.normal;

        if (options.vertices)
            this.initFromVertices(options.vertices);

        this.updateParams(options);

        this.arrows = [];

        this.options = options;

        this.enableArrowWidget = this.options.arrowWidget;

        /** @type {OffsetSegment} */
        this.offsetSegment = undefined;

        /** @type {number?} */
        this.azimuth = null;

        this.setXRayMode(true);

        // depends on instantiation of this.materialOutline and must come after
        if (options.colorMode) {
            this.setColorMode(options.colorMode);
        }
    }

    /** @param {import('three').Mesh} mesh */
    addMesh(mesh)     { this.meshes.push(mesh); }

    /** @param {number} angle */
    setAzimuth(angle) { this.azimuth = angle;   }

    /** @param {import('three').Scene} scene */
    addToScene(scene)  {
        this.scene = scene;
        this.scene.add(this.container);

        this.container.add(this.markerSnap90);
    }

    removeFromScene() {
        this.container.remove(this.markerSnap90);

        this.scene.remove(this.container);
        this.scene = undefined;
    }


    show(show) {
        if (show !== false) {
            this.scene.add(this.container);
        } else {
            this.scene.remove(this.container);

            for (let arrow of this.arrows)
                this.scene.remove(arrow);
        }
    }

    /** @param {'dark' | 'light'} mode */
    setColorMode(mode) {
        if (!(mode === 'dark' || mode === 'light')) {
            throw new Error(`Invalid PolyContext color mode: ${mode}`);
        }

        this.colorMode = mode;
        return this.setColor(
            mode === 'dark' ? SCANIFLY_RED : PURE_YELLOW
        );
    }

    /** @param {LineBasicMaterial} mtl */
    _setColorOf(mtl) {
        /** @param {Color} color */
        return color => {
            mtl.setValues({ color });
            mtl.needsUpdate = true;

            return this;
        }
    }

    setColor(color) {
        return this.setOutlineColor(color).setExtrusionColor(color);
    }

    setXRayMode(xray) {
        this.xRayMode = xray;

        this.materialOutline  .depthTest = !xray;
        this.materialExtrusion.depthTest = !xray;
        this.materialHighlight.depthTest = !xray;

        this.annotationSelected(xray);

        this.materialOutline  .materialNeedsUpdate = true;
        this.materialExtrusion.materialNeedsUpdate = true;
        this.materialHighlight.materialNeedsUpdate = true;

        for (let ms of this.markersSegment)
            ms.material = Materials.markerSegment({ xray });

        for (let mm of this.markersMidpoint)
            mm.material = Materials.markerMidpoint({ xray });
    }


    setRenderOrder(val) {
        this.container.children.forEach(c => c.renderOrder = val);
    }

    refreshSegmentCentroid(){
        //map each vertices of total segments
        let vertices = this.segments.map(v=> v.geometry.vertices[0]);

        this.segmentCentroid = vertices.reduce(
            (sum, vtx) => sum.add(vtx),
            new Vector3(0, 0, 0)
          ).divideScalar(
            vertices.length
          );
    }

    annotationSelected(selected) {
        this.isAnnotSelected = selected;
        this._isClosedLoop() && this.shape === "poly" ? this.addAnnotation() : null;
    }


    addAnnotation() {
        // clear annotation if exists
        if (this.annotation) {
            this.container.remove(this.annotation);
        }

        if(!this.hasAnnotation) {
            return;
        }

        const annotation = new SegmentAnnotation({
            radius: 64,
            offset: 0,
            bgColor:  this.isAnnotSelected ? '#c018d6':'#1495ff',
        });

        annotation.setTextRectangle(this.label);

        const sprite = annotation.getSprite();
        sprite.visible = this.annotation ? this.annotation.visible : true;

        //reuse sprite position if exists, else create and update one via the refresh centroid method
        if (!this.segmentCentroid) {
            this.refreshSegmentCentroid();
        }

        sprite.position.copy(this.segmentCentroid);

        this.container.add(sprite);
        this.annotation = sprite;

        this.scaleAnnotation();
    }

    scaleAnnotation() {
        if(this.annotation) {
            let d = camera.position.distanceTo(new Vector3(0, 0, 0));
            let k = d * 0.0625;
            this.annotation.children.forEach(c => c.scale.set(k, k, 1));
        }
    }

    /**
     * Restore from save data consisting of Vector3 vertices.
     */
    initFromVertices(vertices) {
        this.segments.forEach((s) => this.container.remove(s));

        this.segments = [];

        for (var i = 0; i < vertices.length; i++) {
            var v1 = vertices[i];
            var v2 = vertices[(i + 1) % vertices.length];

            var segment = this.createSegment(v1);
            segment.geometry.vertices[1].copy(v2);

            this.segments.push(segment);
            this.container.add(segment);

            this.showMarkers(v1, v2);
        }

        if(this.shape === "poly")
            this.addAnnotation();

        this.createMovementPlane();
        this.refreshOffsetSegment();
        this.scaleMarkers();
        this.updateLengths();
        this.state = this.states.CLOSED;
    }


    /**
     * Make sure vertices will work to create a working poly context and won't cause the context to throw up when fed
     * into initFromVertices()
     *
     * Throws an exception if the vertices will not work.
     */
    validateVertices(vertices) {
        this.segments.forEach((s) => this.container.remove(s));

        this.segments = [];

        for (var i = 0; i < vertices.length; i++) {
            var v1 = vertices[i];
            var v2 = vertices[(i + 1) % vertices.length];

            var segment = this.createSegment(v1);
            segment.geometry.vertices[1].copy(v2);

            this.segments.push(segment);
            this.container.add(segment);

            this.showMarkers(v1, v2);
        }

        this.createMovementPlane();
    }


    showMarkers(v1, v2) {
        if (this.showSegmentMarkers) {
            let m = this.createMarker(1, 'segment', v1);

            this.container.add(m);
            this.markersSegment.push(m);
        }

        if (this.showMidpointMarkers) {
            let midpoint = v1.clone().add(v2).divideScalar(2);
            let m = this.createMarker(1, 'midpoint', midpoint);

            this.container.add(m);
            this.markersMidpoint.push(m);
        }
    }


    /**
     * Create a plane used for dragging polygon vertices and midpoints.
     */
    createMovementPlane() {
        this.fitPlane = this.planeFromSegments();

        if (!this.fitPlane)
            return;

        const c = this.fitPlane.centroid;
        const n = this.fitPlane.normal;

        var box = new Box3();
        this.meshes.forEach(m => box.expandByObject(m));
        var size = box.getBoundingSphere().radius;

        // invisible plane used for raycasting when dragging vertices
        const plane = new Mesh(
            new PlaneBufferGeometry(size * 2, size * 2),
            Materials.invisibleMesh
        );

        plane.position.copy(c);
        plane.quaternion.setFromUnitVectors(jUnitVector, n);
        plane.rotateX(degToRad(90));
        plane.updateMatrixWorld();

        if (this.movementPlane) {
            this.container.remove(this.movementPlane);
            this.movementPlane.geometry.dispose();
        }

        this.container.add(plane);
        this.movementPlane = plane;

        // show fit plane normal
        if (this.nLine) {
            this.container.remove(this.nLine);
            this.nLine.geometry.dispose();
        }

        const bg = (new BufferGeometry()).setFromPoints([c, c.clone().add(n)]);

        this.nLine = new Line(bg, Materials.normalLine);
        this.nLine.frustumCulled = false;

        this.normal = n.clone();

        this.container.add(this.nLine);

        this.updateHighlightPlane();
    }

    refreshOffsetSegment() {
        if (this.offsetSegment) {
            this.offsetSegment.dispose();
            delete this.offsetSegment;
        }

        this.offsetSegment = new OffsetSegment({
            context: this
        });
    }


    toPoly2() {
        var poly2 = new Polygon({
            vertices: this.getVertices().map((v) => {
                return this.movementPlane.worldToLocal(v.clone());
            }),
            fixWindingOrder: false
        });

        return poly2;
    }


    updateHighlightPlane() {
        if (!this.fitPlane)
            return;

        if (this.planeHighlight) {
            this.container.remove(this.planeHighlight);
            this.planeHighlight.geometry.dispose();

            // @ts-ignore planeHighlight.material is not MeshMaterialType[] by construction
            this.planeHighlight.material.dispose();
        }

        var geometry = this.getPolygonGeometry(this.fitPlane);
        var material = new MeshBasicMaterial({
            color:       0x02ffa8,
            side:        DoubleSide,
            transparent: true,
            opacity:     0.0,
            visible:     false
        });

        this.planeHighlight = new Mesh(geometry, material);
        this.planeHighlight.position.copy(this.fitPlane.normal.clone().multiplyScalar(0.05));

        this.container.add(this.planeHighlight);
    }


    mouseAddSegment(mouse, vSnap) {
        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObjects(this.meshes);
        if (intersects.length === 0)
            return false;

        var segment = this.createSegment(intersects[0].point, vSnap);

        this.segments.push(segment);
        this.container.add(segment);

        return true;
    }


    insertSegment(index, point) {
        var end = this.segments[index].geometry.vertices[1].clone();
        this.segments[index].geometry.vertices[1].copy(point);

        var next = (index + 1) % this.segments.length;

        var segment = this.createSegment(point);
        this.container.add(segment);
        this.segments.splice(next, 0, segment);

        this.segments[next].geometry.vertices[1].copy(end);

        this.segments[index].geometry.verticesNeedUpdate = true;
        this.segments[ next].geometry.verticesNeedUpdate = true;

        var mSegment = this.createMarker(1, 'segment', point);
        this.container.add(mSegment);
        this.markersSegment.splice(next, 0, mSegment);

        var mMidpoint = this.createMarker(1, 'midpoint', point);
        this.container.add(mMidpoint);
        this.markersMidpoint.splice(next, 0, mMidpoint);

        this.scaleMarkers();
    }


    /**
     *
     * @param {import('three').Vector3} head
     * @param {import('three').Vector3} [tail]
     * @returns
     */
    createSegment(head, tail) {
        var s = this.segments;

        if (s.length > 0) {
            head = head.clone();

            var g = s[s.length - 1].geometry;

            if (tail) {
                tail = tail.clone();
                g.vertices[1] = tail.clone();
                g.verticesNeedUpdate = true;
            } else {
                tail = g.vertices[1].clone();
            }
        } else {
            head = head.clone();
            tail = tail || head.clone();
        }

        var geometry = new Geometry();
        geometry.vertices.push(tail, head);

        /**@type {import('../../../types/index').NoBufferGeom<import('three').Line>} */
        /// @ts-ignore It's a Geometry
        var line = new Line(geometry, this.materialOutline);

        // prevent line from disappearing due to frustum culling
        // alternatively, call line.geometry.computeBoundingSphere() every time we
        // update the geometry
        line.frustumCulled = false;

        return line;
    }


    /**
     * Markers used to indicate vertices and midpoints.
     */
    createMarker(radius, markerType, position) {
        var markerGeometry = new SphereGeometry(radius, 32, 32);
        var markerMaterial = (markerType === 'segment')
            ? Materials.markerSegment({ xray: this.xRayMode })
            : Materials.markerMidpoint({ xray: this.xRayMode });

        var marker = new Mesh(markerGeometry, markerMaterial);
        marker.position.copy(position);




        marker.parentCtx = this;




        return marker;
    }


    getOrigin () { return this.segments[0].geometry.vertices[0].clone(); }


    getVertices() {
        var vv = [];

        for (let s of this.segments)
            vv.push(s.localToWorld(s.geometry.vertices[0].clone()));

        return vv;
    }

    /**
     *
     * @param {Object3D} obj
     */
    getVerticesInCoordinateFrameOf(obj) {
        return this.getVertices().map(v => obj.worldToLocal(v));
    }


    // TODO: don't rely on another tool to calculate area
    // refactor area calculation into a separate function that can be invoked here and in AreaAndExcavate
    calculateArea() {
        if(!this.areaTool) {
            this.areaTool = new AreaAndExcavate()
        }
        this.areaTool.segments = this.segments
        let area               = this.areaTool.calculatePolygonArea()
        return area
    }


    getSetbackVertices() {
        if (!this.setback)
            return this.getVertices();

        var vv = [];

        for (let v of this.setback.geometry.vertices)
            vv.push(v.clone());

        return vv;
    }


    undo() {
        if (this.state === this.states.STARTED) {
            let retval = true;

            if (this.segments.length) {
                this.container.remove(this.segments.pop());

                this.clearMarkers();
            } else {
                this.state = this.states.NONE; // no more segments to remove
                retval = false;
            }

            emitEvent('status', { message: this.statusMessage });

            return retval;
        }
    }


    planeFromSegments () {
        return planeFromPoints(this.segments.map((s) => s.geometry.vertices[1]));
    }


    /**
     * Convert line segments to polygon geometry.
     *
     * @param {PlaneDescriptor} [plane]
     */
    getPolygonGeometry(plane) {
        if (plane === undefined)
            plane = this.fitPlane;

        let vv = [ this.segments[0].geometry.vertices[0].clone() ];
        this.segments.forEach(s => vv.push(s.geometry.vertices[1].clone()));

        /** @type {import('three').Plane} */
        var p = new Plane();
        p.setFromNormalAndCoplanarPoint(plane.normal, plane.centroid);

        let vvp = vv.map(v => p.projectPoint(v));

        // use shape to triangulate polygon formed by segment vertices
        /** @type {import('three').Shape} */
        let shape = new Shape();

        shape.moveTo(vvp[0].x, vvp[0].z);

        vvp.slice(1).forEach(vp => shape.lineTo(vp.x, vp.z));

        /** @type {import('three').ShapeGeometry} */
        let sg = new ShapeGeometry(shape);

        /** @type {import('three').Geometry} */
        let g  = new Geometry();

        let minY = Math.min(...vv.map(v => v.y));
        let maxY = Math.max(...vv.map(v => v.y));

        for (let v of sg.vertices) {
            let l = new Line3(
                new Vector3(v.x, minY - 10, v.y),
                new Vector3(v.x, maxY + 10, v.y)
            );

            g.vertices.push(p.intersectLine(l));
        }

        sg.faces.forEach((f) => {
            // point faces outward instead of inward
            [ f.c, f.a ] = [ f.a, f.c ];

            g.faces.push(f);
        });

        return g;
    }

    /**
     * Construct the basis matrix for the coordinate system centered at the centroid
     * of this polygon in units of (normal to azimuth, normal to plane, azimuth).
     *
     * @param {number} [azimuth = this.azimuth] Option to pass in azimuth heading
     *   because deriving it directly from the fit plane leaves a janky-looking
     *   grid not really aligned with the ridge direction.
     */
    getLocalCoordinateFrameMatrix(azimuth = this.azimuth) {
        const yAxis = this.fitPlane.normal;

        const zAxis = headingToDirectionVector(azimuth)
            .projectOnPlane(this.fitPlane.normal)
            .normalize();
        const xAxis = new Vector3().crossVectors(yAxis, zAxis);

        return new Matrix4().makeTranslation(
            this.fitPlane.centroid.x,
            this.fitPlane.centroid.y,
            this.fitPlane.centroid.z
        ).multiply(
            new Matrix4().makeBasis(xAxis, yAxis, zAxis)
        );
    }

    /**
     * Construct a Group whose world transform is the local
     * coordinate frame matrix. This exists primarily so that we
     * can expose the three.js API for frame transforms with the
     * manually constructed coordinate frame matrix.
     *
     * @param {number} [azimuth = this.azimuth] Optional override for azimuth heading.
     */
    getLocalCoordinateFrameGroup(azimuth = this.azimuth) {
        const group = new Group();

        this.getLocalCoordinateFrameMatrix(azimuth).decompose(
            group.position,
            group.quaternion,
            group.scale
        );

        group.updateMatrix();
        group.updateMatrixWorld(true);

        return group;
    }

    /**
     * 2D projection of the given PolyContext.
     * @param {number} [azimuth = this.azimuth] Optional override for azimuth heading.
     */
    getLocalCoordinateFrame2DProjection(azimuth = this.azimuth) {
        return this.getVerticesInCoordinateFrameOf(
            this.getLocalCoordinateFrameGroup(azimuth)
        ).map(
            v => v.projectOnPlane(UP)
        );
    }

    /**
     * Polygon object for the 2D projection of the given PolyContext.
     * @param {number} [azimuth = this.azimuth] Optional override for azimuth heading.
     */
    getLocalCoordinateFramePoly2(azimuth = this.azimuth) {
        return new Polygon({
            vertices: this.getLocalCoordinateFrame2DProjection(azimuth).map(
                v => new Vector2(v.x, v.z)
            ),
            fixWindingOrder: true
        });
    }

    getLocalCoordinateBasisAlignedBoundingBox() {
        return new BasisAlignedBoundingBox(
            this.getLocalCoordinateFrameMatrix()
        ).grow(
            ...this.getVertices()
        );
    }

    /**
     * Create 3D geometry based on this context.
     *
     * Pass minY value to treat this polygon as a roof and generate
     * complementing walls and floor; with no arguments treat this polygon as a
     * keepout and generate complementing walls and ceiling.
     */
    getClosedVolumeGeometry(minY) {
        let gg = [];

        if (minY !== undefined || this.height > 0) {
            let g = this.getPolygonGeometry(this.fitPlane);

            /** @type {import('three').Geometry} */
            let gWalls = new Geometry();

            let vp1, vp2;

            let modify;

            if (minY === undefined) {
                /** @type {import('three').Vector3} */
                let offset = (this.extrude === 'vertical') ?
                    jUnitVector.clone().multiplyScalar(this.height) :
                    this.normal.clone().multiplyScalar(this.height);

                /** @type {import('three').Plane} */
                var p = new Plane();

                p.setFromNormalAndCoplanarPoint(offset.clone().normalize(),
                                                this.fitPlane.centroid);

                modify = (v) => v.copy(p.projectPoint(v).add(offset));
            } else {
                modify = (v) => v.setY(minY);
            }

            for (let v of g.vertices) {
                let v1 = modify(v.clone());
                let v2 = v.clone();

                if (vp1) {
                    let len = gWalls.vertices.length;

                    gWalls.vertices.push(vp1, v1, vp2, v2);
                    gWalls.faces.push(
                        new Face3(len,   len+1, len+2),
                        new Face3(len+1, len+3, len+2)
                    );
                }

                vp1 = v1.clone();
                vp2 = v2.clone();
            }

            // last remaining wall to close loop
            let len = gWalls.vertices.length;

            gWalls.vertices.push(vp1, modify(g.vertices[0].clone()),
                                 vp2,        g.vertices[0].clone());
            gWalls.faces.push(
                new Face3(len,   len+1, len+2),
                new Face3(len+1, len+3, len+2)
            );

            // cap walls from one end
            for (let v of g.vertices)
                modify(v);

            // fix face orientation
            let gFix = (minY === undefined) ? gWalls : g;

            for (let f of gFix.faces)
                [ f.c, f.a ] = [ f.a, f.c ];

            gg.push(g);
            gg.push(gWalls);
        }

        return gg;
    }


    clearMarkers() {
        for (let m of this.markersSegment)
            this.container.remove(m);

        this.markersSegment = [];

        for (let m of this.markersMidpoint)
            this.container.remove(m);

        this.markersMidpoint = [];
    }


    clear() {
        this.state = this.states.NONE;

        for (var i = 0; i < this.segments.length; i++) {
            this.container.remove(this.segments[i]);
        }

        this.segments = [];

        this.clearMarkers();

        emitEvent('status', { message: this.statusMessage });
    }


    scaleMarkers() {
        for (let m of this.markersSegment) {
            var v = new Vector3();

            v.setFromMatrixPosition(m.matrixWorld);
            let scale = v.sub(this.camera.position).length() / 150;

            m.scale.set(scale, scale, scale);
        }

        for (let m of this.markersMidpoint) {
            var v = new Vector3();

            v.setFromMatrixPosition(m.matrixWorld);
            let scale = v.sub(this.camera.position).length() / 300;

            m.scale.set(scale, scale, scale);
        }

        this.scaleAnnotation();
    }


    //// MOUSE EVENTS


    /**
     * Return true when we intersect a marker.
     */
    mouseDown(event, mouse, panning) {
        if (this.state === this.states.CLOSED && event.button === MOUSE.LEFT) {
            // start segment marker dragging
            this.raycaster.setFromCamera(mouse, this.camera);

            var index;

            if ( (index = this.intersectObjectIndex(this.markersSegment)) !== null ) {
                this.movementMarker = index;

                //var geometry = new Geometry();
                //geometry.vertices.push(new Vector3(0, 0, 0), new Vector3(0, 0, 0));

                //var line = new Line(geometry, Materials.guideClosed);
                //line.computeLineDistances();

                //this.moveIntersections.push(line);
                //this.scene.add(line);

                //var geometry = new Geometry();
                //geometry.vertices.push(new Vector3(0, 0, 0), new Vector3(0, 0, 0));

                //var line = new Line(geometry, Materials.guideClosed);
                //line.computeLineDistances();

                //this.moveIntersections.push(line);
                //this.scene.add(line);

                // remove this polygon vertex from list of snap points
                // list will be rebuilt after mouse is released
                var vv = [];

                for (var i = 0; i < this.vvSnap.length; i++) {
                    if (!this.vvSnap[i].equals(this.markersSegment[index].position))
                        vv.push(this.vvSnap[i]);
                }

                this.vvSnap = vv;

                return true;
            }

            if ( (index = this.intersectObjectIndex(this.markersMidpoint)) !== null ) {
                this.splitMarker = index;
                return true;
            }
        }

        return false;
    }


    mouseMove(mouse, panning) {
        if (panning) {
            this.scaleMarkers();
        }

        if (this.state === this.states.STARTED) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            var point = intersects[0].point;

            if (this.segments.length > 0) {
                this.clearMarkers();
                this.moveSegment(point);
            }
        } else if (this.state === this.states.CLOSED) {
            this.raycaster.setFromCamera(mouse, this.camera);
            let intersects = this.raycaster.intersectObject(this.movementPlane);

            if (intersects.length > 0) {
                if (this.movementMarker !== null) { // resize the polygon
                    this.moveVertex(this.movementMarker, intersects[0].point);

                    this.updateLengths();

                    this.onVertexMove(this.movementMarker);
                } else if (this.splitMarker !== null) { // add new vertex
                    var len = this.segments.length; // length before insert
                    this.insertSegment(this.splitMarker, intersects[0].point);

                    this.updateLengths();

                    var next = (this.splitMarker + 1) % len;

                    this.onSegmentInsert(next, this.splitMarker);

                    this.movementMarker = next;
                    this.splitMarker = null;
                } else {
                    let mi = this.intersectObjectIndex(this.markersSegment);

                    if (this.enableArrowWidget && mi === null) {
                        let p = intersects[0].point;

                        let minD = 10, s;

                        // highlight segment under cursor
                        for (let i = 0; i < this.line3s.length; i++) {
                            let vc = this.line3s[i].closestPointToPoint(p, true);
                            let d = vc.distanceTo(p);

                            if (d < minD) {
                                minD = d;
                                s = i;
                            }
                        }

                        if (s !== undefined) {
                            let pointer = false;

                            for (let i = 0; i < this.segments.length; i++) {
                                if (s === i && minD < 0.1) {
                                    this.segments[i].material = this.materialHighlight;
                                    this.segments[i].selected = true;

                                    pointer = true;
                                } else {
                                    this.segments[i].material = this.materialOutline;
                                    this.segments[i].selected = false;
                                }
                            }

                            mouseHandler.setCursor('pointer', 'polyCtx', pointer);
                        }
                    } else if (mi !== null) {
                        for (let s of this.segments) {
                            s.material = this.materialOutline;
                            s.selected = false;
                        }
                    }
                }
            }

            if (this.arrows.length > 0) {
                for (let arrow of this.arrows)
                    arrow.select(false);

                let intersects = this.raycaster.intersectObjects(this.arrows, true);

                if (intersects.length > 0) {
                    let id = intersects[0].object.uuid;

                    let pointer = false;

                    for (let arrow of this.arrows) {
                        arrow.select(arrow.line.uuid === id || arrow.cone.uuid === id);

                        if (arrow.selected)
                            pointer = true;
                    }

                    mouseHandler.setCursor('pointer', 'polyCtx', pointer);
                }
            }
        }
    }


    mouseUp(event, mouse, panning, vSnap) {
        if (event.button !== MOUSE.LEFT)
            return;

        if (this.state === this.states.NONE) {
            if (this.mouseAddSegment(mouse, vSnap)) {
                this.state = this.states.STARTED;

                emitEvent('status', { message: this.statusMessage });
            }
        } else if (this.state === this.states.STARTED) {
            if (this._isClosedLoop()) {
                if (this.dashedLine)
                    this.container.remove(this.dashedLine);

                this.createMovementPlane();
                this.refreshOffsetSegment();
                this.state = this.states.CLOSED;
                this.parentSnap = false;

                if (this.shape === "poly")
                    this.addAnnotation();

                this.updateLengths();
                this.onComplete();
            } else {
                this.mouseAddSegment(mouse, vSnap);
            }

            emitEvent('status', { message: this.statusMessage });
        } else if (this.state === this.states.CLOSED) {
            if (this.movementMarker !== null || this.splitMarker !== null) {
                // end movement or split marker drag
                this.movementMarker = null;
                this.splitMarker    = null;

                for (let mi of this.moveIntersections)
                    this.scene.remove(mi);

                this.moveIntersections = [];

                this.refreshSegmentCentroid();

                if (this.shape === "poly")
                     this.addAnnotation();

                this.updateLengths();
                this.onShapeChanged();
                this.refreshOffsetSegment();
                this.updateHighlightPlane();
                this.hideIntersectionLines();

            } else if (this.enableArrowWidget) {
                if (this.arrows.length > 0) {
                    // select direction if mouseup occurs on arrow widget's arrow
                    this.raycaster.setFromCamera(mouse, this.camera);
                    var intersects = this.raycaster.intersectObjects(this.arrows, true);

                    if (intersects.length > 0) {
                        let id = intersects[0].object.uuid;
                        let dir;

                        for (let arrow of this.arrows) {
                            if (arrow.line.uuid === id || arrow.cone.uuid === id) {
                                dir = arrow.val;
                                break;
                            }
                        }

                        if (this.onSelectDir)
                            this.onSelectDir(dir);

                        for (let arrow of this.arrows)
                            this.scene.remove(arrow);

                        this.arrows = [];
                        this.arrowsUsed = true;

                        emitEvent('status', { message: this.statusMessage });
                    }
                } else {
                    // show arrow widget if mouseup occurs on segment and we haven't disabled arrows
                    // also see: AutoSegments.js and automatic tilt/az selection
                    for (let s of this.segments) {
                        if (s.selected) {
                            let vv = s.geometry.vertices;

                            // NSWE in the relative bearing sense, not absolute cardinal direction sense
                            let north = vv[1].clone().sub(vv[0]).normalize();
                            let south = north.clone().negate();
                            let west = south.clone().cross(UP).normalize();
                            let east = west.clone().negate();

                            let vOffset = new Vector3(0, 0.3, 0);
                            let m = vv[0].clone().add(vv[1]).divideScalar(2).add(vOffset);

                            let nO = m.clone().add(north);
                            let wO = m.clone().add(west);
                            let sO = m.clone().add(south);
                            let eO = m.clone().add(east);

                            // direction of arrows on screen is different from vectors used for az/tilt calculation
                            let vA = vv[0].clone().setY(0);
                            let vB = vv[1].clone().setY(0);

                            let v12 = vB.clone().sub(vA).normalize();
                            let v6 = v12.clone().negate();
                            let v9 = v6.clone().cross(UP).normalize();
                            let v3 = v9.clone().negate();

                            this.arrows = [
                                new Arrow({
                                    dir:        north,
                                    origin:     nO.clone(),
                                    length:     2,
                                    color:      0x0075dc,
                                    headLength: 0.75,
                                    headWidth:  0.5,
                                    val:        v12
                                }),
                                new Arrow({
                                    dir:        west,
                                    origin:     wO.clone(),
                                    length:     2,
                                    color:      0x0075dc,
                                    headLength: 0.75,
                                    headWidth:  0.5,
                                    val:        v9
                                }),
                                new Arrow({
                                    dir:        south,
                                    origin:     sO.clone(),
                                    length:     2,
                                    color:      0x0075dc,
                                    headLength: 0.75,
                                    headWidth:  0.5,
                                    val:        v6
                                }),
                                new Arrow({
                                    dir:        east,
                                    origin:     eO.clone(),
                                    length:     2,
                                    color:      0x0075dc,
                                    headLength: 0.75,
                                    headWidth:  0.5,
                                    val:        v3
                                })
                            ];

                            for (let arrow of this.arrows)
                                this.scene.add(arrow);

                            emitEvent('status', { message: this.statusMessage });

                            break;
                        }
                    }
                }
            }
        }
    }


    moveSegment(dest) {
        // first/staring segment
        var g0  = this.segments[0].geometry;
        var vv0 = g0.vertices;

        // last/current segment
        var gCur  = this.segments[this.segments.length - 1].geometry;
        var vvCur = gCur.vertices;

        vvCur[1].copy(dest); // update to mouse position on model

        var vCur = vvCur[1].clone().sub(vvCur[0]);

        if (this.segments.length > 1) {
            // project current segment onto plane perpendicular to previous segment
            var vvP = this.segments[this.segments.length - 2].geometry.vertices;
            var plane = new Plane(vvP[1].clone().sub(vvP[0]).normalize().negate());
            var vp = plane.projectPoint(vCur);

            // snap to 90 degree angle
            if (this.snap.ninety && vp.angleTo(vCur) < degToRad(5))
                vvCur[1] = vvCur[0].clone().add(vp);

            vCur = vp; // update after snap

            // snap segment length to line perpendicular to starting segment
            if (this.snap.start) {
                var startPlane = new Plane(vv0[1].clone().sub(vv0[0]).normalize().negate());

                // cast ray from current segment to starting plane
                var origin = vvCur[1].clone().sub(vv0[0]);
                var ray = new Ray(origin, vvCur[1].clone().sub(vvCur[0]).normalize());
                var vi = ray.intersectPlane(startPlane);

                // snap to dashed line
                if (this.dashedLine)
                    this.container.remove(this.dashedLine);

                if (vi !== null) {
                    vi.sub(origin);

                    if (vi.length() < vCur.length() * 0.2) {
                        vvCur[1].add(vi);
                        vCur = vvCur[1].clone().sub(vvCur[0]); // update after snap

                        var ls = new Line3(vv0[0].clone(), vvCur[1].clone());
                        var vc = ls.closestPointToPoint(vvCur[0], true);

                        // snap to closest point on dashed line (effectively at 90 degrees)
                        if (vc.distanceTo(vvCur[1]) < vCur.length() * 0.2) {
                            vvCur[1] = vc;
                            vCur = vvCur[1].clone().sub(vvCur[0]); // update after snap
                        }

                        // show dashed line from starting point
                        var geometry = new Geometry();
                        geometry.vertices.push(ls.start, ls.end);

                        this.dashedLine = new Line(geometry, Materials.dashed);
                        this.dashedLine.computeLineDistances();

                        this.container.add(this.dashedLine);
                    }
                }
            }
        }

        // snap to start
        if (this.snap.start && this.segments.length > 2 &&
                vvCur[1].distanceTo(vv0[0]) < vCur.length() * 0.2) {

            vvCur[1].copy(vv0[0]);

            if (this.showSegmentMarkers || this.showMidpointMarkers) {
                for (let s of this.segments) {
                    let [ v1, v2 ] = s.geometry.vertices.map((v) => { return v.clone(); });

                    this.showMarkers(v1, v2);
                }

                this.scaleMarkers();
            }
        }

        gCur.verticesNeedUpdate = true;
    }


    hideIntersectionLines() {
        this.planeIntersections.forEach((pi) => {
            pi.line.material = Materials.invisibleLine;
            pi.line.material.needsUpdate = true;
        });

        if (this.circles)
            this.circles.forEach((c) => this.container.remove(c));
    }


    moveVertex(index, v) {
        var pprev = (this.segments.length + index - 2) % this.segments.length;
        var prev  = (this.segments.length + index - 1) % this.segments.length;
        var next  = (index + 1 ) % this.segments.length;

        var gpp = this.segments[pprev].geometry;
        var gp  = this.segments[ prev].geometry;
        var gi  = this.segments[index].geometry;
        var gn  = this.segments[ next].geometry;

        var vvpp = gpp.vertices;
        var vvp  =  gp.vertices;
        var vvi  =  gi.vertices;
        var vvn  =  gn.vertices;

        var snaps = [];
        var snapTypes = {
            VERTEX:       0,
            INTERSECTION: 1,
            LINE:         2,
            RIGHT:        3
        };

        if (!keyboard.pressed('shift')) {
            this.hideIntersectionLines();

            var pii = this.planeIntersections.slice();

            //var fitPlane = new Plane();
            //fitPlane.setFromNormalAndCoplanarPoint(this.fitPlane.normal.clone(),
            //                                       this.fitPlane.centroid.clone().negate());

            //// find lines of intersection between planes perpendicular to previous
            //// and next vertices
            //var pp = [
            //    [ vvpp[1].clone().sub(vvpp[0]).normalize().negate(), vvpp[1] ],
            //    [  vvn[0].clone().sub( vvn[1]).normalize(),           vvn[0] ]
            //];

            //for (var i = 0; i < pp.length; i++) {
            //    var p = new Plane();
            //    p.setFromNormalAndCoplanarPoint(pp[i][0], pp[i][1].clone().negate());

            //    var fpi = intersectPlanes(p, fitPlane);

            //    if (!fpi)
            //        continue;

            //    var ext = fpi[1].clone().multiplyScalar(10);
            //    var il = new Line3(fpi[0].clone().sub(ext), fpi[0].clone().add(ext));
            //    var mi = this.moveIntersections[i];

            //    mi.geometry.vertices[0] = il.start;
            //    mi.geometry.vertices[1] = il.end;

            //    mi.geometry.verticesNeedUpdate      = true;
            //    mi.geometry.lineDistancesNeedUpdate = true;

            //    mi.computeLineDistances();

            //    pii.push(il);
            //}

            if (this.snap.ninety) {
                var v90 = this.snapToRightAngle(v, vvi[1], vvp[0], 0.3);

                if (v90) {
                    snaps.push([
                        snapTypes.RIGHT,
                        v90.distanceTo(v),
                        v90
                    ]);
                }
            }

            if (this.snap.intersects) {
                let snap = this.snapToLines(v, pii, 0.45);

                if (snap) {
                    // make line visible
                    snap[2].line.material = Materials.planeIntersection;
                    snap[2].line.material.needsUpdate = true;

                    snap[2].line.computeLineDistances();

                    //if (this.circles)
                    //    this.circles.forEach((c) => this.container.remove(c));

                    //var mm = new MeshBasicMaterial({
                    //    color:       0x57d4fd,
                    //    depthTest:   false,
                    //    transparent: true,
                    //    side:        DoubleSide,
                    //    opacity:     0.2
                    //});

                    //let r1 = snap[1].distanceTo(snap[2].point1);
                    //let r2 = snap[1].distanceTo(snap[2].point2);

                    //let g1 = new CircleGeometry(r1, 32);
                    //let g2 = new CircleGeometry(r2, 32);

                    //let c1 = new Mesh(g1, mm);
                    //let c2 = new Mesh(g2, mm);

                    //c1.position.copy(snap[2].point1);
                    //c2.position.copy(snap[2].point2);

                    //c1.lookAt(snap[2].point1.clone().add(snap[2].normal1));
                    //c2.lookAt(snap[2].point2.clone().add(snap[2].normal2));

                    //this.container.add(c1);
                    //this.container.add(c2);

                    //this.circles = [ c1, c2 ];

                    snaps.push([
                        snapTypes.LINE,
                        snap[1].distanceTo(v),
                        snap[1]
                    ]);
                }

                var vInter = this.snapToLineIntersections(v, pii, 0.25);

                if (vInter) {
                    snaps.push([
                        snapTypes.INTERSECTION,
                        vInter.distanceTo(v),
                        vInter
                    ]);
                }
            }

            if (this.snap.vertices) {
                var vVertex = this.snapToClosestVertex(v, this.vvSnap, 0.3);

                if (vVertex) {
                    snaps.push([
                        snapTypes.VERTEX,
                        vVertex.distanceTo(v),
                        vVertex
                    ]);
                }
            }
        }

        // find highest priority snap point
        snaps.sort();

        this.markerSnap90.visible = false;

        if (snaps.length > 0) {
            v = snaps[0][2];

            if (snaps[0][0] === snapTypes.RIGHT) {
                var v2 = v.clone().sub(vvp[0]).normalize().negate().multiplyScalar(0.5);
                var v3 = vvi[1].clone().sub(v).normalize().multiplyScalar(0.5);

                var g = this.markerSnap90.geometry;

                g.vertices[0] = v2.clone().add(v);
                g.vertices[1] = v2.clone().add(v).add(v3);
                g.vertices[2] = v3.clone().add(v);
                g.vertices[3] = v3.clone().add(v).add(v2);

                g.verticesNeedUpdate = true;

                this.markerSnap90.computeLineDistances();
                this.markerSnap90.visible = true;
            }
        }

        vvi[0].copy(v);
        vvp[1].copy(v);

        gi.verticesNeedUpdate = true;
        gp.verticesNeedUpdate = true;

        this.markersSegment[index].position.copy(v);

        // move the two midpoint markers on adjacent segments
        this.markersMidpoint[ prev].position.copy(vvi[0].clone().add(vvp[0]).divideScalar(2));
        this.markersMidpoint[index].position.copy(vvi[0].clone().add(vvn[0]).divideScalar(2));
    }

    setSnapVertices(vv) { this.vvSnap = vv; }

    snapToClosestVertex(v, vv, threshold) {
        // calculate distance to every polygon vertex
        var distances = [];

        for (var i = 0; i < vv.length; i++) {
            var d = vv[i].distanceTo(v);

            if (d > 0)
                distances.push([ d, vv[i] ]);
        }

        distances.sort();

        return (distances.length && distances[0][0] < threshold) ?
            distances[0][1] :
            false;
    }

    snapToLines(v, lines, threshold) {
        // find closest point on each intersection line
        var distances = [];

        for (var i = 0; i < lines.length; i++) {
            var ptp = lines[i].line3.closestPointToPoint(v);

            distances.push([ ptp.distanceTo(v), ptp, lines[i] ]);
        }

        distances.sort();

        return (distances.length && distances[0][0] < threshold) ?
            distances[0] :
            false;
    }


    snapToLineIntersections(v, lines, threshold) {
        var distances = [];

        // doesn't look like we need this
        //this.movementPlane.updateMatrixWorld();

        // find where lines intersect
        for (var i = 0; i < lines.length - 1; i++) {
            for (var j = i + 1; j < lines.length; j++) {
                var is = this.movementPlane.worldToLocal(lines[i].line3.start.clone());
                var ie = this.movementPlane.worldToLocal(lines[i].line3  .end.clone());
                var js = this.movementPlane.worldToLocal(lines[j].line3.start.clone());
                var je = this.movementPlane.worldToLocal(lines[j].line3  .end.clone());

                var res = intersectLineSegments(is.x, is.y, ie.x, ie.y, js.x, js.y, je.x, je.y);

                if (res) {
                    var ip = new Vector3(res.x, res.y, 0);
                    this.movementPlane.localToWorld(ip);

                    distances.push([ ip.distanceTo(v), ip ]);
                }
            }
        }

        distances.sort();

        return (distances.length && distances[0][0] < threshold) ?
            distances[0][1] :
            false;
    }


    snapToRightAngle(v, v1, v2, threshold) {
        // find circle center between two vertices
        var vr = v1.clone().sub(v2).divideScalar(2);
        var vc = vr.clone().add(v2);

        // snap to 90 degree angle
        if (Math.abs(v.distanceTo(vc) - vr.length()) < threshold) {
            var vSnap = v.clone().sub(vc);

            vSnap.normalize().multiplyScalar(vr.length());
            vSnap.add(vc);

            return vSnap;
        }

        return false;
    }


    intersectObjectIndex(objects) {
        var index = null;

        var intersects = this.raycaster.intersectObjects(objects, true);

        if (intersects.length > 0) {
            for (var i = 0; i < objects.length; i++) {
                if (objects[i].uuid == intersects[0].object.uuid)
                    index = i;
            }
        }

        return index;
    }


    /**
     * @private
     * for internal consumption only, use isComplete() externally
     */
    _isClosedLoop() {
        if (this.segments.length < 3)
            return false;

        var first = this.segments[0].geometry.vertices[0];
        var last  = this.segments[this.segments.length - 1].geometry.vertices[1];

        return first.equals(last);
    }

    isComplete() { return this.state === this.states.CLOSED; }

    updateParams({ extrude, height, setback, highlight, colorMode }) {
        if (height !== undefined || (this.height && extrude)) {
            if (this.extrusion) {
                this.container.remove(this.extrusion);
                delete this.extrusion;
            }

            if (height === undefined)
                height = this.height;

            if (height > 0) {
                let g = new Geometry();
                let p = new Plane();

                if (extrude === undefined)
                    extrude = this.extrude;

                let offset = (extrude === 'vertical') ?
                    new Vector3(0, height, 0) :
                    this.normal.clone().multiplyScalar(height);

                p.setFromNormalAndCoplanarPoint(offset.clone().normalize(),
                                                this.fitPlane.centroid);

                for (let s of this.segments) {
                    for (let v of s.geometry.vertices) {
                        v = p.projectPoint(s.localToWorld(v.clone()));
                        g.vertices.push(v);
                    }
                }

                /** @type {import('../../../types/index').NoBufferGeom<import('three').LineSegments>} */
                let poly = new LineSegments(g, this.materialExtrusion);

                poly.position.copy(offset);
                poly.updateMatrixWorld();

                let g2 = new Geometry();

                for (let i = 0; i < this.segments.length; i++) {
                    let s = this.segments[i];

                    g2.vertices.push(   s.localToWorld(s.geometry.vertices[  0].clone()));
                    g2.vertices.push(poly.localToWorld(         g.vertices[i*2].clone()));
                }

                /** @type {import('../../../types/index').NoBufferGeom<import('three').LineSegments>} */
                let s = new LineSegments(g2, this.materialExtrusion);

                /**
                 * @type {import('three').Object3D & {children: [typeof poly, typeof s]}}
                 */
                let e = new Object3D();
                e.add(poly);
                e.add(s);

                this.container.add(e);
                this.extrusion = e;
            }

            this.height = height;
            this.extrude = extrude;
        }

        if (setback !== undefined) {
            if (this.setback) {
                this.container.remove(this.setback);
                delete this.setback;
            }

            if (setback > 0) {
                let poly2 = new Polygon({
                    vertices: this.segments.map((s) => {
                        let p = s.localToWorld(s.geometry.vertices[0].clone());

                        return this.movementPlane.worldToLocal(p);
                    })
                });

                let offset = poly2.shrink(() => { return -setback; });
                let g = new Geometry();

                for (let v of offset.vertices) {
                    let vw = this.movementPlane.localToWorld(new Vector3(v.x, v.y, 0));
                    g.vertices.push(vw);
                }

                g.vertices.push(g.vertices[0].clone());

                /** @type {import('../../../types').NoBufferGeom<import('three').Line>} */
                let s = new Line(g, this.materialExtrusion);

                this.container.add(s);
                this.setback = s;
            }

            this.setbackSize = setback;
        }

        if (colorMode) {
            this.setColorMode(colorMode);
        }

        if (this._isClosedLoop()) {
            this.segments.forEach((s) => {
                s.material = highlight ? this.materialHighlight : this.materialOutline;
            });
        }

        if (this.extrusion) {
            this.extrusion.children.forEach((c) => {
                c.material = highlight ? this.materialHighlight : this.materialExtrusion;
            });
        }
    }

    getPolyline() {
        if (!this.segments) {
            return;
        }

        return [this.segments[0].geometry.vertices[0].clone()].concat(
            this.segments.map(s => s.geometry.vertices[1].clone())
        );
    }

    getSetbackPolyline() {
        if (!this.setback) {
            return;
        }

        return this.setback.geometry.vertices.map(v => this.setback.localToWorld(v.clone()));
    }

    getExtrusions() {
        const kids = this.extrusion.children;
        return {
            top: kids[0].geometry.vertices.map(v => kids[0].localToWorld(v.clone())),
            edges: Draw.exportSegmentGeometry(
                kids[1].geometry.vertices.map(v => kids[1].localToWorld(v.clone()))
            )
        };
    }

    getLines() {
        var lines = [];

        if (this.extrusion) {
            for (let e of this.extrusion.children) {
                let vv = e.geometry.vertices.map(v => e.localToWorld(v.clone()));
                lines.push(...Draw.exportSegmentGeometry(vv));
            }
        }

        if (this.setback) {
            let vv = this.setback.geometry.vertices.map((v) => {
                return this.setback.localToWorld(v.clone());
            });

            lines.push(...Draw.exportLineGeometry(vv));
        }

        if (this.segments) {
            for (let s of this.segments)
                lines.push([ s.geometry.vertices[0].clone(),
                             s.geometry.vertices[1].clone() ]);
        }

        return lines;
    }


    updateLengths() {
        delete this.midpoints;

        this.dimLengths = [];
        this.line3s = [];

        for (let s of this.segments) {
            let vv = s.geometry.vertices;

            this.dimLengths.push(vv[1].clone().sub(vv[0]).length());
            this.line3s.push(new Line3(vv[0].clone(), vv[1].clone()));
        }

        return this.dimLengths;
    }


    getLengths() { return this.dimLengths; }


    getFrameMidpoints(offsetAmount) {
        if (!this.midpoints) {
            var origin = this.getOrigin();
            var { normal, centroid } = this.fitPlane;
            var axis = new Vector3(0, 1, 0);

            var frame = new Object3D();
            frame.position.copy(origin);
            frame.quaternion.setFromUnitVectors(axis, normal.clone().normalize());
            //frame.rotateOnAxis(axis, degToRad(360 - this.azimuth));

            this.scene.add(frame);

            frame.updateMatrixWorld();

            var poly2 = new Polygon({
                vertices: this.getVertices().map((v) => {
                    var vw = frame.worldToLocal(v.clone());
                    return new Vector2(vw.x, vw.z);
                }),
                // fixing winding order for ccw polys results in mismatched midpoints
                fixWindingOrder: false
            });

            this.midpoints = poly2.getMidpointOffsets(() => offsetAmount || 0);

            this.frame = frame;
        }

        return this.midpoints;
    }


    getScreenMidpoints(offsetAmount) {
        const midpoints = this.getFrameMidpoints(offsetAmount);

        var coords = midpoints.map((p) => {
            var v = new Vector3(p.x, 0, p.y);

            return Draw.getScreenCoordinates({
                camera:    this.camera,
                raycaster: this.raycaster,
                container: this.frame,
                v:         v
            });
        });

        return coords;
    }


    getWorldMidpoints(offsetAmount) {
        const midpoints = this.getFrameMidpoints(offsetAmount);

        const coords = midpoints.map((p) => {
            let v = new Vector3(p.x, 0, p.y);

            return this.frame.localToWorld(v);
        });

        return coords;
    }


    get statusMessage() {
        let msg = '';

        if (this.state === this.states.CLOSED && this.enableArrowWidget) {
            if (this.arrows.length === 0 && !this.arrowsUsed) {
                msg = 'Click one of the outline segments to populate tilt and azimuth';
            } else if (this.arrows.length > 0) {
                msg = 'Click an arrow to select azimuth direction';
            }
        } else if (this.state === this.states.NONE) {
            msg = 'Place first point';
        } else if (this.state === this.states.STARTED) {
            if (this.segments.length === 1) {
                msg = 'Place second point';
            } else if (this.segments.length === 2) {
                msg = 'Place third point';
            } else {
                msg = 'Place next point or click first point to close polygon';
            }
        }

        return msg;
    }
}


export { PolyContext };
