import { MeasurementUnits } from './MeasurementsTool';
import { CameraRotator } from '../libs/CameraRotator';
import * as THREE from '../libs/three.module';




class ElevationHeatMap extends CameraRotator {
    constructor() {
        super();

        this.shaderMaterial = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms: {
                'min': { type: 'f' },
                'max': { type: 'f' }
            },
            vertexShader: `
                varying vec4 vPos;

                void main() {
                    vPos = modelMatrix * vec4(position, 1.0);

                    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                }
            `,
            fragmentShader: `
                varying vec4 vPos;

                uniform float min;
                uniform float max;

                float _v(float m1, float m2, float h) {
                    h = mod(h, 1.0);

                    if (h < 1.0/6.0)
                        return m1 + (m2 - m1) * h * 6.0;

                    if (h < 0.5)
                        return m2;

                    if (h < 2.0/3.0)
                        return m1 + (m2 - m1) * (2.0/3.0 - h) * 6.0;

                    return m1;
                }

                vec3 hls_to_rgb(float h, float l, float s) {
                    float m1, m2;

                    if (s == 0.0)
                        return vec3(l, l, l);

                    if (l <= 0.5) {
                        m2 = l * (1.0 + s);
                    } else {
                        m2 = l + s - (l * s);
                    }

                    m1 = 2.0 * l - m2;

                    return vec3(_v(m1, m2, h + 1.0/3.0),
                                _v(m1, m2, h),
                                _v(m1, m2, h - 1.0/3.0));
                }

                void main( void ) {
                    float pos = (vPos.y - min) / (max - min);

                    vec3 color = hls_to_rgb(clamp(0.75 * pos, 0.0, 0.75), 0.5, 1.0);
                    gl_FragColor = vec4(color.r, color.g, color.b, 1.0);
                }
            `
        });

        this.show3d = false;

        this.units = MeasurementUnits.FEET_INCHES;

        this.objects = [];
        this.materials = [];

        this.axes = { X: 0, Y: 1, Z: 2 };
    }


    enable() {
        if (this.enabled)
            return;

        this.enabled = true;

        this.showMap(true);

        if (!this.show3d)
            this.setView3D(false);
    }


    disable() {
        if (!this.enabled)
            return;

        this.showMap(false);

        this.camera.toPerspective();
        this.controls.maxPolarAngle = this.maxControlsAngle;

        this.enabled = false;
    }


    setCamera(camera) {
        this.camera = camera;
    }


    setControls(controls) {
        this.controls = controls;
        this.maxControlsAngle = controls.maxPolarAngle;
    }


    addObject(object) {
        this.objects.push(object);

        var materials = {};

        object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
                materials[child.id] = child.material;
            }
        });

        this.materials.push(materials);

        var bbox = this.getBoundingBox();

        this.shaderMaterial.uniforms['min'].value = bbox.max.y;
        this.shaderMaterial.uniforms['max'].value = bbox.min.y;
    }


    getBoundingBox() {
        var bbox = new THREE.Box3();

        for (var i = 0; i < this.objects.length; i++) {
            bbox.expandByObject(this.objects[i]);
        }

        return bbox;
    }


    showMap(show) {
        var self = this;

        for (var i = 0; i < this.objects.length; i++) {
            this.objects[i].traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    if (show) {
                        child.material = self.shaderMaterial;
                    } else {
                        child.material = self.materials[i][child.id];
                    }

                    child.material.needsUpdate = true;
                }
            });
        }
    }


    getUnits() {
        return this.units;
    }


    setUnits(units) {
        this.units = units;
    }


    setThreshold(min, max) {
        var bbox = this.getBoundingBox();
        var d = bbox.max.y - bbox.min.y;

        this.shaderMaterial.uniforms['min'].value = bbox.max.y - d * (1.0 - max);
        this.shaderMaterial.uniforms['max'].value = bbox.min.y + d * min;
        this.shaderMaterial.needsUpdate = true;

        this.bounds = { min: d * min, max: d * max };

        return this.getThresholdBounds();
    }


    getThresholdBounds() {
        if (this.units === MeasurementUnits.FEET_INCHES) {
            return { min: this.bounds.min * 3.2808, max: this.bounds.max * 3.2808 };
        } else {
            return this.bounds;
        }
    }


    setView3D(show3d) {
        this.show3d = show3d;

        if (this.show3d) {
            this.camera.toPerspective();
            this.controls.maxPolarAngle = this.maxControlsAngle;
            this.moveCameraAngle(0, Math.PI / 4);
        } else {
            this.camera.toOrthographic();
            this.controls.maxPolarAngle = 0;
        }
    }
}


export { ElevationHeatMap };
