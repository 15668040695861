import { Format } from './Format';
import { Validator } from './Validator';
import { GUI } from './Gui.js';


import tippy from 'tippy.js';


class TiltAzimuthGui {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }

    cmd(c, val) {
        return this.options.tool.cmd(c, val);
    }

    init() {
        if (this.initialized)
            return;

        GUI.inputText('.input-panel-tilt', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertTo:   (val) => Format.val(val, 1),
                updateState: (val) => this.cmd('setTilt', val),
                resetState:  () => {
                    const s = this.getState();
                    const pid = s.array.pid;

                    return s.array.planes[pid].tilt;
                }
            })
        });

        GUI.inputText('.input-panel-azimuth', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertTo:   (val) => Format.val(val, 1),
                updateState: (val) => this.cmd('setAzimuth', val),
                resetState:  () => {
                    const s = this.getState();
                    const pid = s.array.pid;

                    return s.array.planes[pid].azimuth;
                }
            })
        });

        this.initialized = true;
    }

    renderState() {
        let s = this.getState();

        const pid     = s.array.pid;
        const tilt    = s.array.planes[pid].tilt;
        const azimuth = s.array.planes[pid].azimuth;
        const specs   = s.array.moduleSpecs[pid][0];

        let azDisabled   = (azimuth === undefined || !specs.dynamicMode) ? 'disabled' : '';
        let tiltDisabled = (tilt    === undefined) ? 'disabled' : '';
        let flash        = '';

        if (s.array.qty === 0) {
            if (s.array.arrayInfo[pid] && s.array.arrayInfo[pid].length > 0) {
                if (azimuth === undefined) {
                    flash = 'flash';
                }
            }
        }

        let t = `
            <div class="block segment-props ${flash}">
                <div class="azimuth">
                    <img src="/styles/images/icons/azimuth-icon.svg" alt="azimuth" data-tippy-content="Azimuth" />

                    <input
                        ${azDisabled}
                        type="text"
                        class="number input-panel-azimuth"
                        value="${Format.val(azimuth, 1)}" />
                </div>

                <div class="tilt">
                    <img src="/styles/images/icons/tilt-icon.svg" alt="tilt" data-tippy-content="Tilt" />

                    <input
                        ${tiltDisabled}
                        type="text"
                        class="number input-panel-tilt"
                        value="${Format.val(tilt, 1)}" />
                </div>

                <div class="info">
                    <div class="qmark-info">
                        <div class="tooltip-content">
                            To add the tilt and azimuth: First, create an outline, then select the line and choose the arrow in the direction of your module orientation. For a tutorial, see <a style="color:var(--white); outline: none !important; text-decoration: underline !important;" target="_blank" href='https://www.youtube.com/watch?v=Kj3PgyYk3hQ&list=PLxvd904aUdMMkMFfA_OqzP0N1_D3egVqL&index=9&t=0s'>here</a>.<br><br>

                            <strong>Helpful tip:</strong> For residential properties, typically hover over the line on the ridge or the eave and select the perpendicular arrow.
                        </div>
                    </div>
                </div>
            </div>
        `;

        $(this.options.selector).html(t);

        tippy('.segment-props .qmark-info', {
            animation:   'scale',
            inertia:     true,
            content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
            allowHTML:   true,
            interactive: true
        });

        tippy('.segment-props [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'bottom'
        });
    }
}


export { TiltAzimuthGui };
