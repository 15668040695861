import { emitEvent, project, renderer, render, measurementsTool, toolSelector, viewer } from '../Viewer.js';
import { Net } from '../libs/Net';
import { Config } from '../../bootstrap/Config';

class ImageTool {
    constructor() {
    }

    /**
     *
     * @param {'profilePicture' | 'screenshot'} c
     * @param {any} val
     */
    cmd(c, val) {
        switch (c) {
            case 'profilePicture':
                return this.profilePicture();
            case 'screenshot':
                return this.screenshot();
            default:
                console.log(`Unknown command: ${c} ${val}`);
        }
    }


    async profilePicture() {
        var dataUrl = renderer.domElement.toDataURL('image/png');

        emitEvent('progress', {percent: 50});

        const decimatorUrl = await Net.getDecimatorUrl();
        const data = await Net.postJSON(`${decimatorUrl}/resize`, {
            source: dataUrl,
            width: 600,
            height: 600,
            key: project.id + '/thumbnail-' + Date.now() + '.png'
        });

        globalThis.viewer.thumbnail = data.resized;
        emitEvent('progress', {percent: 100});
        emitEvent('status', {message: 'Profile picture saved'});
    }


    async screenshot() {
        let dataUrl;

        if (toolSelector.isEnabled(measurementsTool)) {
            // measurements display is an html overlay and not visible in the
            // screenshot, so render an in-scene tooltip instead
            measurementsTool.tooltip.show();
            render();

            dataUrl = renderer.domElement.toDataURL('image/png');

            measurementsTool.tooltip.hide();
            render();
        } else {
            dataUrl = renderer.domElement.toDataURL('image/png');
        }

        emitEvent('progress', {percent: 50});

        const decimatorUrl = await Net.getDecimatorUrl();
        /** @type {{success: boolean, resized: string}} */
        const data = await Net.postJSON(`${decimatorUrl}/resize`, {
            source: dataUrl,
            width: renderer.domElement.clientWidth,
            height: renderer.domElement.clientHeight,
            key: project.id + '/screenshot-' + Date.now() + '.png'
        });

        emitEvent('progress', {percent: 100});
        emitEvent('status', {message: "Screenshot saved"})
        return data.resized;
    }
}

export { ImageTool };
