
import * as d3 from 'd3';



function propMerge(obj1, obj2) {
    var result = {};

    for (var attr in obj1) {
        result[attr] = obj1[attr];
    }
    for (var attr in obj2) {
        result[attr] = obj2[attr];
    }

    return result;
}

function roundedRectTop(x, y, w, h, r) {
    return 'M' + x + ',' + y +
           'v' + (-h + r) +
           'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r +
           'h' + (w - 2 * r) +
           'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r +
           'v' + (h - r) +
           'h' + -w +
           'z';
}

function roundedRectBottom(x, y, w, h, r) {
    return 'M' + x + ',' + y +
           'v' + (h - r) +
           'a' + r + ',' + r + ' 0 0 0 ' + r + ',' + r +
           'h' + (w - 2 * r) +
           'a' + r + ',' + r + ' 0 0 0 ' + r + ',' + -r +
           'v' + (-h + r) +
           'h' + -w +
           'z';
}

function roundedRect(x, y, w, h, r) {
    if (h > 0)
        return roundedRectTop(x, y, w, h, r);
    else
        return roundedRectBottom(x, y, w, -h, r);
}


var GRAPH = {};

GRAPH.DOMAIN_MONTHS = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

GRAPH.max2d = function (data) {
    var max = [];

    for (var i = 0; i < data.length; i++) {
        max.push(d3.max(data[i]));
    }

    return d3.max(max);
};

GRAPH.min2d = function (data) {
    var min = [];

    for (var i = 0; i < data.length; i++) {
        min.push(d3.min(data[i]));
    }

    return d3.min(min);
};

GRAPH.monthly = function (data, params) {
    var defaults = {
        barWidth:    6,
        aspect:      1,
        fontSize:    2,
        color:       '#6E30E5',
        labelColor1: '#78819A',
        labelColor2: '#2E3134',
        bgColor:     '#FFF',
        type:        'bar',
        compress:    false,
        format:      d3.format('s'),
        paddingLeft: 10,
        ticks:       6,
        labelFormat: d3.format('.2s')
    };

    if (params === null || params === undefined) {
        params = {};
    }

    var o = propMerge(defaults, params);

    if (!Array.isArray(data[0])) {
        data = [ data ];
    }

    var vy = 100;
    var vx = vy * o.aspect;

    var paddingRight  = 5;
    var paddingBottom = 10;

    var svg = d3.select('body').append('svg')
        .remove() // we'll attach it to the DOM later
        .attr('xmlns',       'http://www.w3.org/2000/svg')
        .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
        .attr('viewBox',     '0 0 ' + vx + ' ' + vy)
        //.attr('width',       '1.23456789in')
        //.attr('height',      '1.23456789in')
        .attr('font-size',   o.fontSize)
        .attr('font-family', 'Poppins, Helvetica, Arial, sans-serif');

    // BG
    //svg.append('rect')
    //    .attr('width',  vx)
    //    .attr('height', vy)
    //    .attr('fill', o.bgColor);

    var body = svg.append('g')
        .attr('transform', 'translate(' + o.paddingLeft + ', ' + (vy - paddingBottom) + ')');

    // X AXIS
    var range = [];
    var xOffset = vx / 12 / 2;

    for (var i = 0; i < 12; i++) {
        range.push(xOffset + (vx - o.paddingLeft - paddingRight) / 12 * i);
    }

    var domain = GRAPH.DOMAIN_MONTHS;//.map(function (m) { return m.toUpperCase(); });
    var x = d3.scale.ordinal().domain(domain).range(range);

    var xAxis = d3.svg.axis()
        .scale(x)
        .orient('bottom');

    var xg = body.append('g');
    xg.attr('class', 'x axis')
        .call(xAxis);

    xg.selectAll('text')
        .attr('y', 3)
        .attr('font-size', o.fontSize * 0.8)
        .attr('fill', o.labelColor1);

    xg.selectAll('path').attr('fill', 'none');

    // Y AXIS
    var minData = GRAPH.min2d(data);
    var maxData = GRAPH.max2d(data);

    var minY = o.compress ? maxData - (maxData - minData) * 4 : Math.min(0, minData);
    var maxY = o.compress ? maxData : Math.max(maxData, 0);

    var yOffset = o.fontSize * 3;
    var y = d3.scale.linear().range([ 0, -vy + yOffset + paddingBottom ]).domain([ minY, maxY ]);

    var yAxis = d3.svg.axis()
        .scale(y)
        .orient('left')
        .ticks(o.ticks)
        .tickFormat(o.format);

    var yg = body.append('g');
    yg.attr('class', 'y axis')
        .call(yAxis);

    yg.selectAll('text')
        .attr('x', -2)
        .attr('fill', o.labelColor1);

    yg.selectAll('path').attr('fill', 'none');

    // FIGURES
    var graph = body.append('g');

    var bar = function (j) {
        return function (d, i) {
            var w  = o.barWidth / data.length;
            var h  = -( y(d) - y(0) ) ;
            var x0 = x(GRAPH.DOMAIN_MONTHS[i]) - o.barWidth/2 + w*j;
            var y0 = y(0);
            if(h == 0){
                // adjust this to not look bad
                return roundedRect(x0, y0, w, 1.25, w/2);

            }

            return roundedRect(x0, y0, w, h, w/2);
        };
    };

    /* if the bar graph only has 12 items, this look good, but otherwise we need to use the other function
    var bar = function (j) {
        return function (d, i) {
            var w  = (o.barWidth / data.length)/2;
            var h  = -( y(d) - y(0) );
            var x0 = x(GRAPH.DOMAIN_MONTHS[i]) - (o.barWidth + w*j)/4;
            var y0 = y(0);

            return roundedRect(x0, y0, w, h, w/2);
        };
    };*/

    var line = d3.svg.line()
        .x(function(d, i) { return x(GRAPH.DOMAIN_MONTHS[i]); })
        .y(function(d)    { return y(d) + 1; });
        //.curve(d3.curveBundle); This isn't working with me rn, but should help curve our lines..
        //.curve (d3.curveBundle.beta (1) ) ;

    var posX = function (j) {
        return function (d, i) {
            var w  = o.barWidth / data.length;

            return x(GRAPH.DOMAIN_MONTHS[i]) - o.barWidth/2 + w/2 + w*j;
        };
    };

    var color = function (j) {
        return (o.color[0].length === 1) ? o.color : o.color[j];
    };

    for (var j = 0; j < data.length; j++) {
        if (o.type === 'bar' || o.type[j] === 'bar') {
           graph.selectAll('.bar-' + j)
                .data(data[j])
                .enter()
                .append('path')
                .attr('d', bar(j))
                .attr('class', 'bar-' + j)
                .attr('fill', color(j));
        } else if (o.type === 'line' || o.type[j] === 'line') {
            graph.append('path')
                .attr('d', line(data[j]))
                .attr('fill', 'none')
                .attr('stroke-width', .75)
                .attr('stroke-linejoin', 'round')
                .attr('stroke', color(j))
                .attr('class', 'line-' + j);
        }
    }

    for (var j = 0; j < data.length; j++) {
        var labelColor = (o.type === 'line') ? color(j) : o.labelColor2;

        graph.selectAll('.label-' + j)
            .data(data[j])
            .enter()
            .append('text')

            .attr('x', posX(j))
            .attr('y', function (d) { return y(Math.max(0, d)) - 1; })
            .text(     function (d) { return o.labelFormat(Math.round(d)); })

            .style('text-anchor', 'middle')
            .attr('class', 'n label-' + j)
            .attr('font-size', o.fontSize * .85)
            .attr('fill', labelColor);
    }

    return svg;
};



export {
  GRAPH,
  roundedRect,
  roundedRectBottom,
  roundedRectTop,
  propMerge
};
