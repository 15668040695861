function round(n, precision) {
    if (precision === undefined)
        precision = 2;

    const p = Math.pow(10, precision)

    return Math.round(n * p) / p;
}

var makeConversion = function(u, conversion, precision) {
    if (precision === undefined)
        precision = 2;

    var p = Math.pow(10, precision)

    return Math.round(u * conversion * p) / p;
}

var inchesToMeters = function (inches, p) { return makeConversion(inches, 0.0254, p) };
var inchesToMm     = function (inches, p) { return makeConversion(inches, 25.4, p) };
var mmToInches     = function (mm, p)     { return makeConversion(mm, 0.0393701, p) };
var metersToInches = function (m, p)      { return makeConversion(m, (100 / 2.54), p) };
var cmToMeters     = function (cm, p)     { return makeConversion(cm, 0.01, p) };
var mmToMeters     = function (mm, p)     { return makeConversion(mm, 0.001, p) };
var metersToCm     = function (m, p)      { return makeConversion(m, 100, p) };
var metersToMm     = function (m, p)      { return makeConversion(m, 1000, p) };
var feetToMeters   = function (ft, p)     { return makeConversion(ft, 0.3048, p) };
var metersToFeet   = function (m, p)      { return makeConversion(m, 3.28084, p) };
var mSqToFtSq      = function (m, p)      { return makeConversion(m, 10.764, p) };
var ftSqToMSq      = function (ft, p)     { return makeConversion(ft, 0.0929, p) };
var perFtSqToPerMSq = mSqToFtSq;
var perMSqToPerFtSq = ftSqToMSq;

export {
  perMSqToPerFtSq,
  perFtSqToPerMSq,
  ftSqToMSq,
  mSqToFtSq,
  metersToFeet,
  feetToMeters,
  metersToMm,
  metersToCm,
  mmToMeters,
  cmToMeters,
  metersToInches,
  mmToInches,
  inchesToMm,
  inchesToMeters,
  makeConversion,
  round
};