
import { CircleGeometry, ConeGeometry, Matrix4, Mesh, MeshBasicMaterial, Spherical, Sprite, SpriteMaterial, Object3D, TextureLoader, Vector3 } from '../libs/three.module';

class Compass extends Object3D {

    constructor(options) {
        super();

        const { w, l, homeImg, scale, homeColor } = options;

        const gSmCircle = new CircleGeometry(5, 32);
        const mSmCircle = new MeshBasicMaterial({
            color:       0,
            opacity:     0.3,
            transparent: true,
        });
        const smCircle = new Mesh(gSmCircle, mSmCircle);
        smCircle.position.set(0, 0, -1);

        this.add(smCircle);

        const gLgCircle = new CircleGeometry(12, 32);
        const mLgCircle = new MeshBasicMaterial({
            color:       0,
            opacity:     0.3,
            transparent: true,
        });
        const lgCircle = new Mesh(gLgCircle, mLgCircle);
        lgCircle.position.set(0, 0, -2);

        this.add(lgCircle);

        const gNeedleNorth = new ConeGeometry(w, l, 8);
        gNeedleNorth.translate(0, l/2, 0);
        const mNeedleNorth = new MeshBasicMaterial({ color: 0xff0000 });
        const needleNorth = new Mesh(gNeedleNorth, mNeedleNorth);

        this.add(needleNorth);

        const gNeedleSouth = new ConeGeometry(w, l, 8);
        gNeedleSouth.translate(0, l/2, 0);
        const mNeedleSouth = new MeshBasicMaterial({ color: 0xffffff });
        const needleSouth = new Mesh(gNeedleSouth, mNeedleSouth);
        needleSouth.applyMatrix(new Matrix4().makeScale(1, -1, 1)); // reverse direction

        needleNorth.add(needleSouth);

        const homeSpriteMap = new TextureLoader().load(homeImg);
        const homeSpriteMaterial = new SpriteMaterial({
            map:         homeSpriteMap,
            color:       homeColor,
            transparent: true
        });
        const home = new Sprite(homeSpriteMaterial);

        home.scale.set(scale, scale, scale);
        home.position.set(16, -16, 0);

        this.add(home);

        this.dir = new Vector3(0,0,1);
        this.sph = new Spherical();

        this.home   = home;
        this.needle = needleNorth;
        this.circle = lgCircle;
        this.smCircle = smCircle;
    }


    set azimuth(theta) { this.needle.rotation.set(0, 0, theta); }

    set highlight(val) {
        if (val) {
            this.smCircle.material.color.setHex(0xffffff);
            this.smCircle.material.opacity = 0.2;
        } else {
            this.smCircle.material.color.setHex(0x0);
            this.smCircle.material.opacity = 0.3;
        }

        this.circle.material.needsUpdate = true;
    }
}


export { Compass };
