import { Tooltip } from '../libs/Tooltip';
import { Line } from '../elements/Line';
import { DoubleSide, Line as ThreeLine, LineBasicMaterial, CircleGeometry, Matrix4, Mesh, MeshBasicMaterial, MOUSE, PlaneBufferGeometry, Vector3 } from '../libs/three.module';

var Protractor = function () {
    var radius   = 2;
    var segments = 32;
    var material = new LineBasicMaterial({
        color: 0x0000ff,
        linewidth: 3
    });
    var geometry = new CircleGeometry(radius, segments);

    geometry.vertices.shift(); // remove center vertex
    geometry.vertices.push(geometry.vertices[0].clone()); // close ring

    this.ring = new ThreeLine(geometry, material);
    this.ring.visible = false;

    this.normalLine = new Line({
        color: 0x0000ff,
        linewidth: 3
    });
    this.normalLine.setVisible(false);

    this.orientationLine = new Line({
        color: 0x0000ff,
        linewidth: 1
    });
    this.orientationLine.setVisible(false);

    this.line1 = new Line({
        color: 0x0000ff,
        linewidth: 1
    });
    this.line1.setVisible(false);

    this.line2 = new Line({
        color: 0x0000ff,
        linewidth: 1
    });
    this.line2.setVisible(false);

    this.anglePlane = new Mesh(
        new PlaneBufferGeometry(400, 400, 8, 8),
        new MeshBasicMaterial({ visible: false, side: DoubleSide })
    );

    this.tooltip = new Tooltip();

    this.states = {NONE: 0, ADJUST: 1, LINE1: 2, LINE2: 3, STOPPED: 4};
    this.state = this.states.NONE;

    this.snapAngles = [5, 85, 95, 175];

    var snapMarkerMaterial = new LineBasicMaterial({
        color:     0x00ff00,
        linewidth: 1
    });
    var snapMarkerGeometry = new CircleGeometry(0.1, 6);
    snapMarkerGeometry.vertices.shift(); // remove center vertex

    this.snapMarker = new ThreeLine(snapMarkerGeometry, snapMarkerMaterial);
    this.snapMarker.visible = false;

    this.meshes = [];
};

Protractor.prototype = {};
Protractor.prototype.constructor = Protractor;

Protractor.prototype.enable = function () { this.enabled = true; };

Protractor.prototype.disable = function () {
    this.enabled = false;
    this.cancel();
};

Protractor.prototype.setCamera    = function (camera) { this.camera = camera; };
Protractor.prototype.setRaycaster = function (rc)     { this.raycaster = rc; };

Protractor.prototype.addMesh = function (mesh) {
    this.meshes.push(mesh);
};

Protractor.prototype._setTooltipPosition = function () {
    if (this.intersect)
        this.tooltip.setPosition(this.intersect.point.clone().project(this.camera));
};

Protractor.prototype.addToScene = function (scene, sceneOrtho) {
    scene.add(this.ring);
    this.normalLine.addToScene(scene);
    this.orientationLine.addToScene(scene);
    this.line1.addToScene(scene);
    this.line2.addToScene(scene);
    scene.add(this.anglePlane);
    scene.add(this.snapMarker);

    sceneOrtho.add(this.tooltip.getSprite());
};

Protractor.prototype.cancel = function () {
    this.state = this.states.NONE;

    this.ring.visible = false;
    this.orientationLine.setVisible(false);
    this.normalLine.setVisible(false);
    this.line1.setVisible(false);
    this.line2.setVisible(false);
    this.snapMarker.visible = false;
    this.tooltip.hide();
};

Protractor.prototype.mouseDown = function (event, mouse, matrixWorld, panning) {
    if (!this.enabled || event.button !== MOUSE.LEFT || panning)
        return;

    if (this.state === this.states.NONE || this.state === this.states.STOPPED) {
        this.state = this.states.ADJUST;

        this.raycaster.setFromCamera(mouse, this.camera);
        var intersects = this.raycaster.intersectObjects(this.meshes, true);

        if (intersects.length === 0)
            return;

        this.intersect = intersects[0];

        this.orientationLine.setVisible(false);
        this.line1.setVisible(false);
        this.line2.setVisible(false);
        this.snapMarker.visible = false;
        this.tooltip.hide();

        var point = this.intersect.point.clone();
        var normal = this.intersect.face.normal.clone();
        var matrix = new Matrix4();
        matrix.extractRotation(matrixWorld);
        normal.applyMatrix4(matrix);

        // show protractor ring
        this.ring.position.set(0, 0, 0);
        this.ring.lookAt(normal);
        this.ring.position.copy(point);
        this.ring.geometry.verticesNeedUpdate = true;
        this.ring.visible = true;

        // set up the measurement anglePlane
        this.anglePlane.position.set(0, 0, 0);
        this.anglePlane.lookAt(normal);
        this.anglePlane.position.copy(point);

        // show normal to the surface
        this.normalLine.setPoints(new Vector3(0, 0, 0), normal);
        this.normalLine.copyPosition(point);
        this.normalLine.setVisible(true);

        this.vSnapStart = new Vector3(0, 1, 0).cross(normal).normalize();
        this.normal = normal;
    } else if (this.state === this.states.ADJUST) {
        this.state = this.states.LINE1;

    } else if (this.state === this.states.LINE1) {
        this.state = this.states.LINE2;

    } else if (this.state === this.states.LINE2) {
        this.state = this.states.STOPPED;
        this._setTooltipPosition();
    }
};

Protractor.prototype.mouseUp = function () { };

Protractor.prototype.mouseMove = function (mouse, tooltipOnly) {
    if (!this.enabled)
        return;

    if (this.state === this.states.ADJUST && !tooltipOnly) {
        this.raycaster.setFromCamera(mouse, this.camera);
        var intersects = this.raycaster.intersectObjects(this.meshes, true);

        if (intersects.length > 0) {
            var point = intersects[0].point;

            this.ring.lookAt(point);
            this.anglePlane.lookAt(point);

            this.orientationLine.setPoints(this.intersect.point, point);
            this.orientationLine.setVisible(true);

            this.normal = point.clone().sub(this.intersect.point).negate().normalize();
            this.normalLine.setPoints(new Vector3(0, 0, 0), this.normal);

            this.vSnapStart = new Vector3(0, 1, 0).cross(this.normal).normalize();
        }
    } else if (this.state === this.states.LINE1 && !tooltipOnly) {
        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObject(this.anglePlane);
        if (intersects.length === 0)
            return;

        this.v1 = intersects[0].point.sub(this.intersect.point);
        var snapStartAngle = this.vSnapStart.angleTo(this.v1) * (180 / Math.PI);

        if (this.snapAngles[1] < snapStartAngle && snapStartAngle < this.snapAngles[2]) {
            var vDirection = this.vSnapStart.clone().cross(this.v1).normalize().round();

            if (this.normal.clone().round().equals(vDirection)) {
                var normal = this.normal;
            } else {
                var normal = this.normal.clone().negate();
            }

            var snapTo = new Vector3();
            snapTo.crossVectors(normal, this.vSnapStart);
            snapTo.normalize();
            snapTo.multiplyScalar(this.v1.length());

            this.v1 = snapTo;
        } else if (snapStartAngle < this.snapAngles[0] || this.snapAngles[3] < snapStartAngle) {
            var snapTo = this.vSnapStart.clone();
            snapTo.normalize();

            if (snapStartAngle > this.snapAngles[3]) {
                snapTo.negate();
            }

            snapTo.multiplyScalar(this.v1.length());

            this.v1 = snapTo;
        }

        if (snapTo) {
            this.snapMarker.position.set(0, 0, 0);
            this.snapMarker.lookAt(this.normal.clone().negate());
            this.snapMarker.position.copy(snapTo.clone().normalize().multiplyScalar(2).add(this.intersect.point));
            this.snapMarker.visible = true;
        } else {
            this.snapMarker.visible = false;
        }

        this.line1.copyPosition(this.intersect.point);
        this.line1.setPoints(new Vector3(0, 0, 0), this.v1);
        this.line1.setVisible(true);

    } else if (this.state === this.states.LINE2) {
        if (!tooltipOnly) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObject(this.anglePlane);
            if (intersects.length === 0)
                return;

            this.line2.setPoints(this.intersect.point, intersects[0].point);
            this.line2.setVisible(true);

            this.v2 = intersects[0].point.sub(this.intersect.point);

            var angle = this.v1.angleTo(this.v2) * (180 / Math.PI);
            angle = Math.round(angle * 100) / 100;
            this.tooltip.setText(angle + "°");
        }

        this._setTooltipPosition();
        this.tooltip.show();
    } else if (this.state === this.states.STOPPED) {
        this._setTooltipPosition();
    }
};

Protractor.prototype.handleKeyboard = function (keyboard) {
    if (!this.enabled)
        return;

    if (keyboard.down('esc'))
        this.cancel();
};



export { Protractor };
