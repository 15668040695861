import { OccludableShadowMaterial } from './OccludableShadowMaterial';
import { OccludableMaterial } from './OccludableMaterial';
import { CylinderGeometry, DoubleSide, Matrix4, Mesh, MeshNormalMaterial, Object3D, PlaneGeometry, Vector3 } from './three.module';


const default_size  = 1;

//material to be used on model
let occludable_material;
let occludable_shadow;


class OcclusionManager {
    constructor() {
        this.occluders = [];
        this.occluders_cy = [];

        this.boxOccludersActive = new Array(8).fill(1);
        this.cylinderOccludersActive = new Array(8).fill(1);

        this.occluder_guide_material = new MeshNormalMaterial({
            side:        DoubleSide,
            transparent: true,
            opacity:     0.2
        });

        this.occluder_guide_highlight_material = new MeshNormalMaterial({
            side:        DoubleSide,
            transparent: true,
            opacity:     0.6
        });

        this.maxOccluders = 8;
    }


    get opacity() {
        return this.occluder_guide_material.opacity;
    }


    set opacity(val) {
        this.occluder_guide_material.opacity = val;
        this.occluder_guide_material.needsUpdate = true;
    }


    getOccludableMaterial(texture) {
        occludable_material = new OccludableMaterial({
            map:        texture,
            side:       DoubleSide,
            shadowSide: DoubleSide
        });

        return occludable_material;
    }


    getOccludableShadowMaterial() {
        if (!occludable_shadow) {
            occludable_shadow = new OccludableShadowMaterial();
        }

        return occludable_shadow;
    }


    placeBoxAt(x,y,z) {
        if (this.occluders.length >= this.maxOccluders) {
            return false;
        } else {
            let occ = new BoxOccluder(x, y, z);
            this.occluders.push(occ);

            return occ.getDisplay(this.occluder_guide_material);
        }
    }


    placeCylinderAt(x, y, z) {
        if (this.occluders_cy.length >= this.maxOccluders) {
            return false;
        } else {
            let occ = new CylinderOccluder(x, y, z);
            this.occluders_cy.push(occ);

            return occ.getDisplay(this.occluder_guide_material);
        }
    }


    remove(occluder_display) {
        for (let i = 0; i < this.occluders.length; i++) {
            if (occluder_display === this.occluders[i].container) {
                this.occluders.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < this.occluders_cy.length; i++) {
            if (occluder_display === this.occluders_cy[i].container) {
                this.occluders_cy.splice(i, 1);
                break;
            }
        }
    }


    hoverOn(obj, parent) {
        if (parent){
            parent.traverse(function(ob) {
                ob.material = this.occluder_guide_material;
            });
        }

        obj.traverse(function(ob) {
            ob.material = occluder_guide_highlight_material;
        });
    }


    hoverOff(obj) {
        obj.traverse(function(ob) {
            ob.material = this.occluder_guide_material;
        });
    }


    // pass occluder bounds and inverse transforms to shader
    update() {
        let bounds     = new Array( 48).fill(0);
        let transforms = new Array(128).fill(0);

        let bounds_cy     = new Array( 48).fill(0);
        let transforms_cy = new Array(128).fill(0);

        for (let i = 0; i < this.occluders.length; i++) {
            this.occluders[i].update();

            bounds.splice(i*6, 6, ...this.occluders[i].bounds);
            transforms.splice(i*16, 16, ...this.occluders[i].invTransform.toArray());
        }

        for (let i = 0; i < this.occluders_cy.length; i++) {
            this.occluders_cy[i].update();

            bounds_cy.splice(i*6, 6, ...this.occluders_cy[i].bounds);
            transforms_cy.splice(i*16, 16, ...this.occluders_cy[i].invTransform.toArray());
        }

        if (occludable_material) {
            occludable_material.uniforms['boxOccludersActive' ].value = this.boxOccludersActive;
            occludable_material.uniforms['occluder_count'     ].value = this.occluders.length;
            occludable_material.uniforms['bounds_array'       ].value = bounds;
            occludable_material.uniforms['occluder_transforms'].value = transforms;

            occludable_material.uniforms['cylinderOccludersActive'].value = this.cylinderOccludersActive;
            occludable_material.uniforms['occluder_cy_count'      ].value = this.occluders_cy.length;
            occludable_material.uniforms['bounds_cy_array'        ].value = bounds_cy;
            occludable_material.uniforms['occluder_cy_transforms' ].value = transforms_cy;
        }

        if (occludable_shadow) {
            occludable_shadow.uniforms['boxOccludersActive' ].value = this.boxOccludersActive;
            occludable_shadow.uniforms['occluder_count'     ].value = this.occluders.length;
            occludable_shadow.uniforms['bounds_array'       ].value = bounds;
            occludable_shadow.uniforms['occluder_transforms'].value = transforms;

            occludable_shadow.uniforms['cylinderOccludersActive'].value = this.cylinderOccludersActive;
            occludable_shadow.uniforms['occluder_cy_count'      ].value = this.occluders_cy.length;
            occludable_shadow.uniforms['bounds_cy_array'        ].value = bounds_cy;
            occludable_shadow.uniforms['occluder_cy_transforms' ].value = transforms_cy;
        }
    }
}


class BoxOccluder {
    constructor(x, y, z) {
        this.x = x;
        this.y = y;
        this.z = z;

        this._invTransform = new Matrix4();
    }


    //return a display object to represent the occlusion area
    getDisplay(material) {
        let rots = [
            new Vector3(  0,  1,  0), // top 0
            new Vector3(  0, -1,  0), // bottom 1
            new Vector3(  0,  0,  1), // front 2
            new Vector3(  0,  0, -1), // back 3
            new Vector3(-20,  0,  0), // left 4
            new Vector3(  1,  0,  0)  // right 5
        ];

        let init_dist = 1;
        let plane_geo = new PlaneGeometry(init_dist*2, init_dist*2);

        this.planes = [];
        this.container = new Object3D();
        this.container.renderOrder = 2;

        for (let i = 0; i < rots.length; i++) {
            let p_mesh = new Mesh(plane_geo, material);
            p_mesh.lookAt(rots[i]);

            let dir = new Vector3();
            p_mesh.getWorldDirection(dir);
            p_mesh.position.addScaledVector(dir, init_dist);
            p_mesh.castShadow = false;
            p_mesh.receiveShadow = false;

            this.planes.push(p_mesh);
            this.container.add(p_mesh);
        }

        this.container.position.set(this.x, this.y, this.z);
        this.container['type'] = 'cube';

        return this.container;
    }


    highlight() {
        this.container.traverse(function(ob) {
            ob.material = occluder_guide_highlight_material;
        });
    }


    update() {
        // find dimensions
        let x_span = (this.planes[5].position.x - this.planes[4].position.x) * 0.5;
        let y_span = (this.planes[0].position.y - this.planes[1].position.y) * 0.5;
        let z_span = (this.planes[2].position.z - this.planes[3].position.z) * 0.5;

        // size guide plane
        this.planes[4].scale.x = z_span;
        this.planes[4].scale.y = y_span;
        this.planes[4].position.x = -x_span;

        this.planes[5].scale.x = z_span;
        this.planes[5].scale.y = y_span;
        this.planes[5].position.x = x_span;

        this.planes[1].scale.x = x_span;
        this.planes[1].scale.y = z_span;
        this.planes[1].position.y = -y_span;

        this.planes[0].scale.x = x_span;
        this.planes[0].scale.y = z_span;
        this.planes[0].position.y = y_span;

        this.planes[3].scale.x = x_span;
        this.planes[3].scale.y = y_span;
        this.planes[3].position.z = -z_span;

        this.planes[2].scale.x = x_span;
        this.planes[2].scale.y = y_span;
        this.planes[2].position.z = z_span;
    }


    get bounds() {
        return [
            this.planes[4].position.x,
            this.planes[5].position.x,
            this.planes[1].position.y,
            this.planes[0].position.y,
            this.planes[3].position.z,
            this.planes[2].position.z,
        ];
    }


    get invTransform() {
        this._invTransform.getInverse(this.container.matrixWorld);

        return this._invTransform;
    }
}


class CylinderOccluder {
    constructor(x, y, z) {
        this.x = x;
        this.y = y
        this.z = z;

        this.mesh;

        this._invTransform = new Matrix4();
    }


    //return a display object to represent the occlusion area
    getDisplay(material) {
        let init_dist = 1;
        let plane_geo = new CylinderGeometry(init_dist, init_dist, init_dist*2, 32);
        let cy_mesh = new Mesh(plane_geo, material);

        cy_mesh.castShadow = false;
        cy_mesh.receiveShadow = false;

        this.mesh = cy_mesh;

        this.container = new Object3D();
        this.container.add( cy_mesh );

        this.container.position.set(this.x, this.y, this.z);
        this.container['type'] = 'cylinder';

        return this.container;
    }


    update() {
    }


    get bounds() {
        return [ -this.mesh.scale.y, this.mesh.scale.y, this.mesh.scale.x, 0, 0, 0 ];
    }


    get invTransform() {
        this._invTransform.getInverse(this.container.matrixWorld);

        return this._invTransform;
    }
}

export {
  OcclusionManager
};
