import { MeasurementUnits } from '../tools/MeasurementsTool';
import { measurementUnits, guiModuleLibrary, prompts, init } from '../Viewer.js';
import { metersToInches, metersToCm, metersToFeet, round, inchesToMeters, cmToMeters, feetToMeters } from '../alibs/Units';
import { Validator } from './Validator';
import { GUI } from './Gui.js';
import { LearnMoreDialogGUI } from './GuiLearnMoreDialog';


import tippy from 'tippy.js';

class SidebarArrayGUI {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;

        this.moduleImages = {
            'poly':             'styles/images/modules/module-polycrystalline.jpg?x=v3',
            'mono':             'styles/images/modules/module-monocrystalline.jpg?x=v3',
            'mono-black':       'styles/images/modules/module-monocrystalline-black.jpg?x=v3',
            'lg-neon':          'styles/images/modules/module-lg-neon.jpg?x=v3',
            'sunpower-x-black': 'styles/images/modules/module-sunpower-x-black.jpg?x=v3',
            'sunpower-x':       'styles/images/modules/module-sunpower-x.jpg?x=v3',
            'poly-blue-2':      'styles/images/modules/module-polycrystalline-2.jpg?x=v3',
            'mono-blue-3':      'styles/images/modules/module-monocrystalline-3.jpg?x=v3',
            'mono-black-4':     'styles/images/modules/module-monocrystalline-black-4.jpg?x=v3',
            'mono-black-5':     'styles/images/modules/module-monocrystalline-black-5.png?x=v3',
        };

        this.learnMoreDialog = new LearnMoreDialogGUI();
    }


    cmd(command, val) {
        this.options.tool.cmd(command, val);
        return val;
    }


    init() {
        let self = this;

        $('body').on('click', 'button.manual-array-fill', function () {
            $(this).prop('disabled', true);
            $(this).attr('aria-disabled', "true");
            self.cmd('fill');
        });

        $('body').on('change', '.auto-fill-params', function () {
            let pid = $(this).val();

            if (pid !== '')
                self.cmd('copySpecsFrom', pid);
        });

        $('body').on('click', '#toggle-array-details', function () {
            self.showArrayDetails = $(this).prop('checked');
            $('.array-details').toggleClass('hidden');
        });

        $('body').on('change', 'input:radio[name=racking]:checked', function () {
            let mountType = $(this).val();
            let specs;
            let inch = (n) => inchesToMeters(n, 100);

            switch (mountType) {
                case "flush-mount":
                    specs = {
                        'hOffset':      inch(1),
                        'vOffset':      inch(1),
                        'heightOffset': inch(4),
                        'orientation':  'portrait',
                        'mountType':    mountType
                    };

                    break;
                case "tilted":
                    specs = {
                        'hOffset':      inch( 1),
                        'vOffset':      inch(12),
                        'heightOffset': inch( 6),
                        'orientation':  'landscape',
                        'mountType':    mountType
                    };

                    break;
                case "ground":
                    specs = {
                        'hOffset':      inch( 1),
                        'vOffset':      inch( 1),
                        'heightOffset': inch(48),
                        'orientation':  'landscape',
                        'mountType':    mountType
                    };

                    break;
                default:
                    break;
            }

            self.cmd('editModuleSpecs', specs);
        });

        $('body').on('change', '#toggle-module-orientation', function () {
            self.cmd('editModuleSpecs', {
                orientation: $(this).is(':checked') ? 'portrait' : 'landscape'
            });
        });

        $('body').on('change', '#toggle-array-mode', function () {
            const toggle = $(this);

            let toMode   = toggle.is(':checked') ? 'manual' : 'dynamic';
            let fromMode = (toMode === 'dynamic') ? 'manual' : 'dynamic';

            let switchMode = false;

            $(`#dialog-confirm-mode-${toMode}`).dialog({
                resizable: false,
                textAlign: 'center',
                margin:    '6px 0px -4px',
                height:    'auto',
                width:     500,
                closeOnEscape: true,
                modal:     true,
                buttons: [
                    {
                        text:    `Yes, switch to ${toMode.toUpperCase()} mode`,
                        'class': 'mode-switch-btn mode-switch-green',
                        click:   function() {
                            self.cmd('switchMode', toMode);
                            switchMode = true;
                            $(this).dialog('close');
                        }
                    },
                    {
                        text:    `Stay in ${fromMode.toUpperCase()}`,
                        'class': 'mode-switch-btn mode-switch-blue',
                        click:   function() {
                            $(this).dialog('close');
                        }
                    }
                ],
                open: function(){
                    $('.ui-widget-overlay').on('click',function(){
                        $('#save-as-dialog').dialog('close');
                    })
                },
                close: function () {
                    if (!switchMode)
                        toggle.prop('checked', !toggle.prop('checked'));

                    self.renderState();
                }
            });
        });

        $('body').on('click', 'button.manual-array-select-all',  function () {
            self.cmd('selectAll');
        });

        $('body').on('click', 'button.manual-array-select-none', function () {
            self.cmd('selectNone');
        });

        $('body').on('click', 'button.manual-array-move', function () {
            self.cmd('move');
        });

        $('body').on('click', '.manual-array-select', function () {
            self.cmd('rectangleSelect');
        });

        $('body').on('click', '.manual-array-copy', function () {
            self.cmd('copy');
        });

        $('body').on('click', 'button.manual-array-remove', function () {
            prompts.confirm('Are you sure you would like to delete selected modules?').then(function (ok) {
                if (ok) {
                    self.cmd('remove');
                } else {
                    let s = self.getState();

                    $(`.plane-${s.pid} button.manual-array-select-all`).addClass("active-btn");
                }
            });
        });

        $('body').on('click', 'button.manual-array-add', function () {
            self.cmd('addModule');
        });

        GUI.inputText('.input-module-specs-tool-height-offset', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertFrom: this.cm_InToM,
                convertTo:   this.mToCm_In,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { heightOffset: val });

                    return this.getState().moduleSpecs.heightOffset;
                },
                resetState:  () => this.getState().moduleSpecs.heightOffset
            })
        });

        GUI.inputText('.input-module-specs-tool-hoffset', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertFrom: this.cm_InToM,
                convertTo:   this.mToCm_In,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { hOffset: val });

                    return this.getState().moduleSpecs.hOffset;
                },
                resetState:  () => this.getState().moduleSpecs.hOffset
            })
        });

        GUI.inputText('.input-module-specs-tool-voffset', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertFrom: this.cm_InToM,
                convertTo:   this.mToCm_In,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { vOffset: val });

                    return this.getState().moduleSpecs.vOffset;
                },
                resetState:  () => this.getState().moduleSpecs.vOffset
            })
        });

        GUI.inputText('.input-module-specs-tool-rack-cols', {
            validate: Validator.create({
                sanitize:    parseInt,
                isValid:     Validator.positiveNumber,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { cols: val });

                    return this.getState().moduleSpecs.cols;
                },
                resetState:  () => this.getState().moduleSpecs.cols
            })
        });

        GUI.inputText('.input-module-specs-tool-rack-rows', {
            validate: Validator.create({
                sanitize:    parseInt,
                isValid:     Validator.positiveNumber,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { rows: val });

                    return this.getState().moduleSpecs.rows;
                },
                resetState:  () => this.getState().moduleSpecs.rows
            })
        });

        GUI.inputText('.input-module-specs-tool-rack-h-spacing', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertFrom: this.m_FtToM,
                convertTo:   this.mToM_Ft,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { hSpacing: val });

                    return this.getState().moduleSpecs.hSpacing;
                },
                resetState:  () => this.getState().moduleSpecs.hSpacing
            })
        });

        GUI.inputText('.input-module-specs-tool-rack-v-spacing', {
            validate: Validator.create({
                sanitize:    parseFloat,
                isValid:     Validator.positiveNumber,
                convertFrom: this.m_FtToM,
                convertTo:   this.mToM_Ft,
                updateState: (val) => {
                    this.cmd('editModuleSpecs', { vSpacing: val });

                    return this.getState().moduleSpecs.vSpacing;
                },
                resetState:  () => this.getState().moduleSpecs.vSpacing
            })
        });

        $('body').on('change', '.input-snap-modules', function () {
            self.cmd('snap.modules', $(this).is(':checked'));
        });

        this.learnMoreDialog.init();
    }


    m_FtToM(val) {
        /** @global measurementUnits */
        return (measurementUnits === MeasurementUnits.METERS) ?
            val :
            feetToMeters(val, 10);
    }


    cm_InToM(val) {
        /** @global measurementUnits */
        return (measurementUnits === MeasurementUnits.METERS) ?
            cmToMeters(val, 10) :
            inchesToMeters(val, 10);
    }


    mToM_Ft(val) {
        /** @global measurementUnits */
        return (measurementUnits === MeasurementUnits.METERS) ?
            round(val, 2) :
            metersToFeet(val, 2);
    }


    mToCm_In(val) {
        /** @global measurementUnits */
        return (measurementUnits === MeasurementUnits.METERS) ?
            metersToCm(val, 2) :
            metersToInches(val, 2);
    }


    renderState() {
        const s = this.getState();

        let autoFillOptions = [
            '<option value=""> -- select one -- </option>',
            '<option value="default">Defaults</option>'
        ];

        // TODO: how do we track if settings were copied from another plane?
        for (let pid = 0; pid < s.planeNames.length; pid++) {
            autoFillOptions.push(
                `<option value="${pid}">${s.planeNames[pid]}</option>`
            );
        }

        const specs = s.moduleSpecs;
        let isChecked = (option, val) => (option === val) ? 'checked="checked"' : '';

        var t = `
        <div class="pane-container array-properties array-properties-${s.pid}">
            <h3>Array</h3>

            <div class="segment-info"></div>

            <div class="">
                <div class="autofill">
                    <h4>Autofill from...</h4>

                    <select class="auto-fill-params" name="autofill from">
                        ${autoFillOptions.join('\n')}
                    </select>

                    <div class="info">
                        <div class="qmark-info">
                            <div class="tooltip-content">
                                Fill this array using array properties from another segment.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="moduleDropdown">
                    <div id="moduleDropdown"></div>
                </div>

                <div class="block-outline toggle-box">
                    <div class="heading">
                        <h4>Array Details</h4>

                        <div class="toggle">
                            <input type="checkbox" id="toggle-array-details" class="switch-input" ${this.showArrayDetails ? 'checked' : ''}>
                            <label for="toggle-array-details" class="switch-label switch-details">
                            </label>
                        </div>
                    </div>

                    <div class="array-details ${this.showArrayDetails ? '' : 'hidden'}">
                        <div class="block">
                            <h4>Racking Type</h4>

                            <div class="racking-type">
                                <div class="racking">
                                    <input type="radio"
                                        name="racking"
                                        value="flush-mount"
                                        id="racking-flush-mount"
                                        ${isChecked(specs.mountType, 'flush-mount')}>

                                    <div class="img-block">
                                        <img src="styles/images/icons/flush-mount.png" alt=""></img>
                                    </div>

                                    <div class="caption">Flush Mount</div>
                                </div>

                                <div class="racking">
                                    <input type="radio"
                                        name="racking"
                                        value="tilted"
                                        id="racking-tilted"
                                        ${isChecked(specs.mountType, 'tilted')}>

                                    <div class="img-block">
                                        <img src="styles/images/icons/tilted.png" alt=""></img>
                                    </div>

                                    <div class="caption">Tilted</div>
                                </div>

                                <div class="racking">
                                    <input type="radio"
                                        name="racking"
                                        value="ground"
                                        id="racking-ground"
                                        ${isChecked(specs.mountType, 'ground')}>

                                    <div class="img-block">
                                        <img src="styles/images/icons/ground-mount.png" alt=""></img>
                                    </div>

                                    <div class="caption">Ground Mount</div>
                                </div>
                            </div>
                        </div>

                        <div class="block module-spacing">
                            <h4>Spacing (<span class="units"></span>)</h4>

                            <div>
                                Col
                                <input type="number"
                                    class="input-module-specs-tool-hoffset number number-md"
                                    min="0" />
                            </div>

                            <div>
                                Row
                                <input type="number"
                                    class="input-module-specs-tool-voffset number number-md"
                                    min="0" />
                            </div>
                        </div>

                        <div class="block array-height">
                            <div>
                                <h4>Height (<span class="units"></span>)</h4>
                            </div>

                            <div class="input-icon">
                                <input type="number" class="input-module-specs-tool-height-offset number number-md" min="0" />
                                <img class="height-img" src="../../styles/images/icons/flush-mount-height.png" alt="flush mount"/>
                            </div>

                            <div class="info">
                                <div class="qmark-info">
                                    <div class="tooltip-content">
                                        Module distance from the roof surface.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="block ground-mount hidden">
                            <h4>Ground Mount Specs</h4>

                            <div class="row">
                                <div class="text">
                                    Spacing (<span class="units-ft"></span>)
                                </div>

                                <div>
                                    Col
                                    <input type="text" class="number input-module-specs-tool-rack-h-spacing" />
                                </div>

                                <div>
                                    Row
                                    <input type="text" class="number input-module-specs-tool-rack-v-spacing" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="text">
                                    Layout
                                </div>

                                <div>
                                    Col
                                    <input type="text" class="number input-module-specs-tool-rack-cols" />
                                </div>

                                <div>
                                    Row
                                    <input type="text" class="number input-module-specs-tool-rack-rows" />
                                </div>
                            </div>
                        </div><!-- .ground-mount -->
                    </div><!-- .array-details -->
                </div>

                <div class="block builder-mode">
                    <div>
                        <h4>Array Builder Mode</h4>
                        <a class="learn-more">Learn More</a>
                    </div>

                    <div class="toggle-container">
                        <div>Dynamic</div>

                        <div>
                            <div class="qmark-info">
                                <div class="tooltip-content">
                                    Dynamic mode responsively rebuilds the array when adding keepouts, changing vertices, or adjusting inputs...  Start here!
                                </div>
                            </div>
                        </div>

                        <div class="toggle">
                            <input type="checkbox" id="toggle-array-mode" class="switch-input" ${isChecked(specs.dynamicMode, false)} />
                            <label for="toggle-array-mode" class="switch-label switch-details"></label>
                        </div>

                        <div>Manual</div>

                        <div>
                            <div class="qmark-info">
                                <div class="tooltip-content">
                                    Manual mode allows you to build your array to specific dimensions for tailor-made module builds.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block module-orientation">
                    <h4>Module Orientation</h4>

                    <div>Landscape</div>

                    <div class="toggle">
                        <input type="checkbox" id="toggle-module-orientation" class="switch-input" ${isChecked(specs.orientation, 'portrait')}>
                        <label for="toggle-module-orientation" class="switch-label switch-details">
                        </label>
                    </div>

                    <div>Portrait</div>
                </div>

                <div class="fill-button-container"></div>

                <div class="array-tools">
                    <h4>Array Tools</h4>

                    <div class="button-row">
                        <button class="sy manual-array-select-all tippy-controls-all">
                            <i class="fa fa-th" aria-hidden="true"></i>
                            Select All
                        </button>

                        <button class="sy manual-array-select-none tippy-controls-none">
                            <i class="fa fa-ban" aria-hidden="true"></i>
                            Select None
                        </button>

                        <button class="sy manual-array-select">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Select Tool
                        </button>
                    </div>

                    <div class="button-row">
                        <button class="sy manual-array-copy">
                            <i class="fa fa-clone" aria-hidden="true"></i>
                            Copy
                        </button>

                        <button class="sy manual-array-move">
                            <i class="fa fa-arrows" aria-hidden="true"></i>
                            Move
                        </button>

                        <button class="sy manual-array-remove tippy-controls-delete">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            Delete
                        </button>

                        <button class="sy manual-array-add">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            Add
                        </button>
                    </div>
                </div><!-- .array tools -->

                <div class="snap-modules">
                    <div>
                        <h4>Snap To Modules</h4>
                    </div>

                    <input type="checkbox"
                        id="input-snap-modules-${s.pid}"
                        class="input-snap-modules"
                        checked="checked" aria-checked="true"/>

                    <div class="info">
                        <div class="qmark-info">
                            <div class="tooltip-content">
                                Use points on existing modules for positioning while moving or adding modules.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;

        if ($(`${this.options.selector} .array-properties-${s.pid}`).length === 0) {
            $(this.options.selector).html(t);

            let idxSelected = -1;

            $('#moduleDropdown').ddslick({
                ...this.getModuleData(),
                background:    '#fff',
                width:         '100%',
                autoOpen:      false,
                height:        130,
                margin:        8,
                imagePosition: 'right',
                onSelected:    (selected) => {
                    if (idxSelected > -1) {
                        let selection = selected.selectedData.name;

                        if (selection === 'add-module') {
                            // open module library, select previously selected module
                            /** @global guiModuleLibrary */
                            guiModuleLibrary.openLibrary();

                            $('#moduleDropdown').ddslick('select', {
                                index: idxSelected.toString() // index must be string or ddslick bugs out
                            });
                        } else {
                            this.cmd('setModule', selection);
                        }
                    }

                    idxSelected = selected.selectedIndex;
                }
            });

            tippy(`.array-properties-${s.pid} .qmark-info`, {
                animation:   'scale',
                inertia:     true,
                content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
                allowHTML:   true,
                interactive: true
            });
        }

        this.options.tiltAzimuthWidget.renderState();

        $(this.options.selector + ' .fill-button-container').html(!s.populated ? `
            <div class="fill">
                <button class="sy manual-array-fill">
                    Fill Modules
                </button>
            </div>
        ` : '');

        // MEASUREMENT UNITS
        $(this.options.selector + ' .input-module-specs-tool-hoffset'       ).val(this.mToCm_In(specs.hOffset));
        $(this.options.selector + ' .input-module-specs-tool-voffset'       ).val(this.mToCm_In(specs.vOffset));
        $(this.options.selector + ' .input-module-specs-tool-height-offset' ).val(this.mToCm_In(specs.heightOffset));

        /** @global measurementUnits */
        let unitsName = (measurementUnits === MeasurementUnits.METERS) ? 'cm' : 'in';
        let unitsFtName = (measurementUnits === MeasurementUnits.METERS) ? 'm' : 'ft';

        $(this.options.selector + ' .array-properties .units').html(unitsName);
        $(this.options.selector + ' .array-properties .units-ft').html(unitsFtName);

        // GROUND MOUNT OPTIONS
        const $groundMount = $(this.options.selector + ' .ground-mount');

        if (specs.mountType === 'ground') {
            $groundMount.removeClass('hidden');

            $(this.options.selector + ' .input-module-specs-tool-rack-h-spacing').val(this.mToM_Ft(specs.hSpacing));
            $(this.options.selector + ' .input-module-specs-tool-rack-v-spacing').val(this.mToM_Ft(specs.vSpacing));

            $(this.options.selector + ' .input-module-specs-tool-rack-cols').val(specs.cols);
            $(this.options.selector + ' .input-module-specs-tool-rack-rows').val(specs.rows);
        } else {
            $groundMount.addClass('hidden');
        }

        // ARRAY MODE
        $('#toggle-array-mode').prop('checked', !specs.dynamicMode);

        // MODULE ORIENTATION
        $('#toggle-module-orientation').prop('checked', s.moduleSpecs.orientation === 'portrait');

        // AVAILABLE TOOLS
        $(this.options.selector + ' .manual-array-select-all' ).prop('disabled', !s.populated);
        $(this.options.selector + ' .manual-array-select-none').prop('disabled', !s.populated);

        $(this.options.selector + ' .manual-array-move'  ).prop('disabled', s.selectedQty === 0);
        $(this.options.selector + ' .manual-array-remove').prop('disabled', s.selectedQty === 0);

        $(this.options.selector + ' .manual-array-add'    ).prop('disabled', specs.dynamicMode || !s.populated);
        $(this.options.selector + ' .manual-array-select' ).prop('disabled', specs.dynamicMode || !s.populated);

        $(this.options.selector + ' .manual-array-copy' ).prop('disabled', (specs.dynamicMode || s.selectedQty === 0));
    }


    getModuleData() {
        const s = this.getState();

        const data = [
            {
                'name':        'add-module',
                'value':       'add-module',
                'selected':    false,
                'description': '+ADD MODULE',
                'imageSrc':    'styles/images/modules/module-monocrystalline.jpg?x=v3',
            }
        ];

        // Whether the mod spec in use on this segment matches *any* mod in the user library.
        let hasKnownSpec = false;

        for (const name of Object.keys(s.moduleLibrary)) {
            const tMap = s.moduleLibrary[name].textureMap;

            // this mod is used in the array on this segment
            const nameMatch = s.moduleSpecs.moduleName === name;

            // Select this module, rather than the default mod, in the dropdown if:
            // (1) this segment already has a module spec associated with it, and
            // (2) there is already an array populated with that spec
            const selected = nameMatch && s.populated;

            hasKnownSpec ||= nameMatch;

            data.push({
                selected,
                name:        name,
                value:       tMap,
                description: name,
                imageSrc:    this.moduleImages[tMap],
            });
        }

        // kludge to handle edge case that only comes up for scanifly admins:
        // *if* viewing a project with a populated array, *and* the module used
        // is not in the current user's module library, do *not* default to the
        // first module in the user's library for the dropdown. Instead, maintain
        // the current behavior of selecting the "+ADD MODULE" dummy option.
        return { data, defaultSelectedIndex: !hasKnownSpec && s.populated ? 0 : 1 };
    }
}


export { SidebarArrayGUI };
