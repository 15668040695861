import { camera, raycaster, container } from '../Viewer';
import { Vector3 } from '../libs/three.module';


class Draw {
    /**
     * @param {Vector3[]} vv
     */
    static exportLineGeometry(vv) {
        var lines = [];

        for (let i = 0; i < vv.length; i++) {
            let j = (i+1 + vv.length) % vv.length;

            lines.push([ vv[i].clone(), vv[j].clone() ]);
        }

        return lines;
    }

    /**
     * @param {Vector3[]} vv
     */
    static exportSegmentGeometry(vv) {
        var lines = [];

        for (let i = 0; i < vv.length - 1; i += 2) {
            lines.push([ vv[i].clone(), vv[i+1].clone() ]);
        }

        return lines;
    }


    static getScreenCoordinates({ camera, raycaster, container, v }) {
        let pos = container ? container.localToWorld(v.clone()) : v.clone();
        pos.project(camera);
        raycaster.setFromCamera(pos, camera);

        let container3d = document.getElementById('container3d');
        let rect = container3d.getBoundingClientRect();

        let wHalf = container3d.clientWidth  / 2;
        let hHalf = container3d.clientHeight / 2;

        pos.x = rect.x + (pos.x * wHalf) + wHalf;
        pos.y = rect.y - (pos.y * hHalf) + hHalf;

        return pos;
    }
}


export { Draw };
