import { formatK } from '../libs/Utilities';
import { Format } from './Format';


import tippy from 'tippy.js';

class VersionsGUI {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }


    cmd(command, val) {
        this.options.tool.cmd(command, val);
    }


    init() {
        const self = this;


        $('body').on('change', '.version-select', function () {
            var saveId = $(this).val();

            if (saveId === '') {
                $("#save-as-dialog").dialog({
                    modal: true,
                    classes: {
                        'ui-dialog': 'save-as-dialog'
                    },
                    height: 'auto',
                    minHeight: 50,
                    closeOnEscape: true,
                    open: function(){
                        $('.ui-widget-overlay').on('click',function(){
                            $('#save-as-dialog').dialog('close');
                        })
                    },
                    close: function () {
                        $('#save-as-dialog input').val('');

                        // reset to previously selected option
                        self.renderState();
                    }
                });
            } else {
                self.cmd('load', saveId);
            }
        });

        // BUTTONS
        $('body').on('click', '#versions-delete', function () {
            const s = self.getState();

            $('#delete-dialog .desc').html(
                `Enter the version name (<code>${s.currentDesign.name}</code>) to confirm you want to permanently delete it.`
            );

            $("#delete-dialog").dialog({
                modal: true,
                classes: {
                    'ui-dialog': 'delete-dialog'
                },
                height: 'auto',
                minHeight: 50,
                closeOnEscape: true,
                open: function(){
                    $('.ui-widget-overlay').on('click',function(){
                        $('#delete-dialog').dialog('close');
                    })
                },
                close: function () {
                    $('#delete-dialog input').val('');
                }
            });
        });

        $('body').on('click', '#versions-start-over', function () {
            const s = self.getState();

            $('#start-over-dialog .desc').html(
                `Are you sure you'd like to delete all segments for version (<code>${s.currentDesign.name}</code>) and start over?`
            );

            $("#start-over-dialog").dialog({
                modal: true,
                classes: {
                    'ui-dialog': 'start-over-dialog'
                },
                height: 'auto',
                minHeight: 50,
                closeOnEscape: true,
                open: function(){
                    $('.ui-widget-overlay').on('click',function(){
                        $('#start-over-dialog').dialog('close');
                    })
                }
            });
        });

        $('body').on('click', '#versions-rename', function () {
            const s = self.getState();

            $('#rename-dialog input').val(s.currentDesign.name);
            $('#rename-dialog .rename').prop('disabled', true);
            $('#rename-dialog .rename').attr('aria-disabled', "true");

            $("#rename-dialog").dialog({
                modal: true,
                classes: {
                    'ui-dialog': 'rename-dialog'
                },
                height: 'auto',
                minHeight: 80,
                closeOnEscape: true,
                open: function(){
                    $('.ui-widget-overlay').on('click',function(){
                        $('#rename-dialog').dialog('close');
                    })
                },
                close: function () {
                    //$('#rename-dialog input').val('');
                }
            });
        });

        // DIALOGS
        var isValidName = function (newName) {
            const s = self.getState();

            var saveNames = (s.designs && s.designs.length > 0 ) ?
                s.designs.map(s => s.name) :
                [ s.projectName ];

            return newName !== '' && (saveNames.indexOf(newName) === -1);
        }

        // save as
        $('body').on('click', '#save-as-dialog .save', function () {
            const newName = $('#save-as-dialog input').val();

            self.cmd('save', newName);

            $('#save-as-dialog').dialog('close');
        });

        $('body').on('input', '#save-as-dialog input', function () {
            $('#save-as-dialog .save').prop('disabled', !isValidName($(this).val()));
        });

        $('body').on('click', '#save-as-dialog .cancel', function () {
            $('#save-as-dialog').dialog('close');
        });

        $('body').on('click', '.dropbtn', function() {
            var dropdown = document.getElementById("dropdown");
            if(dropdown.style.display=="block"){
                dropdown.style.display = "none";
            }
            else {
                dropdown.style.display = "block";
                $('body').on('focusout', '.dropbtn', function() {
                    document.addEventListener('click', function (event) {
                        if(!$(event.target).parents('.dropdown').length > 0){
                            dropdown.style.display = "none";
                        }
                    });
                });
            }
        });

        // delete
        $('body').on('click', '#delete-dialog .delete', function () {
            self.cmd('delete');

            $('#delete-dialog').dialog('close');
        });

        $('body').on('input', '#delete-dialog input', function () {
            const s = self.getState();

            $('#delete-dialog .delete').prop('disabled', s.currentDesign.name !== $(this).val());
        });

        $('body').on('click', '#delete-dialog .cancel', function () {
            $('#delete-dialog').dialog('close');
        });

        // start over
        $('body').on('click', '#start-over-dialog .start-over', function () {
            self.cmd('startOver');
        });

        $('body').on('click', '#start-over-dialog .cancel', function () {
            $('#start-over-dialog').dialog('close');
        });

        // rename
        $('body').on('click', '#rename-dialog .rename', function () {
            const newName = $('#rename-dialog input').val();
            self.cmd('rename', newName);

            $('#rename-dialog').dialog('close');
        });

        $('body').on('input', '#rename-dialog input', function () {
            $('#rename-dialog .rename').prop('disabled', !isValidName($(this).val()));
        });

        $('body').on('click', '#rename-dialog .cancel', function () {
            $('#rename-dialog').dialog('close');
        });
    }


    renderState() {
        const s = this.getState();
        const projectName = s.projectName;
        const currentDesign = s.currentDesign;

        let options = [];

        if (s.designs.length > 0) {
            for (const d of s.designs) {
                var selected = (currentDesign?.id === d.id) ? ' selected="selected"' : '';

                options.push(`<option value="${d.id}"${selected}>${d.name}</option>`);
            }
        } else {
            options.push(`<option value="${currentDesign?.id}" selected="selected">${currentDesign?.name}</option>`);
        }

        // limit number of alternative versions to 1
        if (s.designs.length < 2) {
            options.push('<option value="">+ CREATE COPY</option>');
        }

        let disabled = '';

        if (s.designs.length < 2)
            disabled = 'disabled';

        let t = `
            <div class="versions">
                <div class="row">
                    <h2>${projectName}</h2>
                </div>
                <div class="row">
                    <select class="version-select" data-tippy-content="Save-as tool allows you to create two layouts per project">
                        ${options.join('\n')}
                    </select>

                    <div class="navbar">
                        <div class="dropdown">
                            <button class="sy ellipsis-v dropbtn" aria-label="more options">
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>

                            <div class="dropdown-content" id="dropdown">
                                <div data-tippy-content="Delete current project">
                                    <a id="versions-delete" class="${disabled}">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                        <span class="caption">
                                            Delete
                                        </span>
                                    </a>
                                </div>

                                <div data-tippy-content="Start over! Erase all segments in one click">
                                    <a id="versions-start-over">
                                        <i class="fa fa-eraser" aria-hidden="true"></i>
                                        <span class="caption">
                                            Start over
                                        </span>
                                    </a>
                                </div>

                                <div data-tippy-content="Rename current project">
                                    <a id="versions-rename">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        <span class="caption">
                                            Rename
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <table class="system-tree-totals">
                        <tr>
                            <td><strong>System Size:</strong></td>
                            <td>${Format.wattsAsKilowatts(s.array.totalOutput)} DC STC</td>
                        </tr>
                        <tr>
                            <td><strong>Module QTY:</strong></td>
                            <td>${formatK(s.array.totalQty)}</td>
                        </tr>
                    </table>
                </div>
            </div>
        `;
        $(this.options.selector).html(t);

        tippy('.versions [data-tippy-content]', {
            animation: 'scale',
            inertia:   true
        });
    }
}

export { VersionsGUI };
