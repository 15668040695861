import { cameraOrtho } from '../Viewer.js';
import * as THREE from './three.module';




class Tooltip {
    constructor(options) {
        options = Object.assign({
            borderColor: '#003eff',
            bgColor:     '#1C48F2',
            color:       '#fff',
            fontSize:    14,
            radius:      3,
            offset:      6,
        }, options || { });

        this.rectW = 256;
        this.rectH = 64;

        this.sprite = new THREE.Sprite();

        this.options = options;
    }

    show(val) {
        let show = (val === undefined) ? true : val;

        this.sprite.visible = show;

        if (this.doubleSprite)
            this.doubleSprite.visible = show;
    }

    hide() { this.show(false); }

    isVisible() {
        return this.sprite.visible;
    }

    getSprite() {
        if (!this.doubleSprite) {
            let obj = new THREE.Object3D();

            obj.add(this.sprite);

            if (this.sprite.material.depthTest && this.texture) {
                var spriteMaterial = new THREE.SpriteMaterial({
                    map:         this.texture,
                    color:       0xffffff,
                    transparent: true,
                    opacity:     0.3,
                    depthTest:   false
                });

                var s = new THREE.Sprite(spriteMaterial);

                s.scale.copy(this.sprite.scale);

                obj.add(s);
            }

            this.doubleSprite = obj;
        }

        return this.doubleSprite;
    }


    setupSprite(w, h) {
        // tweak the canvas pixel ratio to prevent blurry text
        var ratio = 4;

        this.canvas = document.createElement('canvas');
        this.canvas. width = w * ratio;
        this.canvas.height = h * ratio;
        //this.canvas.style.border = '1px solid #000';
        //console.log(this.canvas.style);

        this.ctx = this.canvas.getContext('2d');
        this.ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        //this.ctx.strokeStyle = 'red';
        //this.ctx.lineWidth = 15;
        //this.ctx.strokeRect(20, 20, this.canvas.width, this.canvas.height);
        this.ctx.font = this.options.fontSize + "px 'Poppins',Helvetica,Arial,sans-serif";
        //this.ctx.lineWidth = 15;
        //this.ctx.stroke();
        //this.ctx.strokeStyle = this.options.borderColor;
        //this.ctx.roundedRect(this.ctx, bw, bw, w, h, this.options.radius);
        //this.ctx.stroke();
        // canvas contents will be used for a texture
        this.texture = new THREE.Texture(this.canvas);

        var spriteMaterial = new THREE.SpriteMaterial({
            map:   this.texture,
            color: 0xffffff
        });

        this.sprite.material = spriteMaterial;
    }


    /**
     * @deprecated
     *
     * Kept for backward compatibility, pain to use.
     */
    setText(text) {
        if (!Array.isArray(text))
            text = [ text ];

        this.setupSprite(this.rectW, this.rectH);

        this.sprite.scale.set(this.rectW, this.rectH, 1);

        var textW = Math.max(...text.map((l) => this.ctx.measureText(l).width));

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();

        // DEV uncomment to draw whole sprite
        //this.ctx.fillStyle = '#ffff0099';
        //this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        var bw     = 4; // border width
        var hpd    = 8; // padding
        var vpd    = 12;

        var w = textW + hpd;
        var h = this.options.fontSize * text.length + vpd;

        this.ctx.translate(this.options.offset, this.options.offset);

        // tooltip border
        this.ctx.strokeStyle = this.options.borderColor;
        this.roundRect(this.ctx, 0, 0, w + bw*2, h + bw*2, this.options.radius);
        this.ctx.fill();
        /*this.ctx.strokeStyle = this.options.borderColor;
        this.ctx.roundedRect(this.ctx, bw, bw, w, h, this.options.radius);
        this.ctx.stroke();*/

        // tooltip fill
        this.ctx.fillStyle = this.options.bgColor;
        this.roundRect(this.ctx, bw, bw, w, h, this.options.radius);
        this.ctx.fill();

        // text
        this.ctx.fillStyle = this.options.color;

        for (let i = 0; i < text.length; i++)
            this.ctx.fillText(text[i], (w - textW)/2, (vpd/2 - 2) + this.options.fontSize * (i + 1));

        this.ctx.restore();

        this.texture.needsUpdate = true;

        this.text = text;
    }


    setTextRectangle(text) {
        this.text = text;

        const w = 256;
        const h = 256;

        if (this.ctx === undefined)
            this.setupSprite(w, h);

        this.ctx.clearRect(0, 0, w, h);
        this.ctx.save();

        // DEV uncomment to draw whole sprite
        //this.ctx.fillStyle = '#ff0000';
        //this.ctx.fillRect(0, 0, w, h);

        const fh = 50;

        this.ctx.font = fh + "px 'Poppins',Helvetica,Arial,sans-serif";
        const tMeasure = this.ctx.measureText(text);
        const tw = tMeasure.width;

        let rw = tw + tw * 0.3;
        let rh = fh + fh * 0.3;
        let rx = (w - rw) / 2;
        let ry = h/4;

        // background
        this.ctx.fillStyle = this.options.bgColor;
        this.roundRect(this.ctx, rx, ry, rw, rh, fh * 0.2);
        this.ctx.fill();

        // text
        this.ctx.translate(w/2, fh*2);
        this.ctx.fillStyle = this.options.color;
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText(text, -tw/2, 1);

        this.ctx.restore();

        this.sprite.material.depthTest = false;
        this.texture.needsUpdate = true;
    }


    scale(factor) {
        this.doubleSprite.children.forEach(c => c.scale.set(factor, factor, 1));
    }


    setTextCircle(text) {
        this.text = text;

        var w = 2*this.options.radius;
        var h = 2*this.options.radius;

        this.setupSprite(w, h);
        this.sprite.scale.set(0.6, 0.6, 1);

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();

        // DEV uncomment to draw whole sprite
        //this.ctx.fillStyle = '#ffff0055';
        //this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        var textW = this.ctx.measureText(text).width;

        // starting point
        this.ctx.translate(w/2, h/2);

        // fill
        this.ctx.fillStyle = this.options.bgColor;

        this.ctx.beginPath();
        this.ctx.arc(0, 0, this.options.radius, 0, 2*Math.PI, true);
        this.ctx.closePath();

        this.ctx.fill();

        // text
        this.ctx.fillStyle = this.options.color;
        this.ctx.textBaseline = 'middle';

        this.ctx.fillText(text, -textW/2, 1);

        this.ctx.restore();
        this.texture.needsUpdate = true;
    }


    setPosition(vScreen) {
        var v = vScreen.clone().unproject(cameraOrtho);

        this.sprite.position.set(v.x + this.rectW/2,
                                 v.y - this.rectH/2,
                                 1);
    }


    roundRect(ctx, x, y, w, h, radius) {
        if (typeof radius === 'number')
            radius = {
                tl: radius,
                tr: radius,
                br: radius,
                bl: radius
            };

        ctx.beginPath();

        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + w - radius.tr, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + radius.tr);

        ctx.lineTo(x + w, y + h - radius.br);
        ctx.quadraticCurveTo(x + w, y + h, x + w - radius.br, y + h);

        ctx.lineTo(x + radius.bl, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - radius.bl);

        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);

        ctx.closePath();
    }
}


export { Tooltip };
