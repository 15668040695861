import { Colors } from '../core/Colors.js';
import { WorkerPool } from '../libs/WorkerPool.js';
import { CanvasAnnotations } from '../libs/CanvasAnnotations.js';
import { Net } from '../libs/Net.js';
import { camera, moduleSpecsTool, project, renderer, scene, emitEvent, occluderTool, mouseHandler, toolSelector } from '../Viewer.js';
import { getPlaneName as getPlaneNameUtil, chunkBy, zip } from '../libs/Utilities';
import { ViewshedOverlay } from '../workers/ViewshedOverlay.js';
import { degToRad } from '../libs/Geometry';
import { findClosestStation, calculateInsolation, calculateSunPositionSunCalc } from '../workers/Irradiation.js';
import { Tooltip } from '../libs/Tooltip.js';
import { InfernoGradient } from '../draw/InfernoGradient.js';
import { GRAPH } from '../libs/Graph.js';
import { KeyStore } from '../alibs/KeyStore.js';
import { Viewshed } from '../libs/Viewshed.js';
import { FisheyeLens } from '../libs/FisheyeLens.js';
import { CameraHelper, Color, BufferGeometry, Geometry, Line, LineBasicMaterial, MOUSE, Object3D, OrthographicCamera, Points, PointsMaterial, Raycaster, Vector2, Vector3 } from '../libs/three.module';
import { Config } from '../../bootstrap/Config'
import * as Q from 'q';
import * as d3 from 'd3';
import { uuidv4 } from '../alibs/Random.js';

import { BoundsCentersCheckerboardPlacementStrategy, ModuleCenterPlacementStrategy } from '../libs/AutoViewshed';

const PROPOSED_AUTO_LOCATION_MATERIAL = new PointsMaterial({
    color: Colors.Scanifly.teal(),
    transparent: true,
    opacity: 0.75,
    depthTest: false,
    size: 0.5
});

/**
 * Viewshed Definition
 * @class
 * @constructor
 * @public
 */
class ViewshedDefinition {
    /**
     *
     * @param {THREE.Vector3} position
     * @param {number} lat
     * @param {number} lng
     * @param {string} UTCOffset
     * @param {number} year
     * @param {boolean} automatic
     */
    constructor( position, lat, lng, UTCOffset, year, automatic ) {
        this.position = position;
        this.overlay = {
            lat: lat,
            lng: lng,
            year: year,
            offset: UTCOffset
        };
        this.automatic = automatic;
    }
}

/**
 * Insolation data
 * @class
 * @constructor
 * @public
 */
class InsolationData {
     constructor() {
        this.total = 0;
        this.hourly = [];
        this.monthly = [];

     }
}

class ViewshedTool {

    /** @param {number} [concurrency] */
    constructor(concurrency) {
        // by default, use up to 1/4 of the available hardware threads
        // on the client machine, but at least 2
        if (!concurrency) {
            concurrency = Math.max(navigator.hardwareConcurrency / 4, 2);
        }
        this.imageWidth = 600.0;

        this.fisheyeLens = new FisheyeLens(0.01, 100);
        this.fisheyeLens.position.set(0, 7, 0);
        this.fisheyeLens.rotation.x = Math.PI;
        this.fisheyeLens.rotation.y = -Math.PI/2;

        this.fisheyeHelper = new CameraHelper(this.fisheyeLens.cameras[0]);
        this.fisheyeHelper.visible = false;

        var viewshedPlaneZ = -1000;

        this.viewshed = new Viewshed(2);
        this.viewshed.setRenderTargets(this.fisheyeLens.renderTargets);
        this.viewshed.position.set(0, 0, viewshedPlaneZ);
        this.viewshed.visible = false;
        this.viewshed.rotation.y = Math.PI / 2;

        var geometry = new Geometry();
        geometry.vertices.push(new Vector3(0, 0, 0));
        geometry.vertices.push(new Vector3(0, 2, 0));

        this.material = new LineBasicMaterial({
            color:       0xffff00,
            linewidth:   3,
            transparent: true,
            depthTest:   false
        });

        var line = new Line(geometry, this.material);

        this.fisheyeVector = new Object3D();
        this.fisheyeVector.add(line);
        this.fisheyeVector.visible = false;

        this.captureCamera = new OrthographicCamera(-1, 1, 1, -1, 1, 10);
        this.captureCamera.lookAt(new Vector3(0, -1, 0));
        this.captureCamera.position.set(0, 2, viewshedPlaneZ);
        this.captureCamera.rotation.z = Math.PI * 1.5;
        //this.captureCameraHelper = new CameraHelper(this.captureCamera);

        this.weatherStation = null;

        this.meshes      = [];

        /** @type {any[][]} */
        this.viewsheds   = [];
        this.annotations = [];
        this.planes      = [];
        this.markers     = [];

        this.show = new KeyStore();

        // TODO: make states a static property
        this.states = { READY: 0, AUTO: 1};
        this.state = this.states.READY;

        // TODO: make this a static property as well
        this.displayValues = {ASA :0, TSRF: 1};
        this.displayValue = this.displayValues.ASA;

        this.commandHistory = [];

        /** @type {WorkerPool?} */
        this._overlayWorkerPool = null;

        /** @type {number} */
        this.concurrency = concurrency;

        /** @type {Vector3[]?} of proposed auto-viewshed locations in world space */
        this.proposedViewshedLocations = null;

        /** @type {Points?} rendering of proposed locations in 3D scene */
        this.proposedViewsheds = null;

        this.autoGridDensity = 0.5; // viewsheds per sq meter

        /** @type {(BoundsCentersCheckerboardPlacementStrategy | ModuleCenterPlacementStrategy)?} */
        this.autoStrategy = null;

        this.coverImage = {};
    }

    // stub
    propagateState() {}

    /** @param {Scene} scene */
    addToScene(scene) {
        scene.add(this.fisheyeLens);
        scene.add(this.viewshed);
        scene.add(this.fisheyeHelper);
        scene.add(this.fisheyeVector);
        scene.add(this.captureCamera);
        //scene.add(this.captureCameraHelper);
    }

    /** @param {Camera} camera */
    setCamera(camera) { this.camera = camera; };

    /** @param {Mesh} mesh */
    addMesh(mesh)     { this.meshes.push(mesh); };

    /** @param {Raycaster} rc */
    setRaycaster(rc)  { this.raycaster = rc; };

    /** @param {() => void} callback */
    registerPropagateState(callback) { this.propagateState = callback; }


    // TODO: gradually move all UI interactions spread over callbacks to this method and cmd
    getState() {
        let crops = [];

        for (let i = 0; i < this.annotations.length; i++)
            crops.push(this.annotations[i].crop);

        return {
            pid:             this.currentRoofPlane,
            viewsheds:       this.viewsheds,
            planes:          this.planes,
            crops:           crops,
            averages:        this.getAllSolarAccessAverages(),
            show:            this.show,
            horizonSVG:      this.getHorizonSVG(),
            autoGridDensity: this.autoGridDensity,
            toolState:       this.state,
            displayValue:    this.displayValue
        }
    }


    cmd(c, val) {
        this.commandHistory.push({ c, val });

        const self = this;

        if (c === 'setAzimuthAndTilt') {
            this.setAzimuthAndTilt(val.azimuth, val.tilt);
        } else if (c === 'switchPlane') {
            this.setCurrentRoofPlane(val);
        } else if (c === 'isDataReady') {
            return (this.weatherStation !== null);
        } else if (c === 'add') {
            toolSelector.enable(this);
        } else if (c === 'remove') { // @deprecated
            this.removeViewshed(val);
        } else if (c === 'removeViewsheds') {
            this.removeViewsheds(this.currentRoofPlane, val);
        } else if (c === 'removePlane') {
            this.removePlane(val);
        } else if (c === 'renamePlane') {
          this.updatePlaneName(val.pid, val.newName);
        } else if (c === 'getHorizonProfile') {
            return this.getHorizonProfile(val);
        } else if (c === 'addSegment') {
            this.addPlane();
        } else if (c === 'setAutoStrategy') {
            switch (val) {
                case 'density-grid':
                    this.useDensityGridAutoStrategy()
                    break;
                case 'module-center':
                    this.useModuleCenterAutoStrategy();
                    break;
                default:
                    console.log(`unknown auto viewshed strategy type: ${val}`);
                    return;
            }
        } else if (c === 'setAutoGridDensity') {
            this.autoGridDensity = val;
            this.useDensityGridAutoStrategy();
        } else if (c === 'proposeAutoViewsheds') {
            if (this.state !== this.states.AUTO) { // don't propose viewsheds while viewsheds are being generated
                this.clearAutoViewsheds();
                this.proposeAutoViewsheds();
            }
        } else if (c === 'clearAutoViewsheds') {
            this.clearAutoViewsheds();
        } else if (c === 'commitAutoViewsheds') {
            return this.commitAutoViewsheds().then(
                () => {
                    this.clearAutoViewsheds();
                    this.autoStrategy = null;
                }
            );
        } else if (c === 'removeAutoViewsheds') {
            this.removeAutoViewsheds();
        } else if (c == 'refreshViewsheds') {
            this.refreshViewshedsByIndex( val ).then(() => this.propagateState());
        } else if (c === 'setDisplayedValue') {
            if( undefined == val || (val !== this.displayValues.ASA && val !== this.displayValues.TSRF) ) {
                console.log('Invalid argument for command: setDisplayedValue.');
            } else {
                this.displayValue = val;
                this.markers.forEach(( marker, pid ) => {
                    if(undefined !== pid) {
                        this.recreateViewshedMarkers(pid);
                    }
                });
                this.scaleMarkerSprites();
            }
        } else if (c === 'annotations.redraw') {
            this.annotations[this.currentRoofPlane].redraw();
        } else if (c === 'annotations.load') {
            let a = this.annotations[this.currentRoofPlane];

            a.setCanvas(val.canvas);
            a.setImage(val.img);
            a.redraw();
        } else if (c === 'annotations.mouseMove') {
            this.annotations[this.currentRoofPlane].mouseMove(val.mouse, val.callback);
        } else if (c === 'annotations.mouseEnter') {
            this.annotations[this.currentRoofPlane].mouseEnter(val.mouse);
        } else if (c === 'annotations.mouseUp') {
            this.annotations[this.currentRoofPlane].mouseUp(val.mouse, val.button);
        } else if (c === 'annotations.mouseDown') {
            this.annotations[this.currentRoofPlane].mouseDown(val.mouse, val.button);
        } else if (c === 'annotations.close') {
            this.getAnnotationsSVG(this.currentRoofPlane).then(() => {
                self.propagateState();
            });
        } else if (c === 'annotations.clear') {
            this.clearAnnotationLines();
        } else if (c === 'show') {
            let pids = [];

            if (val.pid !== undefined) {
                pids.push(val.pid);
            } else { // all planes
                for (let i = 0; i < this.planes.length; i++)
                    pids.push(i);
            }

            if (val.item === 'all' || val.item === 'viewsheds') {
                for (let pid of pids)
                    this.show.set([ pid ], val.show);

                this.showPlaneMarkers();
            }
        } else {
            console.log('Unknown command:', c, val);
        }
    }


    /**
     * Returns the value that should be used for false-color representation of the viewshed data.
     * This is based on the this.displayValue, which can be either ASA or TSRF.
     * @param {ViewshedDefintion} viewshed
     * @returns {number} Value between [0-1]
     */
    getDisplayValue(viewshed) {
        return (this.displayValue == this.displayValues.ASA ? viewshed.solarAccess : viewshed.TSRF) / 100;
    }

    setCoordinates(lat, lng) {
        this.lat = lat;
        this.lng = lng;
    }


    setYear(year)        { this.year = year; }
    getUTCOffset()       { return this.UTCOffset; }
    setUTCOffset(offset) { this.UTCOffset = offset; }


    getViewshedLength()  {
        let viewshedCount = 0;

        this.viewsheds.forEach((viewshedArr) => {
            viewshedCount += viewshedArr.length;
        });

        return viewshedCount;
    }


    setAzimuthAndTilt(azimuth, tilt) {
        if (this.currentRoofPlane > -1) {
            this.planes[this.currentRoofPlane].azimuth = azimuth;
            this.planes[this.currentRoofPlane].tilt    = tilt;
        }
    }


    getAzimuthAndTilt() {
        if (this.planes[this.currentRoofPlane] !== undefined) {
            return {
                azimuth: this.planes[this.currentRoofPlane].azimuth,
                tilt:    this.planes[this.currentRoofPlane].tilt
            };
        } else {
            return null;
        }
    }


    setProjectId(id) { this.projectId = id; }

    enable() {
        if (!this.enabled) {
            this.enabled = true;

            //this.fisheyeHelper.visible = true;
            this.fisheyeVector.visible = true;

            mouseHandler.setCursor('pointer', 'vt', true);
        }
    }


    disable() {
        if (this.enabled) {
            this.enabled = false;

            this.cancel();
        }
    }


    cancel() {
        //this.fisheyeHelper.visible = false;
        this.fisheyeVector.visible = false;

        mouseHandler.clearCursor('vt');
    }

    useModuleCenterAutoStrategy() {
        this.autoStrategy = new ModuleCenterPlacementStrategy(
            /// @todo: global state still bad, even if imported from a module :(
            moduleSpecsTool.outlineCtx
        );
    }

    useDensityGridAutoStrategy() {
        this.autoStrategy = new BoundsCentersCheckerboardPlacementStrategy(
            this.autoGridDensity,
            moduleSpecsTool.outlineCtx,
            moduleSpecsTool.keepoutCtxs[this.currentRoofPlane],
            this.getAzimuthAndTilt().azimuth
        );
    }

    proposeAutoViewsheds() {
        if (!this.autoStrategy) {
            this.useDensityGridAutoStrategy(); // bc hack
        }
        this.proposedViewshedLocations = this.autoStrategy.getLocations();

        this.proposedViewsheds = new Points(
            new BufferGeometry().setFromPoints(
                this.proposedViewshedLocations
            ),
            PROPOSED_AUTO_LOCATION_MATERIAL
        );

        scene.add(this.proposedViewsheds);
    }

    clearAutoViewsheds() {
        scene.remove(this.proposedViewsheds);
        this.proposedViewsheds?.geometry?.dispose?.();

        this.proposedViewshedLocations = [];
    }

    async getViewshedOverlay(imageData, imageWidth, lat, lng, year, utcOffset) {
        const result = await this.overlayWorkerPool.dispatch({
            imageData,
            imageWidth,
            lat,
            lng,
            year,
            utcOffset
        });

        return {
            // The web worker MPI serializes class instances to raw objects
            // so we have to hydrate a ViewshedOverlay in *our* process space
            // from the fields returned by the worker for it.
            overlay: Object.assign(new ViewshedOverlay(), result.overlay),
            shadeData: result.shadeData
        };
    }

    async commitAutoViewsheds() {
        this.proposedViewsheds.visible = false;

        // Set heights off the plane are fine for proposing locations, but
        // we need to raycast down to the actual model space to do the viewsheds.
        const rc = new Raycaster();

        const clampedPoints = [];
        const offset = moduleSpecsTool.outlineCtx.fitPlane.normal;
        const dir = offset.clone().negate();

        for (const point of this.proposedViewshedLocations) {
            rc.set(point.clone().add(offset), dir);

            const [intersection, ..._] = rc.intersectObjects(
                moduleSpecsTool.getModuleMeshes().concat(this.meshes)
            );

            if (intersection) {
                clampedPoints.push(intersection.point);
            }
        }

        return this.createMultipleViewshedsOnPlane(
            clampedPoints,
            true,
            this.currentRoofPlane,
            renderer,
            scene
        );
    }

    /**
     *
     * @param {Array<number>} viewshedIndices
     */
    async refreshViewshedsByIndex(viewshedIndices) {
        const viewshedsToUpdate = this.viewsheds[this.currentRoofPlane].filter((value, index) => viewshedIndices.includes(index));
        return this.refreshViewsheds(viewshedsToUpdate).then( () => {
            const markers = this.markers[this.currentRoofPlane];
            if(markers === undefined) {
                return;
            }
            const viewsheds = this.viewsheds[this.currentRoofPlane];
            for(let idx of viewshedIndices) {
                scene.remove(markers[idx]);
                markers[idx] = undefined;
                const viewshed = viewsheds[idx];
                const pos = viewshed.position;
                if (pos) {
                    const display_value = this.getDisplayValue(viewshed);
                    const marker = this.createMarker( pos, idx+1, display_value, viewshed.automatic);
                    scene.add(marker);
                    markers[idx] = marker;
                }
            }
            this.scaleMarkerSprites();
        });
    }

    /**
     *
     * @param {Array<ViewshedDefinition>} viewshedsToUpdate
     */
    async refreshViewsheds(viewshedsToUpdate) {
        let s = '';
        if(viewshedsToUpdate.length > 1) {
            s = 's';
        }

        const insolationData = this.getInsolationForLocation(this.currentRoofPlane);
        const optimumInsolation = this.getOptimumInsolation();

        const chunks = chunkBy(this.concurrency)(viewshedsToUpdate);

        occluderTool.hideFeaturesFromViewsheds();
        const restoreModuleSpecsVisibility = moduleSpecsTool.setFeatureVisibility(false);

        for (let chunkIdx = 0; chunkIdx < chunks.length; ++chunkIdx) {
            const chunk = chunks[chunkIdx];
            emitEvent(
                'status',
                {message: `Calculating shading for viewshed ${Math.min(this.concurrency * chunkIdx + 1, viewshedsToUpdate.length)} of ${viewshedsToUpdate.length}`}
            );

            await Promise.all(chunk.map(viewshedDefinition => this.populateViewshedDefinitionData(viewshedDefinition, insolationData, optimumInsolation)));
            emitEvent('progress', {percent: 50 * (chunkIdx + 1) / chunks.length});
        }
        emitEvent('status', {message: `Successfully calculated 3D shading data`});

        occluderTool.toggleVisibility();
        restoreModuleSpecsVisibility();

        for( let chunkIdx = 0; chunkIdx < chunks.length; ++chunkIdx ) {
            const chunk = chunks[chunkIdx];
            emitEvent(
                'status',
                {message: `Placing viewshed ${Math.min(this.concurrency * chunkIdx + 1, viewshedsToUpdate.length)} of ${viewshedsToUpdate.length}`}
            )

            await Promise.all(
                chunk.map( viewshedDefinition =>
                    this.populateViewshedDefinitionReport(viewshedDefinition)
                )
            );

            // 50% start from shading -> 95% at report completion
            emitEvent('progress', {percent: 50 + 45 * (chunkIdx + 1) / chunks.length});
        }

        emitEvent('status', {message: `Successfully placed ${viewshedsToUpdate.length} viewshed${s}`});
        emitEvent('progress', {percent:100});
    }

    async populateViewshedDefinitionData(viewshedDefinition, insolationData, optimumInsolation) {
        const imageData = this.renderViewshed(viewshedDefinition.position, renderer, scene, this.viewshed, false );
        /** @type {{overlay: ViewshedOverlay, shadeData: any}} */
        const {overlay, shadeData} = await this.getViewshedOverlay(
            imageData,
            this.imageWidth,
            viewshedDefinition.overlay.lat,
            viewshedDefinition.overlay.lng,
            viewshedDefinition.overlay.year,
            viewshedDefinition.overlay.offset
        );
        const availableInsolation = this.getAvailableInsolation(
            insolationData,
            shadeData
        );
        const solarAccess = availableInsolation.total / insolationData.total;
        // Math.min is a kludge here in case our total output is somehow larger than the optimum output
        const TOF = Math.min(insolationData.total / optimumInsolation, 1);
        viewshedDefinition.solarAccess = Math.round(solarAccess * 10000) / 100;
        viewshedDefinition.TOF = Math.round(TOF * 10000) / 100;
        viewshedDefinition.TSRF = Math.round(solarAccess * TOF * 10000) / 100;

        const monthly = this.getAvailableMonthlyInsolationPercent(insolationData, availableInsolation);
        viewshedDefinition.summer = this.avg(monthly.slice(4, 9));
        viewshedDefinition.winter = this.avg(monthly.slice(0, 4).concat(monthly.slice(9, 12)));

        viewshedDefinition.intermediateData = { overlay: overlay, imageData: imageData, monthly: monthly };
    }

    async populateViewshedDefinitionReport(viewshedDefinition) {
        const {overlay, imageData, monthly} = viewshedDefinition.intermediateData;
        viewshedDefinition.intermediateData = undefined;
        const now = new Date().getTime();
        const prefix = this.projectId + '/viewsheds';
        // substring removes the preceeding '/' in the path
        const keyViewshed = viewshedDefinition.viewshed ? new URL(viewshedDefinition.viewshed).pathname.substring(1) : `${prefix}/plane_${now}.png`;
        const keyGraph = viewshedDefinition.monthlyGraph ? new URL(viewshedDefinition.monthlyGraph).pathname.substring(1) : `${prefix}/plane_graph_${now}.svg`;
        const keyOverlay = viewshedDefinition.overlay.svg ? new URL(viewshedDefinition.overlay.svg).pathname.substring(1) : `${prefix}/plane_overlay_${now}.svg`;

        const graph = GRAPH.monthly(monthly, {
            color:    Colors.Scanifly.blue(),
            type:     'line',
            compress: true,
            format:   function (x) { return d3.format('s')(x) + '%'; },
            fontSize: 4
        });

        const decimatorUrl = await Net.getDecimatorUrl();

        const [a, b, c] = await Q.all([
            Net.postJSON(decimatorUrl + '/mask', {
                key:       keyViewshed,
                img_data:  imageData.png,
                poly_data: overlay.getBounds(this.imageWidth/2)
            }),
            Net.postJSON(decimatorUrl + '/s3', {
                key:          keyOverlay,
                data:         overlay.getSVG(imageData.pixels).node().outerHTML,
                content_type: 'image/svg+xml'
            }),
            Net.postJSON(decimatorUrl + '/s3', {
                key:          keyGraph,
                data:         graph.node().outerHTML,
                content_type: 'image/svg+xml'
            })
        ]);

        const cacheBuster = '?' + uuidv4();
        viewshedDefinition.viewshed = a.img_data;
        viewshedDefinition.monthlyGraph = c.url + cacheBuster;
        viewshedDefinition.overlay.svg = b.url + cacheBuster;
        return viewshedDefinition;
    }

    /**
     * @param {THREE.Vector3[]} points at which to place viewsheds
     * @param {boolean} automatic are the points automatically generated, or manual?
     * @param {number} pid plane id
     * @param {THREE.WebGLRenderer} renderer of the 3D context
     * @param {THREE.Scene} scene
     */
    async createMultipleViewshedsOnPlane(points, automatic, pid, renderer, scene) {

        this.state = this.states.AUTO;

        const new_viewsheds = points.map( point => new ViewshedDefinition( point, this.lat, this.lng, this.UTCOffset, this.year, automatic ) );
        await this.refreshViewsheds(new_viewsheds);
        const new_indices = await [...Array(new_viewsheds.length).keys()].map(idx => idx + this.viewsheds[pid].length);
        this.viewsheds[pid].push(...new_viewsheds);
        const markers = new_viewsheds.map( (viewshed,idx) => {
            const display_value = this.getDisplayValue(viewshed);
            const marker = this.createMarker(viewshed.position, new_indices[idx] + 1, display_value, true);
            this.scaleMarkerSprite(marker);
            return marker;
        });
        this.addMarkers(this.currentRoofPlane, ...markers);

        this.state = this.states.READY;

        this.propagateState();
    }

    /**
     * @param {number}        planeId   on which to insert the viewshed
     * @param {Vector3} position  in world space to insert the viewshed
     * @param {number}        label     numeric label to assign to viewshed
     * @param {number}        value     display value fraction for color interpolation [0, 1]
     * @param {boolean}       automatic whether the marker is for a viewshed
     *   generated automatically or not
     */
    addMarker(planeId, position, label, value, automatic = false) {
        return this.addMarkers(
            planeId,
            this.createMarker(
                position,
                label,
                value,
                automatic
            )
        )[0];
    }

    /**
     * @param {number} planeId
     * @param {Object3D[]} markers
     */
    addMarkers(planeId, ...markers) {
        scene.add(...markers);

        if (!this.markers[planeId]) {
            this.markers[planeId] = [];
        }

        this.markers[planeId].push(...markers);

        return markers;
    }

    /**
     * @param {Vector3} position      in world space to insert the marker
     * @param {number} label                numeric label to assign to the viewshed
     * @param {number} value                the value fraction for color interpolation in [0, 1]
     * @param {boolean} [automatic = false] whether or not to create as an "automatic" marker
     *   (used to determine border color scheme)
     */
    createMarker(position, label, value, automatic = false) {
        const markerColor = `#${InfernoGradient.value(value).getHexString()}`;
        const fontColor = value < 0.84 ? Colors.white() : Colors.darkGray();

        const t = new Tooltip({
            color:       fontColor,
            bgColor:     markerColor,
            borderColor: automatic
                ? Colors.Scanifly.teal()
                : Colors.Scanifly.red(),       // 0DCAD3 - teal, FF4D4D - red
            radius:      16,
            offset:      0
        });

        t.setTextCircle(' ' + label + ' ');
        t.show();

        const sprite = t.getSprite();
        sprite.position.copy(position);

        return sprite;
    }

    hideAllMarkers() {
        for (let plane of this.markers)
            if (plane !== undefined)
                for (let m of plane)
                    m.visible = false;
    }

    showPlaneMarkers() {
        for (let pid = 0; pid < this.markers.length; pid++) {
            if (this.markers[pid]) {
                for (let marker of this.markers[pid])
                    marker.visible = this.show.get([ pid ]);
            }
        }
    }

    avg(arr) {
        if (arr.length > 0) {
            return arr.reduce(function (a, b) { return a + b; }) / arr.length;
        }
    }

    getSolarAccessAverages(plane) {
        var len = this.viewsheds[plane].length;
        var avgs = {
            solarAccess: 0,
            TOF:         0,
            TSRF:        0,
            count:       len
        };

        if (len > 0) {
            for (var i = 0; i < len; i++) {
                avgs['solarAccess'] += this.viewsheds[plane][i].solarAccess;
                avgs['TOF']         += this.viewsheds[plane][i].TOF;
                avgs['TSRF']        += this.viewsheds[plane][i].TSRF;
            }

            avgs['solarAccess'] /= len;
            avgs['TSRF']        /= len;
            avgs['TOF']         /= len;
        }

        return avgs;
    }


    getAllSolarAccessAverages() {
        var planes = [];

        for (var planeId = 0; planeId < this.planes.length; planeId++) {
            planes.push(this.getSolarAccessAverages(planeId));
        }

        return planes;
    }

    /**
     * @param {Vector3} position
     * @param {WebGLRenderer} renderer
     * @param {Scene} scene
     * @param {Viewshed} [viewshed = this.viewshed]
     * @param {boolean} [showVector = true] Whether or not to show the vector marker for viewshed placement.
     */
    renderViewshed(position, renderer, scene, viewshed = this.viewshed, showVector = true) {
        this.fisheyeLens.position.copy(position);
        viewshed.setOutputWidth(this.imageWidth);
        viewshed.visible = true;
        this.fisheyeVector.visible = false;
        this.hideAllMarkers();

        // renderer.setSize() multiplies the size by the pixel ratio, we don't want that
        var pixelRatio = renderer.getPixelRatio();
        var rSize = renderer.getSize();

        renderer.setSize(this.imageWidth / pixelRatio, this.imageWidth / pixelRatio, false);
        renderer.setClearColor(0x7e00ff, 1.0);
        renderer.clear();
        this.fisheyeLens.render(renderer, scene);
        renderer.render(scene, this.captureCamera);

        // we're calling toDataURL() and readPixels() right after the render
        // call in the same function, so we should be ok
        var png = renderer.domElement.toDataURL('image/png');

        var gl = renderer.domElement.getContext('webgl');
        var pixels = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4);
        gl.readPixels(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

        viewshed.visible = false;
        this.fisheyeVector.visible = showVector;
        this.showPlaneMarkers();

        renderer.setSize(rSize.width, rSize.height, false);
        renderer.setClearColor(new Color(0.749, 0.82, 0.898), 1.0);
        renderer.clear();

        return { png: png, pixels: pixels };
    }


    /**
     * Estimate the insolation for a location by calculating the insolation figures
     * for the 1st of each month.
     *
     * @param {number} [pid = this.currentRoofPlane]
     * @returns {InsolationData}
     */
    getInsolationForLocation(pid = this.currentRoofPlane) {
        var insolation = new InsolationData();

        for (var m = 0; m < 12; m++) {
            var monthTotal = 0;

            for (var h = 0; h < 24; h++) {
                var d = this.weatherStation.data[m][h];

                if (d[0] > 0) {
                    var p = calculateSunPositionSunCalc(this.lat, this.lng, this.year, m, 1, h, 30, 0, this.UTCOffset);

                    var ins = calculateInsolation(d[0], d[1], d[2], d[3], p.azimuth, p.elevation,
                        this.planes[pid].tilt,
                        this.planes[pid].azimuth);

                    monthTotal += ins;
                    insolation.hourly.push(ins);
                } else {
                    insolation.hourly.push(0);
                }
            }

            insolation.total += monthTotal;
            insolation.monthly.push(monthTotal);
        }

        return insolation;
    }


    /**
     *
     * @returns {number}
     */
    getOptimumInsolation() {
        var total = 0;

        for (var m = 0; m < 12; m++) {
            for (var h = 0; h < 24; h++) {
                var d = this.weatherStation.data[m][h];

                if (d[0] > 0) {
                    var p = calculateSunPositionSunCalc(this.lat, this.lng, this.year, m, 1, h, 30, 0, this.UTCOffset);

                    var ins = calculateInsolation(d[0], d[1], d[2], d[3], p.azimuth, p.elevation,
                        this.weatherStation.optTilt, this.weatherStation.optAzimuth);
                    total += ins;
                }
            }
        }

        return total;
    }

    /**
     *
     * @param {InsolationDefinition} insolationData
     * @param {*} shadedData
     * @returns
     */
    getAvailableInsolation(insolationData, shadedData) {
        var available = {
            total:   insolationData.total,
            monthly: insolationData.monthly.slice()
        };

        for (var m = 0; m < shadedData.length; m++) {
            for (var t = 0; t < shadedData[m].length; t++) {
                var h = (24 + shadedData[m][t].hoursUTC + this.UTCOffset) % 24;
                // subtract a quarter of the insolation, shaded times are in 15 minute increments
                var ins = insolationData.hourly[m * 24 + h] / 4

                available.total      -= ins;
                available.monthly[m] -= ins;
            }
        }

        return available;
    }


    getAvailableMonthlyInsolationPercent(insolationData, availableData) {
        var availableMonthly = [];

        for (var m = 0; m < 12; m++) {
            availableMonthly.push(availableData.monthly[m] / insolationData.monthly[m] * 100);
        }

        return availableMonthly;
    }


    removePlane(plane) {
        this.planes     .splice(plane, 1);
        this.viewsheds  .splice(plane, 1);
        this.annotations.splice(plane, 1);

        this.currentRoofPlane = this.viewsheds.length - 1;

        this.clearAutoViewsheds();

        if (this.markers[plane] !== undefined) {
            for (let m of this.markers[plane])
                scene.remove(m);

            this.markers.splice(plane, 1);
        }
    }


    removeViewshed(vid) {
        return this.removeViewsheds(this.currentRoofPlane, [vid]);
    }


    async removeAutoViewsheds(pid) {
        if (!pid)
            pid = this.currentRoofPlane;

        if (pid >= this.viewsheds.length)
            return;

        let autoViewshedIds = [];

        for (let vid = 0; vid < this.viewsheds[pid].length; vid++)
            if (this.viewsheds[pid][vid].automatic)
                autoViewshedIds.push(vid);

        await this.removeViewsheds(pid, autoViewshedIds);
        return this.cmd('proposeAutoViewsheds', this.autoGridDensity);
    }


    removeViewsheds(pid, vids) {
        // remove viewsheds from the annotations svg (image on the report with all the markers per plane)
        for (let vid of vids) {
            this.annotations[pid]
                .removeViewshed(vid)
                .decrementViewsheds(vid);
        }

        this.annotations[pid].redraw();

        // upload updated annotations svg, then proceed to delete viewsheds and renumber location markers
        return this.getAnnotationsSVG(pid).then(() => {
            // identify which viewsheds to delete before splicing changes array indices
            let toDelete = [];

            for (let vid of vids)
                toDelete.push(this.viewsheds[pid][vid]);

            for (let viewshed of toDelete) {
                let idx = this.viewsheds[pid].indexOf(viewshed);

                if (idx > -1)
                    this.viewsheds[pid].splice(idx, 1);
            }

            this.recreateViewshedMarkers(pid);
            this.scaleMarkerSprites();
            this.propagateState();
        });
    }


    recreateViewshedMarkers(pid) {
        if (this.markers[pid])
            for (let m of this.markers[pid])
                scene.remove(m);

        this.markers[pid] = [];

        for (let vid = 0; vid < this.viewsheds[pid].length; vid++) {
            const viewshed = this.viewsheds[pid][vid];
            const pos = viewshed.position;
            if (pos) {
                const display_value = this.getDisplayValue(viewshed);
                this.addMarker(
                    pid,
                    new Vector3(pos.x, pos.y, pos.z),
                    vid + 1,
                    display_value,
                    viewshed.automatic || false
                );
            }
        }
    }


    /**
     * Load either the closest station or the station with the id provided.
     */
    loadStationData() {
        var stationId, distance;
        var deferred = Q.defer();

        Net.loadStationList().then((response) => {
            let station = findClosestStation(response.stationList, this.lat, this.lng);

            distance = Math.round(station[0] * 10) / 10;
            stationId = station[1];

            return Net.loadStation(stationId);
        }).then((response) => {
            this.weatherStation          = response;
            this.weatherStation.id       = stationId;
            this.weatherStation.distance = distance;

            deferred.resolve({
                info:       response.info,
                distance:   distance,
                UTCOffset:  response.UTCOffset,
                optTilt:    response.optTilt,
                optAzimuth: response.optAzimuth
            });
        }).fail((error) => {
            deferred.reject(error);
        });

        return deferred.promise;
    }


    getHorizonProfile({ pid, vid }) {
        if (pid === undefined)
            pid = this.currentRoofPlane;

        let deferred = Q.defer();

        this.getElevationBreakdown(this.viewsheds[pid][vid].viewshed).then((data) => {
            var imgW = 600;
            var imgH = 600;
            let colW = imgW / 360;

            let canvas = document.createElement('canvas');

            canvas.width  = imgW;
            canvas.height = imgH;

            let ctx = canvas.getContext('2d');

            ctx.lineWidth = 0.2;
            ctx.fillStyle = '#444';

            for (let az = 0; az < 360; az++) {
                let h = data[az] / 90 * imgH;

                ctx.fillRect(Math.round(az * colW), imgH - h, Math.round(colW), h);
                //ctx.strokeRect(Math.round(az * colW) + 0.5, imgH - h, Math.round(colW), h);
            }

            deferred.resolve(canvas.toDataURL());
        });

        return deferred.promise;
    }


    getElevationBreakdown(viewshedUrl) {
        let deferred = Q.defer();
        let img = document.createElement('img');

        img.addEventListener('load', e => {
            let canvasData = document.createElement('canvas');

            canvasData.width  = img.width;
            canvasData.height = img.height;

            let ctxData = canvasData.getContext('2d');

            ctxData.drawImage(img, 0, 0, img.width, img.height);

            let data = ctxData.getImageData(0, 0, img.width, img.height).data;
            let maxEls = this.findMaxElevations(data, 600, 600);

            deferred.resolve(maxEls);
        });
        img.addEventListener('error', e => deferred.reject());

        img.crossOrigin = 'Anonymous';
        img.src = viewshedUrl + '?x=202005250000';

        return deferred.promise;
    }


    findMaxElevations(data, w, h) {
        let elevations = [];

        for (let az = 0; az < 360; az++) {
            let maxEl = 0;

            for (let tilt = 90; tilt >= 0; tilt--) {
                if (this.isShaded(tilt, az, data, w, h)) {
                    maxEl = tilt;
                    break;
                }
            }

            elevations.push(maxEl);
        }

        return elevations;
    }


    isShaded(tilt, az, imgData, w, h) {
        let r = w/2;

        let [ cx, cy ] = this.convert(r, tilt, az);

        let x = Math.round(w/2 + cx);
        let y = Math.round(h/2 + cy);

        let color = this.getColor(imgData, w, x, y);

        // everything except white is shaded
        if ( !(color.r == 255 && color.g == 255 && color.b == 255) )
            return true;

        return false;
    }


    convert(r, tilt, az) {
        let d = r * Math.cos(degToRad(tilt));

        let cx = d * Math.cos(degToRad(az - 90));
        let cy = d * Math.sin(degToRad(az - 90));

        return [ cx, cy ];
    }


    getColor(image, w, x, y) {
        return {
            r: image[(w * y + x) * 4],
            g: image[(w * y + x) * 4 + 1],
            b: image[(w * y + x) * 4 + 2]
        };
    }


    getHorizonSVG() {
        var overlay = new ViewshedOverlay();

        overlay.calculate(this.lat, this.lng, this.year, this.UTCOffset);

        var svg = overlay.getHorizonSVG();

        var b64Start = 'data:image/svg+xml;base64,';
        var svg64 = btoa(svg.node().outerHTML);

        return b64Start + svg64;
    }


    addPlane() {
        this.annotations.push(new CanvasAnnotations());

        this.viewsheds.push([]);

        var last = this.viewsheds.length - 1;
        if (last == 0) {
            this.currentRoofPlane = 0;
        }

        this.planes.push({ name: this.getPlaneName(last) });
    }


    getPlane(planeId)     { return this.planes[planeId]; };
    getPlanes()           { return this.planes; };
    getPlaneName(plane)   { return getPlaneNameUtil(plane); }
    getCurrentRoofPlane() { return this.currentRoofPlane; };


    setCurrentRoofPlane(pid) { this.currentRoofPlane = pid; };

    /**
     * Sets the screenshot image for the specified plane
     * Used by shade reports
     * @param {number} pid - plane index to set the image data in
     * @param {ScreenshotData} img - screenshot data for image
     */
    setImage(pid, img) {
        const p = this.planes[pid];

        p.image       = img.url;
        p.imageWidth  = img.width;
        p.imageHeight = img.height;
        p.thumb       = img.thumbnailUrl;

        this.annotations[pid].setNewImage(true);
    }

    /**
     * Sets the screenshot image for the banner
     * Used by shade reports
     * @param {ScreenshotDAta} img
     */
    setCoverImage(img) {
        this.coverImage = {
            image:       img.url,
            imageWidth:  img.width,
            imageHeight: img.height,
            thumb:       img.thumbnailUrl
        };
    }

    /**
     * @param {MouseEvent} event
     * @param {{x: number, y: number}} mouse
     * @param {boolean} panning
     */
    mouseUp(event, mouse, panning) {
        // Only trigger the mouseUp handler if
        if (!this.enabled || // this tool is active
            event.button !== MOUSE.LEFT || // this is a left mouse click
            panning || // not panning
            // not already capturing a viewshed location or rendering a viewshed
            (this.state !== this.states.READY)
        ) {
            return;
        }

        /** @global */
        const meshes = moduleSpecsTool.getModuleMeshes();

        this.raycaster.setFromCamera(mouse, this.camera);
        var intersects = this.raycaster.intersectObjects(meshes.concat(this.meshes), true);

        if (intersects.length > 0) {
            this.intersect = intersects[0];
            this.fisheyeVector.position.copy(this.intersect.point);
        }

        if (this.intersect) {
            this.fisheyeLens.position.set(
                this.intersect.point.x,
                this.intersect.point.y + 0.05,
                this.intersect.point.z
            );

            this.createMultipleViewshedsOnPlane([this.intersect.point], false, this.currentRoofPlane, renderer, scene );
        }
    }

    /**
     * @param {{x: number, y: number}} mouse
     * @param {boolean} panning
     */
    mouseMove(mouse, panning) {
        if (!this.enabled || panning) {
            return;
        }

        this.raycaster.setFromCamera(mouse, this.camera);
        var intersects = this.raycaster.intersectObjects(this.meshes, true);

        if (intersects.length > 0) {
            this.intersect = intersects[0];
            this.fisheyeVector.position.copy(this.intersect.point);
        }
    }

    mouseWheel() {
        // marker scaling when zooming in/out
        this.scaleMarkerSprites();
    }


    scaleMarkerSprites() {
        for (let plane of this.markers)
            if (plane !== undefined)
                for (let m of plane)
                    this.scaleMarkerSprite(m);
    }

    /** @param {Object3D} m */
    scaleMarkerSprite(m) {
        let d = camera.position.distanceTo(new Vector3(0, 0, 0));
        let k = d * 0.015;

        m.children.forEach(c => c.scale.set(k, k, 1));
    }


    handleKeyboard(keyboard) {
        if (!this.enabled)
            return;

        if (keyboard.down('esc'))
            this.cancel();
    }


    setEnableReportsButton(callback) { this.enableReports = callback; };

    /**
     * @param {number} pid  to update
     * @param {string} name to set for {pid}
     */
    updatePlaneName(pid, name) {
        this.planes[pid].name = name
    }

    async getAnnotationsSVG(planeId) {
        var svg = this.annotations[planeId].getSVG().node().outerHTML;
        var key = this.projectId + '/viewsheds/annotation_' + planeId +
            '_' + new Date().getTime() + '.svg';

        var self = this;

        const decimatorUrl = await Net.getDecimatorUrl();

        const response = await Net.postJSON(decimatorUrl + '/s3', {
            key:          key,
            data:         svg,
            content_type: 'image/svg+xml'
        });

        self.planes[planeId].svg = response.url;
        return response.url;
    }


    clearAnnotationLines() {
        this.annotations[this.currentRoofPlane].clearLines().redraw();
    }


    /**
     * Download and parse monthly solar access percentages for a viewshed.
     *
     * NOTE: ideally, we should be storing these values separately, not parsing
     * them from SVG.
     */
    getSvgPercentages(planeId, i) {
        var deferred = Q.defer();

        $.ajax({
            url:    this.viewsheds[planeId][i].monthlyGraph + '?asdf',
            method: 'GET',
            crossDomain: true,
            success:     function (response) {
                var percentages = [];

                $(response).find('text.n').each(function (i, el) {
                    percentages.push(parseFloat(el.innerHTML));
                });

                deferred.resolve(percentages);
            },
            error: function (error) {
                deferred.reject(new Error(error));
            }
        });

        return deferred.promise;
    }


    /**
     * Calculate monthly averages for an array of 12 element arrays.
     */
    avgPerMonth(data) {
        var averages = [];

        for (var m = 0; m < 12; m++) {
            var sum = 0;

            for (var i = 0; i < data.length; i++) {
                sum += parseFloat(data[i][m]);
            }

            averages.push(sum / data.length);
        }

        return averages;
    }


    /**
     * Calculate monthly solar access for plane.
     */
    getPlaneMonthlySolarAccess(planeId) {
        var deferred = Q.defer();

        if (this.viewsheds[planeId].length > 0) {
            var promises = [];

            for (var i = 0; i < this.viewsheds[planeId].length; i++) {
                promises.push(this.getSvgPercentages(planeId, i));
            }

            var self = this;

            Q.all(promises).then(function (data) {
                var averages = self.avgPerMonth(data);
                deferred.resolve(averages);
            }).then(false, function (error) {
                deferred.reject(new Error(error));
            });
        } else {
            deferred.resolve([ null ]);
        }

        return deferred.promise;
    }


    /**
     * Construct a graph of monthly solar access percentages across all planes/viewsheds.
     */
    getMonthlySolarAccess() {
        var promises = [];

        for (var planeId = 0; planeId < this.viewsheds.length; planeId++) {
            promises.push(this.getPlaneMonthlySolarAccess(planeId));
        }

        var deferred = Q.defer();

        if (promises.length > 0) {
            var self = this;

            Q.all(promises).then(function (data) {
                // Only include planes which have one or more viewsheds in the average
                let dataAvg = data.filter(arr => !(arr.length == 1 && arr[0] === null ));

                let averages = (dataAvg.length > 0)
                    ? self.avgPerMonth(dataAvg)
                    : [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

                deferred.resolve({
                    averages: averages,
                    planes:   data
                });
            }).then(false, function (error) {
                deferred.reject(new Error(error));
            });

            return deferred.promise;
        } else {
            return false;
        }
    }


    getRoofPlaneInfo() {
        var info = [];

        for (var planeId = 0; planeId < this.planes.length; planeId++) {
            var averages = this.getSolarAccessAverages(planeId);
            var viewsheds = [];

            for (var i = 0; i < this.viewsheds[planeId].length; i++) {
                viewsheds.push({
                    solarAccess: this.viewsheds[planeId][i].solarAccess,
                    TOF:         this.viewsheds[planeId][i].TOF,
                    TSRF:        this.viewsheds[planeId][i].TSRF
                });
            }

            info.push({
                name:        this.planes[planeId].name,
                tilt:        this.planes[planeId].tilt,
                azimuth:     this.planes[planeId].azimuth,
                solarAccess: averages.solarAccess,
                TOF:         averages.TOF,
                TSRF:        averages.TSRF,
                viewsheds:   viewsheds
            });
        }

        return info;
    }


    getSaveData() {
        var data = [];

        for (var planeId = 0; planeId < this.viewsheds.length; planeId++) {
            var annotations = {
                lines:       this.annotations[planeId].lines,
                markers:     this.annotations[planeId].markers,
                aspectRatio: this.annotations[planeId].aspectRatio,
                crop:        this.annotations[planeId].crop
            };

            data.push({
                plane:       this.planes[planeId],
                averages:    this.getSolarAccessAverages(planeId),
                viewsheds:   this.viewsheds[planeId],
                annotations: annotations
            });
        }

        return data;
    }


    restoreSaveData(data, legacyData, container) {
        // clear previous viewsheds
        this.viewsheds   = [];
        this.annotations = [];
        this.planes      = [];
        this.markers     = [];

        // legacy data can contain weather station info as first element
        if (data.length > 0 && data[0].stationId !== undefined)
            data = data.slice(1);

        let markerPositions = [];

        if (legacyData) {
            for (let a of legacyData) {
                if (markerPositions[a.pid] === undefined)
                    markerPositions[a.pid] = [];

                markerPositions[a.pid].push(a.position);
            }
        }

        for (let pid = 0; pid < data.length; pid++) {
            this.planes.push(data[pid].plane);
            this.planes[pid].name = this.planes[pid].name || this.getPlaneName(pid);

            if (data[pid].annotations) {
                this.annotations.push(new CanvasAnnotations(data[pid].annotations));
            } else {
                this.annotations.push(new CanvasAnnotations());
            }

            this.viewsheds[pid] = data[pid].viewsheds;

            for (let vid = 0; vid < this.viewsheds[pid].length; vid++) {
                const viewshed = this.viewsheds[pid][vid];
                let pos = viewshed.position;

                // viewshed positions used to be stored in a version of annotations tool
                // and were not stored at all before that
                let vPos;

                if (pos) {
                    vPos = new Vector3(pos.x, pos.y, pos.z);
                } else if (markerPositions[pid] && markerPositions[pid][vid]) {
                    pos = markerPositions[pid][vid];

                    vPos = container.localToWorld(new Vector3(pos.x, pos.y, pos.z));
                    vPos.add(new Vector3(0, 0.1, 0));

                    viewshed.position = { x: vPos.x, y: vPos.y, z: vPos.z };
                }

                if (vPos) {
                    const display_value = this.getDisplayValue(viewshed);
                    this.addMarker(pid, vPos, vid + 1, display_value, viewshed.automatic || false);
                }
            }
        }

        // fill array holes caused by legacy data
        for (let i = 0; i < this.markers.length; i++) {
            if (this.markers[i] === undefined)
                this.markers[i] = [];
        }

        this.scaleMarkerSprites();
    }

    get overlayWorkerPool() {
        if (this._overlayWorkerPool) {
            return this._overlayWorkerPool;
        }

        this._overlayWorkerPool = new WorkerPool(this.concurrency);

        return this._overlayWorkerPool;
    }

    /**
     * Exports Viewshed data as JSON
     * @param {string} filename
     */
    exportViewshedData(filename) {
        const data = JSON.stringify(this.viewsheds, null, 2);
        const blob = new Blob([data], {type: 'application/json'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    }
}


export { ViewshedTool };
