


class Format {
    static imperial(meters) {
        var inchesPerMeter = 39.3701;
        var inches = Math.round(meters * inchesPerMeter);
        var feet = Math.floor(inches / 12);
        inches = inches % 12;

        return feet + "' " + inches + '"';
    }

    static metric(meters) {
        return Math.round(meters * 100) / 100 + 'm';
    }

    static val(s, precision) {
        if (s !== undefined && s !== null && !isNaN(s))
            return precision ?
                Math.round(s * precision * 10) / (precision * 10) :
                s;
        else
            return '';
    }


    static wattsAsKilowatts(w) {
        let n = (w === 0) ?
            0 :
            (w / 1000).toFixed(3);

        return n + ' kW';
    }
}



export { Format };