var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        class=\"auto-viewsheds-strategy-selector sw-multiway disabled\"\n        data-tippy-content=\"Automatic viewshed creation is disabled because automatic viewsheds\n            are already present on the active roof plane.\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        class=\"auto-viewsheds-strategy-selector sw-multiway\"\n    ";
},"5":function(container,depth0,helpers,partials,data) {
    return "            class=\"sw-option\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            aria-disabled=\"true\"\n            class=\"sw-option disabled\"\n            data-tippy-content=\"Per-module automatic placement is disabled because the active roof\n                plane does not have any modules placed.\"\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAutoViewsheds") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + ">\n    <div id=\"sw-option-auto-viewsheds-grid\" class=\"sw-option active\">\n        <div>Whole Roof</div>\n    </div>\n    <div id=\"sw-option-auto-viewsheds-per-mod\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasArray") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + ">\n            <div>Per Module</div>\n    </div>\n</div>\n";
},"useData":true});