var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"viewsheds-auto hidden\">\n    <div class=\"auto-viewsheds-container\">\n        <div class=\"auto-viewsheds-placement\">\n            <div class=\"auto-viewsheds-header\">\n                <h4 class=\"label\">Placement Strategy</h4>\n                <div class=\"qmark-info\">\n"
    + ((stack1 = container.invokePartial(require("./ViewshedsAutoTooltipContent.hbs"),depth0,{"name":"ViewshedsAutoTooltipContent","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = container.invokePartial(require("../BetaTag.hbs"),depth0,{"name":"../BetaTag","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = container.invokePartial(require("./AutoViewshedsStrategyPanelDiv.hbs"),depth0,{"name":"AutoViewshedsStrategyPanelDiv","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"auto-viewsheds-density\">\n            <div class=\"auto-viewsheds-header\">\n                <h4 class=\"label\">Density/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"units") : depth0), depth0))
    + "&sup2;</h4>\n            </div>\n            <div class=\"auto-viewsheds-density-controls\">\n                <div class=\"roof-density-spinner\">\n                    <input type=\"hidden\" id=\"auto-viewshed-density\">\n                    <input \n                        id=\"density-spinner\"\n                        name=\"value\"\n                        type=\"number\"\n                        min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"spinnerMin") : depth0), depth0))
    + "\"\n                        max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"spinnerMax") : depth0), depth0))
    + "\"\n                        step=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"spinnerStep") : depth0), depth0))
    + "\"\n                        value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"spinnerValue") : depth0), depth0))
    + "\"\n                        size=\"32\"\n                        aria-label=\"density\"\n                    >\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"auto-viewsheds-controls\">\n        <div class=\"viewshed-buttons\">\n            <button class=\"sy generate-auto-viewsheds\">\n                Generate\n            </button>\n            <button class=\"sy delete-all-auto-viewsheds hidden\" aria-disabled=\"true\">\n                <i class=\"fa fa-trash\"></i>&nbsp; Delete All Auto Viewsheds\n            </button>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});