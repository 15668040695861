import { Format } from '../gui/Format';
import { radToDeg } from '../libs/Geometry';
import { sceneOrtho } from '../Viewer.js';
import { Tooltip } from '../libs/Tooltip';
import { Geometry, Line, LineBasicMaterial, MOUSE, Vector3 } from '../libs/three.module';





var MeasurementUnits = { METERS: 0, FEET_INCHES: 1 };


class MeasurementsTool {
    constructor() {
        this.distance = 0;
        this.azimuth  = 0;

        var geometry = new Geometry();
        geometry.vertices.push(new Vector3(0, 0, 0));
        geometry.vertices.push(new Vector3(0, 0, 0));

        this.material = new LineBasicMaterial({
            color:       0x00ff00,
            linewidth:   5,
            transparent: true
        });

        this.line = new Line(geometry, this.material);
        this.line.frustumCulled = false;

        this.secondaryMaterial = new LineBasicMaterial({
            color:       0xffff00,
            linewidth:   2,
            transparent: true
        });

        var g90 = new Geometry();
        g90.vertices.push(new Vector3(0, 0, 0));
        g90.vertices.push(new Vector3(0, 0, 0));

        this.line90 = new Line(g90, this.secondaryMaterial);
        this.line90.frustumCulled = false;

        var g180 = new Geometry();
        g180.vertices.push(new Vector3(0, 0, 0));
        g180.vertices.push(new Vector3(0, 0, 0));

        this.line180 = new Line(g180, this.secondaryMaterial);
        this.line180.frustumCulled = false;

        var g270 = new Geometry();
        g270.vertices.push(new Vector3(0, 0, 0));
        g270.vertices.push(new Vector3(0, 0, 0));

        this.line270 = new Line(g270, this.secondaryMaterial);
        this.line270.frustumCulled = false;

        this.showOffsets = false;

        this.states = { NONE: 0, STARTED: 1, STOPPED: 2 };
        this.state = this.states.NONE;

        this.units = MeasurementUnits.FEET_INCHES;

        this.meshes = [];

        this.tooltip    = new Tooltip();
        this.tooltip90  = new Tooltip();
        this.tooltip180 = new Tooltip();
        this.tooltip270 = new Tooltip();

        this.cancel();
    }


    setCamera(camera) { this.camera = camera;   }
    setRaycaster(rc)  { this.raycaster = rc;    }
    setMesh(mesh)     { this.addMesh(mesh);     }
    addMesh(mesh)     { this.meshes.push(mesh); }


    addToScene(scene) {
        scene.add(this.line);
        scene.add(this.line90);
        scene.add(this.line180);
        scene.add(this.line270);

        sceneOrtho.add(this.tooltip.getSprite());
        sceneOrtho.add(this.tooltip90.getSprite());
        sceneOrtho.add(this.tooltip180.getSprite());
        sceneOrtho.add(this.tooltip270.getSprite());
    }


    getState() {
        var s = {
            distance: this.distance,
            units:    this.units,
            xray:     this.enableXray,
            offsets:  this.showOffsets
        };

        return s;
    }


    setXray(value) {
        this.enableXray = value;

        this.material         .depthTest   = !value;
        this.secondaryMaterial.depthTest   = !value;
        this.material         .needsUpdate = true;
        this.secondaryMaterial.needsUpdate = true;
    }


    setUnits(units) {
        this.units = units;

        if (this.distance !== undefined && this.distance > 0)
            this.displayMeasurement();
    }


    setShowOffsets(value) {
        this.showOffsets = value;

        if (this.state === this.states.STARTED || this.state === this.states.STOPPED)
            this.displayMeasurement();
    }


    registerRenderState     (callback) { this.renderState      = callback; }
    registerRenderMouseState(callback) { this.renderMouseState = callback; }

    enable() { this.enabled = true; }

    cancel() {
        this.state = this.states.NONE;

        this.line   .visible = false;
        this.line90 .visible = false;
        this.line180.visible = false;
        this.line270.visible = false;

        this.tooltip.hide();
        this.tooltip90.hide();
        this.tooltip180.hide();
        this.tooltip270.hide();

        this.distance = undefined;
    }


    disable() {
        if (!this.enabled)
            return;
        this.cancel();
        this.enabled = false;
    }


    mouseUp(event, mouse, panning) {
        if (!this.enabled || event.button !== MOUSE.LEFT || panning)
            return;

        if (this.state === this.states.NONE || this.state === this.states.STOPPED) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes, true);

            if (intersects.length > 0) {
                this.state = this.states.STARTED;

                this.point = intersects[0].point;
                this.previousPoint = intersects[0].point;
            }
        } else if (this.state === this.states.STARTED) {
            this.state = this.states.STOPPED;

            this.displayMeasurement();
        }
    }


    mouseMove(mouse, panning) {
        if (!this.enabled)
            return;

        if (this.state === this.states.STARTED) {
            if (!panning)
                this.updateLineFromMouse(mouse);

            this.displayMeasurement();
        } else if (this.state === this.states.STOPPED) {
            // update tooltip in stopped state when camera is moved/rotated
            this.displayMeasurement(true);
        }
    }


    mouseWheel(mouse) {
        if (!this.enabled)
            return;

        if (this.state !== this.states.NONE)
            this.displayMeasurement();
    }


    handleKeyboard(keyboard) {
        if (!this.enabled)
            return;

        if (keyboard.down('esc'))
            this.cancel();
    }


    updateLineFromMouse(mouse) {
        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObjects(this.meshes, true);

        if (intersects.length === 0)
            return false;

        this.point = intersects[0].point;

        var g = this.line.geometry;

        g.vertices[0].copy(this.previousPoint);
        g.vertices[1].copy(this.point);

        g.verticesNeedUpdate = true;
        this.line.visible = true;

        this.distance = this.point.distanceTo(this.previousPoint);

        var offset = this.point.clone()
            .sub(this.previousPoint)
            .cross(new Vector3(0, 1, 0))
            .normalize()
            .multiplyScalar(this.distance * 0.33);

        this.offset90 = offset.clone().add(this.previousPoint);

        var g90 = this.line90.geometry;

        g90.vertices[0].copy(this.previousPoint);
        g90.vertices[1].copy(this.offset90);

        g90.verticesNeedUpdate = true;
        this.line90.visible = this.showOffsets;

        this.offset180 = offset.clone()
            .cross(new Vector3(0, 1, 0))
            .add(this.previousPoint);

        let g180 = this.line180.geometry;

        g180.vertices[0].copy(this.previousPoint);
        g180.vertices[1].copy(this.offset180);

        g180.verticesNeedUpdate = true;
        this.line180.visible = this.showOffsets;

        this.offset270 = offset.clone()
            .negate()
            .add(this.previousPoint);

        var g270 = this.line270.geometry;

        g270.vertices[0].copy(this.previousPoint);
        g270.vertices[1].copy(this.offset270);

        g270.verticesNeedUpdate = true;
        this.line270.visible = this.showOffsets;

        var vAngle = this.point.clone()
            .sub(this.previousPoint)
            .setY(0);
        this.azimuth = vAngle.clone().angleTo(new Vector3(1, 0, 0));

        if (vAngle.angleTo(new Vector3(0, 0, -1)) < Math.PI/2)
            this.azimuth = 2*Math.PI - this.azimuth;

        this.azimuth = (360 + radToDeg(this.azimuth) - 90) % 360;
    }


    displayMeasurement(skipTextUpdate) {
        this.line90 .visible = this.showOffsets;
        this.line180.visible = this.showOffsets;
        this.line270.visible = this.showOffsets;

        var lines = [
            (this.units === 0) ? Format.metric(this.distance) : Format.imperial(this.distance),
            Math.round(this.azimuth * 100)/100 + '° azimuth'
        ];

        if (!skipTextUpdate) { // optionally skip text updates to maintain fps
            this.tooltip.setText(lines);
            this.tooltip90.setText(Math.round((this.azimuth + 90) % 360 * 100) / 100 + '°');
            this.tooltip180.setText(Math.round((this.azimuth + 180) % 360 * 100) / 100 + '°');
            this.tooltip270.setText(Math.round((this.azimuth + 270) % 360 * 100) / 100 + '°');
        }

        this.tooltip.setPosition(this.point.clone().project(this.camera));
        this.tooltip.show();

        if (this.showOffsets) {
            this.tooltip90.setPosition(this.line90.geometry.vertices[1].clone().project(this.camera));
            this.tooltip90.show();

            this.tooltip180.setPosition(this.line180.geometry.vertices[1].clone().project(this.camera));
            this.tooltip180.show();

            this.tooltip270.setPosition(this.line270.geometry.vertices[1].clone().project(this.camera));
            this.tooltip270.show();
        } else {
            this.tooltip90.hide();
            this.tooltip180.hide();
            this.tooltip270.hide();
        }
    }


    getSaveData() {
        return {
            units:   this.units,
            xray:    this.enableXray,
            offsets: this.showOffsets
        }
    }


    restoreSaveData(data) {
        this.units = data.units;
        this.setXray(data.xray);
        this.setShowOffsets(data.offsets);
    }
}


export { MeasurementsTool, MeasurementUnits };
