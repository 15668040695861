import { radToDeg, degToRad } from '../libs/Geometry';
import { SunCalc } from './suncalc';



/**
 * Adapted from Solpy (C) 2013 Nathan Charles
 * https://github.com/nrcharles/solpy
 */

/**
 * Total irradiance/insolation an inclined plane.
 */
function calculateInsolation(Gh, Dh, Bh, etr, azDeg, altDeg, panelTilt, panelAzimuth) {
    var az = degToRad(azDeg);
    var alt = degToRad(altDeg);
    var slope = degToRad(panelTilt);
    var aaz = degToRad(panelAzimuth + 180);
    var Z = Math.PI / 2 - alt;
    var theta = Math.acos(Math.cos(Z) * Math.cos(slope) +
            Math.sin(slope) * Math.sin(Z) * Math.cos(az - Math.PI - aaz));
    var S = degToRad(panelTilt);

    return total_irradiance(Gh, Dh, Bh, etr, az, alt, S, theta);
}

function total_irradiance(Gh, Dh, Bh, etr, az, alt, S, theta) {
    var Z = Math.PI / 2 - alt;
    var nA = radToDeg(az) % 360 - 180;

    var Bth = Math.max(0, Bh * Math.cos(theta));
    var Dth = perez(Bh, Dh, etr, S, theta, Z);
    var p = 0.2;
    var Rth = Gh * p * (1 - Math.cos(S)) / 2;

    if (S > 0) {
        return Bth + Dth + Rth;
    } else {
        return Gh;
    }
}

/**
 * Perez et al. 1990 Anisotropic diffuse irradiance model
 *
 * Diffuse irradiance and illuminance on tilted surfaces.
 */
function perez(dni, hdi, etr, S, theta, zenith) {
    // theta is the incidence angle of the sun
    var Z = zenith;
    var Dh = hdi;
    var Xh = Dh;

    var m = airmass(zenith);

    var I = etr;
    var k = 1.041; // for Z in radians

    // (1)
    var e = 0;
    if (Dh > 0) {
        var clearness = ((Dh + dni) / Dh + k * Math.pow(Z, 3)) / (1.0 + k * Math.pow(Z, 3));
        var e = eBin(clearness);
    }

    // (2)
    var delta = 0;
    if (I != 0) {
        delta = Dh * m / I;
    }

    // table 6
    var IRR = [
        [-0.008,  0.588, -0.062, -0.060,  0.072, -0.022],
        [ 0.130,  0.683, -0.151, -0.019,  0.066, -0.029],
        [ 0.330,  0.487, -0.221,  0.055, -0.064, -0.026],
        [ 0.568,  0.187, -0.295,  0.109, -0.152, -0.014],
        [ 0.873, -0.392, -0.362,  0.226, -0.462,  0.001],
        [ 1.132, -1.237, -0.412,  0.288, -0.823,  0.056],
        [ 1.060, -1.600, -0.359,  0.264, -1.127,  0.131],
        [ 0.678, -0.327, -0.250,  0.156, -1.377,  0.251]
    ];

    // 3.2
    var a = Math.max(0, Math.cos(theta));
    var b = Math.max(0.087, Math.cos(Z));

    var F1 = IRR[e][0] + IRR[e][1] * delta + IRR[e][2] * Z;
    var F2 = IRR[e][3] + IRR[e][4] * delta + IRR[e][5] * Z;

    // 3.2.2 Illuminance model
    // (9)
    var Xc = Xh * ((1 - F1) * (1 + Math.cos(S)) / 2 + F1 * a/b + F2 * Math.sin(S));

    return Math.max(0, Xc);
}

/**
 * Pickering 2002 footnote 39
 */
function airmass(zenith) {
    var h = Math.abs(90 - radToDeg(zenith));
    return 1 / Math.sin(degToRad(h + 244 / (165 + 47 * Math.pow(h, 1.1))));
}

/**
 * Perez et al. 1990 Table 1
 */
function eBin(clearness) {
    if (clearness > 6.2)
        return 7;

    if (clearness > 4.5)
        return 6;

    if (clearness > 2.8)
        return 5;

    if (clearness > 1.95)
        return 4;

    if (clearness > 1.5)
        return 3;

    if (clearness > 1.23)
        return 2;

    if (clearness > 1.065)
        return 1;

    return 0;
}

/*
function testSolpy() {
    var panelTilt = 20;
    var panelAzimuth = 153;

    var ghi = 150;
    var dhi = 64;
    var dni = 491;
    var etr = 246;
    var az = 137.45843;
    var alt = 14.0042;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 285;
    var dhi = 83;
    var dni = 659;
    var etr = 430;
    var az = 150.05756;
    var alt = 20.76662;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 385;
    var dhi = 78;
    var dni = 770;
    var etr = 562;
    var az = 164.24903;
    var alt = 25.21941;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 442;
    var dhi = 80;
    var dni = 809;
    var etr = 631;
    var az = 179.50401;
    var alt = 26.84425;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 439;
    var dhi = 81;
    var dni = 796;
    var etr = 634;
    var az = 194.7972;
    var alt = 25.41941;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 387;
    var dhi = 72;
    var dni = 782;
    var etr = 569;
    var az = 209.08357;
    var alt = 21.14036;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 278;
    var dhi = 63;
    var dni = 685;
    var etr = 442;
    var az = 221.79515;
    var alt = 14.51248;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);

    var ghi = 143;
    var dhi = 41;
    var dni = 547;
    var etr = 261;
    var az = 232.91338;
    var alt = 6.16176;

    var irr = solpyIrradiation(ghi, dhi, dni, etr, az, alt, panelTilt, panelAzimuth);
    console.log('az', az, 'alt', alt);
    console.log('irradiation', irr);
}
*/

function haversine(lat1, lng1, lat2, lng2) {
    var lat1Rad = degToRad(lat1);
    var lng1Rad = degToRad(lng1);
    var lat2Rad = degToRad(lat2);
    var lng2Rad = degToRad(lng2);

    var dlatRad = lat2Rad - lat1Rad;
    var dlngRad = lng2Rad - lng1Rad;

    var a = Math.pow(Math.sin(dlatRad/2), 2) + Math.cos(lat1Rad) * Math.pow(Math.sin(dlngRad/2), 2) * Math.cos(lat2Rad);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var earthRkm = 6371.0;
    var kmInMi = 0.621371192;

    return c * earthRkm * kmInMi;
}

function findClosestStation(stationList, lat, lng) {
    var distances = [];

    for (var i = 0; i < stationList.length; i++) {
        var distance = haversine(stationList[i][0], stationList[i][1], lat, lng);
        distances.push([distance, stationList[i][2]]);
    }

    distances.sort(function (a, b) {
        if (a[0] < b[0])
            return -1;

        if (a[0] > b[0])
            return 1;

        return 0;
    });

    return distances[0];
}

function calculateStationDistance(stationList, stationId, lat, lng) {
    for (var i = 0; i < stationList.length; i++) {
        if (stationList[i][2] == stationId) {
            return haversine(stationList[i][0], stationList[i][1], lat, lng);
        }
    }

    return null;
}

function calculateSunPositionSunCalcDate(lat, lng, d) {
    var position = SunCalc.getPosition(d, lat, lng);

    return {
        azimuth: 180 + radToDeg(position.azimuth),
        elevation: radToDeg(position.altitude)
    };
}

function calculateSunPositionSunCalc(lat, lng, year, month, day, hour, minutes, seconds, offset) {
    var d = new Date(Date.UTC(year, month, day, hour - offset, minutes));

    return calculateSunPositionSunCalcDate(lat, lng, d);
}

function isLeapYear(year) {
    return ( (year % 4 == 0 && year % 100 != 0) || year % 400 == 0 );
}



export {
  isLeapYear,
  calculateSunPositionSunCalc,
  calculateSunPositionSunCalcDate,
  calculateStationDistance,
  findClosestStation,
  haversine,
  eBin,
  airmass,
  perez,
  total_irradiance,
  calculateInsolation
};
