import { emitEvent, onWindowResize, moduleSpecsTool, viewshedTool, project } from '../Viewer';
import { ExportsGUI } from './GuiExports';
import { ShareProjectGUI } from './GuiShareProject';
import { OccluderGui } from './GuiOccluder';
import { SidebarArrayGUI } from './GuiSidebarArray';
import { SidebarKeepoutsGui } from './GuiSidebarKeepouts';
import { SidebarViewshedGui } from './GuiSidebarViewsheds';
import { SidebarAutoOutlineGui } from './GuiSidebarAutoOutline';
import { SidebarOutlineGui } from './GuiSidebarOutline';
import { AllSegmentsGui } from './GuiAllSegments';
import { SegmentInfoGui } from './GuiSegmentInfo';
import { TiltAzimuthGui } from './GuiTiltAzimuth';
import { SystemTreeGUI } from './GuiSystemTree';
import { ModuleSpecsTool } from '../tools/ModuleSpecsTool';
import { ProjInfoGUI } from './GUIProjInfo';
import { DroneImagesGui } from './GuiDroneImages';
import { StatusIndicatorGui } from './GuiStatusIndicator';
import { Config } from '../../bootstrap/Config';


import Split from 'split.js';
import tippy from 'tippy.js';



class SidebarGUI {
    constructor(options) {
        this.options = options;
        this.paneSelector = '#sidebar-deck-bottom';

        this.getState = this.options.getState;

        this.deckWidget = this.treeWidget;
        this.statusIndicator = new StatusIndicatorGui();
    }

    static get canvasSize() {
        let w  = $('#split-main').width();
        let h = $('#split-main').height();

        return { w, h };
    }


    get imagesWidget() {
        if (!this._imagesWidget) {
            this._imagesWidget = new DroneImagesGui({
                thumbSelector: '#sidebar-deck',
                getState: () => {
                    var s = this.getState();
                    return { images: s.images };
                }
            });

            this._imagesWidget.init();
        }

        return this._imagesWidget;
    }


    get projInfoWidget() {
        if (!this._projInfoWidget) {
            this._projInfoWidget = new ProjInfoGUI({
                selector:               '#sidebar-deck',
                getState:               this.options.getState,
                project:                project,                                                                    // moved this from Viewer.js
                getProductionValues:    ()              => moduleSpecsTool.getProductionValues(),
                getSystemParams:        (planeId)       => moduleSpecsTool.getSystemParams(planeId),
                getDefaultSystemParams: ()              => ModuleSpecsTool.getDefaultSystemParams(),
                updateSystemParams:     (planeId, k, v) => moduleSpecsTool.updateSystemParams(planeId, k, v),
                calculateLosses:        (planeId)       => moduleSpecsTool.calculateLosses(planeId),
                getWeatherStationInfo:  (callback)      => viewshedTool.loadStationData().then(callback).done(),
                getRoofPlaneInfo:       ()              => {
                    // project info dialog should use state same as the other widget
                    // this is just for compatibility with old code before we can refactor it
                    // TODO: refactor to only use the app state

                    const s = moduleSpecsTool.getState();
                    const planes = viewshedTool.getRoofPlaneInfo();
                    const modules = moduleSpecsTool.getModuleInfos();

                    for (let pid = 0; pid < s.planes.length; pid++) {
                        planes[pid].name    = s.planes[pid].name;
                        planes[pid].tilt    = s.planes[pid].tilt;
                        planes[pid].azimuth = s.planes[pid].azimuth;
                    }

                    return { planes, modules };
                }
            });

            this._projInfoWidget.init();
        }

        return this._projInfoWidget;
    }


    get treeWidget() {
        if (!this._treeWidget) {
            this._treeWidget = new SystemTreeGUI({
                selector:        '#sidebar-deck',
                moduleSpecsTool: this.options.moduleSpecsTool,
                viewshedTool:    this.options.viewshedTool,
                occluderTool:    this.options.occluderTool,
                versions:        this.options.versions,
                selectOutline:   this.options.selectOutline,
                swapPanel:       (panel) => {
                    this.panel = this[panel];
                    this.renderPanel();
                },
                getState: this.getState
            });

            this._treeWidget.init();
        }

        return this._treeWidget;
    }


    get tiltAzimuthWidget() {
        if (!this._tiltAzimuthWidget) {
            this._tiltAzimuthWidget = new TiltAzimuthGui({
                tool:     this.options.moduleSpecsTool,
                selector: '.segment-info',
                getState: this.getState
            });

            this._tiltAzimuthWidget.init();
        }

        return this._tiltAzimuthWidget;
    }


    get segmentInfoWidget() {
        if (!this._segmentInfoWidet) {
            this._segmentInfoWidet = new SegmentInfoGui({
                tool:     this.options.moduleSpecsTool,
                selector: this.paneSelector,
                getState: this.getState
            });

            this._segmentInfoWidet.init();
        }

        return this._segmentInfoWidet;
    }


    get allSegmentsWidget() {
        if (!this._allSegmentsWidget) {
                this._allSegmentsWidget = new AllSegmentsGui({
                    selector: this.paneSelector,
                    segmentInfoWidget: this.segmentInfoWidget,
                    getState: this.getState
                });

            this._allSegmentsWidget.init();
        }

        return this._allSegmentsWidget;
    }


    get outlinesWidget() {
        if (!this._outlinesWidget) {
            this._outlinesWidget = new SidebarOutlineGui({
                selector:          this.paneSelector,
                tool:              this.options.moduleSpecsTool,
                tiltAzimuthWidget: this.tiltAzimuthWidget,
                getState:          () => {
                    var s = this.getState();
                    let pid = s.array.pid;

                    // TODO: don't create proxies for state, it's really confusing
                    return {
                        pid:       pid,
                        tilt:      s.array.planes[pid].tilt,
                        azimuth:   s.array.planes[pid].azimuth,
                        auto:      s.array.planes[pid].auto,
                        qty:       s.array.qty,
                        area:      s.array.area,
                        arrayInfo: s.array.arrayInfo[pid]
                    };
                }
            });

            this._outlinesWidget.init();
        }

        return this._outlinesWidget;
    }


    get autoOutlinesWidget() {
        if (!this._autoOutlinesWidget) {
            this._autoOutlinesWidget = new SidebarAutoOutlineGui({
                selector: this.paneSelector,
                tool:     this.options.moduleSpecsTool,
                getState: this.getState
            });

            this._autoOutlinesWidget.init();
        }

        return this._autoOutlinesWidget;
    }


    get viewshedsWidget() {
        if (!this._viewshedsWidget) {
            this._viewshedsWidget = new SidebarViewshedGui({
                selector:          this.paneSelector,
                tool:              this.options.viewshedTool,
                tiltAzimuthWidget: this.tiltAzimuthWidget,
                getState:          this.getState,
                statusIndicator:   this.statusIndicator,
            });

            this._viewshedsWidget.init();
        }

        return this._viewshedsWidget;
    }


    get keepoutsWidget() {
        if (!this._keepoutsWidget) {
            this._keepoutsWidget = new SidebarKeepoutsGui({
                selector:          this.paneSelector,
                tool:              this.options.moduleSpecsTool,
                tiltAzimuthWidget: this.tiltAzimuthWidget,
                getState:          this.getState
            });

            this._keepoutsWidget.init();
        }

        return this._keepoutsWidget;
    }


    get modulesWidget() {
        if (!this._modulesWidget) {
            this._modulesWidget = new SidebarArrayGUI({
                selector:          this.paneSelector,
                tool:              this.options.moduleSpecsTool,
                tiltAzimuthWidget: this.tiltAzimuthWidget,
                getState:          () => {
                    var s = this.getState();
                    let pid = s.array.pid;

                    let names = [];

                    for (let i = 0; i < s.array.planes.length; i++) {
                        if (i !== pid)
                            names.push(s.array.planes[i].name);
                    }

                    let mode = (s.array.moduleSpecs[pid][0].dynamicMode) ? 'dynamic' : 'manual';

                    return {
                        pid:           pid,
                        populated:     s.array.populated,
                        moduleSpecs:   s.array.moduleSpecs[pid][0],
                        qty:           s.array.qty,
                        selectedQty:   s.array.selectedQty,
                        mode:          mode,
                        planeNames:    names,
                        moduleLibrary: s.moduleLibrary
                    }
                }
            });

            this._modulesWidget.init();
        }

        return this._modulesWidget;
    }


    get occludersWidget() {
        if (!this._occludersWidget) {
            this._occludersWidget = new OccluderGui({
                selector: this.paneSelector,
                tool:     this.options.occluderTool,
                getState: this.getState
            });

            this._occludersWidget.init();
        }

        return this._occludersWidget;
    }


    get shareWidget() {
        if (!this._shareWidget) {
            this._shareWidget = new ShareProjectGUI({
                tool:     this.options.shareTool,
                getState: this.getState
            });

            this._shareWidget.init();
        }

        return this._shareWidget;
    }


    get exportsWidget() {
        if (!this._exportsWidget) {
            this._exportsWidget = new ExportsGUI({
                selector: '#sidebar-deck',
                statusIndicator: this.statusIndicator,
                selectSegmentOutlines: (pid) => { this.selectSegmentOutlines(pid); }
            });
            this._exportsWidget.init();
        }

        return this._exportsWidget;
    }

    hoverSegmentOutlines(pid) {
        this.treeWidget.manualHover(pid, 'segment');
      }
    removeHoverSegmentOutlines() {
        this.treeWidget.removeManualHover();
    }



    init() {
        if (this.initialized)
            return;

        const self = this;
        let panelWidth = 30.25;
        let modelWidth = 69.75;
        const toolbar = $('.gui.rhs');
        const viewport = $('#split-main .viewport');
        const navButtons = $('.navbar button');
        const title = $('.sidebar-title h1');

        $('#split-sidebar').removeClass('hidden');
        $('.main-header').removeClass('hidden');

        this.mainSplit = Split([ '#split-sidebar', '#split-main' ], {
            elementStyle: function(_dimension, size, gutterSize) {
                return {
                    'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)',
                }
            },
            gutterStyle: function(_dimension, gutterSize) {
                return {
                    'flex-basis': gutterSize + 'px',
                }
            },
            onDrag:     function() {
                onWindowResize();
                viewport.css('width', '100%');
            },
            gutterSize:  3,
            minSize:     400,
            expandToMin: true,
            sizes:       [ panelWidth, modelWidth ]
        });

        $('body').on('click', '#btn-collapse-sidebar', function () {
            self.mainSplit.collapse(0);
            $('#header-open-sidebar').removeClass('hidden');
            $('#split-workspace-wrapper > .gutter-horizontal').addClass('hidden');
            $('#split-workspace-wrapper').attr('aria-expanded', 'false');
            viewport.css('width', '100%');
            if(modelWidth < 50 && $('.nav-project-info').hasClass('active')) {
                switchSizes();
            }

            onWindowResize();
        });

        $('body').on('click', '#nav-logo', function () {
            parent.window.location.replace(Config.portalURL + '/projects-list');
        });

        $('body').on('click', '#nav-model', function () {           // I think I was able to optimize these, but room for optimization in project info. Let me know if we see room for futher optimization elsewhere! Been a minute since I've optimized something :)
            checkSidebar();
            if (self.deckWidget !== self.treeWidget) {
                self.deckWidget = self.treeWidget;
                $(this).addClass('active');

                if (self.deckWidget.panel)
                    this.panel = self.deckWidget.panel;

                self.renderState();
                $(this).attr('aria-pressed', 'true');
            }
            toolbar.css('display', 'block');
            title.html('Model');
        });

        $('body').on('click', '#nav-share', function () {
            checkSidebar();
            if (self.deckWidget !== self.shareWidget) {
                self.deckWidget = self.shareWidget;
                $(this).addClass('active');
                self.renderState();
                $(this).attr('aria-pressed', 'true');
            }
            title.html('Share');
        });

        $('body').on('click', '#nav-exports', function () {
            checkSidebar();
            title.html('Exports');
            if (self.deckWidget !== self.exportsWidget) {
                $(this).addClass('active');
                self.deckWidget = self.exportsWidget;
                self.renderState();
                $(this).attr('aria-pressed', 'true');
            }
        });

        $('body').on('click', '#nav-drone-images', function () {
            $('#sidebar-deck').empty();
            checkSidebar();
            $(this).addClass('active');
            title.html('Drone Images');
            self.deckWidget = self.imagesWidget;
            self.renderState();
            $(this).attr('aria-pressed', 'true');
        });

        $('body').on('click', '#nav-project-info', function () {
            if (!$('#nav-project-info').hasClass('active')){
                $('#sidebar-deck').empty();
                switchSizes();
                self.deckWidget = self.projInfoWidget;
                self.renderState();
            }
            if (!$('#header-open-sidebar').hasClass('hidden')){
                $('#header-open-sidebar').addClass('hidden');
                $('#split-workspace-wrapper > .gutter-horizontal').removeClass('hidden');
            }
            toolbar.css('display', 'none');
            self.mainSplit.setSizes([ panelWidth, modelWidth ]);
            viewport.css('width', '100%');
            navButtons.removeClass('active');
            $(this).addClass('active');
            title.html('Project Info');
            $('#split-workspace-wrapper').attr('aria-expanded', 'true');
            navButtons.attr('aria-pressed', 'false');
            $(this).attr('aria-pressed', 'true');
            onWindowResize();
        });

        $('body').on('click', '#header-open-sidebar', function () {
            if ($('#nav-project-info').hasClass('active')) {
                if (modelWidth < 50){
                    self.mainSplit.setSizes([ panelWidth, modelWidth ]);
                    viewport.css('width', '250%');
                    $(this).addClass('hidden');
                    $('#split-workspace-wrapper > .gutter-horizontal').removeClass('hidden');
                    $('#split-workspace-wrapper').attr('aria-expanded', 'true');
                    return;
                }
                else {
                    switchSizes();
                }
            }
            self.mainSplit.setSizes([ panelWidth, modelWidth ]);
            viewport.css('width', '100%');

            $(this).addClass('hidden');
            $('#split-workspace-wrapper > .gutter-horizontal').removeClass('hidden');
            $('#split-workspace-wrapper').attr('aria-expanded', 'true');

            onWindowResize();
        });

        $('body').on('click', '#nav-profile', function () {
            parent.window.location.replace(Config.portalURL + '/projects/' + project.id + '/designs');
        });

        $('body').on('click', '#nav-help', function () {
            self.options.help();
        });

        const s = this.getState();

        if (s.array.planes.length > 0)
            this.panel = this.outlinesWidget;

        this.statusIndicator.init();

        tippy('.toolbar-vertical [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'right'
        });

        function checkSidebar() {
            if (!$('#header-open-sidebar').hasClass('hidden')){
                $('#header-open-sidebar').addClass('hidden');
                $('#split-workspace-wrapper > .gutter-horizontal').removeClass('hidden');
            }
            if (modelWidth < 50) {
                switchSizes();
            }
            self.mainSplit.setSizes([ panelWidth , modelWidth ]);
            onWindowResize();
            viewport.css('width', '100%');
            toolbar.css('display', 'none');
            navButtons.removeClass('active');
            $('#split-workspace-wrapper').attr('aria-expanded', 'true');
            navButtons.attr('aria-pressed', 'false');
       }

       function switchSizes() {
            var w = modelWidth;
            modelWidth = panelWidth;
            panelWidth = w;
            viewport.css('position', 'relative');
       }

        this.initialized = true;

        $(window).resize(function() {
            if($(this).width() < 950) {
                self.mainSplit.collapse(0);
                $('#header-open-sidebar').removeClass('hidden');
                $('#split-workspace-wrapper > .gutter-horizontal').addClass('hidden');
                onWindowResize();
            }
            else {
                self.mainSplit.setSizes([ panelWidth, modelWidth ]);
                $('#header-open-sidebar').addClass('hidden');
                $('#split-workspace-wrapper > .gutter-horizontal').removeClass('hidden');
                onWindowResize();
            }
        }).resize();
    }


    selectSegmentOutlines(pid) {
        this.treeWidget.select(pid, 'segment');
    }

    selectAutoOutlines() {
        this.treeWidget.select(-1, 'autoOutlines');
        this.panel = this.autoOutlinesWidget;
    }

    renderPanel() {
        let s = this.getState();

        if (this.panel) {
            this.panel.renderState();
        } else {
            $(this.paneSelector).html(`
                <div class="pane-container empty">
                    <h4 class="get-started">Add a new segment and then create an outline to get started!</h4>
                    <img src="/styles/images/scanifly-illustration-primary.svg" class="get-started-illustration">
                </div>
            `);
            emitEvent('status', { message: 'Add a new segment and then create an outline to get started'});
        }
    }


    renderState() {
        this.deckWidget.renderState();

        if (this.deckWidget !== this.imagesWidget) {
            $(`#${this.imagesWidget.selector}`).remove();
        }

        this.renderPanel();
        this.statusIndicator.renderState();
    }
}


// it's handy to have the positions of certain toggles stored at the top UI level
// these toggles are specific to the current UI and are therefore not part of the app state
// TODO: make a static property on the class when the build system supports such things
SidebarGUI.keepoutModeToggle = 'manual';
SidebarGUI.viewshedModeToggle = 'manual';

export { SidebarGUI };
