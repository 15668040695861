import { deallocateObject } from '../libs/Utilities';
import { controls } from '../Viewer.js';
import { CameraRotator } from '../libs/CameraRotator';
import * as THREE from '../libs/three.module';




/**
 * @deprecated
 * @todo: decouple from model imports and delete
 */
class SlowAnnotationsTool extends CameraRotator {

    constructor() {
        super();

        this.mouseButton = THREE.MOUSE.LEFT;

        this.annotations = [];
        this.popup = -1;

        this.currentRoofPlane = null;
        this.leftAmount = 4;
        this.upAmount = 4;
        this.hide = false;

        this.objects = [];
    }


    setCamera                (camera)    { this.camera = camera; }
    setRaycaster             (rc)        { this.raycaster = rc; }
    addObject                (object)    { this.objects.push(object); }
    setContainerObject       (container) { this.container = container; }
    enable                   ()          { this.enabled = true; }
    disable                  ()          { this.enabled = false; }
    setUpdateGui             (callback)  { this.updateGui = callback }
    setUpdateMarkerPositions (callback)  { this.updateMarkerPositions = callback; }


    addMarkerSceneObject(position) {
        var geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        var cube = new THREE.Mesh(geometry, material);
        cube.position.copy(position);

        // raycaster ignores invisible objects, but detects objects with invisible material
        cube.material.visible = false;
        this.container.add(cube);

        return cube;
    }


    mouseDown(event, mouse, panning) {
        if (!this.enabled || event.button !== THREE.MOUSE.LEFT || panning)
            return;

        this.raycaster.setFromCamera(mouse, this.camera);

        var meshes = this.objects

        if(this.moduleSpecsMeshes) {
            // Only include module meshes for viewshed annotations
            var moduleMeshes = this.moduleSpecsMeshes();
            meshes = this.objects.concat(moduleMeshes)
        }

        var intersects = this.raycaster.intersectObjects(meshes, true);

        if (intersects.length === 0)
            return;

        var position = this.container.worldToLocal(intersects[0].point.clone());
        this.annotations.push({
            position:     position,
            cube:         this.addMarkerSceneObject(position),
            camera:       { left: controls.getAzimuthalAngle(), up: controls.getPolarAngle() },
            subject:      '',
            description:  '',
            name:         '',
            height:       '',
            width:        '',
            pid:          this.currentRoofPlane,
            dirty:        true
        });

        this.updateGui(this, this.annotations);
    }


    mouseMove(mouse, panning) {
        if (!this.enabled)
            return;

        if (panning && this.updateMarkerPositions)
            this.updateMarkerPositions(this, this.annotations);
    }


    mouseWheel() {
        if (!this.enabled)
            return;

        if (this.updateMarkerPositions)
            this.updateMarkerPositions(this, this.annotations);
    }

    handleKeyboard(keyboard) { }


    moveCameraToAnnotation(id) {
        this.moveCameraAngle(this.annotations[id].camera.left, this.annotations[id].camera.up);
    }


    getScreenCoordinates(annotation) {
        var pos = this.container.localToWorld(annotation.position.clone());
        pos.project(this.camera);
        this.raycaster.setFromCamera(pos, this.camera);

        // translate world coordinates to screen coordinates
        var container3d = document.getElementById('container3d');
        var rect = container3d.getBoundingClientRect();

        var widthHalf  = container3d.clientWidth  / 2;
        var heightHalf = container3d.clientHeight / 2;

        pos.x =  (pos.x * widthHalf)  + widthHalf  + rect.x;
        pos.y = -(pos.y * heightHalf) + heightHalf + rect.y;

        var occluded = false;

        var targets = [ annotation.cube ];
        for (var i = 0; i < this.objects.length; i++) {
            targets.push(this.objects[i]);
        }

        var intersects = this.raycaster.intersectObjects(targets, true);
        if (intersects.length > 0 && intersects[0].object !== annotation.cube) {
            occluded = true;
        }

        return { pos: pos, occluded: occluded };
    }


    getAnnotations() {
        return this.annotations
    }


    setCurrentPlane(pid) {
        this.currentRoofPlane = pid
    }


    removeAnnotations() {
        this.annotations = []
        this.updateGui(this, this.annotations)
    }


    removeViewshedPlane(planeId) {
        this.annotations = this.annotations.filter( a => {
            return a.pid !== planeId
        });

        this.annotations.forEach( a => {
            if(a.pid > planeId) {
                a.pid = a.pid - 1
            }
        });
    }


    saveAnnotation(id, data) {
        this.annotations[id].subject     = data.subject;
        this.annotations[id].description = data.description;
        this.annotations[id].dirty       = false;

        this.popup = id;

        this.updateGui(this, this.annotations);
    }


    saveVegAnnotation(id, data) {
        this.annotations[id].name     = data.name || this.annotations[id].name
        this.annotations[id].width     = data.width || 0
        this.annotations[id].height    = data.height || 0
        this.annotations[id].dirty       = false;

        this.updateGui(this, this.annotations);
    }


    editAnnotation(id) {
        this.annotations[id].dirty = true;

        this.updateGui(this, this.annotations);
    }


    removeAnnotation(id) {
        if (id == this.popup) {
            this.popup = -1;
        }

        deallocateObject(this.annotations[id].cube);
        this.container.remove(this.annotations[id].cube);

        this.annotations.splice(id, 1);
        this.updateGui(this, this.annotations);
    }


    removeVegAnnotation(id) {
        if (id == this.popup) {
            this.popup = -1;
        }

        if(this.annotations.length === 0) { return }

        deallocateObject(this.annotations[id].cube);
        this.container.remove(this.annotations[id].cube);

        this.annotations.splice(id, 1);

        this.updateGui(this, this.annotations);
    }


    getSaveData() {
        var annotations = [];

        for (var i = 0; i < this.annotations.length; i++) {
            annotations.push({
                position: {
                    x: this.annotations[i].position.x,
                    y: this.annotations[i].position.y,
                    z: this.annotations[i].position.z
                },
                camera:      this.annotations[i].camera,
                subject:     this.annotations[i].subject,
                description: this.annotations[i].description,
                name:        this.annotations[i].name || null,
                width:       this.annotations[i].width || null,
                height:      this.annotations[i].height || null,
                pid:         this.annotations[i].pid
            });
        }

        return annotations;
    }


    restoreSaveData(data) {
        this.popup = -1;

        for (var i = 0; i < this.annotations.length; i++) {
            this.container.remove(this.annotations[i].cube);
            deallocateObject(this.annotations[i].cube);
        }

        this.annotations = [];

        for (var i = 0; i < data.length; i++) {
            var position = new THREE.Vector3(data[i].position.x, data[i].position.y, data[i].position.z);
            var cube = this.addMarkerSceneObject(position);

            this.annotations.push({
                position:     position,
                cube:         cube,
                camera:       data[i].camera,
                subject:      data[i].subject,
                description:  data[i].description,
                name:         data[i].name,
                width:        data[i].width,
                height:       data[i].height,
                pid:          data[i].pid,
                dirty:        false
            });
        }

        this.updateGui(this, this.annotations, true);
    }
}


export { SlowAnnotationsTool };
