import { DeTiltToolState } from '../tools/DeTiltTool';

class DeTiltToolGUICallbacks {
    constructor() {
        /**
         * @callback EnableCallback
         */

        /**
         * @type {EnableCallback}
         */
        this.enableCallback;

        /**
         * @callback ExecuteCallback
         */

        /**
         * @type {ExecuteDeTiltCallback}
         */
        this.executeDeTiltCallback;

        /**
         * @callback ResetCallback
         */

        /**
         * @type {ExecuteResetCallback}
         */
         this.executeResetCallback;
    }
}

class DeTiltToolGUI {

    /**
     * @param {DeTiltToolGUICallbacks} callbacks
     */
    constructor(callbacks) {
        const html_template = `
            <h3 class="header de-tilt-tool">De-Tilt</h3>
            <div class="tool-options">
                <div class="de-tilt">
                    <div class="de-tilt-empty">
                        Click the starting point of a line that will be made horizontal.<br/>
                        NOTE: The model will pivot at this point.
                    </div>
                    <div class="de-tilt-one-point" style="display: none;">
                        Click the ending point.
                    </div>
                    <div class="de-tilt-two-points" style="display: none;">
                        <button class="sy de-tilt" aria-label="de-tilt">
                            <i class="fa fa-wrench" aria-hidden="true"></i>
                        De-Tilt
                        </button>
                    </div>
                    <div class="de-tilt-reset" style="display: none;">
                        <button class="sy de-tilt-reset" aria-label="de-tilt-reset">
                            <i class="fa fa-wrench" aria-hidden="true"></i>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        `;

        $('.placeholder.de-tilt').replaceWith(html_template);
        $('body').on('click', '.header.de-tilt-tool', callbacks.enableCallback);
        $('body').on('click', 'button.de-tilt', callbacks.executeDeTiltCallback);
        $('body').on('click', 'button.de-tilt-reset', callbacks.executeResetCallback);
    }

    /**
     *
     * @param {DeTiltToolState} state
     */
    renderState(state) {
        $('.de-tilt-empty').hide();
        $('.de-tilt-one-point').hide();
        $('.de-tilt-two-points').hide();
        if(state.matrices.length) {
            $('.de-tilt-reset').show();
        } else {
            $('.de-tilt-reset').hide();
        }
        switch(state.points.length) {
            case 0:
                $('.de-tilt-empty').show();
                break;
            case 1:
                $('.de-tilt-one-point').show();
                break;
            case 2:
                $('.de-tilt-two-points').show();
                break;
        }

    }
}

export { DeTiltToolGUI, DeTiltToolGUICallbacks };
