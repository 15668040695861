import { dir } from '../Viewer.js';
import { BufferGeometry, CylinderBufferGeometry, Float32BufferAttribute, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, Vector3} from '../libs/three.module';





class Arrow extends Object3D {
    constructor(options) {
        super();

        var lineGeometry, coneGeometry;

        var { dir, origin, length, color, headLength, headWidth, val } = options;

        lineGeometry = new BufferGeometry();
        lineGeometry.addAttribute( 'position', new Float32BufferAttribute( [ 0, 0, 0, 0, 1, 0 ], 3 ) );

        coneGeometry = new CylinderBufferGeometry(0, 0.5, 1, 16, 1);
        coneGeometry.translate( 0, - 0.5, 0 );

        this.position.copy( origin );

        this.axis = new Vector3();
        this.radians;

        this.mLine = new LineBasicMaterial({
            color:       color,
            linewidth:   10,
            transparent: true,
            depthTest:   false
        });

        this.mCone = new MeshBasicMaterial({
            color:       color,
            transparent: true,
            depthTest:   false
        });

        this.mLineSelected = new LineBasicMaterial({
            color:       0xffff00,
            linewidth:   10,
            transparent: true,
            depthTest:   false
        });

        this.mConeSelected = new MeshBasicMaterial({
            color:       0xffff00,
            transparent: true,
            depthTest:   false
        });

        this.line = new Line(lineGeometry, this.mLine);
        this.cone = new Mesh(coneGeometry, this.mCone);

        this.line.matrixAutoUpdate = false;
        this.cone.matrixAutoUpdate = false;

        this.add(this.line);
        this.add(this.cone);

        this.setDirection( dir );
        this.setLength( length, headLength, headWidth );

        this.dir = dir;
        this.val = val;
    }


    setDirection(dir) {
        // dir is assumed to be normalized
        if ( dir.y > 0.99999 ) {
            this.quaternion.set( 0, 0, 0, 1 );
        } else if ( dir.y < - 0.99999 ) {
            this.quaternion.set( 1, 0, 0, 0 );
        } else {
            this.axis.set( dir.z, 0, - dir.x ).normalize();
            this.radians = Math.acos( dir.y );

            this.quaternion.setFromAxisAngle(this.axis, this.radians);
        }
    }


    setLength(length, headLength, headWidth) {
        this.line.scale.set(1, Math.max(0, length - headLength), 1);
        this.line.updateMatrix();

        this.cone.scale.set(headWidth, headLength, headWidth);
        this.cone.position.y = length;
        this.cone.updateMatrix();
    }


    select(val) {
        this.selected = val;

        if (val) {
            this.line.material = this.mLineSelected;
            this.cone.material = this.mConeSelected;
        } else {
            this.line.material = this.mLine;
            this.cone.material = this.mCone;
        }
    }
}



export { Arrow };
