import { moduleSpecsTool, viewshedTool, mouseHandler } from '../Viewer';
import { Compass } from '../elements/Compass';
import * as THREE from '../libs/three.module';



class CompassTool {

    constructor(options) {
        const w = 55;
        const h = 55;

        const compass = new Compass({
            w:         2,
            l:         8,
            homeImg:   "../styles/images/icons/aim-white.png",
            homeColor: 0xffffff,
            scale:     10
        });

        const camera = new THREE.OrthographicCamera(w / -2, w / 2, h / 2, h / -2, 1, 1000);
        camera.position.set(0, 0, 10);
        camera.lookAt(new THREE.Vector3(0, 0, 0));

        const scene = new THREE.Scene();
        scene.add(compass);
        scene.add(camera);

        const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        renderer.setClearColor(0x000000, 0);
        renderer.setSize(100, 100);
        renderer.autoClear = false;
        renderer.setPixelRatio(window.devicePixelRatio);

        this.scene     = scene;
        this.camera    = camera;
        this.compass   = compass;
        this.renderer  = renderer;
        this.raycaster = new THREE.Raycaster();

        this.camStart = options.camera.position.clone();

        this.options = options;
    }


    get domElement() { return this.renderer.domElement; }

    registerPropagateState(callback) { this.propagateState = callback; }


    onEachFrame() {
        const dir = new THREE.Vector3(0, 0, 0);
        const s = new THREE.Spherical();

        this.options.camera.getWorldDirection(dir);
        s.setFromVector3(dir);

        this.compass.azimuth = -s.theta;

        this.renderer.clearDepth();
        this.renderer.render(this.scene, this.camera);
    }


    mouseMove(mouse, panning, event) {
        let m = new THREE.Vector2();

        const offset = $('.nav-container').offset();

        m.x =   ( ( event.clientX - offset.left ) / this.renderer.domElement.clientWidth  ) * 2 - 1;
        m.y = - ( ( event.clientY - offset.top  ) / this.renderer.domElement.clientHeight ) * 2 + 1;

        this.raycaster.setFromCamera(m, this.camera);
        let xs = this.raycaster.intersectObjects([ this.compass.circle, this.compass.home ]);

        this.compass.highlight = (xs.length > 0 && xs[0].object === this.compass.circle);

        mouseHandler.setCursor('pointer', 'compass', xs.length > 0);
    }


    mouseDown(event) {
        let m = new THREE.Vector2();

        const offset = $('.nav-container').offset();

        m.x =   ( ( event.clientX - offset.left ) / this.renderer.domElement.clientWidth  ) * 2 - 1;
        m.y = - ( ( event.clientY - offset.top  ) / this.renderer.domElement.clientHeight ) * 2 + 1;

        this.raycaster.setFromCamera(m, this.camera);
        let xs = this.raycaster.intersectObject(this.compass.circle);

        let stopPropagation = false;

        if (xs.length > 0) {
            this.switchToOverhead();
            stopPropagation = true;
        } else {
            xs = this.raycaster.intersectObject(this.compass.home);

            if (xs.length > 0) {
                this.resetCamera();
                stopPropagation = true;
            }
        }

        // stop both mouseDown and mouseUp from bubbling up to other tools
        this.stopPropagation = stopPropagation;

        return stopPropagation;
    }


    mouseUp() {
        let retval = false;

        if (this.stopPropagation) {
            this.stopPropagation = false;
            retval = true;
        }

        return retval;
    }


    switchToOverhead() {
        // orbit controls update camera position and lookAt() on update
        // orbit controls store/reset camera position and target

        // don't carry over panning offset from perspective mode
        if (this.options.camera.projectionMode === 'perspective')
            this.options.controls.reset();

        let target = this.options.controls.target.clone();
        let pos = target.clone().setY(100);

        // TODO: fit the whole model in the view
        // frustum defaults to 20 at first
        this.options.camera.frustum = 100;
        this.options.camera.position.copy(pos);
        this.options.camera.lookAt(target);
        this.options.camera.toOrthographic();

        this.options.controls.rotateLeft(Math.PI);
        this.options.controls.update();

        // scale tool sprites
        viewshedTool.mouseWheel();
        moduleSpecsTool.mouseWheel();

        this.propagateState();
    }


    resetCamera() {
        this.options.controls.reset();
        this.options.camera.setZoom(1);
        this.options.camera.toPerspective();

        // scale tool sprites
        viewshedTool.mouseWheel();
        moduleSpecsTool.mouseWheel();

        this.propagateState();
    }
}


export { CompassTool };
