import tippy from 'tippy.js';

class OccluderGui {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;

        this.occluderType = 'cube';
    }


    init() {
        const self = this;

        $('body').on('click', '#occluder-container .sq-btn-container', function () {
            self.occluderType = $(this).hasClass('cube') ? 'cube' : 'cylinder';

            const s = self.getState();

            if (s.occluders.selected !== -1) {
                self.options.tool.cmd('changeShape', self.occluderType);
            }

            self.renderState();
        });

        $('body').on('click', '#occluders-add', function () {
            self.options.tool.cmd('add', self.occluderType);
            self.renderState();
        });

        $('body').on('click', '#occluder-container .sw-option', function () {
            let mode = 'translate';

            if ($(this).hasClass('rotate')) {
                mode = 'rotate';
            } else if ($(this).hasClass('size')) {
                mode = 'scale';
            }

            self.options.tool.cmd('setMode', mode);
            self.renderState();
        });
    }


    renderState() {
        const s = this.getState();

        if ($(`#occluder-container`).length === 0) {
            var t = `
                <div id="occluder-container" class="pane-container">
                    <div class="occluder-heading">
                        <h3>Occluder</h3>
                        <div class="info">
                            <div class="qmark-info">
                                <div class="tooltip-content">
                                    Add an Occluder volume to remove objects in the 3D model.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="occluder-section">
                        <h4 class="label">Type:</h4>

                        <a class="sq-btn-container cube">
                            <button class="sq-icon-btn">
                                <i class="fa fa-cube" aria-hidden="true"" alt="cube icon"></i>
                            </button>
                        Cube</a>

                        <a class="sq-btn-container cylinder">
                            <button class="sq-icon-btn">
                                <img src="/styles/images/icons/cylinder-icon.svg" alt="cylinder icon">
                            </button>
                        Cylinder</a>

                        <div class="clearfix"></div>
                    </div>

                    <div class="occluder-section add">
                        <button id="occluders-add" class="sy">Add Occluder</button>
                    </div>

                    <div class="occluders-warn"></div>

                    <div class="occluder-section occluder-opacity">
                        <h4 class="label">Opacity:</h4>

                        <div class="input-wrapper first">
                            <div id="occluder-opacity-slider"></div>
                        </div>

                        <input id="occluder-opacity" class="number" placeholder="1.0"></input>
                    </div>

                    <div class="occluder-section">

                    <div class="sw-multiway">
                        <div class="sw-option move">
                            <i class="fa fa-arrows" aria-hidden="true"></i>
                            Move
                        </div>

                        <div class="sw-option rotate">
                            <i class="fa fa-rotate-right" aria-hidden="true"></i>
                            Rotate
                        </div>

                        <div class="sw-option size">
                            <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                            Scale
                        </div>
                    </div>
                </div>
            `;

            $(this.options.selector).html(t);

            const self = this;

            $('#occluder-opacity-slider').slider({
                min:         0,
                max:         1,
                val:         0,
                step:        0.1,
                orientation: 'horizontal',
                range:       'min',
                animate:     true,
                slide:       function( event, ui ) {
                    $('#occluder-opacity').val(ui.value);

                    self.options.tool.cmd('setOpacity', ui.value);
                }
            });

            tippy('.pane-container .qmark-info', {
                animation:   'scale',
                inertia:     true,
                content:     (ref) => ref.querySelector('.tooltip-content').innerHTML,
                allowHTML:   true,
                interactive: true
            });
        }

        let occluderType = (s.occluders.selected === -1) ? this.occluderType : s.occluders.type;

        let cubeCount     = s.occluders.occluders.filter(o => o.type === 'cube').length;
        let cylinderCount = s.occluders.occluders.filter(o => o.type === 'cylinder').length;

        let limitReached = (occluderType === 'cube' && cubeCount >= s.occluders.maxCount) ||
                           (occluderType === 'cylinder' && cylinderCount >= s.occluders.maxCount);

        $('#occluders-add').prop('disabled', s.occluders.adding || limitReached);

        $('#occluder-container .occluders-warn').toggleClass('hidden', !limitReached || s.occluders.selected !== -1);

        if (occluderType === 'cube' && cubeCount >= s.occluders.maxCount)
            $('#occluder-container .occluders-warn').html("You've placed the maximum number of Cube occluders");

        if (occluderType === 'cylinder' && cylinderCount >= s.occluders.maxCount)
            $('#occluder-container .occluders-warn').html("You've placed the maximum number of Cylinder occluders");

        $('#occluder-container .cube'    ).toggleClass('active', occluderType === 'cube');
        $('#occluder-container .cylinder').toggleClass('active', occluderType === 'cylinder');

        $('#occluder-opacity').val(s.occluders.opacity);
        $('#occluder-opacity-slider').slider('value', s.occluders.opacity);

        $('#occluder-container .move')  .toggleClass('active', s.occluders.mode === 'translate');
        $('#occluder-container .rotate').toggleClass('active', s.occluders.mode === 'rotate');
        $('#occluder-container .size' ) .toggleClass('active', s.occluders.mode === 'scale');

        $('#occluder-container .occluder-section.add').toggleClass('hidden', s.occluders.selected !== -1);
        $('#occluder-container .occluder-opacity'    ).toggleClass('hidden', s.occluders.selected === -1);
        $('#occluder-container .sw-multiway'         ).toggleClass('hidden', s.occluders.selected === -1);
    }
}


export { OccluderGui };
