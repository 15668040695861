

import { ErrorPointsNotOnPlane } from '../errors/Errors';
import { SolarPanelModuleArray } from '../elements/SolarPanelModuleArray';
import { SOLAR_PANEL_MODULE_BORDER_W, SolarPanelModule } from '../elements/SolarPanelModule';
import { deepCopy, formatK, avg, sum, getPlaneName, clampBetween } from '../libs/Utilities';
import { KeyStore } from '../alibs/KeyStore';
import { Net } from '../libs/Net';
import { GRAPH } from '../libs/Graph';
import { mouseHandler, viewshedTool, vMouse, emitEvent, camera, scene, measurementUnits, toolSelector, sceneOrtho } from '../Viewer.js';
import { CircleContext } from '../draw/CircleContext';
import { RectContext } from '../draw/RectContext';
import { degToRad } from '../libs/Geometry';
import { PolyContext } from '../draw/PolyContext';
import { LineContext } from '../draw/LineContext';
import { intersectPlanes, headingToDirectionVector } from '../libs/Geometry';
import { azimuthFromVector, tiltFromVectorAndNormal } from '../libs/Orienteering';
import { Format } from '../gui/Format';
import { MeasurementUnits } from './MeasurementsTool';
import { Tooltip } from '../libs/Tooltip';
import { inchesToMeters, mmToMeters } from '../alibs/Units';
import { ModuleSpecsToolManualMode } from './ModuleSpecsToolManualMode';
import { ModuleSpecsToolDynamicMode } from './ModuleSpecsToolDynamicMode';
import { Color, Geometry, Object3D, Line, Line3, LineDashedMaterial, MOUSE, Plane, Vector3 } from '../libs/three.module';
import * as Q from 'q';
import * as d3 from 'd3';
import Colors from '../core/Colors';
import { formatSpecForModuleSpecsTool } from './ModuleLibrary';

const SCANIFLY_RED = Object.freeze(new Color(Colors.Scanifly.red()));

/** @typedef {import("./DxfExportTool").OutlineContext} OutlineContext */

/**
 * Top level module for working with solar arrays. Responsible for managing:
 *
 *  + multiple roof planes
 *  + drawing contexts for defining a roof plane by drawing a polygon (outline)
 *  + drawing contexts for defining obstructions on roof planes (keepouts and
 *    setbacks)
 *  + the workflow used to build and modify solar arrays
 *  + saving and restoring solar arrays to/from saved data
 *  + mouse interactions and current tool mode
 *  + calculating coordinates to show dimension inputs over the 3d scene
 *  + calculating output, module count and production values
 */

class ModuleSpecsTool {

    /**
     * @param {PVWattsClient} pvWattsClient
     * @param {AutoKeepoutFinder} keepoutFinder
     * @param {import('./ModuleLibrary').ModuleLibrary} moduleLibrary
     */
    constructor (pvWattsClient, keepoutFinder, moduleLibrary) {
        this.pvWattsClient = pvWattsClient;

        this.keepoutFinder = keepoutFinder;
        this.moduleLibrary = moduleLibrary;

        // structures (buildings, ground, etc) on which modules can be placed
        this.meshes = [];

        this.planes = [];

        // 2d array [roof-plane-id][module-id] of legacy single modules for each
        // roof plane
        this.modules = [];

        // 2d array [roof-plane-id][context-id] of polygon contexts for outlines of
        // solar arrays
        /** @type {[OutlineContext][]} */
        this.outlineCtxs = [];

        /** @type {PolyContext<'poly'>[]} */
        this.proposedKeepouts = [];

        // 2d array [roof-plane-id][keepout-id] of polygon contexts for keepouts
        // (areas where modules can't be placed)
        /** @type {(PolyContext | CircleContext | LineContext)[][]} */
        this.keepoutCtxs = [];

        // array [roof-plane-id][array-id] of module specs
        this.moduleSpecs = [];

        this.fireSetbacks = new KeyStore();
        this.fsCtx; // fire setback context being edited
        this.fsId;  // id of fire setback context being edited

        this.parapets = new KeyStore();
        this.parapetCtx; // parapet context being edited
        this.parapetId;  // id of parapet context being edited

        this.setbackInputOffset = 1;

        this.planeId = 0; // current roof plane id

        /** @type {OutlineContext} */
        this.outlineCtx; // current polygon context active for editing
        this.keepoutCtx; // current keepout context
        this.moveVertexCtx;

        this.systemParams = [];

        this.selectMode = false;

        this.states = {
            NONE:         0,
            AUTO:         1,
            MANUAL:       2,
            KEEPOUT:      3,
            FIND_PLANE:   4,
            MODE:         5
        };
        this.state = this.states.NONE;

        this.planeIntersections = new Object3D();
        this.highlightedContext;

        // vertices of all module array polygons
        this.vertices = [];

        this.materialPlaneIntersection = new LineDashedMaterial({
            color:       0x57d4fd,
            linewidth:   3,
            scale:       1,
            dashSize:    0.3,
            gapSize:     0.15,
            depthTest:   false,
            transparent: true,
            opacity:     0
        });

        this.materialSnapLine = new LineDashedMaterial({
            color:       0x57d4fd,
            linewidth:   3,
            scale:       1,
            dashSize:    0.3,
            gapSize:     0.15,
            depthTest:   false,
            transparent: true,
            opacity:     0.75
        });

        this.snap = {
            ninety:      true,
            vertices:    true,
            intersects:  true,
            start:       true,
            modules:     true
        };

        this.show = new KeyStore();

        /** @type {'vertical' | 'normal'} */
        this.keepoutExtrude = 'vertical';

        this.animationFrame = 0;
        this.lastTimeStamp = 0;

        this.tooltip = new Tooltip();
        this.tooltip.hide();

        this.selectedModules = [];
        this.conflictingModules = [];

        this.name = 'moduleSpecsTool';

        this.modes = {
            'dynamic': ModuleSpecsToolDynamicMode,
            'manual':  ModuleSpecsToolManualMode
        }

        this.mode([ 'dynamic' ]);

        this.commandHistory = [];

        // default spec used on new segment creation
        this.defaultSpecs = {
            ...formatSpecForModuleSpecsTool(
                this.moduleLibrary.getDefaultSpec()
            ),
            // these are what could be considered "dynamic properties of an array
            // installation" as opposed to "static properties of the module itself"
            // the redux refactor deals extensively with this so I am not cleaning
            // it up anymore now other than to allow the user's first module to
            // prepopulate on new segment instead of the hard-coded "Scanifly Default
            // Module" from which these defaults were extracted.
            cols: 8,
            rows: 4,
            hOffset: 0.0254,
            vOffset: 0.0254,
            hSpacing: 6.096,
            vSpacing: 1.524,
            mountType: 'flush-mount',
            dynamicMode: true,
            orientation: 'portrait',
            heightOffset: 0.1016,
        };

        this.dimensions = [];

        // [tag:autoSegments]
        this.autoSegmentsStatuses = {
            NOT_SELECTED: 'not-selected',
            SELECTING:    'selecting',
            SELECTED:     'selected',
            APPLIED:      'applied'
        };

        this.isInGuiHover = false;

        this.statusAutoSegments = this.autoSegmentsStatuses.NOT_SELECTED;

        /** @type {(pid: number, ctx: OutlineContext) => void} */
        this.onContextSelect = () => {};

        /** @type {(pid: number, ctx: OutlineContext) => void} */
        this.onContextHover = () => {};

         /** @type {(pid: number, ctx: OutlineContext) => void} */
         this.onContextHoverEnd = () => {};

    }

    /**
     * @param {(pid: number, ctx: OutlineContext) => void} cb
     */
    setOnContextSelect(cb) {
        this.onContextSelect = cb;
    }

    /**
     * @param {(pid: number, ctx: OutlineContext) => void} cb
     */
    setOnContextHover(cb) {
        this.onContextHover = cb;
    }

    /**
    * @param {(pid: number, ctx: OutlineContext) => void} cb
    */
    setOnContextHoverEnd(cb) {
        this.onContextHoverEnd = cb;
    }

    /** @param {THREE.Camera} camera */
    setCamera(camera, domElement) { this.camera = camera; }

    /** @param {Scene} scene */
    addToScene(scene) {
        this.scene = scene;
        this.scene.add(this.planeIntersections);

        sceneOrtho.add(this.tooltip.getSprite());
    }

    /** @param {Raycaster} rc */
    setRaycaster                (rc)        { this.raycaster = rc;                    }

    /** @param {Object3D} container */
    setContainerObject          (container) { this.container = container;             }

    /** @param {Mesh} mesh */
    addMesh                     (mesh)      { this.meshes.push(mesh);                 }
    setProjectId                (id)        { this.projectId = id;                    }

    registerPropagateState      (callback)  { this.propagateState = callback;         }
    registerRenderMidpointItems (callback)  { this.renderItems = callback;            }

    getState() {
        var ctx = this.outlineCtx;

        var populated = false;

        if(ctx && ctx.moduleArray && ctx.moduleArray.moduleCount > 0)
            populated = true;

        var fireSetbacks = (this.fsId !== undefined) ?
                           this.fireSetbacks.get([ this.planeId, this.fsId ], []) :
                           undefined;

        var parapets = (this.parapetId !== undefined) ?
                       this.parapets.get([ this.planeId, this.parapetId ], []) :
                       undefined;

        var moduleInfo = this.getModuleInfo(this.planeId);

        var arrayInfo = [];

        for (var pid = 0; pid < this.planes.length; pid++) {
            let info = this.getModuleInfo(pid);
            arrayInfo.push(info.arrayInfo);
        }

        var s = {
            pid:                this.planeId, // current plane id

            statusAutoSegments: this.statusAutoSegments,
            planes:             this.planes,
            toolState:          this.state,
            moduleSpecs:        this.moduleSpecs,
            prevSpecs:          this.prevSpecs,
            moduleCount:        this.selectedModules.length,

            keepouts:           this.getKeepoutInfos(),
            show:               this.show,
            snap:               this.snap,
            units:              this.units,
            area:               this.area,
            relay:              this.relay,
            mType:              this.mType,

            qty:                moduleInfo.qty,
            arrayInfo:          arrayInfo,
            output:             moduleInfo.output,
            totalQty:           moduleInfo.totalQty,
            totalOutput:        moduleInfo.totalOutput,

            fireSetbacks:       fireSetbacks,
            parapets:           parapets,

            populated:          populated,
            selectedQty:        this.selectedModules.length,

            proposedKeepoutQty: this.proposedKeepouts.length,
            keepoutPlaneOffset: (this.outlineCtx && this.outlineCtx.offsetSegment) ? this.outlineCtx.offsetSegment.offset : 0
        };

        return s;
    }


    // stub
    propagateState() { }


    set dynamicDiff(val) { this.moduleSpecs[this.planeId][0].dynamicDiff = val; }

    /* ensure that state is allways in search mode if select toggle is on. but only when set to none,
     this way it shouldnt intefere with other context states */
    set state(val) {
        if (val == this.states.NONE){
            this._state = this.selectMode ? this.states.FIND_PLANE : val;
        } else {
            this._state = val
        }
    }
    get state (){ return this._state}

    mode(path) {
        var head = path.slice(0, 1)[0];

        if (Object.keys(this.modes).indexOf(head) !== -1) {
            if (this._mode && this._mode.name !== head) {
                this.stop();

                //console.log('exiting mode:', this._mode.name);
                this._mode = null;
            }

            if (!this._mode) {
                //console.log(`creating mode: '${head}' in '${this.name}'`);

                this._mode = new this.modes[head](head, this);
            }
        } else {
            console.log('unknown mode:', head);
        }

        var tail = path.slice(1);

        if (tail.length > 0) {
            this._mode.mode(tail); // delegate
        }

        return this._mode;
    }


    stop() {
        if (this._mode) {
            this._mode.stop();
        }

        //console.log('stopping in mode:', this.name);

        this.state = this.states.NONE;
    }


    cmd(command, val) {
        this.commandHistory.push({ command, val });
        // ensure the tool is active and a roof plane is selected before processing a command, except for:
        //
        // - keepout highlighting that is triggered on hover and doesn't care about context initialization
        // - edit fire setbacks/parapets that rely on fsId and parapetId being set by enableSetbackEditing
        // - snap commands that can be invoked in the middle other commands
        if (!(command === 'editKeepout' && val.params.highlight !== undefined) &&
              command !== 'editFireSetbacks' &&
              command !== 'editParapets' &&
              command !== 'manualSegmentHighlight' &&
              command !== 'selectAllPlanes' &&
              command !== 'selectNoPlanes' &&
             !command.startsWith('snap')) {
               toolSelector.enable(this);

            if (command !== 'switchPlane') {
                this.switchPlane(this.planeId);
            }
        }

        if (command === 'setAzimuth' || command === 'setTilt') {
            const attr = command.replace('set', '').toLowerCase();

            this.planes[this.planeId][attr] = val;
            this.moduleSpecs[this.planeId][0][attr] = val;

            let ma = this.ctxModuleArray();

            if (ma && this._mode.name === 'manual') {
                this.selectAll();

                let specs = this.moduleSpecs[this.planeId][0];

                ma.setCommonSpecs(specs);
                ma.updateInPlace(this.selectedModules);

                this.selectNone();
                this.checkForModuleConflicts();
            }

            this.refreshModuleLayout();
            return val;
        } else if (command === 'outline') {
            this.startOutline();
        } else if (command === 'addSegment') {
            // before adding a segment manually, purge the auto ones first
            this.applyAutoSegments();
            return this.addPlane();
        } else if (command === 'switchPlane') {
            this.switchPlane(val);
        } else if (command === 'renamePlane') {
            this.outlineCtxs[val.pid][0].label = val.newName;
            this.outlineCtxs[val.pid][0].addAnnotation();
            this.planes[val.pid].name = val.newName;
        } else if (command === 'removePlane') {
            this.removePlane(val);
        } else if (command === 'fill') {
            this.fillModules();
        } else if (command === 'editModuleSpecs') {
            this.moduleSpecs[this.planeId][0] = Object.assign(this.moduleSpecs[this.planeId][0], val);

            let ma = this.ctxModuleArray();

            if (ma) {
                if (this._mode.name === 'dynamic') {
                    this.placeModules(this.outlineCtx);
                } else {
                    let specs = this.moduleSpecs[this.planeId][0];

                    if (ma.currentModule) {
                        ma.setSelectedSpecs([ ma.currentModule ], specs);
                        ma.updateInPlace([ ma.currentModule ]);
                    }

                    if (this.selectedModules.length > 0) {
                        ma.setSelectedSpecs(this.selectedModules, specs);
                        ma.updateInPlace(this.selectedModules);

                        this.selectedModules = [];
                        this.checkForModuleConflicts();
                    }
                }
            }

            this.propagateState();
        } else if (command === 'copySpecsFrom') {
            let source = (val === 'default') ?
                this.defaultSpecs :
                this.moduleSpecs[val][0];

            this.moduleSpecs[this.planeId][0] = deepCopy(source);

            this.refreshModuleLayout();
            this.propagateState();
        } else if (command.startsWith('snap')) {
            const snapType = command.split('.')[1];

            this.snap[snapType] = val;

            if (this.outlineCtx)
                this.outlineCtx.snap = Object.assign({}, this.snap);

            for (let plane of this.outlineCtxs)
                for (let ctx of plane)
                    ctx.snap = Object.assign({}, this.snap);
        } else if (command === 'addKeepout') {
            this.addKeepout(val);
        } else if (command === 'editKeepout') {
            let ctx = this.keepoutCtxs[val.pid][val.kid];

            ctx.updateParams(val.params);
        } else if (command === 'removeKeepout') {
            this.removeKeepoutsFromPlane(val.pid, [val.kid]);
        } else if (command === 'removePlaneAutoKeepouts') {
            this.removePlaneAutoKeepouts(val);
        } else if (command === 'editFireSetbacks') {
            this.fireSetbacks.set([ this.planeId, this.fsId, val.i ], val.val);

            let vv = this.shrinkSetbacks(this.fireSetbacks, this.fsId);
            this.fsCtx.initFromVertices(vv);

            this.refreshModuleLayout();
        } else if (command === 'editParapets') {
            this.parapets.set([ this.planeId, this.parapetId, val.i ], val.val);

            let vv = this.shrinkSetbacks(this.parapets, this.parapetId, -1);
            this.parapetCtx.initFromVertices(vv);

            this.refreshModuleLayout();
        } else if (command === 'enableSetbackEditing') {
            this.editKeepout(val.pid, val.kid);
        } else if (command === 'selectAll') {
            this.selectAll();
            this.propagateState();
        } else if (command === 'selectNone') {
            this.selectNone();
            this.propagateState();
        } else if (command === 'move') {
            this.moveModules();
            this.propagateState();
        } else if (command === 'copy') {
            let ma = this.ctxModuleArray();

            if (ma && ma.mode === 'manual') {
                this._mode.copy();
                this.moveModules();
                this.propagateState();
            } else {
                throw 'Failed to copy modules';
            }
        } else if (command === 'remove') {
            this.removeModules();
            this.propagateState();
        } else if (command === 'addModule') {
            this.addModule();
            this.propagateState();
        } else if (command === 'rectangleSelect') {
            this.rectangleSelect();
        } else if (command === 'switchMode') {
            this.mode([ val ]);
            this.moduleSpecs[this.planeId][0].dynamicMode = (val === 'dynamic');

            let ma = this.ctxModuleArray();

            if (ma) {
                ma.mode = val;

                if (val === 'dynamic')
                    this.placeModules(this.outlineCtx);
            }
        } else if (command === 'setModule') {
            this.cmd(
                'editModuleSpecs',
                formatSpecForModuleSpecsTool(
                    this.moduleLibrary.getSpecByName(val)
                )
            );
        } else if (command === 'show') {
            let pids = [];

            if (val.pid !== undefined) {
                pids.push(val.pid);
            } else { // all planes
                for (let i = 0; i < this.planes.length; i++)
                    pids.push(i);
            }

            if (val.item === 'all') {
                for (let pid of pids) {
                    this.show.set([ pid ], {
                        'annotations': val.show,
                        'outlines': val.show,
                        'keepouts': val.show,
                        'modules':  val.show
                    });
                }
            } else if (val.item) {
                for (let pid of pids)
                    this.show.set([ pid, val.item ], val.show);
            }

            this.toggleVisibility();
        } else if (command === 'setAutoKeepoutOffset') {
            this.outlineCtx.offsetSegment.setOffset(val);
            this.keepoutFinder.offset = val;

        } else if (command === 'proposeKeepouts') {
            this.proposeKeepouts(val);

        } else if (command == 'acceptProposedKeepouts' || command === 'clearProposedKeepouts') {
            const accept = command === 'acceptProposedKeepouts';

            for (const proposedCtx of this.proposedKeepouts) {
                proposedCtx.removeFromScene();

                if (accept) {
                    // hack: swap between each accepted keepout context and make it active
                    // i hate mutable shared state :(
                    this.keepoutCtx = this.addKeepout('poly', proposedCtx);
                    this.keepoutCtx.onComplete();
                }
            }

            this.proposedKeepouts = [];
            this.keepoutFinder.reset();
        } else if (command === 'toggleOffsetSegment') {
            this.outlineCtx.offsetSegment.toggleVisible(val);



            // [tag:autoSegments]
        } else if (command === 'autoSegments.select') {


            this.statusAutoSegments = this.autoSegmentsStatuses.SELECTING;
            this.mode([ 'manual' ]);
            this.rectangleSelect();


        } else if (command === 'autoSegments.apply') {

            this.applyAutoSegments();
            this.propagateState();

        } else if (command === 'autoSegments.clear') {
            for (let pid of this.autoSegmentsSelected) {
                let ctx = this.outlineCtxs[pid][0];

                ctx.container.visible = false;
                this.planes[pid].auto = true;
            }

            this.autoSegmentsSelected = [];
            this.statusAutoSegments = this.autoSegmentsStatuses.NOT_SELECTED;
            this.propagateState();

        }else if (command === 'selectAllPlanes') {

            this.selectedMode = "All";
            this.allAnotToggle(true);

        }else if (command === 'selectNoPlanes') {

            this.selectedMode = "None";
            this.allAnotToggle(false);

        }else if (command === 'manualSegmentHighlight') {
           this.manualSegmentHighlight(val.toggle,val.pid);
        } else {
            console.log('Unknown command:', command, val);
        }
    }


    applyAutoSegments() {
        // viewshed tool and module specs tool maintaining their own plane lists is annoying
        // TODO: figure out keep them in sync better
        let deleteThese = [];
        let deleteTheseViewshedPlanes = [];

        for (let pid = 0; pid < this.outlineCtxs.length; pid++) {
            let ctx = this.outlineCtxs[pid][0];

            if(ctx && this.planes[pid].auto) {
                if (ctx.container.visible) {
                    this.planes[pid].auto = false;
                } else {
                    deleteThese.push(this.planes[pid]);
                    deleteTheseViewshedPlanes.push(viewshedTool.planes[pid]);
                }
            }
        }

        for (let plane of deleteThese)
            this.removePlane(this.planes.indexOf(plane));

        for (let plane of deleteTheseViewshedPlanes)
            viewshedTool.removePlane(viewshedTool.planes.indexOf(plane));

        this.statusAutoSegments = this.autoSegmentsStatuses.APPLIED;
    }


    /**
     * @param {Color | string | number} color to place proposed keepouts in scene.
     */
    proposeKeepouts(color) {
        const autoKeepouts = this.keepoutFinder.getKeepOuts(
            this.outlineCtx.fitPlane,
            headingToDirectionVector(this.outlineCtx.azimuth),
            this.outlineCtx.getVertices()
        );

        for (const autoKeepout of autoKeepouts) {
            const proposedCtx = new PolyContext({
                label               : this.planes[this.planeId].name,
                camera              : this.camera,
                raycaster           : this.raycaster,
                vertices            : autoKeepout.vertices,
                shape               : 'poly',
                extrude             : autoKeepout.extrude,
                height              : autoKeepout.height,
                color               : color,
                showMidpointMarkers : false,
                showSegmentMarkers  : false,
                hasAnnotation       : false
            });

            // hack so I can use the returned poly context as an options hash to addKeepout
            proposedCtx['vertices'] = autoKeepout.vertices;
            proposedCtx['automatic'] = true;

            proposedCtx.addToScene(this.scene);
            this.proposedKeepouts.push(proposedCtx);
        }
    }

    shrinkSetbacks(values, sid, sign) {
        if (sign === undefined)
            sign = 1;

        let ctx = this.outlineCtx;
        let bounds2 = ctx.toPoly2().shrink(i => sign * values.get([ this.planeId, sid, i ], 0));

        let vv = bounds2.vertices.map((v) => {
            let v3 = new Vector3(v.x, v.y, 0);
            return ctx.movementPlane.localToWorld(v3);
        });

        return vv;
    }


    toggleVisibility() {
        for (let pid = 0; pid < this.outlineCtxs.length; pid++) {
            for (let ctx of this.outlineCtxs[pid]) {
                if (this.planes[pid].auto)
                    continue;

                // quick fix here. container shares space with other types now
                ctx.container.children.forEach(elem => {
                  if (elem.type == 'Line'|| elem.type == 'Mesh') {
                        elem.visible = this.show.get([ pid, 'outlines' ], true);
                  } else {
                        elem.visible = this.show.get([ pid, 'annotations' ], true);
                  }
                });

                if (this.selectedMode == "All") {
                    this.allAnotToggle(true);
                }

                let ma = ctx.moduleArray;

                if (ma) {
                    ma.visible = this.show.get([ pid, 'modules' ], true);
                    ma.showSetbacks(this.show.get([ pid, 'keepouts' ], true));
                }
            }
        }

        for (let pid = 0; pid < this.keepoutCtxs.length; pid++) {
            for (let ctx of this.keepoutCtxs[pid]) {
                ctx.container.visible = this.show.get([ pid, 'keepouts' ], true);
            }
        }

        this.updateDimensions();
        this.showMidpointItems();
    }

     /**
     * set features container visibility
     * @param {boolean} visible The new visibility state
     * @returns { () => {} } Function to restore previous visibility state
     */
    setFeatureVisibility(visible) {
        let restoreVisibilities = [];
        let fcn = (ctx) => {
            const wasVisible = ctx.container.visible;
            restoreVisibilities.push( () => {
                ctx.container.visible = wasVisible;
            });
            ctx.container.visible = visible;
        };

        this.outlineCtxs.flat().forEach( fcn );
        this.keepoutCtxs.flat().forEach( fcn );

        this.dimensions.forEach( dim => {
            const wasVisible = dim.isVisible();
            restoreVisibilities.push( () => {
                wasVisible ? dim.show() : dim.hide();
            });
            visible ? dim.show() : dim.hide();
        });

        this.outlineCtxs.flat().forEach(ctx => ctx.container.visible = visible);
        this.keepoutCtxs.flat().forEach(ctx => ctx.container.visible = visible);

        return () => {
            restoreVisibilities.forEach( fcn => fcn() );
        }
    }


    showMidpointItems() {
        if (!this.renderItems)
            return;

        let plane = this.outlineCtxs[this.planeId];
        var items = [];

        if (plane) {
            for (let ctx of plane) {
                let vm = ctx.getScreenMidpoints(this.setbackInputOffset);

                const hasParapets = this.parapets.get([ this.planeId, this.parapetId ]);
                const hasFireSetbacks = this.fireSetbacks.get([ this.planeId, this.fsId ]);

                for (let i = 0; i < vm.length; i++) {
                    let item = {
                        x: vm[i].x,
                        y: vm[i].y
                    };

                    let showKeepouts = this.show.get([ this.planeId, 'keepouts' ], true);

                    if (showKeepouts && this.fsId !== undefined && hasFireSetbacks)
                        item.setback = this.fireSetbacks.get([ this.planeId, this.fsId, i ], 0);

                    if (showKeepouts && this.parapetId !== undefined && hasParapets)
                        item.parapet = this.parapets.get([ this.planeId, this.parapetId, i ], 0);

                    items.push(item);
                }
            }
        }

        this.renderItems(items);
    }


    updateDimensions() {
        const pid = this.planeId;
        let plane = this.outlineCtxs[pid];

        for (let dim of this.dimensions)
            dim.hide();

        if (this.showDimensions && plane) {
            for (let ctx of plane) {
                let midpoints = ctx.getWorldMidpoints(this.setbackInputOffset);
                let lengths = ctx.getLengths();

                for (let i = 0; i < midpoints.length; i++) {
                    if (this.dimensions[i] === undefined) {
                        this.dimensions[i] = new Tooltip({
                            color:       '#eee',
                            bgColor:     '#2e3134',
                            borderColor: '#2e3134'
                        });
                    }

                    let t = this.dimensions[i];

                    let dimension = (measurementUnits === MeasurementUnits.METERS) ?
                        Math.round(lengths[i] * 100) / 100 + 'm' :
                        Format.imperial(lengths[i]);

                    t.setTextRectangle(dimension);
                    t.show();

                    var sprite = t.getSprite();
                    sprite.position.copy(midpoints[i]);
                    scene.add(sprite);

                    this.scaleDimensionSprite(t);
                }
            }
        }
    }


    scaleDimensionSprite(tooltip) {
        let d = camera.position.distanceTo(new Vector3(0, 0, 0));
        tooltip.scale(d * 0.04);
    }


    scaleDimensionSprites() {
        for (let t of this.dimensions)
            this.scaleDimensionSprite(t);
    }


    enable() {
        if (this.enabled)
            return;

        this.enabled = true;
    }


    switchPlane(pid) {
        this.planeId = pid;

        this.dropActiveCtxs();

        this.selectedMode = "Individual";

        if (pid !== undefined) {
            for (let plane of this.outlineCtxs)
                for (let ctx of plane)
                    ctx.setRenderOrder(0);

            if (this.outlineCtxs[pid] !== undefined && this.outlineCtxs[pid].length > 0) {
                let ctx = this.outlineCtxs[pid][0];

                ctx.setRenderOrder(1); // draw active context last

                this.outlineCtx = ctx;

                this.state = this.states.NONE;
            }

            if(this.moduleSpecs[pid])
                this.mode([ this.moduleSpecs[pid][0].dynamicMode ? 'dynamic' : 'manual' ]);

            let area = (this.outlineCtxs[pid] && this.outlineCtxs[pid][0]) ? this.outlineCtxs[pid][0].calculateArea() : 0
            this.setSegmentArea(area)
        }

        this.updateDimensions();
        this.showMidpointItems();
        this.highlightCurrentPlane();
    }


    disable() {
        if (!this.enabled)
            return;

        this.selectNone();
        this.dropActiveCtxs();

        /// @todo is there a less hacky/better place to do this?
        for (const [ctx] of this.outlineCtxs) {
            if (ctx)
                ctx.offsetSegment.toggleVisible(false);
        }

        this.enabled = false;
    }


    addPlane() {
        this.planes.push({
            name: 'Segment ' + getPlaneName(this.planes.length)
        });

        this.modules    .push([]);
        this.outlineCtxs.push([]);
        this.keepoutCtxs.push([]);

        let specs = deepCopy(this.defaultSpecs);
        let lastIdx = this.moduleSpecs.length - 1;

        // not sure this behavior should be the default, make it a setting?
        if (lastIdx >= 0)
            specs.dynamicMode = this.moduleSpecs[lastIdx][0].dynamicMode;

        this.moduleSpecs.push([ specs ]);
        this.systemParams.push(this.constructor.getDefaultSystemParams());

        return this.planes.length - 1;
    }


    removePlane(pid) {
        this.dropActiveCtxs();

        this.planes     .splice(pid, 1);
        this.modules    .splice(pid, 1);
        this.moduleSpecs.splice(pid, 1);

        for (var i = 0; i < this.outlineCtxs[pid].length; i++) {
            const ctx = this.outlineCtxs[pid][i];

            ctx.show(false);
            this.scene.remove(ctx.moduleArray);
        }

        this.outlineCtxs.splice(pid, 1);

        for (let ctx of this.keepoutCtxs[pid])
            ctx.show(false);

        this.keepoutCtxs .splice(pid, 1);
        this.systemParams.splice(pid, 1);

        this.show        .splice([ pid ], this.planes.length);
        this.parapets    .splice([ pid ], this.planes.length);
        this.fireSetbacks.splice([ pid ], this.planes.length);

        if (this.planes.length > 0) {
            this.planeId = this.planes.length - 1;
        }

        this.setLastOutlineCtx();

        this.updateDimensions();
        this.showMidpointItems();
        this.highlightCurrentPlane();
        this.createPlaneIntersections();
    }


    /**
     * Interrupt outline/keepout creation or editing.
     */
    dropActiveCtxs(options) {
        options = options || {};

        if (this.state === this.states.MANUAL && this.outlineCtx)
            this.outlineCtx.show(false);

        if (this.state === this.states.KEEPOUT) {
            if (this.keepoutCtx)
                this.keepoutCtx.show(false);

            if (this.fsCtx)
                this.fsCtx.show(false);

            if (this.parapetCtx)
                this.parapetCtx.show(false);
        }

        if (!options.skipOutline)
            delete this.outlineCtx;

        delete this.keepoutCtx;
        delete this.fsCtx;
        delete this.fsId;
        delete this.parapetCtx;
        delete this.parapetId;

        this.state = this.states.NONE;

        mouseHandler.clearCursor('mst');

        this.updateDimensions();
        this.showMidpointItems();
        this.highlightCurrentPlane();
    }

    /**
     * @param {number} azimuth
     * @param {number} tilt
     */
    setAzimuthAndTilt(azimuth, tilt) {
        this.planes[this.planeId].azimuth = azimuth;
        this.planes[this.planeId].tilt    = tilt;
    }


    createOutlineCtx(manual) {
        var ctx = new PolyContext({
            label:           this.planes[this.planeId].name,
            camera:          this.camera,
            raycaster:       this.raycaster,
            placement:       manual ? 'manual' : 'auto',
            snap:            this.snap,
            shape:           'poly',
            arrowWidget:     true,
            onComplete:      () => {
                if (this.outlineCtxs[this.planeId] === undefined)
                    this.outlineCtxs[this.planeId] = [];

                this.outlineCtxs[this.planeId].push(this.outlineCtx);

                if (this.outlineCtx.placement === 'auto') {
                    this.placeModules(this.outlineCtx);
                } else {
                    this.createPlaneIntersections();
                    this.updateSnapVertices();
                }

                this.outlineCtx.setAzimuth(this.planes[this.planeId].azimuth);

                this.setSegmentArea((this.outlineCtx) ? this.outlineCtx.calculateArea() : 0);

                this.showMidpointItems();

                this.state = this.selectMode ? this.states.FIND_PLANE : this.states.NONE;

                this.propagateState();

                mouseHandler.clearCursor('mst');
            },
            onShapeChanged:  () => {
                this.updateSnapVertices();
                this.setSegmentArea((this.outlineCtx) ? this.outlineCtx.calculateArea() : 0);

                if (this._mode.onShapeChanged)
                    this._mode.onShapeChanged();

                this.propagateState();
            },
            onVertexMove:    () => {
                this.updateDimensions();
                this.showMidpointItems();
            },
            onSegmentInsert: (index, copyIndex) => {
                if (this.outlineCtx.moduleArray)
                    this.outlineCtx.moduleArray.insertSetback(index, copyIndex);
            },

            /** @param {Vector3} dir */
            onSelectDir: (dir) => {
                // get azimuth from arrow widget
                const az = azimuthFromVector(dir);

                // get tilt from roof plane normal
                const n = this.outlineCtx.fitPlane.normal.clone();
                const tilt = tiltFromVectorAndNormal(dir, n);

                this.outlineCtx.setAzimuth(az);
                this.setAzimuthAndTilt(az, tilt);
                this.resetOutlineAnimation();

                if (this.ctxModuleArray() && this.inDynamicMode())
                    this.placeModules(this.outlineCtx);

                this.updateDimensions();
                this.propagateState();
            }
        });

        ctx.addToScene(this.scene);

        for (let mesh of this.meshes)
            ctx.addMesh(mesh);

        ctx.setSnapVertices(this.vertices);

        return ctx;
    }


    createPlaneIntersections() {
        var cc = [];

        for (let plane of this.outlineCtxs)
            for (let ctx of plane)
                cc.push(ctx);

        for (let pi of this.planeIntersections.children)
            this.planeIntersections.remove(pi);

        for (let ctx of cc)
            ctx.planeIntersections = [];

        for (var i = 0; i < cc.length - 1; i++) {
            for (var j = i + 1; j < cc.length; j++) {
                var normal1 = cc[i].fitPlane.normal;
                var point1  = cc[i].fitPlane.centroid.clone().negate();

                var p1 = new Plane();
                p1.setFromNormalAndCoplanarPoint(normal1, point1);

                var normal2 = cc[j].fitPlane.normal;
                var point2  = cc[j].fitPlane.centroid.clone().negate();

                var p2 = new Plane();
                p2.setFromNormalAndCoplanarPoint(normal2, point2);

                var pi = intersectPlanes(p1, p2);

                if (pi === null)
                    continue;

                var ext = pi[1].clone().multiplyScalar(1000);
                var line3 = new Line3(pi[0].clone().sub(ext), pi[0].clone().add(ext));

                // show plane intersection line
                var geometry = new Geometry();
                geometry.vertices.push(line3.start.clone(), line3.end.clone());

                var line = new Line(geometry, this.materialPlaneIntersection);
                line.computeLineDistances();

                this.planeIntersections.add(line);

                let is = {
                    line3:   line3,
                    line:    line,
                    normal1: normal1,
                    normal2: normal2,
                    point1:  point1.clone().negate(),
                    point2:  point2.clone().negate()
                };

                cc[i].planeIntersections.push(is);
                cc[j].planeIntersections.push(is);

            }
        }
    }


    updateSnapVertices() {
        this.vertices = [];

        for (let plane of this.outlineCtxs) {
            for (let ctx of plane) {
                this.vertices.push(...ctx.getVertices());
                ctx.setSnapVertices(this.vertices);
            }
        }
    }


    /**
     * @deprecated
     */
    startArray() {
        this.outlineCtx = this.createOutlineCtx();
        this.state = this.states.AUTO;

        mouseHandler.setCursor('crosshair', 'mst', true);
    }


    startOutline() {
        this.dropActiveCtxs();

        this.outlineCtx = this.createOutlineCtx(true);
        this.state = this.states.MANUAL;

        mouseHandler.setCursor('crosshair', 'mst', true);
        emitEvent('status', { message: this.outlineCtx.statusMessage });
    };


    fillModules() {
        if (this._mode.fill)
            this._mode.fill();

        this.dropActiveCtxs({ skipOutline: true });
        this.placeModules(this.outlineCtx, { fill: true });

        this.showMidpointItems();
    };


    addModule() {
        this.state = this.states.MODE;
        this._mode.add();
    }


    moveModules() {
        this.state = this.states.MODE;
        this._mode.move();
    }


    rectangleSelect() {
        this.state = this.states.MODE;
        this._mode.rectangleSelect();
    }


    removeModules() {
        let ctx = this.outlineCtx;
        let ma = ctx.moduleArray;

        ctx.placement = 'manual';

        this.selectedModules.forEach(m => ma.removeModule(m));
        this.selectedModules = [];

        ma.updateModuleCount();

        if (ma.moduleCount === 0) {
            // don't keep array around if all modules are deleted
            this.scene.remove(ctx.moduleArray);

            delete ctx.moduleArray;
            delete this.moduleSpecs[this.planeId][0].dynamicDiff;

            ctx.enableArrowWidget = true;
        }

        this.state = this.states.NONE;

        this.checkForModuleConflicts();
        this.propagateState();
    }


    setSegmentArea(area) {
        this.area = area
    }


    selectAll() {
        let ma = this.ctxModuleArray();

        this.selectedModules = [];

        // TODO: move selection internals to array class
        if (ma && ma.group) {
            ma.group.children.forEach((m) => {
                m.select();
                this.selectedModules.push(m);
            });
        }
    }


    selectNone() {
        if (this.outlineCtx && this.outlineCtx.moduleArray) {
            this.selectedModules.forEach((m) => m.deselect());
            this.conflictingModules.forEach(m =>  m.deselect());

            this.selectedModules = [];
            this.conflictingModules = [];
        }

        this.state = !this.selectMode ? this.states.NONE: this.state = this.states.FIND_PLANE

        mouseHandler.clearCursor('mst');
    }


    setKeepoutExtrude(val) { this.keepoutExtrude = val; }

    /**
     * @template {(PolyContext | LineContext | CircleContext)['shape']} Shape
     * @param {Shape} shape
     * @param {object} [options]
     * @param {this['keepoutExtrude']} [options.extrude]
     * @param {number} [options.height]
     * @param {number} [options.setback]
     * @param {Vector3[]} [options.vertices]
     * @param {number} [options.radius]
     * @param {Vector3} [options.normal]
     * @param {boolean} [options.restore]
     * @param {boolean} [options.automatic = false]
     * @param {PolyContext['colorMode'] | undefined} options.colorMode
     *
     * @returns {Shape extends 'circle' ? CircleContext : (Shape extends 'line' ? LineContext : Shape extends infer PolyType ? PolyContext<PolyType> : never)}
     */
    addKeepout(shape, options) {
        this.dropActiveCtxs({ skipOutline: true });

        options = options || {};

        var params = {
            shape,
            label:         this.planes[this.planeId].name,
            camera:        this.camera,
            raycaster:     this.raycaster,
            color:         SCANIFLY_RED,
            colorMode:     options.colorMode,
            lineWidth:     3,
            normal:        (this.outlineCtx) ? this.outlineCtx.fitPlane.normal :
                                               options.normal,
            extrude:       options.extrude || this.keepoutExtrude,
            height:        options.height,
            setback:       options.setback,
            vertices:      options.vertices,
            automatic:     options.automatic || false,
            hasAnnotation: false,
            onComplete: () => {
                this.keepoutCtxs[this.planeId].push(this.keepoutCtx);
                this.propagateState();
                this.state = this.states.NONE;

                if (this.ctxModuleArray() && this.inDynamicMode())
                    this.placeModules(this.outlineCtx);

                mouseHandler.clearCursor('mst');
            }
        };

        var ctx;

        if (shape === 'line') {
            ctx = new LineContext(params);
        } else if (shape === 'rect') {
            const rectParams = {
                ...params,
                // these optional values -
                // they are undefined and unneeded when loading a project
                // but defined and required when creating a new keep out
                // the rect constructor conditionally uses these values
                azimuth: this.outlineCtx?.azimuth,
                fitPlane: this.outlineCtx?.fitPlane
            };
            ctx = new RectContext(rectParams);
        } else if (shape === 'circle') {
            Object.assign(params, { radius: options.radius });

            ctx = new CircleContext(params);
        } else if (shape === 'poly' || shape === 'fire-setback' || shape === 'parapet') {
            Object.assign(params, {
                showMidpointMarkers: false,
                showSegmentMarkers:  false,
                snap90:              true
            });

            if (shape === 'poly') {
                ctx = new PolyContext(params);

                emitEvent('status', { message: ctx.statusMessage });
            } else {
                if (params.vertices === undefined) {
                    let ctx = this.outlineCtx;
                    let bounds2 = ctx.toPoly2().shrink(i => 0);

                    params.vertices = bounds2.vertices.map((v) => {
                        let v3 = new Vector3(v.x, v.y, 0);
                        return ctx.movementPlane.localToWorld(v3);
                    });
                }

                params.shape = shape;

                ctx = new PolyContext(params);

                if (!options.restore) {
                    this.keepoutCtxs[this.planeId].push(ctx);

                    if (shape === 'fire-setback') {
                        this.fsCtx = ctx;
                        this.fsId = this.keepoutCtxs[this.planeId].length - 1;
                        this.fireSetbacks.set([ this.planeId, this.fsId ], [ 0, 0, 0 ]);
                    } else if (shape === 'parapet') {
                        this.parapetCtx = ctx;
                        this.parapetId = this.keepoutCtxs[this.planeId].length - 1;
                        this.parapets.set([ this.planeId, this.parapetId ], [ 0, 0, 0 ]);
                    }

                    this.propagateState();
                    this.showMidpointItems();
                }
            }
        } else {
            console.log('Unknown shape:', shape)
        }

        for (let mesh of this.meshes)
            ctx.addMesh(mesh);

        ctx.addToScene(this.scene);

        // activate keepout if we're not restoring from save data
        if (!options.vertices && shape !== 'fire-setback' && shape !== 'parapet') {
            this.keepoutCtx = ctx;

            this.state = this.states.KEEPOUT;

            mouseHandler.setCursor('crosshair', 'mst', true);
        }

        return ctx;
    }


    editKeepout(pid, kid) {
        var ctx = this.keepoutCtxs[pid][kid];

        delete this.fsCtx;
        delete this.fsId;
        delete this.parapetCtx;
        delete this.parapetId;

        if (ctx.shape === 'fire-setback') {
            this.fsCtx = ctx;
            this.fsId = kid;
        } else if (ctx.shape === 'parapet') {
            this.parapetCtx = ctx;
            this.parapetId = kid;
        }

        // TODO: verify it's not necessary and delete
        //if (this.ctxModuleArray() && this.inDynamicMode())
        //    this.placeModules(this.outlineCtx);

        this.showMidpointItems();
    }

    /**
     * @param {number} planeId
     * @param {number[]} keepoutIds
     */
    removeKeepoutsFromPlane(planeId, keepoutIds) {
        if (planeId >= this.keepoutCtxs.length) {
            return;
        }

        const planeKeepoutCtxs = this.keepoutCtxs[planeId];
        const planeKeepoutCount = planeKeepoutCtxs.length;

        /** @type {typeof planeKeepoutCtxs} */
        const keepoutsForRemoval = [];

        // This two-phase "mark and sweep" approach is necessary since
        // the keepout "id" is an array index which is mutable when keepouts
        // are removed; hence, the handle to a passed keepout is immediately
        // invalidated once the first one is spliced out.
        for (const keepoutId of keepoutIds) {
            if (keepoutId > planeKeepoutCount) {
                continue;
            }

            const keepout = planeKeepoutCtxs[keepoutId];
            this.scene.remove(keepout.container);
            keepoutsForRemoval.push(keepout);
        }

        for (const toDelete of keepoutsForRemoval) {
            const newIdx = planeKeepoutCtxs.indexOf(toDelete);

            if (newIdx === -1) {
                continue;
            }

            planeKeepoutCtxs.splice(newIdx, 1);

            const planeKeepoutCount = planeKeepoutCtxs.length;

            this.parapets.splice([planeId, newIdx], planeKeepoutCount);
            this.fireSetbacks.splice([planeId, newIdx], planeKeepoutCount);
        }

        this.ctxModuleArray() && this.inDynamicMode() && this.placeModules(this.outlineCtx);

        this.showMidpointItems();
        this.propagateState();
    }

    /**
     * @param {number} [pid = this.planeId]
     */
    removePlaneAutoKeepouts(pid) {
        if (!pid) {
            pid = this.planeId;
        }

        if (pid >= this.keepoutCtxs.length) {
            return;
        }

        const parentThis = this;
        this.removeKeepoutsFromPlane(
            pid,
            Array.from(
                function* () {
                    for (let kId = 0; kId < parentThis.keepoutCtxs[pid].length; ++kId) {
                        const kCtx = parentThis.keepoutCtxs[pid][kId];

                        if (kCtx['automatic']) {
                            yield kId;
                        }
                    }
                }()
            )
        );
    }

    removeKeepout(pid, kid) {
        var ctx = this.keepoutCtxs[pid][kid];

        this.scene.remove(ctx.container);
        this.keepoutCtxs[pid].splice(kid, 1);

        let length = this.keepoutCtxs[pid].length;

        this.parapets    .splice([ pid, kid ], length);
        this.fireSetbacks.splice([ pid, kid ], length);

        if (this.ctxModuleArray() && this.inDynamicMode())
            this.placeModules(this.outlineCtx);

        this.showMidpointItems();
        this.propagateState();
    }


    /**
     * Legacy modules
     */
    placeSingleModule(planeId, module) {
        module.object = new SolarPanelModule({
            width:      module.width,
            height:     module.height,
            moduleType: module.moduleType
        });

        module.object.rotateZ(Math.PI/2 - degToRad(module.azimuth));
        module.object.setTilt(module.tilt);
        module.object.visible = false;

        this.container.add(module.object);
        this.modules[planeId].push(module);

        if (module.position) {
            module.object.position.set(module.position.x, module.position.y, module.position.z);
            module.object.visible = true;
        }

        return module;
    }

    /**
     *
     * @param {PolyContext & {moduleArray?: SolarPanelModuleArray}} ctx
     * @param {*} options
     * @returns
     */
    placeModules(ctx, options) {
        var fitPlane = ctx.planeFromSegments();

        if (!fitPlane)
            return;

        if (options === undefined)
            options = {};

        if (ctx.moduleArray === undefined) {
            ctx.moduleArray = new SolarPanelModuleArray({
                scene:        this.scene,
                meshes:       this.meshes,
                raycaster:    this.raycaster,
                fireSetbacks: options.fireSetbacks
            });

            this.scene.add(ctx.moduleArray);
        }

        let specs = this.moduleSpecs[this.planeId][0];

        let az   = this.planes[this.planeId].azimuth;
        let tilt = this.planes[this.planeId].tilt;

        specs.azimuth = az;
        specs.tilt    = tilt;

        var kCtxs = this.keepoutCtxs[this.planeId] || [];
        var fireSetback;

        for (let ctx of kCtxs) {
            if (ctx.shape === 'fire-setback') {
                fireSetback = ctx.toPoly2().vertices.map((v) => {
                    let v3 = new Vector3(v.x, v.y, 0);
                    return ctx.movementPlane.localToWorld(v3);
                });
            }
        }

        ctx.setAzimuth(az);
        ctx.moduleArray.setCommonSpecs(specs);

        return ctx.moduleArray.placeAsync({
            fitPlane:    fitPlane,
            origin:      ctx.getOrigin(),
            vertices:    ctx.getVertices(),
            fireSetback: fireSetback,
            keepouts:    kCtxs,
            placement:   ctx.placement,
            progressCb:  (val) => {
                emitEvent('progress', { percent: Math.round(val * 100) });
            }
        }).then(() => {
            if (ctx.moduleArray.moduleCount > 0)
                ctx.enableArrowWidget = false;

            this.propagateState();
        });
    }


    refreshModuleLayout() {
        let ma = this.ctxModuleArray();

        if (ma) {
            if (this._mode.name === 'dynamic') {
                this.placeModules(this.outlineCtx);
            }
        }
    }


    checkForModuleConflicts() {
        let ma = this.ctxModuleArray();

        if (ma) {
            console.log('conflict check');
            this.conflictingModules = ma.checkConflicts();
        }
    }


    ctxModuleArray() {
        let ctx = this.outlineCtx
        var ma

        if (ctx) {
            ma = ctx.moduleArray
            return ma
        }
        return null
    }


    intersectMovingPlane() {
        let ma = this.ctxModuleArray();
        let x;

        if (ma) {
            /** @global vMouse */
            this.raycaster.setFromCamera(vMouse, this.camera);
            var xs = this.raycaster.intersectObject(ma.movingPlane, true);

            if (xs.length > 0) {
                x = xs[0];
            }
        }

        return x;
    }


    intersectModules() {
        let ma = this.ctxModuleArray();
        let x;

        if (ma) {
            /** @global vMouse */
            this.raycaster.setFromCamera(vMouse, this.camera);
            var xs = this.raycaster.intersectObjects(ma.group.children, true);

            if (xs.length > 0) {
                x = xs[0];
            }
        }

        return x;
    }


    clear() { }


    getModuleSpecs() {
        // this is suboptimal, because if we are saving module specs to db, we
        // need to make sure we're accidentally not saving extra data
        let s = this.getState();

        // delete s.moduleSpecs[s.pid].parapets;
        // delete s.moduleSpecs[s.pid].fireSetbacks;
        // delete s.moduleSpecs[s.pid].keepouts;
        // delete s.moduleSpecs[s.pid].populated;
        // delete s.moduleSpecs[s.pid].snap;
        // delete s.moduleSpecs[s.pid].show;

        // Only return specs for the current roof plane
        return s.moduleSpecs[s.pid] || [{}]
    }


    getModuleInfo(planeId) {
        var totalQty = 0;

        for (let pid = 0; pid < this.modules.length; pid++)
            totalQty += this.modules[pid].length;

        for (let plane of this.outlineCtxs)
            for (let ctx of plane)
                if (ctx.moduleArray && ctx.moduleArray.moduleCount)
                    totalQty += ctx.moduleArray.moduleCount;

        var totalOutputKW = 0, outputKW = 0;

        // individual module output
        for (var pid = 0; pid < this.modules.length; pid++) {
            for (var i = 0; i < this.modules[pid].length; i++) {

                var kw
                if(this.moduleSpecs[pid][i]) {
                    kw = this.moduleSpecs[pid][i].wattage
                } else {
                    kw = this.modules[pid][i] ? this.modules[pid][i].wattage : 0
                }

                if (kw)
                    totalOutputKW += kw;

                if (pid === planeId) {
                    outputKW += kw;
                }
            }
        }

        // array output
        var qty = (this.modules[planeId] !== undefined) ? this.modules[planeId].length : 0;
        var moduleInfo = { wattage: outputKW, qty: qty };

        for (var pid = 0; pid < this.outlineCtxs.length; pid++) {
            for (var i = 0; i < this.outlineCtxs[pid].length; i++) {
                let ctx = this.outlineCtxs[pid][i];

                if (!ctx.moduleArray)
                    continue;

                let kw = ctx.moduleArray.totalOutput;

                if (kw)
                    totalOutputKW += kw;

                if (pid === planeId) {
                    if (kw)
                        outputKW += kw;

                    if (ctx.moduleArray.moduleCount)
                        qty += ctx.moduleArray.moduleCount;
                }
            }
        }

        // array module count and total output
        var arrayInfo = [];

        if(this.outlineCtxs[planeId] !== undefined) {
            for (var i = 0; i < this.outlineCtxs[planeId].length; i++) {
                let ctx = this.outlineCtxs[planeId][i];
                let pl = ctx.placement || 'auto';

                if (ctx.moduleArray) {
                    arrayInfo.push({
                        wattage:   ctx.moduleArray.totalOutput || 0,
                        qty:       ctx.moduleArray.moduleCount || 0,
                        placement: pl
                    });
                } else {
                    arrayInfo.push({
                        wattage:   0,
                        qty:       0,
                        placement: pl
                    });
                }
            }
        }

        var info = {
            qty:         qty,
            output:      outputKW,
            totalOutput: totalOutputKW,
            totalQty:    totalQty,
            arrayInfo:   arrayInfo,
            moduleInfo:  moduleInfo
        };

        if (this.outlineCtxs[planeId] !== undefined && this.outlineCtxs[planeId].length > 0) {
            info['wattage'] = [];

            for (var i = 0; i < this.outlineCtxs[planeId].length; i++) {
                if (this.outlineCtxs[planeId][i].moduleArray) {
                    var wattage
                    if (this.outlineCtxs[planeId][i].moduleArray && this.outlineCtxs[planeId][i].moduleArray.moduleSpecs) {
                        wattage = this.outlineCtxs[planeId][i].moduleArray.moduleSpecs.wattage
                    } else {
                        if (this.moduleSpecs[planeId][0] && this.moduleSpecs[planeId][0].wattage) {
                            wattage = this.moduleSpecs[planeId][0].wattage
                        } else {
                            wattage = 0
                        }
                    }
                    info['wattage'].push(wattage);
                } else {
                    info['wattage'].push(0);
                }
            }
        } else if (this.modules[planeId] !== undefined && this.modules[planeId].length > 1) {
            var module = this.modules[planeId][0];

            info['wattage'] = module.wattage;
        }

        return info;
    }


    getModuleInfos() {
        var infos = [];

        for (var planeId = 0; planeId < this.outlineCtxs.length; planeId++) {
            if (this.outlineCtxs[planeId].length > 0 || (this.modules[planeId] !== undefined && this.modules[planeId].length > 0)) {
                infos[planeId] = this.getModuleInfo(planeId);
            } else {
                infos[planeId] = {
                    qty:         0,
                    output:      0,
                    totalOutput: 0,
                    arrayInfo:   [],
                    moduleInfo:  { wattage: 0, qty: 0 }
                };
            }
        }

        return infos;
    }


    inDynamicMode() {
        let dynamic = true;

        if (this.moduleSpecs[this.planeId] && this.moduleSpecs[this.planeId][0]) {
            dynamic = this.moduleSpecs[this.planeId][0].dynamicMode;
        }

        return dynamic;
    }

    /**
     * @param {number} pid
     */
    getKeepoutsInfoForPlane(pid) {
        return this.keepoutCtxs[pid].map((_keepouts, kid) => this.getKeepoutInfo(pid, kid));
    }

    /**
     * @param {number} pid @param {number} kid
     */
    getKeepoutInfo(pid, kid) {
        const keepout = this.keepoutCtxs[pid][kid];

        return {
            shape: keepout.shape || 'poly',
            normal: keepout.normal,
            extrude: keepout.extrude,
            height: keepout.height,
            setback: keepout.setbackSize,
            automatic: keepout.automatic || false,
            // @todo: determine if it's necessary to return a POJO here
            //   when we have to attach these proxies to the actual
            //   keepout object anyway. (I'm thinking *not*.)
            getPolyline: () => keepout.getPolyline(),
            getExtrusions: () => keepout.getExtrusions(),
            getSetbackPolyline: () => keepout.getSetbackPolyline(),
            ...(keepout.shape === 'circle' ? {radius: keepout.radius} : {}),
            ...(keepout.colorMode ? { colorMode: keepout.colorMode } : {})
        };
    }

    getKeepoutInfos() {
        return this.keepoutCtxs.map((_ctx, pid) => this.getKeepoutsInfoForPlane(pid));
    }


    static getDefaultSystemParams() {
        return {
            arrayType:    0,
            moduleType:   0,
            dcToAcRatio:  1.1,
            invEff:       96,

            // losses due to various factors, in %
            snow:         0,
            mismatch:     2,
            wiring:       2,
            connections:  0.5,
            lid:          1.5,
            rating:       1,
            age:          0,
            availability: 3,
            soiling:      2
        };
    }


    getSystemParams(pid) {
        /** @global */
        const avgs = viewshedTool.getSolarAccessAverages(pid);

        if (avgs && avgs.count > 0) {
            this.systemParams[pid].shading = 100 - avgs.solarAccess;
        } else {
            delete this.systemParams[pid].shading;
        }

        return this.systemParams[pid];
    }


    getModuleMeshes() {
        let meshes = []
        this.outlineCtxs.forEach( (ctx, pid) => {
            if(ctx[0]) {
                let ma = ctx[0].moduleArray
                if(ma) {
                    meshes.push(...ma.getModuleMeshes());
                }
            }
        })
        return meshes
    }


    updateSystemParams(planeId, key, value) {
        this.systemParams[planeId][key] = value;
    }


    calculateLosses(planeId) {
        var params = this.getSystemParams(planeId);
        let avail = 1;

        for (var key in params) {
            if ([ 'moduleType', 'arrayType', 'dcToAcRatio', 'invEff' ].indexOf(key) === -1) {
                avail *= (1 - params[key] / 100);
            }
        }

        return clampBetween(-5, 99)(100 * (1 - avail));
    }


    async getMonthlySolarAccess () { }; // stub
    getPlaneInfo          () { }; // stub
    getMonthlyUsage       () { }; // stub
    getPricePerKWH        () { }; // stub

    getPlanesLength       () {
        let drawn = 0
        this.outlineCtxs.forEach( (ctxArr) => { if (ctxArr.length > 0) { drawn += 1 }})
        return drawn
    };

    getSolarParams() {
        var info = this.getPlaneInfo();
        var solarParams = [];

        for (var planeId = 0; planeId < info.planes.length; planeId++) {
            var moduleInfo = this.getModuleInfo(planeId);

            solarParams.push({
                lat:         info.lat,
                lng:         info.lng,
                tilt:        info.planes[planeId].tilt,
                azimuth:     info.planes[planeId].azimuth % 360,
                kW:          moduleInfo.output / 1000,
                utilityRate: this.getPricePerKWH().utility
            });
        }

        return solarParams;
    }


    getProductionValues() {
        var deferred = Q.defer();

        /** @type {PromiseLike<any>[]} */
        var promises = [ this.getMonthlySolarAccess() ];
        var solarParams = this.getSolarParams();

        if (solarParams.length === 0) {
            deferred.resolve();
            return deferred.promise;
        }

        // pvWatts api throws an error if we send 0 as the system capacity
        var totalkW = sum(solarParams, function (x) { return x.kW; });

        if (totalkW === 0) {
            deferred.resolve();
            return deferred.promise;
        }

        // get pvWatts data for each roof plane with viewsheds
        let pids = [];
        var annualProduction = [];

        for (let pid = 0; pid < solarParams.length; pid++) {
            let sysParams = this.getSystemParams(pid);
            let losses = this.calculateLosses(pid);

            // backward compatibility
            solarParams[pid].tilt = this.planes[pid].tilt;
            solarParams[pid].azimuth = this.planes[pid].azimuth % 360;

            if (sysParams.shading !== undefined) {
                pids.push(pid);
                promises.push(this.pvWattsClient.pvWattsInfo(solarParams[pid], sysParams, losses));
            }

            annualProduction.push(0); // pre-fill to avoid gaps in array
        }

        var self = this;

        Q.all(promises).then(function (data) {
            var solarAccess = data[0];

            data = data.slice(1);

            var production = [];
            var asaPerPlane = []

            for (let i = 0; i < data.length; i++) {
                let pid = pids[i];

                /** @global */
                const planeAvg = viewshedTool.getSolarAccessAverages(pid);
                asaPerPlane.push(planeAvg.solarAccess)

                annualProduction[pid] = data[i].ac_annual;

                for (var m = 0; m < 12; m++) {
                    var kWh = Math.round(data[i].ac_monthly[m]);

                    if (production[m] === undefined)
                        production[m] = 0;

                    production[m] += kWh * 1000; // convert to watts
                }
            }

            var monthlyUsage = self.getMonthlyUsage();
            var consumption = [];

            for (var m = 0; m < 12; m++) {
                consumption.push(monthlyUsage[m] ? monthlyUsage[m] * 1000 : 0);
            }

            var prices = self.getPricePerKWH();
            var monthlyBill = monthlyUsage.map(function (kW) { return kW * prices.utility; });
            var yearlySum = sum(monthlyBill);

            var monthlyBillAfter = [];

            for (var i = 0; i < production.length; i++) {
                var usage = (monthlyUsage[i] !== undefined) ? monthlyUsage[i] : 0;
                var kW = usage - (production[i] / 1000); // production is in watts, convert to kW
                var amount = (kW > 0) ? kW * prices.utility : kW * prices.solar;

                monthlyBillAfter.push(amount);
            }

            var estimatedYearlySum    = sum(monthlyBillAfter);
            var totalAvgASA           = asaPerPlane.length > 0 ? avg(asaPerPlane) : 0;
            var annualUsage           = sum(monthlyUsage, function (x) { return parseFloat(x); });
            var annualProductionTotal = sum(annualProduction, function (x) { return parseFloat(x); });
            var systemOffset          = (annualUsage >= 0) ? annualProductionTotal / annualUsage * 100 : 100

            deferred.resolve({
                solarAccess:           solarAccess,
                annualProduction:      annualProduction,
                production:            production,
                consumption:           consumption,
                monthlyBill:           monthlyBill,
                yearlySum:             yearlySum,
                monthlyBillAfter:      monthlyBillAfter,
                estimatedYearlySum:    estimatedYearlySum,
                totalAvgASA:           totalAvgASA,
                annualUsage:           annualUsage,
                annualProductionTotal: annualProductionTotal,
                systemOffset:          systemOffset,
                systemSize:            totalkW
            });
        }).then(false, function (error) {
            if (error.message.toLowerCase().indexOf('outside the us') !== -1) {
                // NREL doesn't have data for locations outside the US
                deferred.resolve(undefined);
            } else {
                deferred.reject(error);
            }
        });

        return deferred.promise;
    }


    /**
     * @deprecated
     */
    removeArray(arrayId) {
        var ctx = this.outlineCtxs[this.planeId][arrayId];

        ctx.show(false);

        this.scene.remove(ctx.moduleArray);
        this.outlineCtxs[this.planeId].splice(arrayId, 1);
        this.createPlaneIntersections();

        this.propagateState();
    }


    /**
     * @deprecated
     */
    removeIndividualModules() {
        for (var i = 0; i < this.modules[this.planeId].length; i++)
            this.container.remove(this.modules[this.planeId][i].object);

        this.modules.splice(this.planeId, 1);
        this.propagateState();

        mouseHandler.clearCursor('mst');
    }


    /**
     * @deprecated
     */
    editArray(pid, cid, place) {
        this.state = this.states.NONE;

        var ctx = this.outlineCtxs[pid][cid];

        this.outlineCtx = ctx;

        // var ma = ctx.moduleArray

        this.propagateState();

        // if (place !== false)
        //     if (this.outlineCtx.placement === 'auto')
        //         this.placeModules(this.outlineCtx, { updateGui: false });

        mouseHandler.clearCursor('mst');
    }


    /**
     * @deprecated
     */
    toggleArrayVisibility(arrayId) {
        var moduleArray = this.outlineCtxs[this.planeId][arrayId].moduleArray;
        moduleArray.visible = !moduleArray.visible;
        return moduleArray.visible;
    }

    setLastOutlineCtx() {
        var lastIdx = this.outlineCtxs.length - 1;
        this.outlineCtx = this.outlineCtxs[lastIdx] ? this.outlineCtxs[lastIdx][0] : undefined;
    }


    mouseWheel() {
        this.showMidpointItems();

        for (let plane of this.outlineCtxs)
            for (let ctx of plane)
                ctx.scaleMarkers();

        this.scaleDimensionSprites();
    }


    mouseDown(event, mouse, panning) {
        if (!this.enabled)
            return;

        if (this.outlineCtx !== undefined && !this.outlineCtx.isComplete())
            return; // we're still drawing a polygon

        this.mouseDownPoly(event, mouse, panning);

        if (event.button === MOUSE.LEFT) {
            if (this.state === this.states.NONE) {
                let x = this.intersectModules();

                if (x) {
                    let module = x.object.parent.parent.parent;
                    this._mode.selectModule(module);

                    this.propagateState();
                }
            } else if (this.state === this.states.MODE) {
                this._mode.mouseDown(event);
            }
        }
    }


    mouseDownPoly(event, mouse, panning) {
        // are we clicking on any of the existing polygons?
        for (var pid = 0; pid < this.outlineCtxs.length; pid++) {
            for (var cid = 0; cid < this.outlineCtxs[pid].length; cid++) {
                // activate the context with a mouse event on one of its vertices
                if (this.outlineCtxs[pid][cid].mouseDown(event, mouse, panning)) {
                    this.moveVertexCtx = this.outlineCtxs[pid][cid];
                    return;
                }
            }
        }
    }


    mouseUp(event, mouse, panning) {
        // used when dxf export tool is enabled
        if (this.state === this.states.FIND_PLANE && event.button === MOUSE.LEFT) {
            if (this.highlightedContext) {
                const { pid, ctx } = this.highlightedContext;

                this.onContextSelect(pid, ctx);
                if(this.selectMode){
                    this.switchPlane(pid);
                    this.propagateState();
                }

                ctx.planeHighlight.material.opacity = 0.0;
                ctx.planeHighlight.material.visible = false;
            }

            !this.selectMode? this.state = this.states.NONE: this.state = this.states.FIND_PLANE;

        }

        if (!this.enabled)
            return;

        if (event.button === MOUSE.LEFT) {
            if (this.state === this.states.MODE && this._mode.mouseUp) {
                this._mode.mouseUp(event);
            } else if (this.moveVertexCtx) {
                // mouseup on moving vertex takes priority
                this.moveVertexCtx.mouseUp(event, mouse, panning, vSnap);

                delete this.moveVertexCtx;
            } else if (this.outlineCtx) {
                var vSnap;

                // snap to other context's vertex
                if (this.state === this.states.MANUAL) {
                    var dots = [];

                    for (let plane of this.outlineCtxs) {
                        for (let ctx of plane) {
                            dots.push(...ctx.markersSegment);
                        }
                    }

                    this.raycaster.setFromCamera(mouse, this.camera);
                    var intersects = this.raycaster.intersectObjects(dots);

                    if (intersects.length > 0)
                        vSnap = intersects[0].object.position.clone();
                }

                this.outlineCtx.mouseUp(event, mouse, panning, vSnap);
            }
        }

        if (this.keepoutCtx)
            this.keepoutCtx.mouseUp(event, mouse, panning);
    }


    /**
     * @param {(pid: number, ctx: OutlineContext) => void} onSelect Called when plane context is selected on hover.
     */
    startPlaneHighlight(selectCb) {
        this.state = this.states.FIND_PLANE;
        $('body canvas').css('cursor', 'zoom-in');
        selectCb && this.setOnContextSelect(selectCb);
    }


    startGuiHover(hoverCb) {
        hoverCb && this.setOnContextHover(hoverCb);
    }

    endGuiHover(hoverCb) {
        hoverCb && this.setOnContextHoverEnd(hoverCb);
    }



    manualSegmentHighlight(toggle,pid){
        if (toggle){
            for (var i = 0; i < this.outlineCtxs[pid].length; i++) {

                var c = this.outlineCtxs[pid][i];
                c.planeHighlight.material.opacity = 0.5;
                c.planeHighlight.material.visible = true;
                c.planeHighlight.material.depthTest = false;
                c.planeHighlight.material.depthWrite = false;
                c.isAnnotSelected = true;
                c.addAnnotation();
                this.highlightedContext = { pid: pid, ctx: c };

                //field added so this is not overwritten by the mouse move function
                this.highlightedContext.mustHighlight = true;

            }
        } else {
            for (var i = 0; i < this.outlineCtxs[pid].length; i++) {

                var c = this.outlineCtxs[pid][i];
                c.planeHighlight.material.opacity = 0.0;
                c.planeHighlight.material.visible = false;

                if ((this.planeId!= pid  &&  this.selectedMode != "All")||  this.selectedMode == "None"){
                    c.isAnnotSelected = false;
                    c.addAnnotation();
                }
            }
        }

    }

    allAnotToggle(toggle) {
        for (var pid = 0; pid < this.outlineCtxs.length; pid++) {
            for (var i = 0; i < this.outlineCtxs[pid].length; i++) {
                var c = this.outlineCtxs[pid][i];
                if (c.annotation) {
                    c.isAnnotSelected = toggle;
                    c.addAnnotation();
                }
            }
        }
    }

    mouseMove(mouse, panning, event) {
        // used when dxf export tool is enabled
        // TODO: find a more appropriate place for this
        if (this.state === this.states.FIND_PLANE && this.isInGuiHover == false) {

            this.raycaster.setFromCamera(mouse, this.camera);
            let planeHighlighted = false;

            for (var pid = 0; pid < this.outlineCtxs.length; pid++) {
                for (var i = 0; i < this.outlineCtxs[pid].length; i++) {
                    var c = this.outlineCtxs[pid][i];
                    var intersects = this.raycaster.intersectObject(c.planeHighlight, true);
                    if (intersects.length > 0) {
                        c.planeHighlight.material.opacity = 0.5;
                        c.planeHighlight.material.visible = true;
                        c.planeHighlight.material.depthTest = false;
                        c.planeHighlight.material.depthWrite = false;
                        c.isAnnotSelected = true;
                        c.addAnnotation();
                        this.highlightedContext = { pid: pid, ctx: c };
                        this.onContextHoverEnd();
                        this.onContextHover(pid);
                        planeHighlighted = true;
                    } else {
                        if (this.highlightedContext !== undefined){
                            if (this.highlightedContext.mustHighlight === undefined | !this.highlightedContext.mustHighlight){
                                c.planeHighlight.material.opacity = 0.0;
                                c.planeHighlight.material.visible = false;
                                if ((this.planeId!= pid  &&  this.selectedMode != "All") ||  this.selectedMode == "None"){
                                    c.isAnnotSelected = false;
                                    c.addAnnotation();
                               }

                            }
                            if (!planeHighlighted){
                                this.onContextHoverEnd();
                            }
                        }
                    }
                }
            }
        }

        // update dimensions/setback inputs even when inactive
        if (panning)
            this.showMidpointItems();

        if (!this.enabled)
            return;

        if (this.state === this.states.MODE) {
            this._mode.mouseMove(mouse, panning, event);
        } else {
            if (this.moveVertexCtx) {
                this.moveVertexCtx.mouseMove(mouse, panning);
            } else if (this.keepoutCtx) {
                this.keepoutCtx.mouseMove(mouse, panning, event);
            } else if (this.outlineCtx) {
                this.outlineCtx.mouseMove(mouse, panning);
            }
        }
    }

    // TODO: move material manipulation to poly context
    animateOutline() {
        var now = Date.now();
        var frames = 30;

        if (!this.planes[this.planeId])
            return;

        var az = this.planes[this.planeId].azimuth;

        if (az === undefined && now - this.lastTimeStamp >= 45) {
            this.lastTimeStamp = now;

            this.animationFrame += 1;

            if (this.outlineCtx && this.outlineCtx.isComplete()) {
                let ctx = this.outlineCtx;

                let k = (1 + Math.cos(2*Math.PI * this.animationFrame/frames)) / 2;

                ctx.material.color = new Color(0xff0066);
                ctx.material.linewidth = 3 + 7*k;
                ctx.material.opacity = 0.75 + 0.25*k;

                ctx.material.needsUpdate = true;
            }

            if (this.animationFrame >= frames)
                this.animationFrame = 0;
        }
    }


    // TODO: move material manipulation to poly context
    resetOutlineAnimation() {
        if (this.outlineCtx) {
            let ctx = this.outlineCtx;

            ctx.material.color       = new Color(0x26ec6a);
            ctx.material.linewidth   = 3;
            ctx.material.opacity     = 1;
            ctx.material.needsUpdate = true;
        }
    }


    // TODO: move material manipulation to poly context
    highlightCurrentPlane() {
        for (let plane of this.outlineCtxs) {
            for (let ctx of plane) {
                ctx.material.color       = new Color(0x2e3134);
                ctx.material.linewidth   = 3;
                ctx.material.opacity     = 1;
                ctx.material.needsUpdate = true;

                ctx.setXRayMode(false);
            }
        }

        if (this.outlineCtx) {
            let ctx = this.outlineCtx;

            ctx.material.color       = new Color(0x26ec6a);
            ctx.material.linewidth   = 3;
            ctx.material.opacity     = 1;
            ctx.material.needsUpdate = true;

            ctx.setXRayMode(true);
        }
    }


    undoKeepout() {
        let undo = this.keepoutCtx.undo();

        if (!undo)
            delete this.keepoutCtx;

        return undo;
    }


    undoOutline() {
        let undo = this.outlineCtx.undo();

        if (!undo)
            delete this.outlineCtx;

        return undo;
    }


    undoManualAdd() {
        let ma = this.outlineCtx.moduleArray;

        this.tooltip.hide();

        ma.group.remove(ma.currentModule);
        delete ma.currentModule;

        this.propagateState();
    }


    resetMode() {
        this.state = this.states.NONE;

        mouseHandler.clearCursor('mst');
    }

    canAddOutline() { return this.planes[this.planeId] !== undefined; }

    canAddKeepouts () {
        return (this.outlineCtx && this.outlineCtx.isComplete() &&
                this.planes[this.planeId].azimuth !== undefined);
    }


    onEachFrame() {
        if (!this.enabled)
            return false;

        this.animateOutline();
    }


    // get the outline of the panels for DXF export
    getModuleVertices() {
        var vv = [];

        for (let plane of this.outlineCtxs)
            for (let ctx of plane)
                if (ctx.moduleArray)
                    vv = vv.concat(ctx.moduleArray.getVertices());

        return vv;
    }


    getModuleGeometries() {
        var gg = [];

        for (let plane of this.outlineCtxs)
            for (let ctx of plane)
                if (ctx.moduleArray)
                    gg.push(...ctx.moduleArray.getGeometries());

        return gg;
    }


    getSaveData() {
        var data = {
            // user shouldn't be able to select auto segments after loading saved changes
            statusAutoSegments: this.autoSegmentsStatuses.APPLIED,
            planes:             this.planes,
            modules:            [],
            moduleArrays:       [],
            keepouts:           []
        };

        // legacy individually placed modules
        for (let pid = 0; pid < this.modules.length; pid++) {
            let modules = [];

            for (let i = 0; i < this.modules[pid].length; i++) {
                var m = this.modules[pid][i];
                var p = m.object.position.clone();

                modules.push({
                    azimuth:      m.azimuth,
                    tilt:         m.tilt,
                    moduleType:   m.moduleType,
                    manufacturer: m.manufacturer,
                    model:        m.model,
                    wattage:      m.wattage,
                    width:        m.width,
                    height:       m.height,
                    position:     { x: p.x, y: p.y, z: p.z }
                });
            }

            data.modules.push(modules);
        }

        // new style auto and manual modules
        for (var pid = 0; pid < this.outlineCtxs.length; pid++) {
            var moduleArrays = [];

            for (var cid = 0; cid < this.outlineCtxs[pid].length; cid++) {
                var ctx = this.outlineCtxs[pid][cid];

                var vertices = ctx.getVertices().map(function (v) {
                    return { x: v.x, y: v.y, z: v.z };
                });

                // TODO: move SPMA serialization to SPMA class

                var ma = ctx.moduleArray;
                var mm = [];

                // save position and specs of each module placed in manual mode
                if (ma && ctx.placement === 'manual') {
                    mm = ma.getModules().map(m => {
                        var p = m.position.clone();
                        var mo = { x: p.x, y: p.y, z: p.z };

                        if (m.sid)
                            mo.sid = m.sid;

                        return mo;
                    });
                }

                var maData = {
                    vertices:  vertices,
                    modules:   mm,
                    placement: ctx.placement
                };

                if (ma) {
                    if (ctx.placement !== 'manual') {
                        // correct for auto module arrays that don't include
                        // border into module size
                        maData.moduleSpecs.width  -= SOLAR_PANEL_MODULE_BORDER_W;
                        maData.moduleSpecs.height -= SOLAR_PANEL_MODULE_BORDER_W;
                    }

                    maData = Object.assign(maData, {
                        fireSetbacks: ma.getFireSetbacks(),
                        wStart:       ma.wStart,
                        wStep:        ma.wStep,
                        hStart:       ma.hStart,
                        hStep:        ma.hStep
                    });

                    maData.moduleSpecs = deepCopy(ma.moduleSpecs);

                    if (Object.keys(ma.getAddedSpecs()).length > 0)
                        maData.addedSpecs = ma.getAddedSpecs();
                } else {
                    maData.moduleSpecs = deepCopy(this.moduleSpecs[pid][0]);
                }

                moduleArrays.push(maData);
            }

            data.moduleArrays.push(moduleArrays);
        }

        var keepouts = this.getKeepoutInfos();

        for (let pid = 0; pid < keepouts.length; pid++) {
            for (let kid = 0; kid < keepouts[pid].length; kid++) {
                let k           = keepouts[pid][kid];
                let ctx = this.keepoutCtxs[pid][kid];

                let n = ctx.normal;

                k.normal = { x: n.x, y: n.y, z: n.z };
                k.vertices = ctx.getVertices().map((v) => {
                    return { x: v.x, y: v.y, z: v.z }
                });
            }
        }

        data.keepouts = keepouts;

        data.systemParams = this.systemParams;
        data.fireSetbacks = this.fireSetbacks.data;
        data.parapets     = this.parapets.data;

        var deferred = Q.defer();
        var self = this;

        let solarAccessPIDs = [];

        Promise.all(
            [this.getProductionValues(), Net.getDecimatorUrl()]
        ).then(function ([response, decimatorUrl]) {
            if (response === undefined) {
                data.numbers = {
                    systemSize:             0,
                    systemOffset:           0,
                    totalAvgASA:            0,
                    totalAnnualConsumption: 0,
                    totalAnnualProduction:  0,
                    avgMonthlySolarAccess:  [],
                    monthlyConsumption:     [],
                    monthlyProduction:      [],
                };

                deferred.resolve(data);
            }

            // raw numbers
            data.numbers = {
                systemSize:             response.systemSize,
                systemOffset:           response.annualUsage > 0 ? response.systemOffset : 0,
                totalAvgASA:            response.totalAvgASA,
                totalAnnualConsumption: response.annualUsage,
                totalAnnualProduction:  response.annualProductionTotal,
                avgMonthlySolarAccess:  response.solarAccess.averages,
                monthlyConsumption:     response.consumption,
                monthlyProduction:      response.production,
            };

            // numbers formatted as strings for consistent formatting between viewer and pdf report
            data.systemSize          = response.systemSize.toFixed(2);
            data.systemOffset        = response.annualUsage > 0 ? response.systemOffset.toFixed(0) : '0';
            data.estimatedProduction = formatK(response.annualProductionTotal.toFixed(0));
            data.yearlyBillBefore    = formatK(response.yearlySum);
            data.avgBillBefore       = formatK(response.yearlySum / 12);
            data.yearlyBillAfter     = formatK(response.estimatedYearlySum);
            data.avgBillAfter        = formatK(response.estimatedYearlySum / 12);

            var promises = [];

            var graphConsProd = GRAPH.monthly([ response.consumption, response.production ], {
                color:       [ '#a6a6a6', '#27E2A4' ],
                format:      function (x) { return d3.format('s')(x) + 'Wh'; },
                fontSize:    4,
                barWidth:    13,
                aspect:      2,
                paddingLeft: 18,
                labelFormat: d3.format('.3s')
            });

            var graphKey = self.projectId + '/graphs/consumption-production-' +
                           new Date().getTime() + '.svg';

            promises.push(Net.postJSON(decimatorUrl + '/s3', {
                key:          graphKey,
                data:         graphConsProd.node().outerHTML,
                content_type: 'image/svg+xml'
            }));

            var graphBillBefore = GRAPH.monthly(response.monthlyBill, {
                color:       '#a6a6a6',
                aspect:      2,
                barWidth:    13,
                fontSize:    5,
                format:      d3.format('$s'),
                paddingLeft: 15
            });

            var graphKey = self.projectId + '/graphs/electric-bill-before-' +
                           new Date().getTime() + '.svg';

            promises.push(Net.postJSON(decimatorUrl + '/s3', {
                key:          graphKey,
                data:         graphBillBefore.node().outerHTML,
                content_type: 'image/svg+xml'
            }));

            var graphBillAfter = GRAPH.monthly(response.monthlyBillAfter, {
                color:       '#27E2A4',
                aspect:      2,
                barWidth:    13,
                fontSize:    5,
                format:      d3.format('$s'),
                paddingLeft: 15
            });

            var graphKey = self.projectId + '/graphs/electric-bill-after-' +
                           new Date().getTime() + '.svg';

            promises.push(Net.postJSON(decimatorUrl + '/s3', {
                key:          graphKey,
                data:         graphBillAfter.node().outerHTML,
                content_type: 'image/svg+xml'
            }));

            for (let pid = 0; pid < response.solarAccess.planes.length; pid++) {
                let monthly = response.solarAccess.planes[pid];

                if (monthly.length === 12) {
                    solarAccessPIDs.push(pid);

                    let graphSolarAccess = GRAPH.monthly(monthly, {
                        color:       '#1C48F2',
                        type:        'line',
                        compress:    true,
                        format:      function (x) { return d3.format('s')(x) + '%'; },
                        paddingLeft: 13,
                        fontSize:    4
                    });

                    let graphKey = self.projectId + '/graphs/solar-access-' + pid + '-' +
                        new Date().getTime() + '.svg';

                    promises.push(Net.postJSON(decimatorUrl + '/s3', {
                        key:          graphKey,
                        data:         graphSolarAccess.node().outerHTML,
                        content_type: 'image/svg+xml'
                    }));
                }
            }

            return Q.all(promises);
        }).then(function ([consProd, before, after, ...solarAccessGraphs]) {
            data.consumptionProductionGraph = consProd.url;
            data.electricBillBefore         = before.url;
            data.electricBillAfter          = after.url;

            data.solarAccessGraphs = [];

            for (let i = 0; i < solarAccessGraphs.length; ++i) {
                data.solarAccessGraphs[solarAccessPIDs[i]] = solarAccessGraphs[i].url;
            }

            deferred.resolve(data);
        }).catch(function (error) {
            deferred.reject(error);
        });

        return deferred.promise;
    }


    restoreSaveData(data) {
        this.statusAutoSegments = data.statusAutoSegments || this.autoSegmentsStatuses.NOT_SELECTED;

        if(data.planes) {
            this.planes = [];

            for (let pid = 0; pid < data.planes.length; pid++) {
                // save data consistency/sanity check
                if (data.moduleArrays[pid] || data.modules[pid]) {
                    this.planes.push(data.planes[pid]);
                }
            }
        }

        var modules = (data.modules !== undefined) ? data.modules : data;

        this.modules = [];

        for (var planeId = 0; planeId < modules.length; planeId++) {
            this.modules[planeId] = [];

            for (var i = 0; i < modules[planeId].length; i++) {
                this.placeSingleModule(planeId, modules[planeId][i]);
            }
        }

        if (data.systemParams)
            this.systemParams = data.systemParams;

        if (data.fireSetbacks) {
            // legacy setbacks are stored as array
            let setbacks = data.fireSetbacks;

            if (Array.isArray(setbacks)) {
                this.fireSetbacks = new KeyStore();

                for (let pid = 0; pid < setbacks.length; pid++) {
                    for (let i = 0; i < setbacks[pid].length; i++) {
                        this.fireSetbacks.set([ pid, i ], setbacks[pid][i]);
                    }
                }
            } else {
                this.fireSetbacks = new KeyStore(setbacks);
            }
        }

        if (data.parapets)
            this.parapets = new KeyStore(data.parapets);

        if (data.moduleArrays !== undefined) {
            for (var pid = 0; pid < data.moduleArrays.length; pid++) {
                var dma = data.moduleArrays[pid];

                if (this.outlineCtxs[pid] === undefined)
                    this.outlineCtxs[pid] = [];

                if (this.moduleSpecs[pid] === undefined)
                    this.moduleSpecs[pid] = [];

                for (var i = 0; i < dma.length; i++) {
                    var ctx = this.createOutlineCtx(dma[i].modules && dma[i].modules.length);

                    ctx.label = data.planes[pid].name;
                    ctx.initFromVertices(dma[i].vertices.map(function (v) {
                        return new Vector3(v.x, v.y, v.z);
                    }));

                    ctx.placement = dma[i].placement;
                    ctx.enableArrowWidget = (dma[i].modules && dma[i].modules.length == 0);






                    // [tag:autoSegments]
                    // TODO: move this to create outline ctx?
                    // use the plane flag when creating ctx to hide it by default?
                    if (this.planes[pid].auto) {
                        ctx.container.visible = false;
                        ctx.hasAnnotation = false;
                        ctx.pid = pid;
                    }






                    // TODO: is setting azimuth on the context optional?
                    if (data.planes && data.planes[pid]) {
                        ctx.setAzimuth(data.planes[pid].azimuth);
                    }

                    let ms = dma[i].moduleSpecs;

                    if (!ms) {
                        // fall back to defaults if specs are missing for whatever reason
                        ms = deepCopy(this.defaultSpecs);
                    }

                    // bug in previous version saved snap in module specs
                    if (ms.snap)
                        delete ms.snap;

                    if (this.moduleSpecs === undefined)
                        this.moduleSpecs = [];

                    if (this.moduleSpecs[pid] === undefined)
                        this.moduleSpecs[pid] = [];

                    this.moduleSpecs[pid][0] = ms;

                    if (dma[i].placement !== 'manual') {
                        // correct for auto module arrays that don't include
                        // border into module size
                        ms.width  += SOLAR_PANEL_MODULE_BORDER_W;
                        ms.height += SOLAR_PANEL_MODULE_BORDER_W;
                    }

                    if (ms.type && !ms.moduleType)
                        ms.moduleType = ms.type;

                    if (dma[i].placement === 'manual') {
                        if (dma[i].modules && dma[i].modules.length > 0) {
                            var ma = new SolarPanelModuleArray({
                                scene:        this.scene,
                                meshes:       this.meshes,
                                raycaster:    this.raycaster,
                                moduleSpecs:  ms,
                                addedSpecs:   dma[i].addedSpecs,
                                fireSetbacks: this.fireSetbacks.get([ pid, i ], []),
                                wStart:       dma[i].wStart,
                                wStep:        dma[i].wStep,
                                hStart:       dma[i].hStart,
                                hStep:        dma[i].hStep
                            });

                            this.scene.add(ma);

                            ma.setCommonSpecs(ms);

                            ma.placeSaveData({
                                modules:  dma[i].modules,
                                fitPlane: ctx.fitPlane,
                                origin:   ctx.getOrigin(),
                                vertices: ctx.planeFromSegments()
                            });

                            ctx.moduleArray = ma;
                        }
                    } else {
                        this.planeId = pid

                        let tilt = dma[i].moduleSpecs.tilt
                        let az   = dma[i].moduleSpecs.azimuth

                        if (this.planes[pid] === undefined)
                            this.planes[pid] = {};

                        this.setAzimuthAndTilt(az, tilt);

                        this.placeModules(ctx, {
                            moduleSpecs:  ms,
                            fireSetbacks: this.fireSetbacks.get([ pid, i ], [])
                        });
                    }

                    this.outlineCtxs[pid].push(ctx);
                }

                // Create default empty array so we can key into keepoutCtxs with planeId later
                this.keepoutCtxs[pid] = []

                if(dma.length === 0 && this.moduleSpecs[pid]) {
                    this.moduleSpecs[pid][0] = this.defaultSpecs;
                }
            }
        }

        if (data.keepouts) {
            for (let pid = 0; pid < data.keepouts.length; pid++) {
                let ctxs = [];

                for (let kid = 0; kid < data.keepouts[pid].length; kid++) {
                    let k = data.keepouts[pid][kid];
                    let n = k.normal;

                    k.normal = new Vector3(n.x, n.y, n.z);
                    k.vertices = k.vertices.map(v => new Vector3(v.x, v.y, v.z));
                    k.restore = true;

                    try {
                        ctxs.push(this.addKeepout(k.shape || 'poly', k));
                    } catch (err) {
                        // few times keepouts had coordinates with very large values in them for unknown reason
                        // NOTE: this is supposed to be a one-off, do not repeat elsewhere
                        if (err instanceof ErrorPointsNotOnPlane) {
                            console.log(`Skipping loading kid ${kid}, pid ${pid} due to exception...`);

                            if (window.Sentry !== undefined) {
                                Sentry.captureException(err);
                            }
                        } else {
                            throw err;
                        }
                    }
                }

                this.keepoutCtxs[pid] = ctxs;
            }
        }

        this.updateDimensions();
    }
}


export { ModuleSpecsTool };
