import tippy from 'tippy.js';
import { dxfExportTool, shadeTool, viewshedTool, project, moduleSpecsTool, modelImportTool, prompts } from "../Viewer";
import { prepareDownload } from "../gui/Gui";

import * as html from '../../templates/GuiExports/CAD.hbs';

class ExportCADGUI {
    /**
     * @param {object} options
     * @param {import('./GuiStatusIndicator').StatusIndicatorGui} options.statusIndicator
     */
    constructor(options) {
        this.name = 'CAD';
        this.selector = '.cad-exports';
        this.statusIndicator = options.statusIndicator;
        this.html = html;
    }

    onExportPlane() {
        moduleSpecsTool.startPlaneHighlight(async (pid, ctx) => {
            this.statusIndicator.pushStatus('Use the mouse to select a plane.');
            const opt = {
                ...ctx.fitPlane, // centroid,normal
                project,
                outline:        ctx,
                details:        project.details,
                keepouts:       moduleSpecsTool.getKeepoutsInfoForPlane(pid),
                projectName:    project.name,
                roofPlaneInfo:  viewshedTool.getRoofPlaneInfo()[pid],
                importedModels: modelImportTool.getModelsInfo()
            };

            this.prepareDownloadFactory(
                '.dxf-export-plane',
                'DXF for selected plane',
                () => dxfExportTool.exportParallelPlane(opt)
            )();
        });
    }

    /** @type {(lat: number, zoom: number) => number} */
    metersPerPixel(lat, zoom) {
        const mapParams = shadeTool.getMapParams();
        return shadeTool.metersPerPixel(mapParams.lat, mapParams.zoom);
    };

    getDxfExportOptions() {
        const outlines = Array.isArray(moduleSpecsTool.outlineCtxs)
            ? moduleSpecsTool.outlineCtxs.flat()
            : [];

        // if no keepouts, empty list of keepouts for each outline
        const keepouts = Array.isArray(moduleSpecsTool.keepoutCtxs)
            ? moduleSpecsTool.keepoutCtxs
            : outlines.map(() => []);

        return {
            outlines,
            keepouts,
            metersPerPixel: this.metersPerPixel,
            imgDataUrl: shadeTool.imgDataUrl,
            orthoDataUrl: project.details.orthomosaicUrl,
            groundSize: shadeTool.groundSize,
            moveObjectPosition: shadeTool.moveObject.position,
            details: project.details,
            projectName: project.name,
            projectType: project.type,
            project,
            modules: moduleSpecsTool.getModuleVertices(),
            importedModels: modelImportTool.getModelsInfo(),
            roofPlaneInfo: viewshedTool.getRoofPlaneInfo()
        };
    }

    getPrepareDownloadCbs(exportName) {
        return {
            onStart: () => {
                this.statusIndicator.pushStatus(`Downloading ${exportName}...`);
                this.statusIndicator.setIndeterminate();
            },
            onError: (err) => {
                this.statusIndicator.pushError(
                    new Error(`An error occurred when downloading ${exportName}`)
                );
                prompts.info(
                    `<p>An error occurred when downloading the ${exportName}.</p>
                     <p>Please contact support for assistance.</p>`
                );
                console.error(err);
            },
            onSuccess: () => {
                this.statusIndicator.pushStatus(`${exportName} created successfully`);
                this.statusIndicator.deferClearProgress(1000);
            },
            onSettled: () => this.statusIndicator.deferClearProgress()
        };
    }

    prepareDownloadFactory(selector, type, download) {
        return () => {
            const { onStart, onSuccess, onError, onSettled } = this.getPrepareDownloadCbs(type);
            prepareDownload(selector, onStart, download, onSuccess, onError, onSettled);
        }
    }

    onExportOverhead = this.prepareDownloadFactory(
        '.dxf-export-overhead',
        '2D Wireframe DXF',
        () => dxfExportTool.exportOverhead(this.getDxfExportOptions())
    );

    onExportAllPlanes = this.prepareDownloadFactory(
        '.dxf-export-all-planes',
        'DXF for all planes',
        () => dxfExportTool.exportAllPlanes(this.getDxfExportOptions())
    );

    onExport3D = this.prepareDownloadFactory(
        '.dxf-export-3d',
        '3D Wireframe DXF',
        () => dxfExportTool.export3DFile(this.getDxfExportOptions())
    );

    getStlObjColladaExportOptions() {
        return {
            planes:         moduleSpecsTool.outlineCtxs,
            modules:        moduleSpecsTool.getModuleGeometries(),
            importedModels: modelImportTool.getModelsInfo(),
            keepouts:       moduleSpecsTool.keepoutCtxs
        };
    }

    onExportStl = this.prepareDownloadFactory(
        '.surface-export-stl',
        'STL',
        () => dxfExportTool.exportBinaryStl(this.getStlObjColladaExportOptions())
    );

    onExportObj = this.prepareDownloadFactory(
        '.surface-export-obj',
        'OBJ',
        () => dxfExportTool.exportObj(this.getStlObjColladaExportOptions())
    );

    onExportCollada = this.prepareDownloadFactory(
        '.surface-export-collada',
        'DAE',
        () => dxfExportTool.exportCollada(this.getStlObjColladaExportOptions())
    );

    init() {
        $('body').on('click', '.dxf-export-3d',          () => { this.onExport3D() });
        $('body').on('click', '.dxf-export-overhead',    () => { this.onExportOverhead(); });
        $('body').on('click', '.dxf-export-plane',       () => { this.onExportPlane(); });
        $('body').on('click', '.dxf-export-all-planes',  () => { this.onExportAllPlanes(); });
        $('body').on('click', '.surface-export-stl',     () => { this.onExportStl(); });
        $('body').on('click', '.surface-export-obj',     () => { this.onExportObj(); });
        $('body').on('click', '.surface-export-collada', () => { this.onExportCollada(); });
    }

    renderState() {
        const exportButtons = $('.export-btn');
        const exportButtonsDisabled = moduleSpecsTool.getPlanesLength() === 0;
        exportButtons.prop("disabled", exportButtonsDisabled);
        exportButtons.attr("aria-disabled", exportButtonsDisabled.toString());
        exportButtonsDisabled ? exportButtons.addClass("disabled-exports") : exportButtons.removeClass("disabled-exports");
        if (exportButtonsDisabled) {
            const tip = "Please add roof segments to download CAD files";
            tippy('.input-wrapper', { content: tip });
        }
    }
}

export { ExportCADGUI }
