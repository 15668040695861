




function volumeTool(callback) {
    var template = [
        '<h3 class="header volume-tool">Volume Measurement</h3>',
        '<div class="tool-options">',
            '<div class="option">',
                '<div class="label">',
                    'Volume:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<span class="volume-tool-volume">0.00</span>',
                '</div>',
                '<div class="input-wrapper second">',
                    '<select class="input-volume-units" name="volume units">',
                        '<option value="meters">meters &sup3;</option>',
                        '<option value="yards">yards &sup3;</option>',
                    '</select>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',

            //'<div class="option">',
            //    '<div class="label">',
            //        'Plane placement:',
            //    '</div>',
            //    '<div class="input-wrapper first">',
            //        '<select class="input-plane-placement">',
            //            '<option value="bestfit">Best Fit</option>',
            //            '<option value="lowest">Lowest Point</option>',
            //        '</select>',
            //    '</div>',
            //    '<div class="clearfix"></div>',
            //'</div>',

            '<div class="option">',
                '<div class="label">',
                    '&nbsp;',
                '</div>',
                '<div class="input-wrapper first">',
                    '<button class="sy input-volume-clear"><i class="fa fa-times" aria-hidden="true"></i> Clear</button>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',
        '</div>'
    ].join("\n");
    $('.placeholder.volume-tool').replaceWith(template);
    $('.header.volume-tool').click(callback);
};

function volumeToolUnits(value, callback) {
    $('.input-volume-units').val(value);

    if (callback) {
        $('.input-volume-units').change(function () {
            callback($(this).val());
        });
    }
};

function volumeToolClear(callback) {
    $('.input-volume-clear').click(callback);
};

function volumeToolVolume(value) {
    $('.volume-tool-volume').html(value);
};

export { volumeTool, volumeToolUnits, volumeToolClear, volumeToolVolume }
