import { deallocateObject } from '../libs/Utilities';
import { camera, mouseHandler, controls } from '../Viewer.js';
import { Tooltip } from '../libs/Tooltip';
import { CameraRotator } from '../libs/CameraRotator';
import * as THREE from '../libs/three.module';





class AnnotationsTool extends CameraRotator {

    constructor() {
        super();

        this.mouseButton = THREE.MOUSE.LEFT;

        this.annotations = [];
        this.popup = -1;

        this.currentRoofPlane = null;
        this.leftAmount = 4;
        this.upAmount = 4;
        this.hide = false;

        this.objects = [];
    }


    setCamera          (camera)    { this.camera = camera; }
    setRaycaster       (rc)        { this.raycaster = rc; }
    addObject          (object)    { this.objects.push(object); }
    setContainerObject (container) { this.container = container; }
    getAnnotations     ()          { return this.annotations }
    setCurrentPlane    (pid)       { this.currentRoofPlane = pid }
    enable             ()          { this.enabled = true; }


    disable () {
         this.enabled = false;

         for (let a of this.annotations) {
             a.active = false;
         }

         this.propagateState();
    }


    propagateState() {}

    registerPropagateState(callback) { this.propagateState = callback; }


    getState() {
        let s = {
            annotations: this.annotations.map((a) => {
                if (a.active)
                    a.popupPos = this.getScreenCoordinates(a);

                return a;
            }),
            popupOnly: this.popupOnly
        };

        this.popupOnly = false;

        return s;
    }


    updateState(s) {
        for (let i = 0; i < s.annotations.length; i++) {
            let a = s.annotations[i];

            this.annotations[i].subject     = a.subject;
            this.annotations[i].description = a.description;
            this.annotations[i].dirty       = a.dirty;
        }
    }


    removeAnnotation(id) {
        this.container.remove(this.annotations[id].sprite);

        this.annotations.splice(id, 1);

        // re-number annotation sprites
        for (let i = 0; i < this.annotations.length; i++) {
            let a = this.annotations[i];

            this.container.remove(a.sprite);
            a.sprite = this.createTooltip(a.position, i + 1);
            this.container.add(a.sprite);

            this.scaleMarkerSprite(a);
        }

        this.propagateState();
    }


    removeAnnotations() {
        this.annotations = []
        this.propagateState();
    }


    removeViewshedPlane(planeId) {
        this.annotations = this.annotations.filter( a => {
            return a.pid !== planeId
        });

        this.annotations.forEach( a => {
            if( a.pid > planeId) {
                a.pid = a.pid - 1
            }
        });
    }


    getScreenCoordinates(annotation) {
        var width = window.innerWidth, height = window.innerHeight;
        var widthHalf = width / 2, heightHalf = height / 2;

        var pos = this.container.localToWorld(annotation.position.clone());
        pos.project(this.camera);
        this.raycaster.setFromCamera(pos, this.camera);

        pos.x =  (pos.x * widthHalf)  + widthHalf;
        pos.y = -(pos.y * heightHalf) + heightHalf;

        return pos;
    }


    mouseDown(event, mouse, panning) {
        if (!this.enabled || event.button !== THREE.MOUSE.LEFT || panning)
            return;

        for (let a of this.annotations)
            a.active = false;

        this.raycaster.setFromCamera(mouse, this.camera);

        var sprites = this.annotations.map(a => a.sprite);
        var intersects = this.raycaster.intersectObjects(sprites, true);

        if (intersects.length > 0) {
            let sprite = intersects[0].object;
            let a = this.annotations[sprites.indexOf(sprite.parent)];

            a.active = true;

            this.moveCameraAngle(a.camera.left, a.camera.up);
        } else {
            var meshes = this.objects

            if(this.moduleSpecsMeshes) {
                // Only include module meshes for viewshed annotations
                var moduleMeshes = this.moduleSpecsMeshes();
                meshes = this.objects.concat(moduleMeshes)
            }

            var intersects = this.raycaster.intersectObjects(meshes, true);

            if (intersects.length === 0)
                return;

            var position = this.container.worldToLocal(intersects[0].point.clone());

            position.add(new THREE.Vector3(0, 0.3, 0));

            var sprite = this.createTooltip(position, this.annotations.length + 1);
            this.container.add(sprite);

            this.annotations.push({
                position:     position,
                sprite:       sprite,
                camera:       { left: controls.getAzimuthalAngle(), up: controls.getPolarAngle() },
                subject:      '',
                description:  '',
                name:         '',
                height:       '',
                width:        '',
                pid:          this.currentRoofPlane,
                dirty:        true
            });

            this.scaleMarkerSprite(this.annotations[this.annotations.length - 1]);
        }

        this.propagateState();
    }


    mouseMove(mouse, panning) {
        if (!this.enabled)
            return;

        if (panning) {
            this.popupOnly = true;
            this.propagateState();
            return;
        }

        let sprites = this.annotations.map(a => a.sprite);

        this.raycaster.setFromCamera(mouse, this.camera);
        var xs = this.raycaster.intersectObjects(sprites, true);

        mouseHandler.setCursor('pointer', 'at', xs.length > 0);
    }


    createTooltip(pos, text) {
        let t = new Tooltip({
            radius: 16,
            offset: 0
        });

        t.setTextCircle(' ' + text + ' ');
        t.show();

        var sprite = t.getSprite();
        sprite.position.copy(pos);

        return sprite;
    }


    mouseWheel() {
        for (let a of this.annotations)
            this.scaleMarkerSprite(a);

        this.popupOnly = true;
        this.propagateState();
    }


    scaleMarkerSprite(a) {
        let d = camera.position.distanceTo(new THREE.Vector3(0, 0, 0));
        let k = d * 0.015;

        a.sprite.children.forEach(c => c.scale.set(k, k, 1));
    }


    handleKeyboard(keyboard) { }


    getSaveData() {
        var annotations = [];

        for (var i = 0; i < this.annotations.length; i++) {
            annotations.push({
                position: {
                    x: this.annotations[i].position.x,
                    y: this.annotations[i].position.y,
                    z: this.annotations[i].position.z
                },
                camera:      this.annotations[i].camera,
                subject:     this.annotations[i].subject,
                description: this.annotations[i].description,
                name:        this.annotations[i].name || null,
                width:       this.annotations[i].width || null,
                height:      this.annotations[i].height || null,
                pid:         this.annotations[i].pid
            });
        }

        return annotations;
    }


    restoreSaveData(data) {
        this.popup = -1;

        for (var i = 0; i < this.annotations.length; i++) {
            this.container.remove(this.annotations[i].cube);
            deallocateObject(this.annotations[i].cube);
        }

        this.annotations = [];

        for (var i = 0; i < data.length; i++) {
            var position = new THREE.Vector3(data[i].position.x, data[i].position.y, data[i].position.z);
            var sprite = this.createTooltip(position, i + 1);
            this.container.add(sprite);

            this.annotations.push({
                position:     position,
                sprite:       sprite,
                camera:       data[i].camera,
                subject:      data[i].subject,
                description:  data[i].description,
                name:         data[i].name,
                width:        data[i].width,
                height:       data[i].height,
                pid:          data[i].pid,
                dirty:        false
            });
        }

        for (let a of this.annotations)
            this.scaleMarkerSprite(a);

        this.propagateState();
    }
}


export { AnnotationsTool };
