/// @ts-check

import { metersToInches } from '../alibs/Units';
import { ColladaExporter } from '../libs/ColladaExporter';
import { Net } from '../libs/Net';
import { kUnitVector, iUnitVector, jUnitVector, radToDeg } from '../libs/Geometry';
import { SolarPanelModuleArray } from '../elements/SolarPanelModuleArray';
import { MeasurementUnits } from './MeasurementsTool';
import { Camera, Group, Line3, Matrix4, Mesh, Object3D, Quaternion, Line as ThreeLine, MeshBasicMaterial, Vector3 } from '../libs/three.module';
import{ CircleContext } from '../draw/CircleContext';
import{ PolyContext } from '../draw/PolyContext';
import{ LineContext } from '../draw/LineContext';

import { Config } from '../../bootstrap/Config';
import { currentDesign, project } from '../Viewer';
import { browserDownloadUrl, browserDownload, shouldStoreExportsForPublicAPI, downloadAndArchiveForPublicAPI } from '../libs/Utilities';


/**
 * These typedefs are here because
 *
 * @typedef {[Vector3, Vector3, string]} DxfLineDatum
 * @typedef {(CircleContext | PolyContext | LineContext)} Keepout
 *
 * @typedef {Object} ImportedModel
 * @property {[(Object3D & {children: Mesh[]}), ...Object3D[]]} children
 *
 * @typedef {Object} Outline
 * @property {SolarPanelModuleArray?} moduleArray
 * @property {Line3[]} line3s
 *
 * @typedef {Outline & PolyContext} OutlineContext
 */

class DxfExportTool {
    /**
     *
     * @param {import('three').Camera} camera
     * @param {OrbitControls} controls;
     */
    constructor(camera, controls) {
        this.camera = camera;
        this.controls = controls;

        this.units = MeasurementUnits.FEET_INCHES;

        // Overhead projection camera: target (0, -100, 0) orthogonal to x-z plane
        this.overheadProjectionMatrix = this.getProjectionMatrix(
            jUnitVector.clone().multiplyScalar(-100),
            jUnitVector,
            true
        );

        Object.freeze(this.overheadProjectionMatrix);
    }

    getState()              { return { units: this.units }; }
    updateState(s)          { this.units = s.units; }

    /**
     *
     * @param {Vector3} centroid Centroid of plane to project.
     * @param {Vector3} normal   Normal vector to plane to project.
     * @param {boolean} northUp  Whether projection should be rotated to orient top of drawing to N.
     *
     * @returns {Matrix4}
     */
    getProjectionMatrix(centroid, normal, northUp = false) {
        // Push current camera frame
        const p = this.controls.object.position.clone();
        const t = this.controls.target.clone();

        // Reposition camera orthogonal to and facing facet
        const orthographicCameraLocation = centroid.clone().add(
            normal.clone().multiplyScalar(10) // 10 == "number that empirically works well"
        );

        this.controls.object.position.copy(orthographicCameraLocation);
        this.controls.target.copy(centroid);

        if (northUp) {
            this.controls.rotateLeft(Math.PI);
        }

        this.controls.update();
        this.camera.updateMatrixWorld();

        /** @type {Matrix4} */
        const transformMatrix = (new Matrix4())
            .makeOrthographic(-1, 1, 1, -1, 1, 200)
            .multiply(this.camera.matrixWorldInverse);

        // Pop camera frame
        this.controls.target.copy(t);
        this.controls.object.position.copy(p);

        this.controls.update();
        this.camera.updateMatrixWorld();

        return transformMatrix;
    }

    /**
     * Given a collection of line segments representing a roof facet outline,
     * extract the two vertices of lowest elevation. This represents the eave
     * if the facet has one.
     *
     * Otherwise, it represents the closest thing to an eave that the facet has.
     *
     * @param {OutlineContext} outline
     * @returns {Line3}
     */
    getVirtualEaveForSegment(outline)
    {
        const verticesByElevation = this.uniq(
            outline.line3s.flatMap(l => [l.start, l.end]),
            (v1, v2) => v1.equals(v2)
        ).sort(
            (v1, v2) => v1.y - v2.y
        );

        return new Line3().set(
            verticesByElevation[0],
            verticesByElevation[1]
        );
    }

    /**
     * Given a transformation matrix for (probably) an orthographic plane projection
     * of a roof facet, and an eave line (or virtual eave, if the facet lacks one),
     * return a *new* transformation matrix that applies isometric transformations
     * (rotation and translation) to the given projection to fix the lowest point of
     * the eave to its corresponding position in the *overhead* projection and the
     * direction of the eave to correspond to its direction in the overhead projection.
     *
     * @param {Matrix4} matrix
     * @param {Line3} eave
     *
     * @returns {Matrix4}
     */
    updateTransformToFixEaveToOverhead(matrix, eave)
    {
        const eaveOverhead = eave.clone().applyMatrix4(this.overheadProjectionMatrix);
        const eaveOrtho = eave.clone().applyMatrix4(matrix);

        const eaveDirOverhead = eaveOverhead.delta(new Vector3()).projectOnPlane(kUnitVector).normalize();
        const eaveDirOrtho = eaveOrtho.delta(new Vector3()).projectOnPlane(kUnitVector).normalize();

        /**
         * @type {Quaternion}
         * Determine rotation we need to apply so direction of eave in ortho projection
         * matches direction in overhead projection.
         */
        const fixRotation = (new Quaternion()).setFromUnitVectors(eaveDirOrtho, eaveDirOverhead);

        /**
         * @type {Vector3}
         * Determine translation we need to apply after rotation so lowest facet
         * vertex position in ortho projection matches position in overhead projection.
         */
        const coordOffset = eaveOverhead.start.clone().sub(
            eaveOrtho.start.clone().applyQuaternion(fixRotation)
        ).projectOnPlane(
            kUnitVector
        );

        return (new Matrix4()).compose(
            coordOffset,
            fixRotation,
            new Vector3().setScalar(1)
        ).multiply(
            matrix
        );
    }

    /// @todo Most of these geometry positions can probably be DRYd into a common function.
    /**
     * @param {{segments: ThreeLine[]}[]} outlines
     * @param {Matrix4} matrix
     *
     * @returns {DxfLineDatum[]}
     */
    getProjectedDxfOutlines(outlines, matrix)
    {
        /** @type {DxfLineDatum[]}  */
        let projectedOutlines = [];

        for (let ctx of outlines || []) {
            projectedOutlines = projectedOutlines.concat(
                ctx.segments.map(
                    (segment) => {
                        /** @type {[Vector3, Vector3]} vertices */
                        /// @ts-ignore exists in our current version of three.js
                        const vertices = segment.geometry.vertices;

                        return [
                            vertices[0].clone().applyMatrix4(matrix),
                            vertices[1].clone().applyMatrix4(matrix),
                            "outlines"
                        ];
                    }
                )
            )
        }

        return this.uniq(projectedOutlines, this.lComp);
    }

    /**
     *
     * @param {OutlineContext[]} outlines
     * @param {Matrix4} matrix
     *
     * @returns {[Vector3, Vector3, string][]}
     */
    getProjectedDxfSetbacks(outlines, matrix) {
        if (!outlines) {
            return [];
        }

        /** @type {[Vector3, Vector3, "setbacks"][]} */
        let projectedSetbacks = []

        for (const ctx of outlines.filter(ctx => ctx.moduleArray)) {
            for (const l of ctx.moduleArray.getSetbackLines()) {

                /** @type {[Vector3, Vector3]} vertices */
                /// @ts-ignore exists in our current version of three.js
                const vertices = l.geometry.vertices;

                projectedSetbacks.push([
                    vertices[0].clone().applyMatrix4(matrix),
                    vertices[1].clone().applyMatrix4(matrix),
                    "setbacks"
                ]);
            }
        }

        return projectedSetbacks;
    }

    /**
     * @typedef {[Vector3, Vector3, Vector3, Vector3, Vector3, Vector3]} ModuleGeometry
     * @param {ModuleGeometry[]?} modules
     * @param {Matrix4} matrix
     */
    getProjectedDxfModules(modules, matrix) {

        /** @type {[Vector3, Vector3, "modules"][]} */
        let outputLines = [];

        for (const module of modules || []) {
            /** @type {ModuleGeometry} projectedModule */
            /// @ts-ignore
            const projectedModule = module.map(v => v.clone().applyMatrix4(matrix));

            outputLines.push([projectedModule[0], projectedModule[1], "modules"]);
            outputLines.push([projectedModule[1], projectedModule[2], "modules"]);
            outputLines.push([projectedModule[4], projectedModule[5], "modules"]);
            outputLines.push([projectedModule[5], projectedModule[3], "modules"]);
        }

        return outputLines;
    }

    /**
     *
     * @param {(CircleContext|PolyContext)[]?} keepouts
     * @param {Matrix4} matrix
     */
    getProjectedKeepouts(keepouts, matrix)
    {
        /** @type {DxfLineDatum[]} */
        let lines = [];

        for (const context of keepouts || []) {
            lines.push(
                ...context.getLines().map(
                    /** @returns {DxfLineDatum} */
                    l => [
                        l[0].clone().applyMatrix4(matrix),
                        l[1].clone().applyMatrix4(matrix),
                        "keepouts"
                    ]
                )
            );
        }

        return lines;
    }

    /**
     *
     * @param {Matrix4} matrix Transformation matrix to use when exporting plane geometries.
     * @param {ExportOptions} options
     */
    getDecimatorPlaneGeometries(matrix, options) {
        return this.getProjectedDxfOutlines(options.outlines, matrix).concat(
            this.getProjectedDxfSetbacks(options.outlines, matrix)
        ).concat(
            this.getProjectedDxfModules(options.modules, matrix)
        ).concat(
            this.getLinesImportedModels(options.importedModels, matrix)
        ).concat(
            this.getProjectedKeepouts(options.keepouts, matrix)
        );
    }

    /**
     * Apply {matrix} to collections in {options} and return a specification
     * understood by the decimator API.
     *
     * @todo The {options} hash is clunky and inelegant.
     *
     * @param {Matrix4} matrix
     * @param {ExportOptions & {segmentName?: string}} options
     * @returns
     */
    getDxfData(matrix, options) {
        const unitConverter = this.units === 0
            ? x => x
            : metersToInches;

        let filename = `${options.filename || 'exportPlane'}-${options.projectName}`;

        if (currentDesign.name !== options.projectName) {
            filename = `${filename}-${currentDesign.name}`;
        }

        if (options.segmentName) {
            filename = `${filename}-${options.segmentName}`;
        }

        return {
            filename,
            units: this.units,
            lines: this.getDecimatorPlaneGeometries(matrix, options).map(
                line => [
                    {x: unitConverter(line[0].x), y: unitConverter(line[0].y)},
                    {x: unitConverter(line[1].x), y: unitConverter(line[1].y)},
                    {dType: line[2]}
                ]
            )
        }
    }

    /**
     * @typedef {{
     *   filename?: string,
     *   projectName: string,
     *   segmentName?: string
     * }} Options
     * @param {Options} options
     * @returns
     */
    getExportFileName(options) {
        let filename = `${options.filename || 'exportPlane'}-${options.projectName}`;

        if (currentDesign.name !== options.projectName) {
            filename = `${filename}-${currentDesign.name}`;
        }

        if (options.segmentName) {
            filename = `${filename}-${options.segmentName}`;
        }

        return filename;
    }

    /**
     * @param {import('../../../types/dxf-export').ExportPlaneOptions} options
     */
    async exportParallelPlane(options) {
        const matrix = this.getProjectionMatrix(options.centroid, options.normal);

        /// @todo Unify the way we handle this; entity export includes unit conversion
        ///       but line export does not. Or just get rid of line exports altogether.
        const scaleFactor = this.getUnitConverter()(1);

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        let dxfEntities = this.getAllFacetDxfEntities(
            options.outline,
            options.roofPlaneInfo.name,
            options.keepouts,
            matrix
        );

        /// @todo Do we need to update how we draw the model imports as well?
        const lines = this.getLinesImportedModels(
            options.importedModels,
            (new Matrix4()).makeScale(scaleFactor, scaleFactor, scaleFactor).multiply(matrix)
        ).map(
            l => [
                {x: l[0].x, y: l[0].y},
                {x: l[1].x, y: l[1].y},
                {dType: l[2]}
            ]
        );

        const dxfRequestBody = {
            filename: `${this.getExportFileName(options)}-${options.roofPlaneInfo.name}`,
            units: this.units,
            entities: dxfEntities,
            lines,
            roofPlaneInfo: [options.roofPlaneInfo],
            roofPlaneAreas: [options.outline.calculateArea() * 10.7639],
            moduleArrayInfo: [this.getExportModuleInfoForFacet(options.outline)],
            projectName: options.projectName,
            projectLink: `${Config.baseURL}/#!/project/${options.project.id}/viewer`,
            designName: currentDesign.name
        };

        await this.getDxf(
            options,
            dxfRequestBody,
            'dxf-export-plane'
        );

    }

    /**
     * @param {Vector3} v
     * @returns {import('../../../types').Tuple3<number>}
     */
    vec3To3Tuple(v) {
        return [v.x, v.y, v.z];
    }

    /**
     * @param {Vector3} v
     * @returns {import('../../../types').Tuple2<number>}
     */
    vec3To2Tuple(v) {
        return [v.x, v.y];
    }

    /**
     * Export the geometries directly associated with the roof facet (i.e. the outline
     * and the module array) to a collection of DXF entities understood by the decimator.
     *
     * @param {OutlineContext} facet
     * @param {string} name
     * @param {Matrix4} matrix
     */
    roofFacetWithNameInProjectionTo2DDxfEntities(facet, name, matrix) {
        const scaleFactor = this.getUnitConverter()(1);
        matrix = matrix.clone().premultiply(
            (new Matrix4()).makeScale(scaleFactor, scaleFactor, scaleFactor)
        );

        /** @type {import('../../../types/dxf-export').PolylineDxfEntity<2>[]} */
        let dxfEntities = [{
            type: 'polyline2d',
            geometry: {
                points: facet.getVertices().map(v => this.vec3To2Tuple(v.clone().applyMatrix4(matrix)))
            },
            layer: {
                type: "outlines",
                name: `${name}-outlines`
            }
        }];

        if (!(facet.moduleArray instanceof SolarPanelModuleArray)) {
            return dxfEntities;
        }

        for (const modulePolyline of facet.moduleArray.getPolylines()) {
            dxfEntities.push({
                type: 'polyline2d',
                geometry: {
                    points: modulePolyline.map(v => this.vec3To2Tuple(v.clone().applyMatrix4(matrix)))
                },
                layer: {
                    type: "modules",
                    name: `${name}-modules`
                }
            });
        }

        return dxfEntities;
    }

    /**
     *
     * @param {OutlineContext} outline
     * @param {string} name
     * @param {boolean} [extrude = true]
     */
    roofFacetWithNameTo3DDxfEntities(outline, name, extrude = true) {
        const transform = this.getThreeJs3DToDxf3DTransformMatrix();

        /** @type {import('../../../types/dxf-export').PolylineDxfEntity<3>[]} */
        let dxfEntities = [{
            type: 'polyline',
            geometry: {
                points: outline.getVertices().map(
                    v => this.vec3To3Tuple(v.clone().applyMatrix4(transform))
                ),
                closed: true
            },
            layer: {
                type: "outlines",
                name: `${name}-outline`
            }
        }];

        if (extrude) {
            /** @type {import('../../../types/dxf-export').DxfLayer} */
            const extrudeLayer = {type: "extrusions", name: `${name}-extrusions`};

            // extrusion onto ground
            dxfEntities.push({
                type: 'polyline',
                geometry: {
                    points: outline.getVertices().map(
                        v => this.vec3To3Tuple(
                            v.clone().applyMatrix4(transform).projectOnPlane(kUnitVector)
                        )
                    ),
                    closed: true
                },
                layer: extrudeLayer
            });

            // wall extrusions
            for (const segment of outline.segments) {

                const ul = segment.geometry.vertices[0].clone().applyMatrix4(transform);
                const ur = segment.geometry.vertices[1].clone().applyMatrix4(transform);
                const ll = ul.clone().projectOnPlane(kUnitVector);
                const lr = ur.clone().projectOnPlane(kUnitVector);


                dxfEntities.push({
                    type: 'polyline',
                    geometry: {
                        points: [ll, lr, ur, ul].map(this.vec3To3Tuple),
                        closed: true
                    },
                    layer: extrudeLayer
                });
            }
        }

        if (!(outline.moduleArray instanceof SolarPanelModuleArray)) {
            return dxfEntities;
        }

        for (const modulePolyline of outline.moduleArray.getPolylines()) {
            dxfEntities.push({
                type: 'polyline',
                geometry: {
                    points: modulePolyline.map(v => this.vec3To3Tuple(v.clone().applyMatrix4(transform)))
                },
                layer: {
                    type: "modules",
                    name: `${name}-modules`
                }
            });
        }

        return dxfEntities;
    }

    /**
     * Export a keepout context to a collection of DXF entities.
     *
     * This will generally consist of a polyline for the keepout boundary,
     * a polyline for the setback from the boundary, a polyline for the top
     * of the extruded keepout, and lines connecting vertices of the keepout
     * and extruded top.
     *
     * @param {Keepout} keepout
     * @param {string} name
     * @param {Matrix4} matrix
     * @returns {(
     *   import('../../../types/dxf-export').PolylineDxfEntity<2> | import('../../../types/dxf-export').LineDxfEntity<2>
     * )[]}
     */
    keepoutWithNameInProjectionTo2DDxfEntities(keepout, name, matrix) {
       const scaleFactor = this.getUnitConverter()(1);

        matrix = (new Matrix4()).makeScale(
            scaleFactor,
            scaleFactor,
            scaleFactor
        ).multiply(
            matrix
        );

        /** @param {Vector3} v */
        const convert = v => this.vec3To2Tuple(v.clone().applyMatrix4(matrix));
        return this.keepoutWithNameToDxfEntities(keepout, name, convert);
    }

    /**
     * Export a keepout context (keepout bounds + setback + extrusion) to 3D.
     *
     * @param {Keepout} keepout
     * @param {string} name
     * @returns {(
     *   import('../../../types/dxf-export').PolylineDxfEntity<3> | import('../../../types/dxf-export').LineDxfEntity<3>
     * )[]}
     */
    keepoutWithNameTo3DDxfEntities(keepout, name) {
        /** @param {Vector3} v */
        const convert = v => this.vec3To3Tuple(
            v.clone().applyMatrix4(
                this.getThreeJs3DToDxf3DTransformMatrix()
            )
        );

        return this.keepoutWithNameToDxfEntities(keepout, name, convert);
    }

    /**
     * @template {2 | 3} N
     * @param {Keepout} keepout
     * @param {string} name
     * @param {(v: Vector3) => import('../../../types').Tuple<number, N>} convert A function
     *   converting a Vector3 to either a 2-tuple (for plane projections) or 3-tuple (for 3D exports).
     */
    keepoutWithNameToDxfEntities(keepout, name, convert) {
        /** @type {import('../../../types/dxf-export').DxfLayer} */
        const layer = {name: `${name}-keepouts`, type: "keepouts"};
        const arity = convert(iUnitVector).length;

        /** @type {N extends 2 ? 'polyline2d' : 'polyline'} */
        /// @ts-ignore
        const polylineType = arity === 2 ? 'polyline2d' : 'polyline';

        switch (keepout.shape) {
            case 'fire-setback':
                return [{
                    type: polylineType,
                    geometry: {
                        points: keepout.getPolyline().map(convert)
                    },
                    layer
                }];

            default:
                /**
                 * @typedef {import('../../../types/dxf-export').PolylineDxfEntity<N>} PLine
                 * @typedef {import('../../../types/dxf-export').LineDxfEntity<N>} Line
                 * @type {(PLine | Line)[]}
                 */
                const centities = [{
                    type: polylineType,
                    geometry: {
                        points: keepout.getPolyline().map(convert)
                    },
                    layer
                }];

                if (keepout.setback) {
                    centities.push({
                        type: polylineType,
                        geometry: {
                            points: keepout.getSetbackPolyline().map(convert)
                        },
                        layer
                    });
                }

                if (keepout.extrusion) {
                    const extr = keepout.getExtrusions();
                    centities.push({
                        type: polylineType,
                        geometry: {
                            points: extr.top.map(convert)
                        },
                        layer
                    });

                    centities.push(
                        ...extr.edges.map(
                            /** @returns {import('../../../types/dxf-export').LineDxfEntity<N>} */
                            edge => {
                                const decEdge = edge.map(convert);
                                return {
                                    type: 'line',
                                    geometry: {
                                        start: decEdge[0],
                                        end: decEdge[1]
                                    },
                                    layer
                                };
                            }
                        )
                    );
                }

                return centities;
        }
    }

    /**
     * Wrapper function that performs all of the steps of exporting a roof segment
     * to 2D DXF. This is very nearly the same across 2D wireframe and all-planes PP.
     *
     * @param {OutlineContext} outline
     * @param {string} outlineName,
     * @param {Keepout[]} keepouts
     * @param {Matrix4} transform
     *
     * @returns {import('../../../types/dxf-export').DxfEntity[]}
     */
    getAllFacetDxfEntities(outline, outlineName, keepouts, transform) {
        // OMG I have peppered this damn thing all over the place and REALLY need
        // to clean it up. Either the transformation matrix should account for units
        // or it shouldn't, but it shouldn't do this stupid thing where it does in
        // some cases but not in others!!! FIX THIS ASAP.
        const scaleFactor = this.getUnitConverter()(1);

        // Start with entities tied directly to the outline context, i.e. outline
        // and module array.

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        let dxfEntities = this.roofFacetWithNameInProjectionTo2DDxfEntities(
            outline,
            outlineName,
            transform
        );

        // Attach keepouts
        dxfEntities.push(
            ...keepouts.flatMap(
                k => this.keepoutWithNameInProjectionTo2DDxfEntities(
                    k,
                    outlineName,
                    transform
                )
            )
        );

        // Attach segment annotation
        dxfEntities.push({
            type: 'text',
            geometry: {
                text: outlineName,
                position: this.vec3To2Tuple(
                    // See above: unit conversion needs to either consistently
                    // be applied as part of the matrix transformation or never
                    // applied as part of the matrix transformation, but definitely
                    // not in some cases but not others.
                    outline.fitPlane.centroid.clone().applyMatrix4(
                        new Matrix4().makeScale(
                            scaleFactor,
                            scaleFactor,
                            scaleFactor
                        ).multiply(transform)
                    )
                ),
                // This makes segment annotations 1 ft tall in drawing space
                size: 0.3048 * scaleFactor
            },
            layer: {
                type: 'annotations',
                name: 'segment-annotations'
            }
        });

        return dxfEntities;
    }

    /**
     *
     * @param {OutlineContext} outline
     * @return {{moduleCount: number, totalPKWSTC: number, modulePMax: number}}
     */
    getExportModuleInfoForFacet(outline) {
        if (!(outline.moduleArray instanceof SolarPanelModuleArray)) {
            return {
                moduleCount: 0,
                modulePMax: 0,
                totalPKWSTC: 0
            }
        }

        const r = outline.moduleArray;

        return {
            moduleCount: r.moduleCount,
            totalPKWSTC: r.totalOutput / 1000, // Stored in watts but exported to kW
            modulePMax: r.moduleSpecs.wattage
        }

    }

    /**
     * @template {number} N
     * @param {import('../../../types/dxf-export').ExportOptions<N>} options */
    async exportAllPlanes(options) {
        console.assert(options.outlines.length === options.keepouts.length);

        options = Object.assign({filename: 'exportAllPlanes'}, options);

        /** @type {options['outlines']['length']} */
        const outlineCount = options.outlines.length;

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        let entities = [];

        /** @type {number[]} */
        const roofPlaneAreas = [];

        for (let pid = 0; pid < outlineCount; ++pid) {
            const facet = options.outlines[pid];
            const facetName = options.roofPlaneInfo[pid].name;

            // Projection matrix
            const projectionMatrix = this.updateTransformToFixEaveToOverhead(
                this.getProjectionMatrix(
                    facet.fitPlane.centroid,
                    facet.fitPlane.normal
                ),
                this.getVirtualEaveForSegment(facet)
            );


            entities.push(
                ...this.getAllFacetDxfEntities(
                    facet,
                    facetName,
                    options.keepouts[pid],
                    projectionMatrix
                )
            );

            roofPlaneAreas.push(facet.calculateArea() * 10.7639);
        }

        await this.getDxf(
            options,
            {
                filename: this.getExportFileName(options),
                units: this.units,
                entities,
                roofPlaneInfo: options.roofPlaneInfo,
                roofPlaneAreas,
                moduleArrayInfo: options.outlines.map(this.getExportModuleInfoForFacet),
                projectName: options.projectName,
                projectLink: `${Config.baseURL}/#!/project/${options.project.id}/viewer`,
                designName: currentDesign.name
            },
            'dxf-export-all-planes'
        );
    }

    /**
     * @template {number} N
     * @param {import('../../../types/dxf-export').ExportOptions<N>} options
     */
    async exportOverhead(options) {
        console.assert(options.outlines.length === options.keepouts.length);
        options = Object.assign({filename: "exportOverhead"}, options);

        const sf = this.getUnitConverter()(1); // omg make it go away

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        const dxfEntities = [];

        /** @type {number[]} */
        const facetAreas = [];

        for (let pid = 0, n = options.outlines.length; pid < n; ++pid) {
            const facet = options.outlines[pid];
            const facetName = options.roofPlaneInfo[pid].name;
            const keepouts = options.keepouts[pid];

            dxfEntities.push(
                ...this.getAllFacetDxfEntities(
                    facet,
                    facetName,
                    keepouts,
                    this.overheadProjectionMatrix
                )
            );

            facetAreas.push(facet.calculateArea() * 10.7639);
        }

        const lines = this.getLinesImportedModels(
            options.importedModels,
            // bake unit conversion into projection
            (new Matrix4()).makeScale(sf, sf, sf).multiply(
                this.overheadProjectionMatrix
            )
        ).map(
            l => [
                {x: l[0].x, y: l[0].y},
                {x: l[1].x, y: l[1].y},
                {dType: l[2]}
            ]
        );

        await this.getDxf(
            options,
            {
                filename: this.getExportFileName(options),
                units: this.units,
                entities: dxfEntities,
                lines,
                roofPlaneInfo: options.roofPlaneInfo,
                moduleArrayInfo: options.outlines.map(this.getExportModuleInfoForFacet),
                roofPlaneAreas: facetAreas,
                projectName: options.projectName,
                projectLink: `${Config.baseURL}/#!/project/${options.project.id}/viewer`,
                designName: currentDesign.name
            },
            'dxf-export-overhead'
        );
    }

    /**
     * @template {number} N
     * @param {import('../../../types/dxf-export').ExportOptions<N>} options
     */
    async export3DFile(options) {
        var lines = this.getLines3D(options);

        var axis1 = new Vector3(1, 0, 0);
        var axis2 = new Vector3(0, 0, 1);

        var units = (this.units == 0) ? (x) => x : metersToInches;

        // copy-paste since this skips getDxfData
        let filename = `export3D-${options.projectName}`;

        if (currentDesign.name !== options.projectName) {
            filename = `${filename}-${currentDesign.name}`;
        }

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        const dxfEntities = options.outlines.flatMap(
            (p, idx) => this.roofFacetWithNameTo3DDxfEntities(
                p,
                options.roofPlaneInfo[idx].name
            )
        );

        dxfEntities.push(
            ...options.keepouts.flatMap(
                (ks, idx) => ks.flatMap(
                    k => this.keepoutWithNameTo3DDxfEntities(
                        k,
                        options.roofPlaneInfo[idx].name
                    )
                )
            )
        );

        var dxfData = {
            filename,
            units:    this.units,
            lines:    lines.map((l) => {
                var v1      = l[0].applyAxisAngle(axis1, Math.PI/2).applyAxisAngle(axis2, Math.PI);
                var v2      = l[1].applyAxisAngle(axis1, Math.PI/2).applyAxisAngle(axis2, Math.PI);
                var dType   = l[2];

                return [
                    { x: units(v1.x), y: units(v1.y), z: units(v1.z) },
                    { x: units(v2.x), y: units(v2.y), z: units(v2.z) },
                    { dType: dType }
                ];
            }),
            entities: dxfEntities,
            roofPlaneInfo: options.roofPlaneInfo,
            moduleArrayInfo: options.outlines.map(this.getExportModuleInfoForFacet),
            roofPlaneAreas: options.outlines.map(o => o.calculateArea() * 10.7639),
            projectName: options.projectName,
            projectLink: `${Config.baseURL}/#!/project/${options.project.id}/viewer`,
            designName: currentDesign.name
        };

        await this.getDxf(options, dxfData, "dxf-export-3d");
    }

    /**
     * The viewer operates in y-vertical 3-space with the positive z-axis south.
     * DXF operates in z-vertical 3-space with the positive y-axis north.
     *
     * We may also need to perform a scaling from meter-native to inches.
     *
     * Get the transformation matrix to effect the three.js -> dxf transform.
     */
    getThreeJs3DToDxf3DTransformMatrix() {
        /** @type {Quaternion} */
        const zVertToYVert = (new Quaternion()).setFromAxisAngle(iUnitVector, Math.PI / 2);

        /** @type {Quaternion} */
        const ySouthToYNorth = (new Quaternion()).setFromAxisAngle(kUnitVector, Math.PI);
        const scaleFactor = this.getUnitConverter()(1);

        return (new Matrix4()).compose(
            new Vector3(),
            ySouthToYNorth.multiply(zVertToYVert),
            (new Vector3()).setScalar(scaleFactor)
        );
    }

    /**
     *
     * @param {ExportOptions} options
     * @returns
     */
    getLines3D(options) {
        var lines = [];

        /** @type {import('../../../types/dxf-export').DxfEntity[]} */
        const entities = [];

        // for (var i = 0; i < options.modules.length; i++) {
        //     const vv = options.modules[i];
        //     entities.push({
        //         type: 'polyline',
        //         geometry: {
        //             points: [
        //                 vv[0], vv[1], vv[4], vv[5]
        //             ].map(
        //                 v => [v.x, v.y, v.z]
        //             ),
        //             closed: true
        //         },
        //         layer: {
        //             type: "modules",
        //             name: "modules"
        //         }
        //     });


        //     lines.push([ vv[0].clone(), vv[1].clone(), "modules" ]); // was "3d"
        //     lines.push([ vv[1].clone(), vv[2].clone(), "modules" ]);
        //     lines.push([ vv[4].clone(), vv[5].clone(), "modules" ]);
        //     lines.push([ vv[5].clone(), vv[3].clone(), "modules" ]);
        // }

        // roof plane outlines
        if (options.planes) {
/*             let outlines = [];

            for (let plane of options.planes)
                for (let ctx of plane)
                    for (let s of ctx.segments)
                        outlines.push([ s.geometry.vertices[0].clone(),
                                        s.geometry.vertices[1].clone(),
                                        "outlines" ]);

            // combine overlapping lines into one line
            outlines = this.uniq(outlines, this.lComp);

            // roof plane extrusions to produce building shape
            let extr = [];

            for (let l of outlines) {
                extr.push(
                    [
                        new Vector3(l[0].x,      0, l[0].z),
                        new Vector3(l[1].x,      0, l[1].z),
                        "extr"
                    ], [
                        new Vector3(l[0].x,      0, l[0].z),
                        new Vector3(l[0].x, l[0].y, l[0].z),
                        "extr"
                    ], [
                        new Vector3(l[1].x,      0, l[1].z),
                        new Vector3(l[1].x, l[1].y, l[1].z),
                        "extr"
                    ]
                );
            } */

            var setbacks = [];

            for (let plane of options.planes) {
                for (let ctx of plane) {
                    if (!ctx.moduleArray)
                        continue;

                    for (let l of ctx.moduleArray.getSetbackLines())
                        setbacks.push(l.concat(["setbacks"]));
                }
            }

            //lines.push(...outlines);
            //lines.push(...extr);
            lines.push(...setbacks);
        }

        // if (options.keepouts) {
        //     for (let pid = 0; pid < options.keepouts.length; pid++) {
        //         for (let kid = 0; kid < options.keepouts[pid].length; kid++) {
        //             let k = options.keepouts[pid][kid];

        //             for (let l of k.getLines())
        //                 lines.push(l.concat(["keepouts"]));
        //         }
        //     }
        // }

        if (options.importedModels)
            lines.push(...this.getLinesImportedModels(options.importedModels));

        return lines;
    }


    async getDxf(options, dxfData, updateType) {
        if (options.imgDataUrl) {
            var units = (this.units == 0) ? (x) => x : metersToInches;

            dxfData = Object.assign(dxfData, {
                imgData: {
                    url: options.imgDataUrl,
                    dim: {
                        w: units(options.groundSize),
                        h: units(options.groundSize)
                    },
                    rot: 0
                }
            });
        }

        if(options.orthoDataUrl && options.details.boundingBox) {
            var dimensions = this.realDimensions(
                options.details.boundingBox.width + 3.3,
                options.details.boundingBox.height + 3.3,
                options.details.boundingBox.heading,
                this.units,
                options.projectType
            )

            var scalingType = options.details.processedWith &&
                options.details.processedWith.engine !== 'ag';

            var fixedScaling = options.details.processedWith &&
                options.details.processedWith.engine === 'ag' &&
                options.details.processedWith.version.startsWith('1.6');

            const rot = (options.details.processedWith || {}).engine === 'w3d'
                ? 0 // w3d orthos are north-up and do not need to be rotated for alignment
                : radToDeg(-1 * options.details.boundingBox.heading);

            dxfData = Object.assign(dxfData, {
                orthoDataUrl: {
                    rot,
                    url:          options.orthoDataUrl,
                    dim:          { w: dimensions[0], h: dimensions[1] },
                    scalingType:  scalingType,
                    fixedScaling: fixedScaling
                }
            });
        } else {
            console.log('No orthomosaic or bounding box data available');
        }

        try {
            var key;
            if (await shouldStoreExportsForPublicAPI()) {
                const newKey = `${project.id}/${currentDesign.id}/${dxfData.filename}.zip`;
                if(updateType == 'dxf-export-overhead') {
                    key = this.dxf2DWireframeUrl ? new URL(this.dxf2DWireframeUrl).pathname.substring(1) : newKey;
                } else if(updateType == 'dxf-export-3d') {
                    key = this.dxf3DWireframeUrl ? new URL(this.dxf2DWireframeUrl).pathname.substring(1) : newKey;
                } else if(updateType == 'dxf-export-all-planes') {
                    key = this.dxfParallelProjectionsAllPlanesUrl ? new URL(this.dxf2DWireframeUrl).pathname.substring(1) : newKey;
                }
            }
            if(key) {
                dxfData.url_key = key;
                dxfData.responseType = 'json';
            } else {
                dxfData.responseType = 'arraybuffer';
            }
            const response = await Net.generateDxf(dxfData);
            if(key) {
                const url = response.url;
                if(updateType == 'dxf-export-overhead') {
                    this.dxf2DWireframeUrl = url;
                } else if(updateType == 'dxf-export-3d') {
                    this.dxf3DWireframeUrl = url;
                } else if(updateType == 'dxf-export-all-planes') {
                    this.dxfParallelProjectionsAllPlanesUrl = url;
                }
                // download file at url
                if(url) {
                    browserDownloadUrl(url, dxfData.filename + '.zip');
                }
            } else {
                browserDownload({
                    blob: new Blob( [response], {type: 'application/zip'}),
                    filename: dxfData.filename + '.zip',
                    url: undefined
                });
            }
        } catch( error ) {
            console.log(`Error exporting DXF: ${error}`);
        }
    }

    getLinesImportedModels(importedModels, m = undefined) {
        let points = [];

        for (let i = 0; i < importedModels.length || 0; i++) {
            /** @type {Mesh[]} */
            const groupChildren = importedModels[i].children[0].children;

            groupChildren.forEach((childMesh) => {

                const vv = childMesh.geometry.attributes.position.array;

                for (let j = 2; j < vv.length; j += 3) {
                    const vw = childMesh.localToWorld(
                        new Vector3(vv[j - 2], vv[j - 1], vv[j])
                    );

                    if (m) {
                        vw.applyMatrix4(m);
                    }

                    points.push(vw);
                }
            });
        }

        /** @type {DxfLineDatum[]} */
        let lines = [];

        for (let i = 1; i < points.length; i += 2) {
            lines.push([
                points[i-1].clone(),
                points[i].clone(),
                "imported-models"
            ]);
        }

        return lines;
    }


    getExportablePlaneGeometry(planes) {
        let gg = [];

        for (let plane of planes)
            for (let ctx of plane)
                if (ctx.fitPlane)
                    gg.push(ctx.getPolygonGeometry(ctx.fitPlane));

        if (gg.length > 0) {
            // lowest and highest points of roof geometry
            let minY = gg[0].vertices[0].y;
            let maxY = gg[0].vertices[0].y;

            for (let g of gg) {
                for (let v of g.vertices) {
                    if (v.y < minY)
                        minY = v.y;

                    if (v.y > maxY)
                        maxY = v.y;
                }
            }

            //let gY = maxY - height;
            let gY = 0;

            let ggc = [];

            for (let plane of planes)
                for (let ctx of plane)
                    if (ctx.fitPlane)
                        ggc.push(...ctx.getClosedVolumeGeometry(gY));

            gg.push(...ggc);
        }

        return gg;
    }


    getExportableImportedModelGeometry(models) {
        let gg = [];

        models.forEach((m) => {
            m.children[0].children.forEach((c) => {
                let g = c.geometry.clone();

                c.updateMatrixWorld();
                g.applyMatrix(c.matrixWorld);

                gg.push(g);
            });
        });

        return gg;
    }


    getExportableKeepoutGeometry(keepouts) {
        let gg = [];

        for (let plane of keepouts) {
            for (let ctx of plane) {
                if (ctx.getExportGeometry) {
                    gg.push(...ctx.getExportGeometry());
                } else if (ctx.getPolygonGeometry) {
                    let ggk = ctx.getClosedVolumeGeometry();

                    if (ggk.length > 0) {
                        let pg = ctx.getPolygonGeometry(ctx.fitPlane);

                        // fix face orientation
                        for (let f of pg.faces)
                            [ f.c, f.a ] = [ f.a, f.c ];

                        gg.push(pg);
                        gg.push(...ggk);
                    }
                }
            }
        }

        return gg;
    }


    getExportable3DGeometry(options) {
        let gg = [];

        gg.push(...this.getExportablePlaneGeometry(options.planes));
        gg.push(...this.getExportableImportedModelGeometry(options.importedModels));
        gg.push(...options.modules);
        gg.push(...this.getExportableKeepoutGeometry(options.keepouts));

        // rotate so Z is up
        let mr = new Matrix4();
        mr.makeRotationX(Math.PI/2);

        gg.forEach(g => g.applyMatrix(mr));

        // DEBUG uncomment to see the geometries prior to exporting
        //let m = new MeshBasicMaterial({color:0xffff00});
        //for (let g of gg) {
        //    let mesh = new Mesh(g.clone(), m);
        //    mesh.position.copy(new Vector3(0, 10, 0));
        //    scene.add(mesh);
        //}

        return gg;
    }


    geometryToVertices12(gg) {
        // x, y, z for face normal and x, y, z for 3 face vertices
        let vv12 = [];

        for (let g of gg) {
            if (g.faces) {
                for (let f of g.faces) {
                    vv12.push([
                               f.normal.x,         f.normal.y,         f.normal.z,
                        g.vertices[f.a].x,  g.vertices[f.a].y,  g.vertices[f.a].z,
                        g.vertices[f.b].x,  g.vertices[f.b].y,  g.vertices[f.b].z,
                        g.vertices[f.c].x,  g.vertices[f.c].y,  g.vertices[f.c].z
                    ]);
                }
            } else { // BufferGeometry
                let pos  = g.attributes.position.array;
                let norm = g.attributes.  normal.array;

                for (let i = 0; i < pos.length; i += 9) {
                    let n = new Vector3(
                        norm[i  ] + norm[i+3] + norm[i+6],
                        norm[i+1] + norm[i+4] + norm[i+7],
                        norm[i+2] + norm[i+5] + norm[i+8]
                    );

                    n.normalize();

                    let vv = [ n.x, n.y, n.z ];

                    for (let j = 0; j < 9; j++)
                        vv.push(pos[i+j]);

                    vv12.push(vv);
                }
            }
        }

        return vv12;
    }


    async exportAsciiStl(options) {
        let gg = this.getExportable3DGeometry(options);
        let vv12 = this.geometryToVertices12(gg);

        let ff = [];

        for (let vv of vv12) {
            // indent/newlines here are important for stl formatting
            ff.push(`
  facet normal ${vv[0]} ${vv[1]} ${vv[2]}
    outer loop
        vertex ${vv[ 3]} ${vv[ 4]} ${vv[ 5]}
        vertex ${vv[ 6]} ${vv[ 7]} ${vv[ 8]}
        vertex ${vv[ 9]} ${vv[10]} ${vv[11]}
    endloop
  endfacet`);
        }

        let stl = `solid Scanifly_Export
${ff.join('\n')}
endsolid Scanifly_Export`;

        this.stlSurfaceModelUrl = await downloadAndArchiveForPublicAPI( {
            blob: new Blob([stl], {type:'model/stl'}),
            filename: 'export.stl',
            url: this.stlSurfaceModelUrl
        });
    }


    async exportBinaryStl(options) {
        let gg = this.getExportable3DGeometry(options);
        let vv12 = this.geometryToVertices12(gg);

        let buffer = new ArrayBuffer(84 + (50 * vv12.length));
        let dv     = new DataView(buffer);

        let offset = 80; // header is empty
        let littleEndian = true;

        dv.setUint32(offset, vv12.length, littleEndian);
        offset += 4;

        for (let vv of vv12) {
            // normal
            dv.setFloat32(offset,     vv[ 0], littleEndian);
            dv.setFloat32(offset + 4, vv[ 1], littleEndian);
            dv.setFloat32(offset + 8, vv[ 2], littleEndian);

            offset += 12;

            // vertices
            dv.setFloat32(offset,     vv[ 3], littleEndian);
            dv.setFloat32(offset + 4, vv[ 4], littleEndian);
            dv.setFloat32(offset + 8, vv[ 5], littleEndian);

            offset += 12;

            dv.setFloat32(offset,     vv[ 6], littleEndian);
            dv.setFloat32(offset + 4, vv[ 7], littleEndian);
            dv.setFloat32(offset + 8, vv[ 8], littleEndian);

            offset += 12;

            dv.setFloat32(offset,     vv[ 9], littleEndian);
            dv.setFloat32(offset + 4, vv[10], littleEndian);
            dv.setFloat32(offset + 8, vv[11], littleEndian);

            offset += 14; // 12 + 2 for unused Uint16 attribute byte count
        }

        this.stlSurfaceModelUrl = await downloadAndArchiveForPublicAPI( {
            blob: new Blob([dv], { type: "model/stl" }),
            filename: 'export.stl',
            url: this.stlSurfaceModelUrl
        } );
    }


    async exportObj(options) {
        let gg = this.getExportable3DGeometry(options);
        let vv12 = this.geometryToVertices12(gg);

        let ovv  = [];
        let ovvn = [];
        let off  = [];

        for (let vv of vv12) {
            // OBJ indices start from 1
            let v1 = ovv.length + 1;
            let v2 = v1 + 1;
            let v3 = v1 + 2;

            let vn = ovvn.length + 1;

            // face vertices
            ovv.push(`v ${vv[3]} ${vv[ 4]} ${vv[ 5]}`);
            ovv.push(`v ${vv[6]} ${vv[ 7]} ${vv[ 8]}`);
            ovv.push(`v ${vv[9]} ${vv[10]} ${vv[11]}`);

            // face normal
            ovvn.push(`vn ${vv[0]} ${vv[1]} ${vv[2]}`);

            // face w vertex/normal indices
            off.push(`f ${v1}//${vn} ${v2}//${vn} ${v3}//${vn}`);
        }

        let obj = `# Scanifly OBJ File: ''
# https://scanifly.com
o Scanifly_Export
${ovv.join('\n')}
${ovvn.join('\n')}
s off
${off.join('\n')}`;

        this.objSurfaceModelUrl = await downloadAndArchiveForPublicAPI( {
            blob:     new Blob([obj], {type: 'model/obj'}),
            filename: 'export.obj',
            url:      this.objSurfaceModelUrl
        });
    }



    async exportCollada(options) {
        let gg = this.getExportable3DGeometry(options);

        let exporter = new ColladaExporter();

        let group = new Group();
        let m = new MeshBasicMaterial({ color: 0xffffff });

        for (let g of gg) {
            let mesh = new Mesh(g, m);
            group.add(mesh);
        }

        let dae = exporter.parse(group).data;

        this.daeSurfaceModelUrl = await downloadAndArchiveForPublicAPI( {
            blob:     new Blob([dae], {type: 'model/vnd.collada+xml'}),
            filename: 'export.dae',
            url:      this.daeSurfaceModelUrl
        });
    }


    getSaveData() {}
    restoreSaveData() {}


    /**
     * Orthomosaic helper
     */
    realDimensions(w, h, heading, units, projectType) {
        var realHeight, realWidth

        if(units === 1) {
            w = w * 3.28084 * 12
            h = h * 3.28084 * 12
        }

        // Convert bounding box height / width to real model height / width using rotation (heading)
        if(projectType !== "commercial-rc" && projectType !== "commercial" && projectType !== "utility" && projectType !== "commercial-small") {
            realWidth = Math.abs(Math.cos(heading)) * w + Math.abs(Math.sin(heading) * h)
            realHeight = Math.abs(Math.sin(heading)) * w + Math.abs(Math.cos(heading) * h)
        } else {
            realWidth = w
            realHeight = h
        }

        return [realWidth, realHeight]
    }

    /**
     * @returns {(m: number) => number}
     */
    getUnitConverter() {
        return this.units === 0
            ? x => x
            : metersToInches;
    }

    /**
     * Remove duplicate lines from arr using eq function.
     *
     * @template T
     * @param {T[]} arr
     * @param {(_1: T, _2: T) => boolean} eq
     */
    uniq(arr, eq) {
        var u = [];

        for (let i = 0; i < arr.length; i++) {
            let found = false;

            for (let j = i + 1; j < arr.length; j++) {
                if (eq(arr[i], arr[j])) {
                    found = true;
                    break;
                }
            }

            if (!found)
                u.push(arr[i]);
        }

        return u;
    }


    /**
     * Given a pair of "line" arrays (a start vertex, end vertex and optional
     * additional tagged data), determine if they represent the same line segment.
     *
     * @template {[Vector3, Vector3, ...any[]]} T
     * @template {[Vector3, Vector3, ...any[]]} U
     * @param {T} a
     * @param {U} b
     */
    lComp(a, b) {
        return ( (a[0].equals(b[0]) && a[1].equals(b[1])) ||
                 (a[0].equals(b[1]) && a[1].equals(b[0])) );
    }
}

export { DxfExportTool };

