import { Draw } from './Draw.js';
import { CircleGeometry, Face3, Geometry, LineBasicMaterial, Line, LineSegments, MOUSE, Object3D, Plane, Vector3 } from '../libs/three.module';



class CircleContext {
    constructor(options) {
        var options = options || {};

        /** @type {"circle"} */
        this.shape = 'circle';

        /** @type {import('three').Object3D} */
        this.container = new Object3D();

        this.camera    = options.camera;
        this.raycaster = options.raycaster;

        this.meshes = [];

        this.material = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth || 1,
            transparent: true,
            depthTest:   false
        });

        this.material2 = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });

        this.materialHighlight = new LineBasicMaterial({
            color:       options.colorHighlight || 0xffff00,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });

        this.states = { NONE: 0, STARTED: 1, CLOSED: 2 };
        this.state = this.states.NONE;

        this.nSegments = 32;

        this.height      = 0;
        this.setbackSize = 0;

        /** @type {number} */
        this.radius      = options.radius || 0;

        this.extrude = options.extrude || 'vertical';
        this.normal  = options.normal;

        this.onComplete = options.onComplete;

        if (options.vertices)
            this.initFromVertices(options.vertices);

        this.updateParams(options);
    }

    addToScene(scene) {
        this.scene = scene;
        this.scene.add(this.container);
    }


    show(show) {
        if (show !== false) {
            this.scene.add(this.container);
        } else {
            this.scene.remove(this.container);
        }
    }


    addMesh(mesh) { this.meshes.push(mesh); }

    undo() {
        if (this.state === this.states.STARTED) {
            this.container.remove(this.circle);

            this.state = this.states.NONE;

            return true;
        }

        return false;
    }

    mouseDown(event, mouse, panning) { }

    mouseMove(mouse, panning) {
        if (this.state === this.states.STARTED) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            var point = intersects[0].point;

            let v = point.clone().sub(this.position);
            let half = v.clone().divideScalar(2);

            this.circle.position.copy(half.clone().add(this.position));

            this.updateRadius(this.circle, half.length());
        }
    }

    mouseUp(event, mouse, panning, vSnap) {
        if (event.button !== MOUSE.LEFT)
            return;

        this.raycaster.setFromCamera(mouse, this.camera);

        var intersects = this.raycaster.intersectObjects(this.meshes);
        if (intersects.length === 0)
            return;

        if (this.state === this.states.NONE) {
            this.position = intersects[0].point.clone();

            let g = this.createGeometry(0.01);
            /** @type {import('../../../types').NoBufferGeom<import('three').Line>} */
            let c = new Line(g, this.material);

            c.position.copy(this.position);
            c.lookAt(this.position.clone().add(this.normal));

            this.container.add(c);

            this.circle = c;

            this.state = this.states.STARTED
        } else if (this.state === this.states.STARTED) {
            delete this.position;

            if (this.onComplete)
                this.onComplete();

            this.state = this.states.CLOSED;
        }
    }


    createGeometry(radius) {
        /** @type {import('three').CircleGeometry} */
        var g = new CircleGeometry(radius, this.nSegments);

        g.vertices.shift(); // remove center vertex
        g.vertices.push(g.vertices[0].clone());

        return g;
    }

    /**
     * @template {Object3D} T
     * @param {import('../../../types').NoBufferGeom<T>} c
     * @param {number} radius
     */
    updateRadius(c, radius) {
        let geometry = this.createGeometry(radius);

        for (var i = 0; i < c.geometry.vertices.length; i++) {
            c.geometry.vertices[i].copy(geometry.vertices[i]);
        }

        c.geometry.verticesNeedUpdate = true;

        this.radius = radius;
    }


    updateParams({ extrude, height, setback, radius, highlight }) {
        if (radius && radius !== this.radius)
            this.updateRadius(this.circle, radius);

        if (height !== undefined || (this.height && extrude) || (this.height && radius)) {
            if (this.extrusion) {
                this.container.remove(this.extrusion);
                delete this.extrusion;
            }

            if (height === undefined)
                height = this.height;

            if (height > 0) {
                let g = new Geometry();
                let p = new Plane();

                if (extrude === undefined)
                    extrude = this.extrude;

                let offset = (extrude === 'vertical') ?
                    new Vector3(0, height, 0) :
                    this.normal.clone().multiplyScalar(height);

                p.setFromNormalAndCoplanarPoint(offset.clone().normalize(), this.circle.position);

                let tcg = this.circle.geometry;

                for (let v of tcg.vertices)
                    g.vertices.push(p.projectPoint(this.circle.localToWorld(v.clone())));

                let c = new Line(g, this.material2);

                c.position.copy(offset);
                c.updateMatrixWorld();

                let g2 = new Geometry();

                for (var i = 0; i < tcg.vertices.length; i++) {
                    g2.vertices.push(this.circle.localToWorld(tcg.vertices[i].clone()));
                    g2.vertices.push(          c.localToWorld(  g.vertices[i].clone()));
                }

                let s = new LineSegments(g2, this.material2);

                /**
                 * @type {Object3D & {
                 *     children: [
                 *         import('../../../types').NoBufferGeom<import('three').Line>,
                 *         import('../../../types').NoBufferGeom<import('three').LineSegments>,
                 *         ...import('three').Object3D[]
                 *     ]
                 * }}
                 */
                let e = new Object3D();
                e.add(c);
                e.add(s);

                this.container.add(e);
                this.extrusion = e;

                // TODO: what happens when height is 0 or been set back to 0?

                // build geometry for 3d file export
                let gBot = new CircleGeometry(this.radius, this.nSegments);
                gBot.applyMatrix(this.circle.matrixWorld);

                let gTop = gBot.clone();

                for (let v of gTop.vertices)
                    v.copy(p.projectPoint(v).add(offset));

                for (let f of gBot.faces) // orient faces outward
                    [ f.c, f.a ] = [ f.a, f.c ];

                // connect top and bottom
                let gWalls = new Geometry();
                let vvw = g2.vertices;

                for (let i = 0; i < vvw.length; i += 2) {
                    let len = gWalls.vertices.length;

                    let v2 = (vvw[i+2] !== undefined) ? vvw[i+2].clone() : vvw[0].clone();
                    let v3 = (vvw[i+3] !== undefined) ? vvw[i+3].clone() : vvw[1].clone();

                    gWalls.vertices.push(vvw[  i].clone(), v2,
                                         vvw[i+1].clone(), v3)

                    gWalls.faces.push(
                        new Face3(len,   len+1, len+2),
                        new Face3(len+1, len+3, len+2)
                    );
                }

                this.exportGeometry = [ gBot, gTop, gWalls ];
            }

            this.height = height;
            this.extrude = extrude;
        }

        if (setback !== undefined || (this.setbackSize && radius)) {
            if (this.setback) {
                this.container.remove(this.setback);
                delete this.setback;
            }

            if (setback === undefined)
                setback = this.setbackSize;

            if (setback > 0) {
                let g = this.createGeometry(this.radius + setback);

                /** @type {import('../../../types').NoBufferGeom<import('three').Line>} */
                let s = new Line(g, this.material2);

                s.position.copy(this.circle.position);
                s.lookAt(s.position.clone().add(this.normal));

                this.container.add(s);
                this.setback = s;
            }

            this.setbackSize = setback;
        }

        if (this.circle)
            this.circle.material = highlight ? this.materialHighlight : this.material;

        if (this.extrusion) {
            this.extrusion.children.forEach((c) => {
                c.material = highlight ? this.materialHighlight : this.material2;
            });
        }
    }


    getExportGeometry() {
        return this.exportGeometry ? this.exportGeometry.map(g => g.clone()) : [];
    }


    getVertices() { return [ this.circle.position.clone() ]; }


    initFromVertices(vv) {
        let g = this.createGeometry(this.radius);

        /** @type {import('../../../types').NoBufferGeom<import('three').Line>} */
        let c = new Line(g, this.material);

        c.position.copy(vv[0]);
        c.lookAt(vv[0].clone().add(this.normal));
        c.updateMatrixWorld();

        this.container.add(c);
        this.circle = c;
    }

    getSetbackPolyline() {
        if (!this.setback) {
            return;
        }

        return this.setback.geometry.vertices.map(v => this.setback.localToWorld(v.clone()));
    }

    getPolyline() {
        if (!this.circle) {
            return;
        }

        return this.circle.geometry.vertices.map(v => this.circle.localToWorld(v.clone()));
    }

    getExtrusions() {
        if (!this.extrusion) {
            return;
        }

        const extrusionTop = this.extrusion.children[0];
        const extrusionSides = this.extrusion.children[1];

        return {
            top: extrusionTop.geometry.vertices.map(v => extrusionTop.localToWorld(v.clone())),
            edges: Draw.exportSegmentGeometry(extrusionSides.geometry.vertices)
        }
    }

    getLines() {
        var lines = [];

        if (this.extrusion) {
            let c = this.extrusion.children[0]
            let vv1 = c.geometry.vertices.map(v => c.localToWorld(v.clone()));

            lines.push(...Draw   .exportLineGeometry(vv1));

            let vv2 = this.extrusion.children[1].geometry.vertices;

            lines.push(...Draw.exportSegmentGeometry(vv2));
        }

        if (this.setback) {
            let vv = this.setback.geometry.vertices.map((v) => {
                return this.setback.localToWorld(v.clone());
            });

            lines.push(...Draw.exportLineGeometry(vv));
        }

        if (this.circle) {
            let vv = this.circle.geometry.vertices.map((v) => {
                return this.circle.localToWorld(v.clone());
            });

            lines.push(...Draw.exportLineGeometry(vv));
        }

        return lines;
    }
}



export { CircleContext };
