import tippy from 'tippy.js';
import { ExportCADGUI } from './GuiExportCAD.js';
import { ExportProductionDataGUI } from './GuiExportProductionData.js';
import { ExportShadeFilesGUI } from './GuiExportShadeFiles.js';
import {ExportsSurveyDataGUI } from './GuiExportSurveyData.js';

class ExportsGUI {
    /**
     * @param {object} options
     * @param {import('./GuiStatusIndicator').StatusIndicatorGui} options.statusIndicator
     */
    constructor(options) {
        this.options = options;
        this.statusIndicator = options.statusIndicator;
        this.exportTypes = [
            new ExportsSurveyDataGUI(options),
            new ExportShadeFilesGUI(options),
            new ExportCADGUI(options),
            new ExportProductionDataGUI(options)
        ];

        this.currentExportType = this.exportTypes[0];
    }

    init() {
        const self = this;
        $('body').on('change', '#export-type', function (e) {                // handler for select
            self.currentExportType = self.exportTypes[e.target.selectedIndex];
            self.renderState();
        });

        this.exportTypes.forEach( exportType => {
            exportType.init();
        });
    }

    render() {
        var t = '<select id="export-type">'
        this.exportTypes.forEach( exportType => {
            t += `<option>${exportType.name}</option>`;
        });
        t += '</select>';
        t += '<div style = "overflow: auto" class="exports-content">';
        this.exportTypes.forEach( exportType => {
            t += exportType.html();
        });
        t += '</div>';

        $(this.options.selector).html(t);

        tippy('.exports-content [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            placement: 'top'
        });

        $("#export-type").val(this.currentExportType.name);             // setting the value of the select to display the right option
    }

    renderState() {
        this.render();
        this.exportTypes.forEach( exportType => {
            if( this.currentExportType === exportType ) {
                $(exportType.selector).removeClass('hidden');
                exportType.renderState();
            } else {
                $(exportType.selector).addClass('hidden');
            }
        });
    }
}

export { ExportsGUI };
