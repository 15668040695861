import { viewer, project, prompts } from '../Viewer.js';
import { GUI } from './Gui.js';
import { Net } from '../libs/Net';
import { SCANIFLY_GLOBAL_MODELS, SCANIFLY_EXCLUDE_ROWS } from './GuiConstants';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import { Config } from '../../bootstrap/Config';




var modelList;
var GUIModelList = function (options) {
    var template = [
        '<ul class="model-list">',
        '</ul>'
    ].join("\n");

    options.container.append(template);

    this.options = options;
    this.models = {};

    // Library
    this.libModels = {}
    this.sceneObjs = {}

    this.treeData = {}
    this.species = null
    this.userSeeding = false
    this.seeds = []

    for (var i = 0; i < options.models.length; i++) {
        var id = options.models[i].id || i;

        var name = options.models[i].name

        this.add({
            id:   id,
            type: "upload",
            name: name,
        });

        this.complete(id, name, "upload");
    }
};

GUIModelList.prototype = {};
GUIModelList.prototype.constructor = GUIModelList;

GUIModelList.prototype.add = function (options) {
    if(options.type === "library"){
        var template, excludeInputs;

        SCANIFLY_EXCLUDE_ROWS.forEach( key => {
            if(options.id.includes(key)) {
                excludeInputs = true;
            }
        })

        if(excludeInputs) {
            template = [
                '<li class="model-item model-item-{ID}" data-key={LIBMODEL} data-id={ID}>',
                    '<div class="row-title">',
                        '<input type="checkbox" value="{ID}"/>',
                        '<div class="info">{VEGETATION}</div>',
                    '</div>',
                    '<div class="actions">',
                            '<button class="sy delete" aria-label="delete">',
                                '<i class="fa fa-trash"></i>',
                            '</button>',
                    '</div>',
                '</li>',
                ].join('\n');
        } else {
            template = [
                '<li class="model-item model-item-{ID}" data-key={LIBMODEL} data-id={ID}>',
                    '<div class="row-title">',
                        '<input type="checkbox" value="{ID}"/>',
                        '<div class="info">{VEGETATION}</div>',
                    '</div>',
                    '<div class="actions">',
                            'H: <input class="input-height" step="1"  type="number" value="{HEIGHT}" />',
                            'W: <input class="input-width" step="1" type="number" value="{WIDTH}" />',
                            '{DEPTH-INPUT}',
                            '<button class="sy delete" aria-label="delete">',
                                '<i class="fa fa-trash"></i>',
                            '</button>',
                    '</div>',
                '</li>',
                ].join('\n');
        }

        var previousName = options.name
        var modelId = options.id

        // Disable depth if vegetation
        var dInput = 'D: <input class="input-depth" type="number" value="{DEPTH}" />'
        template = template.replace(/\{DEPTH-INPUT\}/g, dInput);
        template = template.replace(/\{LIBMODEL\}/g, options.libModel);
        template = template.replace(/\{ID\}/g, modelId);
        template = template.replace(/\{VEGETATION\}/g, previousName);

        var h = 1
        var w = 1
        var d = 1

        template = template.replace(/\{HEIGHT\}/g, (w ? h.toFixed(2) : 1));
        template = template.replace(/\{WIDTH\}/g, (w ? w.toFixed(2) : 1));
        template = template.replace(/\{DEPTH\}/g, (w ? d.toFixed(2) : 1));

        $('.model-list').append($(template));

        return;
    }

    this.models[options.id] = {
        name:    options.name,
        percent: 0,
        done:    options.done,
        error:   ''
    };

    var template = [
        '<li class="model-item model-item-' + options.id + '" data-id="' + options.id + '">',
        '<div class="row-title">',
            '<input type="checkbox" value="${ID}"/>',
                '<div class="info">',
                    options.name + '<div class="status">Uploading... 0%</div>',
                '</div>',
        '</div>',
            '<div class="actions">',
                '<button>Cancel</button>',
            '</div>',
            '<div class="clearfix"></div>',
        '</li>'
    ].join("\n");

    $('.model-list', this.options.container).append(template);
};

GUIModelList.prototype.update = function (id, percent) {
    this.models[id].percent = percent;

    $('.model-list li.model-item-' + id + ' .status').html('Uploading... ' + percent + '%');
};

GUIModelList.prototype.processing = function (id, newId) {
    this.models[newId] = this.models[id];
    this.models[id] = undefined;

    var li = $('.model-list li.model-item-' + id);
    li.removeClass('model-item-' + id).addClass('model-item-' + newId);
    li.attr('data-id', newId);

    $('.status', li).html('Processing...');
    $('.actions', li).empty()
};

GUIModelList.prototype.complete = function (id, name, type) {
    this.models[id].done = true;
    var excludeInputs, template;

    SCANIFLY_EXCLUDE_ROWS.forEach( key => {
        if(id && id.includes(key)) {
            excludeInputs = true;
        }
    })

    if(excludeInputs) {
        template = [
            '<button class="sy delete" aria-label="delete">',
                '<i class="fa fa-trash"></i>',
            '</button>',
        ].join('\n');
    } else {
        template = [
            'H: <input class="input-height" step="1"  type="number" value="{HEIGHT}" />',
            'W: <input class="input-width" step="1" type="number" value="{WIDTH}" />',
            'D: <input class="input-depth" type="number" value="{DEPTH}" />',
            '<button class="sy delete" aria-label="delete">',
                '<i class="fa fa-trash"></i>',
            '</button>',

        ].join('\n');
    }


    template = template.replace(/\{ID\}/g, id);
    template = template.replace(/\{VEGETATION\}/g, name);
    template = template.replace(/\{HEIGHT\}/g, 1);
    template = template.replace(/\{WIDTH\}/g, 1);
    template = template.replace(/\{DEPTH\}/g, 1);

    var li = $('.model-list li.model-item-' + id);

    $('.status', li).html('');
    $('.actions', li)
        .empty()
        .append(template)
};

GUIModelList.prototype.error = function (id, error) {
    this.models[id].error = error;

    $(`.model-list li.model-item-${id} .status`).html('Error: ' + error);
    $(`.model-list li.model-item-${id} .info`).addClass('error');
};

GUIModelList.prototype.updateAll = function (models, restore) {
    for (var i = 0; i < models.length; i++) {
        if (this.models[models[i].id])
            if(!this.models[models[i].id].done) {
                this.complete(models[i].id);
            }
    }
};

function modelImport(options) {
    var template = [
        '<h3 class="header model-import">Import Models</h3>',
        '<div class="model-import-container">',
            '<div class="model-type-selection">',
                '<div class="import-3d option">',
                    '<div class="import-type-title">Local Library</div>',
                    '<div class="input-wrapper second">',
                        '<button class="sy upload" aria-label="upload">',
                            '<i class="fa fa-upload" aria-hidden="true"></i> ',
                        'Upload',
                        '</button>',
                    '</div>',
                '</div>',
                '<div class="model-import-divider"><div class="text">OR</div></div>',
                '<div class="select-vegetation option">',
                    '<div class="import-type-title">Scanifly Library</div>',
                    '<div id="lowPolyDropdown"></div>',
                    '<div id="highPolyDropdown"></div>',
                    '<div id="carportDropdown"></div>',
                    '<div id="inverterDropdown"></div>',
                    '<div id="laddersDropdown"></div>',
                    '<div id="shapesDropdown"></div>',
                    '<div id="electricalDropdown"></div>',
                    '<div id="evDropdown"></div>',
                    '<div id="telecomDropdown"></div>',
                    '<select id="scanifly-model-options" class="dropdown-common" name="scanifly model options">',
                        '<option value="">Select</option>',
                        '<option value="lowPolyDropdown">Trees (Low-Poly)</option>',
                        '<option value="highPolyDropdown">Trees (High-Poly)</option>',
                        '<option value="inverterDropdown">Inverters</option>',
                        '<option value="carportDropdown">Carports / Canopies</option>',
                        '<option value="laddersDropdown">Ladders</option>',
                        '<option value="shapesDropdown">Shapes</option>',
                        '<option value="electricalDropdown">Electrical Components</option>',
                        '<option value="evDropdown">EV Infrastructure</option>',
                        '<option value="telecomDropdown">Telecom Infrastructure</option>',
                    '</select>',
                '</div>',
            '</div>',
            '<div style="display:none;" class="tool-options selected-placeholder">',
                '<div class="model-library-selection">',
                    '<button class="sy start-seeding">Seed</button>',
                    '<button class="sy plant-trees">Plant</button>',
                '</div>',
            '</div>',
            '<div class="tool-options model-import-options">',
                '<hr>',
                '<div class="option buttons">',
                    '<div class="row-title">',
                        '<input id="allModelsChecked" type="checkbox" value=""/>',
                        '<div class="title">Select All</div>',
                        '<button style="display: none;" class="sy delete-all">Trash</button>',
                    '</div>',
                    '<button class="sy model-move">',
                        '<i class="fa fa-arrows" aria-hidden="true"></i>',
                        'Move',
                    '</button>',
                    '<button class="sy model-rotate">',
                        '<i class="fa fa-rotate-right" aria-hidden="true"></i>',
                        'Rotate',
                    '</button>',
                    '<button class="sy model-scale">',
                        '<i class="fa fa-arrows-alt" aria-hidden="true"></i>',
                        'Scale',
                    '</button>',
                '</div>',
                '<hr>',
                //'<div class="row-title">',
                //    '<input id="allModelsChecked" type="checkbox" value=""/>',
                //    '<div class="title">Select All</div>',
                //    '<button style="display: none;" class="delete-all">Trash</button>',
                //'</div>',
                '<div class="model-units">Units:&nbsp;<strong><span>Meters</span></strong></div>',
                '<div class="option model-list-container">',
                '</div>',
            '</div>',
        '</div>',
    ].join("\n");


    $('.placeholder.model-import').replaceWith(template);

    toggleUnits(options.getUnits())

    $('.model-move')  .click(function () { options.move(); });
    $('.model-rotate').click(function () { options.rotate(); });
    $('.model-scale') .click(function () { options.scale(); });

    $(document).on('click', '.model-list .actions .delete', function () {
        var item = $(this).parent().parent()
        var id = item.attr("data-id")
        var key = item.attr("data-key")

        prompts.confirm('Are you sure you want to delete this model?').then(function (ok) {
            if (ok) {
                // Library model
                if(modelList.sceneObjs && modelList.libModels) {
                    if(modelList.libModels[key] && modelList.sceneObjs[key]) {
                        if(modelList.sceneObjs[key]["annotationRefs"][id]) {
                            delete modelList.sceneObjs[key]["annotationRefs"][id]
                        } else { console.log("Model id not found.") }
                    }
                }
                options.delete(id);
                item.remove();
            }
        });
    });

    // TODO later: Handle hiding in Layers dialog, not individual rows
    $(document).on('click', '.model-list .actions .hide', function () {
        var modelId
        if($(this).parent().parent().attr("class").includes("vegetation")) {
            var idx = $(this).parent().parent().attr("data-id");
            // modelId = "vegetation-" + idx
        } else {
            // It's a model that was imported
            modelId = $(this).parent().parent().attr("data-id")
        }
        options.toggleModel(modelId)
    })
    if (!globalThis.viewer) {
      viewer.runViewerInit().bind(viewer);
    }
    modelList = new GUIModelList({
        container: $('.model-list-container'),
        models:    options.models
    });

    var SCANIFLY_IMPORT_MODEL_DROPDOWNS = [
        { name: "inverter", key:     "inverters", description: "Inverters" },
        { name: "highPoly", key:     "highPolyTrees", description: "Trees - High Poly" },
        { name: "carport", key:      "carports", description: "Carports" },
        { name: "lowPoly", key:      "lowPolyTrees", description: "Trees - Low Poly" },
        { name: "ladders", key:      "ladders", description: "Ladders" },
        { name: "shapes", key:       "shapes", description: "Shapes" },
        { name: "electrical", key:   "electrical-components", description: "Electrical Components" },
        { name: "ev", key:           "ev-infrastructure", description: "EV Infrastructure" },
        { name: "telecom", key:      "telecom-infrastructure", description: "Telecom Infrastructure" },
    ]

    var dropdowns = []

    SCANIFLY_IMPORT_MODEL_DROPDOWNS.forEach( dropdownObj => {
        let dropdownSelector = `#${dropdownObj.name}Dropdown`

        dropdowns.push(dropdownSelector)

        $(dropdownSelector).ddslick({
            data: SCANIFLY_GLOBAL_MODELS[dropdownObj.key],
            width: 223,
            selectText: dropdownObj.description,
            imagePosition: "right",
            onSelected: function(selectedData){
                $(".model-import-container .dd-select").css("top", "22px")

                modelList.species = selectedData.selectedData.value;
                modelList.treeData = selectedData.selectedData;
            }
        });
    })

    dropdowns.forEach( dropdownSelector => {
        $(dropdownSelector).hide()
    })

    $("dd-options").css("height", "15vh")

    let updateRowInputs = (idSet, dim, val) => {
        idSet.forEach( (id) => {
            $(`.model-item-${id} .actions`).children(dim).val(val)
        })
    }

    $(document).on("input", '.model-list .input-width, .model-list .input-height, .model-list .input-depth',
        function () {
            var id  = $(this).parent().parent().attr("data-id")
            var key = $(this).parent().parent().attr("data-key")

            var list = $(".model-list").children()
            var idsToUpdate = new Set([])

            for (var idx = list.length-1; idx >= 0 ; idx--) {
                var modelId      = $(list[idx]).attr("data-id")
                var modelKey     = $(list[idx]).attr("data-key")

                var classId = ".model-item-" + modelId
                if($(classId).children(".row-title").children("input").is(":checked")) {
                    if (modelList.libModels[modelKey] && modelList.sceneObjs[modelKey]) {
                        idsToUpdate.add(modelId)
                    }
                }
            }

            if($(this).hasClass("input-width")) {
                var w = $(this).parent().children(".input-width").val()
                // Convert to feet based on global switch
                var wConverted = (options.getUnits() === "feet") ? (w * 0.3048) : w
                if(id && key) {
                    // Library
                    modelList.sceneObjs[key]["annotationRefs"][id].size.x = parseFloat(wConverted)
                    updateRowInputs(idsToUpdate, ".input-width", w)
                }

                options.resizeHeightWidth(idsToUpdate, null, wConverted, null)
            }

            if($(this).hasClass("input-depth")) {
                var d = $(this).parent().children(".input-depth").val()
                var dConverted = (options.getUnits() === "feet") ? (d * 0.3048) : d
                if(id && key) {
                    modelList.sceneObjs[key]["annotationRefs"][id].size.z = parseFloat(dConverted)
                    updateRowInputs(idsToUpdate, ".input-depth", d)
                }
                options.resizeHeightWidth(idsToUpdate, null, null, dConverted)
            }

            if($(this).hasClass("input-height")) {
                var h = $(this).parent().children(".input-height").val()
                var hConverted = (options.getUnits() === "feet") ? (h * 0.3048) : h
                if(id && key) {
                    modelList.sceneObjs[key]["annotationRefs"][id].size.y = parseFloat(hConverted)
                    updateRowInputs(idsToUpdate, ".input-height", h)
                }
                options.resizeHeightWidth(idsToUpdate, hConverted, null, null)
            }
        }
    )

    $(document).on('click', '.delete-all', function() {
        prompts.confirm('Are you sure you want to delete the selected models?').then(function (ok) {
            if (ok) {
                // Go through all check boxes, delete any one that is checked
                var list = $(".model-list").children()

                var idsToDelete = new Set([])

                for (var idx = list.length-1; idx >= 0 ; idx--) {
                    var id      = $(list[idx]).attr("data-id")
                    var key     = $(list[idx]).attr("data-key")
                    var classId = ".model-item-" + id

                    idsToDelete.add(id)

                    if($(classId).children(".row-title").children("input").is(":checked")) {
                        if(modelList.libModels[key] && modelList.sceneObjs[key]) {

                            if (modelList.sceneObjs[key]["annotationRefs"][id]) {
                                delete modelList.sceneObjs[key]["annotationRefs"][id]
                            } else { console.log("Moded id not found.") }
                            // If key has no annotationRefs in sceneObjs, delete it from libModels
                            if(Object.keys(modelList.sceneObjs[key]["annotationRefs"]).length === 0) {
                                delete modelList.libModels[key]
                            }
                        }
                        $(classId).remove()
                    }
                }
                // All should be selected
                options.deleteSelected(idsToDelete);
                // Uncheck select all
                $("#allModelsChecked").prop("checked", false)
            }
        });
    })

    // On change of individual checkboxes
    $(document).on('change', ".row-title input", function() {
        if($(this).attr("id") === "allModelsChecked") {
            return;
        }
        var id = $(this).parent().parent().attr("data-id")
        if($(this).prop("checked")) {
            options.setSelection(null, true, id)
        } else {
            options.setSelection(null, false, id)
            // Uncheck select all
            $("#allModelsChecked").prop("checked", false)
        }
    })

    $(document).on('change', "#allModelsChecked", function() {
        // If checked, check all the others
        if($(this).is(":checked") == true) {
            $(".model-list").children().each(function(idx, item){
                $(item).children(".row-title").children("input").prop("checked", true)
            })
            $(".delete-all").css("display", "block")
            options.setSelection(null, false, null, "all")
        } else {
            $(".model-list").children().each(function(idx, item){
                $(item).children(".row-title").children("input").prop("checked", false);
            })
            $(".delete-all").css("display", "none")
            options.setSelection(null, false, null, "none")
        }
    })

    $(document).on("click", ".plant-trees", function() {
        if(!modelList.species) {
            prompts.info("Please select a specific model first.")
            return;
        }

        options.setSelection(null, false, null, "none")

        $(".stop-seeding").addClass("start-seeding")
        $(".stop-seeding").removeClass("stop-seeding")

        modelList.userSeeding = false
        options.init(true)

        $(".veg-annotation-markers").children().each(function(idx, marker) {
            marker.remove()
        })

        var key = modelList.treeData.name.split(" ").join("") + "_key"

        if(!modelList.libModels) {
            modelList.libModels = {}
        }

        if(!modelList.sceneObjs) {
            modelList.sceneObjs = {}
        }

        modelList.libModels[key] = modelList.treeData
        modelList.sceneObjs[key] = {
            "meshRefs": {},
            "annotationRefs": {}
        }

        var seeds = options.vegetationSeeds()

        options.removeSeeds()

        seeds.forEach(function(seed, idx) {
            if(seed.dirty) {
                var modelAndAnnotationKey = idx.toString() + "_" + key + Date.now()
                if(modelList.treeData.url.includes("high-poly")) {
                    modelAndAnnotationKey += "_highpoly"
                }

                // Add "_veg" identifier to vegetation for disabling (and auto updating) depth
                if(modelList.treeData.url.includes("vegetation")) {
                    modelAndAnnotationKey += "_veg"
                }

                modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey] = {
                    "pos": {},
                    "scale": {},
                    "size": {},
                    "text": "text_ref",
                    // "name": modelList.treeData.name + " #" + (idx+1),
                    "name": modelList.treeData.name,
                    "placed": false
                }

                var pos = options.updatePos(seed.position)

                modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["pos"] = $.extend({}, pos)
                modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["scale"].z = 1
                modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["scale"].x = 1
                modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["scale"].y = 1

                modelList.add({
                    id: modelAndAnnotationKey,
                    name: modelList.sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["name"],
                    type: "library",
                    libModel: key
                })

                $(`.model-list .${modelAndAnnotationKey}`).children(".row-title").children(".info").text(`${name}`)

                modelList.models[key] = {
                    type:    "library",
                    name:    key,
                    val:     modelList.sceneObjs[key],
                    percent: 0,
                    done:    true,
                    error:   ''
                };
            }
        })

        options.importVegetation(key, modelList.libModels[key], modelList.libModels, modelList.sceneObjs, modelList.treeData.imperial)
    });


    var pollAdditionalModels = function (taskId, modelId) {
        var pollPeriod = 15000;

        var f = function () {
            Net.pollAdditionalModels(taskId).then(function (response) {
                if (response.status == 'pending') {
                    setTimeout(f, pollPeriod);
                } else {
                    options.import(modelId);
                }
            });
        };

        setTimeout(f, pollPeriod);
    };

    Net.getDecimatorUrl().then(decimatorUrl => {
        new FineUploaderBasic({
            button:     $('.upload')[0],
            multiple:   false,
            request:    {
                endpoint: `${decimatorUrl}/upload`,
                params: {
                    project_id: project.id,
                    units:      options.getUnits
                }
            },
            validation: {
                allowedExtensions: ['zip']
                //sizeLimit: 52428800 // 50M
            },
            callbacks: {
                onSubmit: function(id, filename) {
                    modelList.add({ id: 'upload-' + id, name: filename });
                },
                onUpload: function(id, filename) { },
                onProgress: function(id, filename, loaded, total) {
                    modelList.update('upload-' + id, Math.round(loaded/total * 100));
                },
                onComplete: function(id, filename, response) {
                    modelList.processing('upload-' + id, response.id);

                    pollAdditionalModels(response.task_id, response.id);
                },
                onError: function (id, filename, error) {
                    modelList.error('upload-' + id, error);
                }
            }
        });
    });

    $('.input-model-import-units').change(function () {
        options.setUnits($(this).val());
    }).prop('selectedIndex', 0);

    $("#scanifly-model-options").change(function() {

        $(".model-import-container .dd-select").css("top", "48px")

        var dropdown = $(this).val()
        stopSeeding(options, true, dropdown)

        if(!dropdown) {
            $(".model-library-selection").hide()
        } else {
            $(".model-library-selection").show()
            $(".selected-placeholder").hide()
            $(".selected-placeholder").css("display", "none")
        }

        $("#" + dropdown).show()
        $("#" + dropdown).trigger("click")
        // hideAllExcept(dropdown)

        $(".selected-placeholder").css("display", "block")

        switch (dropdown) {
            case "lowPolyDropdown":
            case "highPolyDropdown":
                seedOrPlot("seed")
                break;
            case "carportDropdown":
            case "inverterDropdown":
            case "laddersDropdown":
            case "shapesDropdown":
            case "electricalDropdown":
            case "evDropdown":
            case "telecomDropdown":
                seedOrPlot("plot")
                break;
            default:
                break;
        }
    })

    $(document).on('click', '.start-seeding, .stop-seeding', function (e) {
        // Toggle userSeeding
        modelList.userSeeding = modelList.userSeeding ? false : true
        // Toggle class "start-seeding"
        $(".start-seeding, .stop-seeding").toggleClass("start-seeding stop-seeding")

        options.init(!modelList.userSeeding)
    })

    $('.header.model-import').click(function() {
        $(".eye-view-container .inner ul").css("display", "none")
        $(".eye-view-container .minimize i").removeClass("fa-arrow-down")
        $(".eye-view-container .minimize i").addClass("fa-arrow-down")
        options.init(true)
    });
};

function updateRows(modelsSelected, modelId, size, focusRow, clearAllRows, pos, units) {
    if(clearAllRows) {
        var list = $(".model-list-container .model-list")
        list.empty()
        return;
    }

    if(!modelId) {
        var list = $(".model-list").children()
        for (var idx = list.length-1; idx >= 0 ; idx--) {
            var row = list[idx]
            $(row).children(".row-title").children("input").prop("checked", false)
        }
        // Uncheck "Select All"
        $("#allModelsChecked").prop("checked", false)
        return;
    }

    var models = {}
    modelsSelected.forEach(function(model, idx) {
        models[model.name] = true
    })

    var list = $(".model-list").children()
    for (var idx = list.length-1; idx >= 0 ; idx--) {
        var row = list[idx]
        var id = $(row).attr("data-id")
        // If model is in models, check it
        if(models[id]) {
            $(row).children(".row-title").children("input").prop("checked", true)
        } else {
            $(row).children(".row-title").children("input").prop("checked", false)
        }
    }

    if(focusRow) {
        var list = $(".model-list").children()
        for (var index = 0; index < list.length; index++) {
            var item = list[index];
            if(item.className.includes("bkg")) {
                $(item).removeClass("bkg-animated")
            }

        }
        // Use model id to highlight row temporarily
        $(`.model-item-${modelId}`).addClass("bkg-animated")
    }

    if(size) {
        var key
        // TODO Store key on model obj
        for(let modelKey in modelList.sceneObjs) {
            for(let annotationId in modelList.sceneObjs[modelKey]["annotationRefs"]) {
                if (annotationId === modelId) {
                    key = modelKey
                }
            }
        }

        if(size.y) {
            var converted = (units === "feet" || modelList.sceneObjs[key]["annotationRefs"][modelId]["imperial"]) ? size.y * 3.28084 : size.y
            converted     = converted.toFixed(2)
            $(`.model-item-${modelId} .actions`).children(".input-height").val(converted)
            if(modelList && key) {
                if(modelList.sceneObjs[key]["annotationRefs"][modelId] && size.y) {
                    modelList.sceneObjs[key]["annotationRefs"][modelId]["size"].y = converted
                }
            }
        }
        if (size.x) {
            var converted = (units === "feet" || modelList.sceneObjs[key]["annotationRefs"][modelId]["imperial"]) ? size.x * 3.28084 : size.x
            converted     = converted.toFixed(2)
            $(`.model-item-${modelId} .actions`).children(".input-width").val(converted)
            if(modelList && key) {
                if(modelList.sceneObjs[key]["annotationRefs"][modelId]) {
                    modelList.sceneObjs[key]["annotationRefs"][modelId]["size"].x = converted
                }
            }
        }
        if(size.z) {
            var converted = (units === "feet" || modelList.sceneObjs[key]["annotationRefs"][modelId]["imperial"]) ? size.z * 3.28084 : size.z
            converted     = converted.toFixed(2)
            $(`.model-item-${modelId} .actions`).children(".input-depth").val(converted)
            if(modelList && key) {
                if(modelList.sceneObjs[key]["annotationRefs"][modelId]) {
                    modelList.sceneObjs[key]["annotationRefs"][modelId]["size"].z = converted
                }
            }
        }
    }

};

function updateRowModelUnits(units) {
    toggleUnits(units)

    var list = $("ul.model-list").children()

    list.each( (idx, row) => {
        let h = $(row).children(".actions").children(".input-height").val()
        let w = $(row).children(".actions").children(".input-width").val()
        let d = $(row).children(".actions").children(".input-depth").val()
        if(units === 1) {
            h = h * 3.28084
            w = w * 3.28084
            d = d * 3.28084
            $(".model-units span").text("Feet")
        } else {
            h = h * 0.3048
            w = w * 0.3048
            d = d * 0.3048
            $(".model-units span").text("Meters")
        }
        $(row).children(".actions").children(".input-height").val(h.toFixed(2))
        $(row).children(".actions").children(".input-width").val(w.toFixed(2))
        $(row).children(".actions").children(".input-depth").val(d.toFixed(2))
    })
}

function modelImportUpdate(options) {
    if(modelList) {
        modelList.updateAll(options.models, false);
    }
};

function addSceneObjs(sceneObjs, libModels) {
    if(modelList) {
        modelList.sceneObjs = sceneObjs
        modelList.libModels = libModels

        // Repopulate rows
        for (const key in sceneObjs) {
            if (sceneObjs.hasOwnProperty(key)) {
                const obj = sceneObjs[key]["annotationRefs"]
                for (const modelAndAnnotationKey in obj) {
                    if (obj.hasOwnProperty(modelAndAnnotationKey)) {

                        modelList.add({
                            id: modelAndAnnotationKey,
                            name: sceneObjs[key]["annotationRefs"][modelAndAnnotationKey]["name"],
                            type: "library",
                            libModel: key
                        })
                    }
                }
            }
        }
    }
}

function modelImportEnablePlaceButton(id) {
    $('.model-item-' + id + ' .actions .place').prop('disabled', false);
    $('.model-item-' + id + ' .actions .place').attr('aria-disabled', "false");
};

// Helpers
var seedOrPlot = function(action) {
    if(action === "seed"){
        $(".start-seeding").text("Seed")
        $(".plant-trees").text("Plant")
    } else {
        $(".start-seeding").text("Plot")
        $(".plant-trees").text("Place")
    }
}

var hideAllExcept = function(dropdownName, removeText) {
    let dropdowns = ["lowPolyDropdown", "highPolyDropdown",
                        "carportDropdown", "inverterDropdown",
                        "laddersDropdown", "shapesDropdown",
                        "electricalDropdown", "evDropdown", "telecomDropdown"]

    // hide everything
    dropdowns.forEach( (dropdown) => {
        $("#" + dropdown).hide()
        if(removeText) {
            $("#" + dropdown).children(".dd-select").children("a").text("Select")
        }
    })

    $("#" + dropdownName).show()
    $(".dd-options").css("position", "fixed")
}

var stopSeeding = function(options, removeText, keep) {
    $(".stop-seeding").addClass("start-seeding")
    $(".stop-seeding").removeClass("stop-seeding")

    hideAllExcept(keep, removeText)

    $(".dd-selected").css({"padding": "10px" })

    modelList.userSeeding = false
    modelList.species     = null
    options.init(true)
}

var toggleUnits = function(units) {
    if(units === 0) {
        $(".model-units span").text("Meters")
    } else {
        $(".model-units span").text("Feet")
    }
}


export {
  addSceneObjs,
  toggleUnits,
  stopSeeding,
  hideAllExcept,
  seedOrPlot,
  GUIModelList,
  modelList,
  modelImport,
  modelImportUpdate,
  modelImportEnablePlaceButton,
  updateRows,
  updateRowModelUnits
};
