import {
    emitEvent,
    prompts,
    project,
    treeVegetationAnnotation,
    measurementsTool,
    annotationsTool,
    modelImportTool,
    dxfExportTool,
    occluderTool,
    viewshedTool,
    shareTool,
    shadeTool,
    moduleSpecsTool,
    user,
    measurementUnits,
    toolSelector,
    currentDesign,
    csvExportTool,
    viewer,
    deTiltTool
} from '../Viewer.js';

import { Net } from '../libs/Net';
import { deepCopy } from '../libs/Utilities';
import { Permissions } from '../libs/Permissions';
import { GUI } from '../gui/Gui';
import { DeTiltTool } from '../tools/DeTiltTool.js';

function getSaveData() {
    var data;

    if (project.details.lastSave) {
        data = project.details.lastSave.data;
    } else {
        data = project.details.viewer3d;

        // handle legacy save format
        if (data && data.modelImportTool) {
            data.modelImportTool.libModels = project.details.libModels;
            data.modelImportTool.sceneObjs = project.details.sceneObjs;
        }
    }

    return data;
}


function embedLoadChanges() {
    // automatically load the map and solar panels in embed mode
    var saveData = getSaveData() || {};
    var gui = new GUI();

    if (saveData.annotationsTool)
        annotationsTool.restoreSaveData(saveData.annotationsTool);

    if (saveData.shadeTool)
        shadeTool.restoreSaveData(saveData.shadeTool);

    if (saveData.moduleSpecsTool) {
        saveData.moduleSpecsTool.embed = true
        let msData = getFixedModuleSpecsSaveData(saveData);
        moduleSpecsTool.restoreSaveData(msData);
    }

    if (saveData.modelImportTool) {
        modelImportTool.setUpdateGui(
            function (models, id, scale, focusRow, clearAllRows, pos) {
                var units = (measurementUnits == 1) ? "feet" : "meters"
                gui.updateRows(models, id, scale, focusRow, clearAllRows, pos, units);
            })
        ;

        gui.modelImport({
            init: function () {
                toolSelector.enable(modelImportTool);
            },
            models:     project.details.additionalModels,
            getUnits:   function ()      {
                var units = (measurementUnits == 1) ? "feet" : "meters"
                return units
            }
        });

        gui.addSceneObjs(saveData.modelImportTool.sceneObjs,
                          saveData.modelImportTool.libModels);

        modelImportTool.restoreSaveData(
            project,
            saveData.modelImportTool,
            true
        );
    }

    if(saveData.deTiltTool) {
        deTiltTool.restoreSaveData(saveData.deTiltTool);
    }
}


function loadChanges(saveData, gui) {
    if (globalThis.viewer.viewerChangesLoaded) {
        console.log('saved data cannot be loaded more than once');
        return;
    }

    try {
        if (saveData.measurementsTool)
            measurementsTool.restoreSaveData(saveData.measurementsTool);

        // TODO: make sure permissions are up to date
        var p = Permissions;

        if (p.hasPermission(user, project, p.SHADE) && saveData.shadeTool)
            shadeTool.restoreSaveData(saveData.shadeTool);

        if (p.hasPermission(user, project, p.ANNOTATIONS) && saveData.annotationsTool)
            annotationsTool.restoreSaveData(saveData.annotationsTool);

        //if (p.hasPermission(user, project, p.ANNOTATszIONS) && saveData.treeVegetationAnnotation)
        //    treeVegetationAnnotation.restoreSaveData(saveData.treeVegetationAnnotation);

        if (p.hasPermission(user, project, p.VIEWSHEDS) && saveData.viewshedTool)
            viewshedTool.restoreSaveData(saveData.viewshedTool,
                                        saveData.viewshedAnnotationsTool,
                                        annotationsTool.container);

        if (p.hasPermission(user, project, p.DXF_EXPORT) && saveData.dxfExportTool)
            dxfExportTool.restoreSaveData(saveData.dxfExportTool);

        if (saveData.occluderTool)
            occluderTool.restoreSaveData(saveData.occluderTool);

        if (p.hasPermission(user, project, p.SOLAR_PANELS)) {
            if (saveData.moduleSpecsTool) {
                let msData = getFixedModuleSpecsSaveData(saveData);

                moduleSpecsTool.restoreSaveData(msData);
            }
        }

        if (Permissions.hasPermission(user, project, Permissions.IMPORT_MODEL) && saveData.modelImportTool) {
            gui.addSceneObjs(saveData.modelImportTool.sceneObjs,
                              saveData.modelImportTool.libModels);

            modelImportTool.restoreSaveData(
                project,
                saveData.modelImportTool,
                false
            );
        }

        if(saveData.deTiltTool) {
            deTiltTool.restoreSaveData(saveData.deTiltTool);
        }

        // Project Share update
        if (Permissions.hasPermission(user, project, Permissions.PROJECT_INFO) && saveData.shareDetails) {
            shareTool.restoreSaveData(saveData.shareDetails);
        }

        if (    p.hasPermission(user, project, p.VIEWSHEDS) &&
                p.hasPermission(user, project, p.SOLAR_PANELS)) {
            moduleSpecsTool.propagateState();
            moduleSpecsTool.showMidpointItems();
        }
    } catch (e) {
        prompts.info('Scanifly3D encountered an error while loading saved chages');
        throw e;
    } finally {
        globalThis.viewer.viewerChangesLoaded = true;
    }
}


/**
 * Apply fixes and modifications to save data to make it compatible with current code.
 *
 * A seed for viewer data migrations in the future?
 */
function getFixedModuleSpecsSaveData(saveData) {
    let msData = deepCopy(saveData.moduleSpecsTool);

    if (msData.planes === undefined) { // backward compatibility crutch
        msData.planes = [];

        for (let i = 1; i < saveData.viewshedTool.length; i++) {
            let plane = saveData.viewshedTool[i].plane;
            msData.planes.push(plane);
        }
    }

    return msData;
}


/**
 * @param {((progress: number, msg?: string) => void)?} notifyProgressCb Callback
 *   triggered to notify caller of save progress.
 */
async function saveDesign(designId, notifyProgressCb = () => {}) {
    let stepsComplete = 0;
    const totalSteps = 2;

    /**
     * A .then handler to update the step count and notify progress with given {msg}.
     * @param {string} msg
     */
    const notifyProgress = msg => {
        return /** @template T @param {T} r */ r => {
            stepsComplete++;
            notifyProgressCb(stepsComplete / totalSteps, msg);
            return r;
        };
    };

    const msSaveData = await moduleSpecsTool.getSaveData().then(notifyProgress("Production data updated"));

    const data = {
        moduleSpecsTool: msSaveData,
        shadeTool: shadeTool.getSaveData(),
        shareDetails: shareTool.getSaveData(),
        viewshedTool: viewshedTool.getSaveData(),
        occluderTool: occluderTool.getSaveData(),
        dxfExportTool: dxfExportTool.getSaveData(),
        modelImportTool: modelImportTool.getSaveData(),
        annotationsTool: annotationsTool.getSaveData(),
        measurementsTool: measurementsTool.getSaveData(),
        treeVegetationAnnotation: treeVegetationAnnotation.getSaveData(),
        deTiltTool: deTiltTool.getSaveData()
    };

    return Net.updateProjectDesign(designId, {
        shareLinkUrl:                       shareTool.shareUrl ? encodeURI(shareTool.shareUrl) : undefined,
        thumbnailUrl:                       viewer.thumbnail,
        ASA:                                msSaveData.numbers.totalAvgASA,
        systemSize:                         msSaveData.numbers.systemSize,
        offset:                             msSaveData.numbers.systemOffset,
        annualProduction:                   msSaveData.numbers.totalAnnualProduction,
        annualConsumption:                  msSaveData.numbers.totalAnnualConsumption,
        monthlyProduction:                  msSaveData.numbers.monthlyProduction,
        monthlyConsumption:                 msSaveData.numbers.monthlyConsumption,
        avgMonthlySolarAccess:              msSaveData.numbers.avgMonthlySolarAccess,
        shadeReportUrl:                     dxfExportTool.shadeReportUrl ? encodeURI(dxfExportTool.shadeReportUrl) : undefined,
        horizonProfileUrl:                  csvExportTool.horizonProfileUrl ? encodeURI(csvExportTool.horizonProfileUrl) : undefined,
        hourlyProductionUrl:                csvExportTool.hourlyProductionUrl ? encodeURI(csvExportTool.hourlyProductionUrl) : undefined,
        stlSurfaceModelUrl:                 dxfExportTool.stlSurfaceModelUrl ? encodeURI(dxfExportTool.stlSurfaceModelUrl) : undefined,
        objSurfaceModelUrl:                 dxfExportTool.objSurfaceModelUrl ? encodeURI(dxfExportTool.objSurfaceModelUrl) : undefined,
        daeSurfaceModelUrl:                 dxfExportTool.daeSurfaceModelUrl ? encodeURI(dxfExportTool.daeSurfaceModelUrl) : undefined,
        dxfParallelProjectionsAllPlanesUrl: dxfExportTool.dxfParallelProjectionsAllPlanesUrl ?encodeURI(dxfExportTool.dxfParallelProjectionsAllPlanesUrl) : undefined,
        dxf2DWireframeUrl:                  dxfExportTool.dxf2DWireframeUrl ? encodeURI(dxfExportTool.dxf2DWireframeUrl) : undefined,
        dxf3DWireframeUrl:                  dxfExportTool.dxf3DWireframeUrl ? encodeURI(dxfExportTool.dxf3DWireframeUrl) : undefined,

        data

        // TODO: remove when confirmed these are not needed
        // shadingGraphUrl - generated on the fly in project info?
        // productionGraphUrl - consumptionProductionGraph ms
    }).then(
        notifyProgress("3D viewer data saved")
    );
}


async function saveChanges() {
    if (! await prompts.confirm('Are you sure you would like to overwrite currently saved data?'))
        return;

    try {
        await saveDesign(currentDesign.id, pct => updateSaveStatusCb(pct));
    } catch (error) {
        prompts.info('Data could not be saved, please try again later');
        throw error;
    }

    emitEvent('status', {message: 'Data saved successfully'});
}

function viewshedsSaved() {
    let saved = false;
    let saveData = getSaveData();

    if (saveData) {
        let planes = saveData.viewshedTool;

        planes.forEach( planeInfo => {
            if(planeInfo.viewsheds && planeInfo.viewsheds.length > 0){
                saved = true;
            }
        })
    }

    return saved;
}

/**
 * @param {number} pct  Completion percentage
 * @param {string?} [msg] Status message
 */
function updateSaveStatusCb(pct, msg) {
    emitEvent('progress', {percent: Math.round(pct * 100)});
    if (msg) {
        emitEvent('status', {message: msg});
    }
}

export { embedLoadChanges, updateSaveStatusCb, saveDesign, saveChanges, getSaveData, viewshedsSaved, loadChanges };
