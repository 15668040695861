import { prompts, init } from '../Viewer.js';






class AnnotationsToolGUI {
    constructor(options) {
        var t = `
            <h3 class="header annotations-tool">Annotations</h3>
            <div class="tool-options">
                <div class="annotations">
                </div>

                <div class="annotations-empty">
                    Click anywhere on the model to add an annotation.
                </div>
            </div>
        `;

        $('.placeholder.annotations-tool').replaceWith(t);

        this.options = options;
    }


    registerGetState   (callback) { this.getState    = callback; }
    registerUpdateState(callback) { this.updateState = callback; }


    init(callback) {
        var self = this;

        $('body').on('click', '.header.annotations-tool', callback);

        $('body').on('click', '.annotation-form button.save', function () {
            var form = $(this).parents('.annotation-form');
            var aid = form.attr('data-id');

            var s = self.getState();

            s.annotations[aid].subject     = $('.input-subject',     form).val();
            s.annotations[aid].description = $('.input-description', form).val();
            s.annotations[aid].dirty       = false;
            s.annotations[aid].active      = false;

            self.updateState(s);
            self.renderState();
        });

        $('body').on('click', '.annotation-form button.cancel', function () {
            var form = $(this).parents('.annotation-form');
            var aid = form.attr('data-id');

            self.options.remove(aid);
        });

        $('body').on('click', '.annotation button.edit', function () {
            var s = self.getState();

            s.annotations[$(this).attr('data-id')].dirty = true;

            self.updateState(s);
            self.renderState();
        });

        $('body').on('click', '.annotation button.remove', function () {
            let id = $(this).attr('data-id');

            prompts.confirm('Are you sure you would like to remove this annotation?').then(function (ok) {
                if (ok)
                    self.options.remove(id);
            });
        });
    }


    renderState() {
        var s = this.getState();

        var hidePopup = true;

        for (let i = 0; i < s.annotations.length; i++) {
            let a = s.annotations[i];

            if (a.active && (a.subject || a.description)) {
                hidePopup = false;

                $('#annotation-marker-popup')
                    .css('display', 'block')
                    .css('top',  (a.popupPos.y + 10) + 'px')
                    .css('left', (a.popupPos.x + 10) + 'px');

                $('.subject',     '#annotation-marker-popup').html(a.subject);
                $('.description', '#annotation-marker-popup').html(a.description);
            }
        }

        if (hidePopup)
            $('#annotation-marker-popup').css('display', 'none');

        if (s.popupOnly) {
            return;
        }

        $('.annotations').empty();

        if (s.annotations.length == 0) {
            $('.annotations-empty').css('display', 'block');
            $('#annotation-marker-popup').css('display', 'none');
        } else {
            $('.annotations-empty').css('display', 'none');
        }

        var hidePopup = true;

        for (let i = 0; i < s.annotations.length; i++) {
            let a = s.annotations[i];
            let t;

            if (a.dirty) {
                t = `
                    <div class="annotation-form annotation-${i}" data-id="${i}">
                        <div class="option">
                            <div class="label">
                                Subject:
                            </div>
                            <div class="input-wrapper">
                                <input class="input-subject" type="text" value="${a.subject}" aria-label="subject"/>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="option">
                            <div class="label">
                                Description:
                            </div>
                            <div class="input-wrapper">
                                <textarea class="input-description" aria-label="description">${a.description}</textarea>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="option actions">
                            <div class="label">
                                &nbsp;
                            </div>
                            <div class="input-wrapper">
                                <button class="sy cancel"><i class="fa fa-trash" aria-hidden="true"></i> Trash</button>
                            </div>
                            <div class="clearfix"></div>
                            <div class="input-wrapper">
                                <button class="sy save"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                `;
            } else {
                t = `
                    <div class="annotation annotation-${i}">
                        <div class="header" data-id="${i}">
                            ${i+1}: ${a.subject}
                        </div>
                        <div class="option">
                            <div class="label">
                                Description:
                            </div>
                            <div class="input-wrapper">
                                ${a.description}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="option">
                            <div class="label">
                                &nbsp;
                            </div>
                            <div class="input-wrapper">
                                <button class="sy edit" data-id="${i}">Edit</button>
                                <button class="sy red remove" data-id="${i}">Remove</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                `;
            }

            $('.annotations').append($(t));
        }

        // TODO: adjust the height of the ui pane correctly
        var container = $('.annotations');
        container.css('height', 'auto');
        container.css('overflow', 'visible');

        var difference = $(window).height() - $('.gui.rhs').height();

        if (difference < 0) {
            container.css('height', (container.height() + difference) + 'px');
            container.css('overflow', 'auto');
        }
    }
}


export { AnnotationsToolGUI };
