import { loadAdditionalModel, initToolsWithAdditionalModel, getAdditionalModel, render } from '../Viewer.js';
import { modelList } from '../gui/GuiModelImport';
import { TransformControls } from '../controls/TransformControls';
import * as THREE from '../libs/three.module';





var ModelImportTool = function () {
    this.models = [];

    this.modelsSelected = []
    this.modelPreUpdate = null
    this.sceneObjs = []

    this.placementHeight = 10;
    this.originalScale = 1.0;
};

ModelImportTool.prototype = {};
ModelImportTool.prototype.constructor = ModelImportTool;

ModelImportTool.prototype.setCamera = function (camera, domElement) {
    this.camera = camera;

    this.controls = new TransformControls(camera, domElement);
    this.controls.addEventListener('change', render);
    this.controls.setMode('translate');
    this.controls.setSize(0.5);
};

ModelImportTool.prototype.setContainerObject = function (container) { this.container = container }

ModelImportTool.prototype.setRaycaster = function (rc) {
    this.raycaster = rc;
};

ModelImportTool.prototype.addModel = function (id, model, pos, rot, scale, size, type) {
    model.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    var object = new THREE.Object3D();

    object.name = id;
    object.add(model);

    if (type) {
        object.type = type
    }
    if (pos) {
        object.position.set(pos.x, pos.y, pos.z);
    } else {
        object.position.y = this.placementHeight;
    }

    if (rot) {
        var euler = new THREE.Euler(rot.x, rot.y, rot.z, rot.order);
        object.setRotationFromEuler(euler);
    }

    if (scale) {
        object.scale.set(scale.x, scale.y, scale.z);
    } else {
        object.scale.set(1,1,1);
    }

    var scaled
    if (!size) {
        size = this.calculateSize(object)
    }

    scaled = this.scaledSize(size, object.scale)

    var box = new THREE.BoxHelper( model, 0xffff00 );
    box.visible = false;
    //box.visible = true;
    object.add(box);

    this.scene.add(object);

    for (var i = 0; i < this.models.length; i++)
        this.setSelection(this.models[i], false);

    this.models.push(object);

    this.updateGui([], id, scaled, false, false)

    return object;
};

ModelImportTool.prototype.clearModels = function () {
    this.models = []
    this.updateGui([], null, null, false, true)
}

ModelImportTool.prototype.resizeHeightWidth = function(modelIdSet, height, width, depth) {
    for (var i = 0; i < this.models.length; i++) {
        if (modelIdSet.has(this.models[i].name)) {
            var previousSize = this.calculateSize(this.models[i])

            var geometry            = new THREE.BoxGeometry(width, height, depth)
            var newObjectDimensions = new THREE.Mesh(geometry)

            newObjectDimensions.geometry.computeBoundingBox()
            var newSize = newObjectDimensions.geometry.boundingBox.getSize()

            if(width) {
                this.models[i].scale.x = newSize.x / (previousSize.x || 1) || this.models[i].scale.x
            }
            if(height) {
                this.models[i].scale.y = newSize.y / (previousSize.y || 1) || this.models[i].scale.y
            }
            if(depth) {
                this.models[i].scale.z = newSize.z / (previousSize.z || 1) || this.models[i].scale.z
            }
        }
    }
}

ModelImportTool.prototype.addToScene = function (scene) {
    this.scene = scene;
    this.scene.add(this.controls);
};

ModelImportTool.prototype.render = function () {
    if (this.selected) {
        this.controls.update();
    }
};

ModelImportTool.prototype.setUpdateGui = function (callback) {
    this.updateGui = callback;
};

ModelImportTool.prototype.enable = function () {
    this.enabled = true;
};

ModelImportTool.prototype.cancel = function () {
    if (this.models.length > 1) {
        this.models.forEach((obj) => {
            this.setSelection(obj, false, obj.name)
        })
    }

    if (this.selected)
        this.setSelection(this.selected, false);

    this.resetSelections()
};

ModelImportTool.prototype.resetSelections = function () {
    this.modelsSelected = []
    this.updateGui([], null, null, false)
}

ModelImportTool.prototype.disable = function () {
    if (!this.enabled)
        return;

    this.cancel();
    this.enabled = false;
};

ModelImportTool.prototype.scaleToUnits = function (model, units) {
    if (units === 'feet')
        model.scale.set(0.3048, 0.3048, 0.3048);
};

ModelImportTool.prototype.resetPreUpdate = function (model) {
    var preUpdate = {}

    preUpdate.scale = {}
    preUpdate.position = {}
    preUpdate.rotation = {}

    preUpdate.scale.x = model.scale.x
    preUpdate.scale.y = model.scale.y
    preUpdate.scale.z = model.scale.z
    preUpdate.position.x = model.position.x
    preUpdate.position.y = model.position.y
    preUpdate.position.z = model.position.z
    preUpdate.rotation.x = model.rotation.x
    preUpdate.rotation.y = model.rotation.y
    preUpdate.rotation.z = model.rotation.z

    this.modelPreUpdate = preUpdate
}

ModelImportTool.prototype.modelChange = function (model) {
    if(this.modelPreUpdate) {
        if(
            model.scale.x !== this.modelPreUpdate.scale.x ||
            model.scale.y !== this.modelPreUpdate.scale.y ||
            model.scale.z !== this.modelPreUpdate.scale.z ||
            model.position.x !== this.modelPreUpdate.position.x ||
            model.position.y !== this.modelPreUpdate.position.y ||
            model.position.z !== this.modelPreUpdate.position.z ||
            model.rotation.x !== this.modelPreUpdate.rotation.x ||
            model.rotation.y !== this.modelPreUpdate.rotation.y ||
            model.rotation.z !== this.modelPreUpdate.rotation.z
        ) {
            var diff = {}
            diff.x = model.position.x - this.modelPreUpdate.position.x
            diff.y = model.position.y - this.modelPreUpdate.position.y
            diff.z = model.position.z - this.modelPreUpdate.position.z
            return [true, diff]
        } else {
            return [false, false]
        }
    }
    return [false, false]
}

ModelImportTool.prototype.mouseUp = function (event, mouse, panning) {
    if (!this.enabled || event.button !== THREE.MOUSE.LEFT || panning)
        return;

    var model = this.selected || this.raycastModel(mouse);

    if(this.selected) {
        var mc = this.modelChange(model)
        var updated = mc[0]
        var positionDiff = mc[1]
        // Only update other models selected if there was a change in rotation/position/scale

        if(model) {
            this.resetPreUpdate(model)
            if(updated) {
                if (this.modelsSelected.length > 1) {
                    this.modelsSelected.forEach(function(model) {
                        if(model !== this.selected) {
                            if(this.controls.getMode() === "scale") {
                                model.scale.x = this.selected.scale.x
                                model.scale.y = this.selected.scale.y
                                model.scale.z = this.selected.scale.z
                            }
                            if(this.controls.getMode() === "rotate") {
                                model.rotation.x = this.selected.rotation.x
                                model.rotation.y = this.selected.rotation.y
                                model.rotation.z = this.selected.rotation.z
                            }
                            if(this.controls.getMode() === "translate") {
                                model.position.x += positionDiff.x
                                model.position.y += positionDiff.y
                                model.position.z += positionDiff.z
                            }
                        } else {
                        }
                        var size = this.calculateSize(this.selected)
                        var updatedSize = this.scaledSize(size, model.scale)

                        this.updateGui(this.modelsSelected, model.name, updatedSize, false, false, model.position)
                    }, this)
                } else {
                    var size = this.calculateSize(this.selected)
                    var updatedSize = this.scaledSize(size, model.scale)
                    this.updateGui(this.modelsSelected, model.name, updatedSize, false, false, model.position)
                }
            }
            var size = this.calculateSize(this.selected)
            var updatedSize = this.scaledSize(size, model.scale)

            this.updateGui(this.modelsSelected, this.selected.name, updatedSize, true)
        }


    } else {
        this.modelPreUpdate = null
        this.modelsSelected = []
        this.updateGui(this.modelsSelected, null, null, false)
    }
};

ModelImportTool.prototype.scaledSize = function (size, scale) {
    var scaled = new THREE.Vector3(size.x*scale.x, size.y*scale.y, size.z*scale.z)
    return scaled
}

ModelImportTool.prototype.mouseDown = function (event, mouse, panning) {
    if (!this.enabled || event.button !== THREE.MOUSE.LEFT)
        return;

    // Note: this.selected will always be the last model selected.

    var model = this.raycastModel(mouse);

    if (model !== null) {
        this.resetPreUpdate(model)
        this.setSelection(model, true);
    } else {
        for (var i = 0; i < this.modelsSelected.length; i++) {
            this.setSelection(this.modelsSelected[i], false, this.modelsSelected[i].name);
        }
        this.modelsSelected = []
        this.modelPreUpdate = null
        this.updateGui(this.modelsSelected, null, null, false, false)
        this.cancel()
    }
};

ModelImportTool.prototype.mouseMove = function (mouse, panning) { };
ModelImportTool.prototype.mouseWheel = function (mouse) { };

ModelImportTool.prototype.handleKeyboard = function (keyboard) {
    if (!this.enabled)
        return;

    if (keyboard.down('esc'))
        this.cancel();
};

ModelImportTool.prototype.raycastModel = function (mouse) {
    this.raycaster.setFromCamera(mouse, this.camera);

    let objs = this.models.slice()
    objs.push(this.container)

    let intersects  = this.raycaster.intersectObjects(objs, true);
    let sceneModels = new Set(this.models)

    if (intersects.length > 0) {
        let clicked = intersects[0].object.parent.parent

        if(clicked === this.container || !sceneModels.has(clicked)) {
        // if (clicked === this.container || clicked === scene) {
            return null
        } else {
            return clicked
        }
    }

    return null;
};

ModelImportTool.prototype.delete = function (id) {
    var models = [];

    for (var i = 0; i < this.models.length; i++) {
        if (this.models[i].name === id)
            this.scene.remove(this.models[i]);
        else
            models.push(this.models[i]);
    }

    this.models = models;
};

ModelImportTool.prototype.deleteSelected = function () {
    if (this.selected) {
        this.scene.remove(this.selected);
    }

    if (this.modelsSelected.length > 1) {
        // Go through and check which ones are selected
        for (var i = 0; i < this.modelsSelected.length; i++) {
            this.delete(this.modelsSelected[i].name)
        }
    }

    this.modelsSelected = []
};

ModelImportTool.prototype.move = function () {
    if (this.selected) {
        this.controls.setMode('translate');
    }
};

ModelImportTool.prototype.rotate = function () {
    if (this.selected) {
        this.controls.setMode('rotate');
    }
};

ModelImportTool.prototype.scale = function () {
    if (this.selected) {
        this.controls.setMode('scale');
    }
};

ModelImportTool.prototype.calculateSize = function (obj) {
    var size = { x:0, y:0, z:0 }

    if(obj.name.includes("highpoly")) {
        obj.children[0].children[0].geometry.computeBoundingBox()
        size = obj.children[0].children[0].geometry.boundingBox.getSize()
        size.x = size.x || 1
        size.y = size.y || 1
        size.z = size.z || 1
    } else {
        obj.children[0].children.forEach( childMesh => {
            childMesh.geometry.computeBoundingBox()
            var childSize = childMesh.geometry.boundingBox.getSize()
            size.x += childSize.x
            size.y += childSize.y
            size.z += childSize.z
        })
    }
    return size
}

ModelImportTool.prototype.selectAll = function (all) {
    this.modelsSelected = (all) ? this.models : []

    this.models.forEach(function(model) {
        var id = model.name
        if(all) {
            this.setSelection(model, true, id)
        } else {
            this.setSelection(model, false, id)
        }
    }, this);

    if (all) {
        this.selected = this.selected ? this.selected : this.modelsSelected[0];

        if (this.selected) {
            this.resetPreUpdate(this.selected);
            this.controls.attach(this.selected);
        }
    }
}

ModelImportTool.prototype.setSelection = function (object, val, id, allModels) {

    if(allModels === "all"){
        this.selectAll(true)
        return
    }else if (allModels === "none"){
        this.selectAll(false)
        return
    }

    if(id) {
        this.models.forEach(function(model) {
            if(id) {
                if(model.name === id) {
                    object = model
                    model.children[1].visible = val
                }
            }
        })
    }

    if(object) {
        object.children[1].visible = val;
        this.selected = val ? object : null;
        if (val) {
            // Don't reselect selected models
            if(!this.modelsSelected.some( model => {
                return model.name === object.name
            })) {
                this.modelsSelected.push(object)
                this.controls.attach(object);
            }
        } else {
            let models = []
            this.modelsSelected.forEach( (model) => {
                if(model.name === id) {
                    // ID to de-select
                    this.controls.detach(model);
                    model.children[1].visible = false
                } else {
                    models.push(model)
                    model.children[1].visible = true
                }
            })
            this.modelsSelected = models
            this.controls.detach(object);
            this.controls.setMode('translate');

            // One of modelsSelected should always have controls
            if(this.modelsSelected.length >= 1) {
                this.selected = this.selected ? this.selected : this.modelsSelected[0]
                this.resetPreUpdate(this.selected)
                this.controls.attach(this.selected);
            } else {
                this.models.forEach( (obj) => {
                    obj.children[1].visible = false
                })
            }
        }
    }
};

// Use with layers gui
ModelImportTool.prototype.toggleModel = function (id) {
    for (var i = 0; i < this.models.length; i++) {
        var selectObj = false

        if (this.models[i].name === id) {
            var object = this.models[i]
            object.traverse ( function (child) {
                if (child instanceof THREE.Mesh) {
                    if(child.visible) {
                        child.visible = false
                        selectObj = false
                    } else {
                        child.visible = true;
                        selectObj = true
                    }
                }
            });
            this.setSelection(object, selectObj)
        }
    }
}


ModelImportTool.prototype.getModelsInfo = function () {
    return this.models
}

ModelImportTool.prototype.getSaveData = function () {

    var data = {};

    for (var i = 0; i < this.models.length; i++) {
        var model = this.models[i];

        if (!data[model.name]) {
            data[model.name] = [];
        }

        var size = this.calculateSize(model)

        data[model.name].push({
            pos: {
                x: model.position.x,
                y: model.position.y,
                z: model.position.z
            },
            rot: {
                x:     model.rotation.x,
                y:     model.rotation.y,
                z:     model.rotation.z,
                order: model.rotation.order
            },
            scale: {
                x: model.scale.x,
                y: model.scale.y,
                z: model.scale.z
            },
            size: {
                x: size.x,
                y: size.y,
                z: size.z
            },
            type: model.type,
            id: model.name || "",
            key: model.userData || ""
        });
    }

    data.libModels = modelList.libModels;
    data.sceneObjs = modelList.sceneObjs;

    return data;
};

ModelImportTool.prototype.restoreSaveData = function (project, data, embed) {
    var libModels = (data && data.libModels) || {};
    var sceneObjs = (data && data.sceneObjs) || {};

    var self = this;

    // Handle libModels / library first
    for (const key in libModels) {
        if (libModels.hasOwnProperty(key) &&
            (sceneObjs.hasOwnProperty(key) &&
            Object.keys(sceneObjs[key]["annotationRefs"]).length > 0)) {

            const model = libModels[key];

            var success = function (key, model) {
                return function (obj) {

                    for (var id in data) {
                        if (data[id][0] && data[id][0].type === "library" && key === data[id][0].key) {
                            const pos   = data[id][0]["pos"]
                            const scale = data[id][0]["scale"]
                            const rot   = data[id][0]["rot"]
                            const size  = data[id][0]["size"]

                            const objCopy = obj.clone()

                            const modelObj = self.addModel(
                                id, objCopy, pos, rot, scale, size, "library"
                            );
                            modelObj.userData = key
                            if(!embed) { self.setSelection(modelObj, false); }
                        }
                    }
                }
            } (key, libModels[key]);

            if(model) {
                loadAdditionalModel(model).then(success).done();
            }
        }
    }

    for (var id in data) {
        if (data[id] && data[id][0] && data[id][0].type !== "library") {
            var model = getAdditionalModel(project, id);

            var success = function (id, data) {
                return function (obj) {
                    for (var i = 0; i < data.length; i++) {
                        var clone = obj.clone();

                        self.scaleToUnits(clone, model.units);

                        var modelObj = self.addModel(id, clone, data[i].pos, data[i].rot, data[i].scale, data[i].size, "imported");

                        initToolsWithAdditionalModel(modelObj);
                    }

                    self.selectAll(false)
                };
            } (id, data[id]);

            if(model) {
                loadAdditionalModel(model).then(success).done();
            }
        }
    }
};

export { ModelImportTool };
