/// @ts-check

import { PolyContext } from "../../draw/PolyContext";
import { CollisionDetector } from "../CollisionDetector";
import { DensityGrid } from "../DensityGrid";
import { Vector2, Vector3 } from "../three.module";


export default class BoundsCentersCheckerboardPlacementStrategy {
    /**
     * @param {number} density
     * @param {import("../../tools/DxfExportTool").OutlineContext} outlineContext
     * @param {import("../../tools/DxfExportTool").Keepout[]} keepoutContexts
     * @param {number | undefined} azimuthOverride
     */
    constructor(
        density,
        outlineContext,
        keepoutContexts,
        azimuthOverride
    ) {
        this.density = density;
        this.azimuth = azimuthOverride;
        this.keepoutContexts = keepoutContexts;

        const setbackCtx = this.keepoutContexts.find(
            /**
             * @param {import("../../tools/DxfExportTool").Keepout} ctx
             * @returns {ctx is PolyContext<'fire-setback'>}
             */
            ctx => ctx.shape === 'fire-setback'
        );

        const gridCtx = setbackCtx || outlineContext;

        this.polyBounds = gridCtx.getLocalCoordinateFramePoly2(azimuthOverride);
        this.localGroupRef = gridCtx.getLocalCoordinateFrameGroup(azimuthOverride);
    }

    *#locationIterator() {
        const { minX, minY, maxX, maxY } = this.polyBounds;
        const gridIterator = new DensityGrid(this.density).gridFromBoundsCentersCheckerboard(
            new Vector2(minX, minY),
            new Vector2(maxX, maxY)
        );

        const cd = new CollisionDetector({
            // @ts-ignore -- threejs types are a mess right now :|
            ref: this.localGroupRef,
            bounds2: this.polyBounds,
            keepouts: this.keepoutContexts
        });

        for (const point of gridIterator) {
            if (this.polyBounds.contains(point)
                // not in a poly keepout
                && !cd.polys.some(k => k.contains(point))
                // not in a circle keepout
                && !cd.circles.some(
                    c => point.clone().sub(c.center).length() < c.radius
                )
                // not checking line keepouts: line + offset === poly
                // and line + no offset === effectively no chance of intersection
            ) {
                yield this.localGroupRef.localToWorld(new Vector3(point.x, 0.05, point.y));
            }
        }
    }

    getLocations() {
        return Array.from(this.#locationIterator());
    }
}
