import { prompts, mouseHandler } from '../Viewer.js';
import { saveChanges} from '../core/Saves.js';
import tippy from 'tippy.js';


class HeaderGUI {
    /**
     * @param {{
     *   tool: ImageTool,
     *   getState: () => any,
     *   switchUnits: (_: boolean) => void,
     *   switchProjection: (_: boolean) => void
     *   [k: string]: any
     * }} param0
     */
    constructor({tool, getState, switchUnits, switchProjection, ...otherOptions}) {
        this.options = otherOptions;
        this.tool = tool;
        this.getState = getState;
        this.switchUnits = switchUnits;
        this.switchProjection = switchProjection;
    }

    /**
     * @param {'screenshot' | 'profilePicture'} c
     * @param {any} v
     */
    cmd(c, v) {
        return this.tool.cmd(c, v);
    }

    init() {
        let s = this.getState();

        const self = this;

        $('body').on('click', '#header-save', function () {
            /** @global Saves */
            saveChanges();
        });

        $('body').on('click', '#header-screenshot', function () {
            self.cmd('screenshot').then(window.open.bind(window));
        });

        $('body').on('click', '#header-thumbnail', function () {
            self.cmd('profilePicture').then(() => {
                prompts.info('The screenshot of the current view will be used as a thumbnail image on your projects page, and the project designs page.');
            });
        });

        $('body').on('click', '#header-module-lib', function () {
            self.options.openModuleLibrary();
        })

        $('body').on('click', '#header-dimensions', function () {
            let show;

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                show = false;
            } else {
                $(this).addClass('active');
                show = true;
            }

            self.options.showDimensions(show);
        });

        $('body').on('click', '#header-select', function () {
            let show;

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                show = false;
            } else {
                $(this).addClass('active');
                show = true;
            }

            mouseHandler.setCursor('pointer', 'GuiHeader', show);
            self.options.selectMode(show);
        });

        $('body').on('change', '#toggle-camera-projection', function () {
            self.switchProjection($(this).is(':checked'));
        });

        $('body').on('change', '#toggle-measurement-units', function () {
            self.switchUnits($(this).is(':checked'));
            $(this).attr("aria-pressed",$(this).is(':checked'));
        });




        $('body').on('change', '#toggle-auto-projection', function () {
            //self.switchProjection($(this).is(':checked'));

            $(this).attr('checked', $(this).is(':checked'));
            $('#toggle-camera-projection').attr('checked', $(this).is(':checked'));
            $('#toggle-auto-projection').attr("aria-pressed", ($(this).is(':checked')));
            $('#toggle-camera-projection').attr("aria-pressed", ($(this).is(':checked')));
        });

        $('body').on('change', '#measurement-unit-type', function () {
            //self.switchUnits($(this).is(':checked'));
            $('#toggle-measurement-units').attr("checked", ($(this).is(':checked')));
            $('#toggle-measurement-units').attr("aria-pressed", ($(this).is(':checked')));
            $(this).attr("aria-pressed", ($(this).is(':checked')));
        });




        // these are also only working the first time the state is toggled

        tippy('.main-header [data-tippy-content]', {
            animation: 'scale',
            inertia:   true,
            zIndex:    12000,
        });
    }


    renderState() {
        let s = this.getState();

        $('#toggle-camera-projection').prop('checked', s.projection === 'orthographic');
    }
}

export { HeaderGUI };
