


class ProgressBarGUI {
    constructor() {
    }


    set percent(p) {
        $('.loading-screen .bar').css('width', p + '%');
    }


    set show(val) {
        let $el = $('.loading-screen');

        if (val) {
            $el.removeClass('hidden');
        } else {
            $el.addClass('hidden');
        }
    }


    set determinate(val) {
        let $el = $('.loading-screen .bar');

        if (val) {
            $el.removeClass('indeterminate');
        } else {
            $el.addClass('indeterminate');
        }
    }


    set description(text) {
        $('.loading-screen .description').html(text);
    }
}

export { ProgressBarGUI };
