/// @ts-check

/** @param {string} prop */
function getCssProperty(prop) {
    return getComputedStyle(document.body).getPropertyValue(prop).trim();
}

/**
 * Utility object used for fetching colors out of CSS-land when they need to be
 * passed into the 3D renderer context.
 */
const Colors = Object.freeze({

    gray: () => getCssProperty('--gray'),
    black: () => getCssProperty('--black'),
    white: () => getCssProperty('--white'),
    darkGray: () => getCssProperty('--dark-gray'),
    labelGray: () => getCssProperty('--label-gray'),
    lightGray: () => getCssProperty('--light-gray'),
    veryLightGray: () => getCssProperty('--very-light-gray'),
    blackTransparent: () => getCssProperty('--black-transp'),

    Scanifly: Object.freeze({
        red: () => getCssProperty('--red'),
        blue: () => getCssProperty('--main-blue'),
        gray: () => getCssProperty('--blue-gray'),
        teal: () => getCssProperty('--teal'),
        green: () => getCssProperty('--green'),
        orange: () => getCssProperty('--orange'),
        purple: () => getCssProperty('--purple'),
        yellow: () => getCssProperty('--yellow'),
        darkBlue: () => getCssProperty('--dark-blue'),
        lightBlue: () => getCssProperty('--light-blue'),
        lightPurple: () => getCssProperty('--light-purple')
    }),

});

export { Colors as default, Colors };
