import { Config } from '../../bootstrap/Config';
import tippy from 'tippy.js';


class DroneImagesGui {
    constructor(options) {
        this.options = options;

        this.selector = 'drone-img-zoom';

        this.getState = options.getState;
    }


    init() {
        let self = this;

        $('body').on('click', '.drone-img-thumbs img', function () {
            self.imageSelected = parseInt($(this).attr('data-id'));
            self.renderState();
        });

        $('body').on('click', `#${this.selector} .close`, function () {
            $(`#${self.selector}`).remove();

            delete self.imageSelected;
            self.renderState();
        });
    }

    renderState() {
        let s = this.getState();

        if ($(this.options.thumbSelector + ' .drone-img-thumbs').length === 0) {
            $(this.options.thumbSelector).empty();

            let container = $('<div>');
            container.addClass('drone-img-thumbs');
            $(this.options.thumbSelector).append(container);

            for (let i = 0; i < s.images.length; i++) {
                const img = s.images[i];
                const name = img.originalFilenameWithoutExtension
                    ? img.originalFilenameWithoutExtension + img.originalFileExtension
                    : img.name;
                const src = img.thumbnailUrl || img.thumbnail;

                container.append(`
                    <div class="thumb thumb-${i}">
                        <img src="${src}" data-id="${i}" alt="drone image thumbnail"/>
                        <div class="name">${name}</div>
                    </div>`
                );
            }

            $(this.options.thumbSelector).html(container);
        }

        // SELECTED IMAGE
        $(this.options.thumbSelector + ' .drone-img-thumbs .thumb').removeClass('active');

        for (let i = 0; i < s.images.length; i++) {
            if (this.imageSelected === i)
                $('.drone-img-thumbs .thumb-' + i).addClass('active');
        }

        // ZOOM
        $(`#${this.selector}`).remove();

        if (this.imageSelected >= 0) {
            let t = `
                <div id="${this.selector}" class="drone-img-preview">
                    <div class="controls">
                        <button class="close" data-tippy-content="Close" aria-label="close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>

                        <button class="reset" data-tippy-content="Reset Zoom" aria-label="reset zoom">
                            <i class="fa fa-compress" aria-hidden="true"></i>
                        </button>

                        <button class="zoom-in icon-plus" data-tippy-content="Zoom In" aria-label="zoom in">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>

                        <button class="zoom-out icon-minus" data-tippy-content="Zoom Out" aria-label="zoom out">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="zoom-open"><img src="" alt=""></div>
                </div>
            `;

            $('#split-main .viewport').append(t);

            const img = s.images[this.imageSelected];

            $(`#${this.selector} .zoom-open img`).attr('src', DroneImagesGui.getFullImgUrl(img));
            $(`#${this.selector} .zoom-open`).find('img').panzoom({
                $zoomIn:        $(`#${this.selector} .zoom-in`),
                $zoomOut:       $(`#${this.selector} .zoom-out`),
                $reset:         $(`#${this.selector} .reset`),
                startTransform: 1,
                increment:      0.5,
                minScale:       1,
                maxScale:       10,
                contain:        'invert',
                rangeStep:      0.05
            }).panzoom('zoom', 1.0).addClass('zoomed');

            tippy('.drone-img-preview [data-tippy-content]', {
                animation: 'scale',
                inertia:   true,
                zIndex:    12000,
                placement: 'left',
                theme:     'light'
            });
        }
    }

    static getFullImgUrl(img) {
        return img.id
            ? `${Config.baseURL}/medias/${img.id}/download?token=${localStorage.getItem('token')}`
            : img.imagePath;
    }
}

export { DroneImagesGui };
