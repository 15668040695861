import { Draw } from './Draw.js';
import { Geometry, Line, LineBasicMaterial, LineSegments, Line3, MOUSE, Object3D, Plane, Vector3, Points, Matrix4 } from '../libs/three.module';
import { headingToDirectionVector, UP } from '../libs/Orienteering';
import { getCentroidForPoints, planeToMatrix4s, toVector2WithMatrix, toVector3WithMatrix } from '../libs/Geometry.js';

class RectContext {
    constructor(options) {
        options = options || {};

        this.shape = 'rect';

        this.container = new Object3D();

        this.camera    = options.camera;
        this.raycaster = options.raycaster;

        this.meshes = [];

        this.material = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth || 1,
            transparent: true,
            depthTest:   false
        });

        this.material2 = new LineBasicMaterial({
            color:       options.color || 0xeaeaea,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });

        this.materialHighlight = new LineBasicMaterial({
            color:       options.colorHighlight || 0xffff00,
            linewidth:   options.lineWidth * 0.5 || 0.5,
            transparent: true,
            depthTest:   false
        });


        this.states = { NONE: 0, STARTED: 1, CLOSED: 2 };
        this.state = this.states.NONE;

        this.onComplete = options.onComplete;

        this.height      = 0;
        this.setbackSize = 0;

        this.extrude = options.extrude || 'vertical';
        this.normal  = options.normal;
        if (options.vertices) {
            this.initFromVertices(options.vertices);
        } else {
            // grab options used during creation
            this.fitPlane = options.fitPlane;
            const { toWorld, toPlane } = planeToMatrix4s(this.fitPlane.threePlane);
            this.toWorld = toWorld;
            this.toPlane = toPlane;

            const worldToPlaneRotation = new Matrix4().extractRotation(toPlane);
            const azimuthDirection = headingToDirectionVector(options.azimuth);
            this.azimuthDirection2D = toVector2WithMatrix(azimuthDirection, worldToPlaneRotation).normalize()
            const azimuthPerpDirection = azimuthDirection.clone().applyAxisAngle(UP, Math.PI / 2);
            this.azimuthPerpDirection2D = toVector2WithMatrix(azimuthPerpDirection, worldToPlaneRotation).normalize();
            this.startPoint = undefined;
        }

        this.squareConstraint = false;

        this.updateParams(options);
    }

    addToScene(scene) {
        this.scene = scene;
        this.scene.add(this.container);
    }

    toggleSquareConstraint() {
        this.squareConstraint = !this.squareConstraint;
    }

    show(show) {
        if (show !== false) {
            this.scene.add(this.container);
        } else {
            this.scene.remove(this.container);
        }
    }


    addMesh(mesh) { this.meshes.push(mesh); }

    undo() {
        if (this.state === this.states.STARTED) {
            this.container.remove(this.rect);

            this.state = this.states.NONE;
        }
    }

    mouseDown(event, mouse, panning) { }

    mouseMove(mouse, panning, event) {
        if (this.state === this.states.STARTED) {
            this.raycaster.setFromCamera(mouse, this.camera);

            const intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            const point = intersects[0].point;
            const cornerPoint = toVector2WithMatrix(point, this.toPlane);
            const vv = this.createRectangleFromStartPointToCorner(cornerPoint);
            if(undefined === this.rect) {
                this.rect = this.createRect(vv);
                this.container.add(this.rect);
            } else {
                this.updateRect(vv);
            }
        }
    }


    mouseUp(event, mouse, panning) {
        if (event.button !== MOUSE.LEFT)
            return;

        if (this.state === this.states.NONE) {
            this.raycaster.setFromCamera(mouse, this.camera);

            var intersects = this.raycaster.intersectObjects(this.meshes);
            if (intersects.length === 0)
                return;

            const point = intersects[0].point;
            this.startPoint = toVector2WithMatrix(point, this.toPlane);
            this.state = this.states.STARTED;
        } else if (this.state === this.states.STARTED) {
            if (this.onComplete)
                this.onComplete();
            this.state = this.states.CLOSED;
        }
    }

    /**
     *
     * @param {THREE.Vector2} corner
     */
    createRectangleFromStartPointToCorner(corner) {
        const diagonal = corner.clone().sub(this.startPoint);
        const azMag = diagonal.dot(this.azimuthDirection2D);
        const azMagSign = azMag < 0 ? -1 : 1;
        const azPerpMag = diagonal.dot(this.azimuthPerpDirection2D);
        const azPerpMagSign = azPerpMag < 0 ? -1 : 1;

        const minMag = Math.min(Math.abs(azMag), Math.abs(azPerpMag));
        const width = this.squareConstraint ? minMag * azPerpMagSign : azPerpMag;
        const depth = this.squareConstraint ? minMag * azMagSign : azMag;

        const A = toVector3WithMatrix(this.startPoint, this.toWorld);
        const B = toVector3WithMatrix(this.startPoint.clone().addScaledVector(this.azimuthDirection2D, depth), this.toWorld);
        const C = toVector3WithMatrix(this.startPoint.clone().addScaledVector(this.azimuthDirection2D, depth).addScaledVector(this.azimuthPerpDirection2D, width), this.toWorld);
        const D = toVector3WithMatrix(this.startPoint.clone().addScaledVector(this.azimuthPerpDirection2D, width), this.toWorld);

        return [A, B, C, D];
    }

    createRect(vv) {
        var g = new Geometry();
        g.vertices.push(...vv);
        g.vertices.push(vv[0]);
        const rect = new Line(g, this.material);

        // prevent line from disappearing due to frustum culling
        // alternatively, call line.geometry.computeBoundingSphere() every time we
        // update the geometry
        rect.frustumCulled = false;
        return rect;
    }

    updateRect(vv) {
        if(undefined === this.rect) {
            return;
        }
        this.rect.geometry.vertices = [...vv, vv[0]];
        this.rect.geometry.verticesNeedUpdate = true;
        this.center = getCentroidForPoints(vv);
    }

    updateParams({ extrude, height, setback, highlight }) {

        // Height/Extrusion
        if (height !== undefined || (this.height && extrude)) {
            if (this.extrusion) {
                this.container.remove(this.extrusion);
                delete this.extrusion;
            }

            if (height === undefined)
                height = this.height;

            if (height > 0) {
                let g = new Geometry();
                let p = new Plane();

                if (extrude === undefined)
                    extrude = this.extrude;

                let offset = (extrude === 'vertical') ?
                    new Vector3(0, height, 0) :
                    this.normal.clone().multiplyScalar(height);

                p.setFromNormalAndCoplanarPoint(offset.clone().normalize(), this.center);

                for (let v of this.rect.geometry.vertices) {
                    v = p.projectPoint(this.rect.localToWorld(v.clone()));
                    g.vertices.push(v);
                    v.add(offset);
                }
                g.verticesNeedUpdate = true;
                // Top of Extrusion
                let line = new Line(g, this.material2);

//                line.position.copy(offset);
//                line.updateMatrixWorld();

                let g2 = new Geometry();

                for (let i = 0; i < this.rect.geometry.vertices.length; i++) {
                    let vt = this.rect.geometry.vertices[i].clone();

                    g2.vertices.push(g.vertices[i]);
                    g2.vertices.push(this.rect.geometry.vertices[i]);
                }
                g2.verticesNeedUpdate = true;
                let s = new LineSegments(g2, this.material2);

                let e = new Object3D();
                e.add(line);
                e.add(s);

                this.container.add(e);
                this.extrusion = e;
            }

            this.height = height;
            this.extrude = extrude;
        }

        // Setback
        if (setback !== undefined) {
            if (this.setback) {
                this.container.remove(this.setback);
                delete this.setback;
            }

            if (setback > 0) {
                let offsetG = this.rect.geometry.clone();
                let verts = offsetG.vertices;
                let upVec = ((verts[0].clone().sub(verts[3])).normalize()).multiplyScalar(setback);
                let downVec = upVec.clone().multiplyScalar(-1);
                let rightVec = ((verts[1].clone().sub(verts[0])).normalize()).multiplyScalar(setback);
                let leftVec = rightVec.clone().multiplyScalar(-1);

                verts[0].add(upVec).add(leftVec);
              //  verts[0].add(leftVec);
                verts[1].add(upVec).add(rightVec);
                verts[2].add(downVec).add(rightVec);
                verts[3].add(downVec).add(leftVec);
                verts[4].add(upVec).add(leftVec);

                let offset = new Line(offsetG, this.material2);
                this.container.add(offset);
                this.setback = offset;
            }

            this.setbackSize = setback;
        }

        // Misc
        if (this.rect)
            this.rect.material = highlight ? this.materialHighlight : this.material;

        if (this.extrusion) {
            this.extrusion.children.forEach((c) => {
                c.material = highlight ? this.materialHighlight : this.material2;
            });
        }
    }


    getVertices() {
        var vv = [];

        for (let v of this.rect.geometry.vertices)
            vv.push(this.rect.localToWorld(v.clone()));
        vv.pop(); // remove last vert doubling first vert
        return vv;
    }


    getSetbackVertices() {
        if (!this.setback)
            return this.getVertices();

        var vv = [];

        for (let v of this.setback.geometry.vertices)
            vv.push(v.clone());

        return vv;

    }


    initFromVertices(vv) {
        this.rect = this.createRect(vv);
        this.center = getCentroidForPoints(vv)
        this.container.add(this.rect);
    }


    getLines() {
        var lines = [];

        if (this.extrusion) {
            let l = this.extrusion.children[0];
            let vv1 = l.geometry.vertices.map(v => l.localToWorld(v.clone()));

            lines.push(...Draw.exportLineGeometry(vv1));

            let vv2 = this.extrusion.children[1].geometry.vertices;

            lines.push(...Draw.exportSegmentGeometry(vv2));
        }

        if (this.setback) {
            let vv = this.setback.geometry.vertices;

            lines.push(...Draw.exportLineGeometry(vv));
        }

        if (this.rect) {
            let vv = this.circle.geometry.vertices.map((v) => {
                return this.circle.localToWorld(v.clone());
            });

            lines.push(...Draw.exportLineGeometry(vv));
        }

        return lines;
    }

    /**
     *
     * @returns {import('../../../types').Tuple2<Vector3> | []}
     */
    getPolyline() {
        if (!this.rect) {
            return [];
        }

        return this.rect.geometry.vertices.map(v => this.rect.localToWorld(v.clone()));
    }

    getSetbackPolyline() {
        if (!this.setback) {
            return [];
        }

        return this.setback.geometry.vertices.map(v => this.setback.localToWorld(v.clone()));
    }

    getExtrusions() {
        const kids = this.extrusion.children;
        return {
            top: kids[0].geometry.vertices.map(v => kids[0].localToWorld(v.clone())),
            edges: Draw.exportSegmentGeometry(
                kids[1].geometry.vertices.map(v => kids[1].localToWorld(v.clone()))
            )
        };
    }
}



export { RectContext };
