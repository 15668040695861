import { ShadingValuesGui } from './GuiShadingValues';
import { emitEvent, measurementUnits, init } from '../Viewer.js';
import { Format } from './Format';
import { formatK } from '../libs/Utilities';
import { mSqToFtSq } from '../alibs/Units';
import { MeasurementUnits } from '../tools/MeasurementsTool';



class SegmentInfoGui {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }


    cmd(c, val) {
        this.options.tool.cmd(c, val);
    }


    init() {
        if (this.initialized)
            return;


        this.initialized = true;
    }


    renderState() {
        let s = this.getState();

        let pid   = s.array.pid;
        let plane = s.array.planes[pid];

        let tilt = (plane.tilt !== undefined) ? plane.tilt.toFixed(1) + '&deg;' : '--';
        let azimuth = (plane.azimuth !== undefined) ? plane.azimuth.toFixed(1) + '&deg;' : '--';

        let moduleType = (s.array.moduleSpecs[pid]) ? s.array.moduleSpecs[pid][0].model : '--';
        let area = (measurementUnits === MeasurementUnits.FEET_INCHES) ?
            formatK(mSqToFtSq(s.array.area)) + ' sq ft' :
            formatK(s.array.area) + ' sq m';

        let qty = 0;
        let systemSize = '0 kW';

        if (s.array.arrayInfo[pid][0]) {
            qty = formatK(s.array.arrayInfo[pid][0].qty);
            systemSize = Format.wattsAsKilowatts(s.array.arrayInfo[pid][0].wattage);
        }

        emitEvent('status', {message: plane.name});

        let t = `
            <div class="pane-container plane-${pid} seg-info">
                <h3>${plane.name}</h3>
                <div class="tags">
                    <div class="tag">
                        System Size: ${systemSize} DC STC
                    </div>
                    <div class="tag">
                        Module Qty: ${qty}
                    </div>
                    <div class="tag">
                        Module Type: ${moduleType}
                    </div>
                </div>

                <div class="card section-viewsheds">
                    <h4>
                        Shade
                    </h4>

                    <div class="shading-values-container"></div>
                </div>

                <div class="card section-viewsheds">
                    <h4>
                        Array
                    </h4>

                    <div class="az-tilt-area">
                        <div>
                            Azimuth: <span>${azimuth}</span>
                        </div>
                        <div>
                            Tilt: <span>${tilt}</span>
                        </div>
                        <div>
                            Area: <span>${area}</span>
                        </div>
                    </div>
                </div>
            </div>
        `;

        $(this.options.selector).html(t);

        const shadingValues = new ShadingValuesGui({
            selector: '.shading-values-container',
            getState: this.getState,
            mini: false,
        });

        shadingValues.renderState();
    }
}

export { SegmentInfoGui };
