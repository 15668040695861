import { prompts, init } from '../Viewer.js';
import { Validator } from './Validator';
import { GUI } from './Gui';


import tippy from 'tippy.js';



class TreeGUI {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }


    init() {
        var self = this;

        // COLLAPSE-EXPAND
        let collapseExpand = function () {
            let tid = $(this).attr('data-tid');
            let icon = $(this);

            if (tid === undefined) { // node double-clicked
                tid = $(this).parents('li').attr('data-tid');
                icon = $('.toggle.icon', this);
            }

            let node = self.mapping[tid];

            if (node && node.children && !node.disabled) {
                let li = $(this).parents('li').first();

                if (node.collapsed) {
                    node.collapsed = false;

                    li.removeClass('collapsed');
                    li.addClass('expanded');
                    li.attr('aria-expanded', "true");

                    icon.removeClass('icon-expand');
                    icon.addClass('icon-collapse');
                } else {
                    node.collapsed = true;

                    li.removeClass('expanded');
                    li.addClass('collapsed');
                    li.attr('aria-expanded', "false");

                    icon.removeClass('icon-collapse');
                    icon.addClass('icon-expand');
                }

                if (self.options.collapse)
                    self.options.collapse(node);
            }
        };

        $('body').on('click',    '.system-tree .toggle',     collapseExpand);
        $('body').on('dblclick', '.system-tree .title-wrap', collapseExpand);

        // hover enter
        $('body').on('mouseover', '.system-tree .title-wrap', function (e) {
            let selectable = $(this).parent('.selectable');
            let tid = selectable.attr('data-tid');
            self.options.hover(true,self.mapping[tid]);
        });

        // hover leave
        $('body').on('mouseleave', '.system-tree .title-wrap', function (e) {
            let selectable = $(this).parent('.selectable');
            let tid = selectable.attr('data-tid');
            self.options.hover(false,self.mapping[tid]);
        });

        // SELECT
        $('body').on('click', '.system-tree .title-wrap', function (e) {
            let selectable = $(this).parent('.selectable');

            if (e.button !== 0 || selectable.hasClass('disabled'))
                return;

            var wasSelected = selectable.hasClass('selected');

            $('.system-tree .selectable').not(selectable).removeClass('selected');

            if (!wasSelected) {

                let tid = selectable.attr('data-tid');

                if (self.options.checked && e.target.type == 'checkbox') {
                    ($(this).attr('aria-checked'), self.options.checked(e.target.checked, self.mapping[tid]));
                    return;
                }

                selectable.addClass('selected');

                if (self.options.select)
                    self.options.select(self.mapping[tid]);

                // hide any open segment name inputs
                $('.system-tree .node-name').removeClass('hidden');
                $('.system-tree .input-edit').addClass('hidden');
            }
        });

        // CHECK-UNCHECK
        $('body').on('change', '.system-tree input[type="checkbox"]', function () {
            if (self.options.checked) {
                let tid = $(this).parents('li').attr('data-tid');
                ($(this).attr('aria-checked'), self.options.checked($(this).prop('checked'), self.mapping[tid]));
            }
        });

        // RENAME NODE
        $('body').on('click', '.system-tree .btn-edit', function () {
            let wrap = $(this).parents('.title-wrap');

            $('.node-name', wrap).addClass('hidden');
            $('.input-edit', wrap).removeClass('hidden');
        });

        $('body').on('click', '.system-tree .btn-edit', function (e) {
            e.stopPropagation(); // prevent bubble up to node selection
        });

        GUI.inputText('.system-tree .input-edit', {
            validate: Validator.create({
                sanitize:    (text) => text.trim(),
                updateState: (text, $el) => {
                    let tid = $el.parents('.selectable').attr('data-tid');
                    this.options.rename(text, this.mapping[tid]);
                },
                resetState: () => this.options.rename()
            })
        });

        // DELETE NODE
        $('body').on('click', '.system-tree .btn-delete', function () {
            let tid = $(this).parents('.selectable').attr('data-tid');

            prompts.confirm($(this).attr('data-confirm-msg')).then(function (ok) {
                if (ok)
                    self.options.delete(self.mapping[tid]);
            });
        });

        $('body').on('mousedown', '.system-tree .btn-delete', function (e) {
            e.stopPropagation(); // prevent bubble up to node selection
        });
    }

    hoverSegment(pid) {
        $(`div#my-tree li[data-pid="${pid}"] > div.title-wrap`).addClass('hover');
    }

    clearHoverSegments() {
        $(`div#my-tree li > div.title-wrap`).removeClass('hover');
    }


    renderState() {
        $(this.options.selector).html('<ol>');

        var s = this.getState();

        this.mapping = {};
        this.buildTree($(this.options.selector + ' ol'), s);

        tippy(this.options.selector + ' [data-tippy-content]', {
            animation: 'scale',
            inertia:   true
        });
    }


    buildTree(parent, children) {
        for (let child of children) {
            let id = Object.keys(this.mapping).length + '';

            this.mapping[id] = child;

            let checked = child.checked ? 'checked="checked"' : '';
            let iconClass = '';

            if (child.children)
                iconClass = child.collapsed ? 'icon-expand' : 'icon-collapse';

            let text = child.text || 'noname';
            let name = [ text, child.suffix ].join(' ').trim();
            let disabled = child.disabled ? 'disabled="disabled"' : '';

            let row = $(`
                <div class="title-wrap">
                    <a class="toggle icon ${iconClass}" data-tid="${id}"></a>

                    <input type="checkbox" ${checked} ${disabled} />

                    <a class="title icon">
                        <span class="node-name">${name}</span>
                        <input class="hidden input-edit" type="text" value="${text}" />
                    </a>

                    <span class="btn-group">
                        <button class="sy btn-edit" data-tippy-content="Edit Segment Name" aria-label="edit segment name">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>

                        <button class="sy btn-delete" data-tippy-content="Delete Segment" aria-label="delete segment">
                            <img src="styles/images/icons/trash-icon.svg" alt="">
                        </button>
                    </span>
                </div>

                <div class="wholerow"></div>
            `);

            let li = $('<li>');

            li.addClass('selectable');
            li.attr('data-tid', id);

            if(undefined != child.pid) {
                li.attr('data-pid', child.pid);
            }

            if (child.editable)
                li.addClass('editable');

            if (this.selected === id || child.selected)
                li.addClass('selected');

            if (child.disabled)
                li.addClass('disabled');

            li.append(row);

            if (child.children) {
                li.addClass('folder');

                if (child.collapsed) {
                    li.addClass('collapsed');
                    li.attr('aria-expanded', 'false');
                } else {
                    li.addClass('expanded');
                    li.attr('aria-expanded', 'true');
                }

                let ol = $('<ol>');

                li.append(ol);

                this.buildTree(ol, child.children);
            } else {
                li.addClass('leaf');
            }

            parent.append(li);
        }

        return parent;
    }
}

export { TreeGUI };
