import { render, measurementUnits, init } from '../Viewer.js';
import { metersToInches, metersToCm, inchesToMeters, cmToMeters } from '../alibs/Units.js';
import { MeasurementUnits } from '../tools/MeasurementsTool.js';



class MidpointInputsGUI {

    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }


    cmd(command, val) {
        this.options.tool.cmd(command, val);
        return val;
    }


    init() {
        const self = this;

        $('body').on('change', '.input-setback', function () {
            let $el = $(this);

            // don't do anything if invoked on deletion
            if (!$el.hasClass('remove')) {
                $el.val(self.edit($el, 'fireSetbacks'));
            }
        });

        $('body').on('change', '.input-parapet', function () {
            let $el = $(this);

            if (!$el.hasClass('remove')) {
                $el.val(self.edit($el, 'parapets'));
            }
        });
    }


    edit($el, field) {
        let val = parseFloat($el.val());
        let i   = parseInt($el.attr('data-i'));

        let cVal;

        if (!isNaN(val)) {
            cVal = (measurementUnits === MeasurementUnits.METERS) ?
                cmToMeters(val) :
                inchesToMeters(val);

            let command = (field === 'parapets') ?
                'editParapets' :
                'editFireSetbacks';

            this.cmd(command, { i, val: cVal });
        }

        if (cVal === undefined) {
            let s = this.getState();
            cVal = s[field][i];
        }

        return this.format(cVal);
    }


    format(val) {
        let units, v;

        /** @global measurementUnits */
        if (measurementUnits === MeasurementUnits.METERS) {
            units = "cm";
            v = metersToCm(val, 0);
        } else {
            units = "in";
            v = metersToInches(val, 0);
        }

        return v + ' ' + units;
    }


    render(dim) {
        this.polyInputs(dim, 'setback');
        this.polyInputs(dim, 'parapet');
    }


    polyInputs(dim, val) {
        var inputsToRemove = $('.input-' + val);

        for (let i = 0; i < dim.length; i++) {
            if (dim[i][val] !== undefined) {
                let input = $(`.${val}-${i}`);

                inputsToRemove = inputsToRemove.not(input);

                if (input.length === 0) {
                    input = $('<input>');
                    input.attr('type', 'text');
                    input.attr('data-i', i);

                    input.addClass(`${val}-${i}`);
                    input.addClass('input-' + val);

                    input.css('position', 'absolute');
                    input.css('z-index',  999);
                    input.css('width',    '30px');

                    $('body').append(input);
                }

                input.val(this.format(dim[i][val]));

                input.css('top',  (dim[i].y - input.outerHeight() / 2) + 'px');
                input.css('left', (dim[i].x - input.outerWidth()  / 2) + 'px');
            }
        }

        // let input change handlers know they're being triggered on deletion
        inputsToRemove.addClass('remove');
        inputsToRemove.remove();
    }
}


export { MidpointInputsGUI };
