import { GUI } from '../gui/Gui.js';
import { prompts, moduleSpecsTool, project, csvExportTool } from '../Viewer.js';
import { HEADERS_CODE, DAYS_IN_MONTHS, ARRAY_VALUES_TO_TYPE, MODULES_VALUES_TO_TYPE, HEADERS_DESCRIPTIVE, HEADERS_DESCRIPTIVE_TOTAL, HEADERS_CODE_SET } from '../gui/GuiConstants';
import { downloadAndArchiveForPublicAPI, browserDownload, toFixedIfNumber } from '../libs/Utilities';

class CSVTool {
    /**
     * @param {PVWattsClient} pvWattsClient
     */
    constructor(pvWattsClient) {
        this.pvWattsClient = pvWattsClient;
    }

    /**
     *
     * @param {number[]} planes
     * @param {{
     *   inputs: {system_capacity: number, 'losses': number}
     *   outputs: {capacity_factor: number},
     *   station_info: Object
     * }[]} data
     */
    processResultsCSV(planes, data) {
        var processedData = {};
        var varSumsTotal = {
            /** @type {number[]} */
            system_capacity: [],
            /** @type {number[]} */
            capacity_factor: [],
            /** @type {number[]} */
            losses: []
        };

        const self = this;

        data.forEach(
            (segmentResult, idx) => {
                // handle individual CSVs
                const segment = planes[idx] + 1;
                this.generateCSV(segmentResult, 'partial-segment', segment);

                // handle total output CSV
                const outputArrays = segmentResult.outputs;

                varSumsTotal.capacity_factor.push(outputArrays.capacity_factor);
                varSumsTotal.system_capacity.push(segmentResult.inputs.system_capacity);
                varSumsTotal.losses.push(segmentResult.inputs.losses);

                // take only keys we care about (in HEADERS_CODE_SET constant)
                Object.entries(outputArrays).forEach(
                    ([k, v]) => {
                        if (!HEADERS_CODE_SET.has(k)) {
                            return;
                        }

                        if (processedData[k]) {
                            return processedData[k].push(v);
                        }

                        processedData[k] = [v]
                    }
                );
            }
        );

        // now handle total sum
        this.calculateTotalsCSV(
            processedData,
            data[0].inputs,
            data[0].station_info,
            this.returnSumAvg(varSumsTotal)
        );
    }


    // return system capacity sum, capacity factor avg and losses avg
    /**
     * @param {{
     *   system_capacity: number[], capacity_factor: number[], losses: number[]
     * }} arrSum
     * @returns
     */
    returnSumAvg(arrSum) {
        /** @type {{system_capacity: number?, capacity_factor: number?, losses: number?}} */
        var objSumAvg = {
            system_capacity: null,
            capacity_factor: null,
            losses: null
        };
        var n = arrSum.capacity_factor.length;
        var m = arrSum.losses.length;

        objSumAvg.system_capacity = arrSum.system_capacity.reduce((a, b) => a + b);
        var capSum = arrSum.capacity_factor.reduce((a, b) => a + b);
        var lossSum = arrSum.losses.reduce((a, b) => a + b);

        objSumAvg.capacity_factor = capSum / n;
        objSumAvg.losses = lossSum / m;

        return objSumAvg;
    }


    calculateTotalsCSV(data, inputs, stationInfo, dataAvgs) {
        var processedDataWithTotal = $.extend(
            {},
            inputs,
            stationInfo,
            dataAvgs
        );

        processedDataWithTotal.tilt = "N/A, Tilt is different for each segment";
        processedDataWithTotal.azimuth =
            "N/A, Azimuth is different for each segment";

        for (var headerKey in data) {
            if (data.hasOwnProperty(headerKey)) {
                var currOutputArray = data[headerKey];
                switch (headerKey) {
                    // only sum for dc, ac headers
                    case "ac":
                    case "dc":
                        processedDataWithTotal = this.sumPartialsCSV(
                            processedDataWithTotal,
                            headerKey,
                            currOutputArray,
                            data[headerKey].length,
                            false
                        );
                        break;
                    // for ambient temp, wind speed just pass values from any  array
                    // as these will be same across segments for the same coordinates
                    case "tamb":
                    case "wspd":
                        processedDataWithTotal[
                            headerKey
                        ] = currOutputArray[0].slice();
                        break;
                    // for cell temp, poa, beam irradiance, diffuse irradience take the averages
                    case "dn":
                    case "df":
                    case "tcell":
                    case "poa":
                        processedDataWithTotal = this.sumPartialsCSV(
                            processedDataWithTotal,
                            headerKey,
                            currOutputArray,
                            data[headerKey].length,
                            // find the average
                            true
                        );
                        break;
                    default:
                        continue;
                        break;
                }
            }
        }

        this.generateCSV(processedDataWithTotal, "combined-segment", "total");
    }


    sumPartialsCSV(processedDataWithTotal, headerKey, currOutputArray, n, findAvg) {
        for (var index = 0; index < currOutputArray.length; index++) {
            var array8760 = currOutputArray[index];

            array8760.forEach(function(val, j) {
                if (index === 0 && j === 0) {
                    processedDataWithTotal[
                        headerKey
                    ] = currOutputArray[0].slice();
                    processedDataWithTotal[headerKey][0] = parseFloat((val).toFixed(2));
                } else if (index === 0 && j != 0) {
                    processedDataWithTotal[headerKey][j] = parseFloat((val).toFixed(2));
                } else {
                    processedDataWithTotal[headerKey][j] += parseFloat((val).toFixed(2));
                }
            });
        }

        if (findAvg) {
            processedDataWithTotal[headerKey].map(function(partialSum) {
                return parseFloat((partialSum / n).toFixed(2));
            });
        }

        return processedDataWithTotal;
    }


    async generateCSV(dataIn, resultType, segNumber) {
        var headers = (segNumber == 'total') ? HEADERS_DESCRIPTIVE_TOTAL : HEADERS_DESCRIPTIVE;
        var outputs = (segNumber == 'total') ? dataIn : dataIn.outputs;

        var csv = this.buildInputHeadersCSV(dataIn, segNumber) + "\n\n" + headers.join() + "\n";
        csv = this.buildHourlyResultsCSV(1, 1, 0, outputs, csv);

        const title = `${resultType}-${segNumber}`;

        if(segNumber == 'total') {
            csvExportTool.hourlyProductionUrl = await downloadAndArchiveForPublicAPI({
                blob: new Blob([csv], {type: 'text/csv;charset=utf-8;'}),
                filename: `NREL-pvWatts-Report-${title}-hourly.csv`,
                url: csvExportTool.hourlyProductionUrl
            });
        } else {
            browserDownload( {
                blob: new Blob([csv], {type: 'text/csv;charset=utf-8;'}),
                filename: `NREL-pvWatts-Report-${title}-hourly.csv`,
            });
        }
    }

    buildInputHeadersCSV(processedData, segNumber) {
        // @global project
        var address = project.address;
        var inputs, outputs;
        var suffix;

        if (segNumber === "total") {
            inputs    = processedData;
            outputs   = processedData;
            suffix    = ", Average among all segments";
            segNumber = 'All segments'
        } else {
            inputs  = processedData.inputs;
            outputs = processedData.outputs;
            suffix  = "";
        }

        const acTotal  = (outputs.ac.reduce((a, b) => a + b, 0) / 1000);
        const effRatio = (acTotal / inputs.system_capacity);

        var csvHeaders = [
            `PVWatts Hourly PV Performance Data`,
            `Segment Number:, ${segNumber}`,
            `Project Name:, ${project.name}`,
            `Requested Location:,  ${address.street || ''} ${address.city || ''} ${address.stateName || ''} ${address.zip || ''}`,
            `Location:, Lat Lon: ${inputs.lat.toFixed(2)} ${inputs.lon.toFixed(2)}`,
            `Lat (deg N):, ${inputs.lat.toFixed(2)}`,
            `Long (deg W):, ${inputs.lon.toFixed(2)}`,
            `DC System Size (kW):, ${inputs.system_capacity.toFixed(3)}`,
            `AC Annual Production (kWh):, ${acTotal.toFixed(2)}`,
            `Efficiency Ratio (kWh/year per kW installed):, ${effRatio.toFixed(2)}`,
            `Module Type:, ${MODULES_VALUES_TO_TYPE[inputs.module_type]}`,
            `Array Type:, ${ARRAY_VALUES_TO_TYPE[inputs.array_type]}`,
            `Array Tilt (deg):, ${toFixedIfNumber(inputs.tilt)(1)}`,
            `Array Azimuth (deg):, ${toFixedIfNumber(inputs.azimuth)(1)}`,
            `System Losses:, ${inputs.losses.toFixed(2)}${suffix}`,
            `Inverter Efficiency:, ${inputs.inv_eff}`,
            `DC to AC Size Ratio:, ${inputs.dc_ac_ratio}`,
            `Capacity Factor (%):, ${outputs.capacity_factor.toFixed(2)}${suffix}`
        ];

        return csvHeaders.join("\n");
    }


    buildHourlyResultsCSV(month, day, hr, outputs, csv) {
        var n = outputs.ac.length;

        for (let i = 0; i < n; i++) {
            if (hr === 24) {
                hr = 0;
                day += 1;
            }

            if (day === DAYS_IN_MONTHS[month.toString()] + 1) {
                day = 1;
                month += 1;
            }

            csv += month + "," + day + "," + hr + ",";

            for (var j = 0; j < HEADERS_CODE.length; j++) {
                var suffix = j === HEADERS_CODE.length - 1 ? "" : ",";
                csv += outputs[HEADERS_CODE[j]][i] + suffix;
            }

            hr += 1;
            csv += "\n";
        }

        return csv;
    }

    async getPVReportNREL() {

        const solarParams = moduleSpecsTool.getSolarParams();

        /** @type {PromiseLike<any>[]} */
        let requests = [];

        /** @type {number[]} */
        let pids = [];

        for (let pid = 0; pid < solarParams.length; pid++) {
            let sysParams = moduleSpecsTool.getSystemParams(pid);

            // skip segments with no modules or viewsheds
            if (solarParams[pid].kW <= 0 || sysParams.shading === undefined)
                continue;

            requests.push(
                this.pvWattsClient.pvWattsReport(
                    solarParams[pid],
                    sysParams,
                    moduleSpecsTool.calculateLosses(pid)
                )
            );
            pids.push(pid);
        }

        GUI.showPleaseWait(true, "nrel-pv-report");

        try {
            const reports = await Promise.all(requests);
            this.processResultsCSV(pids, reports);
        } catch (e) {
            prompts.info('Error: something went wrong while generating the NREL report');
            throw e;
        } finally {
            GUI.showPleaseWait(false, 'nrel-pv-report');
        }
    }
}

export { CSVTool };
