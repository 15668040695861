
function elevationHeatMap (callback) {
    var template = [
        '<h3 class="header elevation-heat-map">Elevation Heat Map</h3>',
        '<div class="tool-options">',
            '<div class="option elevation-heat-map-threshold">',
                '<div class="label">',
                    'Threshold:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<div class="slider input-heat-map-threshold" style="background: linear-gradient(to right, #00f, cyan, #0f0, #ff0, orange, #f00);">',
                        '<div class="handle1 ui-slider-handle"></div>',
                        '<div class="handle2 ui-slider-handle"></div>',
                    '</div>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',

            '<div class="option">',
                '<div class="label">',
                    'View:',
                '</div>',
                '<div class="input-wrapper first">',
                    '<select class="input-heat-map-3d" name="heatmap">',
                        '<option value="2d" selected="selected">2D</option>',
                        '<option value="3d">3D</option>',
                    '</select>',
                '</div>',
                '<div class="clearfix"></div>',
            '</div>',
        '</div>'
    ].join("\n");

    var self = this;

    $('.placeholder.elevation-heat-map').replaceWith(template);

    $('.header.elevation-heat-map').click(function () {
        self.elevationHeatMapThresholdBounds(callback(0, 1));
    });
};

function elevationHeatMapThreshold(callback) {
    var min = 0;
    var max = 100;

    var self = this;

    $('.input-heat-map-threshold').slider({
        min:    min,
        max:    max,
        values: [min, max],
        step:   0.1,
        create: function () {
            self.elevationHeatMapThresholdBounds(callback(0, 1));
        },
        slide:  function (e, ui) {
            $('.input-heat-map-threshold')
                .attr('style', 'background: linear-gradient(to right, #00f, #00f ' + ui.values[0] + '%,' +
                                                 'cyan, #0f0, #ff0, orange, #f00 ' + ui.values[1] + '%, #f00);');

            var bounds = callback(ui.values[0] / max, ui.values[1] / max);
            self.elevationHeatMapThresholdBounds(bounds);
        }
    });
};

function elevationHeatMapThresholdBounds(bounds) {
    $('.input-heat-map-threshold .handle1').html(bounds.min.toFixed(1));
    $('.input-heat-map-threshold .handle2').html(bounds.max.toFixed(1));
};

function elevationHeatMap3D(callback) {
    $('.input-heat-map-3d').change(function () {
        callback($(this).val() === '3d');
    });
};

export { elevationHeatMap, elevationHeatMapThreshold, elevationHeatMapThresholdBounds, elevationHeatMap3D}
