import { emitEvent, init } from '../Viewer.js';



class SidebarOutlineGui {
    constructor(options) {
        this.options = options;

        this.getState = options.getState;
    }


    cmd(command, val) {
        this.options.tool.cmd(command, val);
        return val;
    }


    init() {
        let self = this;

        $('body').on('click', '.manual-array-outline', function () {
            let s = self.getState()

            $(`#plane-tabs-array-${s.pid} .manual-array .snap`).slideDown();

            self.cmd('outline');
        });

        $('body').on('change', '.input-snap-start', function () {
            self.cmd('snap.start', $(this).is(':checked'));
        });

        $('body').on('change', '.input-snap-intersects', function () {
            self.cmd('snap.intersects', $(this).is(':checked'));
        });

        $('body').on('change', '.input-snap-90', function () {
            self.cmd('snap.ninety', $(this).is(':checked'));
        });

        $('body').on('change', '.input-snap-vertices', function () {
            self.cmd('snap.vertices', $(this).is(':checked'));
        });
    }


    clearActiveButtons() {
        let btns = $('.edit-buttons').find('button');

        btns.each(function (idx, btn) {
            $(btn).removeClass('active-btn')
        })

        setTimeout(function () {
            btns.each(function (idx, btn) {
                $(btn).removeClass('bkg-animated')
            })
        }, 1500)
    }


    renderState() {
        let s = this.getState();

        const pid = s.pid;

        if (s.auto) {
            $(this.options.selector).html(`
                <div class="pane-container empty">
                    <h4 class="get-started">Add a new segment and then create an outline to get started!</h4>
                    <img src="/styles/images/scanifly-illustration-primary.svg" class="get-started-illustration">
                </div>
            `);
        } else {
            const areaFt = (s.area * 10.7639).toFixed(2);
            const area   =  s.area.toFixed(2);

            var t = `
                <div class="pane-container plane-${pid}">
                    <h3>Outlines</h3>

                    <div class="segment-info"></div>

                    <div class="module-specs-tool-actions">
                        <button class="sy manual-array-outline">
                            Create Outline
                        </button>
                    </div>

                    <div class="manual-array">
                        <div class="subsection snap">
                            <h4>Snap To</h4>
                            <div class="content">
                                <div class="row">
                                    <input type="checkbox"
                                        id="input-snap-start-${pid}"
                                        class="input-snap-start"
                                        checked="checked" aria-checked="true"/>

                                    <label for="input-snap-start-${pid}">
                                        Starting point
                                    </label>
                                </div>

                                <div class="row">
                                    <input type="checkbox"
                                        id="input-snap-90-${pid}"
                                        class="input-snap-90"
                                        checked="checked" aria-checked="true"/>

                                    <label for="input-snap-90-${pid}">
                                        90 degrees
                                    </label>
                                </div>

                                <div class="row">
                                    <input type="checkbox"
                                        id="input-snap-intersects-${pid}"
                                        class="input-snap-intersects"
                                        checked="checked" aria-checked="true"/>

                                    <label for="input-snap-intersects-${pid}">
                                        Roof plane intersections
                                    </label>
                                </div>

                                <div class="row">
                                    <input type="checkbox"
                                        id="input-snap-vertices-${pid}"
                                        class="input-snap-vertices"
                                        checked="checked" aria-checked="true"/>

                                    <label for="input-snap-vertices-${pid}">
                                        Polygon vertices
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="segment-area-${pid}">
                        <h4>Area: <span>${areaFt}</span>ft<sup>2</sup>&nbsp;&nbsp;(<span>${area}</span>m<sup>2</sup>)</h4>
                    </div>
                </div>
            `;

            $(this.options.selector).html(t);

            this.options.tiltAzimuthWidget.renderState();

            const $outline = $(`.plane-${pid} .manual-array-outline`);
            const $pSnap   = $(`.plane-${pid} .manual-array .snap`);
            const $pFill   = $(`.plane-${pid} .fill`);
            const $pModify = $(`.plane-${pid} #segment-actions`);
            const $pSnapTo = $(`.plane-${pid} .modify input`);

            $pSnap.slideDown();

            if (s.qty > 0) {
                // outline drawn and populated
                $outline.slideUp();
                $pFill.slideUp();

                $pModify.slideDown();
                $pSnapTo.attr("disabled", false)
                $pSnapTo.attr("aria-disabled", "false")
            } else {
                emitEvent('status', {message: 'Adjust your outlines by dragging handles.'});
                $pModify.slideUp();
                $pSnapTo.attr("disabled", true)
                $pSnapTo.attr("aria-disabled", "false")

                if (s.arrayInfo && s.arrayInfo.length > 0) {
                    // outline drawn, but not populated
                    $outline.slideUp();
                } else {
                    // blank roof plane
                    $outline.slideDown();
                    $pFill.slideUp();
                }
            }
        }
    }
}

export { SidebarOutlineGui };
