import { ShadingValuesGui } from './GuiShadingValues.js';
import { Format } from './Format.js';
import { formatK } from '../libs/Utilities';



class AllSegmentsGui {
    constructor(options) {
        this.options = options;
        this.getState = options.getState;
    }

    cmd(c, val) {
        this.options.tool.cmd(c, val);
    }

    init() {
        if (this.initialized)
            return;

        this.initialized = true;

    }

    renderState() {
        let s = this.getState();

        let t = `<h3>All Segments</h3>`;

        for (let pid = 0; pid < s.array.planes.length; pid++) {
            let plane = s.array.planes[pid];

            if (!plane.auto) {
                let qty = 0;
                let systemSize = '0 kW';

                let tilt = (plane.tilt !== undefined) ? plane.tilt.toFixed(1) + '&deg;' : '--';
                let azimuth = (plane.azimuth !== undefined) ? plane.azimuth.toFixed(1) + '&deg;' : '--';

                if (s.array.arrayInfo[pid][0]) {
                    qty = formatK(s.array.arrayInfo[pid][0].qty);
                    systemSize = Format.wattsAsKilowatts(s.array.arrayInfo[pid][0].wattage);
                }

                let moduleType = (s.array.moduleSpecs[pid]) ? s.array.moduleSpecs[pid][0].model : '--';

                // area presently calculated for the current segment only
                // TODO: make area available for all segments
                //let area = (measurementUnits === MeasurementUnits.FEET_INCHES) ?
                //    formatK(mSqToFtSq(s.array.area)) + ' sq ft' :
                //    formatK(s.array.area) + ' sq m';

                t += `
                <div class="pane-container all-segs">
                    <h3>${s.array.planes[pid].name}</h3>

                    <div class="tags">
                        <div class="tag">
                            System Size: ${systemSize} DC STC
                        </div>
                        <div class="tag">
                            Module Qty: ${qty}
                        </div>
                        <div class="tag">
                            Module Type: ${moduleType}
                        </div>
                    </div>

                    <div class="card section-viewsheds">
                        <h4>
                            Shade
                        </h4>

                        <div class="shading-values-container-${pid}"></div>
                    </div>

                    <div class="card section-viewsheds">
                        <h4>
                            Array
                        </h4>

                        <div class="az-tilt-area">
                            <div>
                                Azimuth: <span>${azimuth}</span>
                            </div>
                            <div>
                                Tilt: <span>${tilt}</span>
                            </div>
                            <!--
                            <div>
                                Area:
                            </div>
                            -->
                        </div>
                    </div>
                </div>
                `;
            }
        }

        if (s.array.planes.filter(p => !p.auto).length === 0) {
            t = `<div class="pane-container empty">
                    <p class="get-started">Select outlines and draw segments to get started!</p>
                    <img src="/styles/images/scanifly-illustration-primary.svg" class="get-started-illustration">
                </div>`
        }

        $(this.options.selector).html(t);

        for (let pid = 0; pid < s.array.planes.length; pid++) {
            // we're not calling the init method on the widget (not attaching any handlers)
            // just using it as a template
            const shadingValues = new ShadingValuesGui({
                selector: `.shading-values-container-${pid}`,
                getState: this.getState,
            });

            shadingValues.renderState(pid);
        }
    }
}

export { AllSegmentsGui };
