import { emitEvent, viewerInitialized } from '../Viewer.js';
import { areaAndExcavate, areaAndExcavateAreaUnits,
areaAndExcavateDepth, areaAndExcavateVolumeUnits, areaAndExcavateArea, areaAndExcavateVolume, areaAndExcavateClear } from './GuiAreaAndExcavate';
import { vegetationAnnotationsTool, vegetationAnnotationsToolUpdateAnnotations, vegetationAnnotationsToolUpdateMarkerPositions, vegetationAnnotationsToolFollowModelPosition } from './GuiVegetationAnnotationsTool';
import { volumeTool, volumeToolUnits, volumeToolClear, volumeToolVolume } from './GuiVolumeTool';
import { elevationHeatMap, elevationHeatMapThreshold, elevationHeatMapThresholdBounds, elevationHeatMap3D } from './GuiElevationHeatMap';
import { modelDownload, closeSliders, dxfExport, generateShadeReport } from './GuiModelDownload';
import { shadeTool, shadeToolOpacity, shadeToolLocation, shadeToolWeatherStationCallback, shadeToolRotate, shadeToolRotateEnable, shadeToolLockMovement, enableAdminZ, shadeToolLatitude, shadeToolLongitude, shadeToolTimezoneName, shadeToolAzimuth, shadeToolElevation, _updateAzimuthElevation, _formatTime, shadeToolTime, shadeToolDate} from './GuiShadeTool';
import { modelImport, updateRows, updateRowModelUnits , modelImportUpdate , addSceneObjs , modelImportEnablePlaceButton } from './GuiModelImport';
/*import { $, jQuery} from "jquery";
import 'jquery-slider';

$.slider = slider; */



var guiReady = function (callback) {
    $('document').ready(callback);
};


var GUI = function () {
    var self = this;

    $(window).resize(function () {
        //console.log('window resized to', $(window).height());
        self.adjustRightToolPane();
    });
};


GUI.prototype = {
    constructor: GUI,
    areaAndExcavate,
    areaAndExcavateAreaUnits,
    areaAndExcavateDepth,
    areaAndExcavateVolumeUnits,
    areaAndExcavateArea,
    areaAndExcavateVolume,
    areaAndExcavateClear,

    vegetationAnnotationsTool,
    vegetationAnnotationsToolUpdateAnnotations,
    vegetationAnnotationsToolUpdateMarkerPositions,
    vegetationAnnotationsToolFollowModelPosition,

    volumeTool,
    volumeToolUnits,
    volumeToolClear,
    volumeToolVolume,

    elevationHeatMap,
    elevationHeatMapThreshold,
    elevationHeatMapThresholdBounds,
    elevationHeatMap3D,

    modelDownload,
    closeSliders,
    dxfExport,
    generateShadeReport,

    modelImport,
    updateRows,
    updateRowModelUnits,
    modelImportUpdate,
    addSceneObjs,
    modelImportEnablePlaceButton,

    shadeTool,
    shadeToolOpacity,
    shadeToolLocation,
    shadeToolWeatherStationCallback,
    shadeToolRotate,
    shadeToolRotateEnable,
    shadeToolLockMovement,
    enableAdminZ,
    shadeToolLatitude,
    shadeToolLongitude,
    shadeToolTimezoneName,
    shadeToolAzimuth,
    shadeToolElevation,
    _updateAzimuthElevation,
    _formatTime,
    shadeToolTime,
    shadeToolDate,

    protractor: function (callback) {
        var template = [
            '<h3 class="header protractor">Protractor</h3>',
            '<div class="tool-options">',
            '</div>'
        ].join("\n");

        $('.placeholder.protractor').replaceWith(template);
        $('.header.protractor').click(callback);
    },
    show: function (options) {
        options = options || {};

        var self = this;

        // clean up placeholders
        $('.accordion .placeholder').remove();

        var rhs = $('.gui.rhs .accordion');

        rhs.accordion({
            collapsible:    true,
            active:         false,
            heightStyle:    'content',
            activate:       function (e, ui) {
                self.adjustRightToolPane();
            },
        });

        $('.gui.rhs').show();

        $('body').on('click', '.vtab-hide', function (e) {
            var el = $(this);

            $('.inner', $(this).parents('.gui')).animate({
                width: 'toggle'
            }, 250, 'swing', function (e) {
                var icon = $('i', el);

                if (icon.hasClass('fa-arrow-right')) {
                    icon.removeClass('fa-arrow-right');
                    icon.addClass('fa-bars');
                    icon.ariaLabel = 'show tools';
                } else if (icon.hasClass('fa-bars')) {
                    icon.removeClass('fa-bars');
                    icon.addClass('fa-arrow-right');
                }
            });
        });

        this.adjustRightToolPane();
    }
};

function getMouse({ clientX, clientY }, $el) {
    const offset = $el.offset();

    const x = clientX - Math.round(offset.left);
    const y = clientY - Math.round(offset.top);

    return { x, y };
}

GUI.getMouse = getMouse;

GUI.embedMode = function (options) {
    guiReady(function () {
        $('.project-header .title').append(options.title);

        $('.project-header').css('display', 'block');
        $('.project-footer').css('display', 'block');
        $('.gui.lhs').css('top', '47px');
        $('.gui.rhs').css('top', '47px');

        $('.project-header .close').click(function () {
            $('.project-header .close').css('display', 'none');
            $('.start-screen').css('display', 'block');
            $('.start-screen .start').css('display', 'block');

            $('.gui').hide();
            $('.eye-view-container').css('display', 'none');
        });

        $('.start-screen')
            .css('background', 'no-repeat center/100% url(' + options.thumbnail + ')')
            .css('display', 'block');

        $('.start-screen .start').click(function () {
            if (!viewerInitialized) {
                $('.start-screen .start').css('display', 'none');

                options.init();
            } else {
                $('.project-header .close').css('display', 'block');
                $('.start-screen').css('display', 'none');
            }
        });

        $('.project-footer .fullscreen').click(function () {
            // TODO: update fullscreen icon when user exits with Esc
            if (!GUI.inFullscreen()) {
                $('.fullscreen .fa-expand')
                    .removeClass('fa-expand')
                    .addClass('fa-compress')
                    .attr('aria-expanded', 'true');

                GUI.requestFullscreen();
            } else {
                $('.fullscreen .fa-compress')
                    .removeClass('fa-compress')
                    .addClass('fa-expand')
                    .attr('aria-expanded', 'false');

                GUI.exitFullscreen();
            }

        });
    });
};


GUI.inFullscreen = function () {
    var d = document;

    return (d.fullscreenElement || d.webkitFullscreenElement || d.mozFullScreenElement);
};


GUI.requestFullscreen = function () {
    var el = document.documentElement;

    if (el.requestFullscreen) {
        el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
    }
};


GUI.exitFullscreen = function () {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};


GUI.prototype.rangeInputWidget = function (selector, params, value, callback) {
    var s = $(selector);
    var v = $(selector + '-value');

    if (callback !== undefined) {
        s.slider({
            min:   params.min,
            max:   params.max,
            value: params.value,
            step:  params.step,
            range: "min",
            slide: function (e, ui) {
                var value = callback(ui.value);
                v.val(value);
            }
        });

        v.change(function () {
            var val = callback($(this).val());
            s.slider('option', 'value', val);
            $(this).val(val);
        });
    }

    s.slider('option', 'value', value);
    v.val(value);
};


GUI.prototype.adjustRightToolPane = function () {
    var container = $('.gui.rhs .accordion');
    container.css('height',   'auto');
    container.css('overflow', 'visible');

    var difference = $(window).height() - $('.gui.rhs').height() - $('.eye-view-container').height();

    if (difference < 0) {
        var h = Math.max(container.height() + difference, 100);
        container.css('height',   h + 'px');
        container.css('overflow', 'auto');
    }
};


/**
 * @deprecated Use GUI.inputText with a Validator instead.
 */
GUI.prototype.textInputWidget = function (input, value, callback) {
    if (value !== null && value !== undefined)
        input.val(value);

    if (callback !== undefined) {
        input.change(function () {
            var val = callback($(this).val());

            if (val !== undefined)
                $(this).val(val);
        });
    }
};


// TODO: fix this workaround
GUI.textInputWidget = GUI.prototype.textInputWidget;


GUI.inputText = function (selector, options) {
    options = options || { };

    if (options.value !== null && options.value !== undefined)
        $(selector).val(options.validate(options.value), $(selector));

    $('body').on('change', selector, function () {
        $(this).val(options.validate($(this).val(), $(this)));
    });
};

GUI.inputSubmit = function (selector, options) {
    options = options || { };

    if (options.value !== null && options.value !== undefined)
        $(selector).val(options.validate(options.value), $(selector));

    $('body').on('keydown', selector, function (e) {
        if (e.key === "Enter") {
            $(this).val(options.validate($(this).val(), $(this)));
            return false;
        }
    });
};

GUI.showPleaseWait = function(show, value) {
    let type
    let action  = "Download"
    let reports

    if(value === "nrel-pv-report") {
        type    = "CSV"
        reports = "nrel"
    } else if(value === "shade-report") {
        type    = "PDF"
        reports = "shade"
    } else if(value.includes("dxf")) {
        reports = false
        action  = "Export"
        type    = "DXF"
    } else {
        type    = "PDF"
    }

    if (show) {
        if(reports) {
            if(reports === "nrel") {
                $("button.production-data").html("Preparing Download...")
            } else {
                $("button.reports").html("Preparing Download...")
            }
        } else {
            $("button.cad").html("Preparing Download...")
        }
        $(`button.${value}`).prop("disabled", true);
        $(`button.${value}`).attr("aria-disabled", "true");
        $(`button.${value}`).html("Preparing Download...")
    } else {
        if(reports) {
            $("button.reports").html('<i class="fa fa-file" aria-hidden="true"></i>&nbsp; Shade Files')
            $("button.production-data").html('<i class="fa fa-bar-chart" aria-hidden="true"></i>Production Data')
        } else {
            $("button.cad").html('<i class="fa fa-pencil" aria-hidden="true"></i>CAD')
        }
        $(`button.${value}`).prop("disabled", false);
        $(`button.${value}`).attr("aria-disabled", "false");
        $(`button.${value}`).html(`<i class="fa fa-download" aria-hidden="true"></i>${action} ${type}`);
    }
};

/**
 * @template {HTMLElement} TElement
 * @param {JQuery<TElement>} elt
 */
function toggleEnable(elt) {
    return (on = true) => $(elt).prop('disabled', !on)
        .attr('aria-disabled', Boolean(!on).toString())
        .toggleClass('disabled', !on);
}

GUI.toggleEnable = toggleEnable;

/**
 * @template {HTMLElement} TElement
 * @param {JQuery<TElement>} elt
 */
function toggleEnableRecursive(elt) {
    return (on = true) => {
        $(elt).children().each((_, child) => { toggleEnableRecursive(child)(on);});

        return toggleEnable(elt)(on);
    };
}

GUI.toggleEnableRecursive = toggleEnableRecursive;

/**
 * @template T
 * @param {() => Promise<T>} until
 */
function disableUntil(until) {
    /**
     * @template {HTMLElement} T
     * @param {JQuery<T>} elt
     * @param { string | undefined } waitHtml */
    return async (elt, waitHtml) => {
        const priorHtml = elt.html();
        const enable = toggleEnable(elt);

        enable(false).html(waitHtml ?? priorHtml);

        try {
            return await (until());
        } finally {
            enable(true).html(priorHtml);
        }
    }
}

/**
 * @param {string} selector
 * @param {string | () => void} onStart Either a status message (for BC purposes) or a thunk
 *   to trigger prior to starting download.
 * @param {() => (void | Promise<void>)} download
 * @param {(() => void) | undefined} onSuccess Callback to trigger once download is ready.
 * @param {((err: Error) => void) | undefined} onError
 * @param {(() => void) | undefined} onSettled
 */
async function prepareDownload(selector, onStart, download, onSuccess, onError, onSettled) {
    const btn = $(selector);

    if (typeof onStart === 'string') {
        const message = onStart;
        onStart = () => {
            if (message.length) {
                emitEvent('status', { message });
            };
        };
    }

    onStart?.();

    try {
        await disableUntil(download)(btn, 'Preparing download...');
        onSuccess?.();
    } catch (err) {
        if (!onError) {
            throw err;
        }
        onError(err);
    } finally {
        onSettled?.();
    }
}

GUI.prepareDownload = prepareDownload;

export { GUI, disableUntil, getMouse, guiReady, prepareDownload, toggleEnable, toggleEnableRecursive };
