import { emitEvent, project } from '../Viewer.js';
import { browserDownloadUrl } from '../libs/Utilities.js';
import { Config } from '../../bootstrap/Config.js';

import * as html from '../../templates/GuiExports/SurveyData.hbs';

class ExportsSurveyDataGUI {

    constructor( options ) {
        this.name = 'Survey Data';
        this.selector = '.survey-exports';

        this.html = html;
    }

    onDownloadModel() {
        emitEvent('status', { message: 'Downloading 3d model' });
        window.open(project.details.modelUrl);
        window.open(project.details.textureUrl);
        emitEvent('progress', { percent: 100 });
    }

    onDownloadDroneImages() {
        browserDownloadUrl(
            `${Config.baseURL}/media-archive/drone-photos/${project.id}`
        );
    }

    onDownloadKMZ() {
        emitEvent('status', { message: 'Downloading Orthomosaic KMZ' });
        browserDownloadUrl(this.getOrthomosaicUrls().orthoKmzUrl);
    }

    onDownloadJPEG() {
        emitEvent('status', { message: 'Downloading Orthomosaic JPG' });
        browserDownloadUrl(this.getOrthomosaicUrls().orthoJpgUrl)
    }

    onDownloadPNG() {
        emitEvent('status', { message: 'Downloading Orthomosaic PNG' });
        browserDownloadUrl(this.getOrthomosaicUrls().orthoPngUrl);
    }

    onDownloadTIFF() {
        emitEvent('status', { message: 'Downloading Orthomosaic TIFF' });
        browserDownloadUrl(project.details.geoTiffUrl);
    }

    getOrthomosaicUrls() {
        var ext = project.details.orthomosaicUrl.toLowerCase();
        var orthoKmzUrl, orthoJpgUrl, orthoPngUrl;

        if (ext.endsWith('kmz')) {
            orthoKmzUrl = project.details.orthomosaicUrl;
        } else if (ext.endsWith('jpg')) {
            orthoKmzUrl = project.details.orthomosaicUrl.replace(/jpg$/, 'kmz');
            orthoJpgUrl = project.details.orthomosaicUrl;
            orthoPngUrl = project.details.orthomosaicUrl.replace(/jpg$/, 'png');
        }
        return { orthoKmzUrl, orthoJpgUrl, orthoPngUrl };
    }

    init() {
        $('body').on('click', 'button.download-model', () => { this.onDownloadModel() });
        $('body').on("click", "button.download-images", () => { this.onDownloadDroneImages() });
        $('body').on("click", "button.download-orthomosaic-kmz", () => { this.onDownloadKMZ() });
        $('body').on("click", "button.download-orthomosaic-jpg", () => { this.onDownloadJPEG() });
        $('body').on("click", "button.download-orthomosaic-png", () => { this.onDownloadPNG() });
        $('body').on('click', '.download-geotiff', () => { this.onDownloadTIFF() });
    }

    renderState() {
        if (project.details.orthomosaicUrl) {
            const urls = this.getOrthomosaicUrls();
            if(!urls.orthoJpgUrl) {
                $('.download-orthomosaic-jpg-container').hide();
            }
            if(!urls.orthoKmzUrl) {
                $('.download-orthomosaic-kmz-container').hide();
            }
            if(!urls.orthoPngUrl) {
                $('.download-orthomosaic-png-container').hide();
            }
        } else {
            $('.download-orthomosaic-kmz-container').hide();
            $('.download-orthomosaic-png-container').hide();
            $('.download-orthomosaic-jpg-container').hide();
        }

        if (this.geoTiffUrl) {
            $('.download-geotiff').css("display", "block");
        } else {
            $('.download-geotiff-container').hide();
        }
    }
}

export { ExportsSurveyDataGUI }