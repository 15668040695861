import { project, renderer } from '../Viewer';
import { Net } from '../libs/Net';
import { Config } from '../../bootstrap/Config';

/**
 * Options to control how a screenshot is taken
 * @typedef ScreenshotOptions
 * @type {object}
 * @property {boolean} thumbnail - should a thumbnail be generated?
 * @property {number} thumbnailWidth - the image width
 * @property {number} thumbnailHeight - the image height
 */

/**
 * The resulting screenshot data object
 * @typedef Screenshot
 * @type {object}
 * @property {string} url - the url where the screenshot is stored
 * @property {number} width - the image width
 * @property {number} height - the image height
 * @property {string | undefined} thumbnailUrl - the url for the thumbnail
 */

/**
 * @param {ScreenshotOptions} options
 * @returns {Promise<Screenshot>}
 */
async function takeScreenshot(options = { thumbnail: true, thumbnailWidth: 150, thumbnailHeight: 150 }) {

    const result = {
        url: undefined,
        width: renderer.domElement.clientWidth,
        height: renderer.domElement.clientHeight,
        thumbnailUrl: undefined
    };

    const dataUrl = renderer.domElement.toDataURL('image/png');
    const decimatorUrl = await Net.getDecimatorUrl();
    const imageData = await Net.postJSON(decimatorUrl + '/resize', {
        source: dataUrl,
        width:  result.width,
        height: result.height,
        key:    project.id + '/screenshot-' + Date.now() + '.png'
    });

    result.url = imageData.resized;

    if(!options?.thumbnail) {
        return result;
    }

    const thumbnailImageData = await Net.postJSON(decimatorUrl + '/resize', {
        source: result.url,
        width:  options.thumbnailWidth,
        height: options.thumbnailHeight,
        key:    project.id + '/thumbnail-' + Date.now() + '.png'
    });

    result.thumbnailUrl = thumbnailImageData.resized;

    return result;
}

export { takeScreenshot };
