import { prompts } from '../Viewer.js';

class ModuleSpecsToolKeyboardControls {

    constructor (tool) {
        this.tool = tool;
    }

    onKeyboardUpdate(keyboard) {
        let t     = this.tool;
        let mode  = t.state;
        let modes = t.states;

        if (!t.enabled)
            return;

        if (keyboard.down('esc')) {
            let undo = true;

            switch (mode) {
                case modes.NONE:
                case modes.SELECT_ALL:
                case modes.RECT_SELECT:
                case modes.DYNAMIC_MOVING:
                case modes.DYNAMIC_MOVE:
                    t.selectNone();
                    t.propagateState();
                    break;
                case modes.KEEPOUT:
                    undo = t.undoKeepout();
                    break;
                case modes.AUTO:
                case modes.MANUAL:
                    undo = t.undoOutline();
                    break;
                case modes.MOVE:
                    undo = t.finalizeMove();
                    break;
                case modes.MOVING:
                    undo = t.cancelMoving();
                    break;
                case modes.MANUAL_ADD:
                    t.undoManualAdd();
                    undo = false;
                    break;
            }

            if (!undo) {
                t.resetMode();
            }
        } else {
            let moduleInfo = t.getModuleInfo(t.planeId);

            if (moduleInfo.qty > 0) { // outline drawn and populated
                if (keyboard.pressed('ctrl') && keyboard.down('A')) {
                    // select all
                    t.selectAll();
                    t.propagateState();
                } else if (keyboard.pressed('ctrl') && keyboard.down('N')) {
                    // select none
                    // t.selectNone();
                } else if (keyboard.down('A')) {
                    if(!t.inDynamicMode()) {
                        // add module
                        t.addModule();
                    }
                } else if (keyboard.down('M')) {
                    // move modules
                    if(!t.inDynamicMode()) {
                        t.moveModules();
                    }
                } else if (keyboard.down('S')) {
                    // lasso / selection tool
                    if(!t.inDynamicMode()) {
                        t.rectangleSelect();
                    }
                } else if (keyboard.pressed('ctrl') && keyboard.down('C')) {
                    // copy modules
                    if(!t.inDynamicMode()) {
                        t.cmd('copy');
                        t.moveModules();
                    }
                } else if (keyboard.up('backspace') || keyboard.up('delete')) {
                    if (t.selectedModules.length > 0) {
                        prompts.confirm('Are you sure you want to delete all individual modules for this plane?').then(function (ok) {
                            if (ok)
                                t.removeModules();
                        });
                    }
                }
            } else if (moduleInfo) {
                if (moduleInfo.arrayInfo.length > 0) { // outline drawn, but not populated
                    if (keyboard.down('F')) {
                        // fill array
                        t.fillModules();
                    } else if (t.canAddKeepouts()) {
                        if (keyboard.down('B')) { // to be assigned to a key
                            // box keepout
                            t.addKeepout('rect');
                        } else if (keyboard.down('C')) {
                            // circle keepout
                            t.addKeepout('circle');
                        } else if (keyboard.down('L')) {
                            // line keepout
                            t.addKeepout('line');
                        } else if (keyboard.down('P')) {
                            // poly keepout
                            t.addKeepout('poly');
                        } else if (keyboard.down('S')) {
                            // fire setback
                            t.addKeepout('fire-setback');
                        } else if(keyboard.down('shift')) {
                            t.keepoutCtx?.toggleSquareConstraint?.();
                        }
                    }
                } else if (t.canAddOutline()) { // blank roof plane
                    if (keyboard.down('O')) {
                        // create outline
                        t.clear();
                        t.startOutline();
                    }
                }
            }
        }
    }
}


export { ModuleSpecsToolKeyboardControls };
