import { moduleSpecsTool } from '../Viewer.js';
import { Config } from '../../bootstrap/Config';
import { browserDownloadUrl } from '../libs/Utilities.js';

import tippy from 'tippy.js';


function modelDownload(options) {

    var template = `
        <h3 class="header model-download">Exports</h3>
            <div class="tool-options">
                <div class="option drone-data-container">
                    <div class="label">
                    </div>
                    <div class="input-wrapper">
                        <span>
                            <button class="sy drone-data-exports">
                                <i class="fa fa-picture-o" aria-hidden="true"></i>
                                    Survey Data
                                </button>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option reports-container">
                    <div class="label">
                    </div>
                    <div class="input-wrapper">
                    <span class="wrapper-reports">
                        <button class="sy reports">
                            <i class="fa fa-file" aria-hidden="true"></i>&nbsp;Shade Files
                        </button>
                    </span>
                    <a class="tippy-text">Add viewsheds for the Shade Report</a>

                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option cad-container">
                    <div class="label">
                    </div>
                    <div class="input-wrapper">
                        <span class="wrapper-cad">
                            <button class="sy cad">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                CAD
                            </button>
                        </span>
                        <a class="tippy-text">Add segments for DXF</a>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option production-data-container">
                    <div class="label">
                    </div>
                    <div class="input-wrapper">
                        <span class="wrapper-production-data">
                            <button class="sy production-data">
                                <i class="fa fa-bar-chart" aria-hidden="true"></i>
                                Production Data
                            </button>
                        </span>
                        <a class="tippy-text">Add modules &amp; viewsheds for the Hourly Production (8760)</a>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="slide-container-1" style="display: none;">
                    <div class="option">
                        <div class="label">
                            3D model
                        </div>
                        <div class="input-wrapper">
                            <button class="sy download-model">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download OBJ
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="option">
                        <div class="label">
                            Drone Images
                        </div>
                        <div class="input-wrapper">
                            <button class="sy download-images">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download JPEGS
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="option download-orthomosaic-kmz-container">
                        <div class="label ortho-header">
                            Orthomosaic
                        </div>
                        <div class="input-wrapper">
                            <button class="sy download-orthomosaic-kmz" style="display: none;">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download KMZ
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="option download-orthomosaic-jpg-container">
                        <div class="input-wrapper">
                            <button class="sy download-orthomosaic-jpg" style="display: none;">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download JPG
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="option download-orthomosaic-png-container">
                        <div class="input-wrapper">
                            <button class="sy download-orthomosaic-png" style="display: none;">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download PNG
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="option download-geotiff-container">
                        <div class="input-wrapper">
                            <button class="sy download-geotiff" style="display: none;">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download TIFF
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            <div class="save-tip-box"><span><i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp Reminder<br></span>Please save changes prior to downloading exports.</div>
            <div class="slide-container-2" style="display: none;">
                <div class="option download-shade-only">
                    <div class="label">
                        Shade Report
                    </div>
                    <div class="input-wrapper">
                        <button value="shade-report" class="sy shade-report">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Download PDF
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option">
                    <div class="label">
                        Horizon Profile
                    </div>
                    <div class="input-wrapper">
                        <button class="sy horizon-profile-csv">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Download CSV
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="slide-container-3" style="display: none;">
                <div class="option">
                    <div class="label">
                        Parallel Projection per Plane
                    </div>
                    <div class="input-wrapper first">
                        <button class="sy dxf-export-plane" data-tippy-content="Export to AutoCAD LT, AutoCAD 3D, and others">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export DXF
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option">
                    <div class="label">
                        Parallel Projections, All Planes
                    </div>
                    <div class="input-wrapper first">
                        <button class="sy dxf-export-all-planes" data-tippy-content="Export to AutoCAD LT, AutoCAD 3D, and others">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export DXF
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option">
                    <div class="label">
                        2D Wireframe
                    </div>
                    <div class="input-wrapper first">
                        <button class="sy dxf-export-overhead" data-tippy-content="Export to AutoCAD LT, AutoCAD 3D, and others">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export DXF
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="option">
                    <div class="label">
                        3D Wireframe
                    </div>
                    <div class="input-wrapper first">
                        <button class="sy dxf-export-3d" data-tippy-content="Export to AutoCAD 3D and SketchUp">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export DXF
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="divider"></div>
                <div class="models3d">
                    <div class="option">
                        <div class="label">
                            3D Surface Model
                        </div>
                        <div class="input-wrapper first">
                            <button class="sy surface-export-stl" data-tippy-content="Export to SketchUp and others">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Export STL
                            </button>
                        </div>

                        <div class="input-wrapper second">
                            <button class="sy surface-export-obj" data-tippy-content="Export to 3D printing and modeling software">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Export OBJ
                            </button>
                        </div>

                        <div class="input-wrapper third">
                            <button class="sy surface-export-collada" data-tippy-content="Export to PVSyst and others">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Export DAE
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <div class="slide-container-4" style="display: none;">
                <div class="option download-nrel-report">
                    <div class="label">
                        Hourly Production (8760)
                    </div>
                    <div class="input-wrapper">
                        <span class="wrapper-nrel-pv-report">
                            <button value="nrel-pv-report" class="sy nrel-pv-report">
                                <i class="fa fa-download" aria-hidden="true"></i>
                                Download CSV
                            </button>
                        </span>
                        <a class="tippy-text">Please add both viewsheds and one or more modules to each segment first</a>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    `;

    var self = this;

    this.shadeReportCallback    = options.callback
    this.viewshedsSaved         = options.viewshedsSaved
    this.viewshedsPopulated     = options.viewshedsPopulated
    this.modulesDrawn           = options.modulesDrawn
    this.showDXF                = options.showDXF
    this.orthomosaicUrl         = options.orthomosaicUrl
    this.textureUrl             = options.textureUrl
    this.geoTiffUrl             = options.geoTiffUrl
    this.openDialog             = ""
    this.getSegments            = options.getSegments


    $('.placeholder.model-download').replaceWith(template);

    $('body').on("click", "button.download-model", function () {
        // window.location.href = options.originalModelUrl;
        window.open(options.originalModelUrl)
        window.open(options.textureUrl)
    });

    if (this.orthomosaicUrl) {
        var ext = this.orthomosaicUrl.toLowerCase();
        var orthoKmzUrl, orthoJpgUrl, orthoPngUrl;

        if (ext.endsWith('kmz')) {
            orthoKmzUrl = this.orthomosaicUrl;
        } else if (ext.endsWith('jpg')) {
            orthoKmzUrl = this.orthomosaicUrl.replace(/jpg$/, 'kmz');
            orthoJpgUrl = this.orthomosaicUrl;
            orthoPngUrl = this.orthomosaicUrl.replace(/jpg$/, 'png');
        }

        if (orthoKmzUrl) {
            $('.download-orthomosaic-kmz').css("display", "block")
            $('body').on("click", "button.download-orthomosaic-kmz", function() {
                window.location.href = orthoKmzUrl;
            })

        } else {
            $('.download-orthomosaic-kmz-container').hide();
        }

        if (orthoJpgUrl) {
            $('.download-orthomosaic-jpg').css("display", "block")
            $('body').on("click", "button.download-orthomosaic-jpg", function() {
                window.location.href = orthoJpgUrl
            })
        } else {
            $('.download-orthomosaic-jpg-container').hide();
        }

        if (orthoPngUrl) {
            $('.download-orthomosaic-png').css("display", "block")
            $('body').on("click", "button.download-orthomosaic-png", function() {
                window.location.href = orthoPngUrl;
            })
        } else {
            $('.download-orthomosaic-png-container').hide();
        }
    } else {
        $('.download-orthomosaic-kmz-container').hide();
        $('.download-orthomosaic-png-container').hide();
        $('.download-orthomosaic-jpg-container').hide();
    }

    if (this.geoTiffUrl) {
        $('.download-geotiff').css("display", "block")
        $('body').on('click', '.download-geotiff', function () {
            window.location.href = self.geoTiffUrl;
        });
    } else {
        $('.download-geotiff-container').hide();
    }

    if (!this.orthomosaicUrl && !this.geoTiffUrl) {
        $(".ortho-header").hide()
    } else {}

    var imageDownloadUrl;

    $('body').on("click", "button.download-images", function () {
        browserDownloadUrl(
          `${Config.baseURL}/media-archive/drone-photos/${options.projectId}`
        );
    });

    $('.header.model-download').click(function() {
        self.generateShadeReport()
        self.dxfExport()

        self.openDialog = ""
        self.closeSliders()
    })

    $(".slide-container, .tool-options").mouseleave(function() {
        self.openDialog = ""
        self.closeSliders();
    })

    $('body button.nrel-pv-report').on("hover", function(e) {
        let segments = self.getSegments()

        let modsMissing = segments.some(function(segment){
            return segment.kW <= 0
        })

        if(modsMissing){
            $(this).prop("disabled", true)
            $(this).attr("aria-disabled", "true")
            $(this).addClass("disabled-exports")
        }
        $(this).prop("disabled", false)
        $(this).attr("aria-disabled", "false")
        $(this).removeClass("disabled-exports")
    })

    $(".gui.rhs .vtab-hide").on("click", function() {
        self.openDialog = ""
        self.closeSliders();
    })

    // Main container buttons
    var btns       = [".reports", ".cad", ".drone-data-exports", ".production-data"]
    let dialog     = 2
    // Hide all by default
    self.closeSliders()

    btns.forEach( btn => {
        $(`button${btn}`).mouseover(function() {
            // Show hovered container, hide others
            for (let i = 0; i < btns.length; i++) {
                const hoveredOn = btns[i];
                if(btn === hoveredOn) {
                    // Leave open if hovered on and already open
                    if(self.openDialog && self.openDialog === hoveredOn) {
                        return;
                    }
                    const h = hoveredOn
                    switch (h) {
                        case ".reports":
                            dialog = 2
                            self.generateShadeReport()
                            self.openDialog = ".reports"
                            break;
                        case ".cad":
                            dialog = 3
                            self.dxfExport()
                            self.openDialog = ".cad"
                            break;
                        case ".drone-data-exports":
                            dialog = 1
                            self.openDialog = ".drone-data-exports"
                            break;
                        case ".production-data":
                            dialog = 4
                            self.openDialog = ".production-data"
                            break;
                        default:
                            self.generateShadeReport()
                            self.openDialog = ".reports"
                            break;
                        }

                    self.closeSliders(dialog)
                    $(`.slide-container-${dialog}`).show()
                }
            }
        })
    })

    $('button.nrel-pv-report, button.shade-report').click(function () {
        self.shadeReportCallback($(this).val());
    });

    $('button.horizon-profile-csv').click(function () {
        let btnText = $(this).html();

        $(this).html('Preparing download...');
        $(this).attr('disabled', 'disabled');
        $(this).attr('aria-disabled', 'true');

        options.horizonCSV().then(() => {
            $(this).html(btnText);
            $(this).removeAttr('disabled');
            $(this).attr('aria-disabled', 'false');
        }).catch((e) => {
            $(this).html(btnText);
            $(this).removeAttr('disabled');
            $(this).attr('aria-disabled', 'false');

            throw e;
        }).done();
    });

    this.generateShadeReport()
    this.dxfExport()
}

function closeSliders(keep) {
    let arr     = [1,2,3,4]
    if(keep) {
        // hide everything but keep
        arr = arr.filter( el => { return el !== keep })
    }
    arr.forEach( i => {
        const currentDialog = $(`.slide-container-${i}`)
        if (!currentDialog.is(":hidden")) {
            currentDialog.hide()
        }
    })
}
function dxfExport(segmentDiff) {
    let dxfButtons = [
        "button.dxf-export-plane",
        "button.dxf-export-overhead",
        "button.dxf-export-all-planes",
        "button.dxf-export-3d, button.cad"
    ];

    for (let i = 0; i < dxfButtons.length; i++) {
        const dxfButton = dxfButtons[i];
        if (this.showDXF && this.modulesDrawn(segmentDiff)) {
            // enable all
            $(dxfButton).prop("disabled", false)
            $(dxfButton).attr("aria-disabled", "false")
            $(dxfButton).removeClass("disabled-exports")

            if(this.exportTips && this.exportTips[1][0]) {
                this.exportTips[1][0].disable();
            }
        } else {
            // disable all
            $(dxfButton).prop("disabled", true)
            $(dxfButton).attr("aria-disabled", "true")
            $(dxfButton).addClass("disabled-exports")

            if(this.exportTips && this.exportTips[1][0]) {
                this.exportTips[1][0].enable();
            }
        }
    }

    tippy(".slide-container-3 button.sy");
}

function generateShadeReport(diff) {
    var self = this
    var reportsButtons = ["button.nrel-pv-report", "button.shade-report", "button.reports", "button.production-data"]

    const nrel      = reportsButtons[0];
    const shade     = reportsButtons[1];
    const reports   = reportsButtons[2];
    const prod      = reportsButtons[3]; // container for nrel

    let modsMissing = this.getSegments ? this.getSegments().some(function(segment){
        return segment.kW <= 0
    }) : false

    if(this.shadeReportCallback) {
        if(this.viewshedsPopulated(diff) && this.modulesDrawn() && !modsMissing) {
            $(shade).prop("disabled", false)
            $(shade).removeClass("disabled-exports")

            $(reports).prop("disabled", false)
            $(reports).removeClass("disabled-exports")

            if(self.exportTips && self.exportTips[0][0]) {
                self.exportTips[0][0].disable();
            }
            if(self.exportTips && self.exportTips[2][0]) {
                self.exportTips[2][0].disable();
            }
        } else if (this.viewshedsPopulated(diff) && modsMissing) {
            $(shade).prop("disabled", false)
            $(shade).attr("aria-disabled", "false")
            $(shade).removeClass("disabled-exports")

            $(reports).prop("disabled", false)
            $(reports).attr("aria-disabled", "false")
            $(reports).removeClass("disabled-exports")

            if(self.exportTips && self.exportTips[0][0]) {
                self.exportTips[0][0].disable();
            }
            if(self.exportTips && self.exportTips[3][0]) {
                self.exportTips[3][0].disable();
            }
        } else {
            $(shade).prop("disabled", true)
            $(shade).attr("aria-disabled", "true")
            $(shade).addClass("disabled-exports")

            $(reports).prop("disabled", true)
            $(reports).attr("aria-disabled", "true")
            $(reports).addClass("disabled-exports")

            // enable tippy tooltip for reports and production data and nrel
            if(self.exportTips && self.exportTips[0][0]) {
                self.exportTips[0][0].enable();
            }
            if(self.exportTips && self.exportTips[3][0]) {
                self.exportTips[3][0].enable();
            }
            if(self.exportTips && self.exportTips[2][0]) {
                self.exportTips[2][0].enable();
            }
        }
    }

    // TODO: refactor the code above to use state
    const s = moduleSpecsTool.getState();

    $(prod).prop("disabled", s.totalQty === 0);
    $(prod).attr("aria-disabled", (s.totalQty === 0) ? "true" : "false");
    $(prod).toggleClass("disabled-exports", s.totalQty === 0);

    $(nrel).prop("disabled", s.totalQty === 0);
    $(nrel).attr("aria-disabled", (s.totalQty === 0) ? "true" : "false");
    $(nrel).toggleClass("disabled-exports", s.totalQty === 0);





}

export { closeSliders, dxfExport, generateShadeReport, modelDownload }
