
import * as d3 from 'd3';
import { MOUSE } from '../libs/three.module';


var CanvasAnnotations = function (options) {
    options = options || {};

    this.lines       = options.lines   || [];
    this.markers     = options.markers || [];
    this.aspectRatio = options.aspectRatio || 1;

    this.lineWidth = 3;
    this.markerR   = 10.0;

    this.lineColor   = 'rgb(72, 215, 116)';
    this.markerColor = 'rgb(72, 215, 116)';
    this.labelColor  = 'rgb(255, 255, 255)';
    this.labelFont   = 'bold 16px Helvetica, Arial, sans-serif';

    this.handleRange = 3;
    this.crop = options.crop;

    this.states = { NONE: 0, LINE: 1, MARKER: 2, CROP1: 3, CROP2: 4 };
    this.state = this.states.NONE;
};

CanvasAnnotations.prototype = {};
CanvasAnnotations.prototype.constructor = CanvasAnnotations;

CanvasAnnotations.prototype.setCanvas = function (canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.canvasWidth = canvas.width;
    this.canvasHeight = canvas.height;
};

CanvasAnnotations.prototype.setNewImage = function (val) {
    this.newImage = val;
};

CanvasAnnotations.prototype.setImage = function (img) {
    this.imageScale = this.getImageScalingFactor(img);

    var cropHeight = img.width / 2.75;
    var d = (img.height - cropHeight) * this.imageScale;

    if (this.newImage || !this.crop) {
        this.crop = {
            x: 0,
            y: d/2,
            w: img.width * this.imageScale,
            h: cropHeight * this.imageScale,
            d: d
        };
    } else {
        // just use the y position of the cropping rectangle that's already
        // there and build a new one for the current image size
        this.crop = {
            x: 0,
            y: this.crop.y / this.crop.w * img.width * this.imageScale,
            w: img.width * this.imageScale,
            h: cropHeight * this.imageScale,
            d: d
        };
    }

    this.img = img;
    this.aspectRatio = this.img.height / this.img.width;
    this.newImage = false;
};

CanvasAnnotations.prototype.getImageScalingFactor = function (img) {
    var arSrc = img.height / img.width;
    var arDst = this.canvas.height / this.canvas.width;

    var scale = (arDst >= arSrc) ?
        this.canvas.width  / img.width :
        this.canvas.height / img.height;

    return scale;
};

CanvasAnnotations.prototype.setUpdateGui = function (callback) {
    this.updateGui = callback;
};

CanvasAnnotations.prototype.redraw = function () {
    if (this.ctx && this.img) {
        this.ctx.save();

        this.clearScreen();

        this.imageScale = this.getImageScalingFactor(this.img);

        var w = this.img.width  * this.imageScale;
        var h = this.img.height * this.imageScale;

        this.renderWidth = w;

        this.xOffset = (this.canvas.width  - w) / 2;
        this.yOffset = (this.canvas.height - h) / 2;

        this.ctx.translate(this.xOffset, this.yOffset);

        this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height,
                                     0, 0, w, h);

        for (var i = 0; i < this.lines.length; i++)
            this.drawLine(this.lines[i].from, this.lines[i].to);

        for (var i = 0; i < this.markers.length; i++)
            this.drawMarker(this.markers[i]);

        if (this.state === this.states.LINE) {
            this.drawLine(this.from, this.to);
        } else if (this.state === this.states.MARKER) {
            this.drawMarker({
                id: this.viewshedId,
                x:  this.to.x,
                y:  this.to.y
            });
        }

        this.drawCropFrame();

        this.ctx.restore();
    }

    return this;
};

CanvasAnnotations.prototype.clearScreen = function () {
    this.ctx.beginPath();

    this.ctx.fillStyle = 'rgb(25,25,25)';
    this.ctx.rect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fill();

    this.ctx.closePath();
};

CanvasAnnotations.prototype.drawLine = function (from, to) {
    this.ctx.beginPath();

    this.ctx.lineWidth   = this.lineWidth;
    this.ctx.strokeStyle = this.lineColor;

    this.ctx.moveTo(from.x * this.renderWidth, from.y * this.renderWidth);
    this.ctx.lineTo(  to.x * this.renderWidth,   to.y * this.renderWidth);

    this.ctx.stroke();
    this.ctx.closePath();
};

CanvasAnnotations.prototype.drawMarker = function (marker) {
    var x = marker.x * this.renderWidth;
    var y = marker.y * this.renderWidth;

    this.ctx.beginPath();

    this.ctx.fillStyle = this.markerColor;
    this.ctx.arc(x, y, this.markerR, 0, 2*Math.PI);
    this.ctx.fill();

    this.ctx.font = this.labelFont;
    this.ctx.textAlign = 'center';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillStyle = this.labelColor;
    this.ctx.fillText(marker.id + 1, x, y);

    this.ctx.closePath();
};

CanvasAnnotations.prototype.drawCropFrame = function () {
    this.ctx.beginPath();

    var y = this.crop.y / this.crop.w * this.renderWidth;
    var h = this.crop.h / this.crop.w * this.renderWidth;
    var d = this.crop.d / this.crop.w * this.renderWidth;

    this.ctx.fillStyle = 'rgba(0,0,0,0.5)';

    this.ctx.rect(0, 0,     this.renderWidth, y);
    this.ctx.rect(0, y + h, this.renderWidth, d - y);
    this.ctx.fill();

    this.ctx.closePath();
    this.ctx.beginPath();

    this.ctx.lineWidth = this.handleRange * 2;
    this.ctx.strokeStyle = 'rgb(12, 125, 196)';
    this.ctx.rect(0, y, this.renderWidth, h);
    this.ctx.stroke();

    this.ctx.closePath();
};

CanvasAnnotations.prototype.mouseMove = function (mouse, handleCallback) {
    var self = this;

    if (handleCallback)
        handleCallback(this.checkHandle(mouse));

    this.handleImageMouse(mouse, function (mouse) { self.mouseMoveImage(mouse); });

    if (this.state !== this.states.NONE)
        this.redraw();
};

CanvasAnnotations.prototype.mouseEnter = function (mouse) {
    if (this.state === this.states.CROP1 || this.state === this.states.CROP2)
        this.state = this.states.NONE;
};

CanvasAnnotations.prototype.mouseDown = function (mouse, button) {
    if (this.state !== this.states.NONE)
        return;

    var handle = this.checkHandle(mouse);

    if (handle.bottom)
        this.state = this.states.CROP1;

    if (handle.top)
        this.state = this.states.CROP2;
};

CanvasAnnotations.prototype.mouseUp = function (mouse, button) {
    if (button === MOUSE.LEFT) {
        var self = this;

        var handled = this.handleImageMouse(mouse, function (mouse) { self.mouseUpImage(mouse); });

        if (!handled)
            this.state = this.states.NONE;
    } else if (button === MOUSE.RIGHT) {
        this.state = this.states.NONE;
        this.redraw();
    }
};

CanvasAnnotations.prototype.handleImageMouse = function (mouse, callback) {
    if (!this.img)
        return;

    var x = mouse.x - this.xOffset;
    var y = mouse.y - this.yOffset;
    var w = this.img.width * this.imageScale;
    var h = this.img.height * this.imageScale;

    var retval = true;

    if (0 <= x && x < w && 0 <= y && y < h)
        callback({ x: x, y: y });
    else
        retval = false;

    return retval;
};

CanvasAnnotations.prototype.checkHandle = function (mouse) {
    if (!this.crop)
        return;

    var y = this.crop.y / this.crop.w * this.renderWidth;
    var h = this.crop.h / this.crop.w * this.renderWidth;

    var bottomLowY  = this.yOffset + y - this.handleRange;
    var bottomHighY = this.yOffset + y + this.handleRange;
    var topLowY     = this.yOffset + y + h - this.handleRange;
    var topHighY    = this.yOffset + y + h + this.handleRange;

    return {
        bottom: (mouse.y > bottomLowY && mouse.y < bottomHighY),
        top:    (mouse.y > topLowY    && mouse.y < topHighY)
    };
};

CanvasAnnotations.prototype.mouseMoveImage = function (mouse) {
    this.to = { x: mouse.x / this.renderWidth, y: mouse.y / this.renderWidth };

    if (!this.crop)
        return;

    var h = this.crop.h / this.crop.w * this.renderWidth;
    var d = this.crop.d / this.crop.w * this.renderWidth;

    if (this.state === this.states.CROP1) {
        // move crop frame using top border
        this.crop = {
            x: 0,
            y: Math.min(Math.max(0, mouse.y), d),
            w: this.renderWidth,
            h: h,
            d: d
        };
    } else if (this.state === this.states.CROP2) {
        // move crop frame using bottom border
        this.crop = {
            x: 0,
            y: Math.min(Math.max(0, mouse.y - h), d),
            w: this.renderWidth,
            h: h,
            d: d
        };
    }
};

CanvasAnnotations.prototype.mouseUpImage = function (mouse) {
    var to = { x: mouse.x / this.renderWidth, y: mouse.y / this.renderWidth };

    if (this.state === this.states.NONE) {
        this.state = this.states.LINE;
        this.from = to;
    } else if (this.state === this.states.LINE) {
        this.lines.push({ from: this.from, to: to });

        this.from = to;
    } else if (this.state === this.states.MARKER) {
        this.state = this.states.NONE;

        this.markers.push({
            id: this.viewshedId,
            x: mouse.x / this.renderWidth,
            y: mouse.y / this.renderWidth
        });

        this.updateGui(this.viewshedId);
    } else if (this.state === this.states.CROP1 || this.state === this.states.CROP2) {
        this.state = this.states.NONE;
    }
};

CanvasAnnotations.prototype.addViewshed = function (id) {
    if (this.state === this.states.NONE) {
        this.state = this.states.MARKER;
        this.viewshedId = id;
    }
};

CanvasAnnotations.prototype.removeViewshed = function (id) {
    if (this.state === this.states.NONE) {
        for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i].id === id) {
                this.markers.splice(i, 1);
                break;
            }
        }
    }

    return this;
};

CanvasAnnotations.prototype.decrementViewsheds = function (id) {
    if (this.state === this.states.NONE) {
        for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i].id > id) {
                this.markers[i].id--;
            }
        }
    }

    return this;
};

CanvasAnnotations.prototype.clearLines = function () {
    this.lines = [];

    return this;
};

CanvasAnnotations.prototype.getSVG = function () {
    var round = function (val) {
        return Math.round(val * 100) / 100;
    };

    var w = 100;
    var fontSize = round(w / 80);

    var svg = d3.select('body').append('svg')
        .remove() // we'll attach it to the DOM later
        .attr('xmlns',        'http://www.w3.org/2000/svg')
        .attr('xmlns:xlink',  'http://www.w3.org/1999/xlink')
        .attr('viewBox',      '0 0 ' + w + ' ' + w * this.aspectRatio)
        .attr('width',        '1in')
        .attr('height',       this.aspectRatio + 'in')
        .attr('font-size',    fontSize + 'px')
        .attr('font-family',  'Helvetica, Arial, sans-serif');

    var outline = svg.append('g')
        .attr('fill',         'none')
        .attr('stroke',       this.lineColor)
        .attr('stroke-width', '0.003in');

    outline.selectAll('line')
        .data(this.lines)
        .enter()
        .append('line')
        .attr('x1', function (d) { return round(d.from.x * w); })
        .attr('y1', function (d) { return round(d.from.y * w); })
        .attr('x2', function (d) { return round(d.to.x   * w); })
        .attr('y2', function (d) { return round(d.to.y   * w); });

    svg.selectAll('circle')
        .data(this.markers)
        .enter()
        .append('circle')
        .attr('cx',   function (d) { return round(d.x * w) })
        .attr('cy',   function (d) { return round(d.y * w) })
        .attr('r',    '0.012in')
        .attr('fill', this.markerColor);

    svg.selectAll('text')
        .data(this.markers)
        .enter()
        .append('text')
        .attr('x',            function (d) { return round(d.x * w); })
        .attr('y',            function (d) { return round(d.y * w); })
        .attr('dy',           '.4em')
        .attr('fill',         this.labelColor)
        .attr('font-weight',  'bold')
        .style('text-anchor', 'middle')
        .text(function(d) { return d.id + 1 + ''; });

    return svg;
};



export { CanvasAnnotations };
