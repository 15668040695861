


var Permissions = function () {
    this.DISTANCE =     0;
    this.ANGLE =        1;
    this.AREA =         2;
    this.EXCAVATION =   3;
    this.ELEVATION =    4;
    this.ANNOTATIONS =  5;
    this.VOLUME =       6;
    this.DXF_EXPORT =   7;
    this.SHADE =        8;
    this.VIEWSHEDS =    9;
    this.EDIT_MESH =    10;
    this.SOLAR_PANELS = 11;
    this.SHADE_REPORT = 12;
    this.DOWNLOAD     = 13;
    this.VOLUME       = 14;
    this.IMPORT_MODEL = 15;
    this.PROJECT_INFO = 16;

    this.levels = {
        free: [
            this.ANNOTATIONS,
            this.DISTANCE,
            this.EXCAVATION,
            this.AREA,
            this.ANGLE
        ],
        industry: [
            this.VOLUME,
            this.ANNOTATIONS,
            this.DISTANCE,
            this.EXCAVATION,
            this.ELEVATION,
            this.AREA,
            this.ANGLE,
            this.VOLUME,
            this.DXF_EXPORT,
            this.SHADE,
            this.DOWNLOAD,
            this.IMPORT_MODEL,
            this.PROJECT_INFO,
            this.VIEWSHEDS,
            this.EDIT_MESH,
            this.SOLAR_PANELS,
            this.SHADE_REPORT
        ]
    };
};

Permissions.prototype = {};
Permissions.prototype.constructor = Permissions;

Permissions.prototype.hasPermission = function (user, project, permission) {
    let roles = user.roles || [ user.role ];

    for (let role of roles) {
        // there's no difference in levels of access between scaniflyAdmin and admin
        if (role === 'scaniflyAdmin' || role === 'admin')
            return true;

        switch (role) {
            case 'default':
                if (this.levels.industry.indexOf(permission) !== -1)
                    return true;
            default:
                if (this.levels.free.indexOf(permission) !== -1)
                    return true;
        }
    }

    return false;
};

Permissions.prototype.isScaniflyAdmin = function(user) {
    const userRoles = user.roles || [user.role];
    return userRoles.includes('scaniflyAdmin');
}

Permissions = new Permissions;


export { Permissions };
