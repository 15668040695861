import * as THREE from '../libs/three.module';
import { UP } from '../libs/Orienteering';
import { zip } from '../libs/Utilities';

class DeTiltToolState {
    constructor() {
        /**
         * @type {boolean}
         * Is the tool enabled?
         */
        this.enabled = false;

        /**
         * @type {THREE.Vector3[]}
         * The two points that define the adjustment line
         */
        this.points = [];

        /**
         * @type {THREE.Matrix4[]}
         * The updated matrices applied to each adjusted model
         */
        this.matrices = [];

        /**
         * @type {THREE.Mesh}
         * The model that was clicked, which will be adjusted
         */
        this.model = undefined;
    }
}

/**
 * @class DeTiltTool
 *
 */
class DeTiltTool {
    constructor() {

        /**
         * @callback StatePropagationCallback
         * @param {DeTiltToolState} state
         * Type declaration of callback used to propagate state change
         */

        /**
         * @type {StatePropagationCallback}
         * This callback is invoked anytime the state is changed,
         * allowing observers to react accordingly
         */
        this.propagateState;

        /**
         * @type {DeTiltToolState}
         * State of the tool
         */
        this.state = new DeTiltToolState();

        /**
         * @type {THREE.Raycaster}
         * The raycaster that will be used to define the points
         */
        this.raycaster;

        /**
         * @type {THREE.Camera}
         * The camera that will be used to cast a ray based on mouse click
         */
        this.camera;


        /**
         * @type {THREE.LineBasicMaterial}
         * The material that will be used to render the drawn line.
         */
        this.material = new THREE.LineBasicMaterial({ color: 0xff0000, linewidth: 8 });

        /**
         * @type {THREE.Mesh[]}
         * The list of mesh objects that can be selected for adjustment.
         */
        this.meshes = [];

        /**
         * @type {THREE.Matrix4[]}
         * The original matrix values for each mesh prior to any adjustments.
         */
        this.originalMatrices = [];

        /**
         * @type {THREE.Line}
         * The line geometry, defined by the points picked by the user.
         */
        this.line = new THREE.Line(new THREE.BufferGeometry(), this.material);
    }

    /**
     * Invoked by ToolSelector when the tool is selected.
     */
    enable() {
        this.state.enabled = true;
        this.propagateState();
    }

    /**
     * Invoked by ToolSelector when the tool is de-selected.
     */
    disable() {
        this.state.enabled = false;
        this.cancel();
        this.propagateState();
    }

    /**
     * Invoked during GUI construction, allowing this object to
     * add items to the scene.
     * @param {THREE.Scene} scene
     */
    addToScene(scene) {
        scene.add(this.line);
    }

    /**
     * Sets the camera to use with ray casting
     * operations.
     * @param {THREE.Camera} camera
     */
    setCamera(camera) {
        this.camera = camera;
    }

    /**
     * Sets the ray cast object to use.
     * @param {THREE.Raycaster} raycaster
     */
    setRaycaster(raycaster) {
        this.raycaster = raycaster;
    }

    /**
     * Adds the provided mesh to the list of objects
     * that can be operated on. The matrix associated
     * with the mesh at the time of this call is preserved
     * so it can be reset at a later time, if desired.
     * @param {THREE.Mesh} mesh
     */
    addMesh(mesh) {
        if(mesh) {
            this.meshes.push(mesh);
            this.originalMatrices.push(mesh.matrix.clone());
        }
    }

    /**
     * Cancel any in-progress line creation.
     * State change is not propagated.
     */
    cancel() {
        this.line.geometry.setFromPoints([]);
        this.state.points = [];
        this.state.model = undefined;
    }

    /**
     * Cancels any operation currently in progress and
     * resets the mesh transforms back to what they were
     * originally when first loaded. State change is propagated.
     */
    reset() {
        this.cancel();
        this.state.matrices = [];
        zip(this.meshes, this.originalMatrices).map(
            ([mesh, originalMatrix]) => {
                const wasAutoUpdate = mesh.matrixAutoUpdate;
                mesh.matrixAutoUpdate = false;
                mesh.matrix.identity();
                mesh.applyMatrix(originalMatrix);
                mesh.matrixAutoUpdate = wasAutoUpdate;
            }
        )
        this.propagateState();
    }

    /**
     * Registers the propagate state callback function
     * @param {PropagateStateCallback} callback
     */
    registerPropagateState(callback) {
         this.propagateState = callback;
    }

    /**
     * Returns a copy of this object's state
     * @returns {DeTiltToolState}
     */
    getState() {
        return Object.assign({}, this.state);
    }

    /**
     * Assigns this object's state to a copy of the provided state object
     * @param {DeTiltToolState} state
     */
    updateState(state) {
        this.state = Object.assign({}, state);
    }

    /**
     * Returns an object contained required data to preserve model adjustments
     * @returns Object
     */
    getSaveData() {
        return {
            matrices: this.state.matrices.map( (m) => { return m.elements } )
        };
    }

    /**
     * Interprets the saveData object to restore this object's state.
     * The saved matrix values are applied to each mesh object.
     * @param {Object} saveData
     * @returns undefined
     */
    restoreSaveData(saveData) {
        if(!saveData) {
            return;
        }

        const count = Math.min(this.meshes.length, saveData.matrices.length);
        for(let idx = 0; idx < count; ++idx) {
            if(!saveData.matrices[idx]) {
                continue;
            }
            const elements = saveData.matrices[idx];
            const matrix = new THREE.Matrix4().fromArray(elements);
            this.state.matrices.push(matrix)

            const mesh = this.meshes[idx];
            const wasAutoUpdate = mesh.matrixAutoUpdate;
            mesh.matrixAutoUpdate = false;
            mesh.matrix.identity();
            mesh.applyMatrix(matrix);
            mesh.matrixAutoUpdate = wasAutoUpdate;
        }
    }

    /**
     * Handle mouse down event. This method is implemented to capture
     * two points in this object's state, that define the line that
     * will be used to reorient the selected object.
     * @param {MouseEvent} event
     * @param {THREE.Vector2} mouse
     * @param {boolean} panning
     */
    mouseDown(event, mouse, panning) {
        if (!this.state.enabled || event.button !== THREE.MOUSE.LEFT || panning) {
            return;
        }

        if(this.state.points.length == 2) {
            return;
        }

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersections = this.raycaster.intersectObjects(this.meshes);
        if(!intersections.length) {
            return;
        }

        if(!this.state.model) {
            this.state.model = intersections[0].object;
        }


        this.state.points.push(intersections[0].point);

        if(this.state.points.length == 2) {
            this.line.geometry = new THREE.BufferGeometry().setFromPoints(this.state.points);
        }

        this.propagateState();
    }

    /**
     * Handle mouse move. Draws a line tracking the mouse position when
     * only one point has been picked.
     * @param {THREE.Vector2} mouse
     * @param {boolean} panning
     */
    mouseMove(mouse, panning) {
        if (!this.state.enabled || this.state.points.length != 1) {
            return;
        }

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersections = this.raycaster.intersectObjects(this.meshes);
        if(!intersections.length) {
            return;
        }

        this.line.geometry = new THREE.BufferGeometry().setFromPoints([this.state.points[0], intersections[0].point]);
    }

    /**
     * Handle mouse up event
     * @param {MouseEvent} event
     * @param {THREE.Vector2} mouse
     * @param {boolean} panning
     */
    mouseUp(event, mouse, panning) {
    }

    /**
     * Handle keyboard events
     * @param {KeyboardState} keyboard
     */
    handleKeyboard(keyboard) {
        if (!this.state.enabled) {
          return;
        }

        if(keyboard.down('esc')) {
            this.cancel();
            this.propagateState();
        }
    }
    /**
     *
     * @param {THREE.Object3D} mesh The object to be reoriented
     * @param {THREE.Vector3} axisPoint World position defining the origin of the pivot.
     * @param {THREE.Vector3} axisDirection Unit vector pointing in the direction of the pivot axis
     * @param {number} angle Angle of the pivot, in radians
     * @returns
     */
    pivotModels(mesh, axisPoint, axisDirection, angle) {
        const parent = mesh.parent;

        if(parent) {
            parent.updateMatrixWorld();
        }

        const parentWorldToLocal = parent ? new THREE.Matrix4().getInverse(parent.matrixWorld) : new THREE.Matrix4();
        const parentWorldRotation = new THREE.Matrix4().extractRotation(parentWorldToLocal);

        const parentLocalAxisPoint = axisPoint.clone().applyMatrix4(parentWorldToLocal);
        const parentLocalAxisDirection = axisDirection.clone().applyMatrix4(parentWorldRotation);
        const parentLocalRotation = new THREE.Matrix4().makeRotationAxis(parentLocalAxisDirection, angle);

        // compute the new origin
        const originVector = mesh.position.clone().sub(parentLocalAxisPoint);
        originVector.applyMatrix4(parentLocalRotation);
        const newOrigin = parentLocalAxisPoint.clone().add(originVector);

        mesh.position.copy(new THREE.Vector3());
        mesh.applyMatrix(parentLocalRotation);
        mesh.position.copy(newOrigin);
        mesh.updateMatrix();
        return mesh.matrix.clone();
    }

    /**
     * This method will adjust the orientation of the selected model such that
     * the defined line becomes alined with the horizontal plane. The updated
     * matrix is stored in the states's matrices array.
     * @returns undefined
     */
    deTilt() {
        if( this.state.points.length != 2) {
            console.warn('Unable to de-tilt: Incorrect number of pick points.');
            return;
        }

        if(!this.state.model) {
            console.warn('Unable to de-tilt: Model to adjust not set.');
            return;
        }

        const worldPickDirection = this.state.points[1].clone().sub(this.state.points[0]).normalize();
        const worldRotationAxis = worldPickDirection.clone().cross(UP).normalize();
        const worldHorizontalDirection = UP.clone().cross(worldRotationAxis).normalize();
        const rotationAngleRadians = -Math.sign(worldPickDirection.dot(UP)) * Math.acos(worldPickDirection.dot(worldHorizontalDirection));
        const updatedMatrix = this.pivotModels(this.state.model, this.state.points[0], worldRotationAxis, rotationAngleRadians);
        this.state.matrices[this.meshes.indexOf(this.state.model)] = updatedMatrix;
        this.cancel();
        this.propagateState();
    }
}

export { DeTiltTool, DeTiltToolState };