/// @ts-check

import { degToRad } from '../libs/Geometry';
import { KeyStore } from '../alibs/KeyStore.js';
import { BoxGeometry, BufferGeometry, Color, DoubleSide, Float32BufferAttribute, Mesh,  MeshBasicMaterial, MeshPhongMaterial, PlaneGeometry, RepeatWrapping, Object3D, TextureLoader, Vector3 } from '../libs/three.module';


var SolarPanelModuleCache = new KeyStore();


var solarPanelModuleTextureMap = {
    'poly':             'styles/images/modules/cell-polycrystalline.png?x=v3',
    'mono':             'styles/images/modules/cell-monocrystalline.png?x=v3',
    'mono-black':       'styles/images/modules/cell-monocrystalline-black.png?x=v3',
    'lg-neon':          'styles/images/modules/cell-lg-neon.png?x=v3',
    'sunpower-x':       'styles/images/modules/cell-sunpower-x.png?x=v3',
    'sunpower-x-black': 'styles/images/modules/cell-sunpower-x-black.png?x=v3',
    'poly-blue-2':      'styles/images/modules/cell-poly-blue-2.png?x=v3',
    'mono-blue-3':      'styles/images/modules/cell-mono-blue-3.png?x=v3',
    'mono-black-4':     'styles/images/modules/cell-mono-black-4.png?x=v3',
    'mono-black-5':     ["styles/images/modules/cell-mono-black-5-vertical.png?x=v3",
                        "styles/images/modules/cell-mono-black-5-horizontal.png?x=v3"]
};


var SOLAR_PANEL_MODULE_BORDER_W = 0.05;


var SolarPanelModuleStatic = {
    materialSnapCubeNormal: new MeshBasicMaterial({
        color:       0x00ffff,
        transparent: true,
        opacity:     0.75,
        depthTest:   false
    }),
    materialSnapCubeHover: new MeshBasicMaterial({
        color:       0xffff00,
        transparent: true,
        opacity:     0.75,
        depthTest:   false
    }),
    geometrySnapCube: new BoxGeometry(0.1, 0.1, 0.1)
};

class SolarPanelModule extends Object3D {
    constructor(params) {
        super();

        /** @typedef {typeof defaults} SolarPanelModuleConstructorParams */
        var defaults = {
            width:       1,
            height:      1,
            moduleType:  'poly',
            cols:        1,
            rows:        1,
            orientation: 'portrait',
            hOffset:     0.1,
            vOffset:     0.1,
            borderWidth: 0.05
        };

        /** @type {SolarPanelModuleConstructorParams} */
        this.params = $.extend(defaults, params);

        var w           = this.params.width;
        var h           = this.params.height;
        var moduleType  = this.params.moduleType;
        var cols        = this.params.cols;
        var rows        = this.params.rows;
        var borderWidth = this.params.borderWidth;
        var orientation = this.params.orientation;

        // flip the offsets depending on orientation for compatibility with legacy code
        var hOffset = (orientation === 'portrait') ? this.params.hOffset : this.params.vOffset;
        var vOffset = (orientation === 'portrait') ? this.params.vOffset : this.params.hOffset;

        /** @type {Object3D} */
        this.frame1 = new Object3D();
        this.frame1.position.set(0, -h * rows, 0);

        this.materialKey = [ 'material', w, h, moduleType, orientation ];

        if (SolarPanelModuleCache.get(this.materialKey) === undefined) {
            var url = solarPanelModuleTextureMap[moduleType];

            if (Array.isArray(url)) {
                url = (orientation === 'portrait') ? url[0] : url[1];
            }

            var texture = new TextureLoader().load(url);

            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;

            var cellW = 0.169;
            var cellH = 0.169;

            texture.repeat.set(w / cellW, h / cellH);

            var panelColor     = new Color(0xffffff);
            var borderColor    = new Color(this.getBorderColor(moduleType));
            var selectionColor = new Color(0x26ec6a);
            var conflictColor  = new Color(0xFF0000);

            // keep materials double side for correct shadow rendering, otherwise we need panels to be closed volumes
            SolarPanelModuleCache.set(this.materialKey, {
                panelMaterial: new MeshPhongMaterial({
                    side:      DoubleSide,
                    map:       texture,
                    shininess: 100,
                    color:     panelColor
                }),
                borderMaterial: new MeshPhongMaterial({
                    side:      DoubleSide,
                    shininess: 100,
                    color:     borderColor
                }),
                panelSelectedMaterial: new MeshPhongMaterial({
                    side:      DoubleSide,
                    map:       texture,
                    shininess: 100,
                    color:     selectionColor
                }),
                borderConflictMaterial: new MeshPhongMaterial({
                    side:      DoubleSide,
                    shininess: 100,
                    color:     conflictColor
                })
            });
        }

        this.selected = false;

        for (var col = 0; col < cols; col++) {
            for (var row = 0; row < rows; row++) {
                var offsetX = w/2 + (w + hOffset) * col;
                var offsetY = h/2 + (h + vOffset) * row;

                this.mesh = this.createMesh(w - borderWidth, h - borderWidth, borderWidth/2);
                this.mesh.translateX(offsetX);
                this.mesh.translateY(offsetY);

                this.mesh.receiveShadow = true;
                this.mesh.castShadow = true;

                // border - not added to the scene, used for backward compatibility
                var geometry = new PlaneGeometry(w, h);

                /** @type {import('../../../types').NoBufferGeom<Mesh>} */
                this.border = /** @type {import('../../../types').NoBufferGeom<Mesh>} */
                    (new Mesh(geometry, this.borderMaterial));
                this.border.translateX(offsetX);
                this.border.translateY(offsetY);

                this.frame1.add(this.mesh);
            }
        }

        /** @type {Object3D} */
        this.frame2 = new Object3D();
        this.frame2.add(this.frame1);
        this.frame2.position.set(0, h * rows, 0);

        this.add(this.frame2);

        this.rotateX(-Math.PI/2);

        this.updateMatrixWorld();
        this.frame2.updateMatrixWorld();
        this.frame1.updateMatrixWorld();
    }

    /**
     * @param {number} w
     * @param {number} h
     * @param {number} bw
     */
    createMesh(w, h, bw) {
        var key = [ 'geometry', w, h ];

        if (!SolarPanelModuleCache.get(key)) {
            var rectIndex = [ 0, 2, 1, 2, 3, 1 ];

            var vv = [];

            // panel
            vv = vv.concat(this.rectVertices(0, 0, w, h));

            // border consisting of 4 rectangles
            vv = vv.concat(this.rectVertices(-w/2 - bw/2,           0, bw, h + 2*bw));
            vv = vv.concat(this.rectVertices(          0,  h/2 + bw/2,  w,       bw));
            vv = vv.concat(this.rectVertices( w/2 + bw/2,           0, bw, h + 2*bw));
            vv = vv.concat(this.rectVertices(          0, -h/2 - bw/2,  w,       bw));

            var ii = [];

            for (let i = 0; i < vv.length / 12; i++) {
                for (let j = 0; j < rectIndex.length; j++) {
                    ii.push(rectIndex[j] + 4*i); // each rect has 4 vertices
                }
            }

            var normals = [], uvs = [];

            for (let i = 0; i < vv.length; i += 3) {
                uvs.push((vv[i    ] + w/2 + bw) / (w + bw*2));
                uvs.push((vv[i + 1] + h/2 + bw) / (h + bw*2));

                normals.push(0, 0, 1);
            }

            var g = new BufferGeometry();

            g.addAttribute('position', new Float32BufferAttribute(vv,      3));
            g.addAttribute('normal',   new Float32BufferAttribute(normals, 3));
            g.addAttribute('uv',       new Float32BufferAttribute(uvs,     2));
            g.setIndex(ii);

            // set up groups to render panel and border with different materials
            g.addGroup(0,  6, 0);
            g.addGroup(6, 30, 1);

            SolarPanelModuleCache.set(key, g);
        }

        var materials = SolarPanelModuleCache.get(this.materialKey);
        var moduleMaterials = [ materials.panelMaterial, materials.borderMaterial ];

        /** @type {import('../../../types').HasBufferGeom<Mesh>} */
        var m = new Mesh(SolarPanelModuleCache.get(key), moduleMaterials);

        return m;
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @returns {import('../../../types').Tuple<number, 12>}
     */
    rectVertices(x, y, w, h) {
        return [
            x - w/2, y + h/2, 0.0,
            x + w/2, y + h/2, 0.0,
            x - w/2, y - h/2, 0.0,
            x + w/2, y - h/2, 0.0
        ];
    }


    getBorderColor(moduleType) {
        var borderColor;

        switch (moduleType) {
            case 'sunpower-x-black':
            case 'sunpower-x':
            case 'lg-neon':
            case 'mono-blue-3':
            case 'mono-black-4':
            case 'mono-black':
                borderColor = 0x161616; // black
                break;
            case 'mono':
            case 'poly':
            case 'poly-blue-2':
                borderColor = 0xd3d3d3; // gray
            case 'mono-black-5':
                borderColor = 0xC0C0C0; // silver
                break;
            default:
                borderColor = 0xffffff;
                break;
        }

        return borderColor;
    }

    /**
     * @param {number} tilt
     */
    setTilt(tilt) {
        const container = (tilt > 0) ? this.frame1 : this.frame2;

        container.rotateX(degToRad(tilt));

        this.tilt = tilt;

        return this;
    };


    /**
     * Remove rotation applied by tilt from v in local mesh coordinates and
     * translate to world coordinates.
     *
     * Used to show reference points for modules with tilt different from the
     * roof plane.
     *
     * @param {Vector3} v
     */
    undoTilt(v) {
        const container = (this.tilt > 0) ? this.frame1 : this.frame2;

        container.rotateX(degToRad(-this.tilt));
        container.updateMatrixWorld();

        const vw = this.mesh.localToWorld(v);

        container.rotateX(degToRad(this.tilt));
        container.updateMatrixWorld();

        return vw;
    }


    addGuidelines(guidelines) { this.frame1.add(guidelines); };
    getPanelMatrix()          { return this.frame1.matrixWorld; };

    /**
     * Get polyline traversal of the vertices of the module.
     *
     * Vertex objects in the array are copies so ownership is transferred to the caller.
     *
     * @public
     * @returns {import('../../../types').Tuple<import('three').Vector3, 4>}
     */
    getPolyline() {
        const vs = this.border.geometry.vertices;
        const fs = this.border.geometry.faces;

        /**
         * Return value from the triangulation of the module is derived from the
         * original code in DxfExportTool below, along with the code in @link {getVertices}:
         *
         *  outputLines.push([projectedModule[0], projectedModule[1], "modules"]);
         *  outputLines.push([projectedModule[1], projectedModule[2], "modules"]);
         *  outputLines.push([projectedModule[4], projectedModule[5], "modules"]);
         *  outputLines.push([projectedModule[5], projectedModule[3], "modules"]);
         */

        /// @ts-ignore There are, indeed, four items.
        return [
            vs[fs[0].a],
            vs[fs[0].b],
            vs[fs[1].b],
            vs[fs[1].c]
        ].map(
            v => this.mesh.localToWorld(v.clone())
        );
    }

    getVertices() {
        var vv = this.border.geometry.vertices;

        var coords = [];

        this.border.geometry.faces.forEach((f) => {
            coords.push(vv[f.a].clone());
            coords.push(vv[f.b].clone());
            coords.push(vv[f.c].clone());
        })

        return coords.map((v) => this.mesh.localToWorld(v));
    }


    getGeometry() {
        let g = this.border.geometry.clone();

        this.mesh.updateMatrixWorld();
        g.applyMatrix(this.mesh.matrixWorld);

        return g;
    }


    /**
     * TODO: figure out how many times this is called on start/load changes
     *
     * @param {number} hPad
     * @param {number} vPad
     */
    getAnchorVertices(hPad, vPad) {
        var vv = this.border.geometry.vertices;

        var anchors = [
            // corner points
            vv[0].clone(), // top left
            vv[2].clone(), // bottom left
            vv[3].clone(), // bottom right
            vv[1].clone(), // top right

            // edge midpoints
            vv[0].clone().add(vv[1]).divideScalar(2), // top
            vv[1].clone().add(vv[3]).divideScalar(2), // right
            vv[2].clone().add(vv[3]).divideScalar(2), // bottom
            vv[0].clone().add(vv[2]).divideScalar(2)  // left
        ];

        for (let i = 0; i < 4; i++)
            anchors[i].anchorType = 'Corner';

        for (let i = 4; i < 8; i++)
            anchors[i].anchorType = 'Middle';

        if (hPad !== undefined || vPad !== undefined) {
            if (hPad === undefined)
                hPad = 0;

            if (vPad === undefined)
                vPad = 0;

            anchors.push(...[
                // padded corner points
                vv[0].clone().add(new Vector3(-hPad,  vPad, 0)), // top left
                vv[2].clone().add(new Vector3(-hPad, -vPad, 0)), // bottom left
                vv[3].clone().add(new Vector3( hPad, -vPad, 0)), // bottom right
                vv[1].clone().add(new Vector3( hPad,  vPad, 0)), // top right

                // padded edge midpoints
                vv[0].clone().add(vv[1]).divideScalar(2).add(new Vector3(    0,  vPad, 0)), // top
                vv[1].clone().add(vv[3]).divideScalar(2).add(new Vector3( hPad,     0, 0)), // right
                vv[2].clone().add(vv[3]).divideScalar(2).add(new Vector3(    0, -vPad, 0)), // bottom
                vv[0].clone().add(vv[2]).divideScalar(2).add(new Vector3(-hPad,     0, 0)), // left

                vv[0].clone().add(new Vector3(-hPad, 0, 0)), // top left
                vv[2].clone().add(new Vector3(-hPad, 0, 0)), // bottom left
                vv[3].clone().add(new Vector3( hPad, 0, 0)), // bottom right
                vv[1].clone().add(new Vector3( hPad, 0, 0)), // top right

                vv[0].clone().add(new Vector3(0,  vPad, 0)), // top left
                vv[2].clone().add(new Vector3(0, -vPad, 0)), // bottom left
                vv[3].clone().add(new Vector3(0, -vPad, 0)), // bottom right
                vv[1].clone().add(new Vector3(0,  vPad, 0)), // top right

                vv[0].clone().add(vv[1]).divideScalar(2).add(new Vector3(    hPad/2,  vPad, 0)), // top
                vv[0].clone().add(vv[1]).divideScalar(2).add(new Vector3(   -hPad/2,  vPad, 0)), // top

                vv[1].clone().add(vv[3]).divideScalar(2).add(new Vector3( hPad,     vPad/2, 0)), // right
                vv[1].clone().add(vv[3]).divideScalar(2).add(new Vector3( hPad,    -vPad/2, 0)), // right

                vv[2].clone().add(vv[3]).divideScalar(2).add(new Vector3(    hPad/2, -vPad, 0)), // bottom
                vv[2].clone().add(vv[3]).divideScalar(2).add(new Vector3(   -hPad/2, -vPad, 0)), // bottom

                vv[0].clone().add(vv[2]).divideScalar(2).add(new Vector3(-hPad,     vPad/2, 0)), // left
                vv[0].clone().add(vv[2]).divideScalar(2).add(new Vector3(-hPad,    -vPad/2, 0)), // left
            ]);

            for (let i = 8; i < 12; i++)
                anchors[i].anchorType = 'Corner + Offset';

            for (let i = 12; i < 16; i++)
                anchors[i].anchorType = 'Middle + Offset';

            for (let i = 16; i < 20; i++)
                anchors[i].anchorType = 'Corner + H Offset';

            for (let i = 20; i < 24; i++)
                anchors[i].anchorType = 'Corner + V Offset';

            for (let i = 24; i < 32; i++)
                anchors[i].anchorType = 'Middle + Half Offset';
        }

        return anchors.map((v) => this.undoTilt(v));
    }


    select() {
        this.selected = true;

        var materials = SolarPanelModuleCache.get(this.materialKey);

        var borderMaterial = (this.conflictSelected) ?
            materials.borderConflictMaterial :
            materials.borderMaterial;

        this.mesh.material = [ materials.panelSelectedMaterial, borderMaterial ];
    }

    deselect() {
        this.selected = false;

        var materials = SolarPanelModuleCache.get(this.materialKey);

        var borderMaterial = (this.conflictSelected) ?
            materials.borderConflictMaterial :
            materials.borderMaterial;

        this.mesh.material = [ materials.panelMaterial, borderMaterial ];
    }

    conflictSelect() {
        this.selected = false;
        this.conflictSelected = true;

        var materials = SolarPanelModuleCache.get(this.materialKey);

        this.mesh.material = [ materials.panelMaterial, materials.borderConflictMaterial ];
    }

    conflictDeselect() {
        this.selected = false;
        this.conflictSelected = false;

        var materials = SolarPanelModuleCache.get(this.materialKey);

        this.mesh.material = [ materials.panelMaterial, materials.borderMaterial ];
    }


    refPointsMode(show) {
        if (this.refCubes) {
            for (let c of this.refCubes)
                this.mesh.remove(c);
        }

        this.refCubes = [];

        if (show) {
            var vv = this.border.geometry.vertices;

            var anchors = [
                // corner points
                vv[0].clone(), // top left
                vv[2].clone(), // bottom left
                vv[3].clone(), // bottom right
                vv[1].clone(), // top right

                // edge midpoints
                vv[0].clone().add(vv[1]).divideScalar(2), // top
                vv[1].clone().add(vv[3]).divideScalar(2), // right
                vv[2].clone().add(vv[3]).divideScalar(2), // bottom
                vv[0].clone().add(vv[2]).divideScalar(2)  // left
            ];

            anchors.map((v) => this.mesh.worldToLocal(this.undoTilt(v))).forEach((a) => {
                let c = new Mesh(
                    SolarPanelModuleStatic.geometrySnapCube,
                    SolarPanelModuleStatic.materialSnapCubeNormal
                );

                c.position.copy(a);

                this.mesh.add(c);
                this.refCubes.push(c);
            });
        }
    }


    toggleSelect() {
        if (this.selected)
            this.deselect();
        else
            this.select();

        return this.selected;
    }
}


export {
  SolarPanelModule,
  SolarPanelModuleStatic,
  SOLAR_PANEL_MODULE_BORDER_W,
  solarPanelModuleTextureMap,
  SolarPanelModuleCache
};
