import { Polygon } from './Polygon';

import { Vector2 } from './three.module';

class CollisionDetector {
    /**
     * @param {{
     *   ref: THREE.Object3D,
     *   bounds2: Polygon,
     *   keepouts: (PolyContext | CircleContext | LineContext | RectContext)[],
     *   modules?: unknown,
     *   ma?: SolarPanelModuleArray
     * }} param0
     */
    constructor({ ref, bounds2, keepouts, modules, ma }) {
        this.ma = ma;
        this.ref = ref;
        this.bounds2 = bounds2;

        /** @type {{center: THREE.Vector2, radius: number}[]} */
        this.circles = [];

        /** @type {Polygon[]} */
        this.polys   = [];

        /** @type {Rect[]} */
        this.rects = [];

        /** @type {THREE.Vector2[][]} */
        this.lines   = [];

        if(modules) {
            let svv = this.ma.getVerticesExcept(modules.ignoreM)

            svv.forEach( (arrayVertices) => {
                let poly2 = new Polygon({
                    vertices: arrayVertices.map(v => this.worldTo2D(v))
                });
                this.polys.push(poly2);
            })
        }

        if(keepouts) {
            for (let k of keepouts) {
                if (k.shape === 'circle') {
                    this.circles.push({
                        center: this.worldTo2D(k.getVertices()[0]),
                        radius: k.radius + k.setbackSize
                    });
                } else if (k.shape === 'line' || k.shape === 'poly' || k.shape === 'rect') {
                    let svv = k.getSetbackVertices();

                    if (svv.length === 0) {
                        svv = k.getVertices();

                        this.lines.push(svv.map(v => this.worldTo2D(v)));
                    } else {

                        let poly2 = new Polygon({
                            vertices: svv.map(v => this.worldTo2D(v))
                        });

                        this.polys.push(poly2);
                    }
                }
            }
        }
    }


    worldTo2D(v) {
        var vw = this.ref.worldToLocal(v.clone());
        var v2 = new Vector2(vw.x, vw.z);

        return v2;
    }


    /**
     * Check if the rectangle at (x, y) with width w and height h is inside the
     * bounds and doesn't overlap any of the keepouts.
     */
    inBounds(x, y, w, h) {
        var rect = new Polygon({ x, y, w, h });

        // rect has to be completely inside bounds
        for (let v of rect.vertices)
            if (!this.bounds2.contains(v))
                return false;

        for (let be of this.bounds2.edges)
            for (let re of rect.edges)
                if (rect.edgeIntersection(re, be))
                    return false;

        // rect can't overlap polygonal keepouts
        for (let p of this.polys) {
            for (let v of p.vertices)
                if (rect.contains(v))
                    return false;

            for (let v of rect.vertices)
                if (p.contains(v))
                    return false;

            for (let re of rect.edges)
                for (let pe of p.edges)
                    if (rect.edgeIntersection(re, pe))
                        return false;
        }

        // rect can't intersect lines
        for (let l of this.lines)
            for (let re of rect.edges)
                if (rect.edgeIntersection(re, { v1: l[0], v2: l[1] }))
                    return false;

        // rect can't overlap circular keepouts
        for (let c of this.circles) {
            if (rect.contains(c.center))
                return false;

            let r = c.radius;

            for (let v of rect.vertices)
                if (v.distanceTo(c.center) < r)
                    return false;

            // check intersections of rect edges and circles
            for (let e of rect.edges) {
                let d = e.v2.clone().sub(e.v1);
                let f = e.v1.clone().sub(c.center);

                let a = d.dot(d);
                let b = 2*f.dot(d);
                let m = f.dot(f) - r*r;

                let discr = b*b - 4*a*m;

                if (discr >= 0) {
                    discr = Math.sqrt(discr);

                    let t1 = (-b - discr)/(2*a);
                    let t2 = (-b + discr)/(2*a);

                    if (t1 >= 0 && t1 <= 1)
                        return false;

                    if (t2 >= 0 && t2 <= 1)
                        return false;
                }
            }
        }

        return true;
    }

    inModuleBounds(x, y, w, h) {
        var rect = new Polygon({ x, y, w, h });

        for (let i = 0; i < this.polys.length; i++) {
            const p = this.polys[i];

            // for each vertice of that remaining module polygon
            for (let v of p.vertices)
                // if the current module contains that vertice
                if (rect.contains(v)) {
                    return false;
                }
            // // Optimize?
            // for (let v of rect.vertices)
            //     if (p.contains(v)) {
            //         console.log('poly vertices conflict / p.contains(v)');
            //         return false;
            //     }
            // // Optimize?
            // for (let re of rect.edges)
            //     for (let pe of p.edges)
            //         if (rect.edgeIntersection(re, pe)) {
            //             console.log('poly vertices conflict / edge intersection');
            //             return false;
            //         }
        }

        return true;
    }
}



export { CollisionDetector };
