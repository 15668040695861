import { project, renderer, moduleSpecsTool } from '../Viewer.js';
import { Net } from '../libs/Net';
import { GRAPH } from '../libs/Graph';
import { formatK } from '../libs/Utilities';
import { Config } from '../../bootstrap/Config';
import * as d3 from 'd3';
import * as Q from'q';

class ShareTool {
    constructor(options) {
        this.project = options.project;
        this.design = options.currentDesign;
        this.shareName = options.shareName || options.project.name;
        this.salesInfo = options.salesInfo || [''];

        this.images = options.images;

        if (!this.images) {
            this.images = [];
        }

        this.jpegBanner;
        this._shareURL;

        this.options = options;
    }


    get shareUrl() { return this._shareURL || this.options.project.shareLink; }


    getState() {
        return {
            project:   this.project,
            shareName: this.shareName,
            salesInfo: this.salesInfo,
            images:    this.images
        };
    }


    cmd(command, val) {
        if (command === 'editName') {
            this.shareName = val;
            return val;
        } else if (command === 'editSalesInfo') {
            this.salesInfo[val.idx] = val.val;
            return val.val;
        } else {
            console.log('Unknown command:', command, val);
        }
    }


    createShareLink() {
        let shareData = {};

        /** @global moduleSpecsTool */
        return Q.all([
            moduleSpecsTool.getProductionValues(),
            Net.getCompanyLogoBase64(),
            Net.getDecimatorUrl()
        ]).spread((values, companyLogo, decimatorUrl) => {
            let p;

            if (values !== undefined) {
                let access      = values.totalAvgASA.toFixed(0);
                let consumption = formatK(values.annualUsage.toFixed(3));
                let totalKW     = values.systemSize.toFixed(3);
                let production  = formatK(values.annualProductionTotal.toFixed(3));
                let offset      = (consumption === "0" || values.annualUsage === 0) ? "0" : values.systemOffset.toFixed(0);

                shareData = { access, consumption, totalKW, production, offset };

                let graph = GRAPH.monthly([ values.consumption, values.production ], {
                    color:       [ '#a6a6a6', '#27E2A4' ],
                    format:      function (x) { return d3.format('s')(x) + 'Wh'; },
                    fontSize:    4,
                    barWidth:    13,
                    aspect:      2,
                    paddingLeft: 18,
                    labelFormat: d3.format('.3s')
                });

                const key = this.options.project.id + '/graphs/consumption-production-' + new Date().getTime() + '.svg';

                p = Net.postJSON(decimatorUrl + '/s3', {
                    key:          key,
                    data:         graph.node().outerHTML,
                    content_type: 'image/svg+xml'
                });
            } else { // empty promise
                let d = Q.defer();
                d.resolve();
                p = d.promise;
            }

            if (companyLogo) {
                this.images[5] = companyLogo;
            }

            return p;
        }).then((response) => {
            const firstName = this.project.ownerDetails ? this.project.ownerDetails.firstName : '';
            const lastName  = this.project.ownerDetails ? this.project.ownerDetails.lastName  : '';

            Object.assign(shareData, {
                projectId:   this.project.id,
                projectName: this.shareName,
                salesInfo:   this.salesInfo,
                address:     this.project.address,
                firstName,
                lastName,
                jpegBanner:  this.jpegBanner,
                images:      this.images,
                graph:       (response && response.success) ? response.url : 'false',
                imgDownloadURL:    `${Config.baseURL}/media-archive/drone-photos/${this.project.id}`,
                iFrameSRC:         `${window.location.origin}/?ID=${this.project.id}&did=${this.design.id}&embed=true`
            });

            return Net.shareProject(this.design.id, shareData);
        }).then((response) => {
            this._shareURL = response.body;

            return this._shareURL;
        });
    }

    uploadPhoto(imgIdx, imgFile, jpeg, src) {
        this.jpegBanner = jpeg;

        let promise;

        if (imgFile === 'roll') { // camera roll
            promise = new Promise( (resolve) => resolve(src) );
        } else if (imgFile) { // upload
            promise = new Promise((resolve, reject) => {
                var reader = new FileReader();

                reader.onerror = reject;
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(imgFile);
            });
        } else { // screenshot
            promise = new Promise((resolve) => {
                resolve(renderer.domElement.toDataURL('image/png'));
            });
        }

        return Promise.all([promise, Net.getDecimatorUrl()]).then(([url, decimatorUrl]) => {
            return Net.postJSON(decimatorUrl + '/resize', {
                source: url,
                width:  renderer.domElement.clientWidth,
                height: renderer.domElement.clientHeight,
                key:    project.id + '/share-image-' + Date.now() + '.png'
            });
        }).then((data) => {
            var shareImg = data.resized;

            this.images[imgIdx] = shareImg;

            return shareImg;
        });
    }


    getSaveData() {
        let data = {
            salesInfo:   this.salesInfo,
            shareImages: this.images
        };

        return data;
    }


    restoreSaveData(data) {
        // TODO: figure out how this was related to sharing
        //downloadImagesURL = Config.decimatorURL + "/images/" + project.id + "/" + user.id

        if (data.shareImages) {
            this.images = data.shareImages;
        }

        if (data.salesInfo && data.salesInfo.length > 0)
            this.salesInfo = data.salesInfo;
    }
}

export { ShareTool };
