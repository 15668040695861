import * as THREE from './three.module';

class MultiProjectionCamera extends THREE.PerspectiveCamera {
    constructor(fov, aspect, near, far) {
        super();

        this.fov = fov !== undefined ? fov : 50;
        this.aspect = aspect !== undefined ? aspect : 1;
        this.near = near !== undefined ? near : 0.1;
        this.far = far !== undefined ? far : 2000;

        this.projectionMode = 'perspective';
        this.updateProjectionMatrix();

        this.frustum = 20;
        this.cameraOrtho = new THREE.OrthographicCamera(-this.frustum * aspect,
            this.frustum * aspect,
            this.frustum,
            -this.frustum,
            1, 2000);

        this.zoomFactor = 0.95;
    }
    toOrthographic() {
        this.projectionMode = 'orthographic';

        this.cameraOrtho.updateProjectionMatrix();

        this.projectionMatrix.copy(this.cameraOrtho.projectionMatrix);
    }
    toPerspective() {
        this.projectionMode = 'perspective';

        this.updateProjectionMatrix();
    }
    setZoom(zoom) {
        this.cameraOrtho.zoom = zoom;
    }
    updateProjectionMatrix() {
        if (this.cameraOrtho) {
            this.cameraOrtho.left = -this.frustum * this.aspect;
            this.cameraOrtho.right = this.frustum * this.aspect;
            this.cameraOrtho.top = this.frustum;
            this.cameraOrtho.bottom = -this.frustum;

            this.cameraOrtho.updateProjectionMatrix();
        }

        if (this.projectionMode === 'orthographic') {
            this.projectionMatrix.copy(this.cameraOrtho.projectionMatrix);
        } else {
            THREE.PerspectiveCamera.prototype.updateProjectionMatrix.call(this);
        }
    }
    zoomIn() {
        this.cameraOrtho.zoom *= this.zoomFactor;
        this.cameraOrtho.updateProjectionMatrix();

        this.projectionMatrix.copy(this.cameraOrtho.projectionMatrix);
    }
    zoomOut() {
        this.cameraOrtho.zoom /= this.zoomFactor;
        this.cameraOrtho.updateProjectionMatrix();

        this.projectionMatrix.copy(this.cameraOrtho.projectionMatrix);
    }
    /**
     * Raycaster's own setFromCamera method will not work with our camera.
     */
    setRaycasterRay(coords, raycaster) {
        if (this.projectionMode === 'perspective') {
            raycaster.ray.origin.setFromMatrixPosition(this.matrixWorld);
            raycaster.ray.direction.set(coords.x, coords.y, 0.5)
                .unproject(this)
                .sub(raycaster.ray.origin)
                .normalize();
        } else {
            raycaster.ray.origin.set(coords.x, coords.y, -1).unproject(this);
            raycaster.ray.direction.set(0, 0, -1).transformDirection(this.matrixWorld);
        }
    }
}


export { MultiProjectionCamera };
