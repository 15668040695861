import { prompts, moduleLibrary, measurementUnits, init } from '../Viewer.js';
import { TEXTURE_MAPS_ASSETS } from './GuiConstants';
import { mmToInches } from '../alibs/Units';

import * as template from '../../templates/ModuleLibrary/ModuleLibrary.hbs';

"use strict";

class ModuleLibraryGUI {
    /**
     *
     * @param {object} options
     * @param {string | undefined} options.rootSelector
     */
    constructor(options) {
        this.options = options;
        this.customModules  = {}

        this.currentModule = {}
        this.tempTextureMap = null
        // Module selected in array tab dropdown
        this.selectedModule = {}

        this.editMode     = false
        this.valuesEdited = false
        this.units        = 1

        this.largeMaps = new Set([ "mono-black-5" ]) // Add to as needed

        this.inputs = [
            "moduleName",
            "model",
            "manufacturer",
            // "textureMap",
            "wattage",
            "powTol",
            "ratVol",
            "ratCurr",
            "opnCirc",
            "maxSeriesFuse",
            "shrtCrc",
            "powTemp",
            "voltTemp",
            "currTemp",
            "cell_quan",
            "weight",
            "max_load_wind",
            "max_load_snow",
            "moduleWidth",
            "moduleHeight"
        ]

        this.titleInputs = new Set(["moduleName", "manufacturer", "model"])

        this.previewImages = {
            'poly':             'styles/images/modules/module-polycrystalline.jpg?x=v3',
            'mono':             'styles/images/modules/module-monocrystalline.jpg?x=v3',
            'mono-black':       'styles/images/modules/module-monocrystalline-black.jpg?x=v3',
            'lg-neon':          'styles/images/modules/module-lg-neon.jpg?x=v3',
            'sunpower-x-black': 'styles/images/modules/module-sunpower-x-black.jpg?x=v3',
            'sunpower-x':       'styles/images/modules/module-sunpower-x.jpg?x=v3',
            'poly-blue-2':      'styles/images/modules/module-polycrystalline-2.jpg?x=v3',
            'mono-blue-3':      'styles/images/modules/module-monocrystalline-3.jpg?x=v3',
            'mono-black-4':     'styles/images/modules/module-monocrystalline-black-4.jpg?x=v3',
            'mono-black-5':     'styles/images/modules/module-monocrystalline-black-5.png?x=v3',
        };

        /** @type {string} */
        this.rootSelector = options.rootSelector ?? '#module-library-dialog';
    }


    init() {
        let self = this;

        $(this.rootSelector).append(template());

        $('#textureMapDropdown').ddslick({
            data: TEXTURE_MAPS_ASSETS,
            width: 223,
            height: 130,
            marginTop: 6,
            selectText: "Texture Maps",
            imagePosition: "center",
            onSelected: function(selectedData){
                var val = selectedData.selectedData.value
                $('.module-specs-wrapper .col2 .preview').attr('src', self.previewImages[val]);
                self.adjustStyling(val)
                self.tempTextureMap = val
                self.valuesEdited = true
            },
            defaultSelectedIndex: 1
        });

        // Clear data
        $("body").on("click", ".module-specs-wrapper .cancel-button", function(e) {
            if(self.valuesEdited) {
                prompts.confirm('Are you sure you want to reset the fields without saving?').then(function (ok) {
                    if (ok) {
                        self.resetDialogState(true);
                        self.removeRowHighlights()
                    }
                });
            } else {
                self.resetDialogState(true);
                self.removeRowHighlights()
            }
        })

        // on click of delete, delete locally, save everything
        $("body").on("click", ".module-specs-wrapper .rem-button", function(e) {
            var name = $(this).parent().parent().attr("data-id")

            prompts.confirm('Are you sure you want to delete the selected module?').then(function (ok) {
                if (ok) {
                    /** @global moduleLibrary */
                    moduleLibrary.cmd('deleteModule', name);
                    moduleLibrary.cmd('save').then(() => {
                        self.removeModuleFromRows(name)
                        self.resetDialogState(true)

                        prompts.info("Your module was successfully removed.");
                    }).catch( (err) => {
                        prompts.info("We're sorry, there was a problem removing this module. Please contact support.")
                        throw err;
                    })
                }
            });
        });

        // on click of favorite icon
        $('body').on(
            'click',
            '.module-specs-wrapper .modules > li > div.module-favorite > button',
            async ({ currentTarget }) => {
                const name = $(currentTarget).parent().parent().attr('data-id');
                moduleLibrary.cmd('toggleFavoriteModule', name);

                await moduleLibrary.save();
                this.renderModuleList(moduleLibrary.cmd('getModules'));
            }
        );

        // on click of row edit item
        $("body").on("click", ".module-specs-wrapper .edit-button", function(e) {
            if(self.editMode && self.valuesEdited) {
                prompts.confirm('Would you like to save your updates to this module before leaving?').then(function (ok) {
                    if (ok) {
                        self.saveModule(self.currentModule, true);
                    } else {
                        self.resetDialogState(true)
                        self.removeRowHighlights();
                    }
                });
            } else {
                let row = $(this).parent().parent()
                row.addClass("module-list-bkg-animated")
                row.addClass("highlight-row")

                let list = $(".module-specs-wrapper ul.modules").children();

                for (var index = 0; index < list.length; index++) {
                    var item = list[index];
                    if(item.className.includes("module-list-bkg-animated") && row[0] !== item) {
                        $(item).removeClass("module-list-bkg-animated ")
                        $(item).removeClass("highlight-row")
                    }
                }

                let moduleName = row.attr("data-id")

                self.setCurrentModule(moduleName)
                self.currentModuleToGUI()

                self.editMode = true
                self.valuesEdited = false
            }
        })

        $("body").on("change", ".module-specs-wrapper input", function() {
            self.valuesEdited = true
        })

        // on click of save, add new module locally, save everything
        $("body").on("click", ".module-specs-wrapper .save-button", (e) => {
            let name = $(".moduleName").val();

            if (moduleLibrary.modules[name]) {
                if (moduleLibrary.modules[name].shared) {
                    prompts.info('A module with this name already exists in your organization.<br /><br />Please save your module under a different name.');
                } else {
                    prompts.confirm('A module with this name already exists. Would you like to update it and overwrite the values?').then(function (ok) {
                        if (ok)
                            self.saveModule(name, true)
                    });
                }
            } else {
                self.saveModule(name, false)
            }
        })

        $('body').on('change', '#library-switch-units', function () {
            self.setUnits($(this).is(':checked') ? 0 : 1)
        });
    }


    openLibrary () {
        $(".module-specs-wrapper ul.modules").empty()
        this.showDialog()
        this.setUnits(measurementUnits);

        this.renderModuleList(moduleLibrary.cmd('getModules'));
    }

    setCurrentModule(name) {
        // currentModule is the current module being edited in the library
        this.currentModule = { ...moduleLibrary.modules[name] || {} };
        const units = this.currentModule.units === 0 || this.currentModule.units === 1
            ? this.currentModule.units
            : this.units;

        this.currentModule.units = units;

        this.setUnits(units);
    }


    setUnits(units) {
        this.units = units
        if(units === 0) {
            // metric
            $("#moduleHeight span").text("mm")
            $("#moduleWidth span").text("mm")
            $("#weight span").text("kg*")
            $("#library-switch-units").prop("checked", true)
        } else {
            // imperial
            $("#moduleHeight span").text("in")
            $("#moduleWidth span").text("in")
            $("#weight span").text("lb*")
            $("#library-switch-units").prop("checked", false)
        }
    }


    moduleTooLarge(h,w) {
        if(this.units === 0) {
            h = mmToInches(h)
            w = mmToInches(w)
        }
        if(h > 100 || w > 60) {
            return true
        }
        return false
    }

    saveModule(name, editing) {
        let self = this

        let missingData = false
        let missingInputs = []
        let ignoreKeys  = new Set(["notes", "textureMap", "maxSeriesFuse",
                                "ratVol", "max_load_snow", "max_load_wind",
                                "units", "voltTemp", "currTemp", "ratCurr",
                                "opnCirc", "shrtCrc"])

        if(self.tempTextureMap) {
            self.currentModule["textureMap"] = self.tempTextureMap
        }

        let currModule = {
            "notes": $(".notes-class textarea").val(),
            "moduleMechType": $("#moduleMechType").val(),
            "unitsVoltageTemp": $("#unitsVoltageTemp").val(),
            "unitsCurrTemp": $("#unitsCurrTemp").val(),
            "textureMap": self.currentModule.textureMap
        }

        for (let index = 0; index < this.inputs.length; index++) {
            let id = this.inputs[index];
            let boxRef = '#' + id + ' input';
            if(this.titleInputs && this.titleInputs.has(id)) {
                boxRef = "." + id
            }
            currModule[id] = $(boxRef).val();
        }

        for (const key in currModule) {
            if (currModule.hasOwnProperty(key)) {
                const data = currModule[key];
                if(!ignoreKeys.has(key)) {
                    if(!data){
                        missingInputs.push(key)
                        missingData = true
                    }
                }
            }
        }

        if(missingData) {
            prompts.info("Please fill out all inputs before saving")
            return;
        }

        if(this.moduleTooLarge(currModule.moduleHeight, currModule.moduleWidth)) {
            prompts.info("The module dimensions are greater than the max allotted (100x60in, 2540x1524mm).  Please double check that the units & dimensions provided are accurate.")
            return;
        }

        this.currentModule       = Object.assign({}, currModule);
        this.currentModule.units = this.units;

        moduleLibrary.cmd('editModule', this.currentModule);
        moduleLibrary.cmd('save').then(() => {
            if(!editing) {
                this.addModuleToRows(this.currentModule.moduleName, this.currentModule);
            }

            this.resetDialogState(false)
            this.removeRowHighlights();

            prompts.info("Awesome! Your new module was successfully added and saved to your library.");
        }).catch((err) => {
            prompts.info("We're sorry, there was a problem saving your modules. Please contact support.")
            throw err;
        }).done();
    }


    removeModuleFromRows(modName) {
        let list = $(".module-specs-wrapper ul.modules").children()

        for (var index = 0; index < list.length; index++) {
            var item = list[index];

            if ($(item).attr("data-id") === modName) {
                $(item).remove()
                break;
            }
        }
    }

    renderModuleList(moduleList) {
        $('.modules').children().remove();
        Object.entries(moduleList).forEach(
            ([modName, modSpec]) => this.addModuleToRows(modName, modSpec)
        );
    }


    addModuleToRows(modName, module) {
        const favoriteIcon = `
        <button class="sy nocolor narrow" type="button" aria-label="favorite" ${module.shared ? 'disabled' : ''}>
            <i class="fa fa-fw ${module.favorite ? 'fa-star' : module.shared ? '' : 'fa-star-o'}"></i>
        </button>`;
        const editBtn = module.shared
            ? `<button class="sy edit-button nocolor narrow" type="button" aria-label="edit">
                    <i class="fa fa-fw fa-copy" aria-hidden="true"></i>
               </button>`
            : `<button class="sy edit-button nocolor narrow" type="button" aria-label="edit">
                    <i class="fa fa-fw fa-edit" aria-hidden="true"></i>
               </button>`;

        const deleteBtn = module.shared
            ? ''
            : `<button class="sy rem-button nocolor narrow" type="button" aria-label="delete">
                   <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
               </button>`;

        $('.modules').append(`
            <li data-id="${modName}">
                <div class="module-favorite">${favoriteIcon}</div>
                <span class="module-text">${modName}</span>

                <div class="list-actions">
                    ${editBtn}
                    ${deleteBtn}
                </div>
            </li>`
        );
    }


    currentModuleToGUI() {
        this.resetDialogState(false)

        // this.currentModule should be set when the user clicks on any row, before this point
        $("#moduleMechType").val(this.currentModule["moduleMechType"])
        $("#unitsVoltageTemp").val(this.currentModule["unitsVoltageTemp"])
        $("#unitsCurrTemp").val(this.currentModule["unitsCurrTemp"])
        $(".notes-class textarea").val(this.currentModule["notes"])

        let tMap = this.currentModule["textureMap"];

        $('.module-specs-wrapper .col2 .preview').attr('src', this.previewImages[tMap])

        let tMaps = TEXTURE_MAPS_ASSETS.map(a => a.value);
        let i = tMaps.indexOf(tMap);

        if (i === -1)
            i = 9;

        $("#textureMapDropdown").ddslick("select", {index: i})

        this.adjustStyling(tMap);

        for (let index = 0; index < this.inputs.length; index++) {
            let id = this.inputs[index];
            let boxRef = '#' + id + ' input';
            if(this.titleInputs && this.titleInputs.has(id)) {
                boxRef = "." + id
            }
            $(boxRef).val(this.currentModule[id]);
        }
    }


    adjustStyling(tMap) {
        if(this.largeMaps.has(tMap)) {
            $(".module-specs-wrapper .col2 table").css("margin-top", "30px")
            $(".module-specs-wrapper .col2 .preview").css("width", "140px")
        } else {
            $(".module-specs-wrapper .col2 table").css("margin-top", "10px")
            $(".module-specs-wrapper .col2 .preview").css("width", "150px")
        }
    }


    resetDialogState(resetCurrentMod) {
        $(".module-specs-wrapper .moduleName").val("")
        $(".module-specs-wrapper .manufacturer").val("")
        $(".module-specs-wrapper .model").val("")
        $(".module-specs-wrapper .input-module-specs-tool-height").val("")
        $(".module-specs-wrapper .input-module-specs-tool-width").val("")

        $(".notes-class textarea").val("")
        $(".module-specs-wrapper .container").each(function(idx, el) {
            let id  = $(this).attr("id")
            let boxRef = '#' + id + ' input';
            if(self.titleInputs && self.titleInputs.has(id)) {
                boxRef = "." + id
            }
            $(boxRef).val("");
        })

        this.currentModule   = resetCurrentMod ? { "textureMap": "mono" } : this.currentModule
        this.editMode        = false
        this.valuesEdited    = false
        this.tempTextureMap  = null
    }


    removeRowHighlights () {
        // Remove row highlights
        let list = $(".module-specs-wrapper ul.modules").children()
        for (var index = 0; index < list.length; index++) {
            var item = list[index];
            if(item.className.includes("module-list-bkg-animated")) {
                $(item).removeClass("module-list-bkg-animated")
                $(item).removeClass("highlight-row")
            }
        }
        this.editMode = false
    }


    showDialog() {
        var self = this

        let saveAndExit = (name) => {
            self.saveModule(name, true);

            self.resetDialogState(true);
            $("#module-library-dialog").dialog('close');
        };

        $("#module-library-dialog").dialog({
            beforeClose: function(event, ui) {
                let valuesFilled = false
                $(".module-specs-wrapper .container").each(function(idx, el) {
                    let id  = $(this).attr("id")
                    let boxRef = '#' + id + ' input';
                    if(self.titleInputs && self.titleInputs.has(id)) {
                        boxRef = "." + id
                    }
                    let val = $(boxRef).val();
                    if(val && val !== undefined) { valuesFilled = true }
                })

                if(valuesFilled) {
                    if(self.editMode && self.valuesEdited) {
                        const msgSave = 'Would you like to save your changes before leaving?';
                        const msgExists = 'A module with this name already exists. Would you like to update it and overwrite the values?';

                        const moduleName = self.currentModule.moduleName;

                        prompts.confirm(msgSave).then(function (ok) {
                            if (ok) {
                                if (moduleLibrary.modules[moduleName]) {
                                    prompts.confirm(msgExists).then(function (ok) {
                                        if (ok)
                                            saveAndExit(moduleName);
                                    });
                                } else {
                                    saveAndExit(moduleName);
                                }
                            }
                        });

                        return false; // don't close the dialog
                    }
                }

                self.resetDialogState(true);
            },
            modal: true,
            dialogClass: "module-library-dialog",
            title: "Module Library",
            closeOnEscape: true,
            open: function(){
                $('.ui-widget-overlay').on('click',function(){
                    $('#module-library-dialog').dialog('close');
                })
            },
            close: function () {
                // force UI refresh to add new modules to ddslick dropdown
                const $arrayProperties = $('#sidebar-deck-bottom .array-properties');

                if ($arrayProperties.length > 0) {
                    $arrayProperties.remove();
                    self.options.refresh();
                }

                $('#header-module-lib').blur(); // hide tooltip
            }
        })
    }
}


export { ModuleLibraryGUI };
